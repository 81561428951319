import React from "react";
import { connect } from "react-redux";
import { insertConference, updateConference } from "redux/Interaction/thunks";
import { getOnProjectWorkers, getStartTimes } from "redux/Project/selectors";
import { closeModal } from "redux/Modal/ModalModule";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { shouldAcceptConference } from "utilities/InteractionChecks";
import { convertTimeToStartAndEnd } from "utilities/utils";
import { addSingleAlert } from "redux/Notification/NotificationModule";

class ConferenceInteraction extends React.Component {
  state = {
    placeholder: false,
    currentConferenceItem: this.props.conferenceItem,
    startTimeString: "",
    selectedDay: this.props.selectedDay,
    workerId:
      this.props.onProjectWorkers.length > 0
        ? this.props.onProjectWorkers[0].id
        : 0
  };

  handleTopicChange = (event) => {
    event.preventDefault();
    const dataset = event.target.options[event.target.selectedIndex].dataset;
    const newTopicNumber = event.target.value;
    const newTopic = dataset.topic;
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.Topic = newTopic;
    item.TopicNumber = newTopicNumber;
    this.setState({
      currentConferenceItem: item
    });
  };

  handleWorkerChange = (event) => {
    event.preventDefault();

    const newWorkerId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.WorkerId = newWorkerId;
    item.WorkerName = this.props.onProjectWorkers.find(
      (c) => c.id === newWorkerId
    ).name;

    this.setState({
      currentConferenceItem: item,
      workerId: newWorkerId
    });
  };

  handleDayChange = (event) => {
    event.preventDefault();

    const newDay = event.target.value;
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.DayOfWeek = newDay;
    this.setState({
      currentConferenceItem: item
    });
  };

  handleStartTimeChange = (event) => {
    const newTime = event.target.value;
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.StartTime = newTime;
    const durationInHours = this.props.conferenceDurationList.find(
      (d) => d.id === item.DurationId
    );
    const newTimes = convertTimeToStartAndEnd(
      newTime,
      durationInHours.duration,
      item.StartDate
    );

    item.StartDate = new Date(newTimes.startDt);
    item.EndDate = new Date(newTimes.endDt);
    this.setState({
      currentConferenceItem: item,
      startTimeString: event.target.value
    });
  };
  handleDurationChange = (event) => {
    event.preventDefault();
    const durationId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.DurationId = durationId;

    const durationInHours = this.props.conferenceDurationList.find(
      (d) => d.id === item.DurationId
    );

    const newTimes = convertTimeToStartAndEnd(
      item.StartTime,
      durationInHours.duration,
      item.StartDate
    );

    //
    item.StartDate = new Date(newTimes.startDt);
    item.EndDate = new Date(newTimes.endDt);

    this.setState({
      currentConferenceItem: item
    });
  };

  handleFeedbackChange = (event) => {
    event.preventDefault();
    const fid = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentConferenceItem);
    item.FeedbackId = fid;
    this.setState({
      currentConferenceItem: item
    });
  };

  saveConference = (event) => {
    event.preventDefault();

    let result = {};
    if (this.props.isAdd) {
      const ci = Object.assign({}, this.state.currentConferenceItem);
      ci.RunId = this.props.runId;
      if (ci.WorkerId === 0) {
        ci.WorkerId = this.props.onProjectWorkers[0].id;
      }

      this.setState({
        currentConferenceItem: ci
      });

      result = shouldAcceptConference(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        ci
      );

      if (result.status === "ok") {
        this.props.insertConference(ci);
        const alert = {
          id: 14,
          type: "success",
          headline: "Conference Scheduled",
          event: "interaction",
          message: `You have a conference scheduled with ${ci.WorkerName} to discuss ${ci.Topic} on ${ci.DayOfWeek}`
        };
        const notificationData = {
          info: `You have a conference scheduled with ${ci.WorkerName} to discuss ${ci.Topic} on ${ci.DayOfWeek}`,
          worker: "Everyone",
          week: this.props.displayProjectWeek
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else if (result.status !== "empty") {
        const alert = {
          id: 15,
          type: "danger",
          headline: "Conference Conflict",
          event: "interaction scheduling",
          message: result.message
        };
        this.props.addAlert(alert, {}, 3000, false);
      }
    } else {
      result = shouldAcceptConference(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        this.state.currentConferenceItem
      );
      if (result.status === "ok") {
        this.props.updateConference(this.state.currentConferenceItem);
        const alert = {
          id: 14,
          type: "success",
          headline: "Conference Updated",
          event: "interaction",
          message: `You have a conference scheduled with ${this.state.currentConferenceItem.WorkerName} to discuss ${this.state.currentConferenceItem.Topic} on ${this.state.currentConferenceItem.DayOfWeek}`
        };
        const notificationData = {
          info: `You have a conference scheduled with ${this.state.currentConferenceItem.WorkerName} to discuss ${this.state.currentConferenceItem.Topic} on ${this.state.currentConferenceItem.DayOfWeek}`,
          worker: "Everyone",
          week: this.props.displayProjectWeek
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else if (result.status !== "empty") {
        const alert = {
          id: 15,
          type: "danger",
          headline: "Conference Conflict",
          event: "interaction scheduling",
          message: result.message
        };
        this.props.addAlert(alert, {}, 3000, false);
      }
    }
    // Close the modal on save
    //  this.props.dismiss();
  };

  render() {
    let iterKey = 1;
    return (
      <div>
        <Form className="form-split">
          <FormGroup>
            <Label for="topic">Choose Topic</Label>
            <Input
              id="topic"
              type="select"
              key="conference-topic"
              placeholder="Topic"
              value={this.state.currentConferenceItem.TopicNumber}
              onChange={(event) => this.handleTopicChange(event)}
            >
              {this.props.conferenceTopics.map((row, index) => (
                <option key={iterKey++} value={row.id} data-topic={row.topic}>
                  {row.topic}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="worker">Choose Worker</Label>
            <Input
              id="worker"
              type="select"
              key="conference-worker-input"
              placeholder="worker"
              value={this.state.currentConferenceItem.WorkerId}
              onChange={(event) => this.handleWorkerChange(event)}
            >
              {this.props.onProjectWorkers.map((row, index) => (
                <option
                  key={iterKey++}
                  value={row.id}
                  data-workername={row.name}
                >
                  {row.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="day">Choose Day</Label>
            <Input
              id="day"
              type="select"
              key="conference-day"
              placeholder="day"
              value={this.state.currentConferenceItem.DayOfWeek}
              onChange={(event) => this.handleDayChange(event)}
            >
              {this.props.days.map((day, index) =>
                day !== "Sunday" && day !== "Saturday" ? (
                  <option key={iterKey++} value={day}>
                    {day}
                  </option>
                ) : (
                  " "
                )
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="startTime">Start: </Label>
            <Input
              id="startTime"
              type="select"
              placeholder="startTime"
              key="conference-start"
              value={this.state.currentConferenceItem.StartTime}
              onChange={(event) => this.handleStartTimeChange(event)}
            >
              {this.props.startTimes.map((row, index) => (
                <option key={iterKey++} value={row}>
                  {row}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="duration">Duration (hours): </Label>
            <Input
              id="duration"
              type="select"
              key="conference-duration"
              placeholder="duration"
              value={this.state.currentConferenceItem.DurationId}
              onChange={(event) => this.handleDurationChange(event)}
            >
              {this.props.conferenceDurationList.map((row, index) => (
                <option key={iterKey++} value={row.id}>
                  {row.duration}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="feedback">Choose Feedback</Label>
            <Input
              id="feedback"
              type="select"
              key="conference-feedback"
              placeholder="feedback"
              value={this.state.currentConferenceItem.FeedbackId}
              onChange={(event) => this.handleFeedbackChange(event)}
            >
              {this.props.feedbackList.map((row, index) => (
                <option key={iterKey++} value={row.id}>
                  {row.topic}
                </option>
              ))}
            </Input>
          </FormGroup>
          <footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(event) => this.saveConference(event)}
            >
              Save
            </button>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayProjectWeek: state.project.displayProjectWeek,
  conferenceTopics: state.project.conferenceTopicList,
  conferenceDurationList: state.project.conferenceDurationList,
  onProjectWorkers: getOnProjectWorkers(state),
  startTimes: getStartTimes(state),
  days: state.project.daysOfWeek,
  feedbackList: state.project.feedbackList,
  runId: state.project.runId,
  interactionList: state.execution.interactions
});

const mapDispatchToProps = (dispatch) => ({
  insertConference: (conferenceItem) =>
    dispatch(insertConference(conferenceItem)),
  updateConference: (conferenceItem) =>
    dispatch(updateConference(conferenceItem)),
  dismiss: () => dispatch(closeModal()),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export const ConferenceInteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceInteraction);
