import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

const ReferenceDocumentPDFLink = (props) => {
  let documentLocation = "/docs/UniversitySim/AlliancePrototype-Ref-02.2018.pdf";
  if (props.productId === 50) {
    documentLocation = "/docs/PaccissSim/PACCISS_Reference_Documents.pdf"
  }
  return (
  <Button tag="a" href={documentLocation}  download style={{marginBottom: "15px"}}>
    <FontAwesomeIcon icon={faFilePdf} /> Download PDF of references
    </Button>
    )
};

export default ReferenceDocumentPDFLink;
