import { Table  } from "reactstrap";
import styled from "styled-components";

const StyledTable = styled(Table)`
  &.table th,
  &.table td {
    vertical-align: middle;
  }

  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: var(--gray-color);
  }
`;

export default StyledTable;
