import React from "react";
import styled from "styled-components";
const StyledSpan = styled.span`
font-size: 10px;
color: red;
margin-right: 10px;
`;
const ProjectDebug = props => {

  return (
    <div className="report-Item">
    <StyledSpan>
      <strong>Urgency: </strong> {props.properties.urgency}
      </StyledSpan>
      <StyledSpan>
       <strong> Scope Change Awareness : </strong>  {props.properties.sca}</StyledSpan>
       </div>
  );
};

export default ProjectDebug
