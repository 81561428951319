import React from "react";
import ResourceImage from "components/ResourceImage";

export const AssistantPanel = (props) => {
  var iterKey = 1;
  return (
    <div className="component-Alert">
      <div className="alert-Resource">
        <ResourceImage alt="Assistant" png="Assistant.png" />
        <div className="resource-Body">
          <h3>Assistant</h3>
        </div>
      </div>
      <div className="alert-Body">
        {props.playScriptList.map((s, index) => (
          <div key={iterKey++} id="message">
            {s.Script}
          </div>
        ))}
      </div>
    </div>
  );
};
