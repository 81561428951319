import React from "react";

export const TrainingBis = ({productId}) => {
  return (
    <div id="TrainingBis" className="style-background-components">
      <h2>Northrop Grumman Security Systems Training Offerings</h2>
      <p>
        The training department at Northrop Grumman offers several workshops
        designed to increase a participant's knowledge in the areas of teamwork,
        leadership, and technical competency.{" "}
      </p>
      <p>
        When available via direct charge on a program, training shall only be
        offered when the direct benefit of the training will be realized by the
        program funding the training. In no case will training be offered via
        direct charge when there is no reasonable expectation that the training
        will benefit the program (e.g. training on a topic after the tasks using
        that information are complete or training on a relevant topic so late in
        a program that no benefit can be gained).
      </p>
      <p>
        The workshops are offered in either a 2-day or 3-day format. The cost of
        the 2-day workshops is {productId === 51 ? '$750' : '$1500'} and the cost of the 3-day workshops is
        {productId === 51 ? '$1050' : '$2100'}. The training department also offers a 2-day long teambuilding
        workshop intended to promote better teamwork and requires the entire
        project team to attend.
      </p>

      <p>
        Project managers may choose any of the following workshops for team
        members to attend:
      </p>
      <ol>
        <br />
        <li>
          <a href="#w1">Interpersonal Skills</a>
        </li>
        <li>
          <a href="#w2">Project Management</a>
        </li>
        <li>
          <a href="#w3">Leadership</a>
        </li>
        <li>
          <a href="#w4">Quality</a>
        </li>
        <li>
          <a href="#w5">Requirements Analysis and Design</a>
        </li>
        <li>
          <a href="#w6">Implementation Methods and Technologies</a>
        </li>
        <li>
          <a href="w7">Systems Testing and Deployment</a>
        </li>
        <li>
          <a href="#w8">Teambuilding</a>
        </li>
      </ol>
      <br />

      <h4 id="w1">Interpersonal Skills</h4>
      <p>
        This workshop is intended for improving a participant's interpersonal
        skills through practical exercises and tool use. Specific topics, skills
        and tools include active listening, conflict management, diversity,
        negotiation, Myers-Briggs Type Indicator (MBTI), and creativity.
        <br />
        <br />
        This is a 2-day workshop that meets on Monday and Tuesday. The cost of
        this workshop is {productId === 51 ? '$750' : '$1500'} per attendee.
      </p>
      <h4 id="w2">Project Management</h4>
      <p>
        This workshop is intended for individuals desiring to learn the basics
        of project management. Specific topics, skills and tools include the
        project management development process, Project Charter, Work Breakdown
        Structure, PERT Chart, Gantt Chart, Critical Path, and resource
        planning. This workshop is computer simulation based and participants
        practice planning and managing a "real-life" project while developing
        their skills.
        <br />
        <br />
        This is a 2-day workshop that meets on Monday and Tuesday. The cost of
        this workshop is {productId === 51 ? '$750' : '$1500'}.
      </p>
      <h4 id="w3">Leadership</h4>
      <p>
        This workshop is intended for individuals desiring to enhance their
        leadership skills. Specific topics, skills and tools include identifying
        the key leadership skills, positive focus, effective questions
        (Enlightened Leadership), building a high performance team, the five Key
        Leadership Practices (Koosner & Posner), and the Leadership Practices
        Inventory.
        <br />
        <br />
        This is a 2-day workshop that meets on Monday and Tuesday. The cost of
        this workshop is {productId === 51 ? '$750' : '$1500'} per attendee.
      </p>
      <h4 id="w4">Quality</h4>
      <p>
        This workshop is for learning about quality and what affects quality.
        Specific topics, skills, and tools include definition of quality,
        relationship of quality to the other project variables, quality through
        customer relations, and 6-Sigma and the company processes and tools.
        <br />
        <br />
        This is a 2-day workshop that meets on Monday and Tuesday. The cost of
        this workshop is {productId === 51 ? '$750' : '$1500'} per attendee.
      </p>
      <h4 id="w5">Requirements Analysis and Design</h4>
      <p>
        This workshop is intended for individuals desiring to develop their
        skills in project requirements analysis and design. Specific topics,
        skills, and tools include requirements identification and management,
        requirements analysis and project specification, and design development
        techniques and tools.
        <br />
        <br />
        This is a 3-day workshop that meets on Wednesday, Thursday, and Friday.
        The cost of this workshop is {productId === 51 ? '$1050' : '$2100'}.
      </p>
      <h4 id="w6">Implementation Methods and Technologies</h4>
      <p>
        This workshop is intended for individuals desiring to develop their
        skills in project implementation techniques. Specific topics, skills,
        and tools include understanding design documents, mapping requirements
        to implementation, specific company implementation techniques and tools,
        and implementation testing.
        <br />
        <br /> This is a 3-day workshop that meets on Wednesday, Thursday, and
        Friday. The cost of this workshop is {productId === 51 ? '$1050' : '$2100'} per attendee.
      </p>

      <h4 id="w7">Systems Testing and Deployment</h4>
      <p>
        This workshop is intended for individuals desiring to develop their
        skills in project testing, deployment, and support. Specific topics,
        skills, and tools include developing and writing test documentation,
        performing project level testing, project deployment, and ongoing
        project support processes and tools.
        <br /> <br /> This is a 3-day workshop that meets on Wednesday,
        Thursday, and Friday. The cost of this workshop is {productId === 51 ? '$1050' : '$2100'} per attendee.
      </p>
      <h4 id="w8">Teambuilding</h4>
      <p>
        This workshop requires the entire team to attend and is designed to
        promote better teamwork. Specific topics, skills, and tools include
        identifying and understanding behavior typing such as the Myers-Briggs
        Type Indicator (MBTI), dealing with conflict, learning effective
        listening, and building team consensus.
        <br /> <br />
        This is a 2-day workshop that meets on Wednesday and Thursday. The cost
        of this workshop is $32,000 per group.
      </p>
    </div>
  );
};
