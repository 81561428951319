import { createSelector } from "reselect";
import React from "react";

const getResourcePlan = state => state.planning.resource.resourcePlan;
const getResourcePlanDisplay = state =>
  state.planning.resource.resourcePlanDisplay;
const getTasks = state => state.project.resourceActionTasks;
const getWorkers = state => state.project.workers;
const getWorkerAssignments = state => state.project.workerAssignmentList;
const getSkills = state => state.project.skills;
const getBudgetIsLocked = state => state.project.budgetIsLocked
export const getColumnNames = createSelector(
  [getResourcePlan],
  resourcePlan => {
    // basic column headers for the grid.
    const resColumns = [];
    if (resourcePlan) {
      resColumns.push({
        dataKey: "ResourceName",
        label: "Resource",
        type: "main"
      });
      resColumns.push({ dataKey: "SkillName", label: "Title", type: "main" });

      const resObj = resourcePlan[0];
      if (resObj) {
        for (
          let i = 0, l = resObj.ResourcePlanItemCollection.length;
          i < l;
          i += 1
        ) {
          const detObj = resObj.ResourcePlanItemCollection[i];
          const str = `W${detObj.PlanWeekColumnId}`;
          resColumns.push({
            datakey: "ResourcePlanItemCollection.Action",
            label: str,
            type: "week"
          });
        }
      }
    }
    return resColumns;
  }
);

export const getTaskSelectOptions = createSelector(
  [getTasks, getBudgetIsLocked],
  (resourceActionTasks, budgetIsLocked) => {
    // label, value
     const actiontasks = [];
    if (resourceActionTasks) {
      for (let i = 0, l = resourceActionTasks.sort((a,b) =>  a.SortOrder - b.SortOrder).length; i < l; i += 1) {
        const thisTask = resourceActionTasks[i];
        // don't add deferred tasks anytime or vacation in planning phase
        if (!thisTask.DeferLoad) {
          if (thisTask.TaskId !== 500) { // vacation
          

            actiontasks.push({
              label: thisTask.DisplayName,
              value: thisTask.Id
            });
          
        } else if (budgetIsLocked) {

            actiontasks.push({
              label: thisTask.DisplayName,
              value: thisTask.Id
            });
        }
        }
      }
    }
    return actiontasks;
  }
)
export const getUnfinishedTaskSelectOptions = createSelector(
  [getTasks],
  resourceActionTasks => {
    // label, value
     const actiontasks = [];
    if (resourceActionTasks) {
      for (let i = 0, l = resourceActionTasks.length; i < l; i += 1) {
        const thisTask = resourceActionTasks[i];
        if (!thisTask.DeferLoad && thisTask.TaskState !== "tsComplete" && parseInt(thisTask.TaskId, 10) < 500) {

          actiontasks.push({
            label: thisTask.DisplayName,
            value: thisTask.Id
          });
        }
      }
    }
    return actiontasks;
  }
)

export const getActionTasks = createSelector(
  [getTasks],
  resourceActionTasks => {
    const actiontasks = [];

    if (resourceActionTasks) {
      for (let i = 0, l = resourceActionTasks.length; i < l; i += 1) {
        const thisTask = resourceActionTasks[i];
        if (!thisTask.DeferLoad) {
          actiontasks.push(thisTask.DisplayName);
        }
      }
    }
    return actiontasks;
  }
);

export const getActionTasksOptions = createSelector(
  [getTasks],
  resourceActionTasks => {
    const actiontaskOptions = [];
    let key = 1;
    if (resourceActionTasks) {
      for (let i = 0, l = resourceActionTasks.length; i < l; i += 1) {
        const thisTask = resourceActionTasks[i];
        if (!thisTask.DeferLoad) {
          actiontaskOptions.push(
            <option
              key={(key += 1)}
              value={thisTask.DisplayName}
              label={thisTask.DisplayName}
            >
              {thisTask.DisplayName}
            </option>
          );
        }
      }
    }
    return actiontaskOptions;
  }
);

export const getAvailableWorkers = createSelector(
  [getWorkers, getWorkerAssignments, getResourcePlanDisplay],
  (workers, workerAssignments, resourcePlanDisplay) => {

    if (resourcePlanDisplay === undefined || resourcePlanDisplay === null
    || workers === undefined || workers === null) {
      return []
    }

    const availableWorkers = [];

    for (let w = 0, l = workers.length; w < l; w += 1) {
      const worker = workers[w];
      let found = false;
      if (worker.DeferLoad === true) {
        continue;
      }

      for (let i = 0; i < resourcePlanDisplay.length; i += 1) {
        if (resourcePlanDisplay[i].ResourceName === worker.WorkerName) {
          found = true;
          break;
        }
      }

      if (found === false) {
        const currentWorkerAssignment = workerAssignments.find(w => w.workerId === worker.workerId);
        worker.statusCode = currentWorkerAssignment.workerStatusCode;
        if (worker.statusCode !== "wsResigned" && worker.statusCode !== "wsNone") {
        worker.ImagePng = `${worker.WorkerName.replace(/\s+/g, "")}.png`;
        availableWorkers.push(worker);
      }
      }
    }
    return availableWorkers;
  }
);


export const getAvailableResources = createSelector(
  [getWorkers, getWorkerAssignments, getResourcePlanDisplay, getSkills],
  (workers, workerAssignments, resourcePlanDisplay, skills) => {

    if (resourcePlanDisplay === undefined || resourcePlanDisplay === null
    || workers === undefined || workers === null) {
      return []
    }

    const availableResources = [];

    for (let w = 0, l = workers.length; w < l; w += 1) {
      const worker = workers[w];
      let found = false;
      if (worker.DeferLoad === true) {
        continue;
      }

      for (let i = 0; i < resourcePlanDisplay.length; i += 1) {
        if (resourcePlanDisplay[i].ResourceName === worker.WorkerName) {
          found = true;
          break;
        }
      }

      if (found === false) {
        const currentWorkerAssignment = workerAssignments.find(w => w.workerId === worker.workerId);
        worker.statusCode = currentWorkerAssignment.workerStatusCode;
        if (worker.statusCode !== "wsResigned" && worker.statusCode !== "wsNone") {
          worker.ImagePng = `${worker.WorkerName.replace(/\s+/g, "")}.png`;
          
          const skillName = skills.find(s => s.SkillId === worker.SkillId).SkillName;
          availableResources.push({
            ...worker,
            ResourceName: worker.WorkerName,
            SkillName: skillName
          });
      }
      }
    }
    return availableResources;
  }
);


export const getWorkerNameAndPng = createSelector(
  getResourcePlanDisplay,
  resourcePlanDisplay => {
    const returnList = [];
    for (let w = 0, l = resourcePlanDisplay.length; w < l; w += 1) {
      const res = resourcePlanDisplay[w];
      const ret = {
        workerId: res.ResourceId,
        workerName: res.ResourceName,
        workerPng: res.ImagePng
      };
      returnList.push(ret);
    }
    return returnList;
  }
);

export const getEnhancedResources = createSelector(
  [getWorkers, getResourcePlanDisplay],
  (workers, resourcePlanDisplay) => {
    const enhancedResources = [];
    if (resourcePlanDisplay === undefined || resourcePlanDisplay === null
    || workers === undefined || workers === null) {
      return []
    }
    for (let w = 0, l = workers.length; w < l; w += 1) {
      const worker = workers[w];
      worker.OnProject = false;
      for (let i = 0, k = resourcePlanDisplay.length; i < k; i += 1) {
        if (resourcePlanDisplay[i].ResourceId === worker.WorkerId) {
          worker.OnProject = true;
          break;
        }
      }

      enhancedResources.push(worker);
    }
    return enhancedResources;
  }
);

export const getWorkerNames = createSelector([getWorkers], workers => {
  const workerNames = [];
  for (let w = 0, l = workers.length; w < l; w += 1) {
    const worker = workers[w];
    workerNames.push(worker.WorkerName);
  }
  return workerNames;
});

// TODO - remove this if not used, commented for now
// function filter(arr, criteria) {
//   return arr.filter(function(obj) {
//     return Object.keys(criteria).every(function(c) {
//       return obj[c] === criteria[c];
//     });
//   });
// }
