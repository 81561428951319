import React from "react";

const StaffingTooltip = () => (
    <div style={{textAlign: "left"}}>
      Efficient use of resources is <strong>VERY IMPORTANT</strong>. <br />Using your plan while considering your current tasks status, project status, and weekly project feedback, do you need to update any:

      <ul>
        <li>“On project” resource task assignments for the week?</li>
        <li>Staffing releasing actions for the week?</li>
        <li>Overtime requests for the week?</li>

      </ul>
    </div>
  )

export default StaffingTooltip
