import React from "react";

export const TaskStatus = props => (
  <div>
    <h6 id="tasks-status">
      <span> Task #{props.properties.task} is:</span> &nbsp;&nbsp;
      {props.properties.status === "complete"
        ? `${props.properties.pctComplete}% complete`
        : `${props.properties.status} and is ${props.properties.pctComplete}% complete`}
    </h6>
  </div>
);
