import React from 'react';


export const Release = (props) => {
	return (
		<div className="content">
			<p>
				<strong>{props.properties.worker}</strong> <br />
				<small>{props.properties.title}</small> <br />
		has been released from the project.</p>
		</div>
	);
};
