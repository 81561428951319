import React from "react";
import imageURLs from "./images/imageURLs.js";

const ProjectPerformanceHelp = () => (
  <div>
    <h3>Project Performance Index Chart Info</h3>
    <p>
      The Project Performance Index (PPI) chart is a trend chart tracking a simple performance measure (% budget spent/% project complete) of the project. It is not a very accurate measurement because it assumes that you will spend money at the same rate as you complete the project. This is typically a bad assumption. It can still be valuable as a trend chart because if your PPI is heading up over time, that is not a good trend. If your PPI is heading down over time, that is a good trend. If you have a bad trend (up) change what you are doing, if you have a good trend (down) continue to do what you are doing.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.PPIChart}
      alt="report"
    />
  </div>
);

export default ProjectPerformanceHelp;
