import React from "react";
import { Button, ButtonGroup } from "reactstrap";

export const ConfirmModal = (props) => {
	return (
		<div className="react-confirm-alert-overlay">
			<div className="react-confirm-alert">
				{props.title && <h4>{props.title}</h4>}
				{props.message && <p>{props.message}</p>}

				<ButtonGroup>
					{props.confirmLabel && (
						<Button color="primary" onClick={props.handleConfirm}>
							{props.confirmLabel}
						</Button>
					)}
					{props.cancelLabel && (
						<Button color="secondary" outline onClick={props.handleCancel}>
							{props.cancelLabel}
						</Button>
					)}
				</ButtonGroup>
			</div>
		</div>
	);
};
