import React from "react";
import { connect } from "react-redux";
import { openModal } from "redux/Modal/ModalModule";
import {
  ADD_RESOURCE_MODAL,
  RESOURCE_PROFILE_MODAL
} from "routes/Modal/ModalTypes";
import { getAvailableWorkers } from "redux/Resource/selectors";
import {
  fetchResourcePlan,
  insertResourcePlan,
  saveResourcePlan,
  saveResourceItem,
  setFilterSkillId
} from "redux/Resource/thunks";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import scrollToComponent from "react-scroll-to-component";

import { addSingleAlert } from "redux/Notification/NotificationModule";

import AvailableResourceList from "routes/Resource/components/AvailableResourceList";

import { ResourceProfile } from "routes/Reference/components";
import ResourceProfileList from "routes/Resource/components/ResourceProfileList";

const { Fragment } = React;

class AvailableResourcesModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      resourcesForProfile: []
    };
    this.hideProfile = this.hideProfile.bind(this);
  }
  showAddResourceModal = newResourceItem => {
    this.props.showAddResourceModal(newResourceItem);
  };

  getFilteredWorkers = () => {
    let filtered = [];
    if (this.props.selectedFilterSkillId === 0) {
      filtered = this.props.availableWorkers;
    } else {
      filtered = this.props.availableWorkers.filter(
        obj =>
          parseInt(obj.SkillId, 0) ===
          parseInt(this.props.selectedFilterSkillId, 0)
      );
    }
    return filtered;
  };
  insertNewResource = resource => {
    // open the choose starting week modal
    this.props.showAddResourceModal(resource);
  };

  showProfile = (resource, event) => {
    // stop gap measure until I can name everything the same
    // in both collections - sheesh consistency
    event.preventDefault();
    let workerName;
    if (Object.prototype.hasOwnProperty.call(resource, "WorkerName")) {
      workerName = resource.WorkerName;
    } else {
      workerName = resource.ResourceName;
    }

    scrollToComponent(this.ResourcesCard, { align: "bottom", duration: 600 });

    //  see if person's profile already is showing:
    const index = this.state.resourcesForProfile.findIndex(
      m => m.Name === workerName
    );
    if (index > -1) {
      return;
    }

    const worker = this.props.workerFixedList.filter(
      wrkr => wrkr.Name === workerName
    );

    if (worker.length > 0) {
      const currProfiles = this.state.resourcesForProfile.slice();
      currProfiles.push(worker[0]);

      this.setState({
        resourcesForProfile: currProfiles
      });
    }
  };
  hideProfile = index => {
    this.setState(prevState => ({
      resourcesForProfile: prevState.resourcesForProfile.filter(
        (_, i) => i !== index
      )
    }));
  };
  closeProfile = res => {
    const currProfiles = this.state.resourcesForProfile.slice();
    const index = currProfiles.indexOf(res);
    currProfiles.splice(index, 1); // remove element
    this.setState({
      resourcesForProfile: currProfiles
    });
  };
  renderProfileLists = () => {
    const profileLists = [];
    let i = 1;

    Object.keys(this.state.resourcesForProfile).forEach((key, index) => {
      profileLists.push(
        <Col md="6" lg="4" key={(i += 1)}>
          <Card>
            <CardHeader>
              Profile
              <Button
                outline
                size="sm"
                color="secondary"
                onClick={e => this.hideProfile(index, e)}
              >
                <i className="fa fa-times ml-0" />
              </Button>
            </CardHeader>
            <CardBody>
              <ResourceProfile
                key={(i += 1)}
                worker={this.state.resourcesForProfile[key]}
              />
            </CardBody>
          </Card>
        </Col>
      );
    });

    return profileLists;
  };

  render() {
    return (
      <Fragment>
          <AvailableResourceList
            skills={this.props.skills}
            runId={this.props.runId}
            showProfile={this.showProfile}
            hideProfile={this.hideProfile}
            insertResourcePlan={this.insertNewResource}
          />
        <Row>
          {/* {this.renderProfileLists()} */}
          <ResourceProfileList
            ref={ref => {
              this.ResourcesCard = ref;
            }}
            profileLists={this.state.resourcesForProfile}
            hideProfile={this.hideProfile}
          />
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  skills: state.project.skills,
  selectedFilterSkillId: state.planning.resource.selectedFilterSkillId,
  runId: state.project.runId,
  availableWorkers: getAvailableWorkers(state),
  workerFixedList: state.project.workerFixedList
});

const mapDispatchToProps = dispatch => ({
  fetchResourcePlan: runId => dispatch(fetchResourcePlan(runId)),
  insertResourcePlan: (runId, data) =>
    dispatch(insertResourcePlan(runId, data)),
  saveResourcePlan: data => dispatch(saveResourcePlan(data)),
  setFilterSkillId: id => dispatch(setFilterSkillId(id)),

  saveResourceItem: data => dispatch(saveResourceItem(data)),
  showAddResourceModal: newResourceItem =>
    dispatch(
      openModal(
        ADD_RESOURCE_MODAL,
        {
          newResourceItem
        },
        "modal-medium"
      )
    ),
  showResourceProfileModal: resource =>
    dispatch(
      openModal(
        RESOURCE_PROFILE_MODAL,
        {
          resource
        },
        "modal-medium"
      )
    ),

  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  AvailableResourcesModal
);
