import React, {useState} from "react";
import StyledCardBody from "components/styled/StyledCardBody";
import {
  Card,
  CardHeader,
  Collapse,
  Label,
  Input
} from "reactstrap";

const SettingsInfo = () => {
  const [cardInfoOpen, setCardInfoOpen] = useState(false);

  return (
    <Card className="Card-info">
      <CardHeader>
        <span style={{ marginRight: "10px" }}>Settings Info</span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => setCardInfoOpen(!cardInfoOpen)}
            defaultChecked={cardInfoOpen}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </CardHeader>
      <Collapse isOpen={cardInfoOpen}>
        <StyledCardBody>
          <h4>Review/Change Classrooms</h4>
          <p>
            Selecting an instructor and placing yourself into a classroom: When
            you first logged in, you selected your instructor and placed
            yourself into a classroom. If you initially selected the wrong
            instructor or classroom, you can change instructors and classrooms
            with the provided dropdown selections. If you do not see your
            instructor's name listed, please contact support@simulationpl.com.)
          </p>
          <h4>Review License</h4>
          <p>Provides information on your simulation license.</p>
        </StyledCardBody>
      </Collapse>
    </Card>
  );
};

export default SettingsInfo
