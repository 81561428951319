import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
font-size: 10px;
color: red;
margin-right: 10px;
`;



const ReassignDebug = props => {

  return (
      <div className="report-Item">

       <StyledSpan><strong> Worker: </strong>  {props.properties.worker}</StyledSpan>
       <StyledSpan><strong>Priority: </strong> {props.properties.which}</StyledSpan>
       <StyledSpan><strong> Was:</strong>  {props.properties.was} </StyledSpan>

       <StyledSpan><strong> Now : </strong> {props.properties.now}</StyledSpan>



       </div>
  );
};

export default ReassignDebug