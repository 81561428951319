import React from "react";
import imageURLs from "./images/imageURLs.js";

const EstimatedFinishHelp = () => (
  <div>
    <h3>Estimated Project Finish Chart Info</h3>

    <p>
      The Estimated Schedule at Completion (ESAC) chart is a trend chart tracking the projected completion date of the project. Watch your trend each week and adjust control your progress to meet your schedule objective.

    </p>



        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.EstimatedProjectFinishChart}
          alt="report"
        />
  </div>
);

export default EstimatedFinishHelp;
