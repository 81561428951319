export const convertTimeToStartAndEnd = (timeString, durationString, start) => {
  const startDt = new Date(start);
  const timePart = timeString.substring(0, timeString.length - 2);
  /* eslint-disable no-useless-escape*/
  const timeParts = timePart.split(/\:|\-/g);
  const hr = timeParts[0];
  const mn = timeParts[1];
  if (parseInt(hr, 10) >= 8 && parseInt(hr, 10) <= 12) {
    startDt.setHours(hr);
  } else {
    const newHr = parseInt(hr, 10) + 12;

    startDt.setHours(newHr.toString());
  }
  startDt.setMinutes(mn);
  startDt.setDate(1);
  startDt.setMonth(0);
  startDt.setFullYear(2001);

  const minsToAdd = parseInt(parseFloat(durationString) * 60, 10);
  const endDt = new Date(startDt.getTime() + minsToAdd * 60 * 1000);

  const result = {
    startDt,
    endDt
  };
  return result;
};

export const workerStatusLookup = statusCode => {
  switch (statusCode) {
    case "Arrives1Week":
      return "Arrives in 1 week";
    case "Requested":
    case "Arrives2Weeks":
      return "Arrives in 2 weeks";
    case "Leaves1Week":
      return "Leaves in 1 week";
    case "Leaves2Weeks":
      return "Leaves in 2 weeks";
    case "OnProject":
      return "On project";
    case "Vacation":
      return "On vacation";
    case "Released":
      return "Released";
    case "Available":
    case "None":
      return "Available";
    case "Arrives3Weeks":
      return "Arrives in 3 weeks";
    case "Resigned":
      return "Resigned";
    default:
      return "Unknown";
  }
};
export const isOnBoarding = status => {
  let onBoarding = false;
  if (
    status === "wsArrives2Weeks" ||
    status === "wsArrives1Week" ||
    status === "wsArrives3Weeks"
  ) {
    onBoarding = true;
  }
  return onBoarding;
};

export const isOnProject = status => {
  let onProject = false;
  if (
    status === "wsOnProject" ||
    status === "wsLeaves1Week" ||
    status === "wsLeaves2Weeks" ||
    status === "wsAbsent" ||
    status === "wsReassign" ||
    status === "wsVacation"
  ) {
    onProject = true;
  }
  return onProject;
};

/**
 * Vanilla JS version on jQuery's nextUntil()
 * https://github.com/cferdinandi/nextUntil
 */
export const nextUntil = (elem, selector, filter) => {
  // matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }
  // Setup siblings array
  const siblings = [];
  // Get the next sibling element
  elem = elem.nextElementSibling;
  // As long as a sibling exists
  while (elem) {
    // If we've reached our match, bail
    if (elem.matches(selector)) break;

    // If filtering by a selector, check if the sibling matches
    if (filter && !elem.matches(filter)) {
      elem = elem.nextElementSibling;
      continue;
    }
    // Otherwise, push it to the siblings array
    siblings.push(elem);
    // Get the next sibling element
    elem = elem.nextElementSibling;
  }
  return siblings;
};

export function shallowDiffers(a, b) {
  for (const i in a) if (!(i in b)) return true;
  for (const i in b) if (a[i] !== b[i]) return true;
  return false;
}

export const  weekOfThisDay = (day) => {
  const correctForZeroDay = day + 1;
  const week = parseInt(
    (correctForZeroDay - ((correctForZeroDay - 1) % 5)) / 5 + 1,
    0
  );
  return week;
};
