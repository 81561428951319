/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword as submit } from "redux/Authentication/thunks";
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Button,
  FormFeedback
} from "reactstrap";

import {
  addSingleAlert,
  dismissAlert,
  clearAlerts
} from "redux/Notification/NotificationModule";

import { NOTIFICATION_MODAL } from "routes/Modal/ModalTypes";
import { openModal } from "redux/Modal/ModalModule";

const errorMessages = {
  invalidPasswordStrength:
    "Passwords must be at least 7 characters and contain at least one special character such as a period or a pound sign",
  invalidPasswordLength: "Password must be at least 7 characters",
  passwordsDontMatch: "Passwords must match"
};
export class ResetPassword extends Component {


  state = {
      passwordAnswer: "",
      newPassword: "",
      confPassword: "",

      error: true,
      answerValidationState: "success",
      passwordValidationState: "success",
      confirmValidationState: "success",
      passwordAlertText: "Please enter new password",
      confirmAlertText: "Please confirm new password",
      answerAlertText: "Please enter response to security question"
    };

  handleResetPassword = event => {
    event.preventDefault();
    this.props.submit(
      this.props.username,
      this.state.passwordAnswer,
      this.props.password,
      this.state.newPassword
    );
  };

  handlePasswordChange = event => {
    event.preventDefault();
    this.setState({ newPassword: event.target.value });
  };

  handlePasswordOnBlur = event => {
    event.preventDefault();
    event.preventDefault();
    const newPass = event.target.value;
    const minNumberofChars = 7;
    const maxNumberofChars = 50;
    let errorMsg = "NONE";
    const regularExpression = /^(?=.*[!@#.$%^&*])[a-zA-Z0-9!@#.$%^&*]{7,50}$/;
    if (
      newPass.length < minNumberofChars ||
      newPass.length > maxNumberofChars
    ) {
      errorMsg = errorMessages.invalidPasswordLength;
      const alert = {
        id: 100,
        type: "danger",
        headline: "Reset Password Error",
        event: "resetpassword",
        message: errorMsg
      };
      this.props.addAlert(alert, {}, 5000, false);
      this.setState({
        newPasswordError: errorMsg,
        error: true
      });
    } else if (!regularExpression.test(newPass)) {
      errorMsg = errorMessages.invalidPasswordStrength;

      const alert = {
        id: 100,
        type: "danger",
        headline: "Reset Password Error",
        event: "resetpassword",
        message: errorMsg
      };
      this.props.addAlert(alert, {}, 5000, false);

      this.setState({
        newPasswordError: errorMsg,
        error: true
      });
    } else {
      this.setState({
        newPassword: newPass,
        newPasswordError: ""

        //  submitButtonDisabled: this.checkFormReady === true ? false : true
      });
    }
  };

  handleConfPasswordOnBlur = event => {
    event.preventDefault();

    const confPass = event.target.value;
    if (confPass !== this.state.newPassword) {
      this.setState({
        error: true,
        confPasswordError: "Passwords must match!"
      });
      const alert = {
        id: 6,
        type: "danger",
        headline: "Reset Password Error",
        event: "resetpassword",
        message: "Passwords must match!"
      };
      this.props.addAlert(alert, {}, 5000, false);
    } else {
      this.setState({
        confPassword: confPass,
        confirmValidationState: "success",
        confirmAlertText: ""
        //    submitButtonDisabled: this.checkFormReady === true ? false : true
      });
    }
  };
  handleConfPasswordChange = event => {
    event.preventDefault();
    const confPass = event.target.value;
    this.setState({
      confPassword: confPass
    });
  };

  handleAnswerChange = event => {
    event.preventDefault();
    this.setState({ passwordAnswer: event.target.value, error: false });
  };

  // this method is used by the username formgroup to get the current
  // validation state of the password input
  // valid states success, warning, error
  getPasswordValidationState = () => this.state.passwordValidationState;

  // this method is used by the confirm password formgroup to get the current
  // validation state of the confirm password input
  // valid states success, warning, error
  getConfirmValidationState = () => this.state.confirmValidationState;

  // this method is used by the answer formgroup to get the current
  // validation state of the answer input
  // valid states success, warning, error
  getAnswerValidationState = () => this.state.answerValidationState;

  handleResetPassword = event => {
    event.preventDefault();

    this.props.submit(
      this.props.auth.user.UserName,
      this.state.passwordAnswer,
      this.props.auth.user.variable,
      this.state.newPassword
    );
  };

  render() {
    const isEnabled =
      this.state !== undefined &&
      this.state.newPassword !== "" &&
      this.state.passwordAnswer !== null &&
      this.props.auth.user.UserName !== "" &&
      this.props.auth.user.variable !== null &&
      this.state.error !== true;
    return (
      <div className="middle-box animated fadeInDown">
        <p>
          <strong>
            Passwords must be at least 7 characters and contain at least one
            special character such as a period or a pound sign.
          </strong>
        </p>
        <Form className="m-t">
          <FormGroup>
            <Label for="pass">Enter new password</Label>
            <Input
              id="pass"
              type="password"
              value={this.state.newPassword}
              placeholder="New Password"
              onChange={(event) => this.handlePasswordChange(event)}
              onBlur={(event) => this.handlePasswordOnBlur(event)}
              required
            />
            <FormFeedback>{this.getPasswordValidationState()}</FormFeedback>
            <FormText>{this.state.passwordAlertText}</FormText>
          </FormGroup>

          <FormGroup>
            <Label for="conf">Confirm password</Label>
            <Input
              id="conf"
              type="password"
              value={this.state.confPassword}
              placeholder="Re-enter Password"
              onChange={(event) => this.handleConfPasswordChange(event)}
              onBlur={(event) => this.handleConfPasswordOnBlur(event)}
              required
            />
            <FormFeedback>{this.state.confirmAlertText}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="pa">{this.props.auth.user.PasswordQuestion}</Label>
            <Input
              id="pa"
              type="text"
              value={this.state.passwordAnswer}
              placeholder="Answer your security question"
              onChange={(event) => this.handleAnswerChange(event)}
              required
            />
            <FormFeedback>{this.getAnswerValidationState()}</FormFeedback>
            <FormText>{this.state.answerAlertText}</FormText>
          </FormGroup>
          <Button
            type="button"
            disabled={!isEnabled}
            className="btn btn-primary block full-width m-b"
            onClick={(event) => this.handleResetPassword(event)}
          >
            Submit
          </Button>
          <Button
            type="cancel"
            className="btn block full-width m-b"
            onClick={(event) =>this.props.cancel(event)}
          >
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  dismissAlert: idx => dispatch(dismissAlert(idx)),
  clearAlerts: () => dispatch(clearAlerts()),
  submit: (username, passwordAnswer, oldPassword, newPassword) =>
    dispatch(submit(username, passwordAnswer, oldPassword, newPassword)),
  showPasswordResetComplete: () =>
    dispatch(
      openModal(
        NOTIFICATION_MODAL,
        "Your password has been reset; please login",
        "modal-medium"
      )
    )
});

export const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
