import React from "react";
import { Card, CardBody, Label, Input,Collapse} from "reactstrap";
import imageURLs from "components/Help/images/imageURLs.js";
import InfoFooter from "components/Info/InfoFooter";
import CardHeaderBold from "components/styled/CardHeaderBold"

const TrainingInfo = props => (
	<Card >
	<CardHeaderBold>
        <span className="float-left">Training Info</span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => props.toggle()}
            defaultChecked={props.panelVisible}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </CardHeaderBold>
      <Collapse isOpen={props.panelVisible}>
		<CardBody>
			<h5>Context and resources for training planning</h5>
			<p>
				Create a detailed Training Plan or training budget to use in making sure your team members execute their tasks efficiently and meet project objectives:

			</p>
			<ol>
				<li>
					The detailed plan approach involves planning the specific class, resource and week of each training event prior to starting the project.

				</li>
				<li>
					The training budget approach can work well too. Pick any number of classes (they can be the same class or different classes), week for the training (same week or different weeks), and resources (same resource or different resource) to create a training budget for use as needed during the execution of your project.

				</li>
			</ol>

			<h5>Building your plan</h5>
			<p>Add a training to your plan by clicking the "Add Training" button</p>
			<ol>

				<li>Select a course/class from the drop-down menu.
    </li>
				<li>
					Select the project week number to hold the training from the drop-down menu.

				</li>
				<li>Select the resource to attend the class from the drop-down menu.
    </li>
			</ol>
			<p>Training options and costs are detailed in the Project Reference Guide. Add enough training classes to meet your approved Training budget.
   </p>
			<h5>Editing your plan</h5>
			<p>
				Once entered, individual training events can be edited or deleted using the   <img
		          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

		          src={imageURLs.EditDeleteIconsSmall}
		          alt="editdelete"
				/> icons to the far right of each row.
			</p>
			<p>
				It is good project management to keep your Training plan up to date as
				you execute your project.
			</p>
		</CardBody>
		   <InfoFooter selectedKey="training" showProjectLink={true}/>
    </Collapse>
	</Card>
);

export default TrainingInfo;
