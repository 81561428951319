import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import imageURLs from "./images/imageURLs.js";


const RiskPlanningHelp = () => (
  <div>
    <h3> Planning Your Project – Risk/Opportunities Plan </h3>
    <h4>Context and Resources for Risk/Opportunities Planning: </h4>
    <p>
      Create a Risk Plan to be proactive in dealing with potential negative and positive risk events which might occur as you execute your project. Building a risk plan creates a budget reserve that will be used in the execution of the project to pay for:

    </p>
    <ol>
      <li>Unknown expenses that popup</li>
      <li>
        Team member salaries while assigned to tasks that are delayed or waiting for a predecessor task to finish (Network Diagram predecessor dependency).
      </li>
    </ol>
    <p>
      The following will be useful in identifying potential risks and
      opportunities in creating your risk plan:{" "}
    </p>
    <ol style={{marginTop: "10px", marginBottom: "10px"}}>
      <li>The Gantt Chart and Network Diagram
      </li>
<li>Project Charter</li>
      <li>Task descriptions</li>
      <li>Team member profiles</li>
    </ol>
    <h4>Building your Risk/Opportunities Plan </h4>


    <Row>
      <Col lg="12">
          <p>Add a risk or opportunity by clicking on the ‘Add New Risk’ button to open the modal: </p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.AddNewRiskButton}
          alt="newRisk"
        />
      </Col>

    </Row>

    <Row style={{marginTop: "10px"}}>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.AddEditRisk}
          alt="newRisk"
        />
      </Col>
      <Col lg="6">
        <ol>
          <li>
            {" "}
            Enter the risk/opportunity ‘event name’. It is always a good idea to
            enter an ‘Unknown unknown’ risk event to cover the risks you can’t
            identify now, but know will happen as you execute your project{" "}
          </li>
          <li> Select a probability from the drop down menu </li>
          <li>
            Enter a cost impact (positive for a risk, negative for an opportunity){" "}
          </li>
          <li>
            {" "}
            Create an action plan for proactively planning to deal with the risk
            (mitigate) or opportunity (enhance). You must enter an action plan.{" "}
          </li>
          <li>
            Click on the “Save” button to save your entry or the “Close” button to
            cancel your entry.{" "}
          </li>
        </ol>
      </Col>

    </Row>

    <p>
     Add enough risks/opportunities to meet your approved Risk/Opportunities budget.

    </p>
    <h4>Editing your Risk/Opportunities Plan</h4>
    <Row>
      <Col lg="8">
        <p>

          Once entered, individual risk/opportunity events can be edited or deleted
          using the icons (pencil/trash can) in the last column of each row.{" "}
        </p>
      </Col>
      <Col lg="4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.EditDeleteIcons}
          alt="newRisk"
        />
      </Col>
    </Row>

    <h4> Execution and your Risk/Opportunities Plan: </h4>
    <p>
      Use your plan in making your weekly project decisions. Follow your plan if it makes sense, but also change your plan as project conditions change (i.e. a new risk needs to be added). When you start executing the project the budget amounts will be locked but you will still be able to update your Risk/Opportunities Plan to track actual progress. It is good Project Management practice to keep your Risk/Opportunities Plan up to date as risks change, even if the planned budget is locked.

    </p>
  </div>
);

export default RiskPlanningHelp;
