import React, { useState } from "react";
import { connect } from "react-redux";
import { resetProject } from "redux/Project/thunks";
import { RESET_PROJECT_MODAL } from "routes/Modal/ModalTypes";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import RightMarginButton from "components/styled/RightMarginButton";

/// current project week to put in dropdown
const ResetRun = props => {
  const [selectedWeek, setSelectedWeek] = useState(0);

  let selectWeek = selected => {
    setSelectedWeek(selected.value);
  };
  return (
    <RightMarginButton
      color="primary"
      onClick={() => {
        props.showResetProjectModal(
          props.runId,
          selectedWeek,
          props.currentWeek,
          selectWeek,
          props.resetProject
        );
      }}
    >
      Reset
    </RightMarginButton>
  );
};

const mapStateToProps = state => ({
  currentWeek: state.project.currentProjectWeek
});

const mapDispatchToProps = dispatch => ({
  resetProject: (runId, weekNumber) =>
    dispatch(resetProject(runId, weekNumber)),
  //RESET_PROJECT_MODAL
  showResetProjectModal: (
    runId,
    selectedWeek,
    currentWeek,
    setSelectedWeek,
    resetProject
  ) =>
    dispatch(
      openModal(
        RESET_PROJECT_MODAL,
        {
          runId,
          selectedWeek,
          currentWeek,
          setSelectedWeek,
          resetProject
        },
        "modal-small"
      )
    ),
  closeModal: () => dispatch(closeModal())
});

const ResetRunContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetRun);
export default ResetRunContainer;
