/* eslint-disable */

import { handleActions } from "redux-actions";
import {
  CREATE_API_ENTITY_ERROR,
  PRE_CREATE_API_ENTITY_ERROR,
  PRE_FETCH_ERROR,
  // TOGGLE_INFO_PANEL,
  // TOGGLE_BUDGET_PANEL,
  TOGGLE_RECOGNITION_INFO,
  TOGGLE_RECOGNITION_BUDGET,
  TOGGLE_RISK_INFO,
  TOGGLE_RISK_BUDGET,
  TOGGLE_RESOURCE_INFO,
  TOGGLE_RESOURCE_PLAN,
  TOGGLE_RESOURCE_BUDGET,
  TOGGLE_STAKEHOLDER_OPTIONS,
  TOGGLE_STAKEHOLDER_INFO,
  TOGGLE_STAKEHOLDER_BUDGET,
  TOGGLE_TRAINING_INFO,
  TOGGLE_TRAINING_BUDGET,
  TOGGLE_WORKWEEK_INFO,
  TOGGLE_STAFFING_INFO,
  TOGGLE_INTERACTION_INFO,  
  TOGGLE_REPORT_INFO,
  TOGGLE_TOUR_OPEN,
  DATA_LOADING
} from "./types";

const initialState = {
  loading: false,
  toggleCaller: "",
  adminURL: process.env.REACT_ADMIN_BASE_URL,
  dismissTitle: "",
  riskInfoVisible: false,
  riskBudgetVisible: false,
  resourceInfoVisible: false,
  resourcePlanVisible: true,
  resourceBudgetVisible: true,
  recognitionInfoVisible: false,
  recognitionBudgetVisible: true,
  stakeholderOptionsVisible: true,
  stakeholderInfoVisible: false,
  stakeholderBudgetVisible: true,
  trainingInfoVisible: false,
  trainingBudgetVisible: true,
  workWeekInfoPanelVisible: false,
  reportInfoVisible: false,
  staffingInfoVisible: false,
 interactionInfoVisible: false,
  // Tour Component properties
  isTourOpen: false,
  tourConfig: [],
  position: "right",
  baseURL: process.env.REACT_APP_BASE_URL,
  mbrshpURL: process.env.REACT_APP_MBRSHP_URL
};

// ------------------------------------
// Reducer
// ------------------------------------
//
export default handleActions(
  {
    [DATA_LOADING]: (state, { payload }) => ({
      ...state,
      loading: payload
    }),
    [TOGGLE_RECOGNITION_INFO]: (state, { payload }) => {
      const currentPanelState = state.recognitionInfoVisible;
      return {
        ...state,
        recognitionInfoVisible: !currentPanelState
      };
    },
    [TOGGLE_RECOGNITION_BUDGET]: (state, { payload }) => {
      const currentPanelState = state.recognitionBudgetVisible;
      return {
        ...state,
        recognitionBudgetVisible: !currentPanelState
      };
    },
    [TOGGLE_RESOURCE_INFO]: (state, { payload }) => {
      const currentPanelState = state.resourceInfoVisible;
      return {
        ...state,
        resourceInfoVisible: !currentPanelState
      };
    },
    [TOGGLE_RESOURCE_PLAN]: (state, { payload }) => {
      const currentPanelState = state.resourcePlanVisible;
      return {
        ...state,
        resourcePlanVisible: !currentPanelState
      };
    },
    [TOGGLE_RESOURCE_BUDGET]: (state, { payload }) => {
      const currentPanelState = state.resourceBudgetVisible;
      return {
        ...state,
        resourceBudgetVisible: !currentPanelState
      };
    },
    [TOGGLE_RISK_INFO]: (state, { payload }) => {
      const currentPanelState = state.riskInfoVisible;
      return {
        ...state,
        riskInfoVisible: !currentPanelState
      };
    },
    [TOGGLE_RISK_BUDGET]: (state, { payload }) => {
      const currentPanelState = state.riskBudgetVisible;
      return {
        ...state,
        riskBudgetVisible: !currentPanelState
      };
    },
    [TOGGLE_STAKEHOLDER_OPTIONS]: (state, { payload }) => {
      const currentPanelState = state.stakeholderOptionsVisible;
      return {
        ...state,
        stakeholderOptionsVisible: !currentPanelState
      };
    },
    [TOGGLE_STAKEHOLDER_INFO]: (state, { payload }) => {
      const currentPanelState = state.stakeholderInfoVisible;
      return {
        ...state,
        stakeholderInfoVisible: !currentPanelState
      };
    },
    [TOGGLE_STAKEHOLDER_BUDGET]: (state, { payload }) => {
      const currentPanelState = state.stakeholderBudgetVisible;
      return {
        ...state,
        stakeholderBudgetVisible: !currentPanelState
      };
    },
    [TOGGLE_TRAINING_INFO]: (state, { payload }) => {
      const currentPanelState = state.trainingInfoVisible;
      return {
        ...state,
        trainingInfoVisible: !currentPanelState
      };
    },
    [TOGGLE_TRAINING_BUDGET]: (state, { payload }) => {
      const currentPanelState = state.trainingBudgetVisible;
      return {
        ...state,
        trainingBudgetVisible: !currentPanelState
      };
    },
    [TOGGLE_WORKWEEK_INFO]: (state, { payload }) => {

      const currentPanelState = state.workWeekInfoPanelVisible;
      
      return {
        ...state,
        workWeekInfoPanelVisible: !currentPanelState
      };
    },
    [TOGGLE_STAFFING_INFO]: (state, { payload }) => {
      return {
        ...state,
        staffingInfoVisible: !state.staffingInfoVisible
      };
    },
      [TOGGLE_INTERACTION_INFO]: (state, { payload }) => {
      return {
        ...state,
        interactionInfoVisible: !state.interactionInfoVisible
      };
    },
    [TOGGLE_REPORT_INFO]: (state, { payload }) => {
      return {
        ...state,
        reportInfoVisible: !state.reportInfoVisible
      };
    },
    [TOGGLE_TOUR_OPEN]: (state, { payload }) => {
      let tc = state.tourConfig.splice(0);

      if (payload.isTourOpen === true) {
        tc = payload.tourConfig.splice(0);
      }

      return {
        ...state,
        isTourOpen: payload.isTourOpen,
        tourConfig: tc
      };
    }
  },
  initialState
);
