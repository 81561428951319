import React from "react";
import ResourceImage from "components/ResourceImage";

export const Staffing = props => {
	// if there was no new staffing activity, worker will be none, don't need
	// image or worker then, just the status
	var iterKey = 1;
	if (props.properties.worker === "NONE" || props.properties.worker === "" || props.properties.worker === null) {
		return (
			<div>
				<p>{props.properties.status}</p>
			</div>
		);
	}


	// eslint-disable-next-line no-return-assign
	return (
		<div className="report-Item">
			<div className="report-Item_Icon">
				<div className="resource-Profile">
					<ResourceImage
						key={iterKey += 1}
						png={
							props.properties.worker === "Buki"
								? "Assistant.png"
								: props.properties.worker
										.replace(/\s+/g, "")
										.replace(/\./g, "") + ".png"
						}
						alt={props.properties.worker}
					/>
				</div>
        <div className="resource-Body">
          <h2>
            {props.properties.worker}
            <small>
              {props.properties.title}
            </small>
          </h2>
        </div>
			</div>
			<div className="report-Item_Content">
				<p>{props.properties.status}</p>
			</div>
		</div>
	);
};
