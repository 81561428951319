import React from 'react';


export const StaffingUpdate = (props) => {
	return (
		<div className="content">
			<h3>
				{props.properties.worker}
				<small>{props.properties.title}</small>
			</h3>
			<p>{props.properties.status}</p>
		</div>
	);
};
