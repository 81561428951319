import React from "react";
import styled from "styled-components";
const StyledSpan = styled.span`
font-size: 10px;
color: red;
margin-right: 10px;
`;
const StakeholderDebug = props => {

  return (
    <div className="report-Item">
    <StyledSpan>
      <strong>Stakeholder: </strong> {props.properties.stakeholder}
      </StyledSpan>
      <StyledSpan>
       <strong> Mutual Trust : </strong>  {props.properties.trust}</StyledSpan>
       <StyledSpan>
       <strong> SCA : </strong>  {props.properties.sca}</StyledSpan>
       </div>
  );
};

export default StakeholderDebug
