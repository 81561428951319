import React from "react";


export const IndvReward = (props) => {
  return (
    <div className="content">
      <h6>
        {props.properties.worker}&nbsp;
        was rewarded with: {props.properties.reward}
      </h6>
    </div>
  );
};
