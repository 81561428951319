import accounting from "accounting";
var options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};
export const overallPlanCheck = (budgetSummary, productId, maxBudget) => {
  let result = {};

  if (budgetSummary.PlannedCost > maxBudget) {
    let diff = budgetSummary.PlannedCost - maxBudget;
    result = {
      className: "check-failed",
      status: "fail",
      message:
        `Your plan cannot be approved because your planned budget exceeds the fixed approved amount of ${accounting.formatMoney(maxBudget, options)} by ${ accounting.formatMoney(diff)}.  Your current planned budget is ${ accounting.formatMoney(budgetSummary.PlannedCost, options)}`
    };
  } else if (budgetSummary.ApprovedBudget > budgetSummary.PlannedCost * 1.05) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your overall planned budget is less than 95% of your approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    result = {
      className: "check-passed",
      status: "pass",
      message: "Passed"
    };
  }
  return result;
};

export const trainingPlanCheck = trainingBudget => {
  let result = {};

  // trainingBudget
  if (trainingBudget.plannedBudget < 0) {
    result = {
      className: "check-failed",
      status: "fail",
      message:
        "There is a miscalculation in the budget and the training plan costs are negative.  Please contact support@simulationpl.com"
    };
  } else if (trainingBudget.plannedBudget === 0) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "You have not added any training to your planned budget.  Are you sure you are ready to submit for approval?"
    };
  } else if (
    trainingBudget.plannedBudget / trainingBudget.approvedBudget >
    1.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "The cost of the planned training is more than 50% greater than the approved budget for training.  You may want to adjust this to make sure you finish on budget."
    };
  } else if (
    trainingBudget.plannedBudget / trainingBudget.approvedBudget <
    0.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned training is less than 50% of amount given in the approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    result = {
      className: "check-passed",
      status: "pass",
      message: "Passed"
    };
  }
  return result;
};

export const stakeholderPlanCheck = stakeholderBudget => {
  let result = {};
  if (stakeholderBudget.plannedBudget < 0) {
    result = {
      className: "check-failed",
      status: "fail",
      message:
        "There is a miscalculation in the budget and the stakeholder plan costs are negative.  Please contact support@simulationpl.com"
    };
  } else if (stakeholderBudget.plannedBudget === 0) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "You have not added any stakeholder activity to your planned budget.  Are you sure you are ready to submit for approval?"
    };
  } else if (
    stakeholderBudget.plannedBudget / stakeholderBudget.approvedBudget >
    1.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned stakeholder activity is more than 50% greater than the approved budget for stakeholders.  You may want to adjust this to make sure you finish on budget."
    };
  } else if (
    stakeholderBudget.plannedBudget / stakeholderBudget.approvedBudget <
    0.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned stakeholder activity is less than 50% of amount given in the approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    result = {
      className: "check-passed",
      status: "pass",
      message: "Passed"
    };
  }
  return result;
};

export const riskPlanCheck = riskBudget => {
  let result = {};

  if (riskBudget.plannedBudget < 0) {
    result = {
      className: "check-failed",
      status: "fail",
      message:
        "There is a miscalculation in the budget and the risk plan costs are negative.  Please contact support@simulationpl.com"
    };
  } else if (riskBudget.plannedBudget === 0) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "You have not added any Risk / Reserve budget to your planned budget.  Are you sure you are ready to submit for approval?"
    };
  } else if (riskBudget.plannedBudget / riskBudget.approvedBudget > 1.5) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned Risk / Reserve budget amount is more than 50% greater than the approved budget for Risk / Reserve.  You may want to adjust this to make sure you finish on budget."
    };
  } else if (riskBudget.plannedBudget / riskBudget.approvedBudget < 0.5) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned Risk / Reserve budget amount is less than 50% of amount given in the approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    result = {
      className: "check-passed",
      status: "pass",
      message: "Passed"
    };
  }
  return result;
};

export const recognitionPlanCheck = recognitionBudget => {
  let result = {};
  if (recognitionBudget.plannedBudget < 0) {
    result = {
      className: "check-failed",
      status: "fail",
      message:
        "There is a miscalculation in the budget and the recognition plan costs are negative.  Please contact support@simulationpl.com"
    };
  } else if (parseInt(recognitionBudget.plannedBudget, 10) === 0) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "You have not added any recognition to your planned budget.  Are you sure you are ready to submit for approval?"
    };
  } else if (
    recognitionBudget.plannedBudget / recognitionBudget.approvedBudget >
    1.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned recognition is more than 50% greater than the approved budget.  You may want to adjust this to make sure you finish on budget."
    };
  } else if (
    recognitionBudget.plannedBudget / recognitionBudget.approvedBudget <
    0.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your planned recognition is less than 50% of amount given in the approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    result = {
      className: "check-passed",
      status: "pass",
      message: "Passed"
    };
  }
  return result;
};

export const resourcePlanCheck = (resourceBudget, overallBudget) => {
 
  let result = {
    className: "check-passed",
    status: "pass",
    message: "Passed"
  };
  if (resourceBudget.plannedBudget < 0) {
    result = {
      className: "check-failed",
      status: "pass",
      message:
        "There is a miscalculation in the budget and the resource plan costs are negative.  Please contact support@simulationpl.com"
    };
  } else if (resourceBudget.plannedBudget === 0) {
    result = {
      className: "check-failed",
      status: "fail",
      message:
        "Your resource costs are zero in your planned budget.  You must complete your resource plan before your budget can be approved."
    };
  } else if (
    resourceBudget.plannedBudget / resourceBudget.approvedBudget >
    1.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your resource plan costs are more than 50% greater than the approved budget.  You may want to adjust this to make sure you finish on budget."
    };
  } else if (
    resourceBudget.plannedBudget / resourceBudget.approvedBudget <
    0.5
  ) {
    result = {
      className: "check-warning",
      status: "warning",
      message:
        "Your resource plan is less than 50% of amount given in the approved budget.  Are you sure you are ready to submit for approval?"
    };
  } else {
    // check first if any particular task budges are missing from the overall resource budget
    let keys = [];
    keys = Object.keys(overallBudget);
    for (let i = 0; i < keys.length; i++) {
        if (overallBudget[i].PlannedCost === 0 && overallBudget[i].Formatting === "2" && overallBudget[i].PrefixDescriptor !== "1.1  Training") { // child budget item
          result = {
            className: "check-failed",
            status: "fail",
            message:
              `Your resource costs for ${overallBudget[i].PrefixDescriptor} budget item are zero in your planned budget.  
              You must complete your resource plan before your budget can be approved.`
          };
          break;
        } else if (overallBudget[i].Formatting === "2" && 
        (overallBudget[i].PlannedCost / overallBudget[i].ApprovedBudget <0.5)) {
          result = {
            className: "check-warning",
            status: "warning",
            message:
            `Your resource costs for ${overallBudget[i].PrefixDescriptor} budget item are less than 50% of amount given in the approved budget.  Please check your resource plan.  Are you sure you are ready to submit for approval?`
          };
          break;
        }

     
    }
  }

  return result;
};
