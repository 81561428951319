import React from "react";

const RiskTooltip = () => (
    <div style={{textAlign: "left"}}>
      <p>Have you identified potential risks for your project?</p>
      <p>Did you include a risk event for “unknown unknowns”? </p>
    </div>
  )

export default RiskTooltip
