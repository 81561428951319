import React from "react";

const InteractionTooltip = () => (
    <div style={{textAlign: "left"}}>
      Using your plan while considering your current tasks status, project status, and weekly project feedback (i.e., individual and team performance, quality, stakeholder feedback) do you need to schedule any:

      
      <ul>
        <li>Individual conferences?</li>
        <li>Team meetings?</li>
        <li>Individual or team rewards?</li>
        <li>Training for your resources</li>
        <li>Stakeholders interactions?</li>
        <li>Stakeholder training?</li>
      </ul>
    </div>
  )

export default InteractionTooltip
