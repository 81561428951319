import React from 'react'

const styles = {
  textStyle: {
    fontWeight: 'normal',
    color: 'red',
    fontSize: 14,
    fontFamily: 'Arial'
  }
}
export const MissedInteraction = (props) => {
  return (
    <div style={{display: 'flex', justifyContent: 'left'}}>
      <span style={styles.textStyle}>{props.properties.worker} {props.properties.reason}<br /><br /></span>
    </div>
 )
}

