import React from "react";
import accounting from "accounting";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import { Card } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%s%v"
};

const WorkerSummaryTable = (props) => {
  const footer = () => {
    const footers = [];
    const totalCosts = props.data.reduce(function (a, b) {
      return a + b["total"];
    }, 0);
    const totalHours = props.data.reduce(function (a, b) {
      return a + (b["reg"] + b["ot"]);
    }, 0);
    const regHours = props.data.reduce(function (a, b) {
      return a + b["reg"];
    }, 0);

    const otHours = props.data.reduce(function (a, b) {
      return a + b["ot"];
    }, 0);

    footers.push(
      <td className="text-right" key="wsfooter1">
        <strong>Totals:</strong>
      </td>
    );
    footers.push(
      <td key="wsfooter2">
        <span />
      </td>
    ); // hourly rate - no summary

    footers.push(
      <td className="text-center" key="wsregfooter">
        <strong>{regHours}</strong>
      </td>
    );
    footers.push(
      <td className="text-center" key="wsotfooter">
        <strong>{otHours}</strong>
      </td>
    );
    footers.push(
      <td className="text-center" key="wshoursfooter">
        <strong>{totalHours}</strong>
      </td>
    );
    footers.push(
      <td className="text-right" key="wscostsfooter">
        <strong>{accounting.formatMoney(totalCosts)}</strong>
      </td>
    );
    return footers;
  };

  return (
    <Card>
      <CardHeaderBold>
      By Resource Labor Hours/Costs
      </CardHeaderBold>
      <NoMaxCardBody>
        <StyledTable bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-center">Hourly Rate</th>
              <th className="text-center">Regular Hours</th>
              <th className="text-center">Overtime Hours</th>
              <th className="text-center">Total Project Hours</th>
              <th className="text-center">Total Project Labor Cost</th>
            </tr>
          </thead>
          <tbody>
            {props.data
              .sort((a, b) =>
                a.workerName < b.workerName
                  ? -1
                  : a.workerName > b.workerName
                  ? 1
                  : 0
              )
              .map((row) => (
                <tr key={`${row.workerName}-tr`}>
                  <td>{row.workerName}</td>
                  <td className="text-right" style={{ marginRight: "10px" }}>
                    {accounting.formatMoney(row.hourlyRate, options)}
                  </td>
                  <td className="text-center">{row.reg}</td>
                  <td className="text-center">{row.ot > 0 ? row.ot : ""}</td>
                  <td className="text-center">{row.reg + row.ot}</td>
                  <td className="text-right" style={{ marginRight: "10px" }}>
                    {accounting.formatMoney(row.total, options)}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>{footer()}</tr>
          </tfoot>
        </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default WorkerSummaryTable;
