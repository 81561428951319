import React from "react";
import { createSelector } from "reselect";
import {isOnProject} from "utilities/utils"

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  ApprovalTooltip,
  BackToStep1Tooltip,
  InteractionTooltip,
  RecognitionTooltip,
  ReportsTooltip,
  ResourceTooltip,
  RevisePlanTooltip,
  RiskTooltip,
  StaffingTooltip,
  StakeholderTooltip,
  TrainingTooltip,
  WorkWeekTooltip
} from "components/Tooltips";

import { getReserveSummaryData } from "routes/Report/components/LaborSummary/LaborSelectors";

const getReserveHoursSummary = (state) => state.report.reserveSummaryData;
const getTaskStatusList = (state) => state.project.taskStatusList;
const getCommunicationList = (state) => state.project.communicationList;
const getWorkers = (state) => state.project.workerAssignmentList;
const getRuns = (state) => state.auth.runDetails;
const getProjectRunId = (state) => state.project.runId;
const getSkillList = (state) => state.project.taskSkillList;
const getBudget = (state) => state.project.budgetList;
const getCurrentDay = (state) => state.project.runDay;
const getCurrentWeek = (state) => state.project.displayProjectWeek;
const getStakeholderActionList = (state) => state.project.stakeholderActionList;
const getStakeholderList = (state) => state.project.stakeholderList;
const getRewardList = (state) => state.project.rewardList;
const getProjectCheckList = (state) => state.project.projectCheckList;
const getProductId = (state) => state.auth.productId;
const getResourcePlanDisplay = (state) =>
  state.planning.resource.resourcePlanDisplay;

export const getPlanningChecklistItems = createSelector(
  [getProjectCheckList],
  (checkList) => {
    let keys = [];
    let checkItem = {};
    const returnList = [];
    if (checkList) {
      keys = Object.keys(checkList);
      let clType = "";
      let clDescription = "";
      for (let i = 0; i < keys.length; i++) {
        checkItem = checkList[i];
        if (checkItem.ControlNumber < 110) {
          switch (checkItem.ControlNumber) {
            case 100:
              clType = "Planning";
              clDescription = <ResourceTooltip />;
              //  "Have you added the 2 Staff weeks required for onboarding and planned tasks for all added resources?";
              break;
            case 101:
              clType = "Planning";
              clDescription = <TrainingTooltip />;
              break;
            case 102:
              clType = "Planning";
              clDescription = <StakeholderTooltip />;
              break;
            case 103:
              clType = "Planning";
              clDescription = <RiskTooltip />;
              break;
            case 104:
              clType = "Planning";
              clDescription = <RecognitionTooltip />;
              break;
            case 105:
              clType = "Planning";
              clDescription = <ApprovalTooltip />;
              break;
            default:
              break;
          }

          checkItem = checkList[i];
          returnList.push({
            id: checkItem.ID,
            type: clType,
            text: checkItem.ControlItem,
            nbr: checkItem.ControlNumber,
            description: clDescription,
            completed: checkItem.ControlValue === 1
          });
        }
      }
    }
    return returnList;
  }
);

export const getExecutionChecklistItems = createSelector(
  [getProjectCheckList],
  (checkList) => {
    let keys = [];
    let checkItem = {};
    const returnList = [];
    if (checkList) {
      keys = Object.keys(checkList);
      let clType = "";
      let clDescription = "";
      for (let i = 0; i < keys.length; i++) {
        checkItem = checkList[i];
        if (checkItem.ControlNumber > 109) {
          switch (checkItem.ControlNumber) {
            case 110:
              clType = "Execution";
              clDescription = <StaffingTooltip />;
              break;
            case 111:
              clType = "Execution";
              clDescription = <InteractionTooltip />;
              break;
            case 112:
              clType = "Execution";
              clDescription = <WorkWeekTooltip />;
              break;
            case 113:
              clType = "Execution";
              clDescription = <ReportsTooltip />;
              break;
            case 114:
              clType = "Execution";
              clDescription = <RevisePlanTooltip />;
              break;
            case 115:
              clType = "Execution";
              clDescription = <BackToStep1Tooltip />;
              break;
            default:
              break;
          }

          checkItem = checkList[i];
          returnList.push({
            id: checkItem.ID,
            type: clType,
            nbr: checkItem.ControlNumber,
            text: checkItem.ControlItem,
            description: clDescription,
            completed: checkItem.ControlValue === 1
          });
        }
      }
    }
    return returnList;
  }
);

export const findWorkerByResourceId = (workerAssignment, resId) => {
  if (workerAssignment) {
    return workerAssignment.find((w) => w.WorkerId === resId);
  } else {
    return {};
  }
};

export const findResourceByWorkerId = (resourcePlan, workerId) =>
  resourcePlan.find((r) => r.ResourceId === workerId);

export const getCombinedWorkerResourceData = createSelector(
  [getWorkers, getResourcePlanDisplay],
  (workerAssignment, resourcePlanDisplay) => {
    if (workerAssignment === undefined || resourcePlanDisplay === undefined) {
      return [];
    }
    const combined = [];
    let keys = [];
    keys = Object.keys(resourcePlanDisplay);
    for (let i = 0; i < keys.length; i++) {
      const res = Object.assign({}, resourcePlanDisplay[i]);
      const wrkr = findWorkerByResourceId(workerAssignment, res.ResourceId);

      let upd = {};
      if (wrkr && wrkr !== {}) {
        try {
          let str = JSON.stringify(wrkr);
          str = str.replace(/Id/g, "wId");

          upd = JSON.parse(str);
        } catch (e) {
          upd = wrkr;
        }
        combined.push(Object.assign(res, upd));
      } else {
        combined.push(Object.assign({}, res));
      }
    }

    return combined;
  }
);

export const getEnhancedWorkerAssignments = createSelector(
  [getWorkers, getResourcePlanDisplay],
  (workerAssignment, resourcePlanDisplay) => {
    const combined = [];
    let keys = [];

    keys = Object.keys(workerAssignment);
    for (let i = 0; i < keys.length; i++) {
      const wrkr = Object.assign({}, workerAssignment[i]);
      const res = findResourceByWorkerId(resourcePlanDisplay, wrkr.WorkerId);

      // mirroring data in the resource plan so the resource component has the right fields

      if (
        res &&
        res !== {} &&
        wrkr &&
        wrkr !== {} &&
        wrkr.WorkerStatusCode !== "wsAvailable" &&
        wrkr.WorkerStatusCode !== "wsNone" &&
        wrkr.WorkerStatusCode !== "wsResigned"
      ) {
        wrkr.SkillName = res.SkillName;
        wrkr.SkillId = res.SkillId;
        wrkr.ImagePng = res.ImagePng;
        wrkr.ResourceName = res.ResourceName;
        wrkr.WorkerStatusOnProject = wrkr.WorkerStatusString;
        combined.push(wrkr);
      }
    }
    return combined;
  }
);
export const getStakeholderListPlusAll = createSelector(
  [getStakeholderList],
  (stakeholderList) =>
    [
      {
        id: 0,
        stakeholder: "All"
      }
    ].concat(stakeholderList)
);
export const getBudgetSummaryRow = createSelector([getBudget], (budget) => {
  const budgetRow = {};
  let keys = [];
  keys = Object.keys(budget);
  for (let i = 0; i < keys.length; i++) {
    if (budget[i].Formatting !== "1" && budget[i].Formatting !== "2") {
      budgetRow.ApprovedBudget = budget[i].ApprovedBudget;
      budgetRow.PlannedCost = budget[i].PlannedCost;
      budgetRow.ActualCost = budget[i].ActualCost;
      budgetRow.Difference = budget[i].Difference;

      return budgetRow;
    }
  }
  return budgetRow;
});

export const getResourceBudgetSummary = createSelector(
  [getBudget, getProductId],
  (budget, productId) => {
    const budgetRow = {
      approvedBudget: 0,
      plannedBudget: 0,
      actualBudget: 0
    };
    let keys = [];
    keys = Object.keys(budget);
    for (let i = 0; i < keys.length; i++) {
      if (
        budget[i].WbsParentId === 200 || //  Task 1 & 2
        budget[i].WbsParentId === 300 || // Task 3 & 5
        budget[i].WbsParentId === 400 || // Task 4 & 7
        budget[i].WbsParentId === 500
      ) {
        // Task 6) {
        budgetRow.approvedBudget += budget[i].ApprovedBudget;
        budgetRow.plannedBudget += budget[i].PlannedCost;
        budgetRow.actualBudget += budget[i].ActualCost;
      }
    }
    return budgetRow;
  }
);

export const getRiskBudgetSummary = createSelector(
  [getBudget, getProductId],
  (budget, productId) => {
    const budgetRow = {
      approvedBudget: 0,
      plannedBudget: 0,
      actualBudget: 0
    };
    let keys = [];
    keys = Object.keys(budget);

    for (let i = 0; i < keys.length; i++) {
      if (budget[i].Kind === 3) {
        //  Reserves
        budgetRow.approvedBudget += budget[i].ApprovedBudget;
        budgetRow.plannedBudget += budget[i].PlannedCost;
        budgetRow.actualBudget += budget[i].ActualCost;
      }
    }
    return budgetRow;
  }
);

export const getStakeholderBudgetSummary = createSelector(
  [getBudget, getProductId],
  (budget, productId) => {
    const budgetRow = {
      approvedBudget: 0,
      plannedBudget: 0,
      actualBudget: 0
    };
    let keys = [];
    keys = Object.keys(budget);
    for (let i = 0; i < keys.length; i++) {
      if (budget[i].Kind === 2) {
        //  Reserves
        budgetRow.approvedBudget += budget[i].ApprovedBudget;
        budgetRow.plannedBudget += budget[i].PlannedCost;
        budgetRow.actualBudget += budget[i].ActualCost;
      }
    }
    return budgetRow;
  }
);
export const getTrainingBudgetSummary = createSelector(
  [getBudget, getProductId],
  (budget, productId) => {
    const budgetRow = {
      approvedBudget: 0,
      plannedBudget: 0,
      actualBudget: 0
    };
    let keys = [];
    keys = Object.keys(budget);

    for (let i = 0; i < keys.length; i++) {
      if (budget[i].Kind === 1) {
        budgetRow.approvedBudget += budget[i].ApprovedBudget;
        budgetRow.plannedBudget += budget[i].PlannedCost;
        budgetRow.actualBudget += budget[i].ActualCost;
      }
    }
    return budgetRow;
  }
);

export const getRecognitionBudgetSummary = createSelector(
  [getBudget, getProductId],
  (budget, productId) => {
    const budgetRow = {
      approvedBudget: 0,
      plannedBudget: 0,
      actualBudget: 0
    };
    let keys = [];
    keys = Object.keys(budget);
    for (let i = 0; i < keys.length; i++) {
      if (budget[i].Kind === 5) {
        budgetRow.approvedBudget += budget[i].ApprovedBudget;
        budgetRow.plannedBudget += budget[i].PlannedCost;
        budgetRow.actualBudget += budget[i].ActualCost;
      }
    }
    return budgetRow;
  }
);

export const getNonLaborCosts = (budget) => {
  const budgetRow = {
    approvedBudget: 0,
    actualBudget: 0,
    plannedBudget: 0,
    difference: 0
  };
  let keys = [];
  keys = Object.keys(budget);
  for (let i = 0; i < keys.length; i++) {
    const kind = budget[i].Kind;
    if (
      budget[i].WbsParentId === 100 &&
      (kind === 1 ||
        kind === 11 || // subcontractor
        kind === 2 ||
        kind === 3 ||
        kind === 4 ||
        kind === 5)
    ) {
      budgetRow.approvedBudget += budget[i].ApprovedBudget;
      budgetRow.plannedBudget += budget[i].PlannedCost;
      budgetRow.actualBudget += parseInt(budget[i].ActualCost, 0) || 0;
    }
  }
  budgetRow.difference = budgetRow.plannedBudget - budgetRow.actualBudget;
  return budgetRow;
};

export const getLaborCosts = (budget) => {
  const budgetRow = {
    approvedBudget: 0,
    actualBudget: 0,
    plannedBudget: 0,
    difference: 0
  };
  let keys = [];
  keys = Object.keys(budget);

  for (let i = 0; i < keys.length; i++) {
    const kind = budget[i].Kind;

    if (
      budget[i].WbsParentId === 200 || //  Task 1 & 2
      budget[i].WbsParentId === 300 || // Task 3 & 5
      budget[i].WbsParentId === 400 || // Task 4 & 7
      budget[i].WbsParentId === 500 ||
      (budget[i].WbsParentId === 100 &&
        (kind === 6 ||
          kind === 7 ||
          kind === 8 ||
          kind === 9 ||
          kind === 10 ||
          kind === 12))
    ) {
      // Task 6) {
      budgetRow.approvedBudget += budget[i].ApprovedBudget;
      budgetRow.plannedBudget += budget[i].PlannedCost;
      budgetRow.actualBudget += parseInt(budget[i].ActualCost, 0) || 0;
    }
  }
  budgetRow.difference = budgetRow.plannedBudget - budgetRow.actualBudget;
  return budgetRow;
};

export const getEnhancedBudgetList = createSelector(
  [getBudget, getReserveHoursSummary],
  (budget, reserves) => {
    const laborBudget = getLaborCosts(budget);
    const nonLaborBudget = getNonLaborCosts(budget);

    // sum over reserves - add labor related reserves to labor and remove from non-labor
    // use the already available reserves data from reserve report
    const laborReserve = reserves.reduce((sum, record) => {
      if (record.RowKey.toLowerCase().includes("task")) {
        return sum + record.SummaryCosts;
      }
      return sum;
    }, 0);

    const newLaborBudgetItem = {
      ActualCost: laborBudget.actualBudget + laborReserve,
      ApprovedBudget: laborBudget.approvedBudget,
      Difference:
        laborBudget.approvedBudget - laborBudget.actualBudget + laborReserve,
      DisplayTask: "-",
      Formatting: " ",
      PlannedCost: laborBudget.plannedBudget,
      PrefixDescriptor: "Total Labor Costs",
      WbsId: 1000,
      WbsParentId: 0
    };

    const newNonLaborBudgetItem = {
      ActualCost: nonLaborBudget.actualBudget - laborReserve,
      ApprovedBudget: nonLaborBudget.approvedBudget,
      Difference:
        nonLaborBudget.approvedBudget -
        nonLaborBudget.actualBudget -
        laborReserve,
      DisplayTask: "-",
      Formatting: " ",
      PlannedCost: nonLaborBudget.plannedBudget,
      PrefixDescriptor: "Total Non Labor Costs",
      WbsId: 1000,
      WbsParentId: 0
    };

    const enhancedBudgetList = [];
    if (budget) {
      for (let i = 0; i < budget.length; i++) {
        if (i === 0) {
          enhancedBudgetList.push(budget[i]);
          enhancedBudgetList.push(newLaborBudgetItem);
          enhancedBudgetList.push(newNonLaborBudgetItem);
        } else {
          enhancedBudgetList.push(budget[i]);
        }
      }
    }
    return enhancedBudgetList;
  }
);

const checkBudgetItemIssues = (item) => {
  const issuesList = [];
  let issue = "";
  if (item.WbsParentId > 0) {
    // this is a child item where the actual budget is
    if (item.PlannedCost < 0) {
      issue = `${item.PrefixDescriptor} has a planned budget less than zero.  You cannot get this approved : ${item.PlannedCost}`;
      issuesList.push(issue);
    }
    if (item.PlannedCost === 0) {
      issue = `${item.PrefixDescriptor} has a planned budget of zero.  You cannot get this approved : ${item.PlannedCost}`;
      issuesList.push(issue);
      //   If (_btvdet.PlannedCost / _btvdet.ApprovedBudget) > 1.5 Or (_btvdet.PlannedCost / _btvdet.ApprovedBudget) < 0.5 Then
    }
    if (item.PlannedCost / item.ApprovedBudget > 1.5) {
      issue = `The planned budget item: ${item.PrefixDescriptor} is more than 50%  greater than the approved budget.  You may want to adjust this or other items to make sure you come in on budget:.  Approved: ${item.ApprovedBudget} Planned: ${item.PlannedCost}`;
      issuesList.push(issue);
    }
    if (item.PlannedCost / item.ApprovedBudget < 0.5) {
      issue = `The planned budget item:  ${item.PrefixDescriptor} is 50% less than the approved budget.  Are you sure you've completed your planning?   Approved: ${item.ApprovedBudget} Planned: ${item.PlannedCost}`;
      issuesList.push(issue);
    }
  }
  return issuesList;
};

export const getListOfPreApprovalBudgetIssues = createSelector(
  [getBudget],
  (budget) => {
    const issuesList = [];
    const subIssuesList = [];
    //  Review details first.  It doesn't make sense to check the total budget
    //  only to find some details are bad.
    //  checks:  planned cost = 0  planned cost < 0,
    //  let issue = "";
    let keyValue = 2000;
    let keys = [];
    let cKeys = [];
    keys = Object.keys(budget);
    for (let i = 0; i < keys.length; i++) {
      const item = budget[i];

      let checkList = [];

      if (item.WbsId === 0) {
        // this is the root, let's check the totals
        if (item.ApprovedBudget > item.PlannedCost * 1.05) {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-failed">
              Analysis Checks
            </li>
          );
          issuesList.push(
            <ul key={(keyValue += 1)}>
              <li key={(keyValue += 1)}>
                You didn't use all of your approved budget dollars. Are you sure
                you have completed your planning? Approved: " +
                item.ApprovedBudget + " vs Planned: " + item.PlannedCost
              </li>
            </ul>
          );
        } else {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-passed">
              Analysis Checks
            </li>
          );
        }
      }
      if (item.WbsId === 2) {
        // / training
        checkList = [];
        checkList = checkBudgetItemIssues(item);
        if (checkList.length <= 0) {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-passed">
              Training Plan Checks
            </li>
          );
        } else {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-failed">
              Training Plan Checks
            </li>
          );

          cKeys = Object.keys(checkList);
          for (let j = 0; j < cKeys.length; j++) {
            const chk = checkList[j];
            subIssuesList.push(<li key={(keyValue += 1)}>{chk}</li>);
          }
          issuesList.push(<ul key={(keyValue += 1)}>{subIssuesList}</ul>);
        }
      }
      if (item.WbsId === 3) {
        // / training
        checkList = [];
        checkList = checkBudgetItemIssues(item);
        if (checkList.length <= 0) {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-passed">
              Stakeholder Relations Plan Checks
            </li>
          );
        } else {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-failed">
              Stakeholder Relations Plan Checks
            </li>
          );
          // issuesList.push(<ul>)

          cKeys = Object.keys(checkList);
          for (let j = 0; j < cKeys.length; j++) {
            const chk = checkList[j];

            subIssuesList.push(<li key={(keyValue += 1)}>{chk}</li>);
          }
          issuesList.push(<ul key={(keyValue += 1)}>{subIssuesList}</ul>);
        }
      }
      if (item.WbsId === 4) {
        // / training
        checkList = [];
        checkList = checkBudgetItemIssues(item);
        if (checkList.length <= 0) {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-passed">
              Risk/Opportunities Plan Checks
            </li>
          );
        } else {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-failed">
              Risk/Opportunities Plan Checks
            </li>
          );
          // issuesList.push(<ul>)

          cKeys = Object.keys(checkList);
          for (let j = 0; j < cKeys.length; j++) {
            const chk = checkList[j];
            subIssuesList.push(<li key={(keyValue += 1)}>{chk}</li>);
          }

          issuesList.push(<ul key={(keyValue += 1)}>{subIssuesList}</ul>);
        }
      }
      if (item.WbsId === 11) {
        // / training
        checkList = [];
        checkList = checkBudgetItemIssues(item);
        if (checkList.length <= 0) {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-passed">
              Recognition Plan Checks
            </li>
          );
        } else {
          issuesList.push(
            <li key={(keyValue += 1)} className="check-failed">
              Recognition Plan Checks
            </li>
          );
          // issuesList.push(<ul>)

          cKeys = Object.keys(checkList);
          for (let j = 0; j < cKeys.length; j++) {
            const chk = checkList[j];
            subIssuesList.push(<li key={(keyValue += 1)}>{chk}</li>);
          }

          issuesList.push(<ul key={(keyValue += 1)}>{subIssuesList}</ul>);
        }
      }
    }
    return issuesList;
  }
);

/*
If (_btvdet.PlannedCost / _btvdet.ApprovedBudget) > 1.5 Or (_btvdet.PlannedCost / _btvdet.ApprovedBudget) < 0.5 Then
    '            '            _messageString = _messageString & "The budget category: " & vbCrLf & _btvdet.PrefixDescriptor &
    '            '                                          " has an awfully wide letiance from your Approved Budget! " & vbCrLf

*/
// enhanced task list is the task with information about skills and
// current works with percentage time on task

// function isMatchingTask(task, taskId) {
//   return task.TaskId === taskId;
// }

export const getLastTask = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    let myTasks = [];
    let max = 1;
    if (taskStatusList) {
      myTasks = taskStatusList.slice(0); // copy so not affect sort
      if (myTasks !== null && myTasks !== undefined && myTasks.length > 0) {
        max = myTasks.reduce((a, b) => {
          const timeA = new Date(a.EarlyFinishDay).getTime();
          const timeB = new Date(b.EarlyFinishDay).getTime();
          return timeA > timeB ? a : b;
        });
      }
    }
    return max;
  }
);
// TODO should really combine all these functions where possible
export const getProjectedLastWeek = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    let myTasks = [];
    let max = 1;
    myTasks = taskStatusList.slice(0); // copy so not affect sort
    if (myTasks !== null && myTasks !== undefined && myTasks.length > 0) {
      max = myTasks.reduce((a, b) => {
        const timeA = new Date(a.EarlyFinishDay).getTime();
        const timeB = new Date(b.EarlyFinishDay).getTime();
        return timeA > timeB ? a : b;
      });
    }

    const lastWeek = Math.ceil(max.LateFinishDay / 5) + 1;
    return lastWeek;
  }
);

const weekOfThisDay = (day) => {
  const correctForZeroDay = day + 1;
  const week = parseInt(
    (correctForZeroDay - ((correctForZeroDay - 1) % 5)) / 5 + 1,
    0
  );
  return week;
};

export const getEnhancedTaskList = createSelector(
  getTaskStatusList,
  getWorkers,
  getSkillList,
  getCurrentDay,
  getCurrentWeek,
  (taskList, workerList, skillList, runDay, displayProjectWeek) => {
    // find the workers and the skills and add them to the selected task object

    const enhancedTaskList = [];
    let myTasks = [];
    let lastTask = {};
    if (taskList) {
      myTasks = taskList.slice(0);
      if (myTasks !== null && myTasks !== undefined && myTasks.length > 0) {
        lastTask = myTasks.reduce((a, b) => {
          const timeA = new Date(a.EarlyFinishDay).getTime();
          const timeB = new Date(b.EarlyFinishDay).getTime();
          return timeA > timeB ? a : b;
        });
      }

      //  what is the finish week of this task
      //  add extra week for display purposes

      const finishWeeks = Math.ceil(lastTask.LateFinishDay / 5) + 1 + 1;

      // go through each task and set up the display properties for the
      // gantt chart
      let keys = [];
      keys = Object.keys(myTasks);
      for (let k = 0; k < keys.length; k++) {
        const currentTask = taskList[k];

        // populate workers assigned to this list
        currentTask.assignedWorkers = [];
        for (let i = 0, l = workerList.length; i < l; i++) {
          if (
            workerList[i].Task1 === currentTask.TaskId ||
            workerList[i].Task2 === currentTask.TaskId ||
            workerList[i].Task3 === currentTask.TaskId
          ) {
            const wObj = {};
            wObj.Name = workerList[i].WorkerName;
            wObj.Title = workerList[i].WorkerTitle;
            wObj.Availability =
              workerList[i].Availability >= 100
                ? 100
                : workerList[i].Availability;
            currentTask.assignedWorkers.push(wObj);
          }
        }
        // populate the skills associated with this task
        currentTask.skillList = [];
        currentTask.totalStaffing = 0;
        for (let i = 0, l = skillList.length; i < l; i++) {
          if (skillList[i].TaskId === currentTask.TaskId) {
            for (
              let j = 0, s = skillList[i].SkillsRequired.length;
              j < s;
              j++
            ) {
              currentTask.totalStaffing +=
                skillList[i].SkillsRequired[j].StaffingQty;

              currentTask.skillList.push(
                `${skillList[i].SkillsRequired[j].SkillName} (${skillList[i].SkillsRequired[j].StaffingQty})`
              );
            }
          }
        }

        // starting the gantt chart
        currentTask.ganttObj = {};

        // set display name
        currentTask.ganttObj.displayName = `${currentTask.TaskId}. ${currentTask.Name}`;

        // set the number of days to work on this task
        currentTask.ganttObj.numDays = finishWeeks * 5;
        // what day should this be done, subtract a day for display purposes
        const planFinishDay = currentTask.PlanEarlyFinishDay - 1;

        // figure out the critical path tasks
        // if there is no float, then the task is critical
        let isCritical = false;
        let finishDay = currentTask.EarlyFinishDay - 1;

        let intPlannedFloat = currentTask.Float;

        //   currentTask.LateFinishDay - currentTask.EarlyFinishDay;
        // currentTask.PlanFloat - (currentTask.PlanLateFinishDay - currentTask.PlanEarlyFinishDay);

        const floatEndDay = currentTask.LateFinishDay - 1;
        //EarlyFinishDay - 1 + intPlannedFloat;

        // if no float (critical) and the task isn't yet complete
        if (currentTask.Float === 0 && currentTask.State !== "tsComplete") {
          isCritical = true;
          finishDay = currentTask.LateFinishDay - 1;
          intPlannedFloat = 0;
        }

        // iterkey is used to give a unique key to each rendered component
        let iterKey = 0;

        // representing pct complete.
        // formula is duration * pct complete days
        // that many days will be filled only on actual tags
        let numFillDays = 0.0;

        if (currentTask.State === "tsComplete") {
          //  New algorithm take the early start day, add the duration days
          //  that should be amount of day cells to fill up
          numFillDays =
            Math.round(currentTask.EarlyStartDay) +
            (currentTask.LateFinishDay - currentTask.EarlyStartDay);
        } else {
          numFillDays = Math.round(
            (currentTask.EarlyFinishDay - currentTask.EarlyStartDay) *
              0.01 *
              currentTask.PercentComplete
          );
        }

        // className is used for the style tags
        let className = "";

        for (let day = 0; day < finishWeeks * 5; day++) {
          // do we have the current day?
          let isCurrentDay = false;
          if (day === runDay) {
            isCurrentDay = true;
          }

          // do we have the current week?
          // get the current week info for highlighting

          const isCurrentWeek = weekOfThisDay(day) === displayProjectWeek;

          // plan start day
          // plan finish day
          // early start day
          // late finish day

          // are we still in the planning rectangle?
          if (day >= currentTask.PlanEarlyStartDay && day <= planFinishDay) {
            // are we in the actual rectangle?

            if (day >= currentTask.EarlyStartDay && day < finishDay) {
              // need both the rectangles

              if (isCritical) {
                className = "gantt-Bar gantt-PlannedDate gantt-ActualDate";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    <div className="gantt-PlannedDate_Bar" />{" "}
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              } else {
                className =
                  "gantt-Bar gantt-nonCritical  gantt-PlannedDate gantt-ActualDate";

                //  className = "gantt-Bar gantt-PlannedDate gantt-nonCritical gantt-ActualDate";
                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td
                    key={(iterKey += 1)}
                    className={className}
                    data-complete={currentTask.PercentComplete}
                  >
                    <div className="gantt-PlannedDate_Bar" />{" "}
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              }
            } else if (day === finishDay) {
              if (isCritical) {
                className =
                  "gantt-Bar gantt-PlannedDate gantt-ActualDate gantt-Last";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    <div className="gantt-PlannedDate_Bar" />{" "}
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              } else {
                // not critical

                className =
                  "gantt-Bar gantt-nonCritical gantt-PlannedDate gantt-ActualDate gantt-Last";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                if (intPlannedFloat >= 0 && day <= floatEndDay) {
                  if (day === floatEndDay) {
                    className += " gantt-Float-last";
                  } else {
                    className += " gantt-Float";
                  }
                }

                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    <div className="gantt-PlannedDate_Bar" />{" "}
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              }
            } else {
              // only need the planning rectangle style

              if (isCritical) {
                className = "gantt-Bar gantt-PlannedDate";
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    <div className="gantt-PlannedDate_Bar" />
                  </td>
                );
              } else {
                className = "gantt-Bar gantt-nonCritical gantt-PlannedDate";
                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    <div className="gantt-PlannedDate_Bar" />
                  </td>
                );
              }
            }
          } else if (day === planFinishDay) {
            // need the end of the plan style - is there such a thing?
            if (day >= currentTask.EarlyStartDay && day < finishDay) {
              // end of plan, but actual continues
              if (isCritical) {
                className = "gantt-Bar gantt-ActualDate";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }

                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              } else {
                className = "gantt-Bar gantt-nonCritical gantt-ActualDate";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }

                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                if (intPlannedFloat >= 0 && day <= floatEndDay) {
                  if (day === floatEndDay) {
                    className += " gantt-Float-last";
                  } else {
                    className += " gantt-Float";
                  }
                }
                currentTask.ganttObj[day] = (
                  <td
                    key={(iterKey += 1)}
                    className={className}
                    data-complete={currentTask.PercentComplete}
                  >
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              }
              // also need the actual style
            } else if (day === finishDay) {
              // need the end style of the actual
              if (isCritical) {
                className = "gantt-Bar gantt-Last gantt-ActualDate";

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }

                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              } else {
                className = `gantt-Bar gantt-nonCritical gantt-Last gantt-ActualDate gantt-Float={${intPlannedFloat}}`;

                if (numFillDays > 0) {
                  className += " gantt-Complete";
                  numFillDays -= 1;
                }

                if (isCurrentDay) {
                  className += " gantt-CurrentPosition";
                }
                if (isCurrentWeek) {
                  className += " table-Current";
                }
                if (intPlannedFloat >= 0 && day <= floatEndDay) {
                  if (day === floatEndDay) {
                    className += " gantt-Float-last";
                  } else {
                    className += " gantt-Float";
                  }
                }
                currentTask.ganttObj[day] = (
                  <td key={(iterKey += 1)} className={className}>
                    {numFillDays > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={(iterKey += 1)}>
                            {" "}
                            {`${Math.round(
                              currentTask.PercentComplete
                            )}% complete`}
                          </Tooltip>
                        }
                      >
                        <div className="gantt-ActualDate_Bar" />
                      </OverlayTrigger>
                    ) : (
                      <div className="gantt-ActualDate_Bar" />
                    )}
                  </td>
                );
              }
            } else {
              // only need the end of the plan style
              className = "gantt-Bar";

              if (isCurrentDay) {
                className += " gantt-CurrentPosition";
              }
              if (isCurrentWeek) {
                className += " table-Current";
              }
              currentTask.ganttObj[day] = (
                <td key={(iterKey += 1)} className={className} />
              );
            }
          } else if (
            // no more planning
            day >= currentTask.EarlyStartDay &&
            day < finishDay
          ) {
            // out of the planning rectangle so now only
            // actuals are involved - need the actual style
            if (isCritical) {
              className = "gantt-Bar gantt-ActualDate";

              if (numFillDays > 0) {
                className += " gantt-Complete";
                numFillDays -= 1;
              }

              if (isCurrentDay) {
                className += " gantt-CurrentPosition";
              }
              if (isCurrentWeek) {
                className += " table-Current";
              }
              currentTask.ganttObj[day] = (
                <td key={(iterKey += 1)} className={className}>
                  {numFillDays > 0 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={(iterKey += 1)}>
                          {" "}
                          {`${Math.round(
                            currentTask.PercentComplete
                          )}% complete`}
                        </Tooltip>
                      }
                    >
                      <div className="gantt-ActualDate_Bar" />
                    </OverlayTrigger>
                  ) : (
                    <div className="gantt-ActualDate_Bar" />
                  )}
                </td>
              );
            } else {
              className = "gantt-Bar gantt-nonCritical gantt-ActualDate";

              if (numFillDays > 0) {
                className += " gantt-Complete";
                numFillDays -= 1;
              }

              if (isCurrentDay) {
                className += " gantt-CurrentPosition";
              }
              if (isCurrentWeek) {
                className += " table-Current";
              }
              currentTask.ganttObj[day] = (
                <td key={(iterKey += 1)} className={className}>
                  {numFillDays > 0 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={(iterKey += 1)}>
                          {" "}
                          {`${Math.round(
                            currentTask.PercentComplete
                          )}% complete`}
                        </Tooltip>
                      }
                    >
                      <div className="gantt-ActualDate_Bar" />
                    </OverlayTrigger>
                  ) : (
                    <div className="gantt-ActualDate_Bar" />
                  )}
                </td>
              );
            }
          } else if (day === finishDay) {
            if (isCritical) {
              className = "gantt-Bar gantt-ActualDate gantt-Last";

              if (numFillDays > 0) {
                className += " gantt-Complete";
                numFillDays -= 1;
              }

              if (isCurrentDay) {
                className += " gantt-CurrentPosition";
              }
              if (isCurrentWeek) {
                className += " table-Current";
              }
              currentTask.ganttObj[day] = (
                <td key={(iterKey += 1)} className={className}>
                  {numFillDays > 0 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={(iterKey += 1)}>
                          {" "}
                          {`${Math.round(
                            currentTask.PercentComplete
                          )}% complete`}
                        </Tooltip>
                      }
                    >
                      <div className="gantt-ActualDate_Bar" />
                    </OverlayTrigger>
                  ) : (
                    <div className="gantt-ActualDate_Bar" />
                  )}
                </td>
              );
            } else {
              className =
                "gantt-Bar gantt-ActualDate gantt-nonCritical gantt-Last";

              if (numFillDays > 0) {
                className += " gantt-Complete";
                numFillDays -= 1;
              }

              if (isCurrentDay) {
                className += " gantt-CurrentPosition";
              }
              if (isCurrentWeek) {
                className += " table-Current";
              }
              if (intPlannedFloat >= 0 && day <= floatEndDay) {
                if (day === floatEndDay) {
                  className += " gantt-Float-last";
                } else {
                  className += " gantt-Float";
                }
              }

              currentTask.ganttObj[day] = (
                <td key={(iterKey += 1)} className={className}>
                  {numFillDays > 0 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={(iterKey += 1)}>
                          {" "}
                          {`${Math.round(
                            currentTask.PercentComplete
                          )}% complete`}
                        </Tooltip>
                      }
                    >
                      <div className="gantt-ActualDate_Bar" />
                    </OverlayTrigger>
                  ) : (
                    <div className="gantt-ActualDate_Bar" />
                  )}
                </td>
              );
            }
          } else if (day > finishDay && day <= floatEndDay) {
            className = "gantt-Bar";

            if (intPlannedFloat >= 0 && day <= floatEndDay && !isCritical) {
              if (day === floatEndDay) {
                className += " gantt-Float-last";
              } else {
                className += " gantt-Float";
              }
            }

            if (isCurrentDay) {
              className += " gantt-CurrentPosition";
            }
            if (isCurrentWeek) {
              className += " table-Current";
            }
            currentTask.ganttObj[day] = (
              <td key={(iterKey += 1)} className={className} />
            );
          } else {
            className = "gantt-Bar";
            if (isCurrentDay) {
              className += " gantt-CurrentPosition";
            }
            if (isCurrentWeek) {
              className += " table-Current";
            }
            currentTask.ganttObj[day] = (
              <td key={(iterKey += 1)} className={className} />
            );
          }
        }
        // algorithm is
        // how many weeks is project?
        // what week is planned early start of this task
        // how long does it go ? duration
        // for each week of task, add "task"
        // what week is the early start
        // duration
        // for each week of task, add "task"
        // for non task weeks - add 'none'

        enhancedTaskList.push(currentTask);
      }
    }

    return enhancedTaskList;
  }
);

/*
Private Function CurrentWeek(ByVal _CurrentDay As Integer)
       CurrentWeek = _CurrentDay - ((_CurrentDay - 1) Mod 5)
   End Function
*/
// get unread email count
export const getUnreadEmailCount = createSelector(
  [getCommunicationList],
  (communicationList) => {
    let emailCount = 0;

    let keys = [];
    let emailBox = [];

    keys = Object.keys(communicationList);

    keys.forEach((boxName) => {
      emailBox = communicationList[boxName];
      let eKeys = [];
      eKeys = Object.keys(emailBox);
      if (eKeys.length > 0) {
        for (let i = 0; i < eKeys.length; i++) {
          const email = emailBox[i];
          if (email.IsRead === false) {
            emailCount += 1;
          }
        }
      }
    });
    return emailCount;
  }
);

export const getEmailCount = createSelector(
  [getCommunicationList],
  (communicationList) => {
    let emailCount = 0;
    let keys = [];
    let emailBox = [];
    if (communicationList) {
      keys = Object.keys(communicationList);
      keys.forEach((boxName) => {
        emailBox = communicationList[boxName];
        let eKeys = [];
        eKeys = Object.keys(emailBox);
        if (eKeys.length > 0) {
          emailCount += eKeys.length;
        }
      });
    }
    return emailCount;
  }
);

export const getInboxEmailCount = createSelector(
  [getCommunicationList],
  (communicationList) => {
    let emailCount = 0;

    let emailBox = [];
    emailBox = communicationList.Inbox;
    let eKeys = [];
    eKeys = Object.keys(emailBox);
    if (eKeys.length > 0) {
      emailCount += eKeys.length;
    }

    return emailCount;
  }
);
export const getSentEmailCount = createSelector(
  [getCommunicationList],
  (communicationList) => {
    let emailCount = 0;
    let emailBox = [];
    emailBox = communicationList.Sent;
    let eKeys = [];
    eKeys = Object.keys(emailBox);
    if (eKeys.length > 0) {
      emailCount += eKeys.length;
    }
    return emailCount;
  }
);
export const getOnProjectWorkers = createSelector(
  [getWorkers],
  (workerList) => {
    const onProjectWorkers = [];
    let keys = [];
    keys = Object.keys(workerList);
    for (let i = 0; i < keys.length; i++) {
      const thisWorker = workerList[i];

      if (isOnProject(thisWorker.WorkerStatusCode)) {
        onProjectWorkers.push({
          id: thisWorker.WorkerId,
          name: thisWorker.WorkerName
        });
      }
    }
    if (onProjectWorkers.length === 0) {
      onProjectWorkers.push({
        id: 0,
        name: "There are no active workers on project"
      });
    }
    return onProjectWorkers;
  }
);

// get uncompleted tasks for staffing form dropdowns
export const getUnfinishedTaskSelectOptions = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    // label, value
    const actiontasks = [];
    if (taskStatusList) {
      for (let i = 0, l = taskStatusList.length; i < l; i += 1) {
        const thisTask = taskStatusList[i];
        if (!thisTask.DeferLoad && thisTask.State !== "tsComplete") {
          actiontasks.push({
            label: thisTask.DisplayName,
            value: thisTask.Id
          });
        }
      }
    }
    return actiontasks;
  }
);

export const getUnfinishedTaskOptions = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    const taskOptions = [];
    let iterKey = 1;
    let keys = [];
    taskOptions.push(
      <option key={(iterKey += 1)} value=" " label=" ">
        {" "}
      </option>
    );
    if (taskStatusList) {
      keys = Object.keys(taskStatusList);
      for (let i = 0; i < keys.length; i++) {
        const thisTask = taskStatusList[i];
        if (thisTask.State !== "tsComplete") {
          taskOptions.push(
            <option
              key={(iterKey += 1)}
              style={{
                width: "100% !important"
              }}
              value={thisTask.TaskId}
              label={thisTask.TaskId}
            >
              {/* {thisTask.TaskId + " " + thisTask.Name} - per request of Jesse, only show task Id */}
              {thisTask.TaskId}
            </option>
          );
        }
      }
    }

    return taskOptions;
  }
);
// get status of task 2 to determine if we can let David lewis go
export const task2Complete = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    let task2 = taskStatusList.filter((t) => t.TaskId === "2");
    if (task2 && task2 != null) {
      if (task2.State === "wsComplete") {
        return true;
      }
    }
    return false;
  }
);

// get active tasks id or staffing form dropdowns
export const getActiveTasks = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    const taskOptions = [];
    let keys = [];

    keys = Object.keys(taskStatusList);
    for (let i = 0; i < keys.length; i++) {
      if (taskStatusList[i].State === "tsWorking") {
        taskOptions.push({
          value: taskStatusList[i].TaskId,
          text: taskStatusList[i].TaskId
        });
      }
    }

    return taskOptions;
  }
);

// get active tasks id or staffing resource dropdowns
export const getUnfinishedTasks = createSelector(
  [getTaskStatusList],
  (taskStatusList) => {
    const taskOptions = [];
    let keys = [];

    keys = Object.keys(taskStatusList);
    for (let i = 0; i < keys.length; i++) {
      if (
        taskStatusList[i].State !== "tsComplete" &&
        taskStatusList[i].DeferLoad === false
      ) {
        taskOptions.push({
          value: taskStatusList[i].TaskId,
          text: taskStatusList[i].TaskId
        });
      }
    }

    return taskOptions;
  }
);

export const getStartTimes = createSelector([], () => {
  const startTimes = [];

  const x = 30; // minutes interval
  let tt = 0; // start time
  const ap = ["am", "pm"]; // AM-PM

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    if (hh > 7 && hh < 17) {
      // only provide times from 8 to 4:30
      if (hh === 12) {
        startTimes[i] = `${`${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}${
          ap[Math.floor(hh / 12)]
        }`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      } else if (ap[Math.floor(hh / 12)] === "pm") {
        startTimes[i] = `${`${hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)}${
          ap[Math.floor(hh / 12)]
        }`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      } else {
        startTimes[i] = `${`0${hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)}${
          ap[Math.floor(hh / 12)]
        }`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      }
      //   startTimes[i] =
      //
      //     ("0" + hh % 12).slice(-2) +
      //     ":" +
      //     ("0" + mm).slice(-2) +
      //     ap[Math.floor(hh / 12)];  pushing data in array in [00:00 - 12:00 AM/PM format]
    }
    tt += x;
  }
  return startTimes;
});

// get next available run to begin new project

export const getNextAvailableRun = createSelector([getRuns], (runList) => {
  let thisRun = {};
  let keys = [];
  if (runList) {
    keys = Object.keys(runList);
    for (let i = 0; i < keys.length; i++) {
      thisRun = runList[i];
      if (thisRun.RunId === 0) {
        break;
      }
    }
  }
  return thisRun;
});

// get run info for checing can plan, can run, stopweeks
export const getCurrentRunInfo = createSelector(
  [getRuns, getProjectRunId],
  (runList, runId) => {
    if (runId > 0 && runList && runList.length > 0) {
      let run = runList.find((r) => r.RunId === runId);
      if (run && run !== undefined && run !== null) {
        return {
          message: "ok",
          currentRun: run
        };
      } else {
        return {
          message: "noRun",
          currentRun: {}
        };
      }
    } else {
      return {
        message: "noRun",
        currentRun: {}
      };
    }
  }
);

// get next available run to begin new project

export const getNextAvailOrCurrentRun = createSelector(
  [getRuns, getProjectRunId],
  (runList, runId) => {
    let thisRun = {};
    let keys = [];
    if (runList) {
      if (runId === 0) {
        // find the first run in the list that can be loaded
        thisRun = runList.find((r) => r.RunId > 0 && r.RunEndDate === null);
      } else {
        thisRun = runList.find((r) => r.RunId === runId);
      }
      if (!thisRun || thisRun === undefined || thisRun === null) {
        keys = Object.keys(runList);
        for (let i = 0; i < keys.length; i++) {
          thisRun = runList[i];

          if (thisRun !== undefined) {
            const first3 = thisRun.RunStatus.substring(0, 3);
            if (first3 === "Run" || first3 === "Pla" || first3 === "App") {
              break;
            }
          }
        }
      }
    }
    return thisRun;
  }
);

export const getStakeholderActions = createSelector(
  [getStakeholderActionList],
  (actionList) => {
    const actions = [];
    let keys = [];
    if (actionList) {
      keys = Object.keys(actionList);
      for (let i = 0; i < keys.length; i++) {
        const thisAction = actionList[i];

        if (thisAction !== undefined) {
          actions.push(thisAction.action);
        }
      }
    }
    return actions;
  }
);

export const getWorkerNameAndImgPng = createSelector(getWorkers, (workers) => {
  const returnList = [];
  for (let w = 0, l = workers.length; w < l; w++) {
    const worker = workers[w];
    const ret = {
      workerId: worker.WorkerId,
      workerName: worker.WorkerName,
      workerPng: `${worker.WorkerName.replace(/\s+/g, "")}.png`
    };
    returnList.push(ret);
  }
  return returnList;
});

export const getIndividualRecognition = createSelector(
  getRewardList,
  (recognition) => {
    const returnList = [];
    for (let r = 0, l = recognition.length; r < l; r++) {
      const rec = recognition[r];
      if (rec.Item2 === "INDV" && rec.Item3 !== "None") {
        const ret = {
          id: rec.Item1,
          recognition: rec.Item3
        };
        returnList.push(ret);
      }
    }
    return returnList;
  }
);

export const getTeamRecognition = createSelector(
  getRewardList,
  (recognition) => {
    const returnList = [];
    for (let r = 0, l = recognition.length; r < l; r++) {
      const rec = recognition[r];
      if (rec.Item2.toUpperCase() === "TEAM" && rec.Item3 !== "None") {
        const ret = {
          id: rec.Item1,
          recognition: rec.Item3
        };
        returnList.push(ret);
      }
    }
    return returnList;
  }
);
