import { CardHeader as ReactStrapCardHeader } from "reactstrap";
import styled from "styled-components";

const CardHeader = styled(ReactStrapCardHeader)`
  display: flex;
  align-items: center;
  fontSize: 14;
  .pull-right {
    margin-left: auto;
  }

  .btn {
    margin-top: 0;
  }
`;

export { CardHeader };
