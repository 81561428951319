import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import ReviewLicense from "components/ReviewLicense";
import { ClassroomContainer } from "routes/Classroom";
import TestPanel from "./components/testing/TestPanel";
import SettingsInfo from "./components/SettingsInfo";

class Settings extends React.Component {
  constructor() {
    super();

    this.state = {
      reviewLicenseOpen: true,
      classroomOpen: true
    };
  }

  render() {
    return (
      <>
        <Row>
          <Col lg="12">
            <SettingsInfo />
            </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card className="component-Classroom">
              <CardHeader>Review/Change Classrooms</CardHeader>
              <CardBody>
                <ClassroomContainer />
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <CardHeader>Review Licence</CardHeader>
              <CardBody>
                <ReviewLicense />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <TestPanel />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
