import React from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  MEETING_INTERACTION_MODAL,
  CONFERENCE_INTERACTION_MODAL,
  RECOGNITION_INTERACTION_MODAL,
  STAKEHOLDER_INTERACTION_MODAL,
  TRAINING_INTERACTION_MODAL
} from 'routes/Modal/ModalTypes';
import { openModal } from 'redux/Modal/ModalModule';
import {
  getOnProjectWorkers,
  getStakeholderListPlusAll
} from 'redux/Project/selectors';


class InteractionMenu extends React.Component {

  constructor(props) {
    super(props);
    const startdate = new Date('2001');
    startdate.setDate(startdate.getDate() + 1);
    startdate.setHours('08');
    startdate.setMinutes('00');
    startdate.setSeconds('00');
    // default duration of conference is 1 hour
    const enddateConf = new Date('2001');
    enddateConf.setDate(enddateConf.getDate() + 1);
    enddateConf.setHours('09');
    enddateConf.setMinutes('00');
    enddateConf.setSeconds('00');
    // default duration of meeting is 30 minutes
    const enddateMtg = new Date('2001');
    enddateMtg.setDate(enddateMtg.getDate() + 1);
    enddateMtg.setHours('08');
    enddateMtg.setMinutes('30');
    enddateMtg.setSeconds('00');

    const initialWorkerId =
      this.props.onProjectWorkers.length > 0
        ? this.props.onProjectWorkers[0].id
        : 0;
    const initialWorkerName =
      this.props.onProjectWorkers.length > 0
        ? this.props.onProjectWorkers[0].name
        : '';
    this.state = {
      dropdownOpen: false,
      placeholder: false,
      newConferenceItem: {
        Id: 0,
        RunId: this.props.runId,
        DayOfWeek: this.props.selectedDay,
        WorkerId: initialWorkerId,
        WorkerName: initialWorkerName,
        Topic: '',
        TopicNumber: 0,
        DurationId: 0,
        StartTime: '8:00am',
        FeedbackId: 0,
        Appropriateness: false,
        Commit: 0,
        StartDate: startdate,
        EndDate: enddateConf
      },
      newMeetingItem: {
        Id: 0,
        RunId: this.props.runId,
        DayOfWeek: this.props.selectedDay,
        WorkerId: initialWorkerId,
        WorkerName: initialWorkerName,
        TopicNumber: 0,
        Topic: 'Requirements',
        DurationId: 1,
        StartTime: '8:00am',
        FeedbackId: 0,
        IsRecurring: false,
        Commit: 0,
        StartDate: startdate,
        EndDate: enddateMtg
      },
      newRecognitionItem: {
        ID: 0,
        RunId: this.props.runId,
        RewardType: '',
        RewardId: 0,
        RewardName: '',
        DayOfWeek: this.props.selectedDay,
        WorkerId: initialWorkerId,
        WorkerName: initialWorkerName,
        Commit: 0
      },
      newStakeholderItem: {
        Id: 0,
        RunId: this.props.runId,
        DayOfWeek: this.props.selectedDay,
        StakeholderId: (this.props.stakeholderListPlusAll && this.props.stakeholderListPlusAll.length > 0) ?
          this.props.stakeholderListPlusAll[0].id : 0,
        InteractionId: 2,
        Interaction: 'Status Report', // default to status report
        CourseId: 0,
        WorkerId: 0,
        WorkerName: '',
        StartTime: '8:00 am',
        Duration: 0,
        Commit: 0
      },
      newTrainingItem: {
        Id: 0,
        RunId: this.props.runId,
        DayOfWeek: this.props.selectedDay,
        ClassId: (this.props.trainingList && this.props.trainingList.length > 0) ?
        this.props.trainingList[0].ClassId : 0,
        ClassName: (this.props.trainingList && this.props.trainingList.length > 0) ?
        this.props.trainingList[0].ClassName : '',
        ClassStartDay: (this.props.trainingList && this.props.trainingList.length > 0) ? this.props.trainingList[0].ClassStartDay  : "",
        WorkerId: initialWorkerId,
        WorkerName: initialWorkerName,
        Commit: 0,
        TrainingType: (this.props.trainingList && this.props.trainingList.length > 0) ? this.props.trainingList[0].TrainingType  : 0
      }
    };
    this.showAddConferenceModal = this.showAddConferenceModal.bind(this);
    this.showAddMeetingModal = this.showAddMeetingModal.bind(this);
    this.showAddIndividualRecognitionModal = this.showAddIndividualRecognitionModal.bind(
      this
    );
    this.showAddTeamRecognitionModal = this.showAddTeamRecognitionModal.bind(
      this
    );
    this.showAddTrainingModal = this.showAddTrainingModal.bind(this);
    this.showAddStakeholderActionModal = this.showAddStakeholderActionModal.bind(
      this
    );
    this.showAddStakeholderTrainingModal = this.showAddStakeholderTrainingModal.bind(
      this
    );
  }

  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
   MAPDISPATCHTOPROPS for the opoenModal function below * */
  // show the add conferenceItem modal

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  showAddConferenceModal = () => {
    this.props.showConferenceInteractionModal(
      this.state.newConferenceItem,
      this.props.selectedDay,
      true
    );
  };

  showAddMeetingModal = () => {
    this.props.showMeetingInteractionModal(
      this.state.newMeetingItem,
      this.props.selectedDay,
      true
    );
  };

  showAddIndividualRecognitionModal = () => {
    const item = Object.assign({}, this.state.newRecognitionItem);
    item.RewardType = 'INDV';
    item.RewardId = 1
    item.RewardName = '$1000 Cash'
    this.props.showRecognitionInteractionModal(
      item,
      this.props.selectedDay,
      'INDV',
      true
    );
  };

  showAddTeamRecognitionModal = () => {
    const item = Object.assign({}, this.state.newRecognitionItem);
    item.RewardType = 'TEAM';
    item.WorkerId = 0;
    item.WorkerName = 'Everyone';
    item.RewardId = 1
    item.RewardName = 'Weekend Getaway'

    this.props.showRecognitionInteractionModal(
      item,
      this.props.selectedDay,
      'TEAM',
      true
    );
  };

  showAddTrainingModal = () => {
    const item = Object.assign({}, this.state.newTrainingItem);
    this.props.showTrainingInteractionModal(item, this.props.selectedDay, true);
  };

  showAddStakeholderActionModal = () => {
    const item = Object.assign({}, this.state.newStakeholderItem);
    this.props.showStakeholderInteractionModal(
      item,
      this.props.selectedDay,
      false,
      true
    );
  };

  showAddStakeholderTrainingModal = () => {
    const item = Object.assign({}, this.state.newStakeholderItem);

    item.Interaction = 'Education';
    item.StakeholderId = 1; // punting for now, needs to be the id of stakeholder who gets training.
    item.WorkerId = 0;
    item.CourseId = 1;
    item.InteractionId = 4; // default to first item
    this.props.showStakeholderInteractionModal(
      item,
      this.props.selectedDay,
      true,
      true
    );
  };
  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} size='sm'>
        <DropdownToggle caret={false} color='primary' outline>
          <i className='fa fa-plus fa-fw' />
        </DropdownToggle>
        <DropdownMenu right className={this.state.dropdownOpen ? 'show' : ''}>
          {/* <DropdownItem header tag='div' className='text-center'><strong>Account</strong></DropdownItem> */}
          <DropdownItem eventkey='1' onClick={this.showAddConferenceModal}>
            Individual Conference
          </DropdownItem>
          <DropdownItem eventkey='2' onClick={this.showAddMeetingModal}>
            Team Meeting
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            eventkey='3'
            onClick={this.showAddIndividualRecognitionModal}
          >
            Individual Reward
          </DropdownItem>
          <DropdownItem eventkey='4' onClick={this.showAddTeamRecognitionModal}>
            Team Reward
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem eventkey='5' onClick={this.showAddTrainingModal}>
            Training
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            eventkey='3'
            onClick={this.showAddStakeholderActionModal}
          >
            Stakeholder Action
          </DropdownItem>
          <DropdownItem
            eventkey='4'
            onClick={this.showAddStakeholderTrainingModal}
          >
            Stakeholder Training
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => ({
  onProjectWorkers: getOnProjectWorkers(state),
  stakeholderList: state.project.stakeholderList,
  stakeholderListPlusAll: getStakeholderListPlusAll(state),
  trainingList: state.project.trainingList,
  runId: state.project.runId,
  daysOfWeek: state.project.daysOfWeek
});

const mapDispatchToProps = dispatch => ({
  showMeetingInteractionModal: (meetingItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        MEETING_INTERACTION_MODAL,
        {
          meetingItem,
          selectedDay,
          isAdd
        },
        'modal-lg'
      )
    ),
  showConferenceInteractionModal: (conferenceItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        CONFERENCE_INTERACTION_MODAL,
        {
          conferenceItem,
          selectedDay,
          isAdd
        },
        'modal-lg'
      )
    ),
  showRecognitionInteractionModal: (
    recognitionItem,
    selectedDay,
    recognitionType,
    isAdd
  ) =>
    dispatch(
      openModal(
        RECOGNITION_INTERACTION_MODAL,
        {
          recognitionItem,
          selectedDay,
          recognitionType,
          isAdd
        },
        'modal-lg'
      )
    ),
  showStakeholderInteractionModal: (
    stakeholderItem,
    selectedDay,
    isTraining,
    isAdd
  ) =>
    dispatch(
      openModal(
        STAKEHOLDER_INTERACTION_MODAL,
        {
          stakeholderItem,
          selectedDay,
          isTraining,
          isAdd
        },
        'modal-lg'
      )
    ),
  showTrainingInteractionModal: (trainingItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        TRAINING_INTERACTION_MODAL,
        {
          trainingItem,
          selectedDay,
          isAdd
        },
        'modal-lg'
      )
    )
});

export const InteractionMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractionMenu);
