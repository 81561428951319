import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setExecSeqFilterValue,
  fetchStudents,
  fetchClassesByInstructor,
  setSelectedClass,
  fetchStudentInfo
} from "redux/Instructor/thunks";

import StudentsTable from "./components/StudentsTable";

class StudentManagement extends Component {

   state = {
      loading: false,
      execSeqFilterVal: ""
    };
  
    setExecSeqFilterVal = (value) => {
    this.setState({
      execSeqFilterVal: value
    });
  };

  componentDidMount = () => {
    if (
      this.props.instructorClasses &&
      this.props.instructorClasses.length < 1
    ) {
      this.props.fetchClassesByInstructor(this.props.userId);
    }
  };

  fetchStudentsInClass = (classroom) => {
    this.setState({ loading: true });
    this.props.setSelectedClass(classroom).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    if (
      !this.props.instructorClasses ||
      this.props.instructorClasses.length < 1
    ) {
      return <div>There are no classes for this instructor</div>;
    }
    if (!this.props.selectedClass || this.props.selectedClass === {}) {
      return <div>No selected classroom yet</div>
    }
    return (
      <StudentsTable
        toggleTab={this.props.toggleTab}
        students={this.props.studentList}
        instructorClasses={this.props.instructorClasses}
        fetchStudents={this.props.fetchStudents}
        selectedClass={this.props.selectedClass}
        fetchStudentInfo={this.props.fetchStudentInfo}
        loading={this.props.loading}
        studentHasNoValidLicense={this.props.studentHasNoValidLicense}
        execSeqFilterVal={this.props.execSeqFilterVal}
        setExecSeqFilterVal={this.props.setExecSeqFilterValue}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  execSeqFilterVal: state.instructor.execSeqFilterValue,
  userId: state.auth.userId,
  studentList: state.instructor.studentList,
  instructorClasses: state.instructor.instructorClasses,
  selectedClass: state.instructor.selectedClass,
  studentHasNoValidLicense: state.instructor.studentHasNoValidLicense,
  loading: state.instructor.loading
});

const mapDispatchToProps = (dispatch) => ({
  setExecSeqFilterValue: (execSeq) => dispatch(setExecSeqFilterValue(execSeq)),
  fetchStudents: (classroomId) => dispatch(fetchStudents(classroomId)),
  fetchClassesByInstructor: (instructorId) =>
    dispatch(fetchClassesByInstructor(instructorId)),
  setSelectedClass: (classId) => dispatch(setSelectedClass(classId)),
  fetchStudentInfo: (studentData) => dispatch(fetchStudentInfo(studentData))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentManagement);
