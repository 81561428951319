import React from "react";

export const ScheduleControl = () => {
  return (
    <>
      <p>
        PPI is a key performance indicator to determine if your schedule and
        cost are going to get under control. Schedule and cost move much slower
        than PPI. If you make any expenditure, PPI will quickly change. If your
        PPI is trending down Schedule and cost will follow later. To keep from
        overspending your cost and schedule budget you must drive PPI to one or
        below. Thus, you need to take actions, which will cause your project
        percent complete to increase faster than your percent of budget spent.
        To do this you need to get more earned value for your expenditures.
      </p>
      <p>
        Cutting your weekly project costs will lower PPI. However, be careful
        because if you are investing by spending money on training then you
        should get pay-back in earned value later and PPI will correct itself.
        This all assumes you are doing other things right.
      </p>
      <p>
        Relative to doing things right it will also help to look at what you
        were doing when PPI was going down. Sometimes the answers we need are in
        what is working instead of what is not working.
      </p>
      <p>
        Keep your plans up to date so you don’t forget to take some needed
        action. Keeping all your plans current is key to having a successful
        project. Doing this will keep you from being reactive.{" "}
      </p>
      <p>
        An improvement in earned value can also come about from placing the best
        people on the project tasks. Make sure that when a team member needs
        skill improvement that he or she gets the needed training. Make sure
        that when people are paired together that they are compatible. Recognize
        good work and reward it. All of this will improve the energy and morale
        of your team. As the team’s energy and morale go up, earned value will
        go up.
      </p>
      <p>Try to continually build and maintain a high-performance team. </p>
      <p>
        High performance teams have:
        <ol>
          <li> a clear and elevating goal </li>
          <li> a results driven structure</li>
          <li> a collaborative climate</li>
          <li> standards and work processes focused on excellence</li>
          <li> external support and recognition</li>
          <li> a unified commitment</li>
        </ol>
      </p>
      <p>
        Motivation and unified commitment are results of the six characteristics
        above and being a principled leader. It all starts with being a
        principled leader. A leader who makes decisions that make sense. A
        leader who lets the team know what he or she believes in and who "walks
        their talk".
      </p>
      <footer>
        <p>ID #1070</p>
      </footer>
    </>
  );
};
