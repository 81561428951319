import React from "react";
import accounting from "accounting";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import RenderWeekColumns from "./RenderWeekColumns";
import { Card } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";
const LostHoursTable = ({ maxWeek, lostHoursContent, options }) => {
  const lostHoursHeader = () => {
    const headers = [];
    headers.push(
      <th className="text-left" key="weeks">
        Weeks
      </th>
    );

    for (let i = 1; i <= maxWeek; i += 1) {
      headers.push(
        <th className="text-center" key={`W${i}`}>
          {i}
        </th>
      );
    }
    headers.push(
      <th className="text-center" key="resvth">
        Non-task Related Hours - Charged to Risks/Inefficiencies
      </th>
    );
    headers.push(
      <th className="text-center" key="tothrnot">
        Resource Hours Not Working on Task
      </th>
    );
    headers.push(
      <th className="text-center" key="totcostnot">
        Resource Costs Not Working on Task
      </th>
    );

    return headers;
  };

  const footer = () => {
    const footers = [];
    const totalCosts = lostHoursContent.reduce(function (a, b) {
      return a + b["totalCosts"];
    }, 0);
    const totalHours = lostHoursContent.reduce(function (a, b) {
      return a + b["totalHours"];
    }, 0);

    const totalResHours = lostHoursContent.reduce(function (a, b) {
      return a + b["resHours"];
    }, 0);

    footers.push(
      <td className="text-right" key="weeksfooter">
        <strong>Totals:</strong>
      </td>
    );
    for (let i = 1; i <= maxWeek; i += 1) {
      const weeklyHours = lostHoursContent.reduce(function (a, b) {
        return a + b[i.toString()];
      }, 0);
      footers.push(
        <td className="text-right" key={`W${i}footer`}>
          <strong>{weeklyHours}</strong>
        </td>
      );
    }
    footers.push(
      <td className="text-right" key="resfooter">
        <strong>{totalResHours}</strong>
      </td>
    );

    footers.push(
      <td className="text-right" key="tothrfooter">
        <strong>{totalHours}</strong>
      </td>
    );
    footers.push(
      <td className="text-right" key="totcostnot">
        <strong>{accounting.formatMoney(totalCosts)}</strong>
      </td>
    );
    return footers;
  };
  return (
    <Card>
      <CardHeaderBold>Non-Task Related Labor Hours/Costs</CardHeaderBold>
      <NoMaxCardBody>
        <StyledTable bordered hover>
          <thead>
            <tr>{lostHoursHeader()}</tr>
          </thead>
          <tbody>
            {lostHoursContent.map((data) => {
              return (
                <tr key={data.event}>
                  <td key={`${data.event}-td`} className="text-left">
                    {data.description}
                  </td>
                  <RenderWeekColumns
                    data={data}
                    keyTag="lost-hour"
                    maxWeek={maxWeek}
                  />
                  <td className="text-right" key={`${data.event}-rh`}>
                    {data.resHours}
                  </td>
                  <td className="text-right" key={`${data.event}-th`}>
                    {data.totalHours}
                  </td>
                  <td className="text-right" key={`${data.event}-tc`}>
                    {accounting.formatMoney(data.totalCosts, options)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>{footer()}</tr>
          </tfoot>
        </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default LostHoursTable;
