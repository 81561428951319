import React from "react";

export const IndvReward = (props) => {
  return (
    <div className="content">
      <p>
        {props.properties.worker}&nbsp;
        <small>was rewarded with: {props.properties.reward}</small>
      </p>
    </div>
  );
};
