import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTrash,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";

import imageURLs from "./images/imageURLs.js";

const StaffingHelp = () => (
  <div>
    <h3>Staffing Info:</h3>

    <h4>Context and Resources for Staffing:</h4>

    <p>
      Successful project managers make efficient use of their resources. They
      make sure their resources are assigned to active tasks and they update
      their resource plan based on actual project status to minimize staffing
      resources too early or too late to the project.
    </p>
    <p>
      Successful staffing requires your resource decisions for the week be in
      sync with the actual project/task status. In sync means current resources
      are assigned to active, or soon to be active tasks. If your resource plan
      is out of sync, it may result in inefficient use of your resources. The
      Weekly Staffing Decisions panel on this page is built using your current
      resource plan each week. One key difference is the task assignment for the
      current week, which will always be the active task assignment (task that
      they were working on at the end of the previous week) for each active
      resource. This Weekly Staffing Decisions panel provides the ability for
      you to:
    </p>

    <ul>
      <li>
        Update the task you want a resource to work on (make sure it is an
        active task and any predecessor tasks have completed)
      </li>

      <li>See the task priorities (sequence) for each resource</li>

      <li>Immediately release a resource from the project</li>

      <li>Request a resource to work overtime</li>
    </ul>

    <p>
      A copy of your Resource Plan is provided on the Staffing screen to
      facilitate required resource changes due to late or early task starts, the
      addition of new tasks, or a need to add a resource. Remember that future
      resources need to be staffed when you are two weeks from needing them on
      your project. Current project/task status can impact the timing of these
      staffing requests. A project ahead of schedule might require staffing a
      resource earlier than planned and a project behind schedule might require
      pushing the staffing of a resource back to avoid the resource arriving on
      the project before their task can start.
    </p>

    <p>
      The following will be useful in deciding if you need to make changes, and
      in making changes to your resource plan for the week:
    </p>

    <ul>
      <li>Weekly Staffing Decisions</li>
      <li>Resource Plan </li>

      <li> Network Diagram – specifically which tasks are currently active</li>

      <li>
        {" "}
        Gantt chart – specifically project/task status and which tasks are
        currently active
      </li>

      <li>
        {" "}
        Task Descriptions – accessible via the Network Diagram or the Gantt
        Chart (click on task name)
      </li>
      <li>Project Budget</li>
    </ul>

    <h4>
      <u>Making Weekly Staffing Decisions:</u>
    </h4>

    <p>
      <strong>Warning</strong> - Do not just follow your resource plan each week
      as your staffing decisions for the week may need to be updated based on
      the current project status. As an example, if a task is late finishing,
      your resource plan may have resources moving to a successor task for this
      week, yet the resources are still assigned to the current task because it
      is not completed and must be completed before work can start on the
      successor task(s). In this situation leave the resources on their
      currently assigned tasks.
    </p>

    <h4>Weekly Staffing Decisions</h4>
    <img
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      src={imageURLs.StaffingDecisions}
      alt="staffing"
    />
    <p>Each week:</p>
    <ol>
      <li>
        Confirm the currently active tasks. Tasks with all predecessor tasks
        completed are the only active tasks. The Network Diagram delineates
        completed tasks with a big X inside the task box. The Gantt Chart
        indicates a completed task with a filled solid middle status bar – no
        white left in the middle bar. Again, all predecessor tasks must be
        completed before the successor task can be started. These dependencies
        are not always clear on the Gantt Chart, so use the Network Diagram to
        confirm that a task is active (all predecessor tasks are completed).
      </li>

      <li>
        Based on task status and available resources, you can override the
        starting task assignment (Priority 1) for a resource. Click on the
        Priority 1 task number to enable the dropdown selection. Be aware that
        the dropdown lists all tasks not completed. Hint: It is usually
        inefficient to assign resources to a task they can’t work on or a task
        that doesn’t require their skill set.
      </li>

      <li>
        To request a resource work overtime this week, click on the Overtime
        cell and select the number of overtime hours.
      </li>

      <li>
        To immediately release a resource from your project and back to the
        resource pool click on the Actions - button.
      </li>
    </ol>

    <h4>Resource Plan</h4>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      src={imageURLs.StaffingPlan}
      alt="staffing"
    />

    <p>
      For convenience, your Resource Plan is duplicated on the Staffing Screen.
      Editing this plan is very similar to the Resource Plan on the Planning
      screen. As required make resource plan changes here due to late or early
      task starts, the addition of a new task, or a decision to add a resource
      not originally planned.
    </p>
    <ol>
      <li>
        As necessary, update your current staffing and task assignments in your
        resource plan based on project task status. Shift individual resource
        plan assignments one week to the left (ahead of schedule) or one week to
        the right (behind schedule) to:
        <ul>
          <li>Align current resources to active tasks, or</li>
          <li>Staff or release resources earlier or later to your project</li>
        </ul>
        <p>
          Shift individual resource plans forward or backward a week to align
          the staffing and task plan with the current status of your project. To
          do this, click on the “arrow” icons, ({" "}
          <FontAwesomeIcon icon={faAngleDoubleRight} />,{" "}
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
          ), if available, in the Action cell for that resource.
        </p>
        <p>
          <strong>Helpful Hint</strong> – if a late-finishing task has 3 or less
          days remaining to complete, do not shift resource task assignments to
          the right (behind schedule). If a late-finishing task has 4 or more
          days remaining to complete, shift resource task assignments to the
          right (behind schedule). If an early completing task finished early by
          3 or less days, do not shift resource task assignments to the left
          (ahead of schedule). If an early completing task finished early by 4
          or more days, shift resource task assignments to the left (ahead of
          schedule).
        </p>
      </li>

      <li>
        If a new task is added to your project, you must plan the resources for
        the new task. Use the “Add Resource” icon in the panel header to view
        available resources and their profiles. Click on the “Add” button to add
        them to the Resource Plan. You will be asked to select their starting
        week on the project, just like in planning. Complete their task
        assignments by week and remember to plan their Release from the project
        when you no longer need their skill.
      </li>

      <li>
        If you want to add a resource to an existing task, use the “Add
        Resource” icon in the panel header to view available resources and their
        profiles. Click on the “Add” button to add them to the Resource Plan.
        You will be asked to select their starting week on the project, just
        like in planning. Complete their task assignments by week and remember
        to plan their Release from the project when you no longer need their
        skill.
      </li>

      <li>
        If you need begin the onboarding of a resource earlier than planned,
        update the Staff actions in the appropriate week cell in the resource
        plan.
      </li>

      <li>
        Remove a resource from the resource plan using the Action cell and
        clicking on the “trash can” icon, <FontAwesomeIcon icon={faTrash} />.
      </li>
    </ol>

    <h4>Checking your Staffing against your Plan</h4>

    <p>
      There is a "Check Staffing and Resource Plan differences" button the
      staffing decisions panel as shown below.{" "}
    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      src={imageURLs.CheckStaffingButton}
      alt="staffing"
    />

    <p>
      {" "}
      Use this feature to validate your current staffing decisions to make sure
      you have your workers on active tasks and your plan matches the current
      state of your project. A results panel will appear once the checks are
      finished.
    </p>

    <Row style={{ marginBottom: "15px" }}>
      <Col lg="6">
        If everything passes, the resuts panel will be empty of any warnings or
        errors.
      </Col>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={imageURLs.CheckPassed}
          alt="staffing"
        />
      </Col>
    </Row>
    <Row>
      <Col lg="6">
        Otherwise, you will see warning messages like those on the left
        indicating issues you may want to address even thought the checks
        passed.
      </Col>

      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={imageURLs.CheckFailed}
          alt="staffing"
        />
      </Col>
    </Row>

    <p>You can re-check the plan against the staffing as needed.</p>
  </div>
);

export default StaffingHelp;
