import React, { Component, Fragment } from "react";
import {
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  Button,
  Badge,
  UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
import { Offline, Online } from "react-detect-offline";
import { helpCheckBool } from "redux/Authentication/selectors";
import { getUnreadEmailCount } from "redux/Project/selectors";
import { openModal } from "redux/Modal/ModalModule";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faListAlt
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "components/PageHeader/PageHeader";

import {
  REFERENCE_MODAL,
  HELP_DOCUMENTATION_MODAL,
  EMAIL_MODAL,
  ORIENTATION_MODAL
} from "routes/Modal/ModalTypes";

import { logout, updateCheckListItem } from "redux/Authentication/thunks";
import BuildVersion from "components/BuildVersion";

import "./Header.css";

// const tooltipReferenceGuide = <Tooltip id='t1'>The Project Reference Guide</Tooltip>;
// const tooltipHelp = <Tooltip id='t2'>Help Documentation</Tooltip>;
// const tooltipEmail = <Tooltip id='t3'>Email</Tooltip>;
// const tooltipNotifications = <Tooltip id='t4'>Notification History</Tooltip>;

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSidebar: false
    };
  }
  componentDidMount() {

    document.body.classList.add("aside-menu-hidden");
    
  }

  sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  sidebarMinimize = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  };

  mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  asideToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  };

  showReferences = (evt) => {
    evt.preventDefault();
    this.props.showReferences();
  };

  showHelp = () => {
    if (this.props.location.pathname === "/welcome") {
      if (
        // new user  on welcome screen, update checklist
        this.props.helpCheckBool === false
      ) {
        for (let i = 0, l = this.props.auth.checkListItems.length; i < l; i++) {
          if (
            this.props.auth.checkListItems[i].ControlNumber === 4 &&
            this.props.auth.checkListItems[i].ControlValue === 0
          ) {
            this.props.updateCheckListItem(
              this.props.auth.userId,
              4,
              "VIEW_HELP",
              true
            );
            break;
          }
        }
      }
    }
    this.props.showHelpModal();
  };

  showOrientationModal = () => {
    this.props.showOrientationModal();
  };

  showEmail = () => {
    this.props.showEmailModal();
  };

  render() {
    return (
      <header className="app-header navbar" tourstop="navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          {this.state.showSidebar ? (
            <FontAwesomeIcon icon={faChevronCircleLeft} />
          ) : (
            <FontAwesomeIcon icon={faChevronCircleRight} />
          )}
        </NavbarToggler>
        <NavbarBrand href="#" tag="div" />
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          {this.state.showSidebar ? (
            <FontAwesomeIcon icon={faChevronCircleLeft} />
          ) : (
            <FontAwesomeIcon icon={faChevronCircleRight} />
          )}
        </NavbarToggler>
        <span>
          <BuildVersion version="0.0.0.0" /> <br />
          <small>
            {" "}
            <Fragment>
              <Online>Network connected</Online>
              {/* <Offline>Network Disconnected!</Offline> */}
            </Fragment>
          </small>
        </span>


        <Nav className="ml-auto mr-3" navbar>
          {/* <NavItem className='d-md-down-none'>
            <NetworkConnectionIndicator connected={this.props.connected} />
          </NavItem> */}

          <NavItem>
            <UncontrolledTooltip
              id="notett"
              placement="top"
              target="noteLink"
              delay={{ hide: 900 }}
            >
              Notification History
            </UncontrolledTooltip>
            <Link
              id="noteLink"
              className="btn btn-link"
              to="/notifications"
              href="/notifications"
            >
              <i className="fa fa-bell" />
            </Link>
          </NavItem>

          {this.props.runId > 0 && this.props.auth.userType !== "Instructor" ? (
            <NavItem>
              <UncontrolledTooltip
                id="emailtt"
                placement="top"
                target="emailLink"
                trigger="hover"
              >
                Email
              </UncontrolledTooltip>
              <Button
                id="emailLink"
                color="link"
                className="count-info"
                onClick={this.showEmail}
              >
                <i className="fa fa-envelope" />
                <Badge color="primary">
                  {this.props.unreadEmailCount > 0
                    ? this.props.unreadEmailCount
                    : ""}
                </Badge>
              </Button>
            </NavItem>
          ) : (
            <span />
          )}

          <NavItem>
            <UncontrolledTooltip
              id="projtt"
              placement="top"
              target="projLink"
              trigger="hover"
            >
              The Project Reference Guide
            </UncontrolledTooltip>
            <Button id="projLink" color="link" onClick={this.showReferences}>
              <i className="fa fa-book" />
            </Button>
          </NavItem>
          <NavItem>
            <UncontrolledTooltip
              id="helptt"
              placement="top"
              target="helpLink"
              trigger="hover"
            >
              Help Documentation
            </UncontrolledTooltip>
            <Button id="helpLink" color="link" onClick={this.showHelp}>
              <i className="fa fa-life-ring" />
            </Button>
          </NavItem>
          {this.props.auth.productId !== 50 && (
            <NavItem>
              <UncontrolledTooltip
                id="ortt"
                placement="top"
                target="orientationLink"
                trigger="hover"
              >
                Stakeholder Orientation Guide
              </UncontrolledTooltip>
              <Button
                id="orientationLink"
                color="link"
                onClick={this.showOrientationModal}
              >
                <i className="fa fa-users" />
              </Button>
            </NavItem>
          )}
          <NavItem tourstop="cl1">
            <UncontrolledTooltip
              id="checkt"
              placement="top"
              target="checklistLink"
              trigger="hover"
            >
              Project Checklist
            </UncontrolledTooltip>
            <NavbarToggler
              id="checklistLink"
              className="d-md-down-none"
              type="button"
              onClick={this.asideToggle}
            >
              <FontAwesomeIcon icon={faListAlt} />
            </NavbarToggler>
          </NavItem>
          {this.props.auth.userType !== "Instructor" ? (
            <NavItem>{this.props.name}</NavItem>
          ) : (
            <span />
          )}
          {this.props.auth.userType === "Instructor" ? (
            <NavItem>{this.props.auth.username}</NavItem>
          ) : (
            <span />
          )}
          <NavItem className="d-md-down-none">
            <NavLink onClick={this.props.logout} tag="button">
              <i className="fa fa-lock" /> Logout
            </NavLink>
          </NavItem>
          <NavItem>

            <b>Exec#: {this.props.runId}</b>

          </NavItem>
        </Nav>

      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  helpCheckBool: helpCheckBool(state),
  unreadEmailCount: getUnreadEmailCount(state),
  auth: state.auth,
  runId: state.project.runId,
  displayProjectWeek: state.project.displayProjectWeek,
  projectedFinishWeek: state.project.projectedFinishWeek

});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  showReferences: () => dispatch(openModal(REFERENCE_MODAL, {}, "modal-wide")),
  showHelpModal: () =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, {}, "modal-wide")),
  showOrientationModal: () =>
    dispatch(openModal(ORIENTATION_MODAL, {}, "modal-wide")),
  showEmailModal: () => dispatch(openModal(EMAIL_MODAL, {}, "modal-1100")),
  updateCheckListItem: (userId, controlNumber, controlType, isSet) =>
    dispatch(updateCheckListItem(userId, controlNumber, controlType, isSet))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
