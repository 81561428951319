import React from "react";
import { Link } from "react-router-dom";
import Delay from "react-delay";
import { Col } from "reactstrap";
import CheckLogRow from "components/styled/CheckLogRow";

const CheckLog = props => {
	let itemKey = 2000;
	let delay = 0;
	return (
		<CheckLogRow>
			<Col>
				<ul>
					{props.staffing === null || props.staffing === undefined ? (
						<span />
					) : (
						props.staffing.map(row => (
							<Delay key={(itemKey += 1)} wait={(delay += 150)}>
								<li key={(itemKey += 1)} className={row.className}>
									<p>{row.message}</p>
								</li>
							</Delay>
						))
					)}
					{props.overall === {} || props.overall === null ? (
						<span />
					) : (
						<Delay wait={0}>
							<li>
								<h4>Analysis Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li key={(itemKey += 1)} className={props.overall.className}>
										<p>{props.overall.message}</p>
									</li>
								</ul>
							</li>
						</Delay>
					)}
					{props.training === {} || props.training === null ? (
						<span />
					) : (
						<Delay wait={150}>
							<li>
								<h4>Training Plan Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li key={(itemKey += 1)} className={props.training.className}>
										<p>{props.training.message}</p>
										<Link
											href="/planning/training"
											to="/planning/training"
											className="btn btn-outline-secondary"
										>
											Edit Training Plan
										</Link>
										<br /> <br />
									</li>
								</ul>
							</li>
						</Delay>
					)}

					{props.stakeholder === {} || props.stakeholder === null ? (
						<span />
					) : (
						<Delay wait={300}>
							<li>
								<h4>Stakeholder Plan Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li
										key={(itemKey += 1)}
										className={props.stakeholder.className}
									>
										<p>{props.stakeholder.message}</p>
										<Link
											href="/planning/stakeholder"
											to="/planning/stakeholder"
											className="btn btn-outline-secondary"
										>
											Edit Stakeholder Plan
										</Link>
									</li>
								</ul>
							</li>
						</Delay>
					)}
				</ul>
			</Col>
			<Col>
				<ul>
					{props.risk === {} || props.risk === null ? (
						<span />
					) : (
						<Delay wait={450}>
							<li>
								<h4>Risk/Opportunities Plan Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li key={(itemKey += 1)} className={props.risk.className}>
										<p>{props.risk.message}</p>
										<Link
											href="/planning/risk"
											to="/planning/risk"
											className="btn btn-outline-secondary"
										>
											Edit Risk/Opportunities Plan
										</Link>
										<br /> <br />
									</li>
								</ul>
							</li>
						</Delay>
					)}
					{props.recognition === {} || props.recognition === null ? (
						<span />
					) : (
						<Delay wait={600}>
							<li>
								<h4>Recognition Plan Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li
										key={(itemKey += 1)}
										className={props.recognition.className}
									>
										<p>{props.recognition.message}</p>
										<Link
											href="/planning/recognition"
											to="/planning/recognition"
											className="btn btn-outline-secondary"
										>
											Edit Recognition Plan
										</Link>
										<br /> <br />
									</li>
								</ul>
							</li>
						</Delay>
					)}
					{props.resource === {} || props.resource === null ? (
						<span />
					) : (
						<Delay wait={750}>
							<li>
								<h4>Resource Plan Checks</h4>
								<ul className="results-list" key={(itemKey += 1)}>
									<li key={(itemKey += 1)} className={props.resource.className}>
										<p>{props.resource.message}</p>
										<Link
											href="/planning/resource"
											to="/planning/resource"
											className="btn btn-outline-secondary"
										>
											Edit Resource Plan
										</Link>
									</li>
								</ul>
							</li>
						</Delay>
					)}
				</ul>
			</Col>
		</CheckLogRow>
	);
};

export default CheckLog;
