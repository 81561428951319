import React from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import ResourceImage from "components/ResourceImage";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faListAlt
} from "@fortawesome/free-regular-svg-icons";

import "./OrientationModal.css";

export class OrientationModal extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1"
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    let i = 0;
    const { productId } = this.props;

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              From the CEO
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Human Resources
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Project Office
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "4" })}
              onClick={() => {
                this.toggle("4");
              }}
            >
              COO
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "5" })}
              onClick={() => {
                this.toggle("5");
              }}
            >
              Assistant
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          className="tabs-orientation"
          activeTab={this.state.activeTab}
        >
          <TabPane key={(i += 1)} tabId="1">
            <Row>
              <Col md="3">
                <div className="avatar">
                  <div className="avatar-image">
                    <ResourceImage alt="O.M. Smith Avatar" png="OMSmith.png" />
                  </div>
                  <h4>O.M. Smith</h4>
                </div>
              </Col>
              <Col md="9">
                <h4>Welcome to {productId === 38 ? 'UniWorld' : 
                  productId === 43 ? 'UniWorld' : 
                  productId === 50 ? 'Northrop Grumman' : 
                  productId === 51 ? 'Northrop Grumman' : ''}, I am O.M. Smith, the CEO.
                </h4>
                <p>
                  I have put you in charge of managing this important project.
                  The purpose of this communication is to give you an idea of
                  where to start and an overview of your assignment. Michael
                  Russell, from Human Resources, and Louis Styles, from the
                  Project Office, will follow with additional information on
                  getting started and planning your project.
                </p>
                <p>
                  Let me be clear. Your objectives for scope, schedule, cost and
                  quality have been set; and, they are all attainable and
                  equally important. Do not fail to meet any of these
                  objectives. We have provided a recommended cost allocation of
                  your cost objective, but your final allocation and how you
                  spend money on the project is your decision.
                </p>
                <p>
                  Since you are new to {productId === 38 ? 'UniWorld' : 
                  productId === 43 ? 'UniWorld' : 
                  productId === 50 ? 'Northrop Grumman' : 
                  productId === 51 ? 'Northrop Grumman' : ''} and the family of Alliance
                  projects it would be wise for you to read:
                </p>
                
                  <ul>
                    <li>
                      Your “Welcome to the Project” memo from our COO, B. D.
                      Smith. You will find this memo in your {productId === 38 ? 'UniWorld' : 
                  productId === 43 ? 'UniWorld' : 
                  productId === 50 ? 'Northrop Grumman' : 
                  productId === 51 ? 'Northrop Grumman' : ''}
                       &nbsp;Communications Email inbox (<i className="fa fa-envelope" />{" "}
                      which you will find in the upper right hand corner once
                      you start your project.)
                    </li>
                    <li>
                      The Project Reference Guide Information (<i className="fa fa-book" />{" "}
                      in the upper right hand corner)
                    </li>
                    <li>
                      Help Documentation (<i className="fa fa-life-ring" /> in
                      the upper right hand corner)
                    </li>
                  </ul>
                <p>
               
                  Once you have familiarized yourself with this information you
                  will be ready to complete the planning phase of your project.
                  Once your planning is done, submit your plan for approval and
                  if approved, you will be given the ability to execute your
                  project. I will drop in from time to time as you execute the
                  project to make sure the objectives will be met.
                </p>
                <p>
                  We are all counting on you as our company's future depends on
                  the successful completion of this project.
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane key={(i += 1)} tabId="2">
            <Row>
              <Col md="3">
                <div className="avatar">
                  <div className="avatar-image">
                    <ResourceImage
                      alt="Michael Russell Avatar"
                      png="MichaelRussell.png"
                    />
                  </div>
                  <h4>Michael Russell</h4>
                </div>
              </Col>
              <Col md="9">
                <h4>
                  Hi, I’m Michael Russell, the Vice President of Human
                  Resources.
                </h4>
                <p>
                  O. M. Smith asked to give you a more detailed introduction to
                  starting the project. Once you finish these orientation panels
                  you will have access to:
                </p>
              
                  <ul>
                    <li>A Navigation Bar (top of the screen)</li>
                    <li>A Page Header (below the Navigation Bar)</li>
                    <li>
                      A Sidebar Menu (Left side – starting with the Planning
                      Overview screen) You can collapse or expand the Sidebar
                      Menu using the <i className="fa fa-chevron-left" /> in the
                      Navigation Bar
                    </li>
                    <li>
                      The Planning Overview screen which has three panels in the
                      main window:
                      <ul>
                        <li>
                          Take the Tour – click on the button to get a quick
                          tour of the User Interface
                        </li>
                        <li>
                          Budget – watch your allocated budget numbers here as
                          you build your plans
                        </li>
                        <li>
                          Gantt Chart – the project timetable which will be
                          useful in your planning
                        </li>
                      </ul>
                    </li>
                  </ul>
                
                  Remember to start by reading:
                  <ul>
                    <li>
                      Your “Welcome to the Project” memo from our COO, B. D.
                      Smith. You will find this memo in your {productId === 38 ? 'UniWorld' : 
                  productId === 43 ? 'UniWorld' : 
                  productId === 50 ? 'Northrop Grumman' : 
                  productId === 51 ? 'Northrop Grumman' : ''} 
                       &nbsp;Communications Email inbox (<i className="fa fa-envelope" />{" "}
                      in the Navigation Bar once you start your project.)
                    </li>
                    <li>
                      The Project Reference Guide Information (<i className="fa fa-book" />{" "}
                      in the Navigation Bar)
                    </li>
                    <li>
                      Help Documentation (<i className="fa fa-life-ring" /> in
                      the Navigation Bar)
                    </li>
                  </ul>
                <p>
                  Louis Styles is now ready to share more detailed information
                  on planning your project.
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane key={(i += 1)} tabId="3">
            <Row>
              <Col md="3">
                <div className="avatar">
                  <div className="avatar-image">
                    <ResourceImage
                      alt="Louis Styles Avatar"
                      png="LouisStyles.png"
                    />
                  </div>
                  <h4>Louis Styles</h4>
                </div>
              </Col>
              <Col md="9">
                <h4>Hi, I’m Louis Stiles, VP of the Project Office.</h4>
                <p>
                  Congratulations on being named the project manager. You will
                  be reporting directly to me on this project.
                </p>
                <p>
                  Once you have finished reading your reference documents, you
                  need to plan your project. As you plan your project the
                  associated cost will be reflected in your budget.
                </p>
                <p>
                  O. M. Smith asked to give you a more detailed introduction to
                  planning the project. Once you finish these orientation panels
                  you will have access to a Sidebar menu with the following
                  available under Planning:
                </p>
                  <ul>
                    <li>Overview</li>
                    <li>
                      Resource – create your resource plan by assigning
                      resources to tasks
                    </li>
                    <li>
                      Risk/Opportunities – create a risk management plan and resulting
                      Risk/Opportunities budget
                    </li>
                    <li>
                      Training – create a plan for any training as you execute
                      the project
                    </li>
                    <li>
                      Stakeholder – create a plan for building and maintaining a
                      relationship with your stakeholders
                    </li>
                    <li>
                      Recognition – create a budget for team and individual
                      recognition
                    </li>
                    <li>
                      Approval – for submitting your plan and budget numbers for
                      approval to execute the project
                    </li>
                  </ul>
                <p>
                  Be aware that each screen has an Info window that explains the
                  context and resources needed to complete the planning
                  associated with the screen.
                </p>
                <p>
                  For your convenience, there is a Checklist to guide you through the planning process. Click on the <FontAwesomeIcon icon={faListAlt} />   icon in the Navigation bar (available once you have started your project). Click on the icon again to close the checklist.
                </p>
                <p>
                  B. D. Smith is next to talk to you for a moment about what
                  happens when the planning is over, then you will be done with
                  this orientation.
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane key={(i += 1)} tabId="4">
            <Row>
              <Col md="3">
                <div className="avatar">
                  <div className="avatar-image">
                    <ResourceImage alt="B.D. Smith Avatar" png="BDSmith.png" />
                  </div>
                  <h4>B.D. Smith</h4>
                </div>
              </Col>
              <Col md="9">
                <h4>Hi, I’m B. D. Smith. I’m the President and COO.</h4>
                <p>
                  Let me summarize what O. M., Michael, and Louis have
                  previously shard with you:
                </p>
                  <ul>
                    <li>
                      First, read your reference documents accessible from the
                      Navigation Bar
                    </li>
                    <li>Second, plan your project</li>
                    <li>
                      Third, when your plan is ready, submit it for approval
                    </li>
                    <li>
                      Once your plan is approved, you will have access to an
                      additional Sidebar menu for Execution with the following
                      available:
                      <ul>
                        <li>Staffing</li>
                        <li>Interaction</li>
                        <li>Work Week</li>
                      </ul>
                    </li>

                  </ul>
                <p>
                  For your convenience there is a Checklist to guide you through the execution process. Click on the <FontAwesomeIcon icon={faListAlt} />   icon in the Navigation bar (available once you have started your project). Click on the icon again to close the checklist.
                </p>
                <p>
                  I won’t provide anymore information that you don’t need right
                  now. Suffice it to say, that once the planning is complete,
                  the execution phase of the project will start.
                </p>
                <p>
                  Thank you. That is all for now, but I will drop in from time
                  to time as you execute the project to make sure the objectives
                  will be met.
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane key={(i += 1)} tabId="5">
            <Row>
              <Col md="3">
                <div className="avatar">
                  <div className="avatar-image">
                    <ResourceImage alt="Assistant Avatar" png="Assistant.png" />
                  </div>
                  <h4>Assistant</h4>
                </div>
              </Col>
              <Col md="9">
                <h4>
                  Finally, as we wrap up the orientation, please meet your
                  assistant.
                </h4>
                <p>
                  Your Project Assistant will be providing feedback and
                  information throughout your project.
                </p>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
