import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button } from "reactstrap";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"

import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";
import CollapsibleInfoPanel from "components/Info/CollapsibleReportInfoPanel.jsx";
import GanttChartTable from "routes/Report/components/GanttChart/GanttChart";
import { fetchGanttPng } from "redux/Report/thunks";
import { printMe } from "./reportUtils";

const GanttChartContainer = (props) => {
  const [imgSrc,setImgSrc] = useState("");
  const content = useSelector((state) => state);
  const dispatch = useDispatch();

 const  printMe = async() => {


    const uri = await dispatch(fetchGanttPng(content.project.runId));
    setImgSrc(uri)
    const name = "gantt.png"

  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}


  return (
    <Card>
      <CardHeaderBold>
        <span>Gantt Chart</span>
        <Button
          color="primary"
          size="sm"
          
          onClick={() => printMe()}
        >
         <FontAwesomeIcon icon={faDownload} />Export to PNG
        </Button>
      </CardHeaderBold>
      <NoMaxCardBody>
        <>

        <GanttChartTable {...props} />
        </>
      </NoMaxCardBody>
    </Card>
  );
};

export default GanttChartContainer;
