import AdamHerrez from "./AdamHerrez.png";
import Assistant from "./Assistant.png";
import ProjectAssistant from "./ProjectAssistant.png";
import BDSmith from "./BDSmith.png";
import ChandraLake from "./ChandraLake.png";
import DavidLewis from "./DavidLewis.png";
import JamieBurns from "./JamieBurns.png";
import JaneHodges from "./JaneHodges.png";
import JimThomas from "./JimThomas.png";
import KittyFrost from "./KittyFrost.png";

import LisaDerring from "./LisaDerring.png";
import LouisStyles from "./LouisStyles.png";
import MichaelRussell from "./MichaelRussell.png";
import NoelHodges from "./NoelHodges.png";
import OMSmith from "./OMSmith.png";
import OmarMosa from "./OmarMosa.png";
import SallyNicoles from "./SallyNicoles.png";
import TeaDominque from "./TeaDominque.png";
import Team from "./Team.png";

import BarbaraForester from "./BarbaraForester.png";
import BettyJames from "./BettyJames.png";
import BillMcGuire from "./BillMcGuire.png";
import ChadLewis from "./ChadLewis.png";
import DajaRussman from "./DajaRussman.png";
import DanJackson from "./DanJackson.png";
import DavidWong from "./DavidWong.png";
import DonStevens from "./DonStevens.png";
import GenaDavidson from "./GenaDavidson.png";
import JaneSmythe from "./JaneSmythe.png";
import JessicaTaylor from "./JessicaTaylor.png";
import JohnDerring from "./JohnDerring.png";
import JohnWilliams from "./JohnWilliams.png";
import KimLee from "./KimLee.png";
import LarryDanielson from "./LarryDanielson.png";
import LuDenny from "./LuDenny.png";
import MaggieOshea from "./MaggieOshea.png";
import MariaOrtega from "./MariaOrtega.png";
import MikeHalvorson from "./MikeHalvorson.png";
import RheaJohnson from "./RheaJohnson.png";
import SamHennings from "./SamHennings.png";
import StevenNicoles from "./StevenNicoles.png";
import WashingtonJones from "./WashingtonJones.png";
import AnnShubert from "./AnnShubert.png";
import LawrenceBixby from "./LawrenceBixby.png";
import EvanDeman from "./EvanDeman.png";
import BillFaust from "./BillFaust.png";
import BillJones from "./BillJones.png";
import CharlesWhite from "./CharlesWhite.png";
import DennisWalsh from "./DennisWalsh.png";
import JerryDool from "./JerryDool.png";
import LarryJames from "./LarryJames.png";
import SusanMcFee from "./SusanMcFee.png";
import MaryStyles from "./MaryStyles.png";
import MaryProbst from "./MaryProbst.png";
import SarahAdams from "./SarahAdams.png";
import KristneOlson from "./KristineOlson.png";

import BDAndLouis from "./BDAndLouis.png";
import Subcontractor from "./Subcontractor.png";
import MCAProjectManager from "./MCAProjectManager.png";
import HallTalk from "./HallTalk.png";
import PaccissTeam from "./PaccissTeam.png";
import IntegrationTestTeam from "./IntegrationTestTeam.png";
import PrototypeDelivery from "./PrototypeDelivery.png";
import PrototypeInstallation from "./PrototypeInstallation.png";
import PrototypeQualification from "./PrototypeQualification.png";

// import Task20Team from "./Task20Team.png";
const people = 
 {
  AdamHerrez,
  Assistant,
  ProjectAssistant,
  BDSmith,
  ChandraLake,
  DavidLewis,
  JamieBurns,
  JaneHodges,
  JimThomas,
  KittyFrost,
  LisaDerring,
  LouisStyles,
  MichaelRussell,
  NoelHodges,
  OMSmith,
  OmarMosa,
  SallyNicoles,
  TeaDominque,
  Team,
  BarbaraForester,
  BettyJames,
  BillMcGuire,
  ChadLewis,
  DajaRussman,
  DanJackson,
  DavidWong,
  DonStevens,
  GenaDavidson,
  JaneSmythe,
  JessicaTaylor,
  JohnDerring,
  JohnWilliams,
  KimLee,
  LarryDanielson,
  LuDenny,
  MaggieOshea,
  MariaOrtega,
  MikeHalvorson,
  RheaJohnson,
  SamHennings,
  StevenNicoles,
  WashingtonJones,
  AnnShubert,
  LawrenceBixby,
  EvanDeman,
  BillFaust,
  BillJones,
  CharlesWhite,
  DennisWalsh,
  JerryDool,
  LarryJames,
  SusanMcFee,
  MaryStyles,
  MaryProbst,
  SarahAdams,
  KristneOlson,
  BDAndLouis,
  Subcontractor,
  MCAProjectManager,
  HallTalk,
  PaccissTeam,
  IntegrationTestTeam,
  PrototypeDelivery,
  PrototypeInstallation,
  PrototypeQualification
  // Task20Team
};

export default people
