import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-regular-svg-icons";
import { Row, Col } from "reactstrap";

import imageURLs from "./images/imageURLs.js";

const ChecklistHelp = () => (
  <div>
    <h3>Getting Started – Planning Checklist</h3>
    <p>
      After you finish the orientation and begin your first project, a new{" "}
      <FontAwesomeIcon icon={faListAlt} /> icon will appear in the Navigation
      bar. Clicking on this icon will open a panel on the right side on your
      screen.
    </p>
    <Row>
      <Col md="8">
        <p>
          The checklist guides you through every decision step of the planning
          and execution phases of your project.
        </p>
        <p>
          Typically, you would go through the steps in order, but you can change
          any plan or execution decision at any time prior to running a week of
          the project.
        </p>
        <Row>
          <Col md="8">
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={imageURLs.checklistMouseover}
              alt="checklist"
            />
          </Col>
          <Col md="4">
            <p>
              Mousing over each checklist step provides completion criteria for
              that step.
            </p>
          </Col>
        </Row>

        <p>
          The checklist is manual and optional. As you complete a step, click
          the box to indicate you have completed that step. Execution steps will
          automatically be unchecked after the execution of a week, enabling you
          to track the steps for the next week.
        </p>

        <p>
          Hide the checklist by clicking on the{" "}
          <FontAwesomeIcon icon={faListAlt} /> icon in the Navigation bar.
        </p>
      </Col>
      <Col md="4" style={{ verticalAlign: "top" }}>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={imageURLs.checklist}
          alt="checklist"
        />
      </Col>
    </Row>
  </div>
);

export default ChecklistHelp;
