import React, {  useState } from "react";
import Select from "react-select";
import {  useSelector, useDispatch } from "react-redux";
import { processAssignments} from "redux/Project/thunks";
import {getUnfinishedTaskSelectOptions} from "redux/Resource/selectors";

const StaffTaskSelector = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch();
  const [val, setVal] = useState(props.value ? props.value : "");
  const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted grey",
      fontWeight: state.isSelected ? "bold" : "regular"
    }),
    control: base => ({
      ...base,
      flex: 0,
      id: "task-base",
      border: 0,
      backgroundColor: "transparent",
      // This line disable the blue border
      boxShadow: "none",
      justifyContent: "center",
      alignContent: "center",
      textAlign: "center",
      verticalAlign: "middle"

    }),
    singleValue: (provided, state) => {
      const opacity =
        state.data.label === "None" ? 0.0 : state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    valueContainer: provided => ({
      ...provided,
      id: "task-vc",
      width: "30px",
      marginLeft: "20px",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px"
    })
  };

  const handleTaskChange = async selected => {
    if (selected.value === null || selected.label === "") {
      return;
    }
    setVal(selected.label);
    
    await dispatch(processAssignments(
      props.row,
      "Task1",
      selected.label.toString(),
      selected.label));

  };

  // handleAssignmentChange = (row, cellName, cellValue) => {
  //   const list = this.props.workerAssignmentList.filter(
  //     item => item.WorkerId === row.WorkerId
  //   );
  //   const rIndex = this.props.workerAssignmentList.indexOf(list[0]);
  //   const currentWorker = list[0];
  //   if (cellName === "HoursOvertime") {
  //      this.props.processAssignments(
  //       currentWorker,
  //       "Overtime",
  //       cellValue.toString(),
  //       cellValue
  //     );
  //   } else {
  //      this.props.processAssignments(
  //         currentWorker,
  //         cellName,
  //         cellValue.toString(),
  //         cellValue
  //       )
  //   }
  // };

  return (
    <Select
      id={"staff-tasks"}
      className="select-custom-class"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      options={getUnfinishedTaskSelectOptions(content)}
      styles={selectStyle}
      value={getUnfinishedTaskSelectOptions(content).filter(
        option => option.label === val
      )}
      menuPortalTarget={document.body}
      onChange={selected => {
        handleTaskChange(selected);
      }}
     
      />

  );
};

export default StaffTaskSelector;


/*
 onKeyDown={(event, option) => {
        if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(event.key) && isMenuOpen) {
          event.preventDefault();
          event.stopPropagation();
    
          var option = options.find(o => o.label === event.target.value.toString());
       
          if (option !== undefined && option !== null) {
            handleTaskChange(option);
          }
          
        }

        if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
          // event.stopPropagation();
          console.log(event.target.value)
        }
      }}
*/
