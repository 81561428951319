import React , { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import { Card, ButtonToolbar, Button, UncontrolledTooltip } from "reactstrap";
import accounting from "accounting";
import  ChartCardBody  from "components/styled/ChartCardBody";
import CardHeaderBold from "components/styled/CardHeaderBold";
import InfoButton from "components/Info/InfoButton";


const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

const EVChart = (props) => {
      const chartRef = useRef(null);

    const lineChartOptions = {
      // title: {
      //   text: 'Quality'
      // },
      legend: {
        position: "bottom"
      },
      layout: {
        padding: {
              right: 450 // Adjust the value as per your requirement
        }
    },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Cumulative Value"
            },
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                return accounting.formatMoney(label, options);
              }
            }
          }


        ],
        xAxes: [
          {
            distribution: "linear",
            scaleLabel: {
              display: true,
              labelString: "Project Week"
            },
            ticks: {
              stepSize: 5,
              autoSkip: false,
              maxTicksLimit: 15,
            }
          }
        ]
      },
      fill: false,
      strokeColor: "rgba(151,187,205,1)",
      pointColor: "#CC94B9",
      pointStrokeColor: "#fff",
      pointHighlightFill: "#fff",
      pointHighlightStroke: "rgba(151,187,205,1)"
      // scales: {
      //   xAxes: [{
      //     type: 'linear',
      //     position: 'bottom'
      //   }]
      // }
    };
 const pngExport = async () => {
    const base64Image = chartRef.current.chartInstance.toBase64Image();

    const name = "ev.png";

    var link = document.createElement("a");
    link.download = name;
    link.href = base64Image;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };
    return (
      <Card>
        <CardHeaderBold>
          <span>Earned Value</span>
          <ButtonToolbar>
            <InfoButton selectedKey="ev" />
             <Button id="evpng"  color="primary" size="xs" onClick={() => pngExport()}>
            <span style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faDownload} /> 
            </span>
          </Button>
          </ButtonToolbar>
   <UncontrolledTooltip   placement="top"
            target="evpng">
        Download chart as png file    
           </UncontrolledTooltip>
        </CardHeaderBold>
        <ChartCardBody>
          <div id="evc">
            <Line  ref={chartRef} data={props.data} options={lineChartOptions} height={70} />
          </div>
        </ChartCardBody>


      </Card>
    );
  
}

export default EVChart;
