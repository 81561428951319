import React from "react";
import { connect } from "react-redux";
import { insertMeeting, updateMeeting } from "redux/Interaction/thunks";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import { shouldAcceptMeeting } from "utilities/InteractionChecks";
import { Form, FormGroup, Label, Input,Button} from "reactstrap";
import { getOnProjectWorkers, getStartTimes } from "redux/Project/selectors";
import { convertTimeToStartAndEnd } from "utilities/utils";
import { closeModal } from "redux/Modal/ModalModule";

class MeetingInteraction extends React.Component {
  state = {
    placeholder: false,
    currentMeetingItem: this.props.meetingItem,
    selectedDay: this.props.selectedDay,
    isChecked: true
  };

  handleTopicChange = event => {
    event.preventDefault();

    const newTopicId = parseInt(event.target.value, 10);
    const { topic } = this.props.meetingTopicList.find(
      t => t.id === newTopicId
    );

    const item = { ...this.state.currentMeetingItem };
    item.TopicNumber = newTopicId;
    item.Topic = topic;
    this.setState({
      currentMeetingItem: item
    });
  };

  handleDayChange = event => {
    event.preventDefault();

    const newDay = event.target.value;

    const item = { ...this.state.currentMeetingItem };
    item.DayOfWeek = newDay;
    this.setState({
      currentMeetingItem: item
    });
  };

  handleStartTimeChange = event => {
    const newTime = event.target.value;
    const item = { ...this.state.currentMeetingItem };
    item.StartTime = newTime;

    const durationInHours = this.props.meetingDurationList.find(
      d => d.id === item.DurationId
    );
    const newTimes = convertTimeToStartAndEnd(
      newTime,
      durationInHours.duration,
      item.StartDate
    );

    item.StartDate = new Date(newTimes.startDt);
    item.EndDate = new Date(newTimes.endDt);
    this.setState({
      currentMeetingItem: item
    });
  };

  handleDurationChange = event => {
    event.preventDefault();
    const duration = parseInt(event.target.value, 10); // contains hour value
    const item = { ...this.state.currentMeetingItem };
    item.DurationId = duration;

    const durationInHours = this.props.meetingDurationList.find(
      d => d.id === item.DurationId
    );

    const newTimes = convertTimeToStartAndEnd(
      item.StartTime,
      durationInHours.duration,
      item.StartDate
    );
    item.StartDate = new Date(newTimes.startDt);

    item.EndDate = new Date(newTimes.endDt);

    this.setState({
      currentMeetingItem: item
    });
  };

  handleFeedbackChange = event => {
    event.preventDefault();
    const feedback = parseInt(event.target.value, 10);
    const item = { ...this.state.currentMeetingItem };
    item.FeedbackId = feedback;

    this.setState({
      currentMeetingItem: item
    });
  };

  handleRecurringChange = event => {
    const item = { ...this.state.currentMeetingItem };
    item.IsRecurring = event.target.checked;

    this.setState({
      currentMeetingItem: { ...item }
    });
  };

  saveMeeting = event => {
    event.preventDefault();
    let result = {};

    if (this.props.isAdd) {
      const mi = { ...this.state.currentMeetingItem };
      mi.RunId = this.props.runId;
      if (mi.WorkerId === 0) {
        mi.WorkerId = this.props.onProjectWorkers[0].id;
      }
      this.setState({
        currentMeetingItem: mi
      });
      result = shouldAcceptMeeting(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        mi
      );
      if (result.status === "ok") {
        this.props.insertMeeting(mi);
        const alert = {
          id: 13,
          type: "success",
          headline: "Meeting Scheduled",
          event: "interaction",
          message: `The team has a ${mi.Topic} meeting scheduled for ${mi.DayOfWeek}`
        };
        const notificationData = {
          info: `The team has a ${mi.Topic} meeting scheduled for ${mi.DayOfWeek}`,
          worker: "Everyone",
          week: this.props.displayProjectWeek
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else if (result !== "empty") {
        const alert = {
          id: 15,
          type: "danger",
          headline: "Meeting Conflict",
          event: "interaction scheduling",
          message: result.message
        };
        this.props.addAlert(alert, {}, 3000, false);
      }
    } else {
      result = shouldAcceptMeeting(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        this.state.currentMeetingItem
      );
      if (result.status === "ok") {
        this.props.updateMeeting(this.state.currentMeetingItem);
        const alert = {
          id: 13,
          type: "success",
          headline: "Meeting Updated",
          event: "interaction",
          message: `The team has a ${this.state.currentMeetingItem.Topic} meeting scheduled for ${this.state.currentMeetingItem.DayOfWeek}`
        };
        const notificationData = {
          info: `The team has a ${this.state.currentMeetingItem.Topic} meeting scheduled for ${this.state.currentMeetingItem.DayOfWeek}`,
          worker: "Everyone",
          week: this.props.displayProjectWeek
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else if (result.status !== "empty") {
        const alert = {
          id: 15,
          type: "danger",
          headline: "Meeting Conflict",
          event: "interaction scheduling",
          message: result.message
        };
        this.props.addAlert(alert, {}, 3000, false);
      }
    }
    // Close the modal on save
    // this.props.dismiss();
  };

  render() {
    let iterKey = 1;
    return (
      <div>
        <Form className="form-split">
          <FormGroup>
            <Label for="topic">Choose Topic</Label>
            <Input
              id="topic"
              type="select"
              placeholder="Topic"
              value={this.state.currentMeetingItem.TopicNumber}
              onChange={(event) => this.handleTopicChange(event)}
            >
              {this.props.meetingTopicList.map((row, index) => (
                <option key={iterKey++} value={row.id}>
                  {row.topic}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="day">Choose Day</Label>
            <Input
              id="day"
              type="select"
              placeholder="day"
              value={this.state.currentMeetingItem.DayOfWeek}
              onChange={(event) => this.handleDayChange(event)}
            >
              {this.props.days.map((day, index) =>
                day !== "Sunday" && day !== "Saturday" ? (
                  <option key={iterKey++} value={day}>
                    {day}
                  </option>
                ) : (
                  " "
                )
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="start">Start: </Label>
             <Input
              id="start"
              type="select"
              placeholder="startTime"
              value={this.state.currentMeetingItem.StartTime}
              onChange={(event) => this.handleStartTimeChange(event)}
            >
              {this.props.startTimes.map((row, index) => (
                <option key={iterKey++} value={row}>
                  {row}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="duration">Duration (hours): </Label>
              <Input
              id="duration"
              type="select"
              placeholder="duration"
              value={this.state.currentMeetingItem.DurationId}
              onChange={(event) => this.handleDurationChange(event)}
            >
              {this.props.meetingDurationList.map((row, index) => (
                <option key={iterKey++} value={row.id}>
                  {row.duration}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="feedback">Choose Feedback</Label>
            <Input
              id="feedback"
              type="select"
              placeholder="feedback"
              value={this.state.currentMeetingItem.FeedbackId}
              onChange={(event) => this.handleFeedbackChange(event)}
            >
              {this.props.feedbackList.map((row, index) => (
                <option key={iterKey++} value={row.id}>
                  {row.topic}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              id="recurring"
              onChange={(event) => this.handleRecurringChange(event)}
              checked={this.state.currentMeetingItem.IsRecurring}
            />{' '}   
              Is Recurring?
              </Label>
          </FormGroup>
          <footer>
            <Button
              color="primary"
              className="btn btn-primary"
              type="button"
              onClick={(event) => this.saveMeeting(event)}
            >
              Save
            </Button>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  meetingTopicList: state.project.meetingTopicList,
  meetingDurationList: state.project.meetingDurationList,
  feedbackList: state.project.feedbackList,
  runId: state.project.runId,
  days: state.project.daysOfWeek,
  onProjectWorkers: getOnProjectWorkers(state),
  startTimes: getStartTimes(state),
  interactionList: state.execution.interactions,
  displayProjectWeek: state.project.displayProjectWeek
});

const mapDispatchToProps = dispatch => ({
  insertMeeting: meetingItem => dispatch(insertMeeting(meetingItem)),
  updateMeeting: meetingItem => dispatch(updateMeeting(meetingItem)),
  dismiss: () => dispatch(closeModal()),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export const MeetingInteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingInteraction);
