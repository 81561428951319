import React from "react";
import { connect } from "react-redux";
import { AlertList } from "react-bs-notifier";
import { dismissAlert } from "redux/Notification/NotificationModule";

export const NotificationsContainer = props => (
  <div>
    <AlertList
      position={props.position}
      alerts={props.notifications}
      timeout={props.timeout}
      dismissTitle="Close"
      onDismiss={notification => props.dismissAlert(notification)}
    />
  </div>
);

const mapDispatchToProps = dispatch => ({
  dismissAlert: notification => dispatch(dismissAlert(notification))
});

const mapStateToProps = state => ({
  notifications: state.notification.alerts,
  position: state.notification.position,
  timeout: state.notification.timeout
});

export default connect(mapStateToProps, mapDispatchToProps)(
  NotificationsContainer
);
