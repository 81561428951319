import { handleActions } from "redux-actions";
import {
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_ERROR,
  FETCH_CLASSRUNS_ERROR,
  FETCH_CLASSRUNS_SUCCESS,
  FETCH_INSTRUCTORCLASSES_ERROR,
  FETCH_INSTRUCTORCLASSES_SUCCESS,
  FETCH_INSTRUCTORS_ERROR,
  FETCH_INSTRUCTORS_SUCCESS,
  SET_SELECTED_CLASS,
  ADD_SECONDARY_SUCCESS,
  ADD_SECONDARY_ERROR,
  REMOVE_SECONDARY_SUCCESS,
  REMOVE_SECONDARY_ERROR,
  CHANGE_PRIMARY_SUCCESS,
  CHANGE_PRIMARY_ERROR,
  ADD_CLASSROOM_SUCCESS,
  ADD_CLASSROOM_ERROR,
  INACTIVATE_CLASSROOM_ERROR,
  INACTIVATE_CLASSROOM_SUCCESS,
  SET_STUDENT_INVALIDLICENSE,
  RECEIVE_STUDENT_INFO,
  UPDATE_CLASSROOM_ERROR,
  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSRUNS_ERROR,
  UPDATE_CLASSRUNS_SUCCESS,
  UPDATE_STOPWEEKS_ERROR,
  UPDATE_STOPWEEKS_SUCCESS,
  TOGGLE_ALLOW_TO_RUN,
  TOGGLE_ALLOW_TO_PLAN,
  TOGGLE_STOP_WEEK,
  UPDATE_STOP_MESSAGE,
  SET_LOADING,
  SET_EXECSEQ_FILTER_VALUE
} from "./types";

const initialState = {
  instructorClasses: [],
  instructors: [],
  studentList: [],
  selectedClass: {},
  classRuns: [],
  studentHasNoValidLicense: false,
  selectedStudentInfo: {},
  loading: false,
  execSeqFilterValue: 0  // all
};
// ------------------------------------
// Reducer
// ------------------------------------
//
export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({
      ...state,
      loading: payload
    }),
    [SET_EXECSEQ_FILTER_VALUE]: (state, { payload }) => ({
      ...state,
      execSeqFilterValue: payload
    }),
    [SET_STUDENT_INVALIDLICENSE]: (state, { payload }) => ({
      ...state,
      studentHasNoValidLicense: payload
    }),
    [SET_SELECTED_CLASS]: (state, { payload }) => ({
      ...state,
      selectedClass: payload
    }),
    [FETCH_STUDENTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      studentList: payload
    }),
    [FETCH_CLASSRUNS_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [FETCH_CLASSRUNS_SUCCESS]: (state, { payload }) => ({
      ...state,
      classRuns: payload
    }),
    [FETCH_STUDENTS_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [FETCH_INSTRUCTORCLASSES_SUCCESS]: (state, { payload }) =>
      

      // reverse the order of the classes
      
      ({
        ...state,
        instructorClasses: payload !== undefined && payload.count >> 2 ? payload.reverse() : payload,
        selectedClass: payload !== undefined && payload.count > 1 ? payload[0] : {},
        studentList: []
      }),
    [FETCH_INSTRUCTORCLASSES_ERROR]: (state, { payload }) => ({
      ...state,

      error: payload
    }),
    [FETCH_INSTRUCTORS_SUCCESS]: (state, { payload }) =>
      ({
        ...state,
        instructors: payload
      }),
    [FETCH_INSTRUCTORS_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [ADD_SECONDARY_SUCCESS]: (state, { payload }) => ({
      ...state,
    }),
    [ADD_SECONDARY_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [REMOVE_SECONDARY_SUCCESS]: (state, { payload }) => ({
      ...state
    }),
    [REMOVE_SECONDARY_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [CHANGE_PRIMARY_SUCCESS]: (state, { payload }) => ({
      ...state
    }),
    [CHANGE_PRIMARY_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [ADD_CLASSROOM_SUCCESS]: (state, { payload }) => ({
      ...state
    }),
    [ADD_CLASSROOM_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [INACTIVATE_CLASSROOM_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [INACTIVATE_CLASSROOM_SUCCESS]: (state, { payload }) => ({
      ...state
    }),
    [UPDATE_CLASSROOM_SUCCESS]: (state, { payload }) => {
      const newData = state.instructorClasses.map(el => {
        if (el.classroomId === payload.classroomId)
          return Object.assign({}, el, payload);
        return el;
      });
      return {
        ...state,
        instructorClasses: newData
      };
    },
    [UPDATE_CLASSROOM_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [UPDATE_CLASSRUNS_SUCCESS]: (state, { payload }) =>
      Object.assign({}, state, {
        classRuns: payload
      }),
    [UPDATE_CLASSRUNS_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [UPDATE_STOPWEEKS_SUCCESS]: (state, { payload }) => {
      const index = state.classRuns.findIndex(m => m.ID === payload.ID);
      return Object.assign({}, state, {
        classRuns: state.classRuns
          .slice(0, index)
          .concat(payload)
          .concat(state.classRuns.slice(index + 1))
      });
    },
    [UPDATE_STOPWEEKS_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [RECEIVE_STUDENT_INFO]: (state, { payload }) => ({
      ...state,
      selectedStudentInfo: payload
    }),
    [TOGGLE_ALLOW_TO_PLAN]: (state, { payload }) => ({
      ...state,
      classRuns: payload
    }),
    [TOGGLE_ALLOW_TO_RUN]: (state, { payload }) => ({
      ...state,
      classRuns: payload
    }),
    [TOGGLE_STOP_WEEK]: (state, { payload }) => ({
      ...state,
      classRuns: payload
    }),
    [UPDATE_STOP_MESSAGE]: (state, { payload }) => ({
      ...state,
      classRuns: payload
    }),
  },
  initialState
);
