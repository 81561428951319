import React from "react";
import { ResourceProfile } from "routes/Reference/components";

const ResourceProfileModal = props => {
  if (props && props.resource) {
    return <ResourceProfile key="rp" worker={props.resource} />;
  }
  return <span>No resource specified</span>;
};
export default ResourceProfileModal;
