import React from "react";
import styled from "styled-components";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";

import ResourceImage from "./ResourceImage";

const StyledResource = styled.div`
	display: flex;
	align-items: center;
  margin-top: 3px;
  border: none;

	img {
		width: 45px;
		height: auto;
		border-radius: 50%;
	}

	> div {
		margin-left: 10px;
		line-height: 1.3;
	}

	small {
		display: block;
		font-size: 0.75em;
		color: #818181;
	}

	.badge {
		font-size: 0.7em;
		border-radius: 1px;
	}
`;

const Resource = ({ row, status, showStatus }) => {
   const content = useSelector(state => state); //this hook gives us redux store state

  if (status === undefined || status === null) {
    return <span>no resource</span>
  }

  if (showStatus === undefined || showStatus === null) {
    showStatus = true;
  }
   const findArrivalWeek = () => {
     const currentWeek = content.project.displayProjectWeek;
	 let staffCount = 0;
	 for (const [key, value] of Object.entries(row)) {
		 if (value === "Staff") {
			 staffCount += 1;
		 }
      if (key.startsWith("W")) {
        const parsedWeek = parseInt(key.substring(1, key.length));
        if (isNaN(parsedWeek) || parsedWeek < currentWeek) {
          continue;
        }

        const parsed = parseInt(value, 10);
        if (isNaN(parsed)){
          continue;
        }
        status = staffCount > 1 ? `1st assigned task - week ${key.substring(1, key.length)}` : "staff request missing"
        break;
      }
    }
   }
	let badgeColor;
	switch (status.toLowerCase()) {
    case "available":
      findArrivalWeek()
			badgeColor = status==="staff request missing" ? "danger" : "primary";
			break;
		case "in progress":
		case "on project":
			badgeColor = "secondary";
			break;
		case "arrives in 1 week":
			badgeColor = "info";
			break;
		case "arrives in 2 weeks":
			badgeColor = "warning";
			break;
		default:
			badgeColor = "success";
	}
	return (
		<StyledResource>
			<ResourceImage png={row.ImagePng} alt="" />
			<div>
				{row.ResourceName}
				<small>{row.SkillName}</small>
				{showStatus && status ? <Badge color={badgeColor}>{status.toLowerCase()}</Badge> : ""}
			</div>
		</StyledResource>
	);
};

export default Resource;
