import React from "react";
import { Card, CardBody, Label, Input, Collapse } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import InfoFooter from "components/Info/InfoFooter";

const StakeholderOptionsCosts = (props) => (
  <Card className="Card-info-medium">
    <CardHeaderBold>
      <span className="float-left">Interaction Options & Costs</span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggle()}
          defaultChecked={props.panelVisible}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </CardHeaderBold>
    <Collapse isOpen={props.panelVisible}>
      <CardBody>
        <h5>Meetings</h5>
        <p>
          Meetings can be scheduled with any of the five stakeholders identified
          on the project. Meetings last two hours and involve the project
          manager and the respective stakeholder(s). The cost to the project for
          one meeting is $300.
        </p>
        <h5>Status Reports</h5>
        <p>
          Status reports can be sent to any of the five stakeholders identified
          on the project. Status reports do not require any additional effort
          but cost $200 to produce. There is no additional cost for sending
          status reports to multiple stakeholders.
        </p>
        <h5>Visits</h5>
        <p>
          Visits can be scheduled with the customers, the users and/or the
          subcontractor(s) on the project. Visits last one full day and involve
          a selected member of the project team and the respective stakeholder.
          Visits with the customers or users can be used for focus group
          meetings. The cost to the project for one visit is $150, plus one day
          of effort for the selected team member.
        </p>
        <h5>Training</h5>
        <p>
          Training can be provided for either the customers or subcontractor(s).
          Training opportunities for the customers and subcontractor(s) include:
          Defining the Product, Scope Change Control, Product Development
          Process, and Product Verification and Validation. The cost to the
          project for providing any one of these training sessions is $2,000 per
          session. There is no project effort associated with stakeholder
          training.
        </p>
        <h5>Surveys</h5>
        <p>
          Surveys can be sent to the potential users of the product to collect
          information of product requirements, design, implementation and use.
          Surveys require a resource and cost $200 to produce and process.
        </p>
        <h5>Building your plan</h5>
        <p>To add a stakeholder interaction:</p>
        <ol>
          <p>
            Pick the stakeholder and week and click on the intersecting cell
          </p>
          <p>
            From the drop down menu select a specific interaction from the
            available options for that stakeholder
          </p>
        </ol>
        <p>
          Plan a status report for all stakeholders for the week by clicking on
          the <i className="fa fa-file-text" value="" /> icon in the header of
          the week's column.
        </p>
        <p>
          Add enough interactions to meet your approved Stakeholder budget and
          complete your stakeholder relationship building strategy.
        </p>
        <h5>Editing your plan</h5>
        <p>
          Once entered, stakeholder interactions can be edited or deleted (None)
          by clicking on the cell and using the dropdown menu. All interactions
          for the week can also be cleared using{" "}
          <i className="fa fa-minus-square-o" /> icon in the header of the
          week's column.
        </p>
        <p>
          It is good project management to keep your Stakeholder plan up to date
          as you execute your project.
        </p>
      </CardBody>
      <InfoFooter selectedKey="stakeholder" showProjectLink={true} />
    </Collapse>
  </Card>
);

export default StakeholderOptionsCosts;
