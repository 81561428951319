import React from "react";
import imageURLs from "./images/imageURLs.js";

const WorkWeekHelp = () => (
  <div>
    <h3>WorkWeek Info:</h3>
    <h4>Prerequisites</h4>

    <ul>
      <li>Project Planning Complete</li>
      <li>Project Budget Approved</li>
      <li>
        You have made your decisions for the week (Staffing and Interaction)
      </li>
    </ul>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      src={imageURLs.WorkWeek}
      alt="workweek"
    />


    <p>
      When ready, click on the Start Week # button. This will execute the week.  During the week, Stakeholders and Team Members will communicate with you
      via the Communication Panel.
    </p>


<img
  style={{ maxWidth: "100%", maxHeight: "100%" }}
  src={imageURLs.RunWeekConditionsPopupExample}
  alt="workweek"
/>

    <p>  When responding to the pop-up communications consider the
      anticipated results of each response before selecting your desired
      response.
    </p>

<p> During the weekly execution, a subset of the activities and actions you have planned will appear in the daily columns of the Status Report panel </p>

<img
  style={{ maxWidth: "100%", maxHeight: "100%" }}
  src={imageURLs.WorkWeekStatusPanel}
  alt="workweek"
/>
<p>    At the conclusion of the week, you will be automatically redirected to the Reports where you should carefully review each report to understand what occured in the most recent week and what to do next.
</p>
    <p>
      A reminder: Keep your plans up to date as the status of your project will
      impact those plans. Update your plans via Planning.
    </p>
  </div>
);

export default WorkWeekHelp;
