// routes/Training/index.js
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonToolbar,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import {
  fetchTraining,
  saveTraining,
  deleteTraining
} from "redux/Training/thunks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { saveResourceItem } from "redux/Resource/thunks";
import { openModal } from "redux/Modal/ModalModule";
import { ADD_TRAINING_MODAL } from "routes/Modal/ModalTypes";
import { toggleTrainingInfo, toggleTrainingBudget } from "app/redux/actions";
import ReferenceButton from "components/Info/ReferenceButton";

import BudgetSummary from "routes/Budget/components/BudgetSummary";
import { getWorkerNameAndImgPng } from "redux/Project/selectors";

import ResourceGrid from "../Resource/components/ResourceGrid";

import TrainingTable from "./components/TrainingTable";
import TrainingOptionsCosts from "./components/TrainingOptionsCosts";
import TrainingInfo from "./components/TrainingInfo";
import CardHeaderBold from "components/styled/CardHeaderBold";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";

const Training = (props) => {
  const [newTrainingItem, setNewTrainingItem] = useState({
    ID: 0,
    RunId: props.runId !== undefined ? props.runId : 0,
    ClassId:
      props.trainingList !== undefined && props.trainingList.length > 0
        ? props.trainingList[0].ClassId
        : 0,
    ClassName:
      props.trainingList !== undefined && props.trainingList.length > 0
        ? props.trainingList[0].ClassName
        : "",
    ClassStartDay:
      props.trainingList !== undefined && props.trainingList.length > 0
        ? props.trainingList[0].ClassStartDay
        : "",
    WeekNumber: 1,
    TeamTraining: false,
    WorkerName:
      props.resources !== undefined && props.resources.length > 0
        ? props.resources[0].workerName
        : "",
    WorkerId:
      props.resources !== undefined && props.resources.length > 0
        ? props.resources[0].workerId
        : 0
  });

  const [activeTab, setActiveTab] = useState("resource-plan");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    props.fetchTraining(props.runId);
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [props.runId]);

  const showAddTrainingModal = () => {
    props.showAddTrainingModal(newTrainingItem);
  };

  const showUpdateTrainingModal = (trainingItem) => {
    props.showAddTrainingModal(trainingItem);
  };

  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
	 MAPDISPATCHTOPROPS for the opoenModal function below * */

  return (
    <div>
      <Row>
        <Col lg="12">
          <TrainingInfo
            panelVisible={props.trainingInfoVisible}
            toggle={props.toggleTrainingInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <BudgetSummary
            title="Training Budget Item"
            panelOpen={props.trainingBudgetVisible}
            togglePanel={props.toggleTrainingBudget}
            summaryType="training"
            titleSummary="Training"
            titleApproved="Approved for Training"
            titlePlanned="Planned for Training"
          />
        </Col>
      </Row>

      <Row>
        <Col lg="8">
          <Card>
            <CardHeaderBold>
              Training Plan
              <ButtonToolbar className="text-right">
                <Button
                  id="addTraining"
                  color="link"
                  onClick={() => showAddTrainingModal()}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                </Button>
                <UncontrolledTooltip
                  id="addTrainingtt"
                  placement="top"
                  target="addTraining"
                >
                  Add new training to plan
                </UncontrolledTooltip>
              </ButtonToolbar>
            </CardHeaderBold>
            <CardBody>
              <TrainingTable
                trainings={props.trainings}
                trainingList={props.trainingList}
                resources={props.resources}
                runId={props.runId}
                saveTraining={props.saveTraining}
                updateTraining={showUpdateTrainingModal}
                deleteTraining={props.deleteTraining}
                showAddTrainingModal={showAddTrainingModal}
                modalOpen={props.modalOpen}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="box-AdditionalInfo Card-info-large">
            <CardHeaderBold>
              Training Options + Costs
              <ReferenceButton className="float-right" size="lg" selectedKey="training" />
            </CardHeaderBold>
            <CardBody>
              <TrainingOptionsCosts productId={props.productId} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="resource-plan">
              <ResourceGrid noHideToggle />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  runId: state.project.runId,
  trainingList: state.project.trainingList,
  resources: getWorkerNameAndImgPng(state),
  trainings: state.planning.training.trainings,
  trainingInfoVisible: state.app.trainingInfoVisible,
  trainingBudgetVisible: state.app.trainingBudgetVisible,
  modalOpen: state.modal.open,
  productId: state.auth.productId
});

const mapDispatchToProps = (dispatch) => ({
  fetchTraining: (runId) => dispatch(fetchTraining(runId)),
  saveTraining: (data) => dispatch(saveTraining(data)),
  deleteTraining: (index, id, runId, data) =>
    dispatch(deleteTraining(index, id, runId, data)),
  saveResourceItem: (data) => dispatch(saveResourceItem(data)),
  showAddTrainingModal: (trainingItem) =>
    dispatch(openModal(ADD_TRAINING_MODAL, { trainingItem }, "modal-medium")),
  toggleTrainingInfo: () => dispatch(toggleTrainingInfo()),
  toggleTrainingBudget: () => dispatch(toggleTrainingBudget())
});

export default connect(mapStateToProps, mapDispatchToProps)(Training);
