import {  Table } from "reactstrap";
import styled from "styled-components";

const StyledTable = styled(Table)`
  transition: transform 0.3 ease;
  striped

  &.active {
    transform: scale(1.03);
  }

  &.table-hover tr {
    cursor: pointer;
  }
  th,
  td {
    min-width: 30px;
  }
  tr.active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  tr:nth-child(even) {
   background-color: #f2f2f2;
  }

 tfoot
    {
       border-top: 2px solid black;
    }

  .cell-checkbox {
    text-align: center;

    .form-check-input {
      margin: 0;
    }
  }
`;

export default StyledTable;
