import { createSelector } from "reselect";

const getWorkerFixedList = (state) => state.project.workerFixedList;
const getBudgetList = (state) => state.project.budgetList;
const getSkillList = (state) => state.project.skills;
const getTaskSkillList = (state) => state.project.taskSkillList;
const getWorkers = (state) => state.project.workers;

export const getNonDeferredTaskSkillList = createSelector(
  getTaskSkillList,
  (taskSkillList) => taskSkillList.filter((t) => t.DeferLoad === false)
);

export const getWorkersBySkill = createSelector(
  [getSkillList, getWorkerFixedList, getWorkers],
  (skillList, workerFixedList, workers) => {
    const workersBySkill = [];
    const sKeys = Object.keys(skillList);
    for (let s = 0; s < sKeys.length; s++) {
      const thisSkill = skillList[s];
      const skillId = thisSkill.SkillId;
      const filteredWorkers = [];
      const names = [];
      const wKeys = Object.keys(workers);
      for (let w = 0; w < wKeys.length; w++) {
        let worker = workers[w];

        if (worker.SkillId === skillId && !worker.DeferLoad) {
          filteredWorkers.push(worker);
          names.push(worker.WorkerName);
        }
      }
      workersBySkill.push({
        skillName: thisSkill.SkillName,
        skillDescription: thisSkill.Description,
        resourceNames: names
      });
    }

    return workersBySkill;
  }
);
export const getWBSData = createSelector([getBudgetList], (budgetList) => {
  const wbsData = [];
  for (const item in budgetList) {
    if (Object.prototype.hasOwnProperty.call(budgetList, item)) {
      const budgetItem = budgetList[item];
      if (!budgetItem.PrefixDescriptor.includes("Alliance Project")) {
        wbsData.push({
          description: budgetItem.PrefixDescriptor,
          task: budgetItem.DisplayTask,
          formatting: budgetItem.Formatting
        });
      }
    }
  }
  return wbsData;
});
// return a string list of reference names
export const getSimplePolicyList = createSelector(
  [getWorkerFixedList],
  (workers) => {
    const referenceList = [];

    const comp = {
      menuTitle: "Absenteeism",
      refKey: "Absenteeism",
      index: 5
    };
    referenceList.push(comp);
    referenceList.push({
      menuTitle: "Individual Conferences",
      refKey: "Conferences",
      index: 6
    });
    referenceList.push({ menuTitle: "Overtime", refKey: "Overtime", index: 7 });
    referenceList.push({ menuTitle: "Quality", refKey: "Quality", index: 8 });
    referenceList.push({
      menuTitle: "Recognition",
      refKey: "Recognition",
      index: 9
    });
    referenceList.push({
      menuTitle: "Risk/Opportunities",
      refKey: "Reserves",
      index: 10
    });
    referenceList.push({
      menuTitle: "Staff Resignation",
      refKey: "Resignation",
      index: 11
    });
    referenceList.push({
      menuTitle: "Staffing Projects",
      refKey: "Staffing",
      index: 12
    });
    referenceList.push({
      menuTitle: "Stakeholder Relations",
      refKey: "Stakeholder",
      index: 13
    });
    referenceList.push({
      menuTitle: "Training",
      refKey: "Training",
      index: 14
    });
    referenceList.push({
      menuTitle: "Team Meetings",
      refKey: "Meetings",
      index: 15
    });
    referenceList.push({
      menuTitle: "Vacations",
      refKey: "Vacations",
      index: 16
    });
    referenceList.push({
      menuTitle: "Work Assignments",
      refKey: "Assignments",
      index: 17
    });
    return referenceList;
  }
);

export const getSimpleProjectDocList = createSelector(
  [getWorkerFixedList],
  (workers) => {
    const referenceList = [];
    referenceList.push({
      menuTitle: "Project Charter",
      refKey: "Charter",
      index: 18
    });
    referenceList.push({
      menuTitle: "Organizational Chart",
      refKey: "OrgChart",
      index: 19
    });
    referenceList.push({
      menuTitle: "Work Breakdown Structure",
      refKey: "WBS",
      index: 20
    });
    referenceList.push({
      menuTitle: "Task Descriptions",
      refKey: "Tasks",
      index: 21
    });
    return referenceList;
  }
);

export const getSimpleResourcesList = createSelector(
  [getWorkerFixedList],
  (workers) => {
    const referenceList = [];
    referenceList.push({
      menuTitle: "Resources By Job Description",
      refKey: "BySkill",
      index: 22
    });
    referenceList.push({
      menuTitle: "Resources Summary",
      refKey: "Summary",
      index: 23
    });
    return referenceList;
  }
);

export const getSimpleProfilesList = createSelector([getWorkers], (workers) => {
  const referenceList = [];
  let i = 25;
  const keys = Object.keys(workers);
  for (let r = 0; r < keys.length; r++) {
    const worker = workers[r];
    if (!worker.DeferLoad) {
      referenceList.push({
        menuTitle: worker.WorkerName,
        refKey: worker.WorkerName,
        index: i++
      });
    }
  }

  return referenceList;
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

export const getWorkerSummary = createSelector(
  [getWorkerFixedList, getWorkers],
  (workers, workerData) => {
    const summaryList = [];
    let hourly = 0;
    const keys = Object.keys(workers);
    for (let r = 0; r < keys.length; r++) {
      const worker = workers[r];

      const thisWorker = workerData.find((w) => w.WorkerId === worker.WorkerId);

      if (thisWorker && !thisWorker.DeferLoad) {
        hourly = worker.WeeklyCost / 40;
        summaryList.push({
          resource: worker.Name,
          title: worker.Title,
          weekly: formatter.format(worker.WeeklyCost),
          hourly: formatter.format(hourly)
        });
      }
    }

    return summaryList;
  }
);
/*

Project Documentation
Project Charter
Organization Chart
Work Breakdown Structure
Task Descriptions

Resources
Resources by Job Description
Summary By Name
Summary By Title

Individual Resource Profiles
Adam Herrez
Chandra Lake
David Lewis
Jane Hodges
Jim Thomas
Lisa Derring
Noel Hodges
Omar Mosa
Sally Nicoles
Tea Dominque
*/
