import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import imageURLs from "./images/imageURLs.js";


const StakeholderPlanningHelp = () => (
  <div>
    <h3>Planning Your Project – Stakeholder Plan</h3>
    <h4>Context and Resources for Stakeholder Planning: </h4>
    <p>
      Create a Stakeholder Plan to build and maintain your relationships with your stakeholders over the life of your project. Plan specific interactions with specific stakeholders in specific weeks in building the Stakeholder Plan.

    </p>

    <p>
      The following will be useful in building a successful Stakeholder
      Interaction plan:
    </p>
    <ol>
      <li>Reference the Info window for options and costs.</li>
      <li>Task descriptions (via the Gantt Chart)</li>
      <li>Project Charter and Organizational chart via the Reference Guide</li>
    </ol>


    <h4>Building your Stakeholder Plan</h4>

    <Row>
      <Col lg="6">
        <p>To add a stakeholder interaction:</p>
        <ol>
          <li>Pick the stakeholder and week and click on the intersecting cell</li>
          <li>
            From the dropdown menu select a specific interaction from the available
            options for that stakeholder
          </li>
          <li>
            Plan a status report for all stakeholders for the week by clicking on the “Report” icon, <i className="fa fa-file-text" />,  in the column header for that week.

          </li>
        </ol>
      </Col>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.EditStakeholderPlan}
          alt="stakeholder"
        />
      </Col>
    </Row>




    <Row>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.StakeholderOptionsCostsInfoPanel}
          alt="stakeholder"
        />
      </Col>
      <Col lg="6">
        <p>Stakeholder Interaction options and costs are detailed in the Project Reference Guide and the Info window. Add enough interactions to meet your approved Stakeholder budget and implement your stakeholder relationship building strategy.</p>

      </Col>

    </Row>

    <Row>
      <Col lg="6">
          <h4>Editing your Stakeholder Plan</h4>
        <p>Once entered, stakeholder interactions can be edited or removed (None) by clicking on the cell and using the dropdown menu .</p>
        <p> All interactions for the week can also be cleared using the “Clear” icon,<i className="fa fa-minus-square-o" />,  at the top of the week.</p>

      </Col>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",marginTop: "10px"}}

          src={imageURLs.EditStakeholderCell}
          alt="stakeholder"
        />
      </Col>

    </Row>





    <h4> Execution and your Stakeholder Plan:</h4>
    <p>
      Use your Stakeholder Plan in making your weekly project decisions. Follow your plan if it makes sense, but also change your plan as project conditions change (i.e. poor customer relationship). When you start executing the project the budget amounts will be locked but you will still be able to update your Stakeholder Plan to track actual progress. It is good Project Management practice to keep your Stakeholder Plan up to date as stakeholder relationships change, even if the planned budget is locked.

    </p>
  </div>
);

export default StakeholderPlanningHelp;
