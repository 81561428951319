/* eslint-disable  */

import React from "react";
import jsPDF from "jspdf";
import { Layer, Rect, Stage, Group } from "react-konva";
import { Button } from "reactstrap";
import Konva from "konva";
import { connect } from "react-redux";
import { openModal } from "redux/Modal/ModalModule";
import { TASK_MODAL } from "routes/Modal/ModalTypes";
import "react-perfect-scrollbar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const BUTTON_HEIGHT = 55; // even so have a mid point to start arrow
const ARROW_START_POINT = 26; // mid height of box
// old NETWORK_BOXHEIGHT
const BUTTON_WIDTH = 105; // 130
// old NETWORK_BOXWIDTH

class NetworkDiagram extends React.Component {
  constructor(props) {
    super(props);
    this.stage = React.createRef();
    this.state = {
      taskPanelOpen: false,
      selectedTask: {},
      stageScale: 1,
      stageX: 0,
      stageY: 0
    };
  }

  handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    this.setState({
      stageScale: newScale,
      stageX:
        -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      stageY:
        -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
    });
  };

  componentDidMount() {
    // log stage react wrapper
    if (
      this.props &&
      this.props.nodeNetwork &&
      this.props.nodeNetwork.ColumnList
    ) {
      this.addComponents();
    }
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.project.runId !== prevProps.project.runId) {
      this.addComponents();
    }
  }

  arrowCoordinates = (startNode, endNode) => {
    let X1 = startNode.left + BUTTON_WIDTH;
    let Y1 = startNode.top + BUTTON_HEIGHT / 2;
    let X2 = endNode.left - 2;
    let Y2 = endNode.top + BUTTON_HEIGHT / 2;
    if (Y2 < Y1) {
      Y1 = startNode.top + BUTTON_HEIGHT / 3;
      Y2 = endNode.top + BUTTON_HEIGHT - BUTTON_HEIGHT / 3;
    } else if (Y2 > Y1) {
      Y1 = startNode.top + BUTTON_HEIGHT - BUTTON_HEIGHT / 3;
      Y2 = startNode.top + BUTTON_HEIGHT / 3;
    }
    return {
      x1: X1,
      y1: Y1,
      x2: X2,
      y2: Y2
    };
  };

  addComponents = () => {
    if (this.stage) {
      const stage = this.stage.current.getStage();
      // stage.width = window.innerWidth
      // stage.height = window.innerHeight
      const layer = new Konva.Layer();
      for (let col of this.props.nodeNetwork.ColumnList) {
        for (let _node of col.NodeList) {
          //  for (const _node of this.props.nodeNetwork) {
          const rect = new Konva.Rect({
            x: _node.left,
            y: _node.top,
            width: BUTTON_WIDTH,
            height: BUTTON_HEIGHT,
            stroke: _node.color,
            strokeWidth: 2,
            ref: _node.id,
            id: _node.id
          });
          rect.on("mouseover touchstart", function () {
            this.setFill("lightyellow");
            layer.draw();
          });

          rect.on("mouseout touchend", function () {
            this.setFill("transparent");
            layer.draw();
          });

          const nodeText = new Konva.Text({
            x: _node.left + 5,
            y: _node.top + 5,
            text: _node.text,
            width: BUTTON_WIDTH - 5,
            fontSize: 12,
            fontFamily: "Calibri",
            fill: _node.color,
            align: "center",
            wrap: "word",
            id: _node.id
          });
          const taskIdText = new Konva.Text({
            x: _node.left + 5,
            y: _node.top + 40,
            text: _node.id,
            width: 20,
            fontSize: 10,
            fontFamily: "Calibri",
            fill: _node.color,
            align: "center",
            wrap: "word",
            id: _node.id
          });

          // get assigned workers to this task
          let workerTasks = [];

          workerTasks = this.props.workerAssignmentList.filter(
            (wa) => wa.Id > 0 && wa.Task1 === _node.id.toString()
          );
          let workerString = "";
          if (workerTasks && workerTasks.length > 0) {
            workerString = workerTasks
              .map((worker) => worker.WorkerName)
              .join(", ");

            const assignedWorkers = new Konva.Text({
              x: _node.left,
              y: _node.top + BUTTON_HEIGHT + 5,
              text: workerString,
              width: 100,
              fontSize: 10,
              fontFamily: "Calibri",
              fill: "black",
              align: "left",
              wrap: "word",
              id: _node.id + 4
            });
            layer.add(assignedWorkers);
          } else {
            workerString = "";
          }

          // completed lines
          if (_node.state === "tsComplete") {
            const centerX = (_node.left + (_node.left + BUTTON_WIDTH)) / 2;
            const centerY = (_node.top + _node.top + BUTTON_HEIGHT) / 2;
            const endX = _node.left + (_node.left + BUTTON_WIDTH);
            const endY = _node.top + _node.top + BUTTON_HEIGHT;
            const diag = Math.sqrt(
              BUTTON_WIDTH * BUTTON_WIDTH + BUTTON_HEIGHT * BUTTON_HEIGHT
            );
            const completed1 = new Konva.Line({
              x: _node.left,
              y: _node.top,
              points: [0, 0, BUTTON_WIDTH, BUTTON_HEIGHT],
              stroke: "black",
              strokeWidth: 2,
              id: _node.id
            });
            const completed2 = new Konva.Line({
              x: _node.left,
              y: _node.top + BUTTON_HEIGHT,
              points: [0, 0, BUTTON_WIDTH, 0 - BUTTON_HEIGHT],
              stroke: "black",
              strokeWidth: 2,
              id: _node.id
            });
            layer.add(completed1);
            layer.add(completed2);
          }
          const arrows = [];

          if (
            _node.successorNodes !== "undefined" &&
            _node.successorNodes !== null &&
            _node.successorNodes &&
            _node.successorNodes.length > 0
          ) {
            for (const endNode of _node.successorNodes) {
              let X1 = _node.left + BUTTON_WIDTH; // 0 // (_node.left + BUTTON_WIDTH)
              let Y1 = _node.top + ARROW_START_POINT; //0 // (_node.top - BUTTON_HEIGHT / 2 )
              let X2 = endNode.left - (_node.left + BUTTON_WIDTH); //  (endNode.left)
              let Y2 = 0;
              if (endNode.top < _node.top) {
                // successor is above node on grid, need to send the arrow up
                Y2 = 0 - (_node.top - endNode.top) + ARROW_START_POINT / 2;
              } else if (endNode.top > _node.top) {
                // successor is below node in grid, need to send the arrow down
                Y2 = endNode.top - _node.top - ARROW_START_POINT / 2;
              }

              // having a hard time deciding who rules on the color of the Arrow
              // is it the successor or the predecessor?
              // for now, if anyone is non-critical, give the non-critical color black,
              const color =
                endNode.color === "black" || _node.color === "black"
                  ? "black"
                  : "red";
              layer.add(
                new Konva.Arrow({
                  x: X1,
                  y: Y1,
                  points: [0, 0, X2, Y2],
                  pointerLength: 5,
                  pointerWidth: 5,
                  fill: color,
                  stroke: color,
                  strokeWidth: 2
                })
              );
            }
          }
          layer.add(rect);
          layer.add(nodeText);
          layer.add(taskIdText);
        }
      }
      stage.add(layer);
    }
  };

  handleClick = (evt) => {
    const shape = evt.target;
    const filteredTasks = this.props.taskList.filter(
      (task) => task.TaskId === shape.getId()
    );
    const _thisTask = Object.assign({}, filteredTasks[0]);
    // find the workers and the skills and add them to the selected task object
    //  should do this once elsewhere

    _thisTask.assignedWorkers = [];
    for (const _worker of this.props.workerAssignmentList) {
      if (
        _worker.Task1 === _thisTask.TaskId ||
        _worker.Task2 === _thisTask.TaskId ||
        _worker.Task3 === _thisTask.TaskId
      ) {
        const _wobj = {};
        _wobj.Name = _worker.WorkerName;
        _wobj.Title = _worker.WorkerTitle;
        _wobj.Availability = _worker.Availability;
        _thisTask.assignedWorkers.push(_wobj);
      }
    }
    _thisTask.skillList = [];
    for (const _skill of this.props.taskSkillList) {
      if (_skill.TaskID === _thisTask.TaskId) {
        _thisTask.skillList.push(_skill.SkillName);
      }
    }
    this.setState({
      selectedTask: _thisTask,
      taskPanelOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      selectedTask: {},
      taskPanelOpen: false
    });
  };

  // printMe = () => {
  //   window.print();
  // };

  fitStageToParent = () => {
    var container = document.querySelector("#stage-parent");
    // now we need to fit stage into parent
    var containerWidth = container.offsetWidth;
    // to do this we need to scale the stage
    var scale = containerWidth / stageWidth;
    this.setState({
      stageWidth: this.state.stageWidth * scale,
      stageHeight: this.stage.stageHeight * scale,
      stageScale: { x: scale, y: scale }
    });
    // stage.width(stageWidth * scale);
    // stage.height(stageHeight * scale);
    // stage.scale({ x: scale, y: scale });
    // stage.draw();
  };

  printMe = () => {
    const uri = this.stage.current.toDataURL();

    const name = "network-diagram.png";

    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <div
        id={`stage-parent-${
          this.props.currentProjectWeek ? this.props.currentProjectWeek : 0
        }`}
        style={{ overflow: "scroll" }}
        width="500px"
        height="500px"
      >
        <div className="text-right">
          <Button color="primary" size="sm" onClick={() => this.printMe()}>
            <span style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faDownload} /> Export to PNG
            </span>
          </Button>
        </div>
        <Stage
          draggable={true}
          width={2000}
          height={450}
          onWheel={this.handleWheel}
          scaleX={this.state.stageScale}
          scaleY={this.state.stageScale}
          x={this.state.stageX}
          y={this.state.stageY}
          ref={this.stage}
          style={{ marginTop: "0px", marginLeft: "50px" }}
          name="NetworkNodeDiagram"
          onClick={this.showTaskInformation}
          onTouch={this.showTaskInformation}
          onTouchEnd={this.showTaskInformation}
          onTouchStart={this.showTaskInformation}
          onDragStart={() => {
            this.setState({
              isDragging: true
            });
          }}
          onDragEnd={(e) => {
            this.setState({
              isDragging: false,
              x: e.target.x(),
              y: e.target.y()
            });
          }}
        >
          {/* <Layer>
              {this.state.graphChildren}
            </Layer> */}
        </Stage>
      </div>
    );
  }
  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
	 MAPDISPATCHTOPROPS for the opoenModal function below * */
  showTaskInformation = (evt) => {
    const shape = evt.target;

    if (shape.parent !== null) {
      const filteredTasks = this.props.taskStatusList.filter(
        (task) => task.TaskId === shape.getId()
      );
      const _thisTask = Object.assign({}, filteredTasks[0]);
      this.props.showTaskInformation(_thisTask);
    }
  };
}

const mapStateToProps = (state) => ({
  project: state.project,
  currentProjectWeek: state.project.currentProjectWeek,
  taskStatusList: state.project.taskStatusList,
  taskSkillList: state.project.taskSkillList,
  workerAssignmentList: state.project.workerAssignmentList,
  nodeNetwork: state.project.nodeNetwork
});

const mapDispatchToProps = (dispatch) => ({
  showTaskInformation: (task) =>
    dispatch(openModal(TASK_MODAL, { task }, "modal-large"))
});

const NetworkDiagramContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkDiagram);

export default NetworkDiagramContainer;
