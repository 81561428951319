import React from "react";
import { SectionTitle } from "./SectionTitle";
import accounting from "accounting";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

export const ProjectStatus = props => (
  <div>
    <br />
    <SectionTitle titleText="Project Status Report" />
    <table className="table table-bordered" style={{ width: "50%" }}>
      <tbody>
        <tr>
          <td>The Project % complete</td>
          <td className="text-right">{props.properties.pctComplete}%</td>
        </tr>
        <tr>
          <td>Actual Cost</td>
          <td className="text-right">
            ${accounting.format(props.properties.actualcost, options)}
          </td>
        </tr>
        <tr>
          <td>Planned Value</td>
          <td className="text-right">
            ${accounting.format(props.properties.plannedvalue, options)}
          </td>
        </tr>
        <tr>
          <td>Earned Value</td>
          <td className="text-right">
            ${accounting.format(props.properties.earnedvalue, options)}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
