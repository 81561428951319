import React from "react";
import DataGrid from "react-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
import RemoveAction from "./RemoveAction";
import StaffTaskSelector from "./StaffTaskSelector";
import OvertimeEditor from "./OvertimeEditor";
import Resource from "components/Resource";

import "static-new/styles/vendor/react-data-grid.css";

class StaffingGrid extends React.Component {
  resourceHeaderFormatter = (value) => {
    return (
      <span style={{ paddingLeft: "10px" }} title={value.column.name}>
        {value.column.name}
      </span>
    );
  };

  makeColumns = () => {
    const columns = [
      {
        key: "ResourceName",
        name: "Resource",
        width: 250,
        resizable: true,
        frozen: true,
        headerRenderer: this.resourceHeaderFormatter,
        formatter: (cell, row) => {
          return (
            <div style={{ paddingLeft: "10px" }}>
              <Resource
                id={cell.row.Id}
                key={cell.row.ResourceName}
                row={cell.row}
                status={cell.row.WorkerStatusOnProject}
              />
            </div>
          );
        }
      },
      {
        key: "Task1",
        name: "Assigned",
        width: "*",
        resizable: true,
        frozen: true,
        sort: true,
        headerRenderer: (cell, row) => {
          return (
            <div
              id="tooltip-task1"
              style={{
                textAlign: "center"
              }}
            >
              {cell.column.name}
              <sup>
                <FontAwesomeIcon icon={faInfoCircle} />
              </sup>
              <UncontrolledTooltip placement="top" target="tooltip-task1">
                The worker's first priority. Can be changed to any other active
                tasks if necessary.
              </UncontrolledTooltip>
            </div>
          );
        },
        formatter(props) {
          return (
            <div style={{ textAlign: "center", backgroundColor: "#eef9f6" }}>
              {props.row[props.column.key]}
            </div>
          );
        },
        editor: (props) => {
          return (
            <StaffTaskSelector
              value={props.row[props.column.key]}
              row={props.row}
            />
          );
        },
        editorOptions: {
          editOnClick: true
        }
      },
      {
        key: "Task2",
        name: "Priority 2",
        width: "*",
        resizable: true,
        frozen: true,
        sort: true,
        headerRenderer: (cell, row) => {
          return (
            <div
              id="tooltip-task2"
              style={{
                textAlign: "center"
              }}
            >
              {cell.column.name}
              <sup>
                <FontAwesomeIcon icon={faInfoCircle} />
              </sup>
              <UncontrolledTooltip placement="top" target="tooltip-task2">
                The worker's second priority task - defaults to the next
                upcoming task in the Resource Plan. Change the resource plan to
                alter the worker's second priority task.
              </UncontrolledTooltip>
            </div>
          );
        },
        formatter(props) {
          return (
            <div style={{ textAlign: "center" }}>
              {props.row[props.column.key]}
            </div>
          );
        }
      },
      {
        key: "Task3",
        name: "Priority 3",
        width: "*",
        resizable: true,
        frozen: true,
        sort: true,
        headerRenderer: (cell, row) => {
          return (
            <div
              id="tooltip-task3"
              style={{
                textAlign: "center"
              }}
            >
              {cell.column.name}
              <sup>
                <FontAwesomeIcon icon={faInfoCircle} />
              </sup>
              <UncontrolledTooltip placement="top" target="tooltip-task3">
                The worker's third priority task - defaults to the next planned
                task after Priority #2 task (if any) in the Resource Plan.
                Change the resource plan to alter the worker's third priority
                task.
              </UncontrolledTooltip>
            </div>
          );
        },
        formatter(props) {
          return (
            <div style={{ textAlign: "center" }}>
              {props.row[props.column.key]}
            </div>
          );
        }
      },
      {
        key: "HoursOvertime",
        name: "Overtime",
        width: "*",
        resizable: true,
        frozen: true,
        sort: true,
        headerRenderer: (cell, row) => {
          return (
            <div
              id="tooltip-ot"
              style={{
                textAlign: "center"
              }}
            >
              {cell.column.name}
              <sup>
                <FontAwesomeIcon icon={faInfoCircle} />
              </sup>
              <UncontrolledTooltip placement="top" target="tooltip-ot">
                Up to 20 hours of overtime per week can be assigned to workers.
              </UncontrolledTooltip>
            </div>
          );
        },
        formatter(props) {
          return (
            <div style={{ textAlign: "center" }}>
              {props.row[props.column.key]}
            </div>
          );
        },
        editor: (props) => {
          return (
            <OvertimeEditor
              value={props.row[props.column.key]}
              row={props.row}
            />
          );
        },
        editorOptions: {
          editOnClick: true
        }
      },
      {
        key: "Id",
        name: "remove",
        frozen: true,
        isLastFrozenColumn: true,
        width: "*",
        resizable: true,
        headerRenderer: () => (
          <div style={{ textAlign: "center" }} id="tooltip-remove">
            Remove
               <sup>
                <FontAwesomeIcon icon={faInfoCircle} />
              </sup>
              <UncontrolledTooltip placement="top" target="tooltip-remove">
                Available starting week 3
              </UncontrolledTooltip>
             
          </div>
        ),
        formatter(props) {
          return (
            <div style={{ textAlign: "center" }}>
              <RemoveAction row={props.row} rowIdx={props.rowIdx} />
             
            </div>
          );
        }
      }
    ];

    
    return columns;
  };

  render() {

    var heightCalc = 50 + this.props.data.length * 60;
    if (heightCalc > 900) {
      heightCalc = 900
    }
    var height = heightCalc.toString() + "px";
    
    return (
      <DataGrid
        id={`staffing-grid-${this.props.productId}`}
        headerRowHeight={50}
        rowHeight={60}
        className="rdg-light"
        style={{
          minHeight: height,
          width: "100%",
          background: "transparent",
          overflow: "scroll"
        }}
        columns={this.makeColumns()}
        rows={this.props.data}
      />
    );
  }
}

export default StaffingGrid;
