import React from "react";
import { connect } from "react-redux"; // import XLSX from "xlsx";
import { createStudents } from "redux/Authentication/thunks";

const data = require("./students.json");

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      json: []
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.checkFile = this.checkFile.bind(this);
  }

  uploadFile() {
   this.props.createStudents(data);
  }

  checkFile() {
    this.setState({
      json: data
    });
  }

  render() {
    let iterKey = 1;
    return (
      <div>
        <span>read in json</span>
        <button onClick={this.checkFile}>Read</button>
        <button onClick={this.uploadFile}>Upload</button>
        <div>Count: {this.state.json.length}</div>
        {this.state.json.map(row => (
          <div key={(iterKey += 1)}>
            {row.first}
            {row.last}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  createStudents: studentList => dispatch(createStudents(studentList))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
