import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import {
  fetchStakeholderPlan,
  saveStakeholderItem,
  addActionToAll
} from "redux/Stakeholder/thunks";

import { getStakeholderActions } from "redux/Project/selectors";
import BudgetSummary from "routes/Budget/components/BudgetSummary";
import ResourceGrid from "../Resource/components/ResourceGrid";

import {
  toggleStakeholderOptions,
  toggleStakeholderInfo,
  toggleStakeholderBudget
} from "app/redux/actions";
import { openModal } from "redux/Modal/ModalModule";
import {
  REFERENCE_MODAL,
  HELP_DOCUMENTATION_MODAL
} from "routes/Modal/ModalTypes";
import StakeholderTable from "./components/StakeholderTable";
import StakeholderOptionsCosts from "./components/StakeholderOptionsCosts";
import StakeholderInfo from "./components/StakeholderInfo";
import CardHeaderBold from "components/styled/CardHeaderBold";
import classnames from "classnames";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";

const Stakeholder = (props) => {
  const [activeTab, setActiveTab] = useState("resource-plan");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    props.fetchStakeholderPlan(props.runId);
    // eslint-disable-next-line
  }, [props.runId]);

  return (
    <div>
      <Row>
        <Col lg="4">
          <StakeholderInfo
            panelVisible={props.stakeholderInfoVisible}
            toggle={props.toggleStakeholderInfo}
          />
        </Col>

       
        <Col lg="8">
          <BudgetSummary
            title="Stakeholder Budget"
            panelOpen={props.stakeholderBudgetVisible}
            togglePanel={props.toggleStakeholderBudget}
            summaryType="stakeholder"
            titleSummary="Stakeholder Relations"
            titleApproved="Approved for Stakeholders"
            titlePlanned="Planned for Stakeholders"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="9">
          <Card className="component-StakeholderPlan">
            <CardHeaderBold>Stakeholder Plan</CardHeaderBold>
            <CardBody>
              <StakeholderTable
                stakeholderPlan={props.stakeholderPlan}
                // actionTasks: array,
                // actionTaskOptions: array,
                detailInfoWeekMap={props.detailInfoWeekMap}
                saveStakeholderItem={props.saveStakeholderItem}
                addActionToAll={props.addActionToAll}
                runId={props.runId}
                displayProjectWeek={props.displayProjectWeek}
                projectedFinishWeek={props.projectedFinishWeek}
                stakeholderActions={props.stakeholderActions}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg="3">
          <StakeholderOptionsCosts
            panelVisible={props.stakeholderOptionsVisible}
            toggle={props.toggleStakeholderOptions}
          />


     </Col>
      </Row>

      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="resource-plan">
              <ResourceGrid noHideToggle />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stakeholderPlan: state.planning.stakeholder.stakeholderPlan,
  detailInfoWeekMap: state.planning.stakeholder.detailInfoWeekMap,
  runId: state.project.runId,
  projectedFinishWeek: state.project.projectedFinishWeek,
  displayProjectWeek: state.project.displayProjectWeek,
  stakeholderActions: getStakeholderActions(state),
  stakeholderOptionsVisible: state.app.stakeholderOptionsVisible,
  stakeholderInfoVisible: state.app.stakeholderInfoVisible,
  stakeholderBudgetVisible: state.app.stakeholderBudgetVisible
});

const mapDispatchToProps = (dispatch) => ({
  fetchStakeholderPlan: (runId) => dispatch(fetchStakeholderPlan(runId)),
  saveStakeholderItem: (data) => dispatch(saveStakeholderItem(data)),
  addActionToAll: (idList, weekNo, runId, newAction) =>
    dispatch(addActionToAll(idList, weekNo, runId, newAction)),
  toggleStakeholderOptions: () => dispatch(toggleStakeholderOptions()),
  toggleStakeholderInfo: () => dispatch(toggleStakeholderInfo()),
  toggleStakeholderBudget: () => dispatch(toggleStakeholderBudget()),
  showReferences: (selectedKey) =>
    dispatch(openModal(REFERENCE_MODAL, { selectedKey }, "modal-wide")),
  showHelp: (selectedKey) =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, { selectedKey }, "modal-wide"))
});

export default connect(mapStateToProps, mapDispatchToProps)(Stakeholder);
