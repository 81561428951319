const routes = {
  "/": "",
  "/welcome": "Welcome",
  "/planning/overview": "Overview",
  "/planning/budget": "Budget",
  "/planning/resource": "Resource",
  "/planning/risk": "Risk/Opportunities",
  "/planning/training": "Training",
  "/planning/stakeholder": "Stakeholder",
  "/planning/recognition": "Recognition",
  "/planning/approval": "Approval",
  "/reports": "Reports",
  "/reports/history": "History Reports",
   "/reports/budget": "Budget",
  "/reports/quality": "Quality Report",
  "/reports/eac": "EAC Report",
  "/reports/ev": "Earned Value Report",
  "/reports/epf": "Estimated Project Finish Report",
  "/reports/ppi": "PPI Report",
  "/reports/gantt": "Gantt Chart",
  "/reports/network": "Network Diagram",
  "/reports/labor": "Labor Breakdown Report",
    "/reports/reserve": "Reserve Breakdown Report",
  "/reports/pdf": "Create Report PDFs",
  "/notifications": "Notification History",
  "/projects": "Manage Projects",
  "/settings": "Manage Settings",
  "/execution/assignment": "Staffing",
    "/execution/budget": "Budget",
  "/execution/interaction": "Interaction Decisions",
  "/execution/workweek": "Work Week",
  "/instructor-dashboard": "Instructor Dashboard"
 
};
export default routes;
