import React, { Component } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import { Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import isExternal from "is-url-external";
import classNames from "classnames";
import * as menus from "./_nav";
/* These two can be used if needed */
// import SidebarFooter from './components/SidebarFooter';
// import SidebarHeader from './components/SidebarHeader';
import SidebarMinimizer from "./components/SidebarMinimizer";
// import { isDocumentElement } from "react-select/src/utils";

class Sidebar extends Component {
  // toggling the sideBar folders/subfolders
  handleClick = (e) => {
    e.preventDefault();

    const openedClass = document.getElementsByClassName("nav-dropdown open");

    [...openedClass].forEach((element) => {
      if (
        e.target.parentElement.classList.contains("main-category") &&
        element.classList.contains("main-category") &&
        !e.target.parentElement.isEqualNode(element)
      ) {
        element.classList.toggle("open");
      } else {
        if (
          !element.classList.contains("main-category") &&
          !e.target.parentElement.isEqualNode(element)
        ) {
          element.classList.toggle("open");
        }
      }
    });

    e.target.parentElement.classList.toggle("open");
  };

  activeRoute(itemName, props) {
    const parentSlug = `/${itemName.toLowerCase()}`;

    return props.location.pathname.indexOf(parentSlug) > -1
      ? "nav-item nav-dropdown open"
      : "nav-item nav-dropdown";
  }

  showProjectMenu = () => {
    const currentServer = window.location.href;
    console.log(currentServer)
    if (currentServer.includes("test") || currentServer.includes("localhost") ) {
      return true;
    }
    // if (
    //   this.props.productId === 50 &&
    //   this.props.user.ClassroomStatus === "Running" &&
    //   !this.props.username.toLowerCase().includes("paccissuser") &&
    //   !this.props.username.toLowerCase().includes("paccisstest")
    // ) {
    //   return false;
    // }
    return true;
  };
  renderMenu = () => {
    if (this.props.location.pathname === "/welcome") {
      return menus.welcomeMenu.items;
    }

    if (this.props.projectReadOnly === true) {
      return menus.reviewProjectMenu.items;
    } else if (this.props.isInstructor === true) {
      return menus.instructorMenu.items;
    } else if (this.props.noRunIdSelected === true) {
      return menus.noRunIdMenu.items;
    } else if (this.props.budgetIsLocked === true) {
      if (this.props.workWeekInProgress === true) {
        if (!this.showProjectMenu()) {
          return menus.workWeekInProgressMenu.items;
        } else {
          return menus.workWeekInProgressMenu.items;
        }
      }
      if (!this.showProjectMenu()) {
        // hide for pacciss classes for now
        // will probably morph into in person vs remote?
        return menus.postApprovalMenu.items.filter(
          (i) => i.url !== "/projects"
        );
      } else {
        return menus.postApprovalMenu.items;
      }
    }
    if (!this.showProjectMenu()) {
      // hide for pacciss classes for now
      // will probably morph into in person vs remote?
      return menus.preApprovalMenu.items.filter((i) => i.url !== "/projects");
    } else {
      return menus.preApprovalMenu.items;
    }
  };

  render() {
    const { props } = this;
    const { activeRoute } = this;
    const { handleClick } = this;
    // simple wrapper for nav-title item
    const wrapper = (item) =>
      item.wrapper && item.wrapper.element
        ? React.createElement(
            item.wrapper.element,
            item.wrapper.attributes,
            item.name
          )
        : item.name;

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{" "}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => <li key={key} className="divider" />;

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = classNames(item.class);
      const variant = classNames(
        "nav-link",
        item.variant ? `nav-link-${item.variant}` : ""
      );
      return (
        <NavItem key={key} className={classes}>
          {isExternal(item.url) ? (
            <RsNavLink href={item.url} activeClassName="active">
              <i className={item.icon} />
              {item.name}
            </RsNavLink>
          ) : item.url === "/documentation" ||
            item.url === "/planning" ||
            item.url === "/execution" ? (
            <Link to={item.url} className={variant} activeclassname="active">
              <i className={item.icon} />
              {item.name}
            </Link>
          ) : (
            <NavLink to={item.url} className={variant} activeclassname="active">
              <i className={item.icon} />
              {item.name}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => (
      <li
        key={key}
        className={`${activeRoute(item.name, props)} ${item.class}`}
      >
        <a
          href="/null"
          className={`nav-link nav-dropdown-toggle`}
          onClick={handleClick.bind(this)}
        >
          <i className={item.icon} />
          {item.name}
        </a>
        <ul className="nav-dropdown-items">{navList(item.children)}</ul>
      </li>
    );

    // nav link
    const navLink = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = (items) => items.map((item, index) => navLink(item, index));

    // sidebar-nav root
    return (
      <div className="sidebar">
        {/* <SidebarHeader /> */}
        <nav className="sidebar-nav" tourstop="sidebar">
          <Nav>{navList(this.renderMenu())}</Nav>
        </nav>
        {/* <SidebarFooter /> */}
        <SidebarMinimizer />
      </div>
    );
  }
}

export default withRouter(Sidebar);
