/* eslint-disable max-len */
import React from "react";

export const Training = productId => {

  const trainingCostSim = productId.productId;

  return (
    <div id="Training" className="style-background-components">
      <header>
        <h2>Uniworld Training Offerings</h2>
      </header>
      <div>
        <p>
          The training department at Uniworld offers several workshops designed to 
          increase a participant's knowledge in the areas of teamwork, leadership, 
          and technical competency. The workshops are offered in either a 2-day or 
          3-day format. The cost of the 2-day workshops is {trainingCostSim === 38 ? "$750" : "$1000"} and the cost of
          the 3-day workshops is {trainingCostSim === 38 ? "$1,050" : "$1500"}. The training department also offers a
          2-day long team building workshop intended to promote better teamwork
          and requires the entire project team to attend.
        </p>
        <p>
          Project managers may choose any of the following workshops for team
          members to attend:
        </p>
        <h4>Team Training</h4>
        <ul>
          <li>
            <a href="#t1">Communication and Interpersonal Skills</a>
          </li>
          <li>
            <a href="#t2">Project Management</a>
          </li>
          <li>
            <a href="#t3">Leadership</a>
          </li>
          <li>
            <a href="#t4">Quality and Quality Assurance</a>
          </li>
          <li>
            <a href="#t5">Requirements Analysis and Design</a>
          </li>
          <li>
            <a href="#t6">Implementation Methods and Technologies</a>
          </li>
          <li>
            <a href="#t7">Systems Testing and Deployment</a>
          </li>
          <li>
            <a href="#t8">Teambuilding</a>
          </li>
        </ul>
        <h4 id="t1">Communication and Interpersonal Skills</h4>
        <p>
          This workshop is intended for improving a participant's communication and 
          interpersonal skills through practical exercises and tool use. Specific 
          topics, skills and tools include: active listening, communication styles, 
          conflict management, diversity, negotiation, Myers-Briggs Type Indicator 
          (MBTI), and creativity. <br />
          <br />
        </p>
        <p>
          This is a 2-day workshop that meets on Monday and Tuesday. The cost of 
          this workshop is {trainingCostSim === 38 ? "$750" : "$1000"} per attendee
        </p>
        <h4 id="t2">Project Management</h4>
        <p>
          This workshop is intended for individuals desiring to learn the basics of 
          project management. Specific topics, skills and tools include: the project 
          management development process, Project Charter, Work Breakdown
          Structure, PERT Chart, Gantt Chart, Critical Path, and resource planning. 
          This workshop is computer simulation based and participants practice 
          planning and managing a "real-life" project while developing their skills.
        </p>
        <p>
          This is a 2-day workshop that meets on Monday and Tuesday. The cost of 
          this workshop is {trainingCostSim === 38 ? "$750" : "$1000"} per attendee.
        </p>
        <h4 id="t3">Leadership</h4>
        <p>
          This workshop is intended for individuals desiring to enhance their 
          leadership skills. Specific topics, skills and tools include: identifying the key 
          leadership skills, positive focus, effective questions (Enlightened 
          Leadership), building a high performance team, the five Key Leadership 
          Practices (Kouzes & Posner), and the Leadership Practices Inventory.
        </p>
        <p>
          This is a 2-day workshop that meets on Monday and Tuesday. The cost of 
          this workshop is {trainingCostSim === 38 ? "$750" : "$1000"} per attendee.
        </p>
        <h4 id="t4">Quality and Quality Assurance</h4>
        <p>
          This workshop is for learning about quality and what affects quality. It is 
          also an introduction to the process of quality assurance. Specific topics, 
          skills, and tools include: definition of quality, relationship of quality to the 
          other project variables, quality through customer relations, the quality 
          assurance four step cycle (plan, do, check, and act), and specific company 
          quality processes and tools.
        </p>
        <p>
          This is a 2-day workshop that meets on Monday and Tuesday. The cost of 
          this workshop is {trainingCostSim === 38 ? "$750" : "$1000"} per attendee.
        </p>
        <h4 id="t5">Requirements Analysis and Design</h4>
        <p>
          This workshop is intended for individuals desiring to develop their skills in
          project requirements analysis and design. Specific topics, skills, and tools 
          include: requirements identification and management, requirements 
          analysis and project specification, and design development techniques 
          and tools.
        </p>
        <p>
          This is a 3-day workshop that meets on Wednesday, Thursday, and 
          Friday. The cost of this workshop is {trainingCostSim === 38 ? "$1,050" : "$1500"} per attendee.
        </p>
        <h4 id="t6">Implementation Methods and Technologies</h4>
        <p>
          This workshop is intended for individuals desiring to develop their skills in
          project implementation techniques. Specific topics, skills, and tools 
          include: understanding design documents, mapping requirements to 
          implementation, specific company implementation techniques and tools,
          and implementation testing.
        </p>  
          This is a 3-day workshop that meets on Wednesday, Thursday, and 
          Friday. The cost of this workshop is {trainingCostSim === 38 ? "$1,050" : "$1500"} per attendee.
        < p>
        </p>
        <h4 id="t7">Systems Testing and Deployment</h4>
        <p>
          This workshop is intended for individuals desiring to develop their skills in 
          project testing, deployment, and support. Specific topics, skills, and tools 
          include: developing and writing test documentation, performing project 
          level testing, project deployment, and ongoing project support processes 
          and tools.
        </p>
        <p>
          This is a 3-day workshop that meets on Wednesday, Thursday, and 
          Friday. The cost of this workshop is {trainingCostSim === 38 ? "$1,050" : "$1500"} per attendee.
        </p>
        <h4 id="t8">Teambuilding</h4>
        <p>
          This workshop requires the entire team to attend and is designed to 
          promote better teamwork. Specific topics, skills, and tools include: 
          identifying and understanding behavior typing such as the Myers-Briggs 
          Type Indicator (MBTI), dealing with conflict, learning effective listening, 
          and building team consensus.
        </p>
        <p>
          This is a 2-day workshop that meets on Wednesday and Thursday. The
          cost of this workshop is {trainingCostSim === 38 ? "$15,000" : "$13,000"}.
      </p>
      </div>
    </div>
  );
};
