import { createAction } from "redux-actions";

import {
  PROCESS_EOD,
  PROCESS_EOW,
  PROCESS_EOP,
  PROCESS_EOS,
  RESET_WORKWEEK,
  PROCESS_CONDITION,
  PROCESS_CONDITION_COMPLETE,
  PROCESS_REASSIGN,
  PROCESS_REASSIGN_COMPLETE,
  PLAY_SCRIPT,
  REQUESTING_DATA,
  HIDE_CONDITION_PANEL,
  WORKWEEK_ERROR,
  SET_CONDITION_RESPONSE,
  RECEIVE_PRESENTATION_RUNDAY,
  PROCESS_STAFFING_PROBLEMS,
  RUN_WEEK_STARTED,
  CANSTUDENTRUN_SUCCESS,
  CANSTUDENTRUN_ERROR,
  RECEIVE_CONDITIONS,
  ENABLE_CONDITIONS_SUCCESS,
  RESET_CONDITIONS_SUCCESS,
  TOGGLE_AUTORUN,
  SET_AUTORUN_RESPONSETYPE
} from "./types";

export const startRunWeek = createAction(RUN_WEEK_STARTED, payload => payload);

export const processEOP = createAction(PROCESS_EOP, payload => payload);
export const processEOW = createAction(PROCESS_EOW, payload => payload);
export const processEOD = createAction(PROCESS_EOD, payload => payload);
export const processEOS = createAction(PROCESS_EOS, payload => payload);

export const toggleAutoRun = createAction(TOGGLE_AUTORUN, payload => payload);
export const setAutoRunResponse = createAction(SET_AUTORUN_RESPONSETYPE, payload => payload);


export const processStaffingProblems = createAction(
  PROCESS_STAFFING_PROBLEMS,
  payload => payload
);
export const playScript = createAction(PLAY_SCRIPT, payload => payload);
export const setConditionResponse = createAction(
  SET_CONDITION_RESPONSE,
  payload => payload
);

export const setPresentationRunDay = createAction(
  RECEIVE_PRESENTATION_RUNDAY,
  payload => payload
);
export const hideConditionPanel = createAction(HIDE_CONDITION_PANEL);
export const processCondition = createAction(
  PROCESS_CONDITION,
  payload => payload
);

export const conditionComplete = createAction(
  PROCESS_CONDITION_COMPLETE,
  payload => payload
);
export const processReassign = createAction(
  PROCESS_REASSIGN,
  payload => payload
);
export const reassignComplete = createAction(
  PROCESS_REASSIGN_COMPLETE,
  payload => payload
);
export const requestingData = createAction(REQUESTING_DATA);

export const workWeekError = createAction(WORKWEEK_ERROR, payload => payload);
export const resetWorkWeek = createAction(RESET_WORKWEEK);

export const canStudentRunSuccess = createAction(
  CANSTUDENTRUN_SUCCESS,
  payload => payload
);
export const canStudentRunError = createAction(
  CANSTUDENTRUN_ERROR,
  error => error
);

export const getHeadConditionsSuccess = createAction(
  RECEIVE_CONDITIONS,
  payload => payload
);
export const setEnabledConditionsSuccess = createAction(
  ENABLE_CONDITIONS_SUCCESS,
  payload => payload
);
export const resetConditionsSuccess = createAction(
  RESET_CONDITIONS_SUCCESS,
  payload => payload
);
