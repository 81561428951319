import React from "react";
import imageURLs from "./images/imageURLs.js";

const LaborBreakdownReportHelp = () => {

  const openWindow = () => {
    window.open(
      "https://www.simproject.com/documentation/policies/overtime");
  };

  return (
    <div>
      <h3>Labor <span >Breakdown Reports</span> Info</h3>

      <p>
        The Labor Breakdown Reports table provides additional detail into the labor hours and costs on the project. The reports provide detailed data on:
    </p>

      <ul>
        <li>	Labor hours/costs charged to tasks versus charged to Reserves.
    <ul>
            <li>When assigned to an active task, labor hours are charged to the assigned task</li>
            <li>When assigned to an inactive task, labor hours are charged to Reserves. Inactive tasks are tasks that are either:
            <ul>
                <li>In Delay status or,</li>
                <li>Waiting for a predecessor task to complete</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>	Labor hours/costs not spent working directly on a task. These include hours spent in:
        <ul>
            <li>Team meetings</li>
            <li>Individual conference</li>
            <li>Training</li>
            <li>Stakeholder visit</li>
            <li>Absent from the project</li>
          </ul>
        </li>
        <li>Labor hours/costs by resource</li>
        <li>Labor hours/costs by task</li>
        <li>Total overtime hours and overtime hours by resource. Note - the total cost does not include overtime hours since there is no charge to the project for overtime hours. See the Overtime company policy in the Project Documentation for more information.</li>
      </ul>
      <p>This detailed labor hours/costs breakdown is provided via four reports:</p>
      <ul>
        <li>
          <p><span ><b>Task Related Labor Hours/Costs – </b> </span>provides weekly and total labor hours by task and total costs by task. These are labor hours resources are working on active tasks. It also includes labor hours resources are assigned to inactive (delayed or waiting for a predecessor to complete) tasks which are charged to reserves. Note – these costs are included in the Cost column and the Budget Total Labor Costs item. </p><br></br>
          {/* <h5 style={{ fontSize: "14px" }}>Labor Breakdown Reports</h5> */}
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "5px" }}

            src={imageURLs.ResourceTask1}
            alt="report"
          />
        </li>
        <li>
          <p><span >	<b>Non-Task Related Labor Hours/Costs –</b> </span> provides weekly and total labor hours by non-task related activities and total costs by activity. These are labor hours resources are <b>not working</b> on active tasks. It also includes activity labor hours resources are assigned to inactive (delayed or waiting for a predecessor to complete) tasks which are charged to reserves. Note – these costs are included in the Cost column and the Budget Total Labor Costs item. </p><br></br>
          {/* <h5 style={{ fontSize: "14px" }}>Labor Breakdown Reports</h5> */}
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "5px" }}

            src={imageURLs.NonTask}
            alt="report"
          />
        </li>
        <li>
          <p><span ><b>By Resource Labor Hours/Costs -</b>	 </span>provides weekly and total task labor hours by resource and total task costs by resource. These are labor hours resources are working on <b>active tasks only</b>. This table includes overtime hours by resource. Note - the total cost does not include overtime hours since there is no charge to the project for overtime hours. See the Overtime company policy in the Project Documentation for more information <span onClick={() => openWindow()} style={{cursor:"pointer",color: "#5378ad"}}>Click here</span>.</p><br></br>
          {/* <h5 style={{ fontSize: "14px" }}>Labor Breakdown Reports</h5> */}
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "5px" }}

            src={imageURLs.ResourceHours}
            alt="report"
          />
        </li>
        <li style={{ marginTop: "40px" }}>
          <p><span ><b>	By Task Labor Hours/Costs –  </b></span>provides total task labor hours and costs. These are labor hours resources are working on active tasks only.</p><br></br>
          {/* <h5 style={{ fontSize: "14px" }}>Labor Breakdown Reports</h5> */}
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "5px" }}

            src={imageURLs.TaskRelated}
            alt="report"
          />
        </li>
      </ul>
      <h3 >Additional Calculations:</h3>
      <ul>
        <li>Total Hours Not Working on a Task = (Task Related Reserve Hours + Non-Task Related Reserve Hours + Non-Task Related Activity Hours) <span>(#1+#2+#3)</span></li>
        <li>Labor Productivity % = Total Task Labor Hours <span>(#6)</span>/ Total Labor Hours <span >(#2+#3+#4)</span></li>
        <li>Percent of work effort that is overtime = Total Overtime Hours <span >(#5)</span>/ Total Task Labor Hours <span>(#6)</span></li>
      </ul>
    </div>
  )
}

export default LaborBreakdownReportHelp;
