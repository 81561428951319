import React from "react";
import imageURLs from "./images/imageURLs.js";

const GettingStartedHelp = () => (
  <div>
    <h3>Getting Started - Manage Projects</h3>
    <p>
      Start new and review completed projects through the Manage Projects menu selection. With your license you can run your project from beginning to end three times. You can only run one project at a time. A previous project (run) must be completed or abandoned before the next project can be started. Your instructor may or may not allow abandoning projects. An abandoned project allows you to continue to your next project without finishing your current one. Contact your instructor if you want to abandon a project so you can start over again on your next project.

    </p>
    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "10px"}}

      src={imageURLs.help6}
      alt="image6"
    />

    <p>
      Your instructor can control whether you can plan and execute each project. As you move through a project, you may get messages that an action you are attempting has temporarily been blocked or stopped. These blocks and stops are under the control of your instructor.

    </p>
  </div>
);

export default GettingStartedHelp;
