import { handleActions } from "redux-actions";

import {
  ERROR_ON_FETCH,
  RECEIVE_INTERACTIONS,
  DELETE_CONFERENCE_SUCCESS,
  DELETE_MEETING_SUCCESS,
  DELETE_RECOGNITION_SUCCESS,
  DELETE_STAKEHOLDER_SUCCESS,
  DELETE_TRAINING_SUCCESS,
  //DELETE_INTERACTION_ERROR,
  UPDATE_CONFERENCE_SUCCESS,
  UPDATE_MEETING_SUCCESS,
  UPDATE_RECOGNITION_SUCCESS,
  UPDATE_STAKEHOLDER_SUCCESS,
  UPDATE_TRAINING_SUCCESS,
  //UPDATE_INTERACTION_ERROR,
  INSERT_CONFERENCE_SUCCESS,
  INSERT_MEETING_SUCCESS,
  INSERT_RECOGNITION_SUCCESS,
  INSERT_STAKEHOLDER_SUCCESS,
  INSERT_TRAINING_SUCCESS,
  //INSERT_INTERACTION_ERROR,
  INSERT_STATUSREPORTS_SUCCESS
} from "./types";

const initialState = {
  weeklyInteractions: [],
  conferenceInteractions: [],
  indvRecognitionInteractions: [],
  meetingInteractions: [],
  stakeholderInteractions: [],
  teamRecognitionInteractions: [],
  trainingInteractions: []
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [RECEIVE_INTERACTIONS]: (state, { payload }) => {
      return {
        ...state,
        weeklyInteractions: payload.interactionDisplayList,
        conferenceInteractions: payload.conferenceInteractions,
        indvRecognitionInteractions: payload.indvRecognitionInteractions,
        meetingInteractions: payload.meetingInteractions,
        stakeholderInteractions: payload.stakeholderInteractions,
        teamRecognitionInteractions: payload.teamRecognitionInteractions,
        trainingInteractions: payload.trainingInteractions
      };
    },
    [INSERT_CONFERENCE_SUCCESS]: (state, { payload }) => {
      let newConf = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: [...state.weeklyInteractions, newWeekly],
        conferenceInteractions: [...state.conferenceInteractions, newConf]
      });
    },
    [UPDATE_CONFERENCE_SUCCESS]: (state, { payload }) => {
      let newConf = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      const wIndex = state.weeklyInteractions.findIndex(
        m => m.Id === newWeekly.Id
      );
      const cIndex = state.conferenceInteractions.findIndex(
        m => m.Id === newConf.Id
      );

      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: state.weeklyInteractions
          .slice(0, wIndex)
          .concat(newWeekly)
          .concat(state.weeklyInteractions.slice(wIndex + 1)),
        conferenceInteractions: state.conferenceInteractions
          .slice(0, cIndex)
          .concat(newConf)
          .concat(state.conferenceInteractions.slice(cIndex + 1))
      });
    },
    [DELETE_CONFERENCE_SUCCESS]: (state, { payload }) => {
      let ci = state.conferenceInteractions.filter(conf => conf.Id !== payload);
      let wi = state.weeklyInteractions.filter(weekly => weekly.Id !== payload);
      return {
        ...state,
        conferenceInteractions: ci,
        weeklyInteractions: wi
      };
    },
    [INSERT_MEETING_SUCCESS]: (state, { payload }) => {
      let newMtg = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: [...state.weeklyInteractions, newWeekly],
        meetingInteractions: [...state.meetingInteractions, newMtg]
      });
    },
    [UPDATE_MEETING_SUCCESS]: (state, { payload }) => {
      let newRec = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      const wIndex = state.weeklyInteractions.findIndex(
        m => m.Id === newWeekly.Id
      );
      const cIndex = state.meetingInteractions.findIndex(
        m => m.Id === newRec.Id
      );

      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: state.weeklyInteractions
          .slice(0, wIndex)
          .concat(newWeekly)
          .concat(state.weeklyInteractions.slice(wIndex + 1)),
        meetingInteractions: state.meetingInteractions
          .slice(0, cIndex)
          .concat(newRec)
          .concat(state.meetingInteractions.slice(cIndex + 1))
      });
    },
    [DELETE_MEETING_SUCCESS]: (state, { payload }) => {
      let mi = state.meetingInteractions.filter(
        meeting => meeting.Id !== payload
      );
      let wi = state.weeklyInteractions.filter(weekly => weekly.Id !== payload);
      return {
        ...state,
        meetingInteractions: mi,
        weeklyInteractions: wi
      };
    },
    [INSERT_RECOGNITION_SUCCESS]: (state, { payload }) => {
      let newRec = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      if (newRec["RewardType"] === "TEAM") {
        return Object.assign({}, state, {
          ...state,
          weeklyInteractions: [...state.weeklyInteractions, newWeekly],
          teamRecognitionInteractions: [
            ...state.teamRecognitionInteractions,
            newRec
          ]
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          weeklyInteractions: [...state.weeklyInteractions, newWeekly],
          indvRecognitionInteractions: [
            ...state.indvRecognitionInteractions,
            newRec
          ]
        });
      }
    },
    [UPDATE_RECOGNITION_SUCCESS]: (state, { payload }) => {
      let newRec = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      const wIndex = state.weeklyInteractions.findIndex(
        m => m.Id === newWeekly.Id
      );
      const cIndex = state.meetingInteractions.findIndex(
        m => m.Id === newRec.Id
      );

      if (newRec["RewardType"] === "TEAM") {
        return Object.assign({}, state, {
          ...state,
          weeklyInteractions: state.weeklyInteractions
            .slice(0, wIndex)
            .concat(newWeekly)
            .concat(state.weeklyInteractions.slice(wIndex + 1)),
          teamRecognitionInteractions: state.teamRecognitionInteractions
            .slice(0, cIndex)
            .concat(newRec)
            .concat(state.teamRecognitionInteractions.slice(cIndex + 1))
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          weeklyInteractions: state.weeklyInteractions
            .slice(0, wIndex)
            .concat(newWeekly)
            .concat(state.weeklyInteractions.slice(wIndex + 1)),
          indvRecognitionInteractions: state.indvRecognitionInteractions
            .slice(0, cIndex)
            .concat(newRec)
            .concat(state.indvRecognitionInteractions.slice(cIndex + 1))
        });
      }
    },
    [DELETE_RECOGNITION_SUCCESS]: (state, { payload }) => {
      let tri = state.teamRecognitionInteractions.filter(
        tre => tre.Id !== payload
      );
      let iri = state.indvRecognitionInteractions.filter(
        ire => ire.Id !== payload
      );
      let wi = state.weeklyInteractions.filter(weekly => weekly.Id !== payload);
      return {
        ...state,
        teamRecognitionInteractions: tri,
        indvRecognitionInteractions: iri,
        weeklyInteractions: wi
      };
    },
    [INSERT_STAKEHOLDER_SUCCESS]: (state, { payload }) => {
      let newStk = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: [...state.weeklyInteractions, newWeekly],
        stakeholderInteractions: [...state.stakeholderInteractions, newStk]
      });
    },
    [INSERT_STATUSREPORTS_SUCCESS]: (state, { payload }) => {
      // comes back with lists of dtos and display objs
      // append them to the current collections
      let newDTOs = payload.dtoList;
      let newItems = payload.displayList;
      let newWI = [...state.weeklyInteractions, ...newItems];
      let newSI = [...state.stakeholderInteractions, ...newDTOs];
      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: newWI,
        stakeholderInteractions: newSI
      });
    },
    [UPDATE_STAKEHOLDER_SUCCESS]: (state, { payload }) => {
      let newRec = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      const wIndex = state.weeklyInteractions.findIndex(
        m => m.Id === newWeekly.Id
      );
      const cIndex = state.stakeholderInteractions.findIndex(
        m => m.Id === newRec.Id
      );

      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: state.weeklyInteractions
          .slice(0, wIndex)
          .concat(newWeekly)
          .concat(state.weeklyInteractions.slice(wIndex + 1)),
        stakeholderInteractions: state.stakeholderInteractions
          .slice(0, cIndex)
          .concat(newRec)
          .concat(state.stakeholderInteractions.slice(cIndex + 1))
      });
    },
    [DELETE_STAKEHOLDER_SUCCESS]: (state, { payload }) => {
      let si = state.stakeholderInteractions.filter(st => st.Id !== payload);
      let wi = state.weeklyInteractions.filter(weekly => weekly.Id !== payload);
      return {
        ...state,
        stakeholderInteractions: si,
        weeklyInteractions: wi
      };
    },
    [INSERT_TRAINING_SUCCESS]: (state, { payload }) => {
      // payload is an array

      let trainingList = payload.trainingList;
      let displayList = payload.displayList;
      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: [...state.weeklyInteractions, ...displayList],
        trainingInteractions: [...state.trainingInteractions, ...trainingList]
      });
    },
    [UPDATE_TRAINING_SUCCESS]: (state, { payload }) => {
      let newConf = payload.thisDTO;
      let newWeekly = payload.displayDTO;
      const wIndex = state.weeklyInteractions.findIndex(
        m => m.Id === newWeekly.Id
      );
      const cIndex = state.trainingInteractions.findIndex(
        m => m.Id === newConf.Id
      );

      return Object.assign({}, state, {
        ...state,
        weeklyInteractions: state.weeklyInteractions
          .slice(0, wIndex)
          .concat(newWeekly)
          .concat(state.weeklyInteractions.slice(wIndex + 1)),
        trainingInteractions: state.trainingInteractions
          .slice(0, cIndex)
          .concat(newConf)
          .concat(state.trainingInteractions.slice(cIndex + 1))
      });
    },
    [DELETE_TRAINING_SUCCESS]: (state, { payload }) => {
      // payload should be list of ids to remove from arrays

      let displayList = [];
      let dtoList = [];

      dtoList = state.trainingInteractions.filter(t => !payload.includes(t.Id));
      displayList = state.weeklyInteractions.filter(
        w => !payload.includes(w.Id)
      );

      return {
        ...state,
        trainingInteractions: dtoList,
        weeklyInteractions: displayList
      };
    },
    [ERROR_ON_FETCH]: (state, { payload }) => {
      return {
        ...state
      };
    }
  },
  initialState
);
