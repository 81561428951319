/* eslint-disable max-len */
import React from "react";

export const Recognition = props => {
  return (
    <div id="Recognition" className="style-background-components">
      <header>
        <h2>Individual and Team Recognition</h2>
      </header>
      <div>
        <p>
          Uniworld offers many options for recognizing project personnel for
          their contributions.
        </p>
        <p>
          Project managers can choose to recognize the entire team as a whole,
          or specific individuals on the team. Each recognition option has an
          associated cost to the project and team recognition costs are incurred
          on an accumulated individual basis. Individual recognition must be
          associated with a specific individual on the project team. Project
          managers can choose from any of the following team and individual
          recognition actions on a weekly basis:
        </p>
        <h4>Team Recognition</h4>
        <ul>
          <li>
            <a href="#t1">Weekend Getaway</a>
          </li>
          <li>
            <a href="#t2">Entertainment</a>
          </li>
          <li>
            <a href="#t3">Family Picnic</a>
          </li>
          <li>
            <a href="#t4">Party</a>
          </li>
          <li>
            <a href="#t5">Dinner</a>
          </li>
          <li>
            <a href="#t6">Company Logo Trinkets</a>
          </li>
          <li>
            <a href="#t7">Pizza Lunch</a>
          </li>
        </ul>
        <h4>Individual Recognition</h4>
        <ul>
          <li>
            <a href="#i1">$1000 cash bonus</a>
          </li>
          <li>
            <a href="#i2">$500 cash bonus</a>
          </li>
          <li>
            <a href="#i3">VIP parking spot</a>
          </li>
          <li>
            <a href="#i4">$100 cash bonus</a>
          </li>
          <li>
            <a href="#i5">Dinner for 2</a>
          </li>
          <li>
            <a href="#i6">Entertainment</a>
          </li>
          <li>
            <a href="#i7">Company Logo Trinkets</a>
          </li>
        </ul>
        <h4 id="t1">Weekend Getaway</h4>
        <p>
          Each individual on the team is given up to $1,000 for use on a team
          weekend getaway. The team decides where to go and where to stay and
          makes all of the necessary reservations. Spouses and significant
          others are welcome on the weekend getaway. Team members submit expense
          reports for airfare, hotel, car rental and incidentals. The cost to
          the project is not to exceed $1000 per team member.
        </p>
        <h4 id="t2">Entertainment</h4>
        <p>
          Each individual on the team is given up to $150 for use on team
          entertainment. The team decides where and when to go and makes all of
          the necessary reservations. Spouses and significant others are
          welcome. Team members submit expense reports for the entertainment and
          incidentals. The cost to the project is not to exceed $150 per team
          member.
        </p>
        <h4 id="t3">Family Picnic</h4>
        <p>
          The team is provided with a family outing and picnic lunch at the
          local family fun park and picnic facilities. The team decides when to
          go (weekend day) and makes all of the necessary reservations. The park
          includes amusement rides, swimming, tennis, and other fun activities.
          Team member families are included. The cost to the project is $100 per
          team member.
        </p>
        <h4 id="t4">Party</h4>
        <p>
          The team decides where and when to hold the party, makes the necessary
          reservations and orders the food and drink. Spouses and significant
          others are welcome. The cost to the project is $35 per team member.
        </p>
        <h4 id="t5">Dinner</h4>
        <p>
          The team decides where and when to have dinner and makes the necessary
          reservations. This recognition activity is for team members only. The
          cost to the project is $35 per team member.
        </p>
        <h4 id="t6">Company Logo Trinkets</h4>
        <p>
          The team decides what trinkets to order. This includes any special
          designs and engraving. Example trinkets are: mugs, pens, bags,
          umbrellas, hats, and shirts. The cost to the project is $25 per team
          member.
        </p>
        <h4 id="t7">Pizza Lunch</h4>
        <p>
          The team is treated to pizza for lunch in the Uniworld cafeteria. The
          cost to the project is $10 per team member.
        </p>
        <h4 id="i1">$1,000 cash bonus</h4>
        <p>
          The recognized individual will receive a cash bonus of $1,000, minus
          the required withholdings. The cost to the project is $1,000.
        </p>
        <h4 id="i2">$500 cash bonus</h4>
        <p>
          The recognized individual will receive a cash bonus of $500, minus the
          required withholdings. The cost to the project is $500.
        </p>
        <h4 id="i3">VIP parking spot</h4>
        <p>
          Recognized individuals are permitted to park in a reserved spot close
          to the building entrance for a period of one month. The cost to the
          project is $300.
        </p>
        <h4 id="i4">$100 cash bonus</h4>
        <p>
          The recognized individual will receive a cash bonus of $100, minus the
          required withholdings. The cost to the project is $100.
        </p>
        <h4 id="i5">Dinner for 2</h4>
        <p>
          Recognized individuals are permitted to take themselves and one other
          person to dinner at a place of their choice and submit an expense
          report. The cost to the project is not to exceed $150.
        </p>
        <h4 id="i6">Entertainment</h4>
        <p>
          Recognized individuals are permitted to take themselves and others to
          an entertainment place of their choice and submit an expense report.
          The cost to the project is not to exceed $150
        </p>
        <h4 id="i7">Company Logo Trinkets</h4>
        <p>
          The individual decides what trinket to order. This includes any
          special designs and engraving. Example trinkets are: mugs, pens, bags,
          umbrellas, hats, and shirts. The cost to the project is $25.
        </p>
      </div>
      <footer>
        <p>ID #1002</p>
      </footer>
    </div>
  );
};
