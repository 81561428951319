import React from "react";
import ResourceImage from "components/ResourceImage";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';

const ConditionComp = props => {
  let iterKey = 1;

  //different methods used to render the avatars for the people in the different simulation so I used the productId to make sure the avatars are rendering correctly
  let renderGoodImages = () => {
    return props.productId !== 50 && props.productId !== 51 ? (
      <ResourceImage
        key={(iterKey += 1)}
        png={
          props.properties.worker === "Buki"
            ? "Assistant.png"
            : `${props.properties.worker
              .replace(/\s+/g, "")
              .replace(/\./g, "")}.png`
        }
        alt={props.properties.worker}
      />
    ) : props.properties.avatar && props.properties.avatar !== null && props.properties.avatar !== "" ?
        (
          <ResourceImage
            key={(iterKey += 1)}
            png={`${props.properties.avatar}.png`}
            alt={props.properties.worker}
          />
        ) : <span />;
  };
  if (props.properties.isSilent && props.properties.isSilent === "1") {
    return <span />
  }

  return (
    <div className="report-Item">
      <div className="report-Item_Icon">
        <div className="resource-Profile">{renderGoodImages()}</div>
        <div className="resource-Body">
          <p>
            {props.properties.worker === "Buki"
              ? "Assistant"
              : props.properties.worker}
          </p>
        </div>
      </div>
      <div className="report-Item_Content">
        {ReactHtmlParser(props.properties.message)}
        <p>
          <strong>
            <em>Your response:</em>
          </strong>{" "}
        </p>

        <p>{props.properties.response}</p>


        {Object.prototype.hasOwnProperty.call(props.properties, "condId") ? (
          <p>
            <small>{props.properties.condId}</small>
          </p>
        ) : (
            <p>
              <small />
            </p>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  productId: state.auth.productId
});

export const Condition = connect(mapStateToProps, null)(ConditionComp);
