import React from "react";
import {
  CardBody,
  Button,
  InputGroupAddon,
  Input,
  InputGroup,
  Form,
  FormFeedback
} from "reactstrap";

import "../Registration.css";

const securityQuestions = [
  "Name of your favorite childhood friend?",
  "What was your childhood nickname?",
  "Make and model of your first car?",
  "In what city or town was your first job?",
  "Where did you vacation last year?",
  "What is your maternal grandmother's maiden name?",
  "What is your pet's name?",
  "What school did you attend for sixth grade?",
  "In what year was your father born?",
  "What was the color of your first car?"
];

class PartTwo extends React.Component {
  constructor() {
    super();
    this.state = {
      p1: "",
      p2: "",
      securityQuestion: securityQuestions[0],
      securityAnswer: "",
      registrationError: "",
      validation: {
        p1State: "success",
        p2State: "success"
      },
      passwordRegex: /^(?=[\w!@.#$%^&*()+]{7,})(?:.*[!@.#$%^&*()+]+.*)$/
    };

    this.submitRegistration = this.submitRegistration.bind(this);
    this.verifySubmission = this.verifySubmission.bind(this);
    this.handleSecurityQuestionChange =
      this.handleSecurityQuestionChange.bind(this);
    this.handleP1Change = this.handleP1Change.bind(this);
    this.handleP2Change = this.handleP2Change.bind(this);
    this.handleSecurityAnswerChange =
      this.handleSecurityAnswerChange.bind(this);
  }

  handleSecurityQuestionChange = (event) => {
    this.setState({
      securityQuestion: event.target.value
    });
  };
  handleSecurityAnswerChange = (event) => {
    this.setState({
      securityAnswer: event.target.value
    });
  };

  handleP1Change = (event) => {
    const { validation } = this.state;
    if (this.state.passwordRegex.test(event.target.value)) {
      validation.p1State = "success";
    } else {
      validation.p1State = "failure";
    }
    this.setState({
      validation: validation,
      p1: event.target.value
    });
  };

  handleP2Change = (event) => {
    const { validation } = this.state;
    if (this.state.p1 === event.target.value) {
      validation.p2State = "success";
    } else {
      validation.p2State = "failure";
    }
    this.setState({
      validation: validation,
      p2: event.target.value
    });
  };

  verifySubmission(event, values) {
    if (!this.state.p1) {
      this.setState({
        registrationError: "Please enter a permanent password."
      });

      return;
    }

    if (!this.state.p2) {
      this.setState({
        registrationError: "Please enter a permanent password."
      });

      return;
    }

    if (!this.state.securityAnswer) {
      this.setState({
        registrationError: "Please select a Security Question and Answer."
      });

      return;
    }
    this.setState({ registrationError: "" });

    this.submitRegistration();
  }

  submitRegistration() {
    this.props.submit(
      this.props.username,
      this.state.securityQuestion,
      this.state.securityAnswer,
      this.state.p1
    );
  }

  render() {
    return (
      <CardBody
        className="card-body1"
        style={{ overflow: "hidden", width: "500px" }}
      >
        <p style={{ color: "red" }}>{this.state.registrationError}</p>
        <Form>
          <InputGroup className="mb-3">
            <InputGroupAddon
              addonType="prepend"
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              <i className="fa fa-user" />
            </InputGroupAddon>
            <Input
              type="text"
              name="username"
              value={this.props.username}
              ref={(input) => {
                this.usernameInput = input;
              }}
              className="form-control"
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1">
            <InputGroupAddon
              addonType="prepend"
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              <i className="fa fa-lock" />
            </InputGroupAddon>
            <Input
              type="password"
              name="newpassword"
              className="password__input"
              value={this.state.p1}
              invalid={
                this.state.validation.p1State === "failure" ? true : false
              }
              ref={(input) => {
                this.passwordInput = input;
              }}
              placeholder="New Password"
              required
              onChange={this.handleP1Change}
            />
          </InputGroup>
          <br />
          {/* <span>
            <FormFeedback
              valid={this.state.validation.p1State === "failure" ? false : true}
            >
              Passwords must be at least 7 characters and contain at least one
              special character such as a period or a pound sign.
            </FormFeedback>
          </span> */}
          <InputGroup className="mb-4">
            <InputGroupAddon
              addonType="prepend"
              style={{ marginRight: "5px", marginTop: "5px" }}
            >
              <i className="fa fa-lock" />
            </InputGroupAddon>
            <Input
              value={this.state.p2}
              type="password"
              name="confirmnewpassword"
              className="password__input"
              ref={(input) => {
                this.passwordConfirmInput = input;
              }}
              invalid={this.state.validation.p2State === "failure"}
              placeholder="Confirm Password"
              required
              onChange={this.handleP2Change}
            />
          </InputGroup>

          <FormFeedback
            valid={this.state.validation.p1State === "failure" ? false : true}
            style={{ marginLeft: "15px", fontSize: 14 }}
          >
            Passwords must be at least 7 characters and contain at least one
            special character such as a period or a pound sign.
          </FormFeedback>

          <FormFeedback
            valid={this.state.validation.p1State === "failure" ? true : false}
            style={{ marginLeft: "20px", fontSize: 16 }}
          >
            Passwords must match.
          </FormFeedback>
          <br />
          <InputGroup className="mb-4">
            <Input
              type="select"
              name="select"
              value={this.state.securityQuestion}
              onChange={this.handleSecurityQuestionChange}
              label="Security Question"
            >
              {securityQuestions.map((w, index) => (
                <option key={index} value={w}>
                  {w}
                </option>
              ))}
            </Input>
          </InputGroup>
          <InputGroup className="mb-4">
            <Input
              name="answer"
              placeholder="Answer"
              value={this.state.securityAnswer}
              onChange={this.handleSecurityAnswerChange}
              required
              ref={(input) => {
                this.securityAnswerInput = input;
              }}
            />
          </InputGroup>
          <Button
            color="primary"
            onClick={this.verifySubmission}
            // disabled={this.state.submitButtonDisabled}
          >
            Finish Registration and Log In
          </Button>
        </Form>
      </CardBody>
    );
  }
}

export default PartTwo;
