import React from "react";


export const DelayedTask = (props) => {
  return (
    <div className="content">
      <p>Delayed Task</p>
      <p><small>{props.properties.reason}</small></p>
    </div>
  );
};
