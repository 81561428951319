
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ResourceImage from 'components/ResourceImage';
import { ButtonGroup, Input, Label } from 'reactstrap';

export class ConditionPanel extends React.Component
{
  state = {
      selectedResponse: 0
    };


  setResponseId = event =>
  {
    this.props.onValueChange(event.target.value);
  };

  getImagePng = () => {
    if (this.props.condition.AvatarName && this.props.condition.AvatarName !== "" && this.props.condition.AvatarName !== "ActiveWorker") {
      return `${this.props.condition.AvatarName}.png`;
    } else if ( this.props.condition.Title === 'Buki' ||
      this.props.condition.Title === 'Project Assistant') {

      return "Assistant.png";
    } else if  (this.props.condition.Title.includes("From:")) {
        return "None.png"
    } else {
      return `${ this.props.condition.Title.replace(/\s+/g, '').replace(/\./g, '')
        }.png`;
    }
  }
  render ()
  {
    let responseIndex = 1;
    return (
      <div className='component-Alert'>
        <div className='alert-Resource'>
          <ResourceImage alt={ this.props.condition.Title } png={ this.getImagePng() } />
          <div className='resource-Body'>{/* <h3>{this.props}</h3> */ }</div>
        </div>
        <div className='alert-Body'>
          <p>
            <strong>{ this.props.condition.Announcement }</strong>
          </p>

          <div className='alert-Message'>{ ReactHtmlParser( this.props.condition.Message) }</div>

          <div className='alert-Response'>
            <div className='form-group'>
              <ButtonGroup
                name='conditions'
                vertical
                onChange={ this.setResponseId.bind(this) }
              >
                { this.props.condition.ResponseList.map((row, index) => (
                  <Label check key={`label_${responseIndex.toString()}`}>
                  <Input
                    type="radio"
                    name='conditionGroup'
                      key={`radio_${index}`}
                    value={ responseIndex }
                  />
                    { row[ responseIndex++ ] }
                  </Label>
                )) }
              </ButtonGroup>
            </div>
          </div>
          {/* <div className='alert-Condition'>
        {this.props.condition['ConditionId']}
      </div> */}
        </div>
      </div>
    );
  }
}
