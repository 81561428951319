import { Form } from 'reactstrap';
import styled from 'styled-components';

const ModalForm = styled(Form)`
  &.card-body {
    max-height: none;
    overflow-y: visible;
    flex-basis: auto;
  }

  .invalid-feedback {
    display: block;
    margin-top: .25rem;
    font-size: .75rem;
    color: $form-feedback-invalid-color;
  }
`;

export { ModalForm };
