import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import imageURLs from "./images/imageURLs.js";

const RecognitionPlanningHelp = () => (
  <div>
    <h3>Planning Your Project – Recognition Budget</h3>
    <h4>Context and Resources for Recognition Budget:</h4>
    <p>
      Create a Recognition Budget to reward the team and/or individual team
      members as you execute your project.
    </p>

    <p>The following will be useful in building a recognition budget:</p>
    <ol>
      <li>
        Reference the window “Recognition Options + Costs” for options and costs
      </li>
      <li>Task descriptions (via the Gantt Chart))</li>
      <li>Team member profiles (via the Resource Plan)</li>
    </ol>

    <h4>Building your Recognition Budget</h4>
    <p>
      Estimate the specific recognition rewards (adding the costs by hand) you anticipate using in determining your recognition budget, or just enter a budget amount to create a recognition budget for use as needed during the execution of your project. Individual and team recognition options and costs are detailed in the Project Reference Guide  and the Recognition Options & Costs window.

    </p>
    <Row>
      <Col lg="4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.ProjectReferenceIconNavigationbar}
          alt="plan"
        />
      </Col>
      <Col lg="8">


          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

            src={imageURLs.RecognitionOptionsCosts}
            alt="plan"
          />

      </Col>
    </Row>



    <p>
      Plan enough budget to meet your approved Recognition budget and to implement your recognition strategy. Enter the amount that you wish to use for your planned recognition budget. Note that you must click on ‘Submit Voucher’ to update the project budget.

    </p>

        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.SubmitVoucherButton}
          alt="plan"
        />

    <h4>Editing your Recognition Budget</h4>
    <p>
      Enter the new amount that you wish to use for your planned recognition budget. Note that you must click on ‘Submit Voucher’ to update the project budget. Be aware that once you Approve your project plan you will not have the ability to update your Recognition Budget.

    </p>

    <h4> Execution and your Recognition Budget:</h4>
    <p>
      Use your recognition budget to recognize individual team members and the team in making your weekly project decisions. Follow your recognition strategy if it makes sense, but also change your strategy as project conditions change (i.e. poor team performance). When you start executing the project the budget amounts will be locked and you will be unable to update your Recognition Budget. It is good Project Management practice to keep your recognition strategy up to date as individual and team performance change, even if the budget is locked.

    </p>
  </div>
);

export default RecognitionPlanningHelp;
