import {
  REQUEST_RISKS,
  RECEIVE_RISKS,
  // SAVE_RISK_REQUEST,
  SAVE_RISK_SUCCESS,
  RISK_ERROR,
  DELETE_RISK_SUCCESS,
  DELETE_RISK_ERROR
} from "./types";

const initialState = {
  risks: []
};
export default function riskReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_RISKS:
      return state;
    case RECEIVE_RISKS:
      // have correct object array here in response
      return Object.assign({}, state, {
        ...state,
        risks: action.payload
      });
    case DELETE_RISK_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        risks: [
          ...state.risks.slice(0, action.payload),
          ...state.risks.slice(action.payload + 1, state.risks.length)
        ]
      });
    case DELETE_RISK_ERROR:
      console.log(action.error);
      return state;
    case RISK_ERROR:
      console.log(action.error);
      return state;
    case SAVE_RISK_SUCCESS:
      if (action.payload.newItem === true) {
        return Object.assign({}, state, {
          ...state,
          risks: [...state.risks, JSON.parse(action.payload.result.payload)]
        });
      } else {
        // need to slice / concat to replace updated item
        var returnRes = JSON.parse(action.payload.result.payload);

        const index = state.risks.findIndex(m => m.ID === returnRes.ID);
        return Object.assign({}, state, {
          ...state,
          risks: state.risks
            .slice(0, index)
            .concat(returnRes)
            .concat(state.risks.slice(index + 1))
        });
      }
    default:
      return state;
  }
}
