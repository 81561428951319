import React from "react";
import { ReferenceContainer } from "routes/Reference";

export const ReferenceModal = props => {
  return (
      <div>
    <ReferenceContainer selectedKey={props.selectedKey} />
    </div>
    )
};
