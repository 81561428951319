import React from "react";
import LoadingContainer from "./styled/LoadingContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loading = () => (
    <LoadingContainer>
        <FontAwesomeIcon
            className="ml-1"
            spin
            icon={faSpinner}
        />{" "}
    </LoadingContainer>
)

export default Loading;
