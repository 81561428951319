import React from "react";

class CustomizedDropDown extends React.Component {
 state = {
   selected: {}
};
  

  componentDidMount() {
    this.setState({ selected: this.getSelectedFromProps(this.props) });
  }
  getSelectedFromProps = props => {
    var selected;
    if (props.value === null && props.options.length !== 0) {
      selected = props.options[0][props.valueField];
    } else {
      selected = props.value;
    }

    return selected;
  };

  handleChange = e => {
    e.preventDefault();

    if (this.props.onChange) {
      var change = {
        oldValue: this.state.selected,
        newValue: e.target.value,
        newKey: e.target.selectedIndex,
        whichItemId: this.props.id,
        whichObject: this.props.editableObject
      };
      this.props.onChange(change);
    }
    this.setState({ selected: e.target.value });
  };

  render() {
    var myKey = 199999;
    const extraClasses = this.props.componentClasses
      ? this.props.componentClasses
      : "";
    return (
      <div className={extraClasses + " table-Select"} key={myKey++}>
        <select
          id={this.props.id}
          key={this.props.itemKey}
          dir="rtl"
          style={{
            opacity: this.state.selected === "None" ? "0" : "1"
          }}
          value={this.state.selected}
          onBlur={this.handleChange}
          onChange={this.handleChange}
        >
          <optgroup key={myKey++}>{this.props.options}</optgroup>
        </select>
      </div>
    );
  }
}

export default CustomizedDropDown;
