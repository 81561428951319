import React from "react";
import { PerformanceTable } from "./PerformanceTable";
import classes from "../../styles/ReferenceStyles.css";
import ResourceImage from "components/ResourceImage";
import accounting from "accounting";

import "./ResourceProfile.css";

// for the accounting package
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

export const ResourceProfile = props => {
  var i = 0;
  // change the embedded <br>s in observatios to show up correctly in the render
  var observations = props.worker.Observations.split("<br><br>").map(function(
    item
  ) {
    return (
      <span key={i++}>
        {item}
        <br />
        <br />
      </span>
    );
  });
  var objectives = props.worker.Objectives.split("<br><br>").map(function(
    item
  ) {
    return (
      <span key={i++}>
        {item}
        <br />
        <br />
      </span>
    );
  });
  var experience = props.worker.Experience.split("<br>").map(function(item) {
    return (
      <span key={i++}>
        {item}
        <br />
      </span>
    );
  });
  var education = props.worker.Education.split("<br>").map(function(item) {
    return (
      <span key={i++}>
        {item}
        <br />
      </span>
    );
  });

  return (
    <div className="resource-Item resource-Item--full" id={props.worker.Name}>
      <div className="resource-Row resource-Info">
        <div className="resource-Profile">
          <ResourceImage
            png={props.worker.Name.replace(/\s+/g, "") + ".png"}
            alt={props.worker.Name}
            
          />
        </div>
        <div style={{marginLeft: "30px"}}>
            <h5>{props.worker.Name}</h5>
            <p style={{fontSize: "12px"}}>{props.worker.Title}</p>
            <p style={{fontSize: "12px"}}>Weekly cost : <b>{accounting.formatMoney(props.worker.WeeklyCost, options)}</b></p>                                
        </div>
      </div>
      <div className="resource-Row">
        <h4>Observations</h4>
        {observations}
      </div>
      <div className="resource-Row">
        <h4>Performance Review</h4>
        <PerformanceTable worker={props.worker} />
      </div>
      <div className="resource-Row">
        <h4>Resume</h4>
      </div>
      <div className="resource-Row">
        <h4>Career Objectives</h4>
        <div className={classes.observationText}>{objectives}</div>
      </div>
      <div className="resource-Row">
        <h4>Professional Experience</h4>
        <div className={classes.observationText}>{experience}</div>
      </div>
      <div className="resource-Row">
        <h4>Education</h4>
        <div className={classes.observationText}>{education}</div>
      </div>
    </div>
  );
};
