import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";
import { fetchClassesByInstructor } from "redux/Instructor/thunks";

import * as actions from "./actions";

export function createClassroom(values) {
  return async (dispatch, getState) => {
    try {
      const payload = {
        classroomSetupObj:values,
        InstructorId: values.InstructorId
      }
      const InstructorId = payload.InstructorId
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/setupInitialClassroom`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      ).then (()=> 
      dispatch(
        fetchClassesByInstructor(payload.InstructorId)
      )
      );
      const { status } = response;
      if (!response) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "Report classroomSetup Error",
          headline: "Error accessing the classroomSetup api",
          message: `InstructorId: ${InstructorId}  classroomSetup : response status : ${status}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `InstructorId: ${InstructorId}  classroomSetup : response status : ${status}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actions.createClassroomError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            InstructorId,
            message: "Entity error while creating classroomSetup api"
          })
        );
        return false;
      } 
      return true;
    } catch (e) {
      processFailToFetch(e,getState(), dispatch, addSingleAlert, "createClassroom");
      return false;
    }
  };
}


