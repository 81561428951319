import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";

import { Button, UncontrolledTooltip } from "reactstrap";
import { processAssignments } from "redux/Project/thunks";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import {
  CONFIRMATION_MODAL
} from "routes/Modal/ModalTypes";

const RemoveAction = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  const { row } = props;
  const { rowIdx } = props;
  let firstName = "";
  if (row && row.ResourceName) {
    firstName = row.ResourceName.split(" ")[0];
  }
  
  const updateAssignment = async () => {
    await dispatch(processAssignments(row, "Status", "Release", 0));
    await dispatch(closeModal());

  }
  const closeMe = async () => {
    await dispatch(closeModal());
  }

  const openConfirmModal = () => {
    dispatch(
      openModal(
        CONFIRMATION_MODAL,
        {
          title: "Confirm Release Worker",
          message:
            "If you release this worker, it will take 2 weeks to get them back on project.  Are you sure you want to proceed?",
          cancelLabel: "Cancel",
          confirmLabel: "Confirm Release",
          handleCancel: closeMe,
          handleConfirm: updateAssignment
        },
        "modal-medium"
      )
    );
  };

  if (content.project.currentProjectWeek < 2) {
    return <span></span>
  }
  return (
    <>
      <Button
      id={`tooltip-remove-${rowIdx}`}
        color="link"
        disabled={content.auth.productId === 38 && row.ResourceName === "David Lewis" && content.project.displayProjectWeek < 4}
        onClick={() => openConfirmModal()} >
                 <FontAwesomeIcon icon={faUserMinus}  />

      </Button>
      <UncontrolledTooltip
        placement="top"
        target={`tooltip-remove-${rowIdx}`}
      >
        Use this button to remove {row.ResourceName} from the project immediately.  Note it will take 2 weeks of onboarding to get {firstName} back on the project.
      </UncontrolledTooltip>
    </>
  );
};

export default RemoveAction;
