import React from "react";
import  BudgetContainer from "routes/Budget/components/BudgetContainer";

export const BudgetModal = () => {
  return (
    <div className="component-FullBudget" id="modal-budget">
      <BudgetContainer />
    </div>
  );
};
