import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import { processTaskAssignmentShift } from "redux/Assignment/AssignmentsModule";
import {toggleLoading} from "app/redux/actions";


const ShiftForwardAction = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  // const currentResource = props.cellInfo.row.original;
  // const rowIdx = props.cellInfo.row.index;
   const currentResource = props.row;
  const { rowIdx } = props;
  const runId = content.project.runId;
  const prevWeek = props.prevWeek;

  const handleActionShiftForward = async () => {
    dispatch(toggleLoading(true))

    // props.currentProjectWeek, prevWeek, currentResource)
    // algorithm: 1. take the task from the previous week = weekNumber - 1 and copy it to the current week 2. take the current week task and copy it to the next week and shift every task down one week 3. TODO somehow the projected finish week will have to move forward
    const dataObjCollection = [];
    // grab previous week's value and stick it into current week

    let list = content.planning.resource.detailInfoWeekMap.filter(
      item => Number(item.Item3) === Number(currentResource.RowId) &&
      item.Item4 === `W${props.currentProjectWeek}`);


    let newAction;
    if (
      (currentResource[props.currentProjectWeek] === "Staff" ||
        currentResource[props.currentProjectWeek] === "Hire") &&
      currentResource[prevWeek] === "None"
    ) {
      newAction = "Release"; // need to let this person go because
      // we're pushing the  hiredate forward in time
    } else if (
      (currentResource[prevWeek] === "Staff" ||
        currentResource[prevWeek] === "Hire") &&
      currentResource[props.currentProjectWeek] !== "Staff" &&
      currentResource[props.currentProjectWeek] !== "Hire" &&
      currentResource[props.currentProjectWeek] !== "Release"
    ) {
      newAction = "None"; // don't want to shift in a Staff by mistake
    } else {
      newAction = currentResource[prevWeek];
    }
      dataObjCollection.push({
      RunId: runId,
      DetailId: list[0].Item1,
      NewAction: newAction,
      Week: props.currentProjectWeek
    });

    // now loop through the future weeks and grab the value from each
    // loopweek's previous week to put in the current loopweek
    let prevLoopWeek = props.currentProjectWeek; // starting current week
    const loopWeek = parseInt(props.currentProjectWeek, 0, 10) + 1; // starting next week
    // add 1 to project finish because we're moving it out a week now
    /* eslint-disable no-loop-func */

    for (let i = loopWeek; i <= props.maxWeeks; i++) {
      // hardcoded project end week for simulations - move all the items
      newAction = currentResource[`W${prevLoopWeek.toString()}`];

      const wk = `W${i.toString()}`;
      list = content.planning.resource.detailInfoWeekMap.filter(
        item => item.Item3 === currentResource.RowId && item.Item4 === wk
      );
      dataObjCollection.push({
        RunId: content.project.runId,
        DetailId: list[0].Item1,
        NewAction: newAction,
        Week: wk
      });
      prevLoopWeek = i;
    }
    const wa = content.project.workerAssignmentList.filter(
      item => item.WorkerId === currentResource.ResourceId
    );
     await dispatch(processTaskAssignmentShift(wa[0], dataObjCollection)).then(() => {
      dispatch(toggleLoading(false))

     });

  }; // end of handleActionShiftForward

  return (
    <>
      <Button
        color="link"
        id={`tooltip-shiftRight-${rowIdx}`}
        onClick={() => {
          handleActionShiftForward();
        }}
      >
        <>
        <FontAwesomeIcon icon={faAngleDoubleRight} />
          </>
      </Button>
      <UncontrolledTooltip
        placement="top"
        target={`tooltip-shiftRight-${rowIdx}`}
      >
        Keep your resource plan in sync with the state of your project. Starting
        week 2, you can shift the plan forward or back for each resource
        depending on the state of your project.
      </UncontrolledTooltip>
    </>
  );
};

export default ShiftForwardAction;
