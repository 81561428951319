

const reports =
  [
    {
      name: "History",
      url: "/reports/history",
      icon: "fa fa-history"
    },
    {
      name: "Charts",
      url: "/reports/charts",
      icon: "fa fa-line-chart"
    },
    {
      name: "Gantt Chart",
      url: "/reports/gantt",
      icon: "fa fa-bars"
    },
    {
      name: "Network Diagram",
      url: "/reports/network",
      icon: "fa fa-object-group"
    },

    {
      name: " Labor Breakdown Report",
      url: "/reports/labor",
      icon: "fa fa-line-chart"
    },
      {
      name: "Reserve Breakdown Report",
      url: "/reports/reserve",
      icon: "fa fa-line-chart"
    },
    {
      name: "Budget",
      icon: "fa fa-table",
      url: "/reports/budget"

    },
    {
      name: "Create Report PDFs",
      url: "/reports/pdf",
      icon: "fa fa-file-pdf-o"
    }

  ];


const documents =
{

  
  icon: "fa fa-folder",
  name: "Documentation",
  class: "main-category",
  url: "/documentation",
  children: [
    {
      icon: "fa fa-circle",
      name: "Project",

      url: "/documentation/project",
      children: [
        {
          icon: "fa fa-book",
          name: "Project Charter",
          url: "/documentation/project/charter"
        },
        {
          icon: "fa fa-sitemap",
          name: "Organizational Chart",
          url: "/documentation/project/chart"
        },
        {
          icon: "fa fa-sort-numeric-asc",
          name: "Work Breakdown Structure",
          url: "/documentation/project/wbs"
        },
        {
          icon: "fa fa-calendar-o",
          name: "Task Descriptions",
          url: "/documentation/project/tasks"
        }
      ]
    },
    {
      icon: "fa fa-circle",
      name: "Policies",
      url: "/documentation/policies",
      children: [
        {
          icon: "fa fa-user-times",
          name: "Absenteeism",
          url: "/documentation/policies/absenteeism"
        },
        {
          icon: "fa fa-volume-up",
          name: "Conferences",
          url: "/documentation/policies/conferences"
        },
        {
          icon: "fa fa-group",
          name: "Meetings",
          url: "/documentation/policies/meetings"
        },
        {
          icon: "fa fa-clock-o",
          name: "Overtime",
          url: "/documentation/policies/overtime"
        },
        {
          icon: "fa fa-plus",
          name: "Quality",
          url: "/documentation/policies/quality"
        },
        {
          icon: "fa fa-balance-scale",
          name: "Recognition",
          url: "/documentation/policies/recognition"
        },
        {
          icon: "fa fa-bar-chart-o",
          name: "Reserves",
          url: "/documentation/policies/reserves"
        },
        {
          icon: "fa fa-times-rectangle",
          name: "Resignation",
          url: "/documentation/policies/resignation"
        },
        {
          icon: "fa fa-user-circle-o",
          name: "Staffing",
          url: "/documentation/policies/staffing"
        },
        {
          icon: "fa fa-briefcase",
          name: "Stakeholders",
          url: "/documentation/policies/stakeholders"
        },
        {
          icon: "fa fa-institution",
          name: "Training",
          url: "/documentation/policies/training"
        },
        {
          icon: "fa fa-suitcase",
          name: "Vacations",
          url: "/documentation/policies/vacations"
        },
        {
          icon: "fa fa-tasks",
          name: "Work Assignments",
          url: "/documentation/policies/assignments"
        }
      ]
    },
    {
      icon: "fa fa-circle",
      name: "Resources",
      url: "/documentation/resources",
      children: [
        {
          icon: "fa fa-file-image-o",
          name: "Resources By Job Description",
          url: "/documentation/resources/byskill"
        },
        {
          icon: "fa fa-users",
          name: "Resources Summary",
          url: "/documentation/resources/summary"
        }
      ]
    }
  ]
}
// class in registration
export const registrationMenu = {
  items: [
    documents,
    {
      icon: "fa fa-tasks",
      name: "Manage Projects",
      url: "/projects"
    },
    {
      icon: "fa fa-cog",
      name: "Manage Settings",
      url: "/settings"
    }
  ]
}
export const preApprovalMenu = {
  items: [
    documents,
    {
      icon: "fa fa-sitemap",
      name: "Planning",
      class: "main-category",
      children: [
        {
          icon: "fa fa-list-alt",
          name: "Overview",
          url: "/planning/overview"
        },
        {
          icon: "fa fa-table",
          name: "Budget",
          url: "/planning/budget"
        },
        {
          icon: "fa fa-users",
          name: "Resource",
          url: "/planning/resource"
        },
        {
          icon: "fa fa-umbrella",
          name: "Risk/Opportunities",
          url: "/planning/risk"
        },
        {
          icon: "fa fa-university",
          name: "Training",
          url: "/planning/training"
        },
        {
          icon: "fa fa-money",
          name: "Stakeholder",
          url: "/planning/stakeholder"
        },
        {
          icon: "fa fa-gift",
          name: "Recognition",
          url: "/planning/recognition"
        },
        {
          icon: "fa fa-check-circle",
          name: "Approval",
          url: "/planning/approval"
        }
      ]
    },

    {
      icon: "fa fa-line-chart",
      name: "Reports",
      class: "main-category",
      url: "/reports",
      children: [...reports]

    },
    {
      icon: "fa fa-bell",
      name: "Notification History",
      class: "mt-auto",
      url: "/notifications"
    },
    {
      icon: "fa fa-tasks",
      name: "Manage Projects",
      url: "/projects"
    },
    {
      icon: "fa fa-cog",
      name: "Manage Settings",
      url: "/settings"
    }
  ]
};

export const postApprovalMenu = {
  items: [
    documents,

    {
      icon: "fa fa-sitemap",
      name: "Planning",
      class: "main-category",
      children: [
        {
          icon: "fa fa-list-alt",
          name: "Overview",
          url: "/planning/overview"
        },
        {
          icon: "fa fa-table",
          name: "Budget",
          url: "/planning/budget"
        },
        {
          icon: "fa fa-users",
          name: "Resource",
          url: "/planning/resource"
        },
        {
          icon: "fa fa-umbrella",
          name: "Risk/Opportunities",
          url: "/planning/risk"
        },
        {
          icon: "fa fa-university",
          name: "Training",
          url: "/planning/training"
        },
        {
          icon: "fa fa-money",
          name: "Stakeholder",
          url: "/planning/stakeholder"
        },
        {
          icon: "fa fa-gift",
          name: "Recognition",
          url: "/planning/recognition"
        },
        {
          icon: "fa fa-check-circle",
          name: "Approval",
          url: "/planning/approval"
        }
      ]
    },

    {
      icon: "fa fa-rocket",
      name: "Execution",
      class: "main-category",
      children: [
        {
          icon: "fa fa-users",
          name: "Staffing",
          url: "/execution/assignment"
        },
        {
          icon: "fa fa-table",
          name: "Budget",
          url: "/execution/budget"
        },
        {
          icon: "fa fa-calendar",
          name: "Interaction Decisions",
          url: "/execution/interaction"
        },
        {
          icon: "fa fa-repeat",
          name: "Work Week",
          url: "/execution/workweek"
        }
      ]
    },

    {
      icon: "fa fa-line-chart",
      name: "Reports",
      url: "/reports",
      children: [...reports]
    },
    {
      icon: "fa fa-bell",
      name: "Notification History",
      class: "mt-auto",
      url: "/notifications"
    },
    {
      icon: "fa fa-tasks",
      name: "Manage Projects",
      url: "/projects"
    },
    {
      icon: "fa fa-cog",
      name: "Manage Settings",
      url: "/settings"
    }
  ]
};


export const workWeekInProgressMenu = {
  items: [
    {
      icon: "fa fa-rocket",
      name: "Execution",
      children: [
        {
          icon: "fa fa-repeat",
          name: "Work Week",
          url: "/execution/workweek"
        }
      ]
    },
    {
      icon: "fa fa-line-chart",
      name: "Reports",
      url: "/reports",
      children: [...reports]
    },
    {
      icon: "fa fa-bell",
      name: "Notification History",
      class: "mt-auto",
      url: "/notifications"
    },
    {
      icon: "fa fa-tasks",
      name: "Manage Projects",
      url: "/projects"
    },
    {
      icon: "fa fa-cog",
      name: "Manage Settings",
      url: "/settings"
    }
  ]
};

export const reviewProjectMenu = {
  items: [
    {
      icon: "fa fa-sitemap",
      name: "Planning",
      url: "/review"
    },
    {
      icon: "fa fa-line-chart",
      name: "Reports",
      url: "/reports",
      children: [...reports]
    },
    {
      icon: "fa fa-bell",
      name: "Notification History",
      class: "mt-auto",
      url: "/notifications"
    },
    {
      icon: "fa fa-tasks",
      name: "Manage Projects",
      url: "/projects"
    },
    {
      icon: "fa fa-cog",
      name: "Manage Settings",
      url: "/settings"
    }
  ]
};

export const noRunIdMenu = {
  items: [
    {
      icon: "fa fa-check-square-o",
      name: "Getting Started",
      url: "/welcome"
    },
    {
      icon: "fa fa-bell",
      name: "Notification History",
      class: "mt-auto",
      url: "/notifications"
    },
    {
      icon: "fa fa-list-alt",
      name: "Manage Settings",
      url: "/settings"
    },
    {
      icon: "fa fa-list-alt",
      name: "Manage Projects",
      url: "/projects"
    }
  ]
};

export const instructorMenu = {
  items: [
    {
      name: "Instructor Dashboard",
      url: "/instructor-dashboard",
      icon: "fa a-cogs"
    }
  ]
};

export const welcomeMenu = {
  items: [
    {
      name: "Welcome",
      url: "/welcome",
      icon: "fa fa-handshake"
    }
  ]
};
