import React from "react";
import { fetchProjectData } from "redux/Project/thunks";
import { getWorkersBySkill } from "redux/Reference/selectors";
import { connect } from "react-redux";

import { Card, CardHeader, CardBody } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBriefcase } from "@fortawesome/free-solid-svg-icons";

export const BySkill = props => {
  let i = 1;

  return (
    <div className="style-background-components">
      <h2 id="h2-by-skills">
        <FontAwesomeIcon icon={faBriefcase} className="icon-generic-h2" />
        Available Resources By Job Description
      </h2>

      <br />
      <div id="jobs-description-div">
        {props.workersBySkillList
          .sort((a, b) => a.skillName.localeCompare(b.skillName))
          .map((row, index) => (
            <Card key={i++} id="jobs-description-card">
              <CardHeader id="jobs-description-header" className="task-headers">
                {row.skillName}
              </CardHeader>
              <CardBody>
                <div id="jobs-description-div">{row.skillDescription}</div>

                {row.resourceNames.map((sRow, sIndex) => (
                  <p key={i++}>
                    <FontAwesomeIcon icon={faUser} className="icons-user" />
                    {sRow}
                    <br />
                  </p>
                ))}
              </CardBody>
            </Card>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  runId: state.project.runId,
  workersBySkillList: getWorkersBySkill(state),
  productId: state.auth.productId
});

const mapDispatchToProps = dispatch => ({
  fetchProjectData: runId => dispatch(fetchProjectData(runId))
});

export const BySkillContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BySkill);
