/* this will show the users current license and expiration date for which products they
have available to them - simple, agile, etc.  also will have instructions on requesting an extension */
import React from 'react';
import { connect } from 'react-redux';

class ReviewLicense extends React.Component {

  constructor(props) {
    super(props);

    let start = '';
    if (
      Object.prototype.hasOwnProperty.call(props, 'licenseStartDate') &&
      props.licenseStartDate !== undefined &&
      props.licenseStartDate !== null
    ) {
      start = new Date(props.licenseStartDate.toString());
    }

    let end = '';
    if (
      Object.prototype.hasOwnProperty.call(props, 'licenseExpirDate') &&
      props.licenseExpirDate !== undefined &&
      props.licenseExpirDate !== null
    ) {
      end = new Date(this.props.licenseExpirDate.toString());
    } else {
      end = null;
    }

    this.state = {
      startDt: start,
      endDt: end
    };
  }

  getNumberOfRunsRemaining = () => {
    if (this.props.runDetails !== undefined && this.props.runDetails !== null) {
      const availRuns = this.props.runDetails.filter(run => run.RunId === 0);
      return availRuns.length;
    }
  };

  render() {
    return (
      <div>
        <p>
          Product Name: {this.props.productId}: {this.props.productName}
        </p>
        <p>License Started: {this.state && this.state.startDt ? this.state.startDt.toDateString() : ""}</p>

        <p>License Expiration Date: {this.state.endDt && this.state.endDt.toDateString()} </p>

        <p>Licensed for {this.props.runDetails && this.props.runDetails.length} executions </p>
        <p>

          Number of executions remaining: {this.getNumberOfRunsRemaining()}
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  licenseStartDate: state.auth && state.auth.studentInfo &&
    state.auth.studentInfo.LicenseStartDate ? state.auth.studentInfo.LicenseStartDate : null,
  licenseExpirDate: state.auth && state.auth.studentInfo &&
    state.auth.studentInfo.LicenseExpirationDate ? state.auth.studentInfo.LicenseExpirationDate : null,
  productName: state.auth && state.auth.user && state.auth.user.ProductName
    ? state.auth.user.ProductName : 'No product name found',
  productId: state.auth && state.auth.user && state.auth.user.ProductId
    ? state.auth.user.ProductId : 0,

  licenseId: state.auth && state.auth.studentInfo && state.auth.studentInfo.LicenseId
    ? state.auth.studentInfo.LicenseId : 0,
  runDetails: state.auth.studentInfo.RunDetails
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLicense);
