/* eslint-disable max-len */
import React from "react";

export const Conferences = props => {
  return (
    <div id="Conferences" className="style-background-components">
      <header>
        <h2>Individual Conferences</h2>
      </header>
      <div>
        <p>
          Individual conferences provide a mechanism for communicating privately
          with specific team members. Conferences can be scheduled for any day
          for a selected team member and cover any topic and associated
          feedback, and can range in duration of between 1 and 3 hours.
        </p>
        <p>
          The kind of topics that are typically scheduled for individual
          conferences are:
        </p>
        <ul>
          <li>Attendance</li>
          <li>Quality</li>
          <li>Productivity</li>
          <li>Teamwork</li>
        </ul>
        <p>
          In addition to the topic selection, specific performance feedback must
          be selected from the possible choices of either praise, coach,
          counsel, or warn. The performance feedback choices represent a
          continuum ranging from "Praise" on one end, and "Warning" on the other
          end of the continuum. "Counseling" and "Warning" are generally
          associated with problematic behavior. Any number of conferences may be
          scheduled for any day of the week. However, the combination of team
          meetings, stakeholder meetings, and individual conferences for any day
          of the week cannot exceed 8 hours in combined duration.
        </p>
        <p>
          Hours spent in conferences are <strong>not</strong> reflected in
          "Hours Worked", which are reported on a weekly basis.
        </p>
      </div>
    </div>
  );
};
