import React, { useState } from "react";
import {  useDispatch } from "react-redux";
import Select from "react-select";
import { processAssignments} from "redux/Project/thunks";
const OvertimeEditor = props => {
  const [items] = useState(options);
  const [val, setVal] = useState(
    props.value === 1 ? `${props.value} hour` : `${props.value} hours`);

  const dispatch = useDispatch();
  const row = props.row;

  const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted grey",
      fontWeight: state.isSelected ? "bold" : "regular"
    }),
    control: base => ({
      ...base,
      height: "40px",
      flex: 1,
      border: 0,
      width: "120px",
      backgroundColor: "transparent",
      // This line disable the blue border
      boxShadow: "none",
      justifyContent: "center",
      alignContent: "center",
      textAlign: "center",
      verticalAlign: "middle"
    }),
    singleValue: (provided, state) => {
      const opacity =
        state.data.label === "None" ? 0.0 : state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    valueContainer: provided => ({
      ...provided,
      width: "75px",
      marginLeft: "20px",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px"
    })
  };

  const updateData = (selected) => {
     if (selected.value === null || selected.label === "") {
      return;
    }
    setVal(selected.label);
  
    dispatch(processAssignments(
      row,
      "Overtime",
      selected.value.toString(),
      selected.value
    ));
  };
  return (
    <Select
      id={"staff-ot"}
      className="select-custom-class"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      options={items}
      styles={selectStyle}
      value={items.filter(
        option => option.label === val 
      )}
      menuPortalTarget={document.body}
      onChange={selected => {
        updateData(selected);
      }}

    />
    // <Dropdown size="sm" direction="right" positionFixed={true} isOpen={dropdownOpen} style={{width: "30px", height: "50px"}}
    //   toggle={() => setDropdownOpen(!dropdownOpen)}>
    //   <DropdownToggle width="100%" caret style={{ backgroundColor: "#fff", border: "0px"}}>
    //     <span>{row.HoursOvertime === 1
    //       ? `${row.HoursOvertime} hour`
    //       : `${row.HoursOvertime} hours`} </span>
    //   </DropdownToggle>
    //   <DropdownMenu positionFixed={true}>
    //     {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(hour => (
    //       <DropdownItem
    //         value={hour}
    //         className="text-left"
    //         key={hour + rowIndex}
    //         onClick={event => updateData(event, hour)}
    //       >
    //
    //       </DropdownItem>
    //     ))}
    //   </DropdownMenu>
    // </Dropdown>
  );
};

export default OvertimeEditor;

const options = [
  { value: 0, label: "0 hours" },
  { value: 1, label: "1 hour" },
  { value: 2, label: "2 hours" },
  { value: 3, label: "3 hours" },
  { value: 4, label: "4 hours" },
  { value: 5, label: "5 hours" },
  { value: 6, label: "6 hours" },
  { value: 7, label: "7 hours" },
  { value: 8, label: "8 hours" },
  { value: 9, label: "9 hours" },
  { value: 10, label: "10 hours" },
  { value: 11, label: "11 hours" },
  { value: 12, label: "12 hours" },
  { value: 13, label: "13 hours" },
  { value: 14, label: "14 hours" },
  { value: 15, label: "15 hours" },
  { value: 16, label: "16 hours" },
  { value: 17, label: "17 hours" },
  { value: 18, label: "18 hours" },
  { value: 19, label: "19 hours" },
  { value: 20, label: "20 hours" }
];
