import React from "react";

const { Fragment } = React;
export const ClassroomDisplay = props => (
  <Fragment>
    <h4>Your current Classroom Assignment:</h4>

    <div>
      <h5>
        Instructor: &nbsp;&nbsp;
        <small>{props.instructorName}</small>
      </h5>
      <h5>
        Classroom: &nbsp;&nbsp;
        <small>{props.classroomName}</small>
      </h5>
    </div>
  </Fragment>
);
