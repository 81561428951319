import React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { PlanningTooltip, ExecutionTooltip } from 'components/Tooltips';

const AsideMenu = styled.aside`
	font-size: 0.8rem;
	overflow-y: scroll;

	.list-group-item-success {
		text-decoration: line-through;
	}

	.list-group-item {
		padding: 0.5rem 0.75rem;
		cursor: pointer;
	}

	.todo-list:last-child {
		margin-bottom: 75px;
	}
`;

const Heading = styled.div`
	padding: 1.25rem;

	p:last-child {
		margin-bottom: 0;
	}
`;

const CustomListGroupItem = styled(ListGroupItem)`
	h5 {
		margin-bottom: 0;
		font-size: 1.1rem;
	}

	p:last-child {
		margin-bottom: 0;
	}
`;

class Aside extends React.Component {
	state = {
		checklistData: []
	};


	componentDidMount() {
		this.setState({
			checklistData: [
				{
					title: 'Planning',
					description: <PlanningTooltip />,
					reset: false,
					items: this.props.planningChecklist.slice(0)
				},
				{
					title: 'Execution',
					description: <ExecutionTooltip />,
					reset: true,
					items: this.props.executionChecklist.slice(0)
				}
			]
		});
	}

	checkItem = (event, clickedItem) => {
		event.preventDefault();
		let val = 0.0;
		if (!clickedItem.completed) {
			val = 1.0;
			clickedItem.completed = true;
		}


		this.props.updateCheckListItem(clickedItem.id, clickedItem.nbr, val).then(() => {
			this.setState({
				checklistData: [
					{
						title: 'Planning',
						description: <PlanningTooltip />,
						reset: false,
						items: this.props.planningChecklist.slice(0)
					},
					{
						title: 'Execution',
						description: <ExecutionTooltip />,
						reset: true,
						items: this.props.executionChecklist.slice(0)
					}
				]
			});
		});
	};

	getTargetString = (id) => `Item${id}`;

	renderListItems = (title, items) => {
		let j = items.length;

		let returnList = [];

		for (let i = 0; i < j; i++) {

			returnList.push(
				<ListGroupItem
					onClick={(e) => this.checkItem(e, Object.assign({}, items[i]))}
					key={i}

					color={items[i].completed ? 'success' : ''}
				>
					<div id={`${title}tip${i}`}>
						<span color="link" className="check-link">
							<i className={items[i].completed ? 'fa fa-check-square' : 'fa fa-square-o'} />
						</span>
						<span>&nbsp;&nbsp;</span>
						<span className={items[i].completed ? 'm-l-xs todo-completed' : 'm-l-xs'}>{items[i].text}</span>
					</div>
					<UncontrolledTooltip placement="top" target={`${title}tip${i}`}>
						{items[i].description}
					</UncontrolledTooltip>
				</ListGroupItem>
			);
		}
		return returnList
	};

	render() {
		let iterKey = 1;
		return (
			<AsideMenu className="aside-menu" id="aside">
				<div >
					<Heading>
						<h5>Planning and Execution Checklist</h5>
						<p>
							Use this check list to guide you through the sequential planning and execution steps of your
							project.
						</p>
						<p>You can manually check off each step as you complete it.</p>
					</Heading>
					{this.state.checklistData.map((group) => (
						<ListGroup key={(iterKey += 1)} className="todo-list m-t small-list">
							<CustomListGroupItem active>
								<ListGroupItemHeading id={group.title}>
									{group.title}&nbsp;&nbsp;
									{group.description ? (
										<small>
											<i className="fa fa-info-circle" id={`${group.title}${iterKey}`} />
										</small>
									) : (
										''
									)}
								</ListGroupItemHeading>

								{group.description ? (
									<UncontrolledTooltip placement="top" target={group.title}>
										<div style={{ textAlign: 'left' }}>{group.description}</div>
									</UncontrolledTooltip>
								) : (
									''
								)}
							</CustomListGroupItem>
							{this.renderListItems(group.title, group.items)}


						</ListGroup>
					))}
				</div>
			</AsideMenu>
		);
	}
}

export default Aside;
