/* eslint-disable no-unneeded-ternary */
import React from "react";
import { PerformanceIndexCell } from "./PerformanceIndexCell";

export const PerformanceIndexRow = props => (
  <div className="performance-Row">
    <div className="performance-Row_Description">{props.textA}</div>
    <div className="performance-Row_Blocks">
      <PerformanceIndexCell hasCheck={props.rating === 1 ? true : false} />
      <PerformanceIndexCell hasCheck={props.rating === 2 ? true : false} />
      <PerformanceIndexCell hasCheck={props.rating === 3 ? true : false} />
      <PerformanceIndexCell hasCheck={props.rating === 4 ? true : false} />
      <PerformanceIndexCell hasCheck={props.rating === 5 ? true : false} />
    </div>
    <div className="performance-Row_Description">{props.textB}</div>
  </div>
);
