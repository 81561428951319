import { CardBody as ReactStrapCardBody } from "reactstrap";
import styled from "styled-components";

const NoMaxCardBody = styled(ReactStrapCardBody)`
  &.card-body {
    max-height: none;
    overflow-y: none;
    flex-basis: auto;
  }
`;

export { NoMaxCardBody };
