/* eslint-disable max-len */
import React from "react";

export const Resignation = () => {
  return (
    <div id="Resignation" className="style-background-components">
      <header>
        <h2>Staff Resignation</h2>
      </header>
      <div>
        <p>
          Uniworld typically experiences a 4 % to 5 % attrition rate in any
          given year and project staff may resign at any time for any reason. To
          ensure that members of your team do not leave the project unexpectedly
          you will need to communicate with them often and address their
          individual and team needs.
        </p>
        <p>
          A 2 week standard notice is expected from all personnel announcing
          their resignation from the company.
        </p>
      </div>
    </div>
  );
};
