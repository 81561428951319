import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  NavLink
} from "reactstrap";
import { AlertList } from "react-bs-notifier";
import { ModalContainer } from "routes/Modal/ModalContainer";
import {
  addSingleAlert,
  dismissAlert,
  clearAlerts
} from "redux/Notification/NotificationModule";
import { LICENSE_MODAL, NOTIFICATION_MODAL } from "routes/Modal/ModalTypes";
import { openModal } from "redux/Modal/ModalModule";
import {
  register,
  authenticate,
  acceptLicense,
  getUser
} from "redux/Authentication/thunks"; // actions
import BuildVersion from "components/BuildVersion";
import logo from "static-new/images/logo-spl--full.png";

import PartOne from "./components/PartOne";
import PartTwo from "./components/PartTwo";

import "./Registration.css";

class Registration extends React.Component {
  constructor(props) {
    super(props);
    let uname = "";
    if (
      Object.prototype.hasOwnProperty.call(this.props, "auth") &&
      Object.prototype.hasOwnProperty.call(this.props.auth, "user") &&
      Object.prototype.hasOwnProperty.call(this.props.auth.user, "UserName")
    ) {
      uname = this.props.auth.user.UserName;
    }
    this.state = {
      username: uname,
      tempPass: "",
      licenseTermsAccepted: false,
      error: false,
      renderPartTwo: false,
      downForMaintenance: false
    };

    this.handlePartOne = this.handlePartOne.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
  }

  // componentDidMount = () => {
  //   // document.body.classList = '';
  //   document.body.classList.add('gray-bg');
  //   const alert = {
  //     id: 1,
  //     type: 'info',
  //     headline: 'ALERT - SCHEDULED MAINTENANCE',
  //     event: 'login',
  //     message:
  //       'SimProject app will  going down in less than 10 minutes 6:00 PM EDT, for approximately 2 hours.'
  //   };
  //   this.props.addAlert(alert, {}, 0, false); // no timeout, manual
  // };

  // actual api submit function
  // after user is successfully registered
  // the app will navigate to the dashboard
  // see authentication/redux/thunks.js

  handleRegisterSubmit = async (
    username,
    passwordQuestion,
    passwordAnswer,
    newPassword
  ) => {
    let alert = {};
    alert = {
      id: 6,
      type: "info",
      headline: "Registering",
      event: "registration",
      message:
        "Completing your registration and logging you into the application.  Please wait..."
    };
    this.props.addAlert(alert, {}, 0, false);

    // username
    // passwordQuestion,
    // passwordAnswer,
    // oldPassword,
    // newPassword,
    // orderNo

    this.props
      .register(
        username,
        passwordQuestion,
        passwordAnswer,
        this.state.tempPass,
        newPassword,
        1
      )
      .then((response) => {
        if (response === false) {
          const msg = this.props.auth.message;
          this.props.dismissAlert(6);
          alert = {
            id: 7,
            type: "danger",
            headline: "Registration",
            event: "registration",
            message: `There is a problem with your registration - here is the message we see: ${msg}.  Please contact support : support@simulationpl.com for assistance`
          };
          this.props.addAlert(alert, {}, 0, false);
        }
      });
  };

  handleUsernameChange = (event) => {
    event.preventDefault();
    this.setState({ username: event.target.value });
  };
  setUsername = (name) => {
    this.setState({ username: name });
  };

  handlePartOne(username, tempPass, licAccept) {
    this.props.clearAlerts();
    this.setState({
      username,
      tempPass,
      licenseTermsAccepted: licAccept,
      renderPartTwo: true
    });
  }

  handleRegisterClick() {
    this.setState({
      renderPartTwo: true
    });
  }

  handleLoginClick() {
    this.setState({
      renderPartTwo: false
    });
  }

  render() {
    return (
      <div className="app route-Register flex-row align-items-center">
        <AlertList
          position={this.props.notification.position}
          alerts={this.props.notification.alerts}
          timeout={this.props.notification.timeout}
          dismissTitle="Bye!"
          onDismiss={this.props.dismissAlert}
        />
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5">
              <Card style={{ overflow: "hidden", width: "400px" }}>
                <CardBody style={{ overflow: "hidden" }}>
                  <div align="center">
                    <img src={logo} alt="SPL Logo" className="logo" />
                    <h4>
                      Simulation Project{" "}
                      <small>
                        <BuildVersion version="0.0.0.0" />
                      </small>
                    </h4>
                  </div>
                  <p>
                    We're as excited to get going as you are. Before we proceed
                    though, we need to get you set up.
                  </p>
                  <p>
                    <strong>
                      Use the username and temporary password you received in
                      the registration email.
                    </strong>{" "}
                    You'll then need to pick a new password and security
                    question.
                    <br />
                    If you're having issues logging in, please{" "}
                    <strong>
                      <a href="mailto:support@simulationpl.com?subject=Registration Login Issues">
                        contact us
                      </a>
                      .
                    </strong>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card style={{ overflow: "hidden", width: "550px" }}>
                <CardBody style={{ overflow: "hidden" }}>
                  {this.state.downForMaintenance && (
                    <h4>
                      Simproject is down for maintenance today - target
                      resolution date: Wed - 6 February - 8:00 am ET
                    </h4>
                  )}
                  {!this.state.downForMaintenance && (
                    <span>
                      <h1>Register</h1>
                      <p className="text-muted">
                        Enter username and temporary password
                      </p>
                      {this.state.renderPartTwo === false ? (
                        <PartOne
                          submit={this.handlePartOne}
                          licenseAccepted={this.state.licenseAccepted}
                          auth={this.props.auth}
                          authenticate={this.props.authenticate}
                          authMessage={this.props.auth.message}
                          showLicense={this.showLicense}
                          setUsername={this.setUsername}
                          addAlert={this.props.addAlert}
                          dismissAlert={this.props.clearAlerts}
                          getUser={this.props.getUser}
                        />
                      ) : (
                        <PartTwo
                          submit={this.handleRegisterSubmit}
                          tempPass={this.state.tempPass}
                          username={this.state.username}
                          message={this.props.auth.message}
                          addAlert={this.props.addAlert}
                          dismissAlert={this.props.dismissAlert}
                        />
                      )}
                    </span>
                  )}
                </CardBody>
                <CardFooter>
                  <small className="d-flex justify-content-end align-items-center">
                    <strong> Already registered? </strong>
                    <NavLink
                      className="btn ml-2 btn-outline-primary"
                      style={{ padding: "0.25rem 0.5rem" }}
                      to="/authentication"
                      href="/authentication"
                    >
                      Log in
                    </NavLink>
                  </small>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalContainer />
      </div>
    );
  }
  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
   MAPDISPATCHTOPROPS for the opoenModal function below * */
  showLicense = () => this.props.showLicense();
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
  notification: state.notification
});

/** DEFINE THE DISPATCH OPEN MODAL TO OPEN YOUR NEW MODAL * */
const mapDispatchToProps = (dispatch) => ({
  showLicense: () => dispatch(openModal(LICENSE_MODAL, {}, "modal-medium")),
  showNotification: () =>
    dispatch(
      openModal(
        NOTIFICATION_MODAL,
        "You are already registered, redirecting to login",
        "modal-medium"
      )
    ),
  getUser: (username) => dispatch(getUser(username)),
  register: (
    username,
    passwordQuestion,
    passwordAnswer,
    oldPassword,
    newPassword,
    orderNo
  ) =>
    dispatch(
      register(
        username,
        passwordQuestion,
        passwordAnswer,
        oldPassword,
        newPassword,
        orderNo
      )
    ),
  authenticate: (username, password) =>
    dispatch(authenticate(username, password)),
  acceptLicense: (userId) => dispatch(acceptLicense(userId)),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  dismissAlert: (idx) => dispatch(dismissAlert(idx)),
  clearAlerts: () => dispatch(clearAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
