import React from "react";
import Select from "react-select";

const SelectEditor = ({
  value,
  onChange,
  options,
  rowHeight,
  menuPortalTarget,
  isDisabled
}) => {
  return (
    <Select
      autoFocus
      disab
      defaultMenuIsOpen
      value={options.find((o) => o.value === value)}
      onChange={(o) => onChange(o.value)}
      options={options}
      menuPortalTarget={menuPortalTarget}
      isDisabled={isDisabled}
      styles={{
        control: (provided) => ({
          ...provided,
          height: rowHeight - 1,
          minHeight: 30,
          lineHeight: "normal"
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: rowHeight - 1
        })
      }}
    />
  );
};

export default SelectEditor;
