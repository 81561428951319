import React from "react";
import { connect } from "react-redux";

const FooterComp = props => {
  let handleProjectName = () => {
    return props.productId === 50 ? "PACCISS" : "SimProject";
  };

  let handleCopyWriteName = () => {
    return " Simulation Powered Learning";
  };

  return (
    <footer className="app-footer">
      <span>{handleProjectName()}</span>
      <span className="float-right">
        &copy; {new Date().getFullYear()}
        {handleCopyWriteName()}
      </span>
    </footer>
  );
};

const mapStateToProps = state => ({
  productId: state.auth.productId
});

export const Footer = connect(mapStateToProps, null)(FooterComp);
