import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Row, Col, ButtonToolbar, Nav,
  NavItem,
  NavLink,
  Card
} from "reactstrap";
  import classnames from "classnames";
import {
  getTotalCostByWorker,
  getTotalCostByTask
} from "redux/Report/selectors";
import { fetchLaborSummaryData } from "redux/Report/thunks";
import PerfectScrollbar from "react-perfect-scrollbar";
import accounting from "accounting";
import CardHeaderBold from "components/styled/CardHeaderBold";

import {
  getReserveSummaryData,
  getLostHoursSummaryData
} from "./components/LaborSummary/LaborSelectors";
import ReservesTable from "./components/LaborSummary/ReservesTable";
import LostHoursTable from "./components/LaborSummary/LostHoursTable";


import InfoButton from "components/Info/InfoButton";
// import PdfButton from "./components/PdfButton";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%s%v"
};

class ReserveCostSummary extends Component {
  state = {
    maxWeek: 0,
    activeTab: "reserve-hours"
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab })
  };

  componentDidMount() {
    this.props.fetchLaborSummaryData(this.props.runId);
    const maxWeek = Math.max.apply(
      Math,
      this.props.taskHoursSummary.map(function (o) {
        return o.weekNumber;
      })
    );
    this.setState({
      maxWeek: maxWeek
    });
  }


  render() {
    let iterKey = 1;
    return (
      <>
      <Card>
      <CardHeaderBold>
           <span>Reserve Breakdown Report</span>

          <ButtonToolbar>
           <InfoButton selectedKey="reserve" />
         {/* <PdfButton selectedKey="labor" /> */}

           </ButtonToolbar>
        </CardHeaderBold>
     </Card>
      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
              <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "reserve-hours"
                })}
                onClick={() => {
                  this.toggle("reserve-hours");
                }}
              >
                Reserves
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "lost-hours"
                })}
                onClick={() => {
                  this.toggle("lost-hours");
                }}
              >
                            Resource Non Task-related Hours/Costs

              </NavLink>
            </NavItem> */}

          </Nav>
          <TabContent activeTab={this.state.activeTab} style={{height: "900"}}>


            <TabPane tabId="reserve-hours">
             <PerfectScrollbar>
                   <ReservesTable
                     maxWeek={this.state.maxWeek}
                     options={options}
                     reserveHoursContent={this.props.reserveSummaryData}
                   />
                 </PerfectScrollbar>
            </TabPane>
            {/* <TabPane tabId="lost-hours">
              <PerfectScrollbar>
                   <LostHoursTable
                     maxWeek={this.state.maxWeek}
                     options={options}
                  lostHoursContent={this.props.lostHoursSummaryData.filter(d => d.resHours > 0)}
                 />
                </PerfectScrollbar>
            </TabPane> */}
          </TabContent>
        </Col>
        </Row>

</>





    );
  }
}

const mapStateToProps = (state) => {
  return {
    runId: state.project.runId,
    totalCostByWorker: getTotalCostByWorker(state),
    totalCostByTask: getTotalCostByTask(state),
    workerTaskHoursData: state.report.workerTaskHoursData,
    taskHoursSummary: state.report.taskHoursSummary,
     reserveSummaryData: getReserveSummaryData(state),
    lostHoursSummaryData: getLostHoursSummaryData(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLaborSummaryData: (runId) => dispatch(fetchLaborSummaryData(runId))
  };
};

const ReserveSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveCostSummary);

export default ReserveSummaryContainer;
