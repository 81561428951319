/* eslint max-len: 0 */
import React from "react";
import { connect } from "react-redux";
import { fetchProjectData } from "redux/Project/thunks";
import { getWorkerSummary } from "redux/Reference/selectors";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchDollar } from "@fortawesome/free-solid-svg-icons";

export const Summary = props => {
  const options = {
    defaultSortName: "resource", // default sort column name
    defaultSortOrder: "asc" // default sort order
  };
  return (
    <div id="Summary" className="style-background-components">
      <h2>
        <FontAwesomeIcon icon={faSearchDollar} className="icon-generic-h2" />
        Resources Summary
      </h2>
      <BootstrapTable
        data={props.workerSummary}
        striped
        condensed
        options={options}
      >
        <TableHeaderColumn dataField="resource" isKey dataSort>
          Resource
        </TableHeaderColumn>
        <TableHeaderColumn dataField="title" dataSort>
          Skill
        </TableHeaderColumn>
        <TableHeaderColumn dataField="weekly" dataAlign="right" dataSort>
          Weekly Rate
        </TableHeaderColumn>
        <TableHeaderColumn dataField="hourly" dataAlign="right">Hourly Rate</TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

const mapStateToProps = state => ({
  runId: state.project.runId,
  workerSummary: getWorkerSummary(state),

  productId: state.auth.productId
});

const mapDispatchToProps = dispatch => ({
  fetchProjectData: runId => dispatch(fetchProjectData(runId))
});

export const SummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
