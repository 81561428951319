import React from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';

import Select from 'react-select';
import styled from 'styled-components';

const StyledForm = styled(Form)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	align-items: start;

	.form-group {
		margin-bottom: 0;
	}
`;

const Fragment = React.Fragment;

class ClassroomChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedInstructor: {},
			selectedClassroom: {},
			availableClasses: [],
			submitButtonDisabled: true,
			classroomSelectDisabled: true
		};
		this.handleInstructorChange = this.handleInstructorChange.bind(this);
	}

	// filter the classrooms based on selected instructor
	handleInstructorChange = (selected) => {
		if (selected === undefined || selected === null) {
			return;
		}

		const filteredClasses = [];

		this.props.availableClasses.forEach((cls) => {
			if (cls.InstructorUserId === selected.value) {
				filteredClasses.push({
					label: cls.ClassName,
					value: cls.ClassId
				});
			}
		});

		this.setState({
			selectedInstructor: selected,
			selectedClassroom: null,
			availableClasses: filteredClasses,
			classroomSelectDisabled: false,
			submitButtonDisabled: true
		});
	};

	handleClassroomChange = (selected) => {
		if (selected === undefined) {
			return;
		}

		this.setState({
			selectedClassroom: selected,
			submitButtonDisabled: false
		});
	};
	// let newClass;

	// const myClass = Object.assign({}, selected[0]);
	// for (const item in this.state.availableClasses) {
	//   if (Object.prototype.hasOwnProperty.call(this.state.availableClasses, item)) {
	//     const thisItem = this.state.availableClasses[item];

	//     if (Number(thisItem.ClassId) === Number(selected[0].ClassId)) {
	//       newClass = Object.assign(myClass, thisItem);
	//       // myClass = thisItem
	//       break;
	//     } else {
	//       continue;
	//     }
	//   }
	// }

	handleSubmit = () => {
		const runDBIds = this.props.runDetails.map((a) => a.ClassStudentRunDBId);
		this.props.placeInClassroom(
			this.props.studentInfo.ClassStudentDBId,
			this.state.selectedClassroom.value,
			runDBIds
    );
    this.setState({selectedInstructor: {}, selectedClassroom: {}})
	};

	createInstructorList() {
		const instructorList = [];
		for (const item in this.props.classrooms) {
			if (Object.prototype.hasOwnProperty.call(this.props.classrooms, item)) {
				const thisItem = this.props.classrooms[item];
				if (Object.prototype.hasOwnProperty.call(instructorList, thisItem.InstructorUserId)) {
					continue;
				}
				instructorList.push({
					instructorId: thisItem.InstructorUserId,
					instructorName: thisItem.InstructorFullName
				});
			}
		}
		return instructorList;
	}

	render() {
		return (
			<Fragment>
				<p className="small">
					<i>Choose from dropdown or click in box;type all or part of instructor/class name to filter.</i>
				</p>
				<StyledForm>
					<FormGroup>
						<Label>1. Select Instructor</Label>
						<Select
							options={Array.from(new Set(this.props.uniqueInstructors.map(JSON.stringify)), JSON.parse)}
              placeholder="Choose an instructor ..."
              value={this.state.selectedInstructor}
							onChange={(selected) => {
								this.handleInstructorChange(selected);
							}}
						/>
					</FormGroup>
					<FormGroup
						className={
							this.state.classroomSelectDisabled === true ? 'selectLabelDisabled' : 'selectLabelEnabled'
						}
					>
						<Label>2. Select Class</Label>
						<Select
							options={Array.from(new Set(this.state.availableClasses.map(JSON.stringify)), JSON.parse)}
              placeholder="Choose a class ..."
              value={this.state.selectedClassroom}
							onChange={(selected) => {
								this.handleClassroomChange(selected);
							}}
						/>
					</FormGroup>

					<Button
						type="button"
						color="primary"
						disabled={this.state.submitButtonDisabled}
						onClick={this.handleSubmit.bind(this)}
						label="Assign"
					>
						Assign
					</Button>
				</StyledForm>
			</Fragment>
		);
	}
}

export default ClassroomChooser;
