import React from "react";

export const Overtime = () => {
  return (
    <div id="Overtime" className="style-background-components">
      <header>
        <h2>Overtime</h2>
      </header>
      <div>
        <p>
          Projects which utilize resources less than 50% of their availability
          are strictly prohibited from requiring overtime.
        </p>
        <p>
          The use of mandatory overtime to complete work within schedule
          constraints is recognized by the company as a sometimes necessary
          means to accomplish project goals. However, the company also
          recognizes that overtime can be abused and as a result inflict lasting
          impact on the productivity of project personnel. Therefore it is
          recommended that overtime be used judiciously. Overtime hours are
          assigned on an individual team member basis in addition to task
          priority assignments.
        </p>
        <p>
          There is no charge to the project for the application of overtime
          hours. The assignment of more than 20 hours per week per person of
          overtime is strongly discouraged by the company.
        </p>
      </div>
    </div>
  );
};
