// ------------------------------------
// Constants
// ------------------------------------

export const DATA_REQUESTED = "DATA_REQUESTED";
export const CHART_DATA_RECEIVED = "CHART_DATA_RECEIVED";
export const LABOR_SUMMARY_RECEIVED="LABOR_SUMMARY_RECEIVED"
export const HISTORY_RECEIVED = "HISTORY_RECEIVED";
export const INTERIM_HISTORY_RECEIVED = "INTERIM_HISTORY_RECEIVED";
export const REPORT_ERROR = "REPORT_ERROR";
