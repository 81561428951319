import React from "react";
import ResourceImage from "components/ResourceImage";
import { ButtonGroup, Input, Label } from 'reactstrap';

export class ReassignPanel extends React.Component {


    state = {
      selectedResponse: 0
    };

  setResponseId = event => {
    this.props.onValueChange(event.target.value);
  };
  render() {
    let responseIndex = 0;
    const msg = `${this.props.condition.Title} needs assistance`;

    const imgPng = `${this.props.condition.Title.replace(/\s+/g, "")}.png`;

    return (
      <div className="component-Alert">
        <div className="alert-Resource">
          <ResourceImage alt={this.props.condition.Title} png={imgPng} />
        </div>
        <div className="alert-Body">
          <h3>{msg}</h3>
          <div className="alert-Message">
            <p>
              I have finished my assigned tasks and am totally out of work to
              do. Would you like to release me from the project or would you
              like me to work on one of the Active Tasks listed below?
            </p>
            {/* <p>{this.props.condition["Message"]}</p> */}
          </div>

          <div className="alert-Response">
            <div className="form-group">
              <ButtonGroup
                name="conditions"
                vertical
                onChange={this.setResponseId.bind(this)}
              >
                {this.props.condition.ResponseList.map((row, index) => (
                  <Label key={`label - ${index.toString()}`} check>
                  <Input
                    type="radio"
                    name="conditionGroup"
                    key={`chk - ${index}.toString()}`}
                    value={responseIndex}
                  />

                   {row[responseIndex++]}
                   </Label>
                ))}
              </ButtonGroup>
            </div>
          </div>
          {/* <div className="alert-Condition">
						{this.props.condition['ConditionId']}
					</div> */}
        </div>
      </div>
    );
  }
}

export default ReassignPanel;
