import React from "react";
import { PerformanceIndexRow } from "./PerformanceIndexRow";

export const PerformanceTable = props => {
  const rating1 = props.worker.Performance1;
  const rating2 = props.worker.Performance2;
  const rating3 = props.worker.Performance3;
  const rating4 = props.worker.Performance4;
  const rating5 = props.worker.Performance5;
  const rating6 = props.worker.Performance6;
  const rating7 = props.worker.Performance7;

  return (
    <div className="table-Performance">
      <PerformanceIndexRow
        textA="Completes Tasks Late"
        textB="Completes Tasks Early"
        rating={rating1}
      />
      <PerformanceIndexRow
        textA="Low Work Quality"
        textB="High Work Quality"
        rating={rating2}
      />
      <PerformanceIndexRow
        textA="Works Well Without Direction"
        textB="Needs Direction On Tasks"
        rating={rating3}
      />
      <PerformanceIndexRow
        textA="Likes to Work in Teams"
        textB="Likes to Work Alone"
        rating={rating4}
      />
      <PerformanceIndexRow
        textA="Weak Communication Skills"
        textB="Strong Communication Skills"
        rating={rating5}
      />
      <PerformanceIndexRow
        textA="Openness to New Challenges"
        textB="Stays within Comfort Zone"
        rating={rating6}
      />
      <PerformanceIndexRow
        textA="Abstract Thinker"
        textB="Detailed Thinker"
        rating={rating7}
      />
    </div>
  );
};
