import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import RequiresAuth from "app/RequiresAuth";
import LoginContainer from "routes/Login";
import Registration from "routes/Registration";
import NotFound from "routes/NotFound";
import { AppContainer as HotLoadBoundary } from "react-hot-loader";
import "react-dates/initialize"
// Import Main/shared styles
// font-awesome icons
import "font-awesome/css/font-awesome.min.css";
// import 'bootstrap/dist/css/bootstrap.css';
// Bootstrap 4.0.0 beta with custom and shared styles
import "../static-new/styles/main.css";
import AppContainer from "./App";
import history from "../history";
import ErrorBoundary from "components/ErrorBoundary";
import * as Sentry from "@sentry/react";

const Root = ({ store }) => (
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Router history={history}>
      <Switch>
        <Route
          exact
          path="/authentication"
          name="Authentication"
          component={LoginContainer}
        />
        <Route
          exact
          path="/registration"
          name="Registration"
          component={Registration}
        />
        <Route exact path="/404" name="404 Not Found" component={NotFound} />
        <HotLoadBoundary>
          <Route path="/" name="app" component={RequiresAuth(AppContainer)} />
        </HotLoadBoundary>
      </Switch>
      </Router>
      </Sentry.ErrorBoundary>

  </Provider>
);

export default Root;
