import React from 'react';

export const Footnote = (props) => {
  return (
    <div className='content' style={{fontSize: "12px"}}>

      {props.properties.footnote}
    </div>
  );
};
