/* eslint-disable max-len */
import React from "react";

export const WorkAssignments = props => (
  <div id="Assignments" className="style-background-components">
    <header>
      <h2>Work Assignments</h2>
    </header>
    <div>
      <p>
        Work assignments are assigned on an individual basis by task according
        to the priority in which you desire work to be performed. Unless
        scheduled otherwise, team members will perform work on their assigned
        tasks in the priority order indicated. Certain scheduled activities do
        take precedence over normal task work assignments, in which case no work
        is performed on the task until the higher precedent activity is
        completed. The following is a list of activities assignable to a team
        member given in the order of highest to lowest precedence:
      </p>
      <ul>
        <li>Stakeholder visits</li>
        <li>Training</li>
        <li>Individual Conferences</li>
        <li>Team meetings</li>
        <li>Task assignments</li>
      </ul>
    </div>
  </div>
);
