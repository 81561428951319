import React from "react";
import { connect } from "react-redux";
import Delay from "react-delay";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Collapse,
  Label,
  Input,
  ButtonToolbar,
  Toast,
  ToastHeader,
  ToastBody,
  Alert
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import { CONFIRMATION_MODAL } from "routes/Modal/ModalTypes";
import BudgetContainer from "routes/Budget/components/BudgetContainer";
import LoadingContainer from "components/Loading";
import TabbedReports from "routes/Report/components/TabbedReports";
import { fetchRuns } from "redux/Authentication/thunks";
import {
  getRecognitionBudgetSummary,
  getTrainingBudgetSummary,
  getStakeholderBudgetSummary,
  getRiskBudgetSummary,
  getResourceBudgetSummary,
  getBudgetSummaryRow,
  getCurrentRunInfo
} from "redux/Project/selectors";
import { lockBudget } from "redux/Project/thunks";
import CheckLog from "./components/CheckLog";
import CheckResults from "./components/CheckResults";
import FooterAction from "./components/FooterAction";
import Content from "./components/Content";
import {
  overallPlanCheck,
  trainingPlanCheck,
  stakeholderPlanCheck,
  riskPlanCheck,
  recognitionPlanCheck,
  resourcePlanCheck
} from "./components/PlanCheck";
import CardHeaderBold from "components/styled/CardHeaderBold";
import history from "../../history";

import "./Approval.css";

class Approval extends React.Component {
  state = {
    loading: false,
    costImpactRegex: /^-?[0-9][0-9]*\d*(\.\d+)?$/,
    budgetPanelOpen: false,
    checksPassed: false,
    approvalStatus: "waiting",
    checkMessage: "",
    checkResults: [],
    budgetTitle:
      this.props.budgetIsLocked === true
        ? "Full Budget - NOTE: budget is locked"
        : "Full Budget",
    submitTitle:
      this.props.budgetIsLocked === true
        ? "Budget Status"
        : "Budget Approval Process"
  };

  componentDidMount = () => {
    // Remove the Info and Budget Item toggle links from the PageHeader
    const PageHeaderEl = document.querySelector(".component-PageHeader");
    if (
      PageHeaderEl &&
      PageHeaderEl.classList.contains("route-PlanningChild")
    ) {
      PageHeaderEl.classList.remove("route-PlanningChild");
    }
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    Promise.all([this.props.fetchRuns(this.props.userId)]);
  };

  doBudgetChecks = (event) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    const checkList = [];

    let pass = true;
    let status = "success";
    let projectBudget = this.props.overallBudget.find((b) => b.WbsId === 0)
      .ApprovedBudget;
    const overallResult = overallPlanCheck(
      this.props.budgetSummary,
      this.props.productId,
      projectBudget
    );
    const trainingResult = trainingPlanCheck(this.props.trainingBudget);
    const stakeholderResult = stakeholderPlanCheck(
      this.props.stakeholderBudget
    );
    const recognitionResult = recognitionPlanCheck(
      this.props.recognitionBudget
    );

    const riskResult = riskPlanCheck(this.props.riskBudget);
    const resourceResult = resourcePlanCheck(
      this.props.resourceBudget,
      this.props.overallBudget
    );

    if (
      overallResult.status === "fail" ||
      trainingResult.status === "fail" ||
      stakeholderResult.status === "fail" ||
      recognitionResult.status === "fail" ||
      riskResult.status === "fail" ||
      resourceResult.status === "fail"
    ) {
      pass = false;
      status = "fail";
    } else if (
      overallResult.status === "warning" ||
      trainingResult.status === "warning" ||
      stakeholderResult.status === "warning" ||
      recognitionResult.status === "warning" ||
      riskResult.status === "warning" ||
      resourceResult.status === "warning"
    ) {
      pass = true;
      status = "warning";
    }

    checkList.push(
      <CheckLog
        key="checkListItems"
        overall={overallResult}
        training={trainingResult}
        stakeholder={stakeholderResult}
        recognition={recognitionResult}
        risk={riskResult}
        resource={resourceResult}
      />
    );
    this.setState({
      checksPassed: pass,
      approvalStatus: status,
      checkResults: checkList
    });
  };

  requestBudgetApproval = () => {
    const title = "Budget Approval Alert";
    const message =
      "If you are part of a classroom execution and you are required to confirm your budget with your instructor before proceeding, please do so now before continuing.";
    const confirmLabel = "Approve Budget";
    const cancelLabel = "Cancel";
    this.props.showConfirmation(
      title,
      message,
      cancelLabel,
      confirmLabel,
      this.handleCancel,
      this.lockTheBudget
    );
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  lockTheBudget = async () => {
    this.props.closeModal();
    const now = Date();
    this.setState({ loading: true });
    const result = await this.props.lockBudget(this.props.runId);

    if (result === true) {
      const alert = {
        id: 7,
        type: "success",
        headline: "Budget Approved",
        event: "approval",
        message: `
					Your budget was approved on:
         ${now.toString()}.
       You now have access to the execution phase of the project.`
      };

      const notificationData = {
        info: `
					Your budget was approved on:
				 ${now.toString()}.`,
        worker: "",
        week: 0
      };
      this.props.addAlert(alert, notificationData, 11000, true);
      this.setState({ loading: false });
      history.push("/execution");
    } else {
      // error occurred TODO handle
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col lg="12">
            <Card className="component-FullBudget">
              <CardHeaderBold>
                <span className="float-left">{this.state.budgetTitle}</span>
                <Label className="switch switch-default switch-primary">
                  <Input
                    type="checkbox"
                    className="switch-input"
                    onClick={() =>
                      this.setState((prevState) => ({
                        budgetPanelOpen: !prevState.budgetPanelOpen
                      }))
                    }
                    defaultChecked={this.state.budgetPanelOpen}
                  />
                  <span className="switch-label" />
                  <span className="switch-handle" />
                </Label>
              </CardHeaderBold>
              <Collapse isOpen={this.state.budgetPanelOpen}>
                <CardBody>
                  <BudgetContainer />
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>

        <Card className="component-Info info-Approval">
          <CardHeaderBold>
            {!this.props.budgetIsLocked && (
              <ButtonToolbar className="float-left">
                {this.state.submitTitle}
                <Button
                  style={{ marginRight: "30px", marginLeft: "100px" }}
                  color="primary"
                  onClick={this.doBudgetChecks}
                >
                  Run Plan Check
                </Button>
                {this.props && this.props.currentRunInfo && this.props.currentRunInfo.message === "ok" &&
                  this.props.currentRunInfo.currentRun.CanPlan !== undefined && this.props.currentRunInfo.currentRun.CanPlan === false ? (
                  <Alert color="info">
                    Your instructor has disabled approvals for now.
                  </Alert>
                ) : (
                  <Button
                    disabled={
                      this.state.approvalStatus === "waiting" ||
                      this.state.approvalStatus === "fail"
                    }
                    color="primary"
                    onClick={this.requestBudgetApproval}
                  >
                    <span> Request Approval</span>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <FontAwesomeIcon
                          style={{ color: "white" }}
                          className="ml-1"
                          spin
                          icon={faSpinner}
                        />{" "}
                      </LoadingContainer>
                    ) : (
                      <span />
                    )}
                  </Button>
                )}
              </ButtonToolbar>
            )}
          </CardHeaderBold>
          <div style={{ background: "white" }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="p-3 my-2 rounded">
                      <Toast>
                        <ToastBody>
                          <Content
                            budgetIsLocked={this.props.budgetIsLocked}
                            checksPassed={this.state.checksPassed}
                            approvalStatus={this.state.approvalStatus}
                            requestBudgetApproval={this.requestBudgetApproval}
                            doBudgetChecks={this.doBudgetChecks}
                          />
                        </ToastBody>
                      </Toast>
                    </div>

                    <div
                      className="p-3 my-2 rounded"
                      style={{ fontWeight: "bold" }}
                    >
                      {!this.props.budgetIsLocked && (
                        <Toast>
                          <ToastHeader>Plan check results</ToastHeader>
                          <ToastBody>
                            {this.state.approvalStatus === "waiting" && (
                              <div
                                className="alert text-center"
                                style={{
                                  height: "100px",
                                  color: "silver",
                                  minWidth: "800px",
                                  border: "1px double silver"
                                }}
                              >
                                Plan check results will show here
                              </div>
                            )}
                            {this.state.approvalStatus === "fail" && (
                              <div className="alert alert-danger">
                                <p>
                                  Your plan cannot be approved. Please fix the
                                  issues indicated in the list below.
                                </p>
                              </div>
                            )}
                            {this.state.approvalStatus === "waiting" && (
                              <span />
                            )}
                            {this.state.approvalStatus === "warning" && (
                              <div className="alert alert-warning">
                                <p>
                                  All checks passed but there are some issues.
                                  Please review the list below and correct as
                                  required then submit your budget for approval
                                </p>
                              </div>
                            )}
                            {this.state.approvalStatus === "success" && (
                              <div className="alert alert-success">
                                <p>
                                  All checks passed. Please be aware that once
                                  management approves your Planned Budget, it
                                  will be locked. You will be able to update
                                  your plans, but the plan changes will not
                                  impact your Planned Budget numbers. Click to
                                  submit your budget for approval.
                                </p>
                              </div>
                            )}
                          </ToastBody>
                        </Toast>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>

        {this.props.budgetIsLocked === false ? (
          <Row>
            <Col>
              <Card className="component-CheckLog">
                <CardHeaderBold>Check Log</CardHeaderBold>
                <CardBody className="mh-0">
                  <div className="check-List">
                    {/* <li className="check-passed">Analysis Check passed</li>
                    <li className="check-passed">Stakeholder Check passed</li>
                    <li className="check-failed">System Materials Check failed</li>
                    <li className="check-passed">Resource Check passed</li> */}
                    {this.state.checkResults}
                  </div>
                </CardBody>
                <CardFooter>
                  <Delay wait={750}>
                    <CheckResults
                      checkResults={this.state.checkResults}
                      checksPassed={this.state.checksPassed}
                      approvalStatus={this.state.approvalStatus}
                    />
                    <FooterAction
                      approvalStatus={this.state.approvalStatus}
                      checkResults={this.state.checkResults}
                      doBudgetChecks={this.doBudgetChecks}
                    />
                  </Delay>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        ) : (
          <div />
        )}

        <TabbedReports />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  productId: state.auth.productId,
  project: state.project,
  runId: state.project.runId,
  currentRunInfo: getCurrentRunInfo(state),
  overallBudget: state.project.budgetList,
  recognitionBudget: getRecognitionBudgetSummary(state),
  trainingBudget: getTrainingBudgetSummary(state),
  stakeholderBudget: getStakeholderBudgetSummary(state),
  riskBudget: getRiskBudgetSummary(state),
  resourceBudget: getResourceBudgetSummary(state),
  budgetSummary: getBudgetSummaryRow(state),
  budgetIsLocked: state.project.budgetIsLocked,
  canRunObject: state.execution.workweek.studentRunWeekReturnObject
});

const mapDispatchToProps = (dispatch) => ({
  fetchRuns: (userId) => dispatch(fetchRuns(userId)),
  lockBudget: (runId) => dispatch(lockBudget(runId)),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  closeModal: () => dispatch(closeModal()),
  showConfirmation: (
    title,
    message,
    cancelLabel,
    confirmLabel,
    handleCancel,
    handleConfirm
  ) =>
    dispatch(
      openModal(
        CONFIRMATION_MODAL,
        {
          title,
          message,
          cancelLabel,
          confirmLabel,
          handleCancel,
          handleConfirm
        },
        "modal-medium"
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Approval);
