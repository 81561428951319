import { createAction } from "redux-actions";
import {
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_ERROR,
} from "./types";

// ------------------------------------
// Actions
// ------------------------------------


export const createClassroomSuccess = createAction(
  CREATE_CLASSROOM_SUCCESS,
  payload => payload
);

export const createClassroomError = createAction(CREATE_CLASSROOM_ERROR, error => error);


