import React from "react";
import imageURLs from "./images/imageURLs.js";

const InteractionHelp = () => (
  <div>
    <h3>Interaction Info:</h3>
    <h4>Context and Resources for Interaction:</h4>
    <p>
      Your interactions with your team members and stakeholders for the current week are scheduled for execution via the daily calendar. Each week reference your Stakeholder, Training and Risks/Reserves plans as they might have actions you need to implement this week. Decide if you want to implement your planned interactions for the week or make changes based on the status of your project. The daily calendar is also where you schedule (for this week):

    </p>
    <ul>
      <li>Team meetings</li>
      <li>Individual conferences</li>
      <li>Team and individual recognition</li>
    </ul>

    <p>
      The following will be useful in making interaction decisions for the week
      :
    </p>

    <ul>
      <li>
        Training and stakeholder interaction options and your current plans{" "}
      </li>

      <li> Risk/Opportunities plan</li>
      <li>
        Project history from previous weeks, especially the latest week. Specifically look at task and team member performance, absences, and stakeholder and team member communications.

      </li>
      <li>
        Reports (Gantt chart, Network diagram, EVMS, Estimated Schedule and
        Cost, Quality, and PPI)
      </li>
      <li>Active and future task descriptions</li>
      <li>Team meeting and individual conference options</li>
      <li>Team and individual recognition options</li>
    </ul>

    <p>
      It is good Project Management practice to keep your plans up to date as
      events change, otherwise your plans may become useless as they no longer
      track with the actual project. Updating your plans as you manage the
      project will help you manage better and make subsequent calendar decisions
      easier.
    </p>
    <p>
      <strong>
        Remember, just like a real project, your plans will not be implemented
        automatically; you must communicate your plan decisions each week to
        your team if you want them to occur. THIS IS VERY IMPORTANT!!!!!!!!!!!
      </strong>
    </p>

    <h4>
      <u>Interaction Decisions:</u>
    </h4>

    <img
    	style={{ maxWidth: "100%", maxHeight: "100%" }}
    	src={imageURLs.interaction}
    	alt="interaction"
    />

    <p>
      Enter an interaction by choosing the day and clicking on the   <i className="fa fa-plus fa-fw" /> icon. Use
      the popup window to complete the process. Once an interaction is
      scheduled:
    </p>

    <ul>
      <li>
        Use the <i className="fa fa-pencil" /> icon to edit it
      </li>
      <li>
        Use the<i className="fa fa-trash" /> icon to delete it
      </li>
      <li>
        Move a scheduled item from the current day to a different day by
        clicking and dragging it to the new day (drop zone is near the top of the day panel)
      </li>
    </ul>
  </div>
);

export default InteractionHelp;
