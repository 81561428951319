import React from "react";
import { useDispatch } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

import { openModal } from "redux/Modal/ModalModule";
import { REFERENCE_MODAL } from "routes/Modal/ModalTypes";

const ReferenceButton = (props) => {
  const dispatch = useDispatch();
   let size="2x"
  if (props.size) {
    size = props.size
  }
  return (
    <>
      <Button
        id={`referenceButton-${props.selectedKey}`}
        className="float-right"
        color="link"
        onClick={() =>
          dispatch(
            openModal(
              REFERENCE_MODAL,
              { selectedKey: props.selectedKey },
              "modal-wide"
            )
          )
        }
      >
        <FontAwesomeIcon icon={faBook} size={size} />
      </Button>
      <UncontrolledTooltip
        placement="top"
        target={`referenceButton-${props.selectedKey}`}
      >
        Click to view the reference document for {props.selectedKey}
      </UncontrolledTooltip>
    </>
  );
};
export default ReferenceButton;
