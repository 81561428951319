import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Collapse
} from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { getNextAvailOrCurrentRun } from "redux/Project/selectors";
import { closeModal } from "redux/Modal/ModalModule";
import { bumpRun } from "redux/Project/thunks";
import { fetchRuns } from "redux/Authentication/thunks";

import ExecutionList from "./components/ExecutionList";
import ProjectInfo from "./components/ProjectInfo";

class ManageProjects extends React.Component {
  state = {
    cardInfoOpen: false
  };

  componentDidMount() {
    this.props.fetchRuns(this.props.auth.userId);
  }

  // throwError() {
  //   throw new Error("oops");
  // }

  canBumpRun = () => {
    let existingRun = false;
    if (
      !this.props.auth ||
      !this.props.auth.studentInfo ||
      !this.props.auth.studentInfo.StudentClassification
    ) {
      return false;
    }
    if (
      this.props.auth &&
      this.props.auth.studentInfo &&
      this.props.auth.studentInfo.StudentClassification &&
      Number.parseInt(this.props.auth.studentInfo.StudentClassification, 10) ===
        Number.parseInt(0, 10)
    ) {
      return false;
    }
    if (
      Number.parseInt(this.props.auth.studentInfo.StudentClassification, 10) !==
        Number.parseInt(0, 10) &&
      this.props.runDetails &&
      this.props.runDetails.length > 0
    ) {
      let keys = [];
      keys = Object.keys(this.props.runDetails);
      for (let r = 0; r < keys.length; r++) {
        if (this.props.auth.runDetails[r].RunId > 0) {
          existingRun = true;
        }
      }
      if (existingRun === true) {
        return true;
      }
    }
    return false;
  };

  bumpMyRun = () => {
    this.props.bumpRun(this.props.auth.userId);
  };

  render() {
    return (
      <>
        <Row>
          <Col lg="12">
            <Card className="Card-info">
              <CardHeaderBold>
                <span style={{ marginRight: "10px" }}>Project Info</span>
                <Label className="switch switch-default switch-primary">
                  <Input
                    type="checkbox"
                    className="switch-input"
                    onClick={() =>
                      this.setState({ cardInfoOpen: !this.state.cardInfoOpen })
                    }
                    defaultChecked={this.state.cardInfoOpen}
                  />
                  <span className="switch-label" />
                  <span className="switch-handle" />
                </Label>
              </CardHeaderBold>
              <Collapse isOpen={this.state.cardInfoOpen}>
                <ProjectInfo />
              </Collapse>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {/* there is a run already started, don't show the start run component */}
            {/* {Object.prototype.hasOwnProperty.call(this.props.currentRun, 'RunId') ? (
					<span />
				) : (
						<Col>
							<Card className="Card-info">
								<CardHeaderBold>Start New Execution</CardHeaderBold>
								<CardBody>
									<StartRunContainer />
								</CardBody>
							</Card>
						</Col>
					
				)} */}
            <Col>
              <Card>
                <CardHeaderBold>Review/Manage Projects</CardHeaderBold>
                {this.props.auth.message !== "" &&
                  this.props.auth.message !== "authenticated" && (
                    <span>
                      <br />
                      <br />
                      <ul>{this.props.auth.message}</ul>
                    </span>
                  )}
                <CardBody>
                  <ExecutionList runDetails={this.props.runDetails} />
                </CardBody>
              </Card>
            </Col>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  studentInfo: state.auth.studentInfo,
  runDetails: state.auth.runDetails,
  // Object.prototype.hasOwnProperty.call(state.auth, "studentInfo") &&
  // Object.prototype.hasOwnProperty.call(state.auth.studentInfo, "RunDetails")
  //   ? state.auth.studentInfo.RunDetails
  //   : [],
  currentRun: getNextAvailOrCurrentRun(state),
  modalOpen: state.modal.open
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  bumpRun: userId => dispatch(bumpRun(userId)),
  fetchRuns: userId => dispatch(fetchRuns(userId))
});

export const ManageProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProjects);
