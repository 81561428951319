import React, { Component, Fragment } from "react";
import Select from "react-select";
import { changePrimaryInstructor } from "redux/Instructor/thunks";
import _debounce from "lodash.debounce";
import { connect } from "react-redux";


class EditPrimaryInstructor extends Component {
	constructor(props) {
		super(props);
		this.updatePrimaryInstructor = this.updatePrimaryInstructor.bind(this);
	}
	updatePrimaryInstructor = _debounce((selected) => {
		this.props.changePrimaryInstructor(selected.value, this.props.currentClassroom.classroomId)
		.then(() => {
			this.props.refresh();
		})

	});
	render() {
		return (
			<Fragment>
				<Select
					id="instructorSelect"
					options={this.props.instructorOptions}
					defaultValue={this.props.instructorOptions.filter(option => option.label === this.props.currentClassroom.primaryInstructorName)}
					placeholder="Choose an instructor ..."
					onChange={selected => {
						this.updatePrimaryInstructor(selected);
					}}
				/>
			</Fragment>
		);
	}
}
const mapStateToProps = state => ({
	userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
	changePrimaryInstructor: (instructorId, classroomId) => dispatch(changePrimaryInstructor(instructorId, classroomId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPrimaryInstructor);
