// src/routes/Notification/index.js

import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { getUserNotifications } from "redux/Notification/NotificationModule";
import {
	TrainingNotification,
	ResourcePlanNotification,
	InteractionNotification,
	WorkWeekNotification
} from "./components";

import "./Notification.css";

class Notification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {
		this.props.getUserNotifications(this.props.runId);
		// Remove the Info and Budget Item toggle links from the PageHeader
		const PageHeaderEl = document.querySelector(".component-PageHeader");
		if (
			PageHeaderEl &&
			PageHeaderEl.classList.contains("route-PlanningChild")
		) {
			PageHeaderEl.classList.remove("route-PlanningChild");
		}
	};

	renderNotifications = () => {
		const notificationCollection = [];
		let iterKey = 1;
		for (const n in this.props.notifications) {
			const thisNote = this.props.notifications[n];

			if (thisNote.NotificationEvent === "training") {
				notificationCollection.push(
					<TrainingNotification key={iterKey++} notification={thisNote} />
				);
			} else if (thisNote.NotificationEvent === "interaction") {
				notificationCollection.push(
					<InteractionNotification key={iterKey++} notification={thisNote} />
				);
			} else if (thisNote.NotificationEvent === "workweek") {
				notificationCollection.push(
					<WorkWeekNotification key={iterKey++} notification={thisNote} />
				);
			} else if (thisNote.NotificationEvent === "resourcePlan") {
				notificationCollection.push(
					<ResourcePlanNotification key={iterKey++} notification={thisNote} />
				);
			}
		}
		return notificationCollection;
	};

	render() {
		return (
			<Row>
				<Col lg="9">
					<Card>
						<CardHeader>Timeline</CardHeader>
						<CardBody className="mh-0">
							<ul className="notifications-list">
								{this.renderNotifications()}
							</ul>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	notifications: state.notification.notifications,
	runId: state.project.runId
});

const mapDispatchToProps = dispatch => ({
	getUserNotifications: runId => dispatch(getUserNotifications(runId))
});

export const NotificationHistoryContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Notification);
