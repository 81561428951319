import React from "react";

const RenderWeekColumns = ({ maxWeek, data, keyTag }) => {
  

  
  const items = () => {
    const weekCols = [];
    for (let i = 1; i <= maxWeek; i += 1) {
      if (data[i] !== 0) {
        weekCols.push(
          <td className="text-right" key={`${keyTag}-${i.toString()}`}>
            {data[i.toString()]}
          </td>
        );
      } else {
        weekCols.push(
          <td key={`${keyTag}-${i.toString()}`}>
            <span />
          </td>
        );
      }
    }
    return weekCols
  }
    


  return (
    <>
      {items()}
      </>
  )

  };

export default RenderWeekColumns;
