import React from "react";
import accounting from "accounting";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import RenderWeekColumns from "./RenderWeekColumns";
import TableFooter from "./TableFooter";
import { Card } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

const ReservesTable = ({ maxWeek, reserveHoursContent, options }) => {
  const reserveHoursHeaders = () => {
    let headers = [];
    headers.push(
      <th className="text-left" key="reserveweeks">
        Weeks
      </th>
    );

    for (let i = 1; i <= maxWeek; i += 1) {
      headers.push(
        <th className="text-center" key={`RW${i}`}>
          {i}
        </th>
      );
    }

    headers.push(
      <th className="text-center" key={"totrsvhrs"}>
        Reserve Hours
      </th>
    );
    headers.push(
      <th className="text-center" key={"totrsvcosts"}>
        Reserve Costs{" "}
      </th>
    );

    return headers;
  };

  return (
    <Card>
      <CardHeaderBold>
        Reserve Breakdown
      </CardHeaderBold>
      <NoMaxCardBody>
        <StyledTable bordered hover>
          <thead>
            <tr>{reserveHoursHeaders()}</tr>
          </thead>
          <tbody>
            {reserveHoursContent.map((data, index) => {
              return (
                <tr key={data.event}>
                  <td key={`${data.event}-td`} className="text-left">
                    {data.description}
                  </td>
                  <RenderWeekColumns
                    data={data}
                    keyTag="reserve"
                    maxWeek={maxWeek}
                  />
                  <td className="text-right" key={`${data.event}-th`}>
                    {data.event === "other-costs"
                      ? "N/A"
                      : data.totalHours}
                  </td>
                  <td className="text-right" key={`${data.event}-tc`}>
                    {accounting.formatMoney(data.totalCosts, options)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <TableFooter maxWeek={maxWeek} data={reserveHoursContent} />
        </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default ReservesTable;
