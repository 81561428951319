/* eslint  react/prop-types: 0 */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import { bumpStudentRun, resetStudentRun, fetchStudentInfo } from "redux/Instructor/thunks";
import { setLoading } from "redux/Instructor/actions";

import { fetchProjectData} from "redux/Project/thunks";

import { RESET_PROJECT_MODAL } from "routes/Modal/ModalTypes";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import RightMarginButton from "components/styled/RightMarginButton";
import ResetWeekChooser from "routes/Projects/components/ResetWeekChooser";

import BumpRun from "routes/Projects/components/BumpRun";
import { fetchHistoryData, fetchChartData } from "redux/Report/thunks";
const StudentActionPopover = (props) => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();
  const [popoverOpen, togglePopoverOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(0);

  let selectWeek = (selected) => {
    setSelectedWeek(selected.value);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const resetTheRun = async (runId, selectedWeek) => {
    await dispatch(resetStudentRun(runId, selectedWeek));
  };

 

  return (
    <>
      <div style={{ paddingLeft: "1px", paddingRight: "1px" }}>
            <UncontrolledTooltip id='reviewtt' placement='top' target='reviewButton'>
              Takes you to the Selected Student Overview tab to review this student's projects
            </UncontrolledTooltip>
        <Button style={{ marginRight: "5px", width: "50px", padding: "1px" }} id="reviewButton"
          color="primary" outline onClick={(event) => {
            event.preventDefault();
            dispatch(setLoading(true));
            dispatch(fetchStudentInfo(props.row));
            props.toggleTab("selected-student");
        }}>
          Review
      </Button>
        {props.productId === 50 &&
          <>
           <UncontrolledTooltip id='resettt' placement='top' target='resetButton'>
              Lets you reset this project to a previous week. A modal will open where you can choose the week.
            </UncontrolledTooltip>
        <Button
          id="resetButton"
            style={{marginRight: "5px", width:"40px", padding: "1px"}}
          color="primary"
          outline
              key="reset-run"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              <span style={{fontWeight: "bold", fontSize: "small"}}>Reset</span>
            </Button>
    <UncontrolledTooltip id='bumptt' placement='top' target='bumpButton'>
              Bumps the current project and makes the execution available for a new one.
            </UncontrolledTooltip>
        <Button
          id="bumpButton"
           style={{marginRight: "5px", width:"40px", padding: "1px"}}
          color="primary"
          outline
              key="bump-run"
              onClick={async () => {
                await dispatch(bumpStudentRun(props.row.runId, props.row.id, props.classroomId));
                
              }}
            >
              <span style={{fontWeight: "bold", fontSize: "small"}}>Bump</span>
            </Button>
            </>}
      </div>
      <>
        <Modal
          isOpen={modalOpen}
          toggle={() => setModalOpen(!modalOpen)}
          // className={this.props.className}
         
        >
          <ModalHeader
            cssModule={{ "modal-title": "w-100 text-left" }}
            toggle={() => setModalOpen(!modalOpen)}
          >
            Reset run to beginning of chosen week
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="1"></Col>
              <Col xs="10">
          
            <ResetWeekChooser
              runId={props.row.runId}
              selectedWeek={selectedWeek}
              currentWeek={content.project.currentProjectWeek}
              setSelectedWeek={setSelectedWeek}
              resetProject={resetTheRun}
              closeModal={closeModal}
              />
             </Col>
              <Col xs="1"></Col>
               </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => setModalOpen(!modalOpen)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
   </>
  );
};

export default StudentActionPopover;
