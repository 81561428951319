import React from "react";
import { Link } from "react-router-dom";
import  StartRunContainer from "routes/Projects/components/StartRun";

// type State = {
//   selectedPlanId: number
// };
export const StartRunModal = props => (
  <div>
    <p>Congratulations on completing your previous run.</p>
    {props.availRuns > 0 ? (
      <div>
        <p>
          You currently have&nbsp;
          {props.availRuns === 1 ? (
            <span> 1 run&nbsp;</span>
          ) : (
            <span>{props.availRuns} runs&nbsp;</span>
          )}
          remaining. To begin your next execution, click the Start New Project
          below. You can also review your previous project execution(s) by
          clicking the Review Projects button.
        </p>
        <StartRunContainer startedByModal />
      </div>
    ) : (
      <div>
        <p>You have completed the last available execution for your license.</p>
        <p>
          Click the Review Projects link to view your previous project
          executions
        </p>
      </div>
    )}
    <Link to="/projects" onClick={props.closeModal}>
      Review Previous Executions
    </Link>
  </div>
);
