import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { TableHeaderColumn } from "react-bootstrap-table";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { getUniqueInstructors } from "redux/Authentication/selectors";
import ClassroomActionPopover from "./ClassroomActionPopover"
import EditPrimaryInstructor from "./EditPrimaryInstructor";
import AddSecondaryInstructor from "./AddSecondaryInstructor";
import EditClassName from "./EditClassName";
import EditClassroomStatus from "./EditClassroomStatus";
import EditClassDates from "./EditClassDates";
import CardHeaderBold from "components/styled/CardHeaderBold";

import {
  fetchClassesByInstructor,
  removeSecondaryInstructor,
  updateClassStatus
} from "redux/Instructor/thunks";
import { openModal } from "redux/Modal/ModalModule";
import { NEW_CLASSROOM_MODAL } from "routes/Modal/ModalTypes";
import StyledBootstrapTable from "components/styled/StyledBootstrapTable";

const StyledTableHeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;

  > .col:first-child .btn {
    margin-right: 0px;
  }

  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .search {
    margin-top: 0.5rem;
  }
`;
class ClassroomTable extends Component {
	constructor(props) {
		super(props);
		// need to use state variable for  list because props.classrooms
		// is immutable and you can't sort an immutable list
    let iclasses = [];

		this.state = {
			// instructors: [...this.props.instructors],
			instructorModal: false,
			currentRow: {},
			popoverOpen: false,
			// selectedInstructor: {},
			// disableSaveButton: true,
			modalAction: "",
			sortName: "classroomId",
			sortOrder: "asc",
			modalMessage: "",
			selectedClassName: ""
		};
		this.onRefreshList = this.onRefreshList.bind(this)
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.toggleInstructorModal = this.toggleInstructorModal.bind(this);
		this.actionItemClicked = this.actionItemClicked.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

	}

	componentDidMount() {
    if (this.props.instructorClasses) {
      this.setState({ classroomList: [...this.props.instructorClasses.reverse()] });
    }



	}
	onSortChange = (sortName, sortOrder) => {
		this.setState({
			sortName,
			sortOrder
		});
	}

	statusFormatter = (cell) => {
		const cellValue = cell || "";
		switch (cellValue.toLowerCase()) {
			case "running":
				return <Badge color="success">{cellValue}</Badge>;
			case "halted":
				return <Badge color="warning">{cellValue}</Badge>;
			case "inactive":
				return <Badge color="dark">{cellValue}</Badge>;
			case "new":
				return <Badge color="secondary">{cellValue}</Badge>;
			case "registration":
				return <Badge color="info">{cellValue}</Badge>;
			default:
				return cellValue;
		}
	};

  menuFormatter = (cell, row) => {

		// if (Number.parseInt(row.classroomId, 10) === Number.parseInt(0, 10)) {
		// 	return <span />
		// }
    return <ClassroomActionPopover key={`classroom-action-popover=${row.classroomId.toString()}`} toggleDropdown={this.toggleDropdown} row={row} id={row.classroomId} placement={"left"}
			actionClicked={this.actionItemClicked} />;
	};

	primaryInstructorFormatter = (cell) => {
		// If there's no value, return nothing
		if (!cell) {
			return "NO PRIMARY INSTRUCTOR";
		}
		return cell;
		// If our cell instrutor is in our instructor list exactly, return it
		// if (this.props.instructors.includes(cell)) {
		// 	return cell;
		// }

		// Finall, if there's a cell value that does not exist in our instructor list,
		// return error
		//return <Badge color="danger">{cell} value incorrect</Badge>;
	};

	secondaryInstructorFormatter = (cell) => {
		// If there's no value, return nothing
		if (!cell) {
			return "";
		}
		var secondaryString = cell.map(function (secondary) {
			return secondary.instructorName;
		}).join(", ");
		return secondaryString;
	};

  onRowClick = (row) => {
		this.setState({
			currentRow: row
		})
		this.props.setSelectedClass(row);
	};

	renderModalHeader = () => {
		if (this.state.modalAction === "editPrimary") {
			return <Row><Col sm="8">Change Primary Instructor</Col>
				<Col sm="4"><span>{this.state.modalMessage}</span></Col>

			</Row>;

		} else if (this.state.modalAction === "addSecondary") {
			return <Row><Col sm="8">Add Secondary Instructor</Col>
				<Col sm="4"><span>{this.state.modalMessage}</span></Col>

			</Row>;
		} else if (this.state.modalAction === "editClassName") {
			return <Row><Col sm="8">Edit Class Name</Col>
				<Col sm="4"><span>{this.state.modalMessage}</span></Col>

			</Row>;
		} else if (this.state.modalAction === "changeStatus") {
			return <Row><Col sm="8">Edit Class Status</Col>
				<Col sm="4"><span>{this.state.modalMessage}</span></Col>

			</Row>;
			} else if (this.state.modalAction === "changeDates") {
				return <Row><Col sm="8">Edit Class Dates</Col>
					<Col sm="4"><span>{this.state.modalMessage}</span></Col>

				</Row>;


		} else {
			return <div>unknown action</div>
		}

	};

	toggleInstructorModal = () => {
		this.setState({ instructorModal: !this.state.instructorModal, modalMessage: "" });
	};

	toggleDropdown = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

  actionItemClicked = (action, row, event) => {
		event.preventDefault();

		if (action === "removeSecondary") {
			this.props.removeSecondaryInstructor(row.instructorId, row.classroomId)
		} else {
			this.setState({
				currentRow: row,
				instructorModal: true,
				modalAction: action
			})
			this.props.setSelectedClass(row);

		}
	};

	onRefreshList = () => {
		Promise.all([
			this.setState({ loading: true }),
			this.props.fetchClassesByInstructor(this.props.userId),
		]).then(() => {
			this.setState({ loading: false });
		});

	}

	dateFormatter = cell => {
		if (cell === null) {
			return ""
		}
		return (moment(cell).format("MM/DD/YYYY")).toString()
	}
	showAddClassroomModal = (evt) => {
		evt.preventDefault();
		this.props.showAddClassroomModal(this.onRefreshList);
	}

	processInstructorModalAction = () => {
		if (this.state.modalAction === "editPrimary") {
			return (
				<EditPrimaryInstructor
					refresh={this.onRefreshList}
					instructorOptions={this.getInstructorOptions()}
					currentClassroom={this.state.currentRow} />
			)
		} else if (this.state.modalAction === "addSecondary") {
			return (
				<AddSecondaryInstructor
				refresh={this.onRefreshList}
				instructorOptions={this.getInstructorOptions()}
				currentClassroom={this.state.currentRow} />
			)
		} else if (this.state.modalAction === "editClassName") {
			return <EditClassName
				currentClassroom={this.state.currentRow}
				refresh={this.onRefreshList} />
		} else if (this.state.modalAction === "changeStatus") {
			return (
				<EditClassroomStatus currentClassroom={this.state.currentRow}
				refresh={this.onRefreshList} />
			)
		} else if (this.state.modalAction === "changeDates") {
			return(
				<EditClassDates currentClassroom={this.state.currentRow}
				refresh={this.onRefreshList} />
			)
		}
	}
	getStatusOptions = () => {
		const statuses = [];
		statuses.push("Registration")
		statuses.push("Running")

		return statuses
	}
	getInstructorOptions = () => {
		const instructorOptions = [];
		for (var i = 0, l = this.props.instructors.length; i < l; i++) {
			instructorOptions.push(
				{
					value: this.props.instructors[i].id,
					label: this.props.instructors[i].instructorName,
				}
			)
		}
		return instructorOptions
	}
  render() {

     if (!this.props || !this.props.instructorClasses) {
      return <span>No Classes for this instructor</span>
    }
		//	const { cellEdit, options, hover, search } = this.props;
    // commenting for now - lori 8/7/2020
		// const cellEditProp = {
		// 	mode: "click",
		// 	blurToSave: true,
		// 	// beforeSaveCell: this.validate,
		// 	afterSaveCell: this.saveClassroomChange
		// };

		const createCustomToolBar = (props) => (
			<Col>
				<StyledTableHeaderRow>
					<Col sm="12" lg="6">
						<Button size="sm" color="primary" onClick={this.onRefreshList}>
							<i className="fa fa-refresh" />
						</Button>
						<Button
							size="sm"
							outline
							color="primary"
							onClick={(evt) => this.showAddClassroomModal(evt)}
						>
							<i className="fa fa-graduation-cap" /> Create new classroom
						</Button>


						{/* <Button
						size="sm"
						outline
						color="primary"
						onClick={() => openModal("import-classrooms")}
					>
						<i className="fa fa-upload" /> Import classrooms from CSV
					</Button> */}
					</Col>
					<Col sm="12" lg="6">
						<div className="search">{props.components.searchPanel}</div>
						<div className="search" style={{ color: "maroon" }}><b>Current Sort</b>:  column={this.state.sortName}, order={this.state.sortOrder}</div>

					</Col>
				</StyledTableHeaderRow>
			</Col>
		);

		const tableOptions = {
      toolBar: createCustomToolBar,
      onRowClick: (row) => this.onRowClick(row),
			clearSearch: true,
			sizePerPage: 20,
			sizePerPageList: [
				{
					text: "20",
					value: 20
				},
				{
					text: "40",
					value: 40
				},
				{
					text: "60",
					value: 60
				}
			]
		};


		return (
			<Card>
        <CardHeaderBold>
         Classroom List

        </CardHeaderBold>

				<CardBody>
          {this.props && this.props.instructorClasses &&
            <Fragment>
            <StyledBootstrapTable
			  data={this.props.instructorClasses.length > 0
				? this.props.instructorClasses.sort((a,b) => b.classroomId - a.classroomId) :
				this.props.instructorClasses}
              options={tableOptions}
              hover
              search

              pagination
              key={"tbl"}
            >
              <TableHeaderColumn
                dataAlign="center"
                width="5%"
                isKey
                dataField="classroomId"
                dataSort>
                ID
						</TableHeaderColumn>
              <TableHeaderColumn width="25%"
                dataField="className"
                editable={{ type: "textarea" }}
                editColumnClassName="editing-classname-class"
                tdStyle={{ whiteSpace: "normal" }}
                dataSort>
                Class Name
						</TableHeaderColumn>
              <TableHeaderColumn
                dataField="primaryInstructorName"
                tdStyle={{ whiteSpace: "normal" }}
                editable={false}
                width="10%"
                dataSort
                dataFormat={this.primaryInstructorFormatter}
              >
                Primary Instructor
						</TableHeaderColumn>
              <TableHeaderColumn

                dataField="secondaries"
                tdStyle={{ whiteSpace: "normal" }}
                width="15%"
                editable={false}
                // editable={{
                // 	type: "select",
                // 	options: { values: this.instructorsOptions  }
                // }}
                dataSort
                dataFormat={this.secondaryInstructorFormatter}
              >
                Secondary Instructor
						</TableHeaderColumn>
              <TableHeaderColumn
                dataField="startDate"
                dataSort
                width="8%"
                dataFormat={this.dateFormatter}>
                Start Date
			</TableHeaderColumn>
              <TableHeaderColumn
                dataField="endDate"
                width="8%"
                dataSort
                dataFormat={this.dateFormatter}>
                End Date
			</TableHeaderColumn>

              <TableHeaderColumn
                dataField="classroomStatus"
                dataSort
                width="8%"

              >
                Status
						</TableHeaderColumn>
              <TableHeaderColumn
                width="10%"
                dataAlign="center"
                dataField="actions"
                onClick={this.actionItemClicked}
                dataFormat={this.menuFormatter}>
                Actions
						</TableHeaderColumn>
            </StyledBootstrapTable>

            <Modal
              isOpen={this.state.instructorModal}
              toggle={this.toggleInstructorModal}
              className={this.props.className}
              size="lg"
            >
              <ModalHeader cssModule={{ "modal-title": "w-100 text-left" }}
                toggle={this.toggleInstructorModal}>
                {this.renderModalHeader()}
              </ModalHeader>
              <ModalBody>
                {this.processInstructorModalAction()}
              </ModalBody>
              <ModalFooter>

                <Button color="primary" outline onClick={this.toggleInstructorModal}>
                  Close
							</Button>


              </ModalFooter>
            </Modal>
  </Fragment>}
				</CardBody>
			</Card>
		);
	}

}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  instructorClasses: state.instructor.instructorClasses,
  instructors: state.instructor.instructors,
  uniqueInstructors: getUniqueInstructors(state),
  loading: state.instructor.loading
});

const mapDispatchToProps = dispatch => ({
  updateClassStatus: (cId, cStatus) =>
    dispatch(updateClassStatus(cId, cStatus)),
  removeSecondaryInstructor: (instructorId, classroomId) =>
    dispatch(removeSecondaryInstructor(instructorId, classroomId)),
  fetchClassesByInstructor: instructorId =>
    dispatch(fetchClassesByInstructor(instructorId)),
  showAddClassroomModal: refresh =>
    dispatch(openModal(NEW_CLASSROOM_MODAL, { refresh }, "modal-lg"))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomTable);
