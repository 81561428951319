import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Card, Collapse, Label, Input } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import QualityInfo from "./QualityInfo";
import HistoryInfo from "./HistoryInfo";
import EACInfo from "./EACInfo";
import EPFInfo from "./EPFInfo";
import EVInfo from "./EVInfo";
import GanttInfo from "./GanttInfo";
import NDInfo from "./NDInfo";
import PDFInfo from "./PDFInfo";
import PPIInfo from "./PPIInfo";
import LaborCostInfo from "./LaborCostInfo";

import { toggleReportInfo } from "app/redux/actions";
import InfoFooter from "components/Info/InfoFooter";

const CollapsibleInfoPanel = (props) => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <Card>
      <CardHeaderBold>
        <span className="float-left">{props.title}</span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => dispatch(toggleReportInfo())}
            defaultChecked={content.app.reportInfoVisible}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </CardHeaderBold>
      <Collapse isOpen={content.app.reportInfoVisible}>
        {props.selectedKey === "quality" && <QualityInfo productId={content.auth.user.productId}/>}
        {props.selectedKey === "history" && <HistoryInfo />}
        {props.selectedKey === "eac" && <EACInfo />}
        {props.selectedKey === "ev" && <EVInfo />}
        {props.selectedKey === "epf" && <EPFInfo />}
        {props.selectedKey === "gantt" && <GanttInfo />}
        {props.selectedKey === "labor" && <LaborCostInfo />}
        {props.selectedKey === "nd" && <NDInfo />}
        {props.selectedKey === "pdfs" && <PDFInfo />}
        {props.selectedKey === "ppi" && <PPIInfo />}

        <InfoFooter
          showProjectLink={
            props.selectedKey === "history" || props.selectedKey === "quality"
              ? true
              : false
          }
          selectedKey={props.selectedKey}
        />
      </Collapse>
    </Card>
  );
};

export default CollapsibleInfoPanel;
