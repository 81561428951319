import React, { Component, Fragment } from "react";
import { Button, Input, Row, Col } from "reactstrap";
import { updateClassName } from "redux/Instructor/thunks";
import _debounce from "lodash.debounce";
import { connect } from "react-redux";


class EditClassName extends Component {

    constructor(props) {
        super(props);
        this.saveClassroomChange = this.saveClassroomChange.bind(this);

    }
    saveClassroomChange = _debounce((e) => {
        this.props.updateClassName(
            this.props.currentClassroom.classroomId,
            this.className.value
        ).then(() => {
            this.props.refresh();
        });
    });
render() {

    return (
        <Fragment>
            <Row>
                <Col xs="4" sm="6" lg="9">
            <Input
                type="text"
                name="className"
                className="mb-2 mr-sm-2 mb-sm-0"
                defaultValue={this.props.currentClassroom.className}
                innerRef={(cn) => (this.className = cn)}
                style={{
                    flex: "350px 0"
                }}
            />
            </Col>
            <Col width="10%">
            <Button  xs="2" sm="2" lg="3"
                color="primary"
                onClick={e => this.saveClassroomChange(e)}
            >
                Update
         </Button>
         </Col>
         </Row>
        </Fragment>
    );
}
}
const mapStateToProps = state => ({
    userId: state.auth.userId
});
const mapDispatchToProps = dispatch => ({
    updateClassName: (cId, cName) => dispatch(updateClassName(cId, cName))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditClassName);
