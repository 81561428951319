/* eslint-disable max-len */
const _supportEmail = "support@simulationpl.com";

const messages = {
  feedbackMessages: [
    {
      messageType: "ShowZoomInfo",
      panelNumber: 1,
      cannedImage: "Confident",
      cannedMessage:
        "The Zoom number on your dashboard represents your current Browser Page Zoom Value.  Zoom Values above 100% will cause data at the bottom and right hand parts of your screen to seem to disappear!  So, it is best to keep the number at 100% or less.  You must set your Browser Zoom Value yourself.  Apps like ours are expressly restricted from changing any value outside our own domain.  To learn how to change the zoom level for your browser, do the following internet search (filling in your browser name):  <ul><li> Change Zoom Level Internet Explorer</li><li>Change Zoom Level Chrome</li><li>Change Zoom Level Safari  (etc.)...</li></ul>",
      msgHeight: 325,
      msgWidth: 400
    },
    {
      messageType: "ShowSpeedInfo",
      panelNumber: 2,
      cannedImage: "Clock",
      cannedMessage:
        "The Speed number on your dashboard represents how well you are connected to our server. The maximum speed that you can achieve is 100.   The minimum is zero. Low Speed becomes cause for concern if it stays under 10 for more than 60 seconds. Typically, the only way to change such a low Speed value is to try a different computer or internet connection.",
      msgHeight: 205,
      msgWidth: 320
    },
    {
      messageType: "ShowReadOnlyResPlan",
      panelNumber: 3,
      cannedImage: "Hand-Up",
      cannedMessage:
        "This Resource Plan is for reference purposes ONLY!  To make changes to your resource plan, please select PLANNING from the menu.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedMustSelectClassroom",
      panelNumber: 4,
      cannedImage: "Aha",
      cannedMessage:
        "You must select a classroom before you can start a new Project! Click on the 'Change Classroom' button.  Then select your Instructor's Name followed by your Classroom Name. Finally, click the <u><b>Move Me</u></b> button, when it appears!",
      //  'SetBukiImage(Think)',
      msgWidth: 200,
      msgHeight: 225
    },
    {
      messageType:
        "CannedNewUserName(ByVal Fname As String, ByVal Lname As String)",
      panelNumber: 5,
      cannedImage: "BusinessWoman",
      cannedMessage:
        "This is what I have for your new name: {fName} and {lName}<br/> REMEMBER, your username that you use when loggging in has not changed; however, your instructor will be able to identify you as  {fName} {lName}, and this is the name that will appear on your History Reports.",
      msgWidth: 225,
      msgHeight: 265
    },
    {
      messageType:
        "CannedNameOnHistoryReports(ByVal Fname As String, ByVal Lname As String)",
      panelNumber: 6,
      cannedImage: "Confident",
      cannedMessage:
        "This is what I have for your new name: {fName} {lName}. REMEMBER, your username you use when logging in has not changed; however, your instructor will be able to identify you as {fName} {lName}, and this is the name that will appear on your History Reports.",
      //  'SetBukiImage(Confident)',
      msgWidth: 225,
      msgHeight: 265
    },
    {
      messageType: "CannedAccountExpired",
      panelNumber: 7,
      cannedImage: "Clock",
      cannedMessage:
        "Your account has expired. If you require more time to finish your class work, please send an email to support@simulationpl.com requesting an extension.  You MUST include your username and ask support to extend your license!  There is no cost for an extension.",
      msgWidth: 200,
      msgHeight: 225
    },
    {
      messageType: "CannedCloseBrowserTab",
      panelNumber: 8,
      cannedImage: "Sad",
      cannedMessage:
        "They told us that Project Management was hard! But, do we really need stupid browser issues on top of everything else? Please close this browser tab, then re-launch the Simulation Page Link. If you have not rebooted your computer for days, that might really help too! You might even consider trying a different browser if you keep getting this message.",
      //  'SetBukiImage(Sad)',
      msgWidth: 300,
      msgHeight: 300
    },
    {
      messageType:
        "CannedNewAppVersion(_oldversion As String, _newversion As String)",
      panelNumber: 9,
      cannedImage: "Good-Job",
      cannedMessage:
        "There is newer version of the software available. Click your browser's Reload button to get the most recent version. (In some cases, you may need to clear your browser cache).  You are using {oldversion} but the newer version is {newversion}.",
      msgWidth: 200,
      msgHeight: 225
    },
    {
      messageType: "CannedCheckInternetConnection",
      panelNumber: 10,
      cannedImage: "Holding-Breath",
      cannedMessage:
        "From what I can see, you don't have a good internet connection right now. Please check your internet connection! Here is a link I like to use to test my connection speed, it is free: <a href=\"http://www.speedtest.net/\" target=\"blank\">http://www.speedtest.net</a>.  If this problem persists, email <a mailto=\"support@simulationpl.com\">support</a>",
      msgWidth: 300,
      msgHeight: 300,
      Left: 700,
      Top: 250
    },
    {
      messageType: "CannedListenerNoChanges",
      panelNumber: 11,
      cannedImage: "Yawn",
      cannedMessage:
        "As a Listener of a Team, you cannot make changes! Only the TokenHolder gets to make the actual changes to the Team Project.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedScheduleConflict(ByVal Desc As String)",
      panelNumber: 12,
      cannedImage: "Upset",
      cannedMessage:
        "I noticed you have a conflict on your schedule.  It is no big deal, really.  You just need to shuffle some calendar actions.   Here is a description of the conflict:  {conflictDescription}",
      msgWidth: 220,
      msgHeight: 255
    },
    {
      messageType: "CannedCannotAddAppointment",
      panelNumber: 13,
      cannedImage: "Pouting",
      cannedMessage:
        `I'm sorry, something has happened and I cannot continue adding this appointment.  Please retry your operation or contact my support team at   ${ 
        _supportEmail}`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoTopicChosen",
      panelNumber: 14,
      cannedImage: "Pleading",
      cannedMessage:
        "I noticed you have not yet chosen a topic for this interaction.   Please choose a topic and try your Save again, or Cancel the operation.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoStakeholderResource",
      panelNumber: 15,
      cannedImage: "Pleading",
      cannedMessage:
        "I noticed you have not yet chosen a resource for your stakeholder visit.  Please choose a resource (worker) and try your Save again, or Cancel the operation.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoStakeholderChosen",
      panelNumber: 16,
      cannedImage: "Pleading",
      cannedMessage:
        "I noticed you have not yet chosen a stakeholder.   Please choose one or more stakeholders and try your Save again, or Cancel the operation.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoWorkersAssigned",
      panelNumber: 17,
      cannedImage: "Scolding",
      cannedMessage:
        "There are no workers assigned to the project therefore no items can be scheduled. You need to <u><b>Staff</b></u> some workers in the Staffing Page. Read the Staffing Page <u><b>Help</b></u> if this does not make sense to you!",
      msgWidth: 250,
      msgHeight: 225
    },
    {
      messageType: "CannedTimeOut",
      panelNumber: 18,
      cannedImage: "Clock",
      cannedMessage:
        "Someone has used your credentials and has logged you out.  The most likely reasons are:  <ul><li>You may have logged in on another browser or another tab. </li><li> You are using a shared Team TokenHolder Account </li><li>The System Admin may be researching a problem that you reported.</li></ul>If none of these three reasons seem to fit your situation then use the Forgot Password Function on the Login Form to reset your password.",
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedResumeRunError",
      panelNumber: 19,
      cannedImage: "Overwhelmed",
      cannedMessage:
        `Dang it!  We thought we had fixed this problem FOREVER!  Please help us fix it once and for all. We even gave this problem a number!  Please report the following to  ${ 
        _supportEmail 
        } :  your RunId and Problem #12  Thank you!`,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedResumeRunErrorNoDetails",
      panelNumber: 20,
      cannedImage: "Overwhelmed",
      cannedMessage:
        `Dang it!  We thought we had fixed this problem FOREVER!  Please help us fix it once and for all. We even gave this problem a number!  Please report the following to   ${ 
        _supportEmail 
        } :  Your RunId and Problem #14  Thank you!`,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedGetRunViewDTOError",
      panelNumber: 21,
      cannedImage: "Overwhelmed",
      cannedMessage:
        `Please report the following to   ${ 
        _supportEmail 
        }  :  Your Login Account name.  Error #13 Please click Logout.  Thank you! `,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedClassroomFault",
      panelNumber: 22,
      cannedImage: "Upset",
      cannedMessage:
        `We have encountered a 'ClassroomService' Fault while talking to the Cloud.   Sometimes it is something as harmless as a poor internet connection. You should be able to continue without problems; however, if this persists, PLEASE EMAIL   ${ 
        _supportEmail}`,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedSimServiceFault",
      panelNumber: 23,
      cannedImage: "Upset",
      cannedMessage:
        `We have encountered a 'SimService' Fault while talking to the Cloud.   Sometimes it is something as harmless as a poor internet connection. You should be able to continue without problems; however, if this persists, PLEASE EMAIL   ${ 
        _supportEmail}`,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedAdminServiceFault",
      panelNumber: 24,
      cannedImage: "Upset",
      cannedMessage:
        `We have encountered an 'AdminService' Fault while talking to the Cloud.   Sometimes it is something as harmless as a poor internet connection You should be able to continue without problems; however, if this persists, PLEASE EMAIL   ${ 
        _supportEmail}`,
      msgWidth: 400,
      msgHeight: 285
    },
    {
      messageType: "CannedLookAtResPlan",
      panelNumber: 25,
      cannedImage: "Pouting",
      cannedMessage:
        "You used a Plan File to create your plan. The Plan File is a shortcut, and I have to make sure everything is in order before you continue. In order for me to get everything synchronized and verified, I require you to view the Resource Plan before you can request Budget Approval. I'll send you to the Resource Plan now!",
      msgWidth: 220,
      msgHeight: 265
    },
    {
      messageType: "CannedKeepDavidLewis",
      panelNumber: 26,
      cannedImage: "Thinking",
      cannedMessage:
        "You really don't want to remove David Lewis from the project during the first two weeks of your project! He is the only resource currently on the project who is available to do work! If this sounds mysterious to you, then I suggest you click the Help Button on this page and read the help page, carefully.",
      msgWidth: 210,
      msgHeight: 235
    },
    {
      messageType: "CannedMissedVote",
      panelNumber: 27,
      cannedImage: "Clock",
      cannedMessage:
        "I'm sorry, there was a vote that you missed because you were busy! You should continue to see future votes, as long as you are not busy at the time. I will continue to let you know if you miss any  more votes.",
      msgWidth: 200,
      msgHeight: 235
    },
    {
      messageType: "CannedVoteMissed",
      panelNumber: 28,
      cannedImage: "Clock",
      cannedMessage:
        "I'm sorry, there was a vote that you missed because you were busy! You should continue to see future votes, as long as you are not busy at the time. I will continue to let you know if you miss any more votes.",
      msgWidth: 200,
      msgHeight: 235
    },
    {
      messageType: "CannedNotLicensed",
      panelNumber: 29,
      cannedImage: "Handshake",
      cannedMessage:
        `I'm sorry, I do not show that you have been licensed. Please contact   ${ 
        _supportEmail 
        } with your name and order number.`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoRunLimit",
      panelNumber: 30,
      cannedImage: "Clock",
      cannedMessage:
        `I'm sorry, but you are not properly licensed. This could just be a mixup on our end.  Please email   ${ 
        _supportEmail 
        } with your name and order number.`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedLicenseExpired",
      panelNumber: 30,
      cannedImage: "Upset",
      cannedMessage:
        "Sorry, I show that your license has expired! This could just be a mixup on our end.  Please email support@simulationpl.com with your name and order number.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCannotMoveClassroom",
      panelNumber: 31,
      cannedImage: "Upset",
      cannedMessage:
        ` We could not move you into this classroom. Please contact   support at   ${ 
        _supportEmail 
        }. `,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRunBlewUp",
      panelNumber: 32,
      cannedImage: "Hand-Up",
      cannedMessage:
        `STOP!  Your most recent run execution blew up!   This happens extremely rarely and we just want to make sure that everything is in order before you continue. You MUST contact   ${ 
        _supportEmail 
        } so we can fix any potential problems with your project. These fixes typically do not take very much time; we should have you back up and running quickly.`,
      msgWidth: 230,
      msgHeight: 255
    },
    {
      messageType: "CannedNoSuchPage(ByVal info As String)",
      panelNumber: 33,
      cannedImage: "Upset",
      cannedMessage:
        `Programmer message:   info   is a nonexistant page. Please contact   ${ 
        _supportEmail 
        }  and send us a screen shot of this message!  Thanks!`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedStudentStatusHasChanged",
      panelNumber: 34,
      cannedImage: "Aha",
      cannedMessage: "The student's status has been changed as requested!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedEmailSent",
      panelNumber: 35,
      cannedImage: "Thumb-Up",
      cannedMessage:
        "An email has been sent to the address that we have on file. Thank you for contacting us!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNotGoodPasswordAnswer",
      panelNumber: 36,
      cannedImage: "Thinking",
      cannedMessage:
        "The Password Answer that you supplied is not what we have on file. Refer back to the email that you were sent when you created your permanent password.  The Password and Password Answer are both case sensitive.",
      msgWidth: 200,
      msgHeight: 205
    },
    {
      messageType: "CannedShowResult(ByVal myResult As String)",
      panelNumber: 37,
      cannedImage: "Thinking",
      cannedMessage: "myResult",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedMakeSelection",
      panelNumber: 38,
      cannedImage: "Scolding",
      cannedMessage: "You must make a selection!!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedMustSelectUserStory",
      panelNumber: 39,
      cannedImage: "Scolding",
      cannedMessage: "You must first select a User Story.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedShowScheduleConflict(ByVal myConflict As String)",
      panelNumber: 40,
      cannedImage: "Scolding",
      cannedMessage:
        "I noticed you have a conflict on your schedule.    myConflict Please fix the conflict and try your Save again, or Cancel the operation.",
      msgWidth: 400,
      msgHeight: 250
    },
    {
      messageType: "CannedWorkerOnVacation(ByVal myWorkername As String)",
      panelNumber: 41,
      cannedImage: "Holding-Breath",
      cannedMessage:
        "myWorkername   is on vacation, so you cannot perform any staffing actions on this worker until they return.  The worker will automatically return to the project when their vacation is over.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDeveloperModifyWouldOccur",
      panelNumber: 42,
      cannedImage: "Yawn",
      cannedMessage: "Developer message:  Modify would occur here",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedEnterDescription",
      panelNumber: 43,
      cannedImage: "Yawn",
      cannedMessage: "Please enter a Description",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedSelectTargetView",
      panelNumber: 44,
      cannedImage: "Yawn",
      cannedMessage: "Please select a Target View",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedSelectPriority",
      panelNumber: 45,
      cannedImage: "Scolding",
      cannedMessage: "Please select a Priority",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedSoftwareUpdate",
      panelNumber: 46,
      cannedImage: "Yawn",
      cannedMessage:
        "THE SOFTWARE IS BEING UPDATED.  PLEASE WAIT 30 SECONDS AND TRY AGAIN",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedTimeOutBudgetService",
      panelNumber: 47,
      cannedImage: "Yawn",
      cannedMessage:
        "There was a timeout in the get Budget Service.  Please check your internet connection and login again.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedYouHaveTimedOut",
      panelNumber: 48,
      cannedImage: "Yawn",
      cannedMessage:
        "Your session has been idle too long.  You will now be automatically logged out. ",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDescribeZoom",
      panelNumber: 49,
      cannedImage: "Thumb-Up",
      cannedMessage:
        "Use your browser's Zoom controls to adjust your Zoom.  Or, simply click  your browser's vertical scrollbar, then on your keyboard press  Ctrl- simultaneously to reduce Zoom and avoid clipping!  Pressing Ctrl+ increases Zoom.",
      msgWidth: 210,
      msgHeight: 235
    },
    {
      messageType: "cannedDiffsAnalyzed",
      panelNumber: 50,
      cannedImage: "Thinking",
      cannedMessage: "Differences have been analyzed!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDataCollected",
      panelNumber: 51,
      cannedImage: "Yawn",
      cannedMessage: "Data Collected!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNotAnInstructor",
      panelNumber: 52,
      cannedImage: "BusinessWoman",
      cannedMessage:
        `You are not currently listed as an instructor on any classes. Please contact   ${ 
        _supportEmail 
        } and have them attach your account to a classroom. I am terminating this session.`,
      msgWidth: 200,
      msgHeight: 225
    },
    {
      messageType: "CannedSelectUserStoryFromSprint",
      panelNumber: 53,
      cannedImage: "Scolding",
      cannedMessage:
        "You must first select a User Story from the Sprint!  You will see the selected Story appear in the header of the Resource Window",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRememberNewUserStories",
      panelNumber: 54,
      cannedImage: "Yawn",
      cannedMessage:
        "Remember!  Brand new User Stories were added!  Check your backlog.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedAllRestartsUsed",
      panelNumber: 55,
      cannedImage: "Sad",
      cannedMessage:
        "Sorry, you have used all of the Restarts that you are allowed.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRestartsNotAllowed",
      panelNumber: 56,
      cannedImage: "Sad",
      cannedMessage:
        "Sorry, you have not been given the Privilege of doing Restarts.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRestartRunIdError",
      panelNumber: 57,
      cannedImage: "Sad",
      cannedMessage:
        "Sorry, there is an error in the Run Number that you are attempting to restart.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRestartError(ByVal myReturnValue As String)",
      panelNumber: 58,
      cannedImage: "Nervous",
      cannedMessage:
        `The Restart Process is not allowing you to restart...report error number   myReturnValue   to   ${ 
        _supportEmail 
        } .`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNewPasswordSent",
      panelNumber: 59,
      cannedImage: "TwoThumb-Up",
      cannedMessage:
        "A new password has been sent, it should arrive within 30 seconds.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType:
        "CannedDeveloperTooManyRowsFromRunWeek(ByVal myCount As String)",
      panelNumber: 60,
      cannedImage: "Yawn",
      cannedMessage:
        "Developer Message:  Too many rows returned from runWeek Service, expecting 1, got   myCount",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRunIdZero",
      panelNumber: 61,
      cannedImage: "Nervous",
      cannedMessage:
        `Hmm.  I show that your RunId is zero.  I can't get you run that way.  Please logout and log back in to reset your project.  If you were doing something unusual, please email   ${ 
        _supportEmail 
        }.  They sure would like to know how people can get into the run week with a zero in the runid!  Thanks`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCheckInbox",
      panelNumber: 620,
      cannedImage: "Aha",
      cannedMessage: "You had better check your inbox!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCheckInbox",
      panelNumber: 621,
      cannedImage: "Good-Job",
      cannedMessage: "You have mail!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCheckInbox",
      panelNumber: 622,
      cannedImage: "Happy-Dance",
      cannedMessage: "Someone has sent you a message.  Check your inbox!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCheckInbox",
      panelNumber: 623,
      cannedImage: "TwoThumb-Up",
      cannedMessage:
        "Your Communication App is blinking!  Please check your inbox.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCheckInbox",
      panelNumber: 624,
      cannedImage: "Aha",
      cannedMessage: "You had better check your inbox!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedYouHaveUnreadEmail(ByVal myNumEmails As String)",
      panelNumber: 63,
      cannedImage: "Yawn",
      cannedMessage: "You have {myNumEmails} unread item(s) in your inbox.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedMustSelectUserStoryFromBacklog",
      panelNumber: 64,
      cannedImage: "Scolding",
      cannedMessage:
        "You must choose a User Story out of your Product Backlog!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDeveloperUnknownMessagePage(ByVal myType As String)",
      panelNumber: 65,
      cannedImage: "Scolding",
      cannedMessage: "Developer Message:  Unknown Message Page Type:   myType",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoChartsRTF",
      panelNumber: 66,
      cannedImage: "Sad",
      cannedMessage:
        "We do not save charts to RTF.  We only save History Reports to RTF.   We have been having a problem with PDFs on some History Reports and provide RTF as a workaround.  We are working hard on the problem. ",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDeveloperBadTaskSelection",
      panelNumber: 67,
      cannedImage: "Yawn",
      cannedMessage:
        `Error in task selection, please notify   ${  _supportEmail}`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoResourcesWithSkill",
      panelNumber: 68,
      cannedImage: "Sad",
      cannedMessage:
        "There are no additional resources with that skill available",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedDeveloperResourcePlanEventError(ByVal myChangeEvent)",
      panelNumber: 69,
      cannedImage: "Yawn",
      cannedMessage:
        "Programmer message:  resource plan change event fired but not handled.  change event:    mychangeEvent",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCannotRemoveDavidLewis",
      panelNumber: 70,
      cannedImage: "Aha",
      cannedMessage:
        "You cannot remove David Lewis from the Resource plan.  He is the only resource that is available to do work when the project starts.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNotProperlyLicensed",
      panelNumber: 71,
      cannedImage: "Yawn",
      cannedMessage:
        "Error, no limit specified for number of runs!  You are not properly licensed.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoMaxRuns",
      panelNumber: 72,
      cannedImage: "Thinking",
      cannedMessage:
        "Sorry, you have no LicensePrivilege for MaxRuns, notify license@simulationpl.com.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedServerSlow",
      panelNumber: 73,
      cannedImage: "Nervous",
      cannedMessage:
        `The server is very slow.  We were unable to validate your license properly.  Please logout, wait a few seconds adn login again.  If this happens repeatedly, please email   ${ 
        _supportEmail 
        } as we are experiencing an unexpected server slowdown.`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedSelectRunToDelete",
      panelNumber: 74,
      cannedImage: "Think",
      cannedMessage: "Please Select a Run to Delete.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedBudgetAccepted",
      panelNumber: 75,
      cannedImage: "Happy-Dance",
      cannedMessage:
        "Congratulations!  Your planned budget has been accepted, and locked.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedLeftBudgetItemZero(ByVal myItem As String)",
      panelNumber: 76,
      cannedImage: "Unimpressed",
      cannedMessage:
        "You left the budget category:   myItem   at zero! You need to fix your budget!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedBudgetItemNegative(ByVal myItem As String)",
      panelNumber: 77,
      cannedImage: "Hand-Up",
      cannedMessage:
        "Budget category:   myItem   is negative!  You need to fix your budget!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedBudgetApprovalStyles",
      panelNumber: 78,
      cannedImage: "Upset",
      cannedMessage:
        "I thought Louis Styles was perfectly clear.  He told you not to put more money in your Planned Budget than you were approved for!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedCrazyBudget",
      panelNumber: 79,
      cannedImage: "Screaming",
      cannedMessage:
        "What kind of crazy budget are you trying to approve!  Your planned cost is negative!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedApprovalCancelled",
      panelNumber: 80,
      cannedImage: "Pouting",
      cannedMessage:
        "Your request for approval has been cancelled. Please try again after correcting your budget.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNiceToShowResume",
      panelNumber: 81,
      cannedImage: "Yawn",
      cannedMessage:
        "This is a developer message:  Would it be nice to see the resume here?",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedGenericWithData(ByVal mystring As String)",
      panelNumber: 82,
      cannedImage: "Yawn",
      cannedMessage: "This is a developer message:    mystring",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedSelectTopicFirst",
      panelNumber: 83,
      cannedImage: "Yawn",
      cannedMessage: "Please select a topic before clicking this button.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedClassroomChanged",
      panelNumber: 84,
      cannedImage: "Aha",
      cannedMessage: "Your Classroom has been changed!",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType:
        "CannedDeveloperBadTaskSelectionStakeholderEventError(ByVal myEvent As String)",
      panelNumber: 85,
      cannedImage: "Yawn",
      cannedMessage:
        "This is a developer message:  Stakeholder plan change event fired but not handled.  change event:    myEvent",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedRiskError(ByVal myMsg As String)",
      panelNumber: 86,
      cannedImage: "Yawn",
      cannedMessage: "There is an error updating Risk:   myMsg",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedVersionList(ByVal myList As String)",
      panelNumber: 87,
      cannedImage: "Good-Job",
      cannedMessage: "Version List:   myList",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedNoSuchUser",
      panelNumber: 88,
      cannedImage: "Sad",
      cannedMessage:
        `Sorry, I did not find your user name!  Please contact   ${ 
        _supportEmail 
        } with your UserName.`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "CannedShowBumpInfo",
      panelNumber: 89,
      cannedImage: "Aha",
      cannedMessage:
        `As an Instructor, you have the special 'Bump' feature. This allows you to remove the top execution from the grid, below. This is handy when you have old runs that you no longer want and would like to replace them with new runs.  You can bump all of your runs, if you like! If you make a mistake and want one of your runs back, please contact   ${ 
        _supportEmail 
        }  and we will restore it for you.`,
      msgWidth: 300,
      msgHeight: 255
    },
    {
      messageType: "CannedCompletedGetbudgetListView",
      panelNumber: 90,
      cannedImage: "Thinking",
      cannedMessage:
        "I had trouble getting your latest budget information from the Server. The budget you see may not show all of your recent updates.  I'll try refreshing your budget info again in a few moments. If you want to be pro-active, you can go back to My Executions on the menu and resume the project, which refreshes everything!",
      msgWidth: 240,
      msgHeight: 215
    },
    {
      messageType: "Canned91(ByRef Text As String)",
      panelNumber: 91,
      cannedImage: "Thinking",
      cannedMessage:
        `I have tried to access the cloud several times to get data for you, but I am unable to do so.   There could be several reasons for this:  (1) You have a slow or intermittant internet connection.    (2) Your computer is CPU-Bound.   (3) Our server is extremely busy.   I will let you  know when it is safe to proceed...In the meantime... >> Check that your internet connection is solid.  (Use www.speedtest.net or a similar application).  >> Run the Task Manager on your computer and make sure your personal CPU is not super-busy. If it is, close the program that is using too much CPU time. >> Contact   ${ 
        _supportEmail 
        }  with the date and time of the issue if you are sure that your internet connection is good.  I will keep trying for a few more seconds.   I'll let you know if I give up.`,
      msgWidth: 550,
      msgHeight: 400
    },
    {
      messageType: "SleepFor",
      panelNumber: 92,
      cannedImage: "Thinking",
      cannedMessage:
        "There is data ahead of you on the cloud.  You may experience some brief pauses.",
      msgWidth: 250,
      msgHeight: 95
    },
    {
      messageType: "FixRegionDate",
      panelNumber: 93,
      cannedImage: "Thinking",
      cannedMessage:
        "Your personal computer has the date/time set in a way that I do not understand, so I am unable to schedule appointments on the calendar! Please use English(United States) dates.  This is typically set by specifying the Region and Language in the Control Panel. ",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "NoRunInfoInGanttChart",
      panelNumber: 94,
      cannedImage: "Thinking",
      cannedMessage:
        `I am having difficulty displaying the Gantt Chart that you requested. Try these solutions:   Logout and log back in and try again.    Logout, then close the browser tab.  Open a new tab, reload SimProject, login and try again.    Logout, then close all browser tabs.  Restart the browser, reload Simproject, login and try again.  If none of these solutions works for you.  Please email   ${ 
        _supportEmail 
        }.`,
      msgWidth: 650,
      msgHeight: 285
    },
    {
      messageType: "CallbackInitializeRunError",
      panelNumber: 95,
      cannedImage: "Thinking",
      cannedMessage:
        `I am having difficulty creating the new Execution that you requested. Try these solution:   Logout and log back in and try to start a new run again.    Logout, then close the browser tab.  Open a new tab, reload SimProject, login and try again.    Logout, then close all browser tabs.  Restart the browser, reload Simproject, login and try again.  If none of these solutions works for you.  Please email   ${ 
        _supportEmail 
        }.`,
      msgWidth: 650,
      msgHeight: 285
    },
    {
      messageType: "BadDragDrop",
      panelNumber: 96,
      cannedImage: "Sad",
      cannedMessage:
        "I had trouble completing your drag and drop! Try this:  Select My Exections from the menu and reload the project, then come back to this page.  Then try your operation, again.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "ErrorPrinting",
      panelNumber: 97,
      cannedImage: "Unimpressed",
      cannedMessage:
        `I had trouble printing your document.  This is usually easy to fix! Try this:  Select My Executions from the SimProject menu and resume the project.  Then come back to this page and try your operation, again. If that does not work, try closing your browser completely, then re-start the browser before trying your operation again. If you still cannot print, contact   ${ 
        _supportEmail 
        } with details on what you are trying to print.`,
      msgWidth: 220,
      msgHeight: 350
    },
    {
      messageType: "ConnectionRestored",
      panelNumber: 98,
      cannedImage: "Good-Job",
      cannedMessage:
        "Your Connection has been restored! To verify that your project has been updated correctly,    Select My Executions from the menu, above.  Then resume the run.",
      msgWidth: 300,
      msgHeight: 200
    },
    {
      messageType: "RunWeekTimeOut",
      panelNumber: 99,
      cannedImage: "Sad",
      cannedMessage:
        `I was not able to complete the Run Week.  Probably a connection problem.   Everthing is going to be just fine.  I'll walk you through this, it takes about 10 seconds. To be safe, I have logged you out.   Log back in, once you get a strong internet connection.   Then, resume your execution...   (If anything goes wrong, email   ${ 
        _supportEmail 
        } and tell us your login name)`,
      msgWidth: 400,
      msgHeight: 185
    },
    {
      messageType: "RunWeekResume",
      panelNumber: 100,
      cannedImage: "Good-Job",
      cannedMessage:
        "Your Run Week Timed out.   Simply click the Red Run Week button!     It will remember where you left off.    Don't be alarmed by duplicate History Report entries.   Those will disappear the next time you log in.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "RunWeekResumeSelectProject",
      panelNumber: 101,
      cannedImage: "Good-Job",
      cannedMessage: "STEP 2:  Resume your project!  ",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "FaultLimitExceeded",
      panelNumber: 102,
      cannedImage: "Sad",
      cannedMessage:
        "I am experiencing a system fault.  Please email suppor@simulationpl.com.   Provide your UserName and Approximate time of fault. These issues can sometimes resolve themselves, you may proceed with your project unless you get this message repeatedly",
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "TimeoutTooLong",
      panelNumber: 103,
      cannedImage: "Sad",
      cannedMessage:
        `I have given it the old college try, but I cannot stay connected.  I am logging you out.   Email   ${ 
        _supportEmail 
        } if you don't think the problem is your internet connection.`,
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "TestMessage",
      panelNumber: 104,
      cannedImage: "Good-Job",
      cannedMessage:
        "disconnect your internet then see what happens.    The Counter will go to   _myApp.UserInfo.TimeOutRetryGiveUp  .  You can reconnect anytime before 40 or see what happens after 40.",
      msgWidth: 200,
      msgHeight: 185
    },
    {
      messageType: "RunWeekTimeOutError",
      panelNumber: 105,
      cannedImage: "Thinking",
      cannedMessage:
        `Hmm...I thought you would be able to resume your Run Week. I'm not able to do that right now.  You need to contact   ${ 
        _supportEmail 
        } If you attempt to continue this execution, it will become corrupted and you will have to start over! Please let Tech Support review your run before you continue.`,
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "RunWeekTimeOutCannotRestart",
      panelNumber: 106,
      cannedImage: "Thinking",
      cannedMessage:
        `Hmm...I thought you would be able to resume your Run Week. I'm not able to do that.  There is something that needs to be corrected in your execution. I am going to log you out. Notify   ${ 
        _supportEmail 
        } with your UserName and tell them you cannot resume Your RunWeek!`,
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "Staffing",
      panelNumber: 107,
      cannedImage: "Confident",
      cannedMessage:
        "{workerName}  will arrive in two weeks.  Are you sure you want to add this worker to the project?",
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "Release",
      panelNumber: 108,
      cannedImage: "Thinking",
      cannedMessage:
        "You CANNOT UNDO this action!  The removal of {workerName} will be immediate if you click the YES button below! \n\n Are you sure you want to remove {workerName} from the project?  It will take 2 weeks to get this worker back on the project.",
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "Login",
      panelNumber: 109,
      cannedImage: "Thinking",
      cannedMessage:
        "There does not appear to be a security question on file for user: {username} \n\n so the password cannot be reset. \n\n Please refer to your original purchase email for your \n\n temporary password and use it to complete the registration process",
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "Login",
      panelNumber: 110,
      cannedImage: "Thinking",
      cannedMessage:
        `We have already sent you a Temporary Password that should still be valid.  \n\n The email address we used was {email}.  Go back into your inbox and find the most recent password change email from us.  If you cannot find it, you will need to send an email to ${ 
        _supportEmail 
        } and request another password reset`,
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "Login",
      panelNumber: 111,
      cannedImage: "Upset",
      cannedMessage:
        `Your account is locked.  Please contact SPL Support at ${ 
        _supportEmail 
        } for further assistance`,
      msgWidth: 300,
      msgHeight: 285
    },
    {
      messageType: "PasswordReset",
      panelNumber: 112,
      cannedImage: "Upset",
      cannedMessage:
        `Your account is locked due to too many incorrect password attempts.  Please contact Support at ${ 
        _supportEmail 
        } for further assistance`
    },
    {
      messageType: "invalidPasswordStrength",
      panelNumber: 113,
      cannedImage: "",
      cannedMessage:
        "Passwords must be at least 7 characters and contain at least one special character such as a period or a pound sign"
    },
    {
      messageType: "invalidPasswordLength",
      panelNumber: 114,
      cannedImage: "",
      cannedMessage: "Password must be at least 7 characters"
    },
    {
      messageType: "passwordsDontMatch",
      panelNumber: 115,
      cannedImage: "",
      cannedMessage: "Passwords must match"
    }
  ]
};

// const delay = (ms) =>
//   new Promise(resolve => setTimeout(resolve, ms))

export const findMessage = (msgId, msgProps) => {
  let m = [];
  m = messages.feedbackMessages.filter(t => t.panelNumber === msgId);
  const myMsg = Object.assign({}, m[0]);
  let msgStr = myMsg.cannedMessage;
  for (let i = 0; i < msgProps.length; i++) {
    const keyvaluepair = msgProps[i];
    for (const property in keyvaluepair) {
      if (Object.prototype.hasOwnProperty.call(keyvaluepair, property)) {
        const searchKey = `{${  property  }}`;

        const re = new RegExp(searchKey, "g");

        if (msgStr.includes(searchKey)) {
          msgStr = msgStr.replace(re, keyvaluepair[property]);
        }
      }
    }
  }
  myMsg.cannedMessage = msgStr;
  // var img = myMsg.cannedImage
  // var imgUrl = 'http://simproject.com/Media/Images/ProjectAssistant/' + img + '.jpg'
  // myMsg.imgUrl = imgUrl

  return myMsg;
};
