import React from "react";

export const IntroMemo = () => {
  return (
    <>
      <p>
        I would like to extend my congratulations to you on being selected the
        program manager of the new PACCISS project. In addition, it may prove
        useful if you are aware of the initiative's background.
      </p>
      <p>
        Last month, I had a meeting with the newly appointed Director of
        Homeland Security Agency (HSA). The meeting was intended to be a
        15-minute “meet and greet” but ended up lasting over two hours. During
        that time, the Director conveyed his desire to make a quick, highly
        visible effort as a way to show that he is serious about the charter of
        the new agency.
      </p>
      <p>
        Specifically, the Director asked Northrop Grumman Security Systems to
        submit a sole source proposal for the development and deployment of an
        enhanced Port Authority Cargo Container Inspection Support System
        (PACCISS).
      </p>
      <p>
        The Director said that the program would be structured in two phases.
        The First phase would be to have an Early Operational Feasibility
        Assessment (EOFA) and demonstration based at the Long Beach Cargo Port
        in 20 weeks. If that demonstration is successful, then Northrop Grumman
        Security Systems will be asked to submit a proposal for the Phase II
        contract for development and deployment of the Full Operational
        capability to all Port Authority locations.
      </p>
      <p>
        Funding for Phase I of the effort is available right away from the US
        Treasury Department.
      </p>
      <p>
        The Director suggested that Northrop Grumman Security Systems existing
        Federal Information Services Contract could be used as a vehicle for the
        sole source contract, $1,150,000 Firm Fixed Price (FFP) effort to
        develop and support an EOFA demonstration of the PACCISS.
      </p>
      <p>
        The Director has obtained the agreement with the Port Authorities and
        the Intelligence Agencies to support this effort. The Port Authority has
        requested that its current contractor, McMillan Computer Associates
        (MCA), supporting the existing Port Authority database be a directed
        subcontractor on this effort.
      </p>
      <p>
        Given the strategic market opportunities, I accepted the challenge.
        <br />
        Cordially,
        <br />
        Don Summer, GM Northrop Grumman Security Systems
      </p>
      <footer>
        <p>ID #1061</p>
      </footer>
    </>
  );
};
