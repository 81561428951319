import { createAction, handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const ALERTS_ADDED = "ALERTS_ADDED";
export const ALERT_ADDED = "ALERT_ADDED";
export const ALERT_REMOVED = "ALERT_REMOVED";
export const ALERTS_CLEARED = "ALERTS_CLEARED";
export const ALERT_INCREMENT_INDEX = "ALERT_INCREMENT_INDEX";

export const PERSIST_NOTIFICATION_ERROR = "PERSIST_NOTIFICATION_ERROR";
export const PERSIST_NOTIFICATION_SUCCESS = "PERSIST_NOTIFICATION_SUCCESS";
export const NOTIFICATIONS_RECEIVED = "NOTIFICATIONS_RECEIVED";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";
export const NOTIFICATIONS_INSERTED = "NOTIFICATIONS_INSERTED";

export const CREATE_API_ENTITY_ERROR = "CREATE_API_ENTITY_ERROR";
export const PRE_CREATE_API_ENTITY_ERROR = "PRE_CREATE_API_ENTITY_ERROR";
export const PRE_FETCH_ERROR = "PRE_FETCH_ERROR";
// ------------------------------------
// Actions
// ------------------------------------
//

export const alertsAdded = createAction(ALERTS_ADDED, payload => payload);
export const alertAdded = createAction(ALERT_ADDED, payload => payload);
export const alertRemoved = createAction(ALERT_REMOVED, payload => payload);
export const alertsCleared = createAction(ALERTS_CLEARED, payload => payload);
export const alertIndexIncremented = createAction(ALERT_INCREMENT_INDEX);

export const notificationsReceived = createAction(
  NOTIFICATIONS_RECEIVED,
  payload => payload
);

export const notificationsInserted = createAction(
  NOTIFICATIONS_INSERTED,
  payload => payload
);
export const getNotificationError = createAction(
  GET_NOTIFICATION_ERROR,
  payload => payload
);
export const persistNotificationSuccess = createAction(
  PERSIST_NOTIFICATION_SUCCESS,
  payload => payload
);
export const persistNotificationError = createAction(
  PERSIST_NOTIFICATION_ERROR,
  payload => payload
);

export const createAPIError = createAction(
  CREATE_API_ENTITY_ERROR,
  payload => payload
);

export const preCreateAPIError = createAction(
  PRE_CREATE_API_ENTITY_ERROR,
  payload => payload
);

export const preFetchError = createAction(PRE_FETCH_ERROR, payload => payload);
// ------------------------------------
// Thunk
// ------------------------------------
//
export const addAlerts = (alertList, timeout) => {
  const payload = {
    alertList,
    timeout
  };
  return dispatch => {
    dispatch(alertsAdded(payload));
  };
};
export const persistNotification = data => {
  const date = new Date();
  return (dispatch, getState) => {
    const payload = {
      notificationObj: {
        CreatedBy: data.CreatedBy,
        TimeStamp: new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toJSON(),
        NotificationDay: data.NotificationDay,
        NotificationName: data.NotificationName,
        NotificationWeek: data.NotificationWeek,
        NotificationWorker: data.NotificationWorker,
        AlertHeadline: data.AlertHeadline,
        NotificationEvent: data.NotificationEvent,
        AlertText: data.AlertText,
        RunId: data.RunId,
        AlertType: data.AlertType,
        NotificationSeen: data.NotificationSeen
      }
    };
    return fetch(
      `${getState().app.baseURL}JsonUserSvc.svc/insertUserNotification`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    )
      .then(response =>
        response.json().then(json => ({
          status: response.status,
          json
        }))
      )
      .then(
        ({ status, json }) => {
          if (status >= 400) {
            // status looks bad
            const alert = {
              id: 20,
              type: "warning",
              event: "Insert Notification Error",
              headline: "Error after notification insert",
              message: `Run Id: ${
                data.RunId
              }  insertUserNotification : response status : ${status.toString()}`
            };
            const notificationData = {
              worker: "reducer",
              week: 0,
              info: `Run Id: ${
                data.RunId
              }  insertUserNotification : response status : ${status.toString()}`
            };
            dispatch(this.addSingleAlert(alert, notificationData, 0, true));
            dispatch(persistNotificationError(alert));
            dispatch(
              createAPIError({
                type: "CREATE_API_ENTITY_ERROR",
                errorType: "warning",
                runId: data.RunId,
                message: "Entity error whilst creating"
              })
            );
          } else if (json.status === "error") {
            dispatch(
              persistNotificationError()(
                json.statusMessage
              )
            );
          } else {
            dispatch(
              persistNotificationSuccess(
                JSON.parse(json.payload)
              )
            );
          }
        },
        // Either fetching or parsing failed!
        err => {
          const alert = {
            id: 20,
            type: "danger",
            event: "Insert Notification Error",
            headline: "Fetch / Parse Error!",
            message: `Run Id: ${
              data.RunId
            }  insertUserNotification : Error() : ${err.toString()}`
          };
          const notificationData = {
            worker: "thunk",
            week: 0,
            info: `Run Id: ${
              data.RunId
            }  insertUserNotification : error : ${err.toString()}`
          };
          dispatch(this.addSingleAlert(alert, notificationData, 0, true));
          dispatch(preFetchError(alert));
          dispatch(
            preCreateAPIError({
              type: "PRE_CREATE_API_ENTITY_ERROR",
              errortype: "danger",
              runId: data.RunId,
              message: "API error before creating"
            })
          );
        }
      )
      .catch(error => {
        // need to log here
        // let alert = {};
        // let notificationData = {};
      });
  };
};

export const incrementAlertIndex = () => dispatch => {
  dispatch(alertIndexIncremented);
};

export const addSingleAlert = (
  alert,
  notificationData,
  timeout,
  shouldPersist
) => (dispatch, getState) => {
  dispatch(alertIndexIncremented);

  const payload = {
    alert,
    timeout,
    position: "top-right"
  };
  dispatch(alertAdded(payload));
  // let today = new Date();

  if (shouldPersist === true) {
    let user = "unknown";
    if (
      Object.prototype.hasOwnProperty.call(getState(), "auth") &&
      Object.prototype.hasOwnProperty.call(getState().auth, "user") &&
      Object.prototype.hasOwnProperty.call(getState().auth.user, "UserName")
    ) {
      user = getState().auth.user.UserName;
    }
    const data = {
      CreatedBy: user,
      NotificationDay: "Monday",
      NotificationName: notificationData.info,
      NotificationWeek: notificationData.week,
      NotificationWorker: notificationData.worker,
      AlertHeadline: alert.headline,
      NotificationEvent: alert.event,
      AlertText: alert.message,
      RunId: getState().project.runId,
      AlertType: alert.type,
      NotificationSeen: false
    };

    dispatch(persistNotification(data));
  }
};

export const dismissAlertById = alertId => (dispatch, getState) => {
  const alertArray = getState().notification.alerts.filter(
    a => a.id === alertId
  );
  if (alertArray.length > 0) {
    dispatch(alertRemoved(alertArray[0]));
  }
};
export const dismissAlert = alert => (dispatch, getState) => {
  dispatch(alertRemoved(alert));
};

export const clearAlerts = () => (dispatch, getState) => {
  if (
    Object.prototype.hasOwnProperty.call(getState(), "notification") &&
    Object.prototype.hasOwnProperty.call(getState().notification, "alerts") &&
    getState().notification.alerts.length > 0
  ) {
    dispatch(alertsCleared());
  }
};

export const getUserNotifications = runId => (dispatch, getState) => {
  const payload = {
    runId
  };
  return fetch(
    `${getState().app.baseURL}JsonUserSvc.svc/getUserNotifications`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
      },
      method: "post",
      body: JSON.stringify(payload)
    }
  )
    .then(response =>
      response.json().then(json => ({
        status: response.status,
        json
      }))
    )
    .then(
      ({ status, json }) => {
        if (status >= 400) {
          // status looks bad
          const alert = {
            id: 20,
            type: "warning",
            event: "Get Notification Error",
            headline: "Error retrieving notifications",
            message: `Run Id: ${runId}  getUs : response status : ${status.toString()}`
          };
          const notificationData = {
            worker: "thunk",
            week: 0,
            info: `Run Id: ${runId}  getUserNotifications : response status : ${status.toString()}`
          };
          dispatch(this.addSingleAlert(alert, notificationData, 0, true));
          dispatch(persistNotificationError(alert));
          dispatch(
            createAPIError({
              type: "CREATE_API_ENTITY_ERROR",
              errorType: "warning",
              runId,
              message: "Entity error while creating api"
            })
          );
        } else if (json.status === "error") {
          dispatch(
            getNotificationError(
              json.statusMessage
            )
          );
        } else {
          dispatch(
            notificationsReceived(
              JSON.parse(json.payload)
            )
          );
        }
      },
      // Either fetching or parsing failed!
      err => {
        const alert = {
          id: 20,
          type: "danger",
          event: "Insert Notification Error",
          headline: "Fetch / Parse Error!",
          message: `Run Id: ${runId}  getUserNotification : Error() : ${err.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `Run Id: ${runId}  getNotification : error : ${err.toString()}`
        };
        dispatch(this.addSingleAlert(alert, notificationData, 0, true));
        dispatch(preFetchError(alert));
        dispatch(
          preCreateAPIError({
            type: "PRE_CREATE_API_ENTITY_ERROR",
            errortype: "danger",
            runId,
            message: "Entity error before creating"
          })
        );
      }
    )
    .catch(error => {
      // need to log here
      // let alert = {};
      // let notificationData = {};
    });
};

const initialState = {
  alerts: [],
  timeout: 0,
  position: "bottom-right",
  alertIndex: 0,
  notifications: []
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [ALERTS_ADDED]: (state, { payload }) => ({
      ...state,
      alerts: ([...state.alerts], [...payload.alertList]),
      timeout: payload.timeout
    }),

    [ALERT_ADDED]: (state, { payload }) => ({
      ...state,
      alerts: [...state.alerts, payload.alert],
      timeout: payload.timeout,
      position: payload.position
    }),
    [ALERT_REMOVED]: (state, { payload }) => {
      // find the index of the alert that was dismissed

      // let removeAlert = payload;
      // make a copy
      const currentAlerts = state.alerts.slice(0); //

      // find the index of the alert that was dismissed
      const idx = currentAlerts.indexOf(payload);
      let newAlerts = currentAlerts;
      if (idx >= 0) {
        newAlerts = [
          ...currentAlerts.slice(0, idx),
          ...currentAlerts.slice(idx + 1)
        ];
      }
      return {
        ...state,
        alerts: newAlerts
      };
    },
    [ALERTS_CLEARED]: state => {
      const cleared = [];
      return {
        ...state,
        alerts: cleared
      };
    },

    [ALERT_INCREMENT_INDEX]: state => {
      const newIndex = state.alertIndex + 1;
      return {
        ...state,
        alertIndex: newIndex
      };
    },
    [NOTIFICATIONS_RECEIVED]: (state, { payload }) => ({
      ...state,
      notifications: payload
    }),
    [NOTIFICATIONS_INSERTED]: (state, { payload }) => {
      const newNotification = payload;
      const newNotifications = [...state.notifications, newNotification];

      return {
        ...state,
        notifications: newNotifications
      };
      // notifications: newNotifications
    },
    [GET_NOTIFICATION_ERROR]: (state, { payload }) => ({
      ...state
    }),
    [CREATE_API_ENTITY_ERROR]: (state, { payload }) => ({
      ...state
    }),
    [PRE_CREATE_API_ENTITY_ERROR]: (state, { payload }) => ({
      ...state
    }),
    [PRE_FETCH_ERROR]: (state, { payload }) => ({
      ...state
    }),
    default: state => state
  },
  initialState
);
