import React from "react";

import imageURLs from "./images/imageURLs.js";


const OverviewHelp = () => (
  <div>
    <h3>Project Management is challenging.</h3>
    <p>
      The project manager is responsible for delivering the required scope with the required quality within the cost and schedule established at the beginning of the project. Making it even harder, the project manager must accomplish all of this with the resources available to them.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

      src={imageURLs.help1}
      alt="image1"
    />

    <p>
    Now consider that if you focus on delivering high quality you will need to resist pressing on schedule as that will reduce quality. A schedule focus will cause resources to work faster and as we all know, “If you want it bad, you get it bad”. You can add more resources to help with schedule, but that will add costs and reduce your quality. Project management is challenging!
    </p>
    <p>
    To be a successful project manager you must have good technical and people skills. You must understand the tools and processes, and you must build and maintain a high performing team and good relationships with your stakeholders.
    </p>
    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

      src={imageURLs.help2}
      alt="image2"
    />
    <p>
      You must put together a good plan and then manage the project to that plan. At the same time, you will have to adapt the plan to what is happening outside of and/or within your project.

    </p>
    <p>
      A good project manager understands and focuses on the weekly and day to day details of the project and the team.
    </p>
      <img
        style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

        src={imageURLs.help3}
        alt="image3"
      />
      <p>
      He or she also needs to periodically look at the bigger picture. What is needed a month or two months from now? Does the end of the project still look the same? Are we headed in the right direction? Do we have everything we need to successfully meet the project objectives?

      </p>
      <img
        style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

        src={imageURLs.help4}
        alt="image4"
      />


    <h3>The Simulation Project is challenging.
    </h3>
    <p>
      It will take time to read, plan and execute the simulation project. Plan on:
    </p>
    <ul>
      <li>SimProject - 3 to 4 hours for your first complete execution (completing the project). Subsequent executions will take about 2 hours or less.</li>
      <li>Fundamental - 8 to 10 hours for your first complete execution (completing the project). Subsequent executions will take about 6 hours or less.</li>
      <li>Advanced - 9 to 11 hours for your first complete execution (completing the project). Subsequent executions will take about 7 hours or less.</li>
    </ul>
    <p>Remember, you get three tries (runs or executions).</p>

    <p>
      Understand the project objectives. You must deliver the required scope with the required quality within the required cost and schedule with the available resources.

    </p>
    <p>
    Understand the company policies. There are company policies covering staffing, meetings, stakeholders, training, conferences, recognition, reserves, quality, overtime and others. You must manage within these options and constraints.
    </p>
    <p>
    Understand the people. There are a limited number of people with the right skills to complete the tasks you must complete. Each person is unique and will behave differently based on the decisions you make regarding them.
    </p>
    <p>
      Only 1 out of 20 first time users meets all the project objectives (scope, quality, schedule and cost). The simulation project is challenging!
    </p>
    <h3>The Simulation Project is more challenging than a real-life project.</h3>
    <p>
      The simulation project is designed to be both realistic and to help develop your project management skills; both your technical and people skills.

    </p>
    <p>
      To do this you will find there are more challenges and more challenging people in the simulation project than you might find on any one typical project.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

      src={imageURLs.help5}
      alt="image5"
    />


    <p>
      You will also find that you are limited to weekly vs. daily decisions regarding resources and task assignments. Since most of the tasks are at least two weeks long and none are shorter than a week, it is realistic that you would monitor and control on a weekly basis.

    </p>
  </div>
);

export default OverviewHelp;
