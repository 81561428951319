import React from "react";
import StyledCardBody from "components/styled/StyledCardBody";


const ProjectInfo = () => {
  return (
  <StyledCardBody>
        <p>
          Used to start new and review completed projects. With your license you
          can run your project from beginning to end three times. You can only
          run one project at a time. A previous project (run) must be completed
          or abandoned before the next project can be started. Your instructor
          may or may not allow abandoning projects. An abandoned project allows
          you to continue on to your next project without finishing your current
          one. Contact your instructor if you want to abandon a project so you
          can start over again on your next project.
        </p>
        <p>
          Your instructor can control whether you can plan and execute each
          project. As you move through a project, you may get messages that an
          action you are attempting has temporarily been blocked or stopped.
          These blocks and stops are under the control of your instructor.
        </p>
      </StyledCardBody>

  );
};

export default ProjectInfo;
