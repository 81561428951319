import React from "react";

export const TeamTraining = props => {
  return (
    <div className="content">
      <h3>Team Training</h3>
      <p>
        The team attended the {props.properties.training} all day today.{" "}
      </p>
    </div>
  );
};
