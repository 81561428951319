import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Card, ButtonToolbar, Button, UncontrolledTooltip } from "reactstrap";
import accounting from "accounting";
import CardHeaderBold from "components/styled/CardHeaderBold";
import ChartCardBody from "components/styled/ChartCardBody";
import InfoButton from "components/Info/InfoButton";
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

const EACChart = (props) => {
  const chartRef = useRef(null);

  const lineChartOptions = {
    // title: {
    //   text: 'Quality'
    // },
    legend: {
      position: "bottom"
    },
    layout: {
      padding: {
            right: 450 // Adjust the value as per your requirement
      }
  },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Projected Final Cost"
          },
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return accounting.formatMoney(label, options);
            }
          }
        }
      ],
      xAxes: [
        {
          distribution: "linear",
          scaleLabel: {
            display: true,
            labelString: "Project Week"
          },
          ticks: {
            stepSize: 5,
            autoSkip: false,
            maxTicksLimit: 15
          }
        }
      ]
    },
    scaleBeginAtZero: true,
    fill: false,
    strokeColor: "rgba(151,187,205,1)",
    pointColor: "#CC94B9",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(151,187,205,1)"
  };

  const pngExport = async () => {
    const base64Image = chartRef.current.chartInstance.toBase64Image();

    const name = "eac.png";

    var link = document.createElement("a");
    link.download = name;
    link.href = base64Image;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    
  };

  return (
    <Card>
      <CardHeaderBold>
        <span>Estimated Cost At Completion</span>

        <ButtonToolbar>
          <InfoButton selectedKey="eac" />
            <Button id="eacpng" color="primary"  onClick={() => pngExport()}>
            <span style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faDownload} /> 
            </span>
          </Button>
           <UncontrolledTooltip   placement="top"
            target="eacpng">
        Download chart as png file    
           </UncontrolledTooltip>
        </ButtonToolbar>
      </CardHeaderBold>
      <ChartCardBody>
        <div id="eac">
          <Line
            ref={chartRef}
            data={props.data}
            options={lineChartOptions}
            height={70}
          />
        </div>
      </ChartCardBody>
    </Card>
  );
};

export default EACChart;
