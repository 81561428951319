// src/components/NotFound/index.js
import React from 'react';

export default class NotFound extends React.Component {

  render() {
    return (
      <div>
        <h1>
          404 <small>Not Found :(</small>
        </h1>
      </div>
    );
  }
}
