import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardHeaderBold from "components/styled/CardHeaderBold";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import EmailBody from "./EmailBody";
import { markAsRead } from "redux/Project/thunks";

import {
  getInboxEmailCount,
  getSentEmailCount
} from "redux/Project/selectors";
import "routes/Modal/ModalContainer.css";

const EmailModalContainer = () => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState("Inbox");
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  const inboxList = useMemo(() => {
    if (
      content.project.communicationList &&
      content.project.communicationList.Inbox
    ) {
      return content.project.communicationList.Inbox;
    }
    return [];
  }, [content.project.communicationList]);

  const inboxUnread = useMemo(() => {
    if (
      content.project.communicationList &&
      content.project.communicationList.Inbox &&
      content.project.communicationList.Inbox.length > 0
    ) {
      if (
        content.project.communicationList.Inbox.find((s) => s.IsRead === false)
      ) {
        return true;
      }
    }
    return false;
  }, [content.project.communicationList]);

  const sentList = useMemo(() => {
    if (
      content.project.communicationList &&
      content.project.communicationList.Sent
    ) {
      return content.project.communicationList.Sent;
    }
    return [];
  }, [content.project.communicationList]);

  const sentUnread = useMemo(() => {
    if (
      content.project.communicationList &&
      content.project.communicationList.Sent &&
      content.project.communicationList.Sent.length > 0
    ) {
      if (
        content.project.communicationList.Sent.find((s) => s.IsRead === false)
      ) {
        return true;
      }
    }
    return false;
  }, [content.project.communicationList]);

  const getSelectedRow = () => {
    let selectedRow = {};
    if (
      selectedFolder === "Inbox" &&
      inboxList &&
      inboxList.length > selectedRowIndex
    ) {
      selectedRow = { ...inboxList[selectedRowIndex] };
    } else if (
      selectedFolder === "Sent" &&
      sentList.length > selectedRowIndex
    ) {
      selectedRow = { ...sentList[selectedRowIndex] };
    }
    return selectedRow;
  };
  const setToRead = async (index, row) => {
    await dispatch(markAsRead(row.Id));
    setSelectedRowIndex(index);
  };

  const inboxCount = getInboxEmailCount(content);
  const sentCount = getSentEmailCount(content);
  return (
    <Card key={inboxCount.toString()}>
      <CardHeaderBold>
      {content.auth.productId === 50 ? (
    <span>
      <p key="title">PACCISS Communications Email</p>
          <p>
              <i>
                Your email correspondence from Stakeholders and team members as
                well as your responses to your team members generated during the
                work week are stored here. Be sure to check often.
              </i>{" "}
          </p>{" "}
    </span>
  ) : content.auth.productId === 51 ? (
    <span>
      <p key="title">Northrop Grumman Communications Email</p>
          <p>
            <i>
                Your email correspondence from Stakeholders and team members as
                well as your responses to your team members generated during the
                work week are stored here. Be sure to check often.
              </i>{" "}
          </p>{" "}
    </span>
  ) : (
    <span>
      <p key="title">Uniworld Communications Email</p>
        <p>
            <i>
                Your email correspondence from Stakeholders and team members as
                well as your responses to your team members generated during the
                work week are stored here. Be sure to check often.
              </i>{" "}
          </p>{" "}
    </span>
  )}
      </CardHeaderBold>

      <CardBody className="mh-0">
        <Row>
          <Col lg="3">
            <div className="ibox float-e-margins">
              <div className="ibox-content mailbox-content">
                <div className="file-manager">
                  <h5>Folders</h5>
                  <ListGroup className="mb-2">
                    <ListGroupItem
                      style={{
                        background:
                          selectedFolder === "Inbox" ? "#ebf3fa" : "transparent"
                      }}
                    >
                      <Button
                        color="link"
                        className={"btn-plain"}
                        onClick={() => setSelectedFolder("Inbox")}
                      >
                        <i className="mr-1 fa fa-inbox" />
                        <div
                          key={`div-inbox-${inboxUnread}`}
                          style={{
                            fontSize: "14px",
                            fontWeight: inboxUnread ? "bold" : "regular"
                          }}
                        >
                          Inbox
                        </div>

                        <span className="label label-default pull-right">
                          <div
                            key={`div-count-${inboxUnread}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: inboxUnread ? "bold" : "regular"
                            }}
                          >
                            {inboxCount}
                          </div>
                        </span>
                      </Button>
                    </ListGroupItem>
                    <ListGroupItem
                      style={{
                        background:
                          selectedFolder === "Sent" ? "#ebf3fa" : "transparent"
                      }}
                    >
                      <Button
                        color="link"
                        className="btn-plain"
                        onClick={() => setSelectedFolder("Sent")}
                      >
                        <i className="mr-1 fa fa-envelope-o" />

                        <div
                          key={`div-sent-${sentUnread}`}
                          style={{
                            fontSize: "14px",
                            fontWeight: sentUnread ? "bold" : "regular"
                          }}
                        >
                          Sent
                        </div>
                        <span className="label label-default pull-right">
                          <div
                            key={`div-count-${sentUnread}`}
                            style={{
                              fontSize: "14px",
                              fontWeight: sentUnread ? "bold" : "regular"
                            }}
                          >
                            {sentCount}
                          </div>
                        </span>
                      </Button>
                    </ListGroupItem>
                  </ListGroup>

                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg="9">
            <div className="mail-box">
              <table key={selectedFolder} className="table table-hover table-mail">
                <tbody>
                  {selectedFolder === "Inbox"
                    ? inboxList.map((row, index) => (
                        <tr
                          key={`row-comm-inbox-${row.IsRead}-${index.toString()}`}
                          className={row.IsRead === false ? "unread" : "read"}
                        >
                        <td key={`contact-col-${row.IsRead}`} className="mail-contact">
                            <a
                              href="#null"
                              key="link-to"
                              onClick={() => setToRead(index, row)}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight:
                                    row.IsRead === false ? "bold" : "regular"
                                }}
                              >
                                {row.CommunicationFrom}
                              </div>
                            </a>
                          </td>
                          <td
                            key={`is-read-col-${row.IsRead}`}
                            className={
                              row.IsRead === false
                                ? "unread mail-subject"
                                : "read mail-subject"
                            }
                          >
                            <a
                              href="#null"
                              key="is-read-link"
                              onClick={() => setToRead(index, row)}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight:
                                    row.IsRead === false ? "bold" : "regular"
                                }}
                              >
                                {row.Subject}
                              </div>
                            </a>
                          </td>
                        </tr>
                      ))
                    : sentList.map((row, index) => (
                        <tr
                          key={`row-sent-comm-${row.IsRead}`}
                          className={row.IsRead === false ? "unread" : "read"}
                        >
                          <td
                            key={`sent-col-${index.toString()}`}
                            className="mail-contact"
                          >
                            <a
                              href="#null"
                              key={`sent-link-${index.toString()}`}
                              onClick={() => setToRead(index, row)}
                            >
                           <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight:
                                    row.IsRead === false ? "bold" : "regular"
                                }}
                              >
                              {row.CommunicationFrom}
                              </div>
                            </a>
                          </td>
                          <td
                            key={`sent-subject-${index.toString()}`}
                            className="mail-subject"
                          >
                            <a
                              href="#null"
                              key={`sent-subjlink-${sentUnread}`}
                              onClick={() => setToRead(index, row)}
                          >
                             <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight:
                                    row.IsRead === false ? "bold" : "regular"
                                }}
                              >
                              {row.Subject}
                              </div>
                            </a>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className="mail-detail">
              {getSelectedRow() !== null && getSelectedRow() !== {} && getSelectedRow().IsRead ? (
                <div className="mail-body">
                  <header style={{ marginBottom: "10px" }}>
                    <h5>
                      <p>
                        <span className="font-normal">Subject: </span>
                        {getSelectedRow().Subject}
                      </p>
                    </h5>
                    <h5>
                      <p>
                        <span className="font-normal">From: </span>
                        {getSelectedRow().CommunicationFrom}
                      </p>
                    </h5>
                  </header>

                  <EmailBody
                    row={getSelectedRow()}
                    productId={content.auth.productId}
                  />
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EmailModalContainer;
