/* eslint-disable no-unused-vars  */
import React from "react";
import ClassroomChooser from "./ClassroomChooser";

const ClassroomAssignment = props => (
  <div>
    {props.studentInfo.ClassroomId === 0 ? (
      <div>
        <p>
          We need to know where to assign you. Most SimProject users have an
          instructor and classroom. Once you have selected your instructor
          below, you can choose the classroom they have set up for this class.
          Typically there will be one classroom, but some instructors do have
          multiple classrooms. If you do not have an instructor, select the
          instructor at the top (Proxy Instructor), and select SimProject
          classroom for individuals.
        </p>
        <p>
          You should only need do this once, but if you need to change
          classrooms or instructors later on, you'll be able to from your
          profile settings page.
        </p>
      </div>
    ) : (
      <p>Please use this form to change your classroom if needed</p>
    )}

    <ClassroomChooser
      studentInfo={props.studentInfo}
      runDetails={props.runDetails}
      availableClasses={props.availableClasses}
      uniqueInstructors={props.uniqueInstructors}
      placeInClassroom={props.placeInClassroom}
    />
  </div>
);

export default ClassroomAssignment;
