import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import { CONFIRMATION_MODAL } from "routes/Modal/ModalTypes";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import {deleteResourcePlan } from "redux/Resource/thunks";
import {toggleLoading} from "app/redux/actions";
import { task2Complete } from "redux/Project/selectors";
import _debounce from "lodash.debounce";


const DeleteAction = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method

  const { row } = props;
  const { rowIdx } = props;

var okToDisplay = true;
  if (row.ResourceName === "David Lewis" &&
    content.auth.productId === 38 &&
    task2Complete(content) === false) {
    okToDisplay = false;
  }

  if (
    content.auth.productId === 50 &&
    content.project.currentProjectWeek < 3 &&
    row.WorkerStatus === "wsOnProject" &&
    content.project.budgetIsLocked) {
    okToDisplay = false;
  }

  const deleteResource = _debounce(async () => {
    dispatch(toggleLoading(true))
    closeMe();
    await dispatch(
      deleteResourcePlan(rowIdx, row.Id, content.project.runId, row.ResourceId)
    ).then(() => {
      dispatch(toggleLoading(false));
      
    } );

  }, 75);

   const closeMe = async () => {
    await dispatch(closeModal());
   }
  
  const openConfirmModal = (row, rowIdx) => {
    var deleteMessage = `Are you sure you want to remove ${row.ResourceName}?`;

    if (content.project.budgetIsLocked === true && row.WorkerStatusCode !== "wsAvailable") {
        deleteMessage=`Deleting ${row.ResourceName} will also take them off the project immediately. Note it will take 2 weeks of onboarding to get ${row.ResourceName.split(" ")[0]} back on the project.`
    }
    dispatch(
      openModal(
        CONFIRMATION_MODAL,
        {
          title: "Confirm Delete Resource",
          message: deleteMessage,
          cancelLabel: "Cancel",
          confirmLabel: "Confirm Release",
          handleCancel: closeMe,
          handleConfirm: deleteResource
        },
        "modal-medium"
      )
    );
  };
//default message for simple simproject
  let tooltipText = `This action will delete ${row.ResourceName} from plan and project immediately.  Note: workers who were on project at the beginning cannot be removed until Task 2 is complete.`
// customized for pacciss prod 50
if (
  content.auth.productId === 50 ) {
   tooltipText = `This action will delete ${row.ResourceName} from plan and project immediately.  Note: workers who were on project at the beginning cannot be removed until week 3`;
  }

  return (
    <>
      <Button
        color="link"
        disabled={!okToDisplay}
        id={`tooltip-actions-${rowIdx}`}
        onClick={() =>openConfirmModal(row,rowIdx)
          
        }
      >

          <FontAwesomeIcon icon={faUserMinus}  />


      </Button>
      <UncontrolledTooltip placement="top" target={`tooltip-actions-${rowIdx}`}>
        {tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default DeleteAction;
