import React from "react";

const RecognitionOptionsCosts = props => {
	return (
		<div className="box-content">
			<div className="text">
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Recognition</th>
							<th>Cost</th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-RowHeading">
							<td>Team Recognition</td>
							<td>(cost to project per team member)</td>
						</tr>
						<tr>
							<td>Weekend Getaway</td>
							<td>$1000</td>
						</tr>
						<tr>
							<td>Entertainment</td>
							<td>$150</td>
						</tr>
						<tr>
							<td>Family Picnic</td>
							<td>$100</td>
						</tr>
						<tr>
							<td>Party</td>
							<td>$35</td>
						</tr>
						<tr>
							<td>Dinner</td>
							<td>$35</td>
						</tr>
						<tr>
							<td>Company Logo Trinkets</td>
							<td>$25</td>
						</tr>
						<tr>
							<td>Pizza Lunch</td>
							<td>$10</td>
						</tr>
						<tr className="table-RowHeading">
							<td>Individual Recognition</td>
							<td>(cost to project)</td>
						</tr>
						<tr>
							<td>$1000 cash bonus</td>
							<td>$1000</td>
						</tr>
						<tr>
							<td>$500 cash bonus</td>
							<td>$500</td>
						</tr>
						<tr>
							<td>VIP parking spot</td>
							<td>$300</td>
						</tr>
						<tr>
							<td>$100 cash bonus</td>
							<td>$100</td>
						</tr>
						<tr>
							<td>Dinner for 2</td>
							<td>$150</td>
						</tr>
						<tr>
							<td>Entertainment</td>
							<td>$150</td>
						</tr>
						<tr>
							<td>Company Logo Trinkets</td>
							<td>$25</td>
						</tr>
					</tbody>
				</table>
			</div>
		
		</div>
	);
};

export default RecognitionOptionsCosts;
