import React from "react";
import * as images from "static/images/people/index";


const ResourceImage = props => {

  const backupImage = "static/images/people/ActiveWorker.png";
	const { png, alt = "Resource Avatar" } = props;
  if (png && png !== null && png !== "") {
    try {
      const imgUrl = require(`static/images/people/${png}`).default;
      return <img src={imgUrl}  alt={alt} />; 
    } catch  {
      return <img src={backupImage} alt={png} />
    }
	}
  return <img src={backupImage} alt={"noImage"} />
};

export default ResourceImage;
