import React from 'react';


export const DailyWork = (props) => {
	return (
		<div className="content">
			<h3>
				{props.properties.worker}
				<small>{props.properties.title}</small>
			</h3>
			<p>worked on Task: {props.properties.task}</p>
		</div>
	);
};
