/* eslint-disable no-unused-vars*/
/* eslint-disable indent*/
/* eslint-disable no-loop-func */
import { createSelector } from "reselect";
import React from "react";

const getAvailableClasses = (state) => state.auth.availableClasses;
const getCheckListItems = (state) => state.auth.checkListItems;
const getRunDetails = (state) => state.auth.runDetails;
const getProductId = (state) => state.auth.productId;

// get instructors for filtered dropdowns
export const getUniqueInstructors = createSelector(
  [getAvailableClasses, getProductId],
  (availableClasses, productId) => {
    let instructorList = [];
    let key = 1;
    let instructorId = 0;
    let instructorName = "";
    let instructorProductId = 38;
    if (availableClasses) {
      for (var i = 0, len = availableClasses.length; i < len; i++) {
        var cls = availableClasses[i];
        instructorId = cls.InstructorUserId;
        instructorName = cls.InstructorFullName;
        instructorProductId = cls.InstructorProductId;
        if (cls.InstructorProductId === productId) {
          instructorList.push({
            label: instructorName,
            value: instructorId,
            productId: instructorProductId
          });
        }

        // is the instructor list empty and/or is this instructor not already in it
        // if (instructorList  && !instructorList.find((i) => i.value === instructorId)) {
        // 	instructorList.push({
        // 		label: instructorName,
        // 		value: instructorId,
        // 		productId: instructorProductId
        // 	});
        // }
      }
    }

    return instructorList;
  }
);

// get classrooms for filtered dropdowns
export const getClassroomList = createSelector(
  [getAvailableClasses],
  (availableClasses) => {
    let classList = [];
    let key = 1;
    if (availableClasses) {
      for (var i = 1, n = availableClasses.length; i < n; i += 1)
        classList.push({
          label: availableClasses[i].ClassName,
          value: availableClasses[i].ClassId
        });
    }
    return classList;
  }
);

export const classroomCheckBool = createSelector(
  [getCheckListItems],
  (checkListItems) => {
    let classroomCheck = false;
    if (checkListItems) {
      for (let item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          let thisItem = checkListItems[item];
          if (item.ControlItem === "ASSIGN_CLASSROOM") {
            classroomCheck = item.ControlValue === 1 ? true : false;
            break;
          }
          //if
        }
      }
    }
    return classroomCheck;
  }
);

export const firstRunCheckBool = createSelector(
  [getCheckListItems],
  (checkListItems) => {
    let firstRunCheck = false;
    if (checkListItems) {
      for (let item of checkListItems) {
        if (item.ControlItem === "START_FIRST_PROJECT") {
          firstRunCheck = item.ControlValue === 1 ? true : false;
          break;
        }
      }
    }
    return firstRunCheck;
  }
);

export const helpCheckBool = createSelector(
  [getCheckListItems],
  (checkListItems) => {
    let helpCheck = false;
    if (checkListItems) {
      for (let item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          let thisItem = checkListItems[item];
          if (item.ControlItem === "VIEW_HELP") {
            helpCheck = item.ControlValue === 1 ? true : false;
            break;
          }
          //if
        }
      }
    }
    return helpCheck;
  }
);

export const allChecksPassed = createSelector(
  [getCheckListItems],
  (checkListItems) => {
    let checksPassed = true;
    // first loop - make sure we have all the check list items, if not may be an old Account
    //
    //

    let numItems = 0;
    let item, thisItem;
    if (checkListItems) {
      for (item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          thisItem = checkListItems[item];
          if (thisItem.ControlItem === "CHANGE_PASSWORD") {
            numItems += 1;
          } else if (thisItem.ControlItem === "ACCEPT_LICENSE") {
            numItems += 1;
          } else if (thisItem.ControlItem === "VIEW_ORIENTATION") {
            numItems += 1;
          } else if (thisItem.ControlItem === "VIEW_HELP") {
            numItems += 1;
          } else if (thisItem.ControlItem === "ASSIGN_CLASSROOM") {
            numItems += 1;
          } else if (thisItem.ControlItem === "START_FIRST_PROJECT") {
            numItems += 1;
          }

          //if
        }
      }
    }
    if (numItems !== 6) {
      //TODO need to make sure i have the right
      // check list items here - somewhat dangerous logic
      // assume this is an old login which has been in use
      return true;
    }
    if (checkListItems) {
      for (item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          thisItem = checkListItems[item];
          if (thisItem.ControlValue === 0) {
            checksPassed = false;
            break;
          }
        }
      }
    }
    return checksPassed;
  }
);

export const startProjectPassed = createSelector(
  [getCheckListItems],
  (checkListItems) => {
    let checksPassed = true;
    // first loop - make sure we have all the check list items, if not may be an old Account
    //
    //

    let numItems = 0;
    let item, thisItem;
    if (checkListItems) {
      for (item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          thisItem = checkListItems[item];
          if (thisItem.ControlItem === "CHANGE_PASSWORD") {
            numItems += 1;
          } else if (thisItem.ControlItem === "ACCEPT_LICENSE") {
            numItems += 1;
          } else if (thisItem.ControlItem === "VIEW_ORIENTATION") {
            numItems += 1;
          } else if (thisItem.ControlItem === "VIEW_HELP") {
            numItems += 1;
          } else if (thisItem.ControlItem === "ASSIGN_CLASSROOM") {
            numItems += 1;
          } else if (thisItem.ControlItem === "START_FIRST_PROJECT") {
            numItems += 1;
          }

          //if
        }
      }
    }
    if (numItems !== 6) {
      //TODO need to make sure i have the right
      // check list items here - somewhat dangerous logic
      // assume this is an old login which has been in use
      return true;
    }
    if (checkListItems) {
      for (item in checkListItems) {
        if (Object.prototype.hasOwnProperty.call(checkListItems, item)) {
          thisItem = checkListItems[item];
          if (
            thisItem.ControlValue === 0 &&
            thisItem.ControlItem !== "START_FIRST_PROJECT"
          ) {
            checksPassed = false;
            break;
          }
        }
      }
    }
    return checksPassed;
  }
);
