import { Button } from "reactstrap";
import styled from "styled-components";

const SmallButton = styled(Button)`
	&.btn {
		margin: 0 !important;
		padding: 0.1em 0.3em 0.08em;
		font-size: 0.75rem;
	}

	i.fa {
		margin: 0;
	}
`;

export { SmallButton };
