import React from "react";

export const TeamRevision = () => {
  return (
    <>
      <p>
        Initially we weren't too excited about this schedule compression
        request. But then we thought about the chance to meet the Secretary of
        Homeland Security at the Prototype Demonstration and decided to take a
        positive view of this challenge.
      </p>
      <p>
        We have carefully examined the remaining work on the PACCISS Project and
        have determined a course of action that will meet the required schedule
        of completing the Prototype Demonstration Installation & Delivery task
        no later than week 17. Our proposal is to complete all work one week
        early within the Prototype Demonstration Design Description task that
        needs to be done for subsequent work to begin on both the Prototype
        Demo. COTS HW Procurement task and the Prototype Demo. Software
        Development task.
      </p>
      <p>
        By careful work scheduling we can eliminate the successor relationship
        from Task 10 to Tasks 12 and 16. Thus allowing both Task 12 and 14 to
        start a week earlier than planned. This will allow Task 19, Prototype
        Demo. Software Development, to start and finish in week 17.
      </p>
      <p>
        We realize that this approach introduces additional risk into the
        project, but we have looked at several different options and this is the
        best in our opinion. We are committed to making this plan successful.
      </p>
      <footer>
        <p>ID #1041</p>
      </footer>
    </>
  );
};
