import React from "react";
import { Route, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import "./Breadcrumb.css";

const routes = {
  "/": "",
  "/planning": "Planning",
  "/planning/budget": "Budget",
  "/planning/resource": "Resource Plan",
  "/planning/risk": "Risk/Opportunities Plan",
  "/planning/training": "Training Plan",
  "/planning/stakeholder": "Stakeholder Plan",
  "/planning/recognition": "Recognition/Rewards PLan",
  "/planning/approval": "Plan Approval",
  "/execution": "Execution",
  "/execution/budget": "Budget",
  "/execution/assignment": "Staffing Decisions",
  "/execution/interaction": "Interaction Decisions",
  "/instructor-dashboard": "Instructor Dashboard",
  "/reports": "Reports",
  "/reports/history": "History Reports",
  "/reports/quality": "Quality",
  "/reports/eac": "EAC Report",
  "/reports/ev": "Earned Value Report",
  "/reports/epf": "Estimated Project Finish Report",
  "/reports/ppi": "PPI Report",
  "/reports/gantt": "Gantt Chart",
  "/reports/network": "Network Diagram",
  "/reports/labor": "Labor Breakdown Report",
  "/reports/reserve": "Reserve Breakdown Report",
  "/reports/budget": "Budget",
  "/reports/pdf": "Create Report PDFs"
};

const findRouteName = url => {
  return routes[url];
} 

const getPaths = pathname => {
  const paths = ["/"];

  if (pathname === "/") return paths;

  pathname.split("/").reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({ match, ...rest }) => {
  const routeName = findRouteName(match.url);
  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={match.url || ""} href={match.url || ""}>
          {routeName}
        </Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = ({ location: { pathname }, match, ...rest }) => {
  let iterKey = 1;
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => (
    <Route key={(iterKey += 1)} path={path} component={BreadcrumbsItem} />
  ));
  return <Breadcrumb>{items}</Breadcrumb>;
};

//TODO refactor
// eslint-disable-next-line import/no-anonymous-default-export
export default props => (
  <div>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
);
