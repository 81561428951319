import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import {
  Card,
  FormGroup,
  CardBody,
  Button,
  Collapse,
  Label,
  Input
} from "reactstrap";
import { insertResourcePlan } from "redux/Resource/thunks";
import { openModal } from "redux/Modal/ModalModule";
import { getAvailableResources } from "redux/Resource/selectors";
import AvailableResourceListItem from "./AvailableResourceListItem";
import "./AvailableResourceList.css";
import { ADD_RESOURCE_MODAL } from "routes/Modal/ModalTypes";
import ResourceProfileList from "./ResourceProfileList";
import CardHeaderBold from "components/styled/CardHeaderBold";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  overflow: hidden;
`;

const StyledContentDiv = styled.div`
  min-height: 200px;
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px 20px;
`;
const StyledCardBody = styled(CardBody)`
  &.card-body {
    max-height: ${(props) => props.maxHt} || 400px;
  }
`;
class AvailableResourceList extends React.Component {
  state = {
    selectedFilterSkillId: 0,
    isOpen: this.props.noHideToggle ? true : false,
    resourcesForProfile: []
  };
  // TODO: Instead of hiding, disable the contents of this card
  // componentDidMount = () => {
  //   !this.props.noHideToggle &&
  //     this.setState({
  //       isOpen: !this.props.budgetIsLocked
  //     });
  // };

  insertNewResource = (resource) => {
    // open the choose starting week modal
    this.props.showAddResourceModal(resource);
  };

  handleSkillChange = (event) => {
    this.setState({
      selectedFilterSkillId: parseInt(event.target.value, 0)
    });
  };

  handleClearFilter = (event) => {
    event.preventDefault();
    this.setState({
      selectedFilterSkillId: 0
    });
  };

  closeProfile = (res) => {
    const currProfiles = this.state.resourcesForProfile.slice();
    const index = currProfiles.indexOf(res);
    currProfiles.splice(index, 1); // remove element
    this.setState({
      resourcesForProfile: currProfiles
    });
  };

  showProfile = (resource, event) => {
    event.preventDefault();
    let workerName;
    if (Object.prototype.hasOwnProperty.call(resource, "WorkerName")) {
      workerName = resource.WorkerName;
    } else {
      workerName = resource.ResourceName;
    }

    //  see if person's profile already is showing:
    const index = this.state.resourcesForProfile.findIndex(
      (m) => m.Name === workerName
    );
    if (index > -1) {
      return;
    }

    const worker = this.props.workerFixedList.filter(
      (wrkr) => wrkr.Name === workerName
    );

    if (worker.length > 0) {
      const currProfiles = this.state.resourcesForProfile.slice();
      currProfiles.push(worker[0]);

      this.setState({
        resourcesForProfile: currProfiles
      });
    }
  };

  hideProfile = (index) => {
    this.setState((prevState) => ({
      resourcesForProfile: prevState.resourcesForProfile.filter(
        (_, i) => i !== index
      )
    }));
  };

  render() {
    let i = 1;

    return (
      <Card  tourstop="4">
        <CardHeaderBold>
          <div style={{ marginRight: "300px" }}>Available Resources</div>
          {this.state.isOpen && (
            <div className="resource-Filter  text-right">
              <FormGroup>
                <Input
                  type="select"
                  onChange={this.handleSkillChange}
                  value={this.state.selectedFilterSkillId}
                >
                  <option key="ALL" value="0">
                    All
                  </option>
                  {this.props.skills.map((row) => (
                    <option key={row.Id} value={row.SkillId}>
                      {row.SkillName}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <Button
                style={{
                  marginLeft: "10px",
                  marginTop: "3px",
                  height: "30px",
                  width: "60px"
                }}
                color="primary"
                onClick={() => this.setState({ selectedFilterSkillId: 0 })}
              >
                Clear
              </Button>
            </div>
          )}
          {!this.props.noHideToggle && (
            <Label className="switch switch-default switch-primary">
              <Input
                type="checkbox"
                className="switch-input"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                defaultChecked={this.state.isOpen}
              />
              <span className="switch-label" />
              <span className="switch-handle" />
            </Label>
          )}
        </CardHeaderBold>
        <Collapse isOpen={this.state.isOpen}>
          <StyledCardBody
            height={
              this.state.resourcesForProfile.length > 0 ? "1000px" : "400px"
            }
          >
            <StyledContentDiv>
              <Grid>
                {this.state.selectedFilterSkillId === 0 &&
                  this.props.availableWorkers.map((row, index) => (
                    <AvailableResourceListItem
                      key={(i += 1)}
                      resource={row}
                      runId={this.props.runId}
                      showProfile={this.showProfile}
                      rowIndex={index}
                      insertResourcePlan={this.insertNewResource}
                    />
                  ))}
                {this.state.selectedFilterSkillId > 0 &&
                  this.props.availableWorkers
                    .filter(
                      (w) => w.SkillId === this.state.selectedFilterSkillId
                    )
                    .map((row, index) => (
                      <AvailableResourceListItem
                        key={(i += 1)}
                        resource={row}
                        runId={this.props.runId}
                        showProfile={this.showProfile}
                        rowIndex={index}
                        insertResourcePlan={this.insertNewResource}
                      />
                    ))}
              </Grid>
            </StyledContentDiv>
          </StyledCardBody>
          {this.state.resourcesForProfile.length >> 0 ? (
            <CardBody>
              <ResourceProfileList
                profileLists={this.state.resourcesForProfile}
                hideProfile={this.hideProfile}
              />
            </CardBody>
          ) : (
            <span />
          )}
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  availableWorkers: getAvailableResources(state),
  budgetIsLocked: state.project.budgetIsLocked,
  workerFixedList: state.project.workerFixedList,
  runId: state.project.runId,
  skills: state.project.skills
});
const mapDispatchToProps = (dispatch) => ({
  insertResourcePlan: (runId, data) =>
    dispatch(insertResourcePlan(runId, data)),
  showAddResourceModal: (newResourceItem) =>
    dispatch(
      openModal(
        ADD_RESOURCE_MODAL,
        {
          newResourceItem
        },
        "modal-medium"
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableResourceList);
