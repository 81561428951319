import React from "react";
import { Card, CardBody, Label, Input, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import imageURLs from "components/Help/images/imageURLs.js";
import InfoFooter from "components/Info/InfoFooter"
import CardHeaderBold from "components/styled/CardHeaderBold"
const RiskInfo = props => (
  <Card> 
    <CardHeaderBold>
        <span>Risk/Opportunities Info</span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => props.toggle()}
            defaultChecked={props.panelVisible}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </CardHeaderBold>
    <Collapse isOpen={props.panelVisible}>
      
			<CardBody>
				<h5>Context and resources for risk/opportunity planning </h5>
				<p>
					Create a Risk/Opportunity Plan to be proactive in dealing with potential negative and positive risk events which might occur as you execute your project. Building a Risk/Opportunity plan creates a budget reserve that will be used in the execution of the project to pay for: 

				</p>
				<ol>
					<li>Unknown expenses that pop up </li>
					<li>
						 Team member salaries while assigned to tasks that are delayed or waiting for a predecessor task to finish (Network Diagram predecessor dependency).

					</li>
				</ol>

				<h5> Building your plan </h5>
				<p>
					Add a risk or opportunity by clicking on the               &nbsp;<FontAwesomeIcon icon={faPlusCircle} size="1x"/>  &nbsp;
 button:
				</p>
				<ol>
					<li>
						Enter the risk/opportunity “event name”.

					</li>
          <li>Select a probability from the drop-down menu </li>
        
					<li>
						Enter a cost impact (positive for a risk, negative for an opportunity).
					</li>
					<li>
						Create an action plan for proactively planning to deal with the risk (mitigate) or opportunity (enhance). You must enter an action plan. 

					</li>
					<li>
				  Click on the “Save” button to save your entry or the “Close” button to cancel your entry.
					</li>
				</ol>

				<h5>Editing your plan</h5>
				<p>
				 Editing your plan Once entered, individual risk/opportunity events can be edited or deleted using the edit/delete icons  <img
		          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

		          src={imageURLs.EditDeleteIconsSmall}
		          alt="editdelete"
					/>  to the far right of each row. .

				</p>
				<p>
				  It is good project management to keep your Risk/Opportunity plan up to date as you execute your project!
				</p>

			</CardBody>
      <InfoFooter selectedKey="reserves" showProjectLink={true} />
      </Collapse>
		</Card>
	);

export default RiskInfo;
