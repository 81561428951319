
import React from "react";
import imageURLs from "./images/imageURLs.js";


const CreateReportPDFsHelp = () => (
  <div>
    <h3>Create Report PDFs Info</h3>

    <p>
      Provides the ability to create two reports: one report contains the weekly history data of the project to date and the second contains the current status charts. The history report is portrait-oriented, and the charts are landscape-oriented.

    </p>


    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.CreatePDFsWeeklyReports}
      alt="report"
    />
    <br />
    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.CreatePDFsCharts}
      alt="report"
    />

  </div>
);

export default CreateReportPDFsHelp;
