/** app * */
export const NOTIFICATION_MODAL = "NOTIFICATION_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const EMAIL_MODAL = "EMAIL_MODAL";
export const RESET_PROJECT_MODAL = "RESET_PROJECT_MODAL";

/** authentication * */
export const LICENSE_MODAL = "LICENSE_MODAL";
export const FORGOT_PASSWORD_MODAL = "FORGOT_PASSWORD_MODAL";
export const RESET_PASSWORD_MODAL = "RESET_PASSWORD_MODAL";
export const NEED_REGISTRATION_MODAL = "NEED_REGISTRATION_MODAL";

/** Welcome * */
export const HELP_DOCUMENTATION_MODAL = "HELP_DOCUMENTATION_MODAL";
export const ORIENTATION_MODAL = "ORIENTATION_MODAL";
export const REFERENCE_MODAL = "REFERENCE_MODAL";

/** Simulation * */

/* Planning */
export const TASK_MODAL = "TASK_MODAL";
export const BUDGET_MODAL = "BUDGET_MODAL";
export const ADD_RISK_MODAL = "ADD_RISK_MODAL";
export const ADD_RESOURCE_MODAL = "ADD_RESOURCE_MODAL";
export const ADD_TRAINING_MODAL = "ADD_TRAINING_MODAL";

/** Execution * */

export const MEETING_INTERACTION_MODAL = "MEETING_INTERACTION_MODAL";
export const CONFERENCE_INTERACTION_MODAL = "CONFERENCE_INTERACTION_MODAL";
export const RECOGNITION_INTERACTION_MODAL = "RECOGNITION_INTERACTION_MODAL";
export const STAKEHOLDER_INTERACTION_MODAL = "STAKEHOLDER_INTERACTION_MODAL";
export const TRAINING_INTERACTION_MODAL = "TRAINING_INTERACTION_MODAL";
export const WORKER_ASSIGNMENT_MODAL = "WORKER_ASSIGNMENT_MODAL";
export const AVAILABLE_RESOURCES_MODAL = "AVAILABLE_RESOURCES_MODAL";
export const RESOURCE_PROFILE_MODAL = "RESOURCE_PROFILE_MODAL";

/** Student * */
export const START_RUN_MODAL = "START_RUN_MODAL";

/** Instructor **/

export const NEW_CLASSROOM_MODAL = "NEW_CLASSROOM_MODAL";
