import React, { useState, useRef, useEffect, Fragment } from "react";
import { UncontrolledTooltip } from "reactstrap";
import DataGrid from "react-data-grid";
import styled from "styled-components";
import ReactSelectEditor from "../../../components/ReactSelectEditor";

const StyledStakeholderPlanTable = styled.div`
  .rdg-cell > div {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    padding: 5px;
    text-align: center;

    i {
      margin: 0;
    }
  }
`;

const StakeholderTable = (props) => {
  const [selectedStakeholderDesc, setSelectedStakeholderDesc] = useState(
    "Customer"
  );
  const gridRef = useRef(null);
  useEffect(() => {
    if (
      gridRef && gridRef.current &&
      gridRef.current?.scrollToColumn &&
      props.displayProjectWeek &&
      props.projectedFinishWeek &&
      props.displayProjectWeek > 3 &&
        props.displayProjectWeek + (props.narrowView ? 4 : 8) < props.projectedFinishWeek
    ) {
      const { scrollToColumn } = gridRef.current;
      setTimeout(() => {
        scrollToColumn(props.displayProjectWeek + (props.narrowView ? 4 : 8));
      }, 300);
    }
  }, [gridRef, props]);

  const giveStatusReportToAll = (reportWeek, event) => {
    event.preventDefault();
    const idList = [];

    for (let i = 0, len = props.detailInfoWeekMap.length; i < len; i += 1) {
      const det = props.detailInfoWeekMap[i];
      if (det.Item4 === reportWeek) {
        idList.push(det.Item1);
      }
    }

    props.addActionToAll(
      idList,
      reportWeek.slice(1, reportWeek.length),
      props.runId,
      "Status Report"
    );
  };

  const clearAllCellsForWeek = (reportWeek, event) => {
    event.preventDefault();
    const idList = [];

    for (let i = 0, len = props.detailInfoWeekMap.length; i < len; i += 1) {
      const det = props.detailInfoWeekMap[i];
      if (det.Item4 === reportWeek) {
        idList.push(det.Item1);
      }
    }

    props.addActionToAll(
      idList,
      reportWeek.slice(1, reportWeek.length),
      props.runId,
      "None"
    );
  };

  const createOptions = (row) => {
    const options = [];

    options.push({ label: "Meeting", value: "Meeting" });
    options.push({ label: "Status Report", value: "Status Report" });

    switch (row.StakeholderDescription) {
      case "Customer":
        options.push({ label: "Training", value: "Training" });
        options.push({ label: "Visit", value: "Visit" });
        break;
      case "User":
        options.push({ label: "Survey", value: "Survey" });
        options.push({ label: "Visit", value: "Visit" });
        break;
      case "Subcontractor":
        options.push({ label: "Visit", value: "Visit" });
        options.push({ label: "Training", value: "Training" });
        break;
      default:
        break;
    }
    options.push({ label: "None", value: "None" });
    return options;
  };

  const handleActionChange = (row, cellName, newCellValue) => {
    if (row === null || row === undefined) {
      return;
    }
    setSelectedStakeholderDesc(row.StakeholderDescription);
    // use stakeholderid to represent which row number
    let dataObj = {};
    for (let i = 0, len = props.detailInfoWeekMap.length; i < len; i += 1) {
      const det = props.detailInfoWeekMap[i];
      if (det.Item4 === cellName && det.Item3 === row.StakeholderId) {
        dataObj = {
          displayObj: row,
          detailId: det.Item1,
          newAction: newCellValue,
          week: det.Item4
        };
        break;
      }
    }
    if (
      dataObj !== null &&
      dataObj !== undefined &&
      Object.prototype.hasOwnProperty.call(dataObj, "detailId")
    ) {
      props.saveStakeholderItem(dataObj);
    }
  };

  const hideNone = (cellValue) => {
    if (cellValue === "None") {
      return `<span class="invisible">${cellValue}</span>`;
    }
    return cellValue;
  };

  const makeColumns = () => {
    const columns = [
      {
        key: "StakeholderDescription",
        name: "Name",
        width: 225,
        frozen: true,
        formatter: ({ row }) => <div>{row.StakeholderDescription}</div>,
        headerRenderer: ({ column }) => <div>{column.name}</div>
      }
    ];

    for (let week = 1; week <= props.projectedFinishWeek + 1; week += 1) {
      let activeCol = false;

      if (week === props.displayProjectWeek) {
        activeCol = true;
      }
      const wk = `W${week}`;
      columns.push({
        key: wk,
        name: wk,
        sort: true,
        resizable: true,
        headerRenderer: () => (
          <StyledColumnHeader>
            {wk}
            {!props.displayOnly && (
              <Fragment>
                <span>
                  <UncontrolledTooltip
                    placement="top"
                    target={`statusReportTooltipToggle${wk}`}
                  >
                    Give status report to all stakeholders in Week {week}
                  </UncontrolledTooltip>
                  <button
                    className="btn btn-link"
                    id={`statusReportTooltipToggle${wk}`}
                    onClick={(evt) => giveStatusReportToAll(wk, evt)}
                  >
                    <i className="fa fa-file-text" value={week} />
                  </button>
                  <UncontrolledTooltip
                    placement="top"
                    target={`clearTooltipToggle${wk}`}
                  >
                    Clear all scheduled actions for Week {week}
                  </UncontrolledTooltip>
                  <button
                    className="btn btn-link"
                    id={`clearTooltipToggle${wk}`}
                    onClick={(evt) => clearAllCellsForWeek(wk, evt)}
                  >
                    <i className="fa fa-minus-square-o" />
                  </button>
                </span>
              </Fragment>
            )}
          </StyledColumnHeader>
        ),
        formatter: (formatterProps) => {
          if (activeCol) {
            return (
              <div style={{ textAlign: "center", backgroundColor: "#eef9f6" }}>
                {formatterProps.row[formatterProps.column.key] === "None" ? (
                  <span style={{ color: "transparent" }}>
                    formatterProps.row[formatterProps.column.key]
                  </span>
                ) : (
                  formatterProps.row[formatterProps.column.key]
                )}
              </div>
            );
          } else {
            return (
              <div style={{ textAlign: "center" }}>
                {formatterProps.row[formatterProps.column.key] === "None" ? (
                  <span style={{ color: "transparent" }}>
                    formatterProps.row[formatterProps.column.key]
                  </span>
                ) : (
                  formatterProps.row[formatterProps.column.key]
                )}
              </div>
            );
          }
        },
        width: 120,
        editor: (editorProps) => {
          return (
            <ReactSelectEditor
              value={editorProps.row.StakeholderDescription}
              row={editorProps.row}
              col={editorProps.column}
              options={createOptions(editorProps.row)}
              rowHeight={editorProps.rowHeight}
              menuPortalTarget={editorProps.editorPortalTarget}
              isDisabled={props.displayOnly}
              onChange={(value) => {

                handleActionChange(
                  editorProps.row,
                  editorProps.column.key,
                  value
                );
                return editorProps.onRowChange(
                  { ...editorProps.row, StakeholderDescription: value },
                  true
                );
              }}
            />
          );
        },
        editorOptions: {
          editOnClick: !props.displayOnly
        }
      });
    }

    return columns;
  };

  return (
    <div className="box-content">
      <div className="component-Table">
        <StyledStakeholderPlanTable>
          <DataGrid
            headerRowHeight={50}
            rowHeight={60}
            className="rdg-light"
            columns={makeColumns()}
            ref={gridRef}
            rows={props.stakeholderPlan}
            enableCellSelect={true}
          />
        </StyledStakeholderPlanTable>
      </div>
    </div>
  );
};

export default StakeholderTable;
