import { requestUpdatedBudget } from 'redux/Project/thunks';
import { fetchChartData} from "redux/Report/thunks";


import {
  addSingleAlert,
  createAPIError
} from 'redux/Notification/NotificationModule';
import { processFailToFetch } from 'utilities/handleFetchErrors';

import * as actions from './actions';

export const fetchStakeholderPlan = runId => async (dispatch, getState) => {
  try {
    const payload = { runId };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/stakeholderPlan`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: 'danger',
        event: 'Stakeholder Error',
        headline: 'Error accessing the stakeholderPlan api',
        message: `runId: ${runId}  fetch stakeholderPlan : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: 'thunk',
        week: 0,
        info: `runId: ${runId}  fetchStakeholders : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: 'CREATE_API_ENTITY_ERROR',
          errorType: 'warning',
          runId,
          message: 'Entity error while creating fetchStakeholders api'
        })
      );
      return false;
    } else if (json.status === 'error') {
      dispatch(actions.actionError(json));
      return false;
    }
    const pl = JSON.parse(json.payload);
    dispatch(actions.receiveStakeholderPlan(pl)); //
    return true;

    // In a real world app, you also want to
    // catch any error in the network call.
  } catch (e) {
    processFailToFetch(
e,
      getState(),
      dispatch,
      addSingleAlert,
      'fetchStakeholderPlan'
    );
    return false;
  }
};

export const addActionToAll = (idList, weekNo, runId, newAction) => async (
  dispatch,
  getState
) => {
  const payload = {
    idList,
    weekNo,
    runId,
    newAction
  };
  try {
    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonPlanningSvc.svc/saveStakeholderPlanAllAction`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      const alert = {
        id: 20,
        type: 'danger',
        event: 'Stakeholder Error',
        headline: 'Error accessing the stakeholderPlan api',
        message: `runId: ${runId}  fetch stakeholderPlan : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: 'thunk',
        week: 0,
        info: `runId: ${runId}  fetchStakeholders : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: 'CREATE_API_ENTITY_ERROR',
          errorType: 'warning',
          runId,
          message: 'Entity error while creating fetchStakeholders api'
        })
      );
      return false;
    } else if (json.status === 'error') {
      dispatch(
        actions.saveError(
          json.statusMessage
        )
      );
      return false;
    }
    const pl = JSON.parse(json.payload);
    dispatch(actions.receiveStakeholderPlan(pl));
    dispatch(requestUpdatedBudget(runId));
    dispatch(fetchChartData(runId));

    return true;
  } catch (e) {
    processFailToFetch(e,getState(), dispatch, addSingleAlert, 'addActionToAll');
    return false;
  }
};

export const saveStakeholderItem = data => async (dispatch, getState) => {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  // dleware can return a value,
  // that is passed on as the return value of the dispatch method.

  // In this case, we return a promise to wait for.
  // This is not required by thunk middleware, but it is convenient for us.
  const runId = data.displayObj.RunId;
  try {
    const payload = {
      runId: data.displayObj.RunId,
      detailId: data.detailId,
      newAction: data.newAction
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/saveStakeholderPlanItem`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      const alert = {
        id: 20,
        type: 'danger',
        event: 'Stakeholder Error',
        headline: 'Error accessing the saveStakeholderItem api',
        message: `runId: ${runId}  saveStakeholderItem : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: 'thunk',
        week: 0,
        info: `runId: ${runId}  saveStakeholderItem : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: 'CREATE_API_ENTITY_ERROR',
          errorType: 'warning',
          runId,
          message: 'Entity error while creating saveStakeholderItem api'
        })
      );
      return false;
    } else if (json.status === 'error') {
      dispatch(
        actions.saveError(json.statusMessage)
      );
      return false;
    }
    const pl = JSON.parse(json.payload);
    dispatch(actions.receiveStakeholderPlan(pl));
    dispatch(requestUpdatedBudget(data.displayObj.RunId));
    dispatch(fetchChartData(data.displayObj.RunId));

    return true;
  } catch (e) {
    processFailToFetch(
e,
      getState(),
      dispatch,
      addSingleAlert,
      'saveStakeholderItem'
    );
    return false;
  }
};
