import React from "react";
import { Badge } from "reactstrap";
import styled from "styled-components";

const VersionBadge = styled(Badge)`
	font-size: 0.65rem;
`;

const BuildVersion = props => (
	<VersionBadge className="mr-auto" color="light">
		{props.version}
	</VersionBadge>
);

export default BuildVersion;
