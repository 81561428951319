import {
  fetchRuns,
  getMasterControlUserInfo
} from "redux/Authentication/thunks";
import {
  instructorLoadProject,
  instructorClearProject
} from "redux/Project/thunks";
import {
  fetchHistoryData,
  fetchChartData,
  fetchLaborSummaryData
} from "redux/Report/thunks";
import { processFailToFetch } from "utilities/handleFetchErrors";
import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";

import actions from "./actions";
import history from "../../history";
import apisauce from "apisauce";
import get from "lodash/get";
const url = process.env.REACT_APP_ADMIN_BASE_URL;
//"https://test.simproject.com/adminServices/api/";

const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL: url,
  // here are some default headers
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
   timeout: 60000
});

// const createJsonApi = (url) => {
//   const usrl = process.env.REACT_APP_ADMIN_TOKEN_URL;
//   const jsonApi = apisauce.create({
//     baseUrl: usrl,
//     // here are some default headers
//     headers: {
//       "Accept": "application/json",
//       "Content-Type": "application/json"
//     },
//     // 10 second timeout...
//     timeout: 10000
//   });
//   return jsonApi
// }

const getAccessToken = async (getState) => {
  // try local storage
  const token = localStorage.getItem("access_token");
  const expiresAt = localStorage.getItem("expiresAt");

  if (token && expiresAt > new Date()) {
    return token;
  }
  const tokenResponse = await fetch(
    `${getState().app.baseURL}JsonProjectSvc.svc/getToken`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
      },
      method: "post"
    }
  );

  // api call http status
  // const { status } = tokenResponse;

  // wait for json respone to come back
  const json = await tokenResponse.json();
  const tokenObj = JSON.parse(json.payload);

  if (tokenObj.access_token && tokenObj.access_token.length > 10) {
    localStorage.setItem("expiresAt", tokenObj.expires);
    localStorage.setItem("access_token", tokenObj.access_token);
  }
  return tokenObj.access_token;
};

export const setExecSeqFilterValue = (execSeq) => async (dispatch) => {
  await dispatch(actions.setExecSeqFilterValue(execSeq));
  return true;
};

export const setSelectedClass = (classroom) => async (dispatch) => {
  await dispatch(actions.setSelectedClass(classroom));
  await dispatch(fetchStudents(classroom.classroomId));
  await dispatch(fetchClassRuns(classroom.classroomId));

  return true;
};

export const fetchInstructors = () => async (dispatch, getState) => {
  const token = await getAccessToken(getState);
  if (token) {
    const iResponse = await api.get(
      "Instructors",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (iResponse.ok) {
      const instructors = get(iResponse, "data");
      await dispatch(actions.fetchInstructorsSuccess(instructors));

      return true;
    } else {
      await dispatch(actions.fetchInstructorsError(iResponse));
    }
  } else {
    await dispatch(actions.fetchInstructorsError("no token available"));
  }
};

export const addSecondaryInstructor =
  (instructorId, classroomId) => async (dispatch, getState) => {
    const payload = {
      instructorId,
      classroomId
    };
    const token = await getAccessToken(getState);
    if (token) {
      const iResponse = await api.post("Instructors/AddSecondary", payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (iResponse.ok) {
        await dispatch(actions.addSecondarySuccess());

        return true;
      } else {
        await dispatch(actions.addSecondaryError(iResponse));
      }
    } else {
      await dispatch(actions.addSecondaryError("no token available"));
    }
  };
export const removeSecondaryInstructor =
  (instructorId, classroomId) => async (dispatch, getState) => {
    const payload = {
      instructorId,
      classroomId
    };

    const token = await getAccessToken(getState);
    if (token) {
      const iResponse = await api.post("Instructors/RemoveSecondary", payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (iResponse.ok) {
        await dispatch(actions.removeSecondarySuccess());

        return true;
      } else {
        await dispatch(actions.removeSecondaryError(iResponse));
      }
    } else {
      await dispatch(actions.removeSecondaryError("no token available"));
    }
  };

export const changePrimaryInstructor =
  (instructorId, classroomId) => async (dispatch, getState) => {
    const payload = {
      instructorId: instructorId,
      classroomId: classroomId
    };
    const token = await getAccessToken(getState);
    if (token) {
      const iResponse = await api.post("Instructors/ChangePrimary", payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (iResponse) {
        await dispatch(actions.changePrimarySuccess());

        return true;
      } else {
        await dispatch(actions.changePrimaryError(iResponse));
      }
    } else {
      await dispatch(actions.changePrimaryError("No token available"));
    }
  };

export const inactivateClassroom =
  (classroomId) => async (dispatch, getState) => {
    const token = await getAccessToken(getState);
    if (token) {
      const postString = `inactivateClassroom/${classroomId}`;
      const iResponse = await api.post(
        postString,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (iResponse.ok) {
        await dispatch(actions.inactivateClassroomSuccess());

        return true;
      } else {
        await dispatch(actions.inactivateClassroomError(iResponse));
      }
    } else {
      await dispatch(actions.inactivateClassroomError("token not available"));
    }
  };

export const setupNewClassroom = (payload) => async (dispatch, getState) => {
  const currentUserId = getState().auth.userId;
  let addAsSecondary = false;

  if (currentUserId) {
    if (parseInt(currentUserId, 10) !== parseInt(payload.InstructorId, 10)) {
      addAsSecondary = true;
    }
  }

  const token = await getAccessToken(getState);

  if (token) {
    const iResponse = await api.post("classroomSetup", payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (iResponse.ok) {
      if (addAsSecondary) {
        await dispatch(
          addSecondaryInstructor(currentUserId, iResponse.data.classroomId)
        );
        await dispatch(actions.addClassroomSuccess());

        return true;
      } else {
        await dispatch(actions.addClassroomSuccess());

        return true;
      }
    } else {
      await dispatch(actions.addClassroomError(iResponse));
    }
  } else {
    await dispatch(actions.addClassroomError("no token available"));
  }
};

export const fetchStudents = (classroomId) => async (dispatch, getState) => {
  if (classroomId !== undefined && classroomId !== null) {
    dispatch(actions.setLoading(true));
    try {
      const payload = {
        classroomid: classroomId
      };

      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/getStudentsByClass`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      dispatch(actions.setLoading(false));

      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "fetchStudents",
          headline: "Error fetching students",
          message: `id: ${classroomId}  fetchStudents : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${classroomId}  fetchStudents : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.fetchStudentsError(status));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            classroomId,
            message: "Entity error while creating fetchStudents api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(
          actions.fetchStudentsError(json)
        );
        return false;
      }
      dispatch(
        actions.fetchStudentsSuccess(
          JSON.parse(json.payload)
        )
      );
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "fetchStudents"
      );
      return false;
    }
  } else {
    return false;
  }
};

export const fetchClassRuns = (classroomId) => async (dispatch, getState) => {
  try {
    const payload = {
      classroomid: classroomId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonClassroomSvc.svc/getClassRuns`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad

      const alert = {
        id: 20,
        type: "danger",
        event: "fetchClassRuns",
        headline: "Error in fetchClassRuns",
        message: `id: ${classroomId}  fetchClassRuns : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `id: ${classroomId}  fetchClassRuns : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.fetchClassRunsError(status));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          classroomId,
          message: "Entity error while creating fetchClassRuns api"
        })
      );

      return false;
    } else if (json.status === "error") {
      dispatch(actions.fetchClassRunsError(json));
      return false;
    }
    dispatch(
      actions.fetchClassRunsSuccess(
        JSON.parse(json.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "fetchClassRuns"
    );
    return false;
  }
};

// uses simproject web api
export const fetchClassesByInstructor =
  (instructorId) => async (dispatch, getState) => {
    const token = await getAccessToken(getState);

    if (token) {
      const postString = `ClassesByInstructor/${instructorId}`;
      const iResponse = await api.get(
        postString,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (iResponse) {
        const classes = get(iResponse, "data");
        await dispatch(actions.fetchInstructorClassesSuccess(classes));
        if (classes && classes.length > 0) {
          await dispatch(actions.setSelectedClass(classes[0]));

          await dispatch(fetchStudents(classes[0].classroomId));
          await dispatch(fetchClassRuns(classes[0].classroomId));
        }
        return true;
      } else {
        await dispatch(actions.fetchInstructorClassesError(iResponse));
      }
    } else {
      await dispatch(actions.fetchInstructorClassesError("no token available"));
    }

    return false;
  };

export const fetchStudentInfo = (studentData) => async (dispatch, getState) => {
  const payload = {
    userId: studentData.studentId
  };
  try {
    const response = await fetch(
      `${getState().app.mbrshpURL}getStudentUserByIdForInstructor`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "fetchStudentInfo",
        headline: "Error in fetching students",
        message: `id: ${
          studentData.studentId
        }  fetchStudentInfo : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `id: ${
          studentData.studentId
        }  fetchStudentInfo : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.fetchStudentsError(status));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          id: `${studentData.studentId}`,
          message: "Entity error while creating fetchStudentInfo api"
        })
      );
      return false;
    }
    const pl = JSON.parse(json.getUserByIdResult.payload);

    await dispatch(actions.receiveStudentInfo(pl));

    const response2 = await dispatch(fetchRuns(studentData.studentId));

    if (response2 === true) {
      await dispatch(actions.setStudentInvalidLicense(false));
      if (studentData.runId > 0 && studentData.productId > 0) {
        await Promise.all([
          dispatch(
            instructorLoadProject(studentData.runId, studentData.productId)
          ),
          dispatch(fetchHistoryData(studentData.runId)),
          dispatch(fetchChartData(studentData.runId)),
          // dispatch(fetchLaborSummaryData(studentData.runId))
        ]).then(async () => {
          await dispatch(actions.setLoading(false));
        });
      } else {
        await dispatch(instructorClearProject);
          await dispatch(actions.setLoading(false));
      }
    } else {
      await dispatch(instructorClearProject);
        await dispatch(actions.setLoading(false));
    }
  
    if (window.location.pathname !== "/instructor-dashboard") {
      history.push("/instructor-dashboard");
    }
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "getStudentData"
    );
    return false;
  }
};

export const updateClassDates =
  (cId, startDate, endDate) => async (dispatch, getState) => {
    try {
      const payload = {
        classId: cId,
        startDate: `/Date(${startDate})/`,
        endDate: `/Date(${endDate})/`
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/updateClassroomDates`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "updateClassroomDates",
          headline: "Error in updateClassroomDates",
          message: `id: ${cId}  updateClassroomDates : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${cId}  updateClassroomDates : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: cId,
            message: "Entity error while creating updateClassroomDates api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(actions.updateClassroomError(json));
        return false;
      }
      const pl = JSON.parse(json.payload);
      await dispatch(actions.updateClassroomSuccess(pl));

      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "updateClassroomDates"
      );
      return false;
    }
  };

export const updateClassStatus =
  (cId, cStatus) => async (dispatch, getState) => {
    try {
      const payload = {
        classId: cId,
        classStatus: cStatus
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/updateClassroomStatus`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "updateClassStatus",
          headline: "Error in updateClassStatus",
          message: `id: ${cId}  updateClassStatus : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${cId}  updateClassStatus : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: cId,
            message: "Entity error while creating updateClassStatus api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(
          actions.updateClassroomError(json)
        );
        return false;
      }
      const pl = JSON.parse(json.payload);
      await dispatch(actions.updateClassroomSuccess(pl));
      await dispatch(getMasterControlUserInfo(getState().auth.userId));
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "updateClassStatus"
      );
      return false;
    }
  };

export const updateClassName = (cId, cName) => async (dispatch, getState) => {
  try {
    const payload = {
      classId: cId,
      className: cName
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonClassroomSvc.svc/updateClassroomName`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad

      const alert = {
        id: 20,
        type: "danger",
        event: "updateClassName",
        headline: "Error in updateClassName",
        message: `id: ${cId}  updateClassName : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `id: ${cId}  updateClassName : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.updateClassroomError(status));

      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          id: cId,
          message: "Entity error while creating updateClassName api"
        })
      );

      return false;
    } else if (json.status === "error") {
      dispatch(actions.updateClassroomError(json));
      return false;
    }
    const pl = JSON.parse(json.payload);
    await dispatch(actions.updateClassNameSuccess(pl));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateClassName"
    );
    return false;
  }
};

export const updateClassStopWeeks =
  (stopWeekList) => async (dispatch, getState) => {
    try {
      const payload = {
        classRunStopWeekList: stopWeekList
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/updateClassStopWeeks`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "updateClassStopWeeks",
          headline: "Error  in updateClassStopWeeks",
          message: `updateClassStopWeeks : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `updateClassStopWeeks : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateStopWeeksError(status));

        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: 0,
            message: "Entity error while creating updateClassStopWeeks api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(
          actions.updateStopWeeksError(json)
        );
        return false;
      }
      const pl = JSON.parse(json.payload);
      await dispatch(actions.updateStopWeeksSuccess(pl));

      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "updateClassStopWeeks"
      );
      return false;
    }
  };

export const updateAllowToPlan =
  (id, allowToPlan) => async (dispatch, getState) => {
    try {
      const payload = {
        id: id,
        canPlan: allowToPlan
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/toggleClassRunCanPlan`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "toggleCanPlan",
          headline: "Error in toggleCanPlan",
          message: `id: ${id}  toggleCanPlan : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${id}  toggleCanPlan : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));

        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: id,
            message: "Entity error while creating toggleCanPlan api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(actions.updateClassroomError(json));
        return false;
      }
      const pl = JSON.parse(json.payload);

      await dispatch(actions.toggleAllowToPlan(pl));

      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "toggleCanPlan"
      );
      return false;
    }
  };

export const updateAllowToRun =
  (id, allowToRun) => async (dispatch, getState) => {
    try {
      const payload = {
        id: id,
        canRun: allowToRun
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonClassroomSvc.svc/toggleClassRunCanRun`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "toggleCanRun",
          headline: "Error in toggleCanRun",
          message: `id: ${id}  toggleCanRun : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${id}  toggleCanRun : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));

        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: id,
            message: "Entity error while creating toggleCanRun api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(actions.updateClassroomError(json));
        return false;
      }
      const pl = JSON.parse(json.payload);

      await dispatch(actions.toggleAllowToRun(pl));

      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "toggleCanRun"
      );
      return false;
    }
  };

export const updateStopWeek = (id, stopWeek) => async (dispatch, getState) => {
  try {
    const payload = {
      id: id,
      stopOnWeek: stopWeek
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonClassroomSvc.svc/toggleClassStopWeek`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad

      const alert = {
        id: 20,
        type: "danger",
        event: "toggleClassStopWeek",
        headline: "Error in toggleClassStopWeek",
        message: `id: ${id}  toggleClassStopWeek : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `id: ${id}  toggleClassStopWeek : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.updateClassroomError(status));

      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          id: id,
          message: "Entity error while creating toggleClassStopWeek api"
        })
      );

      return false;
    } else if (json.status === "error") {
      dispatch(actions.updateClassroomError(json));
      return false;
    }
    const pl = JSON.parse(json.payload);
    await dispatch(actions.toggleStopWeek(pl));

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "toggleClassStopWeek"
    );
    return false;
  }
};

export const updateStopMessage =
  (id, stopMessage) => async (dispatch, getState) => {
    try {
      const payload = {
        id: id,
        stopMessage: stopMessage
      };
      const response = await fetch(
        `${
          getState().app.baseURL
        }JsonClassroomSvc.svc/changeClassStopWeekMessage`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "toggleClassStopWeek",
          headline: "Error in toggleClassStopWeek",
          message: `id: ${id}  toggleClassStopWeek : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `id: ${id}  toggleClassStopWeek : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));

        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            id: id,
            message: "Entity error while creating toggleClassStopWeek api"
          })
        );

        return false;
      } else if (json.status === "error") {
        dispatch(
          actions.updateClassroomError(json)
        );
        return false;
      }
      const pl = JSON.parse(json.payload);

      await dispatch(actions.updateStopMessage(pl));

      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "toggleClassStopWeek"
      );
      return false;
    }
  };

export const updateStopMessageList =
  (stopMessageList) => async (dispatch, getState) => {
    try {
      const payload = {
        classRunStopWeek: stopMessageList
      };
      const response = await fetch(
        `${
          getState().app.baseURL
        }JsonClassroomSvc.svc/changeClassStopWeekMessageList`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      const { status } = response;

      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad

        const alert = {
          id: 20,
          type: "danger",
          event: "toggleClassStopWeek",
          headline: "Error in toggleClassStopWeek",
          message: ` toggleClassStopWeek : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: ` toggleClassStopWeek : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actions.updateClassroomError(status));

        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            message: "Entity error while creating toggleClassStopWeek api"
          })
        );
        return false;
      } else if (json.status === "error") {
        dispatch(
          actions.updateClassroomError(
            json
          )
        );
        return false;
      }
      const pl = JSON.parse(json.payload);
      await dispatch(actions.updateStopMessage(pl));
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "toggleClassStopWeek"
      );
      return false;
    }
  };

export const updateClassRuns = (cRuns) => async (dispatch, getState) => {
  try {
    const payload = {
      classRuns: cRuns
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonClassroomSvc.svc/updateClassRuns`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad

      const alert = {
        id: 20,
        type: "danger",
        event: "updateClassRuns",
        headline: "Error in updateClassRuns",
        message: `updateClassRuns : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `updateClassRuns : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.updateClassRunsError(status));

      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          id: 0,
          message: "Entity error while creating updateClassRuns api"
        })
      );
      return false;
    } else if (json.status === "error") {
      dispatch(actions.updateClassRunsError(json));
      return false;
    }
    const pl = JSON.parse(json.payload);
    await dispatch(actions.updateClassRunsSuccess(pl));

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateClassRuns"
    );
    return false;
  }
};

export const bumpStudentRun =
  (runId, classStudentRunId, classroomId) => async (dispatch, getState) => {
    try {
      const payload = {
        runId,
        classStudentRunId
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonProjectSvc.svc/bumpProject`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );

      // api call http status
      // const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        return false;
      }
      if (json.status === "error") {
        return false;
      }
      const pl = JSON.parse(json.payload);
      await dispatch(
        fetchStudents(getState().instructor.selectedClass.classroomId)
      );

      return true;
    } catch (e) {
      throw e;
    }
  };

export const resetStudentRun =
  (runId, weekNumber) => async (dispatch, getState) => {
    try {
      const payload = {
        runId,
        productId: getState().auth.productId,
        weekNumber
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonProjectSvc.svc/resetProject`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      const json = await response.json();
      if (!response.ok) {
        return false;
      }
      if (json.status === "error") {
        return false;
      }

      const pl = JSON.parse(json.payload);
      await dispatch(
        fetchStudents(getState().instructor.selectedClass.classroomId)
      );

      return true;
    } catch (e) {
      throw e;
    }
  };
