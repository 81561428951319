import React from "react";
import accounting from "accounting";
import { TableHeaderColumn } from "react-bootstrap-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";
import _debounce from "lodash.debounce";
import ReactHtmlParser from "react-html-parser";
import StyledBootstrapTable from "components/styled/StyledBootstrapTable";
import styles from './RiskTable.module.css';

// for the accounting package
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

export default class RiskTable extends React.Component {
  state = {
    selectedRows: [],
    updating: false,
    newRiskItem: {
      ID: 0,
      RunId: this.props.runId,
      Action: "",
      CostImpact: "",
      Probability: 20,
      OnCriticalPath: false,
      EventName: ""
    },
    tableOptions: {
      clearSearch: true,
      sizePerPage: 10,
      sizePerPageList: [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        }
      ]
    }
  };

  componentDidMount() {
    if (this.props.modalOpen === false) {
      this.setState({
        updating: false,
        newRiskItem: {
          ID: 0,
          RunId: this.props.runId,
          Action: "",
          CostImpact: "",
          Probability: 20,
          OnCriticalPath: false,
          EventName: ""
        }
      });
    } else {
      this.setState({ updating: true });
    }
  }

  deleteItem = _debounce((index, row) => {
    this.setState({ updating: true });
    this.props.deleteRisk(index, row.ID, this.props.runId);
  }, 75);

  editItem = (row, event) => {
    event.preventDefault();
    this.setState({ updating: true });
    this.props.updateRisk(row);
  };

  costFormatter = (cell, row) => {
    return accounting.formatMoney(cell, options);
  };

  netCostFormatter = (cell, row) => {
    return accounting.formatMoney(
      row.Probability * 0.01 * row.CostImpact,
      options
    );
  };

  actionFormatter = (cell, row) => {
    return <div className={styles.responseActionRow}>{ReactHtmlParser(cell)}</div>;
  };

  buttonActionsFormatter = (cell, row) => {
    if (!row.EventName.includes("idle in week")) {
      return (
        <ButtonGroup>
          <Button
            id="editRisk"
            color="primary"
            onClick={(evt) => this.editItem(row, evt)}
          >
            <i className="fa fa-pencil" />
          </Button>
          <UncontrolledTooltip
            id="editRisktt"
            placement="left"
            target="editRisk"
          >
            Edit record
          </UncontrolledTooltip>
          <Button
            id="deleteRisk"
            color="danger"
            onClick={() => this.deleteItem(row.ID, row)}
          >
            <i className="fa fa-trash" />
          </Button>
          <UncontrolledTooltip
            id="deleteRisktt"
            placement="left"
            target="deleteRisk"
          >
            Delete record
          </UncontrolledTooltip>
        </ButtonGroup>
      );
    }
    return <span></span>;
  };

  render() {
    return (
      <StyledBootstrapTable
        data={this.props.risks}
        options={this.state.tableOptions}
        hover
        search
        striped
        size="sm"
        key={"risk-tbl"}
        bodyStyle={{ wordBreak: "break-all" }}
      >
        <TableHeaderColumn
          dataAlign="left"
          width="20%"
          dataField="EventName"
          dataSort
        >
          Event
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataAlign="center"
          dataField="Probability"
          dataSort
        >
          Prob (%)
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataAlign="right"
          dataField="CostImpact"
          dataSort
          dataFormat={this.costFormatter}
        >
          Cost Impact
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          isKey
          dataAlign="right"
          dataField="ID"
          dataSort
          dataFormat={this.netCostFormatter}
        >
          Net Cost
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="left"
          width="40%"
          dataField="Action"
          dataFormat={this.actionFormatter}
        >
          Response Action
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          width="10%"
          dataField="ID"
          dataFormat={this.buttonActionsFormatter}
        >
          Edit Actions
        </TableHeaderColumn>
      </StyledBootstrapTable>
    );
  }
}
