import { createAction } from "redux-actions";
import {
  REQUEST_STAKEHOLDERPLAN,
  RECEIVE_STAKEHOLDERPLAN,
  SAVE_STAKEHOLDERPLAN_SUCCESS,
  SAVE_STAKEHOLDERPLAN_ERROR,
  STAKEHOLDER_ERROR
} from "./types";

export const receiveStakeholderPlan = createAction(
  RECEIVE_STAKEHOLDERPLAN,
  payload => payload
);

export const requestStakeholderPlan = createAction(
  REQUEST_STAKEHOLDERPLAN,
  payload => payload
);

export const saveSuccess = createAction(
  SAVE_STAKEHOLDERPLAN_SUCCESS,
  payload => payload
);

export const saveError = createAction(
  SAVE_STAKEHOLDERPLAN_ERROR,
  error => error
);

export const actionError = createAction(STAKEHOLDER_ERROR, error => error);
