import React from "react";

const PlanningTooltip = () => (
    <div style={{textAlign: "left"}}>
      <p>Follow the steps in this checklist to complete your project plans.</p>
       <p>For each plan, make sure your planned budget number is close to the approved budget number. A big variance can indicate a problem with your plan</p>
    </div>
  )

export default PlanningTooltip
