
import React, {Fragment} from "react";
import moment from "moment";
import StyledTable from "components/styled/StyledTable";
import RunActionsContainer from "./RunActions";

const ExecutionList = props => {
  return (
    // <table className='table'>
    //   <thead>
    //     <tr>
    //       <th>#</th>
    //       <th>Run Id</th>
    //       <th>Status</th>
    //       <th>Date Started</th>
    //       <th>Date Completed</th>
    //       <th>Available Actions</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {props.runDetails && props.runDetails.length > 0 && props.runDetails.map((row, index) => (
    //       <tr key={(key += 1)}>
    //         <td key={(key += 1)}>{row.RunSequenceNumber}</td>
    //         <td key={(key += 1)}>{row.RunId}</td>
    //         <td key={(key += 1)}>{row.RunStatus}</td>
    //         <td key={(key += 1)}>{row.StartDateString}</td>
    //         <td key={(key += 1)}>{row.EndDateString}</td>
    //         <td key={(key += 1)}>
    //           <RunActionButtonContainer thisRun={row} />
    //         </td>

    
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>
   
        <StyledTable>
          <thead>
            <tr>
              <th width={"3%"}>#</th>
              <th width={"10%"}>Run Id</th>
              <th width={"15%"}>Status</th>
              <th width={"10%"}>Started</th>
              <th width={"10%"}>Completed</th>
          <th width={"50%"} className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
             {props.runDetails && props.runDetails.length > 0 && props.runDetails.map((run, index) => (
              <tr
                key={run.RunSequenceNumber}
                className={
                  run.Status === "Not Available" ||
                     run.Status === "Completed"
                    ? "table-inactive" 
                    : "table-active"
                }
              >
                <th width={"3%"} scope="row">{run.RunSequenceNumber}</th>

                <Fragment>
                  <td width={"10%"}>
                   {run.RunId}
                  </td>

                  <td width={"15%"}>{run.RunStatus}</td>
                  <td width={"10%"}>
                    {run.RunId > 0 ? (
                      moment(
                        run.RunStartDate
                      ).calendar() || null
                    ) : (
                      <span />
                    )}
                  </td>
                  <td width={"10%"}>
                    {run.RunEndDate ? (
                      moment(run.RunEndDate).calendar() || null
                    ) : (
                      <span />
                    )}
                  </td>
                  <td width={"50%"} className="text-center">
                   <RunActionsContainer thisRun={run} />
                  </td>
                </Fragment>
              </tr>
            ))}
          </tbody>
        </StyledTable>

   );
 };

export default ExecutionList;

/*
 <table className='table'>
      <thead>
        <tr>
          <th>#</th>
          <th>Run Id</th>
          <th>Status</th>
          <th>Date Started</th>
          <th>Date Completed</th>
          <th>Available Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.runDetails && props.runDetails.length > 0 && props.runDetails.map((row, index) => (
          <tr key={(key += 1)}>
            <td key={(key += 1)}>{row.RunSequenceNumber}</td>
            <td key={(key += 1)}>{row.RunId}</td>
            <td key={(key += 1)}>{row.RunStatus}</td>
            <td key={(key += 1)}>{row.StartDateString}</td>
            <td key={(key += 1)}>{row.EndDateString}</td>
            <td key={(key += 1)}>
              <RunActionButtonContainer thisRun={row} />
            </td>

    
          </tr>
        ))}
      </tbody>
    </table>
*/
