import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Card, CardBody, Label, Input, Collapse } from "reactstrap";
import InfoFooter from "components/Info/InfoFooter";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { toggleInteractionInfo } from "app/redux/actions"

const StyledCardBody = styled(CardBody)`
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InteractionInfo = () => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();


  return (
   <Card className="Card-info">
    <CardHeaderBold>
      <span className="float-left">Interactions Info</span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
           onClick={() => dispatch(toggleInteractionInfo())}
          defaultChecked={content.app.interactionInfoVisible}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </CardHeaderBold>
     <Collapse isOpen={content.app.interactionInfoVisible}>
  <StyledCardBody>
    <h5>Context and resources for interaction:</h5>
    <p>
      Your interactions with your team members and stakeholders for the current
      week are scheduled for execution via the interaction calendar. Each week
      reference your Stakeholder, Training and Risks/Reserves plans as they
      might have actions you need to implement this week. Decide if you want to
      implement your planned interactions for the week or make changes based on
      the status of your project. The daily calendar is also where you schedule
      (for this week):
    </p>

    <ul>
      <li>Team meetings</li>
      <li>Individual conferences</li>
      <li>Team and individual recognition</li>
    </ul>

    <div>
      <p>
      It is good Project Management practice to keep your plans up to date as
      events change. 
</p>
    </div>

    <p>
      <strong>
        Remember, just like a real project, your plans will not be implemented
        automatically; you must communicate your plan decisions each week to
        your team if you want them to occur. THIS IS VERY IMPORTANT!!!!!!!!!!!
      </strong>
    </p>

    <h5>Interaction decisions:</h5>
    <p>
      Enter an interaction by choosing the day and clicking on the{" "}
      <i className="fa fa-plus fa-fw" /> icon. Use the popup window to complete
      the process. Once an interaction is scheduled:
    </p>
    <ul>
      <li>
        Use the <i className="fa fa-pencil" /> icon to edit it
      </li>
      <li>
        Use the <i className="fa fa-times" /> icon to delete it
      </li>
      <li>
        Move a scheduled item from the current day to a different day by
        clicking and dragging it to the new day. Drop near the top of the day.
        If you see a <i className="fa fa-ban" /> symbol during the drag, move it
        to closer to the top of the day until the <i className="fa fa-ban" />{" "}
        goes away.
      </li>
    </ul>
      </StyledCardBody>
        <InfoFooter selectedKey="interaction" showProjectLink={false} />
    </Collapse>
    </Card>
  )
};

export default InteractionInfo;
