import {
  REQUEST_RESOURCEPLAN,
  RECEIVE_RESOURCEPLAN,
  UPDATE_RESOURCEPLAN_SUCCESS,
  INSERT_RESOURCEPLAN_SUCCESS,
  DELETE_RESOURCEPLAN_SUCCESS,
  ADD_MORE_ITEM_MAPPINGS,
  RESOURCE_ERROR,
  UPDATE_RESOURCEPLANITEM_STATE,
  SAVE_RESOURCEPLANITEM_SUCCESS,
  SET_FILTER_SKILLID
} from "./types";

// ------------------------------------
// Actions
// ------------------------------------

export function receiveResourcePlan(response) {
  return {
    type: RECEIVE_RESOURCEPLAN,
    response
  };
}

export function updateResourceItemState(row) {
  return {
    type: UPDATE_RESOURCEPLANITEM_STATE,
    payload: row
  }
}
export function requestResourcePlan(runId) {
  return {
    type: REQUEST_RESOURCEPLAN,
    runId
  };
}
// here newitem is a boolean to tell us if we are adding or updating
export function updateResourcePlanSuccess(data) {
  return {
    type: UPDATE_RESOURCEPLAN_SUCCESS,
    data // data is a string at this point
  };
}
// here newitem is a boolean to tell us if we are adding or updating
export function insertResourcePlanSuccess(data) {
  return {
    type: INSERT_RESOURCEPLAN_SUCCESS,
    data // data is a string at this point
  };
}

// here newitem is a boolean to tell us if we are adding or updating
export function deleteResourcePlanSuccess(index, id) {
  return {
    type: DELETE_RESOURCEPLAN_SUCCESS,
    index,
    id // data is a string at this point
  };
}

export function addMoreItemMappings(data) {
  return {
    type: ADD_MORE_ITEM_MAPPINGS,
    data
  };
}
export function actionError(error) {
  return {
    type: RESOURCE_ERROR,
    error
  };
}
export function saveResourcePlanItemSuccess(data) {
  return {
    type: SAVE_RESOURCEPLANITEM_SUCCESS,
    data // data is a string at this point
  };
}

export function setFilterSkillId(id) {
  return {
    type: SET_FILTER_SKILLID,
    id
  };
}
