import React from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Label,
  Input
} from "reactstrap";
import GanttChartContainer from "routes/Report/components/GanttChart/GanttChart";
import NetworkDiagramContainer from "routes/Report/NetworkDiagramContainer";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

class TabbedReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      cardReportsOpen: this.props.open || true
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleBudget() {
    this.setState({ cardReportsOpen: !this.state.cardReportsOpen });
  }

  render() {
    let i = 1;
    return (
      <Row tourstop="nd">
        <Col>
          <Card id="card-HelpfulReports">
            <CardHeader>
              <strong>Helpful Reports</strong>
              {!this.props.noHideToggle && (
                <Label className="switch switch-default switch-primary">
                  <Input
                    type="checkbox"
                    className="switch-input"
                    onClick={() => this.toggleBudget()}
                    defaultChecked={this.state.cardReportsOpen}
                  />
                  <span className="switch-label" />
                  <span className="switch-handle" />
                </Label>
              )}
            </CardHeader>
            <Collapse isOpen={this.state.cardReportsOpen}>
              <NoMaxCardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Gantt Chart
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Network Diagram
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane key={(i += 1)} tabId="1">
                    <GanttChartContainer />
                  </TabPane>
                  <TabPane key={(i += 1)} tabId="2">
                    <NetworkDiagramContainer />
                  </TabPane>
                </TabContent>
              </NoMaxCardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default TabbedReports;
