import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import  BudgetContainer from "routes/Budget/components/BudgetContainer";
import ResourceGrid from "../Resource/components/ResourceGrid";
import { StakeholderPlan4DisplayContainer } from "components/StakeholderPlan4Display";
import TrainingTable4DisplayContainer from "components/TrainingPlan4Display";
import { RiskTable4DisplayContainer } from "components/RiskPlan4Display";

const ReviewProject = () => (
  <div>
    <Row>
      <Col>
        <Card className="component-ResourcePlan">
          <CardHeader>Final Budget Summary</CardHeader>
          <CardBody>
            <BudgetContainer />
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <ResourceGrid displayOnly />
      </Col>
    </Row>

    <Row>
      <Col>
        <Card className="component-StakeholderPlan">
          <CardHeader>Stakeholder Plan</CardHeader>
          <CardBody className="mh-0">
            <StakeholderPlan4DisplayContainer />
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card>
          <CardHeader>Training Plan</CardHeader>
          <CardBody>
            <TrainingTable4DisplayContainer />
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card>
          <CardHeader>Risk Plan</CardHeader>
          <CardBody>
            <RiskTable4DisplayContainer />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default ReviewProject;
