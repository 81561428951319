import React from "react";
import { workerStatusLookup } from "utilities/utils";

export const TaskAssignments = props => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center" colSpan="3">
              Worker Information
            </th>
            <th className="text-center" colSpan="4">
              Assigned Task Priorities and Overtime
            </th>
          </tr>
          <tr>
            <th>{props.header.col1}</th>
            <th>{props.header.col2}</th>
            <th className="text-center">{props.header.col3}</th>
            <th className="text-center">{props.header.col4}</th>
            <th className="text-center">{props.header.col5}</th>
            <th className="text-center">{props.header.col6}</th>
            <th className="text-center">{props.header.col7}</th>
          </tr>
        </thead>
        <tbody>
          {props.properties.map((row, index) => (
            <tr key={index}>
              <td>{row.worker}</td>
              <td>{row.skill}</td>
              <td className="text-center">{workerStatusLookup(row.status)}</td>
              <td className="text-center">{row.priority1}</td>
              <td className="text-center">{row.priority2}</td>
              <td className="text-center">{row.priority3}</td>
              <td className="text-center">{row.overtime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
