import React, { useState } from "react";
import RightMarginButton from "components/styled/RightMarginButton";
import "../ProjectStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ResumeRun = props => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <RightMarginButton
        color="primary"
        onClick={() => {
          setLoading(true);
          props.resumeProject(props.runId, props.projectId);
        }}
      >
      

        {loading && (
       <>   <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" /> <span></span> </>
        )}
        Resume
      </RightMarginButton>
    </>
  );
};

export default ResumeRun;
