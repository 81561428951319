import React from "react";


export const IndvTraining = (props) => {
  return (
    <div className="content">
      <h6>
        <strong>{props.properties.worker}</strong>&nbsp;
        attended {props.properties.training}
      </h6>
    </div>
  );
};
