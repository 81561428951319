import styled from "styled-components";

const ComponentArea = styled.div`
	animation-fill-mode: both;
	animation-name: fadeIn;
	animation-duration: 1s;
	padding-bottom: 2rem;
`;

export { ComponentArea };
