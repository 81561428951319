import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  IntroMemo,
  MeetingKO,
  ScheduleControl,
  TeamRevision,
  TeamRevisionForScope,
  ThoughtsOnQuality,
  StandardsReview
} from "../index";



const EmailBody = (props) => {

  
// Function to change the email info according to the productId
    
  if (props.row.Body === "" || props.row.IsRead === false ) {
    return <span style={{ color: "lightgray" }}>&nbsp;</span>;
  }
  if (props.productId !== 50) {
    return <div>{ReactHtmlParser(props.row.Body)}</div>
  }
  if (props.row.TriggerName === "PACCISS_intro_memo") {
  return <IntroMemo />;
  }
  if (props.row.TriggerName === "PACCISS_MN_KO") {
     return <MeetingKO />;
  }

  if (props.row.TriggerName === "PACCISS_Schedule_Control") {
  return <ScheduleControl />;
  }
  if (props.row.TriggerName === "PACCISS_Standards_Review") {
  return <StandardsReview />;
  }
  if (props.row.TriggerName === "PACCISS_Team_Revision_For_Scope") {
   return <TeamRevisionForScope />;
  }
  if (props.row.TriggerName === "PACCISS_Thoughts_On_Quality") {
      return <ThoughtsOnQuality />;
  
  }
  if (props.row.TriggerName === "PACCISS_Team_Revision") {
   return <TeamRevision />;
  }

  return (
     <span style={{ color: "lightgray" }}>Empty</span>
   )
    
  };

export default EmailBody;
