// routes/Classroom/index.js

import React from "react";
import { connect } from "react-redux";

import { placeInClassroom } from "redux/Authentication/thunks"; // function to put student in class
import { getUniqueInstructors } from "redux/Authentication/selectors";
import { ClassroomDisplay } from "./components/ClassroomDisplay";
import ClassroomAssignment from "./components/ClassroomAssignment";

import "./Classroom.css";

const Classroom = props => {
  if (!(props && props.studentInfo)) {
      return (
        <div>
          <span>Student information not available</span>
        </div>
      );
    }
  return (

    <div style={{height: "420px"}}>
      <ClassroomDisplay
        instructorName={props.studentInfo.InstructorName}
        classroomName={props.studentInfo.ClassroomName}
      />
      <br />
      <br />
      <ClassroomAssignment
        studentInfo={props.studentInfo}
        runDetails={props.runDetails}
        availableClasses={props.availableClasses}
        uniqueInstructors={props.uniqueInstructors}
        placeInClassroom={props.placeInClassroom}
      />
    </div>
  );
};
/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapStateToProps = state => ({
  auth: state.auth,
  studentInfo: state.auth.studentInfo,
  runDetails: state.auth.runDetails,
  availableClasses: state.auth.availableClasses,
  uniqueInstructors: getUniqueInstructors(state)
});

const mapDispatchToProps = dispatch => ({
  placeInClassroom: (classStudentDBId, classId, runDBIdList) =>
    dispatch(placeInClassroom(classStudentDBId, classId, runDBIdList))
});

export const ClassroomContainer = connect(mapStateToProps, mapDispatchToProps)(
  Classroom
);
