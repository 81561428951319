/* eslint-disable no-return-assign */
import React from 'react';
import { connect } from 'react-redux';
import { insertRecognition, updateRecognition } from 'redux/Interaction/thunks';
import {
  getOnProjectWorkers,
  getIndividualRecognition,
  getTeamRecognition
} from 'redux/Project/selectors';
// ;
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { closeModal } from 'redux/Modal/ModalModule';
import { addSingleAlert } from 'redux/Notification/NotificationModule';

class RecognitionInteraction extends React.Component {
 state = {
      // workerId: this.props.onProjectWorkers[0].id,
      // isTeamRecognition: false,
      currentRecognitionItem: this.props.recognitionItem,
      recognitionList:
        this.props.recognitionType === 'TEAM'
          ? this.props.teamRecognitionList
          : this.props.individualRecognitionList
    };
  

  handleRecognitionChange = event => {
    event.preventDefault();

    const newRecognitionId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentRecognitionItem);
    item.RewardId = newRecognitionId;
    item.RewardType = this.props.recognitionType;
    const recs = this.state.recognitionList.filter(
      rec => rec.id === item.RewardId
    );

    if (recs !== undefined && recs.length > 0) {
      item.RewardName = recs[0].recognition;
    } else {
      item.RewardName = 'None';
    }
    this.setState({
      currentRecognitionItem: item
    });
  };

  handleWorkerChange = event => {
    event.preventDefault();
    const newWorkerId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentRecognitionItem);
    item.WorkerId = newWorkerId;
    item.WorkerName = this.props.onProjectWorkers.find(
      c => c.id === newWorkerId
    ).name;
    this.setState({
      currentRecognitionItem: item,
      workerId: newWorkerId
    });
  };

  handleDayChange = event => {
    event.preventDefault();

    const newDay = event.target.value;

    const item = Object.assign({}, this.state.currentRecognitionItem);
    item.DayOfWeek = newDay;

    this.setState({
      currentRecognitionItem: item
    });
  };

  saveRecognition = event => {
    event.preventDefault();

    const re = Object.assign({}, this.state.currentRecognitionItem);
    re.RunId = this.props.runId;
    if (re.WorkerId === 0 && re.RewardType === 'INDV') {
      re.WorkerId = this.props.onProjectWorkers[0].id;

      const ws = this.props.onProjectWorkers.filter(
        item => item.id === re.WorkerId
      );

      re.WorkerName = ws[0].name;
    } else if (re.RewardType === 'TEAM') {
      re.WorkerName = 'TEAM';
    }

    this.setState({
      currentRecognitionItem: re
    });

    if (this.props.isAdd) {
      this.props.insertRecognition(re);
      const alert = {
        id: 15,
        type: 'success',
        headline: 'Recognition Scheduled',
        event: 'interaction',
        message:
          `${re.WorkerName 
          } is scheduled to receive ${ 
          re.RewardName 
          } on ${ 
          re.DayOfWeek}`
      };
      const notificationData = {
        info:
          `${re.WorkerName 
          } is scheduled to receive ${ 
          re.RewardName 
          } on ${ 
          re.DayOfWeek}`,
        worker: '',
        week: this.props.displayProjectWeek
      };
      this.props.addAlert(alert, notificationData, 3000, true);
    } else {
      this.props.updateRecognition(this.state.currentRecognitionItem);

      const alert = {
        id: 15,
        type: 'success',
        headline: 'Recognition Updated',
        event: 'interaction',
        message:
          `${re.WorkerName 
          } is scheduled to receive ${ 
          re.RewardName 
          } on ${ 
          re.DayOfWeek}`
      };
      const notificationData = {
        info:
          `${re.WorkerName 
          } is scheduled to receive ${ 
          re.RewardName 
          } on ${ 
          re.DayOfWeek}`,
        worker: '',
        week: this.props.displayProjectWeek
      };
      this.props.addAlert(alert, notificationData, 3000, true);
    }
    // Close the modal on save
    // this.props.dismiss();
  };

  render() {
    let iterKey = 1;
    return (
      <div>
        <Form className='form-split'>
           <FormGroup>
            <Label for="recognition">Choose Recognition</Label>
            <Input
              type="select"
              placeholder='recognition'
              value={this.state.currentRecognitionItem.RewardId}
              onChange={(event) => this.handleRecognitionChange(event)}
            >
              {this.state.recognitionList.map((row, index) => (
                <option key={iterKey += 1} value={row.id}>
                  {row.recognition}
                </option>
              ))}
            </Input>
          </FormGroup>
          {this.props.recognitionType === 'INDV' ? (
              <FormGroup>
              <Label for="worker">Choose Worker</Label>
              <Input
                 type="select"
                placeholder='worker'
                value={this.state.currentRecognitionItem.WorkerId}
                onChange={(event) => this.handleWorkerChange(event)}
              >
                {this.props.onProjectWorkers.map((row, index) => (
                  <option
                    key={iterKey += 1}
                    value={row.id}
                    data-workername={row.name}
                  >
                    {row.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : (
            ' '
          )}
            <FormGroup>
            <Label for="day">Choose Day</Label>
            <Input
               type="select"
              placeholder='day'
              value={this.state.currentRecognitionItem.DayOfWeek}
              onChange={(event) => this.handleDayChange(event)}
            >
              {this.props.days.map(
                (day, index) =>
                  day !== 'Sunday' && day !== 'Saturday' ? (
                    <option key={iterKey += 1} value={day}>
                      {day}
                    </option>
                  ) : (
                    ' '
                  )
              )}
            </Input>
          </FormGroup>
          <footer>
            <Button
              color="primary"
              className='btn btn-primary'
              type='button'
              onClick={(event) => this.saveRecognition(event)}
            >
              Save
            </Button>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    recognitionList: state.project.rewardList,
    individualRecognitionList: getIndividualRecognition(state),
    teamRecognitionList: getTeamRecognition(state),
    onProjectWorkers: getOnProjectWorkers(state),
    days: state.project.daysOfWeek,
    runId: state.project.runId,
    displayProjectWeek: state.project.displayProjectWeek
  });

const mapDispatchToProps = dispatch => ({
    insertRecognition: recognitionItem =>
      dispatch(insertRecognition(recognitionItem)),
    updateRecognition: recognitionItem =>
      dispatch(updateRecognition(recognitionItem)),
    dismiss: () => dispatch(closeModal()),
    addAlert: (alert, notificationData, timeout, shouldPersist) =>
      dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
  });

export const RecognitionInteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecognitionInteraction);
