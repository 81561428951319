/* @flow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, forgotPassword } from 'redux/Authentication/thunks';
import { NOTIFICATION_MODAL } from 'routes/Modal/ModalTypes';
import { openModal } from 'redux/Modal/ModalModule';
import { clearAlerts } from 'redux/Notification/NotificationModule';

import { Button, InputGroupAddon, Input, InputGroup, Form} from 'reactstrap';

import 'routes/Login/Login.css';
export class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordAnswer: '',
			username: '',
			foundUser: false,
      loginError: '',
      type: 'password',
			submitButtonDisabled: true
		};
		this.showHide = this.showHide.bind(this);
		this.handleUsernameSearch = this.handleUsernameSearch.bind(this);
		this.handlePasswordAnswerChange = this.handlePasswordAnswerChange.bind(this);
		this.handleRequestPassword = this.handleRequestPassword.bind(this);
	}

	componentDidMount() {
		if (this.state.foundUser === true) {
			if (this.props.auth.message === 'Incorrect password answer.' || this.props.auth.message.includes('locked')) {
				this.setState({
					loginError: this.props.auth.message
				});
			} else if (this.props.auth.message.includes('temporary password sent; please check your email')) {
				this.props.showEmailNotification();
			}
		}
	}

  showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		});
  }
  
	handleResetPassword = (event) => {
		event.preventDefault();
		this.props.forgotPassword(this.state.username, this.state.passwordAnswer);
	};


	handleUsernameSearch() {
    if  (this.usernameInput.value === null 
      || this.usernameInput.value === undefined 
      || this.usernameInput.value === '') {
       
      this.setState({
        foundUser: false,
        submitDisabled: false,
        loginError: 'Username cannot be blank!'
      });
		} else {
		Promise.all([ this.props.getUser(this.usernameInput.value) ]).then(() => {
			if (this.props.auth.status === 'error') {
				this.setState({
					loginError: this.props.auth.message
				});
			} else {
				this.setState({
					foundUser: true,
					loginError: ''
				});
			}
    });
  }
	}

	handlePasswordAnswerChange() {
    this.props.clearAlerts();
  
    let submitDisabled = false;
    let errors = ""
    if (this.passwordAnswerInput.value === null 
      || this.passwordAnswerInput.value === undefined 
      || this.passwordAnswerInput.value === '') {
      submitDisabled = true;
      errors = "Security answer cannot be blank!"
		}
		this.setState({
			passwordAnswer: this.passwordAnswerInput.value,
      submitButtonDisabled: submitDisabled,
      loginError: errors
		});
	}

	handleRequestPassword() {
		this.props.clearAlerts();
		this.props.forgotPassword(this.usernameInput.value, this.passwordAnswerInput.value);
	}


	render() {
		return (
			<div className="middle-box animated fadeInDown">
				<p>
					Enter your username and press the search <i className="fa fa-search" /> button.
          <br />&nbsp;&nbsp;&nbsp;If we find your
					username, your security question will be displayed.  
          <br />Enter the correct answer and 
          press the <i className="fa fa-check" /> button.  
          <br />&nbsp;&nbsp;&nbsp;If you have answered the question, 
          the request for new
					password button will become active; 
          click it to receive the password reset email.
				</p>
				<p>
					If you have any issues, contact support at
					<a href="mailto:support@simulationpl.com">support@simulationpl.com</a>.
				</p>
				<p style={{ color: 'red' }}>{this.state.loginError}</p>

				<Form className="m-t">
					<InputGroup className="mb-3">
					
						<InputGroupAddon addonType="prepend" style={{'marginRight': '5px', 'marginTop': '5px'}}>
							<i className="fa fa-user" />
						</InputGroupAddon>
						<Input
							type="text"
							name="username"
							placeholder="Username"
							innerRef={(ef) => (this.usernameInput = ef)}
							required
						/>
						<InputGroupAddon addontype="append">
							<Button onClick={this.handleUsernameSearch}>
								<i className="fa fa-search" />
							</Button>
						</InputGroupAddon>
					</InputGroup>

					<div className="form-group" disabled={this.state.foundUser === false}>
						{this.state.foundUser === true ? (
              <div>
               <p>
               {this.props.auth.user.PasswordQuestion}
             </p>
              <InputGroup>
               
								

									<InputGroupAddon addonType="prepend" style={{'marginRight': '5px', 'marginTop': '5px'}}>
										<i className="fa fa-lock" />
									</InputGroupAddon>

									<Input
										type={this.state.type}
										placeholder="security response"
										name="password"
										className="password__input"
										innerRef={(pf) => (this.passwordAnswerInput = pf)}
									/>
                  <InputGroupAddon addontype='append'>
										
									<span className="password__show" onClick={this.showHide}>
										{this.state.type === 'password' ? (
											<i className="fa fa-eye" />
										) : (
											<i className="fa fa-eye-slash" />
										)}
									</span>

									</InputGroupAddon>
                  <InputGroupAddon addontype="append">
							<Button onClick={this.handlePasswordAnswerChange}>
								<i className="fa fa-check" />
							</Button>
						</InputGroupAddon>
								</InputGroup>
                </div>
						) : (
							<span>Your security question will be displayed here once we find your username</span>
						)}
					</div>
					<button
						type="button"
						disabled={this.state.submitButtonDisabled}
						className="btn btn-primary block full-width m-b"
						onClick={this.handleRequestPassword}
					>
						Send me a new temporary password
					</button>
				
			
				</Form>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
	getUser: (username) => dispatch(getUser(username)),
	clearAlerts: () => dispatch(clearAlerts()),
	forgotPassword: (username, passwordAnswer) => dispatch(forgotPassword(username, passwordAnswer)),
	showEmailNotification: () =>
		dispatch(openModal(NOTIFICATION_MODAL, 'Check your email for your new password', 'modal-medium'))
});

export const ForgotPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
