import React from "react";

export const Meeting = props => {
  const timeFrame = props.properties.duration < 2 ? "hour" : "hours";

  return (
    <div className="content">
      <h5>Meeting</h5>
      <p>
        A meeting was held to discuss {props.properties.topic}. The meeting
        lasted {props.properties.duration} {timeFrame} and the feedback provided
        was {props.properties.feedback}.
      </p>
    </div>
  );
};
