import checklist from "./checklist.png"
import checklistMouseover from "./checklistMouseover.png"
import expiredAccountErrorMessage from "./expiredAccountErrorMessage.png"
import ar from "./ar.png"
import ar2 from "./ar2.png"
import ar3 from "./ar3.png"
import p1 from "./p1.png"
import p2 from "./p2.png"
import gantt from "./gantt.png"
import interaction from "./interaction.png"
import WorkWeek from "./WorkWeek.png"
import WorkWeekStatusPanel from "./WorkWeekStatusPanel.png"
import StaffingPlan from "./StaffingPlan.png"
import CheckFailed from "./CheckFailed.png"
import CheckPassed from "./CheckPassed.png"
import CheckStaffingButton from "./CheckStaffingButton.png"
import StaffingDecisions from "./StaffingDecisions.png"
import task from "./task.png"
import rp1 from "./rp1.png"
import is1 from "./is1.png"
import is2 from "./is2.png"
import is3 from "./is3.png"
import AddEditRisk from "./AddEditRisk.png"
import AddEditTraining from "./AddEditTraining.png"
import AddNewRiskButton from "./AddNewRiskButton.png"
import AddNewTrainingButton from "./AddNewTrainingButton.png"
import BarGanttChartPartial from "./BarGanttChartPartial.png"
import BuildStakeholderPlan from "./BuildStakeholderPlan.png"
import CreatePDFsCharts from "./CreatePDFsCharts.png"
import CreatePDFsWeeklyReports from "./CreatePDFsWeeklyReports.png"
import EarnedValueChart from "./EarnedValueChart.png"
import EditDeleteIcons from "./EditDeleteIcons.png"
import EditDeleteIconsSmall from "./EditDeleteIconsSmall.png"
import EditStakeholderPlan from "./EditStakeholderPlan.png"
import EditStakeholderCell from "./EditStakeholderCell.png"
import EstimatedCostatCompletionChart from "./EstimatedCostatCompletionChart.png"
import EstimatedProjectFinishChart from "./EstimatedProjectFinishChart.png"
import GanttChart from "./GanttChart.png"
import LaborCostSummaryTable from "./LaborCostSummaryTable.png"
import ResourceTask1  from "./ResourceTask1.png"
import NonTask  from "./NonTask.png"
import ResourceHours from "./ResourceHours.png"
import TaskRelated from "./TaskRelated.png"
import NetworkDiagram from "./NetworkDiagram.png"
import PPIChart from "./PPIChart.png"
import ProjectReferenceIconNavigationbar from "./ProjectReferenceIconNavigationbar.png"
import QualityChart from "./QualityChart.png"
import RecognitionOptionsCosts from "./RecognitionOptionsCosts.png"
import ReportProjectStatusReport from "./ReportProjectStatusReport.png"
import ReportTaskHoursReport from "./ReportTaskHoursReport.png"
import ReportTaskStatusReport from "./ReportTaskStatusReport.png"
import ReportsInitialTaskAssignment from "./ReportsInitialTaskAssignment.png"
import ReportsWeeklyResourceHours from "./ReportsWeeklyResourceHours.png"
import ReportsWorkerAvailabilityReport from "./ReportsWorkerAvailabilityReport.png"
import ReserveCost from "./ReserveCost.png"
import ReportsMenuButton from "./ReportsMenuButton.png"
import ReportsMenu from "./ReportsMenu.png"
import RequestBudgetApprovalButton from "./RequestBudgetApprovalButton.png"
import RunPlanCheckButton from "./RunPlanCheckButton.png"
import RunPlanCheckResults from "./RunPlanCheckResults.png"
import RunWeekConditionsPopupExample from "./RunWeekConditionsPopupExample.png"
import StaffingResourcePlanAddResource from "./StaffingResourcePlanAddResource.png"
import StaffingResourcePlanTaskDropdown from "./StaffingResourcePlanTaskDropdown.png"
import StakeholderClearIcon from "./StakeholderClearIcon.png"
import StakeholderOptionsCostsInfoPanel from "./StakeholderOptionsCostsInfoPanel.png"
import StakeholderReportIcon from "./StakeholderReportIcon.png"
import StartRunWeekButton from "./StartRunWeekButton.png"
import StartWeekButtonSmall from "./StartWeekButtonSmall.png"
import SubmitVoucherButton from "./SubmitVoucherButton.png"
import TaskDropdownRelease from "./TaskDropdownRelease.png"
import TaskDropdownVacation from "./TaskDropdownVacation.png"
import TaskDropdown from "./TaskDropdown.png"
import TrashCanIcon from "./TrashCanIcon.png"
import ViewFullBudgetButton from "./ViewFullBudgetButton.png"
import WeeklyStaffingShiftIcon from "./WeeklyStaffingShiftIcon.png"
import WeeklyStaffingAssignment from "./WeeklyStaffingAssignment.png"
import help1 from "./help1.png"
import help2 from "./help2.jpeg"
import help3 from "./help3.jpeg"
import help4 from "./help4.jpeg"
import help5 from "./help5.jpeg"
import help6 from "./help6.png"
import help7 from "./help7.png"
import help8 from "./help8.png"
import help9 from "./help9.png"


 const urls = {
checklist,
checklistMouseover,
expiredAccountErrorMessage,
ar,
ar2,
ar3,
is1,
is2,
is3,
p1,
p2,
gantt,
interaction,
WorkWeek,
WorkWeekStatusPanel,
StaffingPlan,
CheckFailed,
CheckPassed,
CheckStaffingButton,
StaffingDecisions,
task,
rp1,
AddEditRisk,
AddEditTraining,
AddNewRiskButton,
AddNewTrainingButton,
BarGanttChartPartial,
BuildStakeholderPlan,
CreatePDFsCharts,
CreatePDFsWeeklyReports,
EarnedValueChart,
EditDeleteIcons,
EditDeleteIconsSmall,
EditStakeholderPlan,
EditStakeholderCell,
EstimatedCostatCompletionChart,
EstimatedProjectFinishChart,
GanttChart,
LaborCostSummaryTable,
NetworkDiagram,
PPIChart,
ProjectReferenceIconNavigationbar,
QualityChart,
RecognitionOptionsCosts,
ReportProjectStatusReport,
ReportTaskHoursReport,
ReportTaskStatusReport,
ReportsInitialTaskAssignment,
ReportsWeeklyResourceHours,
ReportsWorkerAvailabilityReport,
ReportsMenuButton,
ReportsMenu,
ResourceTask1,
ReserveCost,
NonTask,
ResourceHours,
TaskRelated,
RequestBudgetApprovalButton,
RunPlanCheckButton,
RunPlanCheckResults,
RunWeekConditionsPopupExample,
StaffingResourcePlanAddResource,
StaffingResourcePlanTaskDropdown,
StakeholderClearIcon,
StakeholderOptionsCostsInfoPanel,
StakeholderReportIcon,
StartRunWeekButton,
StartWeekButtonSmall,
SubmitVoucherButton,
TaskDropdownRelease,
TaskDropdownVacation,
TaskDropdown,
TrashCanIcon,
ViewFullBudgetButton,
WeeklyStaffingShiftIcon,
WeeklyStaffingAssignment,
help1,
help2,
help3,
help4,
help5,
help6,
help7,
help8,
help9
}

export default urls
