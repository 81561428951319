import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
const imgUrl1 = require("static/images/org_chart_new_cropped.jpg").default;

export const OrgChartBiss = (productId) => {
  return (
    <>
       <div className="organisation-chart style-background-components">
       <img src={imgUrl1} alt="orgchart" width="100%" height="100%" style={{ maxWidth: '72%', height: 'auto' }} />
       </div>
    </>
  );
};
