import React from "react";
import imageURLs from "./images/imageURLs.js";

const EarnedValueHelp = () => (
  <div>
    <h3>Earned Value Chart Info</h3>
    <h4>Context for Earned Value:</h4>
    <p>
      An earned value management system (EVMS) includes a planned value plan, actual project costs you have expended, and a measure of work completed (earned value) for each week of the project. The planned value line shows how you plan to spend your budget over the life of the project. The actual costs and earned value are plotted each week as they are calculated at the end of the week (see history report for these weekly values).

    </p>

    <h4>Using Earned Value:</h4>

    <p>
      Use the EVMS as a trend chart to help you manage the project. If the earned value is trending up, that is good. An earned value line that is trending down is a bad indicator. If the actual cost is trending down that is good. An actual cost line that is trending up is a bad indicator.
    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.EarnedValueChart}
      alt="report"
    />
  </div>
);

export default EarnedValueHelp;
