// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_STUDENTS_ERROR = "FETCH_STUDENTS_ERROR";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";

export const RECEIVE_STUDENT_INFO = "RECEIVE_STUDENT_INFO";

export const FETCH_INSTRUCTORCLASSES_ERROR = "FETCH_INSTRUCTORCLASSES_ERROR";
export const FETCH_INSTRUCTORCLASSES_SUCCESS =
  "FETCH_INSTRUCTORCLASSES_SUCCESS";
  
  export const FETCH_INSTRUCTORS_ERROR = "FETCH_INSTRUCTORS_ERROR";
export const FETCH_INSTRUCTORS_SUCCESS =
  "FETCH_INSTRUCTORS_SUCCESS";

export const ADD_SECONDARY_SUCCESS = "ADD_SECONDARY_SUCCESS";
export const ADD_SECONDARY_ERROR = "ADD_SECONDARY_ERROR";
export const REMOVE_SECONDARY_SUCCESS = "REMOVE_SECONDARY_SUCCESS";
export const REMOVE_SECONDARY_ERROR = "REMOVE_SECONDARY_ERROR";
export const CHANGE_PRIMARY_SUCCESS = "CHANGE_PRIMARY_SUCCESS";
export const CHANGE_PRIMARY_ERROR = "CHANGE_PRIMARY_ERROR";

export const ADD_CLASSROOM_SUCCESS = "ADD_CLASSROOM_SUCCESS";
export const ADD_CLASSROOM_ERROR = "ADD_CLASSROOM_ERROR";

export const INACTIVATE_CLASSROOM_ERROR = "INACTIVATE_CLASSROOM_ERROR";
export const INACTIVATE_CLASSROOM_SUCCESS = "INACTIVATE_CLASSROOM_SUCCESS";

export const FETCH_CLASSRUNS_ERROR = "FETCH_CLASSRUNS_ERROR";
export const FETCH_CLASSRUNS_SUCCESS = "FETCH_CLASSRUNS_SUCCESS";

export const FETCH_STOPWEEKS_ERROR = "FETCH_STOPWEEKS_ERROR";
export const FETCH_STOPWEEKS_SUCCESS = "FETCH_STOPWEEKS_SUCCESS";
export const SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT";

export const SET_SELECTED_CLASS = "SET_SELECTED_CLASS";
export const SET_STUDENT_INVALIDLICENSE = "SET_STUDENT_INVALIDLICENSE";

export const UPDATE_CLASSROOM_SUCCESS = "UPDATE_CLASSROOM_SUCCESS";
export const UPDATE_CLASSROOM_ERROR = "UPDATE_CLASSROOM_ERROR";

export const UPDATE_CLASSRUNS_SUCCESS = "UPDATE_CLASSRUNS_SUCCESS";
export const UPDATE_CLASSRUNS_ERROR = "UPDATE_CLASSRUNS_ERROR";

export const UPDATE_STOPWEEKS_SUCCESS = "UPDATE_STOPWEEKS_SUCCESS";
export const UPDATE_STOPWEEKS_ERROR = "UPDATE_STOPWEEKS_ERROR";
export const TOGGLE_ALLOW_TO_RUN = "TOGGLE_ALLOW_TO_RUN"
export const TOGGLE_ALLOW_TO_PLAN = "TOGGLE_ALLOW_TO_PLAN"
export const TOGGLE_STOP_WEEK = "TOGGLE_STOP_WEEK"
export const UPDATE_STOP_MESSAGE = "UPDATE_STOP_MESSAGE"
export const SET_LOADING = "SET_LOADING";

export const SET_EXECSEQ_FILTER_VALUE="SET_EXECSEQ_FILTER_VALUE";
