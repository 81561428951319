import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

const InstructorGuideDownloadLink = () => (
  <Button tag="a" href="/docs/SimProjectInstructorGuide.pdf" download>
    <FontAwesomeIcon icon={faFilePdf} /> Instructor Guide
  </Button>
);

export default InstructorGuideDownloadLink;
