import React from "react";
import { Card, CardBody, Label, Input,Collapse} from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import InfoFooter from "components/Info/InfoFooter";


const RecognitionInfo = props => {
  return (
  <Card>
    <CardHeaderBold>
                <span className="float-left">Recognition Info</span>
        
       
        <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggle()}
          defaultChecked={props.panelVisible}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
   
      
       

       
     
    </CardHeaderBold>
    <Collapse isOpen={props.panelVisible}>
      <CardBody>
        <h5>Context and resources for recognition planning</h5>
        <p>
          Create a Recognition Plan to reward the team and/or individual team members as you execute your project.
   </p>
        <h5>Building your plan</h5>
        <p>
          Estimate the specific recognition rewards (adding the costs by hand) you anticipate using in determining your recognition budget, or just estimate a budget amount to create a recognition budget for use as needed during the execution of your project. Enter the amount that you wish to use for your planned recognition budget. Note that you must click on ‘Submit Voucher’ to update the project budget.

			</p>
        <h5>Editing your plan</h5>
        <p>
          Enter the new amount that you wish to use for your planned recognition budget. Note that you must click on ‘Submit Voucher’ to update the project budget. Be aware that once you get your project plan approved you will not have the ability to update your Recognition Budget.
			</p>
      </CardBody>
      <InfoFooter selectedKey="recognition" />
     
    </Collapse>
    </Card>
    )
};

export default RecognitionInfo;
