import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
font-size: 10px;
color: red;
margin-right: 10px;
`;



const ConditionDebug = props => {

  return (
      <div className="report-Item">

       <StyledSpan><strong> Condition: </strong>  {props.properties.condId}</StyledSpan>
       <StyledSpan><strong>Day fired: </strong> {props.properties.firedDay}</StyledSpan>
       <StyledSpan><strong> Silent? :</strong>  {props.properties.isSilent === "1" ? "true" : "false"}</StyledSpan>

       <StyledSpan><strong> Active Entity : </strong> {props.properties.entity}</StyledSpan>
       <StyledSpan><strong>Avatar : </strong> {props.properties.avatar}</StyledSpan>

       <StyledSpan><strong>  Trigger : </strong> {props.properties.trigger}</StyledSpan>


       </div>
  );
};

export default ConditionDebug
