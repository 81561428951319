import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody } from "reactstrap";
import { clearAlerts } from "redux/Notification/NotificationModule";
import {
  toggleTourComponentOpen,
  toggleTourComponentClosed
} from "app/redux/thunks";
import TabbedReports from "routes/Report/components/TabbedReports";
import  BudgetContainer from "routes/Budget/components/BudgetContainer";
import CardHeaderBold from "components/styled/CardHeaderBold";


class Overview extends React.Component {
  startTheTour = () => {
    const config = [
      {
        selector: "[tourstop='navbar']",
        content:
          "Above is the Navigation Bar which contains quick links to see your Notification History, Email, Project Reference Guide, Help Documentation, Stakeholder Orientation Guide and ability to turn on/off the Project Checklist.",
        position: "bottom",
        style: {
          padding: "30px"
        }
      },
      {
        selector: "[tourstop='pageHeader']",
        content:
          "Just below the navigation bar is the Page Header.  It lets you know where you are in the application and provides some project status information. ",
        style: {
          padding: "30px"
        },
        action: () => {
          if (document.body.classList.contains("sidebar-minimized")) {
            document.body.classList.toggle("sidebar-minimized");
          }
        }
      },
      {
        selector: "[tourstop='sidebar']",
        content:
          "To the left is the sidebar menu, use it to navigate quickly around the simulation project",
        style: {
          padding: "30px",
          marginTop: "60px"
        },
        position: "right"
      },
      {
        selector: "[tourstop='budget']",
        content:
          "This is the budget. It tells you how much you have planned and how much you have spent on each Task or Category. You can click on individual items for more detail.",
        position: "left",
        style: {
          padding: "30px"
        }
      },
      {
        selector: "[tourstop='nd']",
        content:
          " This panel includes the Gantt Chart and Network Diagram. You need this information to help you plan and execute your project. The panel is available on most screens.",
        style: {
          padding: "30px"
        }
      },
      {
        selector: "[tourstop='gantt']",
        content:
          "The Gantt or bar chart provides the project time table. It shows the early start and finish dates for each task. The red tasks indicate your critical path.",
        style: {
          padding: "30px"
        }
      },
      {
        selector: "[tourstop='nd']",
        content:
          "The Network Diagram provides the execution sequence of your tasks. The tasks are represented by the nodes and the lines between the nodes represent the task dependencies or relationships. All the dependencies in the network are finish to start relationships. ",
        style: {
          padding: "30px"
        },
        action: () => {
          this.child.toggle("2");
        }
      },
      {
        selector: "[tourstop='nd']",
        content:
          "Detailed information on each task is available on the Gantt Chart or Network Diagram by clicking on the task name. Click on task name now to see the detailed task information available.",
        style: {
          padding: "30px"
        },
        action: () => {
          this.child.toggle("1");
        }
      },
      {
        selector: "[tourstop='cl1']",
        content: "The Checklist icon opens/closes the checklist. ",
        style: {
          padding: "30px"
        },
        action: () => {
          if (document.body.classList.toggle("aside-menu-hidden")) {
            document.body.classList.toggle("aside-menu-hidden");
          }
        }
      },
      {
        selector: "[tourstop='aside']",
        content:
          " Use the checklist to guide you through the planning and execution steps in completing your project plan as well as the iterative steps in the execution of your project one work week at at time.",
        style: {
          padding: "30px"
        }
      }
    ];
    this.props.startTour(true, config);
  };

  componentDidMount = () => {
    this.props.clearAlerts();
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
  };

  stopTheTour = () => {
    if (document.body.classList.toggle("aside-menu-hidden")) {
      document.body.classList.toggle("aside-menu-hidden");
    }
    this.props.closeTour();
    this.child.toggle("1");
  };

  // customStyle = (cell, row) => ({
  //   backgroundColor: "red"
  // });

  render() {
    return (
      <div>
        <Row>
          <Col md="4">
            <Card>
              <CardHeaderBold>Take the Tour</CardHeaderBold>
              <CardBody>
                <p>
                  There is a lot going on here. Let&apos;s get you acquainted
                  with the dashboard.
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.startTheTour()}
                >
                  Take the tour
                </button>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card tourstop="budget">
              <CardHeaderBold>Budget</CardHeaderBold>
              <CardBody>
                <BudgetContainer />
              </CardBody>
            </Card>
          </Col>
        </Row>
       
        <TabbedReports
          ref={instance => {
            this.child = instance;
          }}
          open
        />
        {/* <Row>
          <Col>
            <Card>
              <CardHeader>Gantt Chart</CardHeader>
              <CardBody>
                <GanttChartContainer />
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  project: state.project,
  newProjectStarted: state.project.newProjectStarted
});

const mapDispatchToProps = dispatch => ({
  startTour: (toggleState, tc) =>
    dispatch(toggleTourComponentOpen(toggleState, tc)),
  stopTour: () => dispatch(toggleTourComponentClosed()),
  clearAlerts: () => dispatch(clearAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
