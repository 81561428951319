import React from "react";

export const DelayedTask = props => {
  return (
    <div className="content" id="delayed-task">
      <h3>
        {" "}
        <i className="fa fa-exclamation" />
        Delayed Task
      </h3>
      <p>{props.properties.reason}</p>
    </div>
  );
};
