import React from "react";
import ResourceImage from "components/ResourceImage";
import UpdatingFooter from "components/UpdatingFooter";
import { Button, ButtonGroup } from "reactstrap";

export default class TrainingTable extends React.Component {
  state = {
    selectedRows: [],
    updating: false
  };

  componentDidMount() {
    if (this.props.modalOpen === false) {
      this.setState({ updating: false });
    } else {
      this.setState({ updating: true });
    }
  }

  deleteItem = (index, row, runId) => {
    this.setState({ updating: true });
     this.props.deleteTraining(index, row.ID, runId, row).then(() => {
      this.setState({ updating: false });
     })

  };

  editItem = row => {
    this.setState({ updating: true });
    this.props.updateTraining(row);
  };

  addItem = () => {
    this.setState({ updating: true });
    this.props.showAddTrainingModal();
  };

  render() {
    let iterKey = 1;
    return (
      <div className="box-content">
        <div className="component-Table">
          {/* Training table style in _tables.scss */}
          <table
            className="table table-bordered table-hover"
            id="training-table"
          >
            <thead>
              <tr>
                <th>Course</th>
                <th>Project Week</th>
                <th>Resource</th>
                {/* <th>Is Team Training?</th> */}
                <th className="text-right table-RowAction">
                   Edit Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.trainings
                .sort((a, b) => a.WeekNumber - b.WeekNumber)
                .map((row, index) => (
                  <tr key={(iterKey += 1)}>
                    <td className="min300">{row.ClassName}</td>
                    <td className="text-center">{row.WeekNumber}</td>
                    <td>
                      <div className="resource-Item">
                        <div className="resource-Profile">
                          <ResourceImage
                            png={`${row.WorkerName.replace(/\s+/g, "")}.png`}
                            alt={row.WorkerName}
                          />
                        </div>
                        <div className="resource-Body">
                          <p>{row.WorkerName}</p>
                        </div>
                      </div>
                    </td>
                    {/* <td>
                    {row.TeamTraining === true ? "Yes" : "No"}
                  </td> */}
                    <td className="text-right table-RowAction">
                      <ButtonGroup>
                        <Button
                          color="secondary"
                          onClick={this.editItem.bind(this, row)}
                        >
                          <i className="fa fa-pencil" />
                        </Button>
                        <Button
                          color="danger"
                          onClick={this.deleteItem.bind(
                            this,
                            index,
                            row,
                            this.props.runId
                          )}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <footer>

            <UpdatingFooter updating={this.state.updating} />
          </footer>
        </div>
      </div>
    );
  }
}
