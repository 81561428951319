import React from "react";
import { Card, CardBody } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import BudgetContainer from "./components/BudgetContainer";
import { connect } from "react-redux";
import {
  getEnhancedBudgetList
} from "redux/Project/selectors";
// import CSVExporter from "routes/Resource/components/CSVExporter";

import { CSVLink } from "react-csv";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Budget = (props) => {
  debugger;
  return (
    <Card>
      <CardHeaderBold>
        <div className="text-left">
          Budget
        </div>
        <div className="text-right">
          <CSVLink data={props.enhancedBudgetList} separator="," filename={`Budget`}>
            <Button color="primary">   <FontAwesomeIcon icon={faDownload} /> Save as CSV</Button>
          </CSVLink>
        </div>
      </CardHeaderBold>
      <CardBody>
        <div style={{ border: "1px solid lightgrey" }}>
          <BudgetContainer />
        </div>
      </CardBody>
    </Card>
  );
};

// export default Budget;

const mapStateToProps = state => ({
  enhancedBudgetList: getEnhancedBudgetList(state),

});

export default connect(
  mapStateToProps
)(Budget);
