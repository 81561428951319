import { clearAlerts } from "redux/Notification/NotificationModule";
// import get from "lodash/get"


export const parseError = (e) => {
 let error = e
  if (e.problem === "NETWORK_ERROR" || e.problem === "SERVER_ERROR")
    error = "Backend server is down."
  return error
}


export const processFailToFetch = (
  error,
  state,
  dispatch,
  addSingleAlert,
  fetchEvent
) => {
 dispatch(clearAlerts());

 if (error.name.substr(0, 11) === "SyntaxError") {
   const alert = {
     id: 44,
     type: "danger",
     headline: "Error",
     event: fetchEvent,
     message:
       `The is server indicated there is a syntax error in the data.  Please send a screenshot of this to support : ${error}`
   };
   const notificationData = {
     info: `userId: ${state.auth.userId} The is server indicated there is a syntax error in the data.
     Please send a screenshot of this to support : ${error}`,
     worker: state.auth.user.UserName,
     week: 0
   };
   dispatch(addSingleAlert(alert, notificationData, 0, true));
   return false
 }
  
    const alert = {
      id: 100,
      type: "warning",
      event: fetchEvent,
      headline: error.name,
      message: `We cannot reach the backend server at this moment from your machine.  Please check your network traffic and availability. ${error.message}`
    };
    const notificationData = {
      info: `We cannot reach the backend server at this moment from your machine.  ${error.message}`,
      worker: state.auth.user.UserName,
      week: state.project.displayProjectWeek
    };
    dispatch(addSingleAlert(alert, notificationData, 5000, true));
    return false
    };
