import React from "react";

export const TeamRevisionForScope = () => {
  return (
    <>
      <p>
        We have carefully examined the current design and it will definitely not
        meet the throughput and response time requirements for the tablets. As a
        result we will need to redesign the related software. The good news is
        we caught the problem early enough that we are confident it will not
        impact the schedule. The bad news is it will require additional effort
        and specific skills.
      </p>
      <p>
        Our plan is to create a new task, Redesign for Throughput, that will run
        concurrently with task 16, Prototype Demo Software Development. The
        Redesign for Throughput task is estimated to take about 2 weeks and will
        require 2 software engineers working closely with the team on task 16.
      </p>
      <p>
        We realize that this approach requires software engineers and that we
        are already utilizing all available software engineers. If you can find
        two software engineers for the two weeks, we will do everything we can
        to make this plan successful.
      </p>
      <footer>
        <p>ID #1041</p>
      </footer>
    </>
  );
};
