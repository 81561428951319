import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import imageURLs from "./images/imageURLs.js";


const TrainingPlanningHelp = () => (
  <div>
    <h3>Planning Your Project – Training Plan</h3>
    <h4>Context and Resources for Training Planning: </h4>
    <p>
      Create a detailed Training Plan or training budget to use in making sure your team members execute their tasks efficiently and meet project objectives:

    </p>
    <ol>
      <li>
        The detailed plan approach involves planning the specific class, resource and week of each training event prior to starting the project.

      </li>
      <li>
        The training budget approach can work well too. Pick any number of classes (they can be the same class or different classes), week for the training (same week or different weeks), and resources (same resource or different resource) to create a training budget for use as needed during the execution of your project.

      </li>
    </ol>
    <p>
      The following will be useful in identifying potential training
      opportunities:
    </p>
    <ol>
      <li>The Training Options & Costs window </li>
      <li>Task descriptions (via the Gantt Chart) </li>
      <li>Team member profiles (via the Resource Plan) </li>
    </ol>

    <h4>Building your Training Plan</h4>


    <Row>
      <Col lg="12">
          <p>Add a training event to your plan by clicking on the ‘Add New Training button to open the modal: </p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.AddNewTrainingButton}
          alt="newRisk"
        />
      </Col>

    </Row>

    <Row style={{marginTop: "10px"}}>
      <Col lg="6">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.AddEditTraining}
          alt="training"
        />
      </Col>
      <Col lg="6">
        <ol>
          <li>Select a class from the drop down menu </li>
          <li>
            Select the week number to hold the training from the drop down menu
          </li>
          <li>Select the resource to attend the class from the drop down menu </li>
          <li>
            Click on the “Save” button to save your entry or the “Close” button to
            cancel your entry.{" "}
          </li>
        </ol>
      </Col>

    </Row>



    <p>
    Training options and costs are detailed in the Project Reference Guide. Add enough training classes to meet your approved Training budget.
    </p>

    <h4>Editing your Training Plan</h4>
    <Row>
      <Col lg="8">
        <p>

          Once entered, individual training events can be edited or deleted
          using the icons (pencil/trash can) in the last column of each row.{" "}
        </p>
      </Col>
      <Col lg="4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.EditDeleteIcons}
          alt="editdelete"
        />
      </Col>
    </Row>
    <h4> Execution and your Training Plan:</h4>
    <p>
      Use your training plan in making your weekly project decisions. Follow your plan if it makes sense, but also change your training plan as project conditions change (i.e. poor quality). When you start executing the project the budget amounts will be locked but you will still be able to update your Training Plan to track actual progress. It is good Project Management practice to keep your Training Plan up to date as project conditions change, even if the planned budget is locked.

    </p>
  </div>
);

export default TrainingPlanningHelp;
