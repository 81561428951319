import React, { Fragment } from "react";
import { Badge } from "reactstrap";
import styled from "styled-components";

const StyledBadge = styled(Badge)`
	margin-left: 10px;
	font-size: 0.9rem !important;
	border-radius: 3px;
`;

const CheckResults = props => {
	if (props.checkResults.length === 0) {
		return <span />;
	}
	if (props.checksPassed === true) {
		if (props.approvalStatus === "warning") {
			return (
				<Fragment>
					Check Status:
					<StyledBadge color="warning">Passed with warnings</StyledBadge>
				</Fragment>
			);
		}
		return (
			<Fragment>
				Check Status:
				<StyledBadge color="success">Passed</StyledBadge>
			</Fragment>
		);
	}
	return (
		<Fragment>
			Check Status:
			<StyledBadge color="danger">Failed</StyledBadge>
		</Fragment>
	);
};

export default CheckResults;
