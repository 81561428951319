import React, {useState} from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardTitle,
  CardText,
  FormText
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toggleAutoRun, setAutoRunResponse } from "redux/WorkWeek/actions";

const AutoRun = () => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  const [responseType, setResponseType] = useState(content.execution.workweek.autorunResponseType)
  const options = [
    { value: 0, label: "random" },
    { value: 1, label: "one" },
    { value: 2, label: "two" },
    { value: 3, label: "three" }
  ];
  return (
    <Card body>
      <CardTitle><p>Enable Auto Run</p><small>Will stay enabled through the browser session.</small></CardTitle>
      <CardText>
        Click first to enable autorun then choose the response type of random, one, prefer two,
        prefer three.  
      </CardText>
      <Row>
        <Col xs="3">
        <Button
            id="autoRun"
            style={content.execution.workweek.autorun ? { background: "#5378ad",fontWeight: "bold" } : {background: "#9E3237", fontWeight: "bold"}}
          color="primary"
          onClick={() => dispatch(toggleAutoRun())}
        >
          {content.execution.workweek.autorun
            ? "Toggle to Off"
            : "Toggle to On"}
          </Button>
        </Col>
        <Col xs="9">

          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            options={options}
            value={options.filter(function(option) {
          return option.value === responseType;
        })}
            placeholder="Choose response type"
            onChange={selected => {
              dispatch(setAutoRunResponse(selected.value));
              setResponseType(selected.value);
            }}
          />
          <FormText color="muted">
            The default value is random.
          </FormText>
        </Col>
      </Row>
    </Card>
  );
};

export default AutoRun;
