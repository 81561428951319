import React, { Component } from "react";
import { connect } from "react-redux";



const RequiresAuth = (ComposedComponent) => {
  class Authentication extends Component {


    componentDidMount() {
      if (!this.props.authenticated) {
        this.props.history.push("/authentication");
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.isAuthenticated };
  }

  return connect(mapStateToProps)(Authentication);
}

export default RequiresAuth
