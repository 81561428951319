import React, { useState } from "react";
import { connect } from "react-redux";
import { bumpProject } from "redux/Project/thunks";
import RightMarginButton from "components/styled/RightMarginButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const BumpRun = props => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <RightMarginButton
        color="primary"
        onClick={async () => {
          setLoading(true);
          await props.bumpProject(props.runId, props.classStudentRunId)
          //   .then(() => {
          //   setLoading(false);
          // });
        }}
      >
        {!loading && "Bump"}
        {loading && (
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" />
        )}
      </RightMarginButton>
    </>
  );
};
// leaving here for now
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  bumpProject: (runId, classStudentRunId) =>
    dispatch(bumpProject(runId, classStudentRunId))
});

const BumpRunContainer = connect(mapStateToProps, mapDispatchToProps)(BumpRun);
export default BumpRunContainer;
