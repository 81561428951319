import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import RightMarginButton from "components/styled/RightMarginButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import { getHeadConditions, setEnabledConditions, resetConditions } from "redux/WorkWeek/thunks";

const ConditionFilter = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method

  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
 

  useEffect(() => {
    (async () => {
      await dispatch(getHeadConditions());      
    })();
  }, [ dispatch]);
  

  const onChange = selectedOptions => setSelectedIds(selectedOptions);

  return (
    <Card body>
      <CardTitle>Enable / disable conditions </CardTitle>
      <CardText>
        Filter 1 or more conditions to run by choosing from the below list.  Search by typing condition id or message text.
      </CardText>
      <Row>
      <Col md="7">
          <Select
            id="conditionSelect"
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), float: "left"}}
            options={content.execution.workweek.testConditionOptions ? content.execution.workweek.testConditionOptions : []}
          placeholder="Choose conditions / click & type to search"
          value={selectedIds}
          isSearchable
          isMulti
          onChange={onChange}
          />
           <UncontrolledTooltip placement="top" target="conditionSelect" trigger="conditionSelect" >
         Select one or more conditions to enable. Type in the dropdown box to filter by condition id or message.
      </UncontrolledTooltip>
</Col>
        <Col lg="2">
          <RightMarginButton
          style={{width: "100px"}}
          id="filterButton"
          color="primary"
            onClick={() => {
              setLoading(true)
              dispatch(setEnabledConditions(selectedIds))
              .then(() => setLoading(false))
          } }
          >

            Filter
                 {loading && (
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" />
        )}
          </RightMarginButton>
           <UncontrolledTooltip placement="top" target="filterButton" trigger="filterButton" >
         Click to set selected head conditions to enabled and disable the rest.
      </UncontrolledTooltip>
          </Col>

        <Col lg="3">
        <RightMarginButton
            id="resetButton"
             style={{width: "100px"}}
            color="primary"
            onClick={() => {
              setLoading(true)
              dispatch(resetConditions())
                .then(() => {
                  setSelectedIds([]);
                  setLoading(false);
                });
            }}
          >
            Reset All
                 {loading && (
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" />
        )}
          </RightMarginButton>
           <UncontrolledTooltip placement="top" target="resetButton" trigger="resetButton" >
         Click to reset all unfired head conditions to enabled.
      </UncontrolledTooltip>
          </Col>
        </Row>
    </Card>
  );
};

export default ConditionFilter;
