// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_RESOURCEPLAN = "REQUEST_RESOURCEPLAN";
export const RECEIVE_RESOURCEPLAN = "RECEIVE_RESOURCEPLAN";
export const UPDATE_RESOURCEPLAN_SUCCESS = "UPDATE_RESOURCEPLAN_SUCCESS";
export const INSERT_RESOURCEPLAN_REQUEST = "INSERT_RESOURCEPLAN_REQUEST";
export const INSERT_RESOURCEPLAN_SUCCESS = "INSERT_RESOURCEPLAN_SUCCESS";
export const DELETE_RESOURCEPLAN_SUCCESS = "DELETE_RESOURCEPLAN_SUCCESS";
export const ADD_MORE_ITEM_MAPPINGS = "ADD_MORE_ITEM_MAPPINGS";
export const RESOURCE_ERROR = "RESOURCE_ERROR";
export const UPDATE_RESOURCEPLANITEM_STATE = "UPDATE_RESOURCEPLANITEM_STATE";
export const SAVE_RESOURCEPLANITEM_SUCCESS = "SAVE_RESOURCEPLANITEM_SUCCESS";
export const SET_FILTER_SKILLID = "SET_FILTER_SKILLID";
