import React from "react";

export const TaskHours = props => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">{props.header.col1}</th>
            <th className="text-center">{props.header.col2}</th>
            <th className="text-center">{props.header.col3}</th>
            <th className="text-center">{props.header.col4}</th>
          </tr>
        </thead>
        <tbody>
          {props.properties.map((row, index) => (
            <tr key={index}>
              <td className="text-center">{row.task}</td>
              <td className="text-center">{row.soFarWeek}</td>
              <td className="text-center">{row.soFarProject}</td>
              <td className="text-center">{row.planned}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-right">
              <strong>{props.footer.col1}</strong>
            </td>
            <td className="text-center">
              {" "}
              <strong>{props.footer.col2}</strong>
            </td>
            <td className="text-center" style={{ background: "#F6F6F6" }}>
              {" "}
              <strong>{props.footer.col3}</strong>
            </td>
            <td className="text-center" style={{ background: "#F6F6F6" }}>
              {" "}
              <strong>{props.footer.col4}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
