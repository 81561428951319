import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { getTaskSelectOptions } from "redux/Resource/selectors";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import DataGrid from "react-data-grid";
import { getProjectedLastWeek } from "redux/Project/selectors";
import Resource from "components/Resource";
import CardHeaderBold from "components/styled/CardHeaderBold";
import ButtonActions from "./ButtonActions";
import { fetchResourcePlan } from "redux/Resource/thunks";
import SelectEditor from "./ActionSelector";
import { toggleResourcePlan, toggleLoading } from "app/redux/actions";

import "static-new/styles/vendor/react-data-grid.css";
import CSVExporter from "./CSVExporter";
import LoadingOverlay from "react-loading-overlay";

const ResourceGrid = (props) => {
  const [sortBy, setSortBy] = useState("sortId");
  const [minGridHeight, setMinGridHeight] = useState(0);
  const gridRef = useRef(null);
  const isResourcePlanVisible = useSelector(
    (state) => state.app.resourcePlanVisible
  );
  const detailInfoWeekMap = useSelector(
    (state) => state.planning.resource.detailInfoWeekMap
  );

  const dispatch = useDispatch();

  const { runId, fetchResourcePlan, data, displayProjectWeek, lastWeek } =
    props;
  useEffect(() => {
    fetchResourcePlan(runId);
    setMinGridHeight(15 * 60 + 50);
    // pacciss =set to 15 rows otherwise browser scroll kicks in
  }, [runId, fetchResourcePlan]);

  useEffect(() => {
    if (
      gridRef &&
      gridRef.current?.scrollToColumn &&
      displayProjectWeek &&
      lastWeek &&
      displayProjectWeek > 3
    ) {
      const { scrollToColumn } = gridRef.current;

      setTimeout(() => {
        scrollToColumn(
          displayProjectWeek + (lastWeek - displayProjectWeek - 2)
        );
      }, 300);
    }
  }, [gridRef, displayProjectWeek, lastWeek, isResourcePlanVisible]);

  const resourceHeaderFormatter = (value) => {
    return (
      <span style={{ paddingLeft: "10px" }} title={value.column.name}>
        {value.column.name}
      </span>
    );
  };
  // check if this is the second staff week
  const okToEdit = (editorProps) => {
    const currentMapping = detailInfoWeekMap.filter(
      (item) =>
        item.Item4 === editorProps.column.key &&
        item.Item3 === editorProps.row.RowId
    );
    const obj = currentMapping[0];
    const index = detailInfoWeekMap.indexOf(obj);
    if (
      editorProps.row[editorProps.column.key] === "Staff" &&
      index > 0
    ) {
      const obj3 = detailInfoWeekMap[index - 1];
      if (editorProps.row[obj3.Item4] === "Staff") {
        return false;
      }

    }
    return true;
  };

  const makeColumns = () => {
    const columns = [
      {
        key: "ResourceName",
        name: "Resource",
        width: 250,
        resizable: true,
        frozen: true,
        headerRenderer: resourceHeaderFormatter,
        formatter: (cell, row) => {
          return (
            <div style={{ paddingLeft: "10px" }}>
              <Resource
                id={cell.row.Id}
                key={cell.row.ResourceName}
                row={cell.row}
                status={cell.row.WorkerStatusOnProject}
              />
            </div>
          );
        }
      }
    ];

    for (let week = 1; week <= props.lastWeek + 1; week += 1) {
      let activeCol = false;

      if (week === props.displayProjectWeek) {
        activeCol = true;
      }
      const wk = `W${week}`;
      columns.push({
        key: wk,
        name: wk,
        sort: true,
        resizable: true,
        headerRenderer: () => <div style={{ textAlign: "center" }}>{wk}</div>,
        formatter: (formatterProps) => {
          if (activeCol) {
            return (
              <div style={{ textAlign: "center", backgroundColor: "#eef9f6" }}>
                {formatterProps.row[formatterProps.column.key] === "None" ? (
                  <span style={{ color: "transparent" }}>
                    formatterProps.row[formatterProps.column.key]
                  </span>
                ) : (
                  formatterProps.row[formatterProps.column.key]
                )}
              </div>
            );
          } else {
            return (
              <div style={{ textAlign: "center" }}>
                {formatterProps.row[formatterProps.column.key] === "None" ? (
                  <span style={{ color: "transparent" }}>
                    formatterProps.row[formatterProps.column.key]
                  </span>
                ) : (
                  formatterProps.row[formatterProps.column.key]
                )}
              </div>
            );
          }
        },
        editor: (editorProps) => {
          if (!okToEdit(editorProps)) {
            return (
              <div
                id="tooltip-cell"
                style={{
                  textAlign: "center",
                  background: "lightgray"
                }}
              >
                <textarea
                  disabled
                  value={editorProps.row[editorProps.column.key]}
                  onClick={(e) => e.preventDefault()}
                />
                <UncontrolledTooltip placement="top" target="tooltip-cell">
                  The second Staff item cannot be deleted. Please delete the
                  first staff cell and both will be removed.
                </UncontrolledTooltip>
              </div>
            );
          }
          return (
            <SelectEditor
              value={editorProps.row[editorProps.column.key]}
              row={editorProps.row}
              col={editorProps.column}
              options={props.taskOptions}
              rowHeight={editorProps.rowHeight}
              menuPortalTarget={editorProps.editorPortalTarget}
            />
          );
        },
        editorOptions: {
          editOnClick: !props.displayOnly
        }
      });
    }
    !props.displayOnly &&
      columns.push({
        key: "Id",
        name: "Actions",
        frozen: true,
        isLastFrozenColumn: true,
        width: 120,
        cellClass: "rdg-cell-frozen-last",
        resizable: true,
        headerRenderer: () => (
          <div style={{ textAlign: "center" }} id="tooltip-actions">
            Actions
            <sup>
              <FontAwesomeIcon icon={faInfoCircle} />
            </sup>
            <UncontrolledTooltip placement="top" target="tooltip-actions">
              <p>
                <u>Remove and Shift actions</u>{" "}
              </p>
              <p>After budget approval:</p>
              <p>Remove action: available week 3</p>
              <p>Shift actions: available week 2.</p>
            </UncontrolledTooltip>
          </div>
        ),
        formatter: (formatterProps) => {
          return (
            <div style={{ textAlign: "center" }}>
              <ButtonActions
                toggleLoading={() => console.log("should be loading")}
                row={formatterProps.row}
                rowIdx={formatterProps.rowIdx}
              />
            </div>
          );
        }
      });
    return columns;
  };

  const sortBySortBy = (sortedBy) => {
    switch (sortedBy) {
      case "sortId": // default
        return [
          ...props.data.sort((a, b) =>
            a.ResourceSortId > b.ResourceSortId
              ? 1
              : b.ResourceSortId > a.ResourceSortId
              ? -1
              : 0
          )
        ];
      case "skill":
        return [
          ...props.data.sort((a, b) =>
            a.SkillName > b.SkillName ? 1 : b.SkillName > a.SkillName ? -1 : 0
          )
        ];

      case "status":
        return [
          ...props.data.sort((a, b) =>
            a.WorkerStatusOnProject > b.WorkerStatusOnProject
              ? 1
              : b.WorkerStatusOnProject > a.WorkerStatusOnProject
              ? -1
              : 0
          )
        ];

      default:
        return [
          ...props.data.sort((a, b) =>
            a.ResourceName > b.ResourceName
              ? 1
              : b.ResourceName > a.ResourceName
              ? -1
              : 0
          )
        ];
    }
  };

  return (
    <Card id="resource-plan" tourstop="3">
      <CardHeaderBold>
        <div className="text-left">Resource Plan</div>
        <div className="flex text-right">
          <ButtonToolbar>
            <ButtonGroup style={{ marginRight: "20px" }}>
              <span style={{ marginRight: "15px" }}>Sort by:</span>
              <Button
                outline={sortBy !== "sortId"}
                color="primary"
                onClick={() => setSortBy("sortId")}
              >
                Default
              </Button>
              <Button
                outline={sortBy !== "name"}
                color="primary"
                onClick={() => setSortBy("name")}
              >
                Name
              </Button>
              <Button
                outline={sortBy !== "skill"}
                color="primary"
                onClick={() => setSortBy("skill")}
              >
                Skill
              </Button>
              <Button
                outline={sortBy !== "status"}
                color="primary"
                onClick={() => setSortBy("status")}
              >
                Status
              </Button>
            </ButtonGroup>
            <CSVExporter data={props.data} />
          </ButtonToolbar>

          {props.displayOnly && (
            <HashLink
              className="btn btn-primary"
              to="/planning/resource#resource-plan"
              href="planning/resource#resource-plan"
            >
              Edit Resource Plan
            </HashLink>
          )}
          {!props.noHideToggle && (
            <Label className="switch switch-default switch-primary">
              <Input
                type="checkbox"
                className="switch-input"
                onClick={() => dispatch(toggleResourcePlan())}
                defaultChecked={isResourcePlanVisible}
              />
              <span className="switch-label" />
              <span className="switch-handle" />
            </Label>
          )}
        </div>
      </CardHeaderBold>

      <Collapse isOpen={isResourcePlanVisible} style={{ overflowY: "hidden" }}>
        <LoadingOverlay
          active={props.loading}
          spinner
          text="Updating Resource Plan..."
        >
          <DataGrid
            headerRowHeight={50}
            rowHeight={60}
            className="rdg-light"
            style={{
              width: "100%",
              height: "650px",
              maxHeight: "650px",
              border: 0,
              overflowY: "auto"
            }}
            columns={makeColumns()}
            rows={[...sortBySortBy(sortBy)]}
            ref={gridRef}
          />
        </LoadingOverlay>
      </Collapse>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  data: state.planning.resource.resourcePlanDisplay,
  runId: state.project.runId,
  displayProjectWeek: state.project.displayProjectWeek,
  loading: state.app.loading,
  lastWeek: getProjectedLastWeek(state),
  taskOptions: getTaskSelectOptions(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchResourcePlan: (runId) => dispatch(fetchResourcePlan(runId))
});

const ResourceGridContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceGrid);
export default ResourceGridContainer;
