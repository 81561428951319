import React from "react";

export const TaskComplete = props => (
  <div className="content">
    <p>
      <i className="fa fa-thumbs-up" /> Task {props.properties.task} is now
      complete.
    </p>
  </div>
);
