import { CardBody  } from "reactstrap";
import styled from "styled-components";

const ChartCardBody = styled(CardBody)`
  &.card-body {
    max-height: 1000px;
    border: solid 1px grey;
    overflow: visible;
  }
`;

export default ChartCardBody ;