import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckLog from "routes/Approval/components/CheckLog";
import CheckResults from "routes/Approval/components/CheckResults";
import Delay from "react-delay";

import { doStaffingChecks } from "utilities/staffingChecks";

const Layout = styled.div``;

const StyledCheckList = styled.div`
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .col {
    flex-basis: auto;
  }
`;

const PlansCheckerResults = (props) => {
  const [checkOutput, setCheckOutput] = useState([]);

  useEffect(() => {
    const runChecks = async () => {
      const currentWeek = `W${props.displayProjectWeek}`;

      const res = await doStaffingChecks(
        props.combinedData,
        props.taskStatusList,
        currentWeek
      );

      return res;
    };

    const fetchData = async () => {
      const result = await runChecks();
      setCheckOutput(result);
    };
    fetchData();
  }, [props.combinedData, props.taskStatusList, props.displayProjectWeek]);

  return (
    <Layout>
      {checkOutput.length > 0 ? (
        <StyledCheckList>
          <CheckLog
            key="checkListItems"
            staffing={checkOutput}
            overall={null}
            training={null}
            stakeholder={null}
            recognition={null}
            risk={null}
            resource={null}
            singleColumn
          />
        </StyledCheckList>
      ) : (
        <span role="img" aria-label="No Issues">
          🎉 No issues found.
        </span>
      )}
      <footer>
        <Delay wait={750}>
          <CheckResults
            checkResults={checkOutput}
            checksPassed
            approvalStatus="success"
          />
        </Delay>
      </footer>
    </Layout>
  );
};

export default PlansCheckerResults;
