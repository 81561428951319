import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faAngleRight,
  faUserCog,
  faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";

export const CharterBis = () => {
  return (
    <div className="charter-div style-background-components">
      <header>
        <h2> Project Charter</h2>
      </header>
      <br />
      <p>
        <FontAwesomeIcon icon={faBookmark} className="icons-presentation" />
        <strong>Project:</strong> PACCISS
        <br />
        <FontAwesomeIcon icon={faFemale} className="icons-presentation" />
        <strong>Customer:</strong> Kristine Olson, Government Customer
        <br />
        <FontAwesomeIcon icon={faUserCog} className="icons-presentation" />
        <strong>Sponsor:</strong> B. D. Smith, VP/GM Homeland Defense Division
      </p>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Objectives
      </h4>
      <p>The objectives of the PACCISS are two-fold:</p>
      <ol>
        <li>
          Integrate information from Port Authority and Federal Intelligence
          Agency (FIA) databases to provide Inspectors at the Ports with real
          time Cargo Risk information for daily inspection use.
        </li>
        <li>
          Provide feedback from inspections into the Port Authority and
          Intelligence Databases.
        </li>
      </ol>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Interfacing Systems
      </h4>
      <p>
        US Intelligence Agencies maintain an existing all source classified
        Oracle database of potential cargo risks entering the US via commercial
        shipping operations. The agencies have agreed to create an unclassified,
        searchable data product from that database that contains information on
        high-risk cargo shipments. This unclassified database is also in Oracle
        and maintained in a central location and would be accessible through a
        secure Web interface to each authorized Port Authority. The Lead Agency
        for this effort is the FIA, and they have designated SINC, Inc. and
        their contractor, to develop the unclassified database.
      </p>
      <p>
        US Port Authorities maintain a logistics database of all incoming cargo
        containerships to US Ports. This Oracle database includes detailed
        shipment information such as ship names, manifests, scheduled time of
        arrival, port, dock number, container numbers, declared contents,
        off-loading schedule etc.
      </p>
      <p>
        A medium-sized software firm, McMillan Computer Associates (MCA), is
        currently contracted with the Port Authorities to manage and maintain
        the Port Authority database.
      </p>
      <p>
        Each port currently has the capability to update the Port Authority
        Database with new information on ships scheduled to arrive at its port
        using a Web interface. Each Port Authority user also has the capability
        to use Web-based forms to search the Port Authority database, to create
        reports using search criteria, to download data to a local Microsoft
        Access DB database maintained at the Port and to create and print
        reports from the database.
      </p>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Operational Concept
      </h4>
      <p>
        Cargo inspectors would be able to generate a daily inspection plan from
        the PACCISS each day. This inspection plan would be based upon the ship
        arrivals and cargo data from the Port Authority database, synthesized
        with the risk data from the FIA database. The synthesis of the FIA
        databases with the Port Authority central database would occur in real
        time, as FIA data becomes available, or new arrivals are identified.
        Inspectors would record the results of the inspections using some
        electronic means and be able to upload that data using PACCISS to the
        central Port Authority Database.
      </p>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Northrop Grumman Security Systems Proposed Solution
      </h4>
      <br />
      <h6 id="sub-title-solution">Solution for Phase 1:</h6>
      <p>
        Phase 1 will concentrate upon developing a prototype demonstration of
        the hand-held Inspection Support Tool, while concurrently, detailed
        requirements, architecture, and application specifications are being
        developed for the PACCISS.
      </p>
      <ol id="sub-title-solution-list">
        <li>Requirements and Architecture</li>
        <br />
        <p>
          Northrop Grumman Security Systems will work with the FIA and Port
          Authority and Cargo Inspection users to determine the requirements for
          the PACCISS.
        </p>
        <p>
          The Northrop Grumman Security Systems Team will extend the data model
          of the current Port Authority Database and standalone databases to
          include the new data being provided by the FIA unclassified cargo risk
          database.
        </p>
        <p>
          The Northrop Grumman Security Systems Team will develop a multi-tiered
          system architecture that clearly shows the interfaces among the FIA
          systems, and the Port Authority Systems.{" "}
        </p>
        <p>
          The Northrop Grumman Security Systems Team will develop a method for
          maintaining consistency and currency among the databases. Northrop
          Grumman Security Systems approach is to obtain “push” data from the
          FIA database as risk data is updated, and to pull data from the FIA
          systems as new ship arrivals are identified in the Port Authority
          database. The local port systems will push inspection report data to
          the Port Authority database, using already established interface
          modes. The data will be made available for the FIA system in the form
          of a daily update data transaction sent to the FIA system. Northrop
          Grumman Security Systems Team will work with SINC, Inc., the FIA
          contractor, to refine the data update approach.
        </p>
        <p>
          The Northrop Grumman Security Systems Team will develop the
          application specification for the user interface elements of the
          PACCISS. Northrop Grumman Security Systems approach is to develop a
          Web-based interface that permits authorized users to connect to the
          PACCISS from any configured workstation with a broadband or high-speed
          dial-up connection.
        </p>
        <p>
          The Northrop Grumman Security Systems Team will develop a remote data
          entry method for inspector use in the field. This approach will use
          ruggedized MIL Tablet PC hardware and software technology to provide
          the inspectors with hand-held electronic data entry methods. A
          standard USB data port will be used to connect the Tablet PC to a
          workstation. Upon authorized access, authentication, and
          synchronization between the Tablet PC and the workstation, the
          inspector will be able to download daily inspection lists to the
          Tablet PC. The Tablet PC will use a Web form interface that has the
          same look and feel as the PC workstation interface. Synchronization
          software will be developed to upload completed inspection reports from
          the Tablet PC to the PACCISS upon docking and authentication.
        </p>
        <li>Prototype Demonstration at Long Beach</li>
        <br />
        <p>
          Concurrent with the requirements and architecture analysis effort, the
          Northrop Grumman Security Systems Team will develop a prototype of a
          handheld field inspection recording tool based on Tablet PC
          technology. The demonstration will use a prototype database at the
          Long Beach facility that will contain the data types needed to support
          the end-user inspection activities. <br />
          The demonstration will be limited to:
        </p>
        <ol className="list-circle">
          <li>
            Generating the inspector’s daily cargo inspection list from a
            PACCISS workstation using a Web interface
          </li>
          <li>Downloading the inspection list to the inspector’s Tablet </li>
          <li>
            Demonstrating the use of the Tablet PC to collect data during
            inspection
          </li>
          <li>
            Uploading the inspection results to PACCISS and showing the results
            in an on-screen report generated from the PACCISS
          </li>
        </ol>
      </ol>
      <p>The outputs of this effort are: </p>
      <p>
        A system architecture and requirements document for PACCISS, including
        but not limited to: interfaces with the FIA and Port Authority central
        databases; data model for the central and standalone databases;
        communications architecture; user interface application requirements;
        prototype demonstration of the port user interface and handheld
        inspection support tool; Feasibility Report that estimates the risk,
        costs, and logistics involved with a nationwide deployment of the
        PACCISS.
      </p>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Government’s Statement of Work
      </h4>
      <p>
        Enhance the security of US sea-based ports of entry by integrating US
        Intelligence reports with existing Port Authority infrastructure.
        Develop the capability to demonstrate the concept, feasibility, and
        effectiveness of an enhanced Port Authority Cargo Container Inspection
        Force within 20 weeks of Contract Award. Establish a system-level
        architecture for a potential nationwide rollout of the PACCISS.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 1: Program Management
        </strong>
        <br />
        Assume the responsibility of Prime Integrating Contractor to plan,
        execute, and manage all aspects of the feasibility demonstration
        PACCISS.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 2: System Engineering and Integration
        </strong>
        <br />
        Define a system architecture to integrate Port Authority and US
        Intelligence data/IT infrastructures with commercial-off-the-shelf
        (COTS)-based hardware/software. Utilize existing systems and products to
        support the EOA demonstration. Integrate and execute the EOA
        demonstration.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 3: Development
        </strong>
        <br />
        Develop a GUI interface for the ruggedized Tablet PC to display, manage,
        transfer, and report daily inspection plans and their results.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 4: Early Operational Feasibility Assessment (EOFA) Report
        </strong>
        <br />
        Assist the Homeland Security Agency in risk analysis, logistics
        planning, cost estimates, and program planning for a nation-wide
        deployment of PACCISS.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 5: Subcontract Management
        </strong>
        <br />
        Enter into a directed subcontract agreement with MCA to perform the data
        modeling, modifications, and reporting for the Port Authority
        interfaces.
      </p>
      <p>
        <strong>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            className="icons-presentation"
          />
          Subtask 6: Vendor Management
        </strong>
        <br />
        Procure the ruggedized Tablet PC and other IT hardware/software
        necessary to support the prototype demonstration.
      </p>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Deliverables
      </h4>
      <ol className="list-circle">
        <li>
          <strong>CDRL 01:</strong> Monthly Cost and Progress Reports.
          <br />
          Due monthly not later than (NLT) 15th of month.
        </li>
        <li>
          <strong>CDRL 02:</strong> System Requirements and Architecture
          Specification. <br />
          Due NLT 90 days after start.
        </li>
        <li>
          <strong>CDRL 03:</strong> Prototype Demonstration System Hardware and
          Software. <br />
          Due NLT 140 days after start.
        </li>
        <li>
          <strong>CDRL 04:</strong> EOFA Effectiveness Report. <br /> Due NLT
          140 days after start.
        </li>
      </ol>
      <br />
      <h4>
        <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
        Performance
      </h4>
      <p>
      The PACCISS prototype will be accepted upon successful completion of the prototype demonstration,
      installation, and delivery with no more than 40 known defects which will
       represent an estimated Mean Time Between Failures (MTBF) of 5 days.
        Furthermore, no known defects can be present which can cause potential
        system failure or major loss of functionality. The Tablet PC must be
        capable of handling any user entry without appreciable degradation in
        system response. The maximum response time shall be no more than 1
        second for any user entry.
      </p>
      <div id="flow-diagram-img">
        <img
          src="/images/data_flow_diagram.jpg"
          alt="PACCISS Data Flow Diagram"
        />
      </div>
    </div>
  );
};
