import styled from "styled-components";
import { Row } from "reactstrap";

const CheckLogRow = styled(Row)`
	li h4 {
		font-size: 1.25rem;
	}

	ul {
		list-style: none;
		padding-left: 20px;
		margin-bottom: 10px;
		max-height: none !important;
		position: relative;

		> li {
			&:first-child {
				margin-top: 0;
			}
		}

		a.btn-outline-secondary {
			color: #333;
		}

		li:before {
			content: "";
			font-family: "fontAwesome";
			position: absolute;
			left: 0;
		}

		li.check-passed {
			font-weight: 600;

			&:before {
				content: "\f00c";
			}
		}

		li.check-failed {
			font-weight: 600;

			&:before {
				content: "\f00d";
			}
		}

		li.check-warning {
			font-weight: 600;

			&:before {
				content: "\f071";
			}
		}

		> ul {
			-webkit-column-count: 2;
			column-count: 2;
		}

		> ul > li {
			margin-top: 1rem;
			border-top: 1px solid #f3f3f4;
			padding: 1.5rem 1rem 1rem;

			&:first-child {
				margin-top: 0;
				border-top: none;
				padding-top: 0;
			}
		}

		ul {
			list-style: none;
			padding: 0;
		}

		> ul > li > ul > li {
			display: block;
			position: relative;

			p {
				padding-left: 30px;
			}

			a.btn {
				margin-bottom: 0;
			}
		}
	}

	.check-passed {
		color: #49c3a2;
	}

	.check-failed {
		color: #9e3237;
	}

	.check-warning {
		color: #f8ac59;
	}

	ul ul li {
		font-weight: 400;
	}

	.card-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 15px 20px 20px 20px;

		p {
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 2rem;

			strong {
				margin-left: 1rem;
			}
		}

		.btn {
			margin-bottom: 0;
		}
	}
`;

export default CheckLogRow;
