import React from "react";
import accounting from "accounting";

const TableFooter = (props) => {
  const footer = () => {
    const footers = [];
    const totalCosts = props.data.reduce(function (a, b) {
      return a + b["totalCosts"];
    }, 0);
    const totalHours = props.data.reduce(function (a, b) {
      return a + b["totalHours"];
    }, 0);

    footers.push(
      <td className="text-right" key="weeksfooter">
        <strong>Totals:</strong>
      </td>
    );

    for (let i = 1; i <= props.maxWeek; i += 1) {
      let weeklyHours = props.data.reduce(function (a, b) {
        return a + b[i.toString()];
      }, 0);
      if (isNaN(weeklyHours)) {
        weeklyHours = 0;
      }
      footers.push(
        <td className="text-right" key={`W${i}footer`}>
          <strong>{weeklyHours}</strong>
        </td>
      );
    }

    footers.push(
      <td className="text-right" key="tothrnotfooter">
        <strong>{totalHours}</strong>
      </td>
    );
    footers.push(
      <td className="text-right" key="totcostnot">
        <strong>{accounting.formatMoney(totalCosts)}</strong>
      </td>
    );
    return footers;
  };

  return (
    <tfoot>
      <tr>{footer()}</tr>
    </tfoot>
  );
};

export default TableFooter;
