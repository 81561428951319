export const loadState = () => {
	try {
		const serializedState = JSON.parse(sessionStorage.getItem("state"));
		if (serializedState === null) {
			return undefined;
		}

		const now = new Date();
		const expirationDate = serializedState.expiresAt;
		if (Date.parse(now) < Date.parse(expirationDate)) {
			return serializedState.sessionData;
		}
		sessionStorage.removeItem("state");
		return undefined;
	} catch (err) {
		return undefined;
	}
};

export const saveState = state => {
	try {
		const expiration = new Date();
		expiration.setMinutes(expiration.getMinutes() + 120);

		// ditch the content if too old

		const sessionObject = {
			expiresAt: expiration,
			sessionData: state
		};

		sessionStorage.setItem("state", JSON.stringify(sessionObject));
	} catch (err) {
		// TODO determine what to do with a write error in localstorage
	}
};
