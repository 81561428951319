import React from "react";
import accounting from "accounting";
import { connect } from "react-redux";

var options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

export class RiskTable4Display extends React.Component {
  render() {
    var iterKey = 1;
    return (
      <div className="component-Box component-ReferenceTable">
        <div className="box-content">
          <div className="component-Table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Event</th>
                  <th>Prob (%)</th>
                  <th>Cost Impact</th>
                  <th>Net Cost</th>
                  <th>Action to be Taken</th>
                </tr>
              </thead>
              <tbody>
                {this.props.risks.map((row, index) => (
                  <tr key={iterKey++}>
                    <td key={iterKey++}>{row.ID}</td>
                    <td>{row.EventName}</td>
                    <td>{row.Probability}%</td>
                    <td>{accounting.format(row.CostImpact, options)}</td>
                    <td>{accounting.formatMoney(
                      row.Probability *
                      0.01 *
                      row.CostImpact,
                      options
                    )}</td>
                    <td>{row.Action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    risks: state.planning.risk.risks,
    runId: state.project.runId
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export const RiskTable4DisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskTable4Display);
