import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import _debounce from "lodash.debounce";
import moment from "moment";

import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { setupNewClassroom, fetchClassesByInstructor } from "redux/Instructor/thunks";

class CreateClassroom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			primaryInstructor: {},
			secondaryInstructor: {},
			disableSaveButton: true,
			formStatus: "Ready to add",
			instructors: this.props.instructors,
			paginate: true,
			startDate: null,
			endDate: null,
			disableEndDatePicker: true,
			className: "",
			classroomStatus: ""
		};
		this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
		this.handleCreateClassroom = this.handleCreateClassroom.bind(this);
		this.changeStartDate = this.changeStartDate.bind(this);
		this.changeEndDate = this.changeEndDate.bind(this);
		this.handleClassNameChange = this.handleClassNameChange.bind(this);
		this.handleClassroomStatusChange = this.handleClassroomStatusChange.bind(this);
	}

	handlePrimaryChange = (selected) => {
		if (!selected) {
			this.setState({ disableSaveButton: true });
			return;
		} else {
			this.setState({ primaryInstructor: selected, disableSaveButton: false });
		}
	};

	handleClassNameChange = (event) => {
		const className = event.target.value;
		this.setState({ className });
	};

	handleClassroomStatusChange = (event) => {
		const classroomStatus = event.target.value;
		this.setState({ classroomStatus });
	};

	changeStartDate = date => {
		this.setState({ startDate: date, disableEndDatePicker: false })	
	};

	changeEndDate = date => {
		this.setState({ endDate: date })
	};

	handleCreateClassroom = _debounce(() => {
		const { startDate, endDate, primaryInstructor, className, classroomStatus } = this.state;

		if (!className) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>Class Name is required</span> });
			return;
		}
		if (!primaryInstructor.value) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>Please choose a Primary Instructor</span>  });
			return;
		}
		if (!startDate) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>Select a start date</span> });
			return;
		}
		if (!endDate) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>Select an end date</span> });
			return;
		}
		
		if (endDate < startDate) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>End date cannot be before start date</span>});
			return;
		}
	
		if (!classroomStatus) {
			this.setState({  formStatus: <span style={{ color: 'red' }}>Select a Classroom Status</span>});
			return;
		}

		this.setState({ formStatus: "Adding ..." });

		// Handle the form submission
		const payload = {
			ProductId: 38,
			ClassName: className,
			TeamType: 0,
			MaxStudents: 100,
			RunsPerStudent: 3,
			MaxTeamSize: 10,
			ClassroomType: 0,
			ClassroomStatus: classroomStatus,
			InstructorId: primaryInstructor.value,
			StartDate: startDate,
			EndDate: endDate
		};

		this.props.setupNewClassroom(payload)
			.then(() => {
				this.props.refresh();
				this.setState({
					primaryInstructor: {},
					disableSaveButton: true,
					formStatus: "Success ...",
					startDate: null,
					endDate: null,
					className: "",
					classroomStatus: ""
				});
			}).then(() => {
				this.setState({
					formStatus: "Ready to add",
					disableEndDatePicker: true
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ formStatus: err.message });
			});
	}, 75);

	render() {
		const { startDate, endDate, disableEndDatePicker, formStatus, className, classroomStatus } = this.state;
	
		const options = this.props.instructors.map(instructor => ({
			value: instructor.id,
			label: instructor.instructorName,
		}));

		return (
			<Form>
				<p>Status: {formStatus}</p>
				<FormGroup>
					<Label for="className">Class Name</Label>
					<Input required type="text" id="className" value={className} onChange={this.handleClassNameChange} />
				</FormGroup>

				<FormGroup>
					<Label for="primaryInstructor">Primary Instructor</Label>
					<Select
						options={options}
						placeholder="Choose an instructor ..."
						onChange={this.handlePrimaryChange}
					/>
				</FormGroup>

				<FormGroup>
					<Label for="startDate">Start Date</Label>
					<div className="customDatePickerWidth">
					<DayPickerInput
						formatDate={formatDate}
						parseDate={parseDate}
						onDayChange={this.changeStartDate}
						placeholder={startDate ? `${formatDate(startDate)}` : "Choose start date"}
					/>
					</div>
				</FormGroup>

				<FormGroup>
					<Label for="endDate">End Date</Label>
					<div className="customDatePickerWidth">
					<DayPickerInput
						disabled={disableEndDatePicker}
						formatDate={formatDate}
						parseDate={parseDate}
						onDayChange={this.changeEndDate}
						placeholder={endDate ? `${formatDate(endDate)}` : "Choose end date"}
					/>
					</div>
				</FormGroup>

				<FormGroup>
					<Label for="classroomStatus">Status</Label>
					<Input type="select" value={classroomStatus} onChange={this.handleClassroomStatusChange} id="classroomStatus">
						<option value="">Select status</option>
						<option value="Running">Running</option>
						<option value="Registration">Registration</option>
					</Input>
				</FormGroup>
				<Button color="primary" onClick={this.handleCreateClassroom}>
					Save
				</Button>
			</Form>
		);
	}
}
const mapStateToProps = state => ({
	userId: state.auth.userId,
	instructorClasses: state.instructor.instructorClasses,
	instructors: state.instructor.instructors,
	loading: state.instructor.loading
});
const mapDispatchToProps = dispatch => ({
	setupNewClassroom: (payload) => dispatch(setupNewClassroom(payload)),
	fetchClassesByInstructor: instructorId => dispatch(fetchClassesByInstructor(instructorId)),
});

export const CreateClassroomContainer = connect(mapStateToProps, mapDispatchToProps)(CreateClassroom);
