/* eslint-disable max-len */
import React from "react";

export const Vacations = () => {
  return (
    <div id="Vacations" className="style-background-components">
      <header>
        <h2>Vacations</h2>
      </header>
      <div>
        <p>
          Each employee accrues vacation based on the number of years of
          employment; 2 weeks after 1 year; 3 weeks after 5 years; 4 weeks after
          15 years; and 5 weeks after 25 years.
        </p>
        <p>
          When requesting staff, the requested individual's near-term vacation
          schedule will be provided. Longer-term vacation schedules will be
          provided to the project manager with at least 5 weeks notice. It is a
          strict company policy that scheduled vacations cannot be negotiated.
        </p>
        <p>
          Vacation time (cost) is not charged to the project, it is paid from a
          corporate overhead account.
        </p>
      </div>
    </div>
  );
};
