import React from "react";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";

import {
	getQualityReport,
	getEACReport,
	getEBCReport,
	getEVReport,
	getEPFReport,
	getPPIReport} from "redux/Report/selectors";
import { CardHeader } from "components/styled/CardHeader";
import { getLastTask } from "redux/Project/selectors";
import QualityChart from "routes/Report/components/Charts/QualityChart";
import EACChart from "routes/Report/components/Charts/EACChart";
// import EBCChart from "../EBC";
import EPFChart from "routes/Report/components/Charts/EPFChart";
import PPIChart from "routes/Report/components/Charts/PPIChart";
import EVChart from "routes/Report/components/Charts/EVChart";
import HistoryContent from "routes/Report/components/History/HistoryContent";
import NetworkDiagramContainer from "routes/Report/NetworkDiagramContainer";
import GanttChartContainer from "routes/Report/GanttChartContainer";
import html2pdf from "html2pdf.js";

import "routes/Report/styles/Reports.css";

const { Fragment } = React;

const styles = {
	slide: {
		padding: 10
	},
	chart: {
		width: "1000px",
		height: "500px",
		display: "inline-block"
	},
	tabs: {
		width: "400px",
		display: "inline-block",
		marginRight: "30px",
		verticalAlign: "top"
	},
	links: {
		margin: 0,
		padding: 0
	},
	tabLink: {
		height: "30px",
		lineHeight: "30px",
		padding: "0 15px",
		cursor: "pointer",
		borderBottom: "2px solid transparent",
		display: "inline-block"
	},
	activeLinkStyle: {
		borderBottom: "2px solid #333"
	},
	visibleTabStyle: {
		display: "inline-block"
	},
	content: {
		padding: "0 15px"
	},
	iframe: {
		height: "0px",
		width: "0px",
		position: "absolute"
	},
	myContainer: {
		maxWidth: "5000px",
		zoom: "0.6",
		MozTransform: "scale(0.5)"
	},
	pageBreakAfter: {
		"page-break-after": "always"
	}
};

const ReportItem = styled.div`
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid #c2cfd6;
	h5 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
	}
`;

const PrintButtonsRow = styled(Row)`
  margin: 1rem 0 2rem;
  display: flex;
  .btn {
    margin-right: 1rem;
  }
`;

const HistoryReports = styled.div`
	@media print {
		@page {
			size: landscape;
		}
		padding: 50px;
	}
`;

const ReportChart = styled.div`position: relative;`;

class PdfReports extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPrinting: false
    };
    this.printLandscape = this.printLandscape.bind(this)
	}

	printLandscape = (reportElementName, event) => {
    event.preventDefault();
    // hides the buttons
		this.setState({
			isPrinting: true
		});
		const element = document.getElementById(reportElementName);
		// Add a loading animation
		const loadingEl = document.createElement("div");
		loadingEl.classList.add("loading-overlay");
		loadingEl.setAttribute("data-html2canvas-ignore", true);
		loadingEl.innerHTML = `
      <h3><i class="fa fa-spinner fa-spin"></i> Generating PDF</h3>
    `;
		element.appendChild(loadingEl);

		let tableContainerEl = "";
		let ganttBarEls = "";


		if (reportElementName === "gchart" || reportElementName === "landscapeDiv") {
			tableContainerEl = element.querySelector(".table-fixed");
			tableContainerEl.classList.remove("table-fixed");
			tableContainerEl.classList.add("print-gantt");

			ganttBarEls = element.querySelectorAll(".table.table-Gantt .gantt-Bar");
			ganttBarEls.forEach((el) => {
				el.style.minWidth = "0";
			});
    }

    // do the printing then reshow buttons

		html2pdf()
				.from(element)
				.set({
					margin: 1,
					filename: `${this.props.runId}-Charts.pdf`,
					image: { type: "jpeg", quality: 0.98 },
					html2canvas: {
						dpi: 96,
						letterRendering: true,
						onrendered() {
							if (reportElementName === "gchart" || reportElementName === "landscapeDiv") {
								tableContainerEl.classList.add("table-fixed");
								tableContainerEl.classList.remove("print-gantt");

								ganttBarEls.forEach((el) => {
									el.style.minWidth = "17px";
								});
							}

							// Remove loading animation
							element.removeChild(loadingEl);
						}
					},
					jsPDF: { format: "legal", orientation: "landscape", unit: "in", compressPDF: true }
				})
				.save()
		.then(() => {
			this.setState({
				isPrinting: false
			});
			return;
		});
	};

	render() {
		let iterKey = 1;
		return (
			<Fragment>
				<PrintButtonsRow>
					<Col>
						<ReactToPrint
							trigger={() => (
								<Button outline color="primary" disabled={!(this.props.history.length > 0)}>
									<FontAwesomeIcon icon={faFilePdf} /> Print all History Reports (portrait)
								</Button>
							)}
							content={() => this.ReportsRef}
						/>
						<Button outline color="primary" onClick={(event) => this.printLandscape("landscapeDiv", event)}>
							<FontAwesomeIcon icon={faFilePdf} /> Create PDF of Charts (landscape)
						</Button>
					</Col>
				</PrintButtonsRow>

				<Row>
					<Col className="mb-3">
						<Card>
							<CardHeader>Full History Report</CardHeader>
							<CardBody>
								<HistoryReports ref={(el) => (this.ReportsRef = el)}>
									{this.props.history.map((row) => (
										<div key={(iterKey += 1)} className="break-after">
											<HistoryContent
												key={(iterKey += 1)}
												history={this.props.history}
												selectedReport={this.props.history[row.Week - 1]}
												week={row.Week}
											/>
											<p>
												{`--------------------------<  END OF WEEK : ${row.Week} >--------------------------`}
											</p>
										</div>
									))}
								</HistoryReports>
							</CardBody>
						</Card>
					</Col>

					<Col className="mb-3">
						<Card>
							<CardHeader>Charts</CardHeader>
							<CardBody
								id="landscapeDiv"
								style={{
									maxHeight: "none",
									overflowY: "visible",
									flexBasis: "auto",
									display: "block"
								}}
							>
								<div>
									<ReportChart>
										<ReportItem id="qual">
											<h5>
												Quality{" "}
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("qual",event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> Quality PDF
												</Button>
											</h5>
											<QualityChart data={this.props.data} style={styles.chart} />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="estcost">
											<h6>
												Estimated&nbsp; Cost&nbsp; At&nbsp; Completion{" "}
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("estcost",event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> Estimated Cost PDF
												</Button>
											</h6>
											<EACChart data={this.props.eacData} style={styles.chart} />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="eval">
											<h6>
												Earned Value
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("eval", event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> Earned Value PDF
												</Button>
											</h6>
											<EVChart data={this.props.evData} style={styles.chart} />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="efin">
											<h6>
												Estimated Project Finish (ESAC){" "}
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("efin", event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> ESAC PDF
												</Button>
											</h6>
											<EPFChart data={this.props.epfData} style={styles.chart} />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="pperf">
											<h6>
												Project Performance Index
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("pperf", event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> PPI PDF
												</Button>
											</h6>
											<PPIChart data={this.props.ppiData} style={styles.chart} />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="gchart">
											<h6>
												Gantt Chart
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("gchart", event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> Gantt Chart PDF
												</Button>
											</h6>
											<GanttChartContainer />
										</ReportItem>
									</ReportChart>
									<div className="html2pdf__page-break" />

									<ReportChart>
										<ReportItem id="nchart">
											<h6>
												Network Diagram
												<Button
													className={this.state.isPrinting ? "buttonHidden" : "buttonVisible"}
													outline
													color="primary"
													// data-html2canvas-ignore="true"
													onClick={(event) => this.printLandscape("nchart", event)}
												>
													<FontAwesomeIcon icon={faFilePdf} /> Network Diagram PDF
												</Button>
											</h6>
											<NetworkDiagramContainer />
										</ReportItem>
									</ReportChart>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
/*
 */

const mapStateToProps = (state) => ({
	defects: state.report.defects,
	projectDefects: state.report.projectDefects,
	softwareDefects: state.report.softwareDefects,
	hardwareDefects: state.report.hardwareDefects,
	data: getQualityReport(state),
	evData: getEVReport(state),
	ebcData: getEBCReport(state),
	eacData: getEACReport(state),
	ppiData: getPPIReport(state),
	epfData: getEPFReport(state),
	lastTask: getLastTask(state),
	runId: state.project.runId,
	currentProjectWeek: state.project.currentProjectWeek,
	history: state.report.history,
	taskStatusList: state.project.taskStatusList,
	taskSkillList: state.project.taskSkillList,
	workerAssignmentList: state.project.workerAssignmentList
});

// const mapDispatchToProps = (dispatch) => ({
// 	fetchHistoryData: (runId) => dispatch(fetchHistoryData(runId)),
// 	fetchReportingData: (runId) => dispatch(fetchReportingData(runId))
// });


export default connect(mapStateToProps)(PdfReports);
