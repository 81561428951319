import {
  REQUEST_TRAININGS,
  RECEIVE_TRAININGS,
  DELETE_TRAININGPLAN_SUCCESS,
  DELETE_TRAININGPLAN_ERROR,
  SAVE_TRAININGPLAN_SUCCESS,
  SAVE_TRAININGPLAN_ERROR,
  REQUEST_ALL_WORKERS,
  RECEIVE_ALL_WORKERS,
  REQUEST_ALL_TRAININGS,
  RECEIVE_ALL_TRAININGS,
  REQUEST_MAX_WEEKS,
  RECEIVE_MAX_WEEKS,
  TRAININGPLAN_ERROR
} from "./types";

const initialState = {
  trainings: []
};
export default function trainingReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TRAININGS:
      return state;
    case RECEIVE_TRAININGS:
      // have correct object array here in response
      return Object.assign({}, state, {
        trainings: action.payload,
        isFetching: false
      });
    case REQUEST_ALL_WORKERS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_ALL_WORKERS:
      return Object.assign({}, state, {
        allWorkers: action.response,
        isFetching: false
      });
    case REQUEST_ALL_TRAININGS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_ALL_TRAININGS:
      return Object.assign({}, state, {
        allTrainings: action.response,
        isFetching: false
      });
    case REQUEST_MAX_WEEKS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_MAX_WEEKS:
      return Object.assign({}, state, {
        maxWeeks: action.response,
        isFetching: false
      });
    case DELETE_TRAININGPLAN_SUCCESS:
      const list = state.trainings.slice();
      list.splice(action.id, 1);
      return Object.assign({}, state, {
        trainings: list,
        isFetching: false
      });
    // return Object.assign({}, )
    case DELETE_TRAININGPLAN_ERROR:
      return state;
    case SAVE_TRAININGPLAN_ERROR:
      console.log(action.error);
      return state;
    case SAVE_TRAININGPLAN_SUCCESS:
      // todo find item and update with new id number
      if (action.payload.newItem) {
        //    let tmpList = state.trainings.slice()
        //    tmpList[index].ID = action.data
        const training = JSON.parse(action.payload.result);
        return Object.assign({}, state, {
          trainings: [...state.trainings, training]
        });
      }
      // updated
      // need to slice / concat to replace updated item
      const returnRes = JSON.parse(action.payload.result);

      const index = state.trainings.findIndex(m => m.Id === returnRes.Id);
      return Object.assign({}, state, {
        trainings: state.trainings
          .slice(0, index)
          .concat(returnRes)
          .concat(state.trainings.slice(index + 1))
      });

    case TRAININGPLAN_ERROR:
      return state;
    default:
      return state;
  }
}
