import React from "react";

const ExecutionTooltip = () => (
    <div style={{textAlign: "left"}}>
      <p>Follow the steps in this checklist to execute your project. </p>
      <p> Complete Steps 1 -5 for each week until you complete the project.</p>
    </div>
  )

export default ExecutionTooltip
