
export const getUserChecks = result => {
  const returnObj = {
    message: result.statusMessage,
    status: result.status,
    user: result.payload
  };
  if (result.status === "success") {
    returnObj.user = result.payload;
  } else if (result.status === "warning") {
    returnObj.status = "warning";
    returnObj.message = result.statusMessagee;
  } else if (result.statusMessage === "invaliduser.") {
    returnObj.status = "error";
    returnObj.message =
      "Invalid username.  If you do not remember your username, please check your registration email or contact support at support@simulationpl.com";
    returnObj.user = {};
  } else if (
    result.payload.IsLockedOut ||
    result.statusMessage.includes("locked")
  ) {
    returnObj.status = "error";
    returnObj.message =
      "Your account has been locked due to too many password attempts (more than 20).  Please contact support at support@simulationpl.com to get your account unlocked";
  } else if (
    result.payload.PasswordQuestion === null ||
    result.payload.PasswordQuestion === ""
  ) {
    returnObj.user = {};
    returnObj.status = "error";
    returnObj.message =
      "There does not appear to be a security question on file for this username so the password cannot be reset. \n\nPlease refer to your original purchase email for your temporary password and use it to complete the registration process.";
  } else if (
    !result.payload.IsApproved &&
    Object.prototype.hasOwnProperty.call(result.payload, "PasswordQuestion") &&
    !result.payload.PasswordQuestion.length === 0
  ) {
    returnObj.user = {};
    returnObj.status = "error";
    returnObj.message = `${"We already sent you a Temporary Password that should still be valid.  The email address " +
      "we used was "}${
      result.payload.Email
    }.  Go back into your inbox and find the most recent password change email from us.  If you cannot find it, you will need to ask  support@simulationpl.com to reset your password.`;
  } else if (result.statusMessage === "License Expired") {
    returnObj.user = {};
    returnObj.status = false;
    returnObj.message =
      "Your license appears to have expired.  If you believe this is an error or if you want to request an extension, please contact support@simulationpl.com.  Be sure to provide your username and order number in the email.";
  }

  return returnObj;
};

export const authenticateChecks = result => {
  const returnObj = {
    message: result.statusMessage,
    status: result.status,
    statusCode: result.statusMessage,
    statusTarget: "",
    user: result.payload
  };
  if (result.status === "success" && result.statusMessage === "success") {
    returnObj.user = result.payload;
  } else if (result.statusMessage === "invaliduser") {
    returnObj.status = "error";
    returnObj.message = "invalid user name entered";
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
  } else if (result.statusMessage === "incorrectpassword") {
    returnObj.status = "error";
    returnObj.message = "Incorrect password, please retry";
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "password";
  } else if (result.statusMessage === "resetpassword") {
    returnObj.status = "error";
    returnObj.message =
      "Your temporary password needs to be changed before continuing.";
    returnObj.user = {};
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "password";
  } else if (result.statusMessage === "needsregistration") {
    returnObj.status = "error";
    returnObj.message =
      "Switching you to registration, please complete before continuing.";
    returnObj.user = {};
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
  } else if (
    result.payload.IsLockedOut ||
    result.statusMessage.includes("locked")
  ) {
    returnObj.status = "error";
    returnObj.message =
      "Your account has been locked due to too many password attempts (more than 20).  Please contact support at support@simulationpl.com to get your account unlocked";
    returnObj.statusCode = result.statusMessage;
  } else if (
    result.payload.PasswordQuestion === null ||
    result.payload.PasswordQuestion === ""
  ) {
    returnObj.user = {};
    returnObj.status = "error";
    returnObj.message =
      "There does not appear to be a security question on file for this username so the password cannot be reset. \n\nPlease refer to your original purchase email for your temporary password and use it to complete the registration process.";
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
  } else if (
    !result.payload.IsApproved &&
    Object.prototype.hasOwnProperty.call(result.payload, "PasswordQuestion") &&
    !result.payload.PasswordQuestion.length === 0
  ) {
    returnObj.user = {};
    returnObj.status = "error";
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
    returnObj.message = `${"We already sent you a Temporary Password that should still be valid.  The email address " +
      "we used was "}${
      result.payload.Email
    }.  Go back into your inbox and find the most recent password change email from us.  If you cannot find it, you will need to ask  support@simulationpl.com to reset your password.`;
  } else if (result.statusMessage === "License Expired") {
    returnObj.user = {};
    returnObj.status = false;
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
    returnObj.message =
      "Your license appears to have expired.  If you believe this is an error or if you want to request an extension, please contact support@simulationpl.com.  Be sure to provide your username and order number in the email.";
  }

  return returnObj;
};

export const registerChecks = result => {
  const returnObj = {
    message: result.statusMessage,
    status: result.status,
    statusCode: result.statusMessage,
    statusTarget: "",
    user: result.payload
  };
  if (result.status === "success" && result.statusMessage === "success") {
    returnObj.user = result.payload;
    // } else if (result.status === "error" && result.statusMessage === "success") {
    //   returnObj.user = result.payload;
  } else {
    returnObj.status = "error";
    returnObj.message = result.statusMessage;
    returnObj.statusCode = result.statusMessage;
    returnObj.statusTarget = "username";
  }

  return returnObj;
};
