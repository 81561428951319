import React from "react";
import { Absent } from "routes/WorkWeek/components/summary/Absent";
import {
  ReportHeader,
  Weekday,
  Condition,
  SectionHeader,
  Staffing,
  SectionTitle,
  TaskStatus,
  ProjectStatus,
  WeeklyResourceHours,
  TaskHours,
  TaskAssignments,
  NoWork,
  Meeting,
  Conference,
  MissedInteraction,
  StakeholderInteraction,
  DelayedTask,
  EndReport,
  TaskComplete,
  TaskWillBeDelayed,
  IndvTraining,
  TeamTraining,
  IndvReward,
  TeamReward,
  PlayScript,
  VolOvertime,
  Reassign,
  Footnote,
  WorkerRemoved
} from "./index";
import IdleWorker from "./Components/IdleWorker"
import Release from "./Components/Release"
import ReassignDebug from "./Components/ReassignDebug";

import ConditionDebug from "./Components/ConditionDebug";
import ResponseDebug from "./Components/ResponseDebug";
import StakeholderDebug from "./Components/StakeholderDebug"
import ProjectDebug from "./Components/ProjectDebug"

// class HistoryWeek extends React.Component {
export const HistoryWeek = props => {
  const renderChildren = () => {
    const children = [];
    const weeklyResources = [];
    let weeklyHeader = {};
    let weeklyFooter = {};
    const taskHours = [];
    let taskHoursHeader = {};
    let taskHoursFooter = {};
    const assignments = [];
    let assignmentHeader = {};
    let i = 1;
    let debugRecordsVisible = false;

    for (const comp of props.componentList) {
      if (comp.ComponentName.toLowerCase() === "reporthdr") {
        children.push(<ReportHeader key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "weekday") {
        children.push(<Weekday key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "endreport") {
        children.push(<EndReport key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "footnote") {
        children.push(<Footnote key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "condition") {
        children.push(<Condition key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "projassist") {
        children.push(<PlayScript key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "meeting") {
        children.push(<Meeting key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "missedmtg" || comp.ComponentName.toLowerCase() === "missedmtgchg" ) {
        children.push(
          <MissedInteraction key={i += 1} properties={comp.Properties} />
        );
      } else if (comp.ComponentName.toLowerCase() === "missedconf") {
        children.push(
          <MissedInteraction key={i += 1} properties={comp.Properties} />
        );
      } else if (comp.ComponentName.toLowerCase() === "absent") {
        children.push(<Absent key={i += 1} properties={comp.Properties} showImage={true}/>);
      } else if (comp.ComponentName.toLowerCase() === "conference") {
        children.push(<Conference key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "delayedtask") {
        children.push(<DelayedTask key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "idle") {
        children.push(<IdleWorker key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "nowork") {
        children.push(<NoWork key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "trainingindv") {
        children.push(<IndvTraining key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "trainingteam") {
        children.push(<TeamTraining key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "indvreward") {
        children.push(<IndvReward key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "rewardteam") {
        children.push(<TeamReward key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "volovertime") {
        children.push(<VolOvertime key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "stakeholderinteraction") {
        children.push(
          <StakeholderInteraction key={i += 1} properties={comp.Properties} />
        );
      } else if (comp.ComponentName.toLowerCase() === "missedinteraction") {
        children.push(
          <MissedInteraction key={i += 1} properties={comp.Properties} />
        );
      } else if (comp.ComponentName.toLowerCase() === "taskcomplete") {
        children.push(<TaskComplete key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "taskwillbedelayed") {
        children.push(
          <TaskWillBeDelayed key={i += 1} properties={comp.Properties} />
        );
      } else if (comp.ComponentName.toLowerCase() === "weeklysummary") {
        children.push(
          <SectionHeader key={i += 1} headerText='Weekly Summary Reports' />
        );
    
        const weeklyHeaderComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "weeklyresourcehrsheader");
        const weeklyResourceComponents = props.componentList.filter(comp => comp.ComponentName.toLowerCase() === "weeklyhours");
        const weeklyFooterComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "weeklyresourcehrsfooter");
    
        let weeklyHeader = {};
        let weeklyFooter = {};
        let weeklyResources = [];
    
        if (weeklyHeaderComponent) {
          weeklyHeader = { ...weeklyHeaderComponent.Properties };
        }
        if (weeklyFooterComponent) {
          weeklyFooter = { ...weeklyFooterComponent.Properties };
        }
    
        weeklyResourceComponents.forEach(comp => {
          weeklyResources.push(comp.Properties);
        });
    
        if (Object.keys(weeklyFooter).length !== 0) {
          children.push(
            <SectionTitle key={i += 1} titleText='Weekly Resource Hours' />
          );
          children.push(
            <WeeklyResourceHours
              key={i += 1}
              productId={props.productId}
              header={weeklyHeader}
              footer={weeklyFooter}
              properties={weeklyResources}
            />
          );
        }
      } else if (comp.ComponentName.toLowerCase() === "workeravailability") {
        children.push(
          <SectionTitle key={i += 1} titleText='Worker Availability Report' />
        );
      } else if (comp.ComponentName.toLowerCase() === "staffing") {
        children.push(<Staffing key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "reassign") {
        children.push(<Reassign key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "workerremoved") {
        children.push(<WorkerRemoved key={i += 1} properties = {comp.Properties} />)
      } else if (comp.ComponentName.toLowerCase() === "release") {
        children.push(<Release key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "conditiondebug" && debugRecordsVisible) {
        children.push(<ConditionDebug key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "reassigndebug" && debugRecordsVisible) {
        children.push(<ReassignDebug key={i += 1} properties={comp.Properties}  />);
      } else if (comp.ComponentName.toLowerCase() === "responsedebug" && debugRecordsVisible) {
        children.push(<ResponseDebug key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "projectdebug" && debugRecordsVisible) {
        children.push(<ProjectDebug key={i += 1} properties={comp.Properties} />);
      } else if (comp.ComponentName.toLowerCase() === "stakeholderdebug" && debugRecordsVisible) {
        children.push(<StakeholderDebug key={i += 1} properties={comp.Properties} />);
      }
    }
    

const assignmentHeaderComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "initialtaskassignmentheader");
const assignmentComponents = props.componentList.filter(comp => comp.ComponentName.toLowerCase() === "initialtaskassignment");
const assignmentEndComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "initialtaskassignmentend");

if (assignmentHeaderComponent) {
  assignmentHeader = { ...assignmentHeaderComponent.Properties };
}

assignmentComponents.forEach(comp => {
  assignments.push(comp.Properties);
});

if (assignmentEndComponent) {
  children.push(
    <SectionTitle key={i += 1} titleText='Initial Task Assignment' />
  );
  children.push(
    <TaskAssignments
      key={i += 1}
      header={assignmentHeader}
      properties={assignments}
    />
  );
}
const taskHoursHeaderComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "taskhoursheader");
const taskHoursComponents = props.componentList.filter(comp => comp.ComponentName.toLowerCase() === "taskhours");
const taskHoursFooterComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "taskhoursfooter");

if (taskHoursHeaderComponent) {
  taskHoursHeader = Object.assign({}, taskHoursHeaderComponent.Properties);
}
if (taskHoursFooterComponent) {
  taskHoursFooter = Object.assign({}, taskHoursFooterComponent.Properties);
}

taskHoursComponents.forEach(comp => {
  taskHours.push(comp.Properties);
});

if (Object.keys(taskHoursFooter).length !== 0) {
  children.push(
    <SectionTitle key={i += 1} titleText='Task Hours Report' />
  );
  children.push(
    <TaskHours
      key={i += 1}
      header={taskHoursHeader}
      footer={taskHoursFooter}
      properties={taskHours}
    />
  );
}
const taskStatusComponents = props.componentList.filter(comp => comp.ComponentName.toLowerCase() === "taskstatus");
const taskStatusHeaderComponent = props.componentList.find(comp => comp.ComponentName.toLowerCase() === "taskstatusheader");

if (taskStatusHeaderComponent) {
  children.push(
    <SectionTitle key={i += 1} titleText='Task Status Report' />
  );
}

taskStatusComponents.sort((a, b) => a.Properties.task - b.Properties.task);

for (const component of taskStatusComponents) {
  children.push(<TaskStatus key={i += 1} properties={component.Properties} />);
}

    const projectStatusComponents = props.componentList.filter(comp => comp.ComponentName.toLowerCase() === "projectstatus");
    if (projectStatusComponents.length > 0) {
      for (const component of projectStatusComponents) {
        children.push(<ProjectStatus key={i += 1} properties={component.Properties} />);
      }
    } 
     return children;
  };
  return (
    <div id={`history${props.weekNumber.toString()}`}>{renderChildren()}</div>
  );
};