import React from "react";
import { Button } from "reactstrap";

const FooterAction = props => {
  if (props.approvalStatus === "success") {
    return <Button color="link">Submit Plan for Approval</Button>;
  }
  if (props.checkResults.length > 0) {
    return (
      <Button
        outline
        color="primary"
        onClick={props.doBudgetChecks}>
        Re-check Plan
      </Button>
    );
  }
  return <span />;
};

export default FooterAction;
