import {
  REQUEST_STAKEHOLDERPLAN,
  RECEIVE_STAKEHOLDERPLAN,
  // SAVE_STAKEHOLDERPLAN_SUCCESS,
  SAVE_STAKEHOLDERPLAN_ERROR,
  STAKEHOLDER_ERROR
} from "./types";

const initialState = {
  detailInfoWeekMap: [],
  stakeholderPlan: []
};
export default function stakeholderReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_STAKEHOLDERPLAN:
      return state;
    case RECEIVE_STAKEHOLDERPLAN:
      return Object.assign({}, state, {
        ...state,
        stakeholderPlan: action.payload.StakeholderPlanDisplayList,
        detailInfoWeekMap: action.payload.DetailInfoWeekMap
      });
    case SAVE_STAKEHOLDERPLAN_ERROR:
      console.log(action.error);
      return state;
    case STAKEHOLDER_ERROR:
      console.log(action.error);
      return state;

    default:
      return state;
  }
}
