import React from "react";

const Content = (props) => {
  if (props.budgetIsLocked === true) {
    return (
      <div className="alert alert-info">
        Your budget is locked, no further approval needed.
      </div>
    );
  }

  return (
    <div>
      <p><b>Instructions:</b></p>
      <p>
        Before submitting your budget for approval, use the <b>Run Plan Check</b> button
        to:
      </p>
      <ul>
        <li>
          Check your budget for any Planned Budget items that are still $0. If
          you find any, then you have more planning to do.
        </li>
        <li>
          Compare your Planned Budget numbers with the corresponding Approved
          Budget numbers to identify any under or over-planned items. Check and
          adjust if there are any big variances between the Planned and Approved
          numbers.
        </li>
      </ul>
      <p>
        <b>Interpreting the results:</b> </p>
        <ul>
          <li>
            If all checks pass without warnings (green), you will have access to
            the <b>Request Approval</b> button below to submit your budget for
            approval.
          </li>
          <li>
            If checks pass but there are warnings, you will have access to the  <b>Request Approval</b> button below to submit your budget for approval{" "}
            <b>BUT</b> you should check the issues below in the Check Log panel
            below to determine what the problems are and whether you should
            address the before requesting approval.
          </li>
          <li>
            If any check fails, the errors will be described in the Check Log
            below and you must correct the before you are able to request
            approval.
          </li>
        </ul>
     
      <p>
        Please be aware that once management approves your Planned Budget, it
        will be locked. You will be able to update your plans, but the plan
        changes will not impact your Planned Budget numbers.
      </p>
    </div>
  );
};

export default Content;
