import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectedLastWeek, task2Complete } from "redux/Project/selectors";
import DeleteAction from "components/Resource/DeleteAction";
import ShiftForwardAction from "components/Resource/ShiftForwardAction";
import ShiftBackwardAction from "components/Resource/ShiftBackwardAction";

const ButtonActions = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method


  const currentProjectWeek = content.project.displayProjectWeek;

  // (cell, row, formatExtraData, rowIdx)
  const week = `W${currentProjectWeek.toString()}`;
  const prevWeek = `W${(currentProjectWeek - 1).toString()}`; // needed for shift  forward function

  const futureWeek = `W${(currentProjectWeek + 1).toString()}`; // needed for shift back function
  const maxWeeks = getProjectedLastWeek(content);
  const { row } = props;
  const { rowIdx } = props;
  var okToDisplay = true;
  if (row.ResourceName === "David Lewis" &&
    content.auth.productId === 38 &&
    task2Complete(content) === false) {
    okToDisplay = false;
  }

  if (
    content.auth.productId === 50 &&
    content.project.currentProjectWeek < 3 &&
    row.WorkerStatus === "wsOnProject") {
    okToDisplay = false;
  }

 
  return (
    <div>
      {!okToDisplay ? <span />
        : 

          <DeleteAction
            cellInfo={props.column}
            row={row}
            rowIdx={rowIdx}
            toggleLoading={props.toggleLoading}
            currentProjectWeek={currentProjectWeek}
          />


        }
      {currentProjectWeek > 1 &&
        (row[prevWeek] !== "Staff" ||
          row.WorkerStatusCode === "wsOnProject") && (
          <ShiftForwardAction
            cellInfo={props.column}
            row={row}
            rowIdx={rowIdx}
            currentProjectWeek={currentProjectWeek}
            prevWeek={prevWeek}
            maxWeeks={maxWeeks}
          />
        )}
      {currentProjectWeek > 1 &&
        row[prevWeek] !== "Staff" &&
        row[week] !== "Staff" && (
          <ShiftBackwardAction
            cellInfo={props.column}

            row={row}
            rowIdx={rowIdx}
            currentProjectWeek={currentProjectWeek}
            futureWeek={futureWeek}
            maxWeeks={maxWeeks}
          />

        )}

    </div>
  );
};

export default ButtonActions;
