// routes/Welcome/index.js
//
/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { logout, updateCheckListItem } from "redux/Authentication/thunks";
import { openModal } from "redux/Modal/ModalModule";
import {
  dismissAlert,
  clearAlerts
} from "redux/Notification/NotificationModule";
import { ClassroomContainer } from "routes/Classroom";
import  StartRunContainer  from "routes/Projects/components/StartRun";

import {
  HELP_DOCUMENTATION_MODAL,
  ORIENTATION_MODAL
} from "routes/Modal/ModalTypes";

import "./Welcome.css";

class Welcome extends Component {

  componentDidMount = () => {
    /* Add mini-sidebar to body class, rather than in a div */
    if (document && document.body) {
      document.body.classList.add("mini-sidebar");
      document.body.classList.remove("gray-bg");
    }
        this.props.clearAlerts(); // dismiss the login alert
 
  };


  render() {
    let i = 1;
    return (
      <div className='component-App route-Welcome'>
        <Row>
          <Col md='4'>
            <Card>
              <CardHeader>Before you start</CardHeader>
              <CardBody>
                <p>
                  The orientation guide will introduce you to the characters,
                  SimProject and the references that will be helpful to you, as
                  you get ready to plan and execute your project.
                </p>
                <Button color='primary' onClick={this.showOrientationModal}>
                  <i className='fa fa-users' /> View Orientation Guide
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md='4'>
            <Card>
              <CardHeader>Getting Help</CardHeader>
              <CardBody>
                <p>
                  If at any point you need help or are struggling to use
                  SimProject, the help documentation should cover most of your
                  questions. You can access it from anywhere via the help icon
                  in the top right corner of your Welcome.
                </p>
                <p>
                  Before getting started, it may be a good idea to review it
                  now.
                </p>
                <Button color='primary' onClick={this.showHelpModal}>
                  <i className='fa fa-life-ring' /> View Help Documentation
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md='4'>
            <Card>
              <CardHeader>Checklist</CardHeader>
              <CardBody>
                <ListGroup className='todo-list m-t small-list'>
                  {this.props.checkListItems && this.props.checkListItems.map((row, index) => (
                    <ListGroupItem
                      id={(i += 1)}
                      key={(i += 1)}
                      color={row.ControlValue === 1 ? "success" : ""}
                    >
                      <span
                        color='link'
                        className='check-link'
                        key={(i += 1)}
                        id={(i += 1)}
                      >
                        <i
                          id={(i += 1)}
                          key={(i += 1)}
                          className={
                            row.ControlValue === 1
                              ? "fa fa-check-square"
                              : "fa fa-square-o"
                          }
                        />{" "}
                      </span>
                      <span
                        key={(i += 1)}
                        id={(i += 1)}
                        className={
                          row.ControlValue === 1
                            ? "m-l-xs todo-completed"
                            : "m-l-xs"
                        }
                      >
                        {row.DisplayText}
                      </span>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md='7'>
            <Card className='component-Classroom component-Box'>
              <CardHeader>
                {this.props.classroomId === 0
                  ? "First, assign yourself"
                  : "Classroom Information"}
              </CardHeader>
              <CardBody>
                <ClassroomContainer />
              </CardBody>
            </Card>
          </Col>

          <Col md='5'>
            <Card className='component-Box'>
              <CardHeader>Start your first Execution</CardHeader>
              <CardBody>
                <StartRunContainer />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
   MAPDISPATCHTOPROPS for the opoenModal function below * */
  // show the help modal but first update the
  // the checklist item
  showHelpModal = () => {
    for (let i = 0, l = this.props.checkListItems.length; i < l; i += 1) {
      if (
        this.props.checkListItems[i].ControlNumber === 4 &&
        this.props.checkListItems[i].ControlValue === 0
      ) {
        this.props.updateCheckListItem(this.props.userId, 4, "VIEW_HELP", true);
        break;
      }
    }
    this.props.showHelpModal();
  };

  // show the orientation modal but first update the
  // the checklist item
  showOrientationModal = () => {
    for (let i = 0, l = this.props.checkListItems.length; i < l; i += 1) {
      if (
        this.props.checkListItems[i].ControlNumber === 3 &&
        this.props.checkListItems[i].ControlValue === 0
      ) {
        this.props.updateCheckListItem(
          this.props.userId,
          3,
          "VIEW_ORIENTATION",
          true
        );
        break;
      }
    }
    this.props.showOrientationModal();
  };
}

const mapStateToProps = state => ({
  app: state.app,
  classroomId: state.auth && state.auth.studentInfo && 
    state.auth.studentInfo.ClassroomId ? state.auth.studentInfo.ClassroomId : 0,
  checkListItems: state.auth.checkListItems,
  username: state.auth && state.auth.user && 
  state.auth.user.UserName ? state.auth.user.UserName : "",
  userId: state.auth.userId
});

/** DEFINE THE DISPATCH OPEN MODAL TO OPEN YOUR NEW MODAL * */
/** format is:
 dispatch(openModal(<MODAL TYPE>, {props (optional), 'size - not yet implemented'})) * */
const mapDispatchToProps = dispatch => ({
  showHelpModal: () =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, {}, "modal-wide")),
  showOrientationModal: () =>
    dispatch(openModal(ORIENTATION_MODAL, {}, "modal-wide")),
  logout: () => dispatch(logout()),
  updateCheckListItem: (userId, controlNumber, controlType, isSet) =>
    dispatch(updateCheckListItem(userId, controlNumber, controlType, isSet)),
  dismissAlert: idx => dispatch(dismissAlert(idx)),
  clearAlerts: () => dispatch(clearAlerts())
});

export const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(
  Welcome
);
