import React from "react";
import imageURLs from "./images/imageURLs.js";

const EstimatedCostHelp = () => (
  <div>
    <h3>Estimated Cost at Completion Chart Info</h3>
    <p>
      The Estimate at Complete (EAC) chart is a trend chart tracking the projected final cost of the project. Watch your trend each week and adjust control your costs to meet your budget objective.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.EstimatedCostatCompletionChart}
      alt="report"
    />
  </div>
);

export default EstimatedCostHelp;
