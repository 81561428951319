import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  fetchResourcePlan,
  insertResourcePlan,
  saveResourcePlan,
  deleteResourcePlan,
  saveResourceItem,
  setFilterSkillId
} from "redux/Resource/thunks";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import {
  getActionTasks,
  getAvailableWorkers,
  getWorkerNames,
  getEnhancedResources,
  getActionTasksOptions
} from "redux/Resource/selectors";
import {
  getBudgetSummaryRow,
  getResourceBudgetSummary,
  getLastTask,
  getEnhancedTaskList,
  getProjectedLastWeek
} from "redux/Project/selectors";

import BudgetSummary from "routes/Budget/components/BudgetSummary";

import {
  toggleResourceBudget,
  toggleResourceInfo,
  toggleResourcePlan
} from "app/redux/actions";
import { addSingleAlert } from "redux/Notification/NotificationModule";

import { openModal } from "redux/Modal/ModalModule";
import {
  REFERENCE_MODAL,
  HELP_DOCUMENTATION_MODAL
} from "routes/Modal/ModalTypes";
import AvailableResourceList from "./components/AvailableResourceList";
import ResourcePlanInfo from "./components/ResourcePlanInfo";
import ResourceGrid from "./components/ResourceGrid";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";

const Resource = (props) => {
  const [activeTab, setActiveTab] = useState("resource-plan");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (props.runId > 0) {
      props.fetchResourcePlan(props.runId);
    }
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [props.runId]);

  return (
    <>
      <Row>
        <Col lg="12">
          <ResourcePlanInfo
            panelVisible={props.resourceInfoVisible}
            toggle={props.toggleResourceInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <BudgetSummary
            title="Resource Budget"
            panelOpen={props.resourceBudgetVisible}
            togglePanel={props.toggleResourceBudget}
            summaryType="resource"
            titleSummary="Labor Costs"
            titleApproved="Approved for Labor Costs"
            titlePlanned="Planned for Labor Costs"
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "available-resources"
                })}
                onClick={() => {
                  toggle("available-resources");
                }}
              >
                Available Resources
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} style={{height: "900"}}>
            <TabPane tabId="resource-plan">
              <ResourceGrid noHideToggle/>
            </TabPane>
            <TabPane tabId="available-resources">
              <AvailableResourceList noHideToggle />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  resource: state.planning.resource,
  resourcePlanDisplay: state.planning.resource.resourcePlanDisplay,
  availableWorkers: getAvailableWorkers(state),
  enhancedResources: getEnhancedResources(state), // also shows if on project
  enhancedTaskList: getEnhancedTaskList(state),
  lastTask: getLastTask(state),
  workerFixedList: state.project.workerFixedList,
  runId: state.project.runId,
  maxWeeks: state.project.maxWeeks,
  currentProjWeek: state.project.currentProjectWeek,
  displayProjectWeek: state.project.displayProjectWeek,
  projectedFinishWeek: getProjectedLastWeek(state),
  tasks: state.project.tasks,
  resourceActionTasks: state.project.resourceActionTasks,
  // resPlanColumnMetadata: getColumnMetadata(state),
  actionTasks: getActionTasks(state),
  workerNames: getWorkerNames(state),
  actionTaskOptions: getActionTasksOptions(state),
  workers: state.project.workers,
  skills: state.project.skills,
  detailInfoWeekMap: state.planning.resource.detailInfoWeekMap,
  resourceBudget: getResourceBudgetSummary(state),
  totalBudget: getBudgetSummaryRow(state),
  resourceInfoVisible: state.app.resourceInfoVisible,
  resourcePlanVisible: state.app.resourcePlanVisible,
  resourceBudgetVisible: state.app.resourceBudgetVisible,
  budgetIsLocked: state.project.budgetIsLocked,
  selectedFilterSkillId: state.planning.resource.selectedFilterSkillId
});

const mapDispatchToProps = (dispatch) => ({
  fetchResourcePlan: (runId) => dispatch(fetchResourcePlan(runId)),
  insertResourcePlan: (runId, data) =>
    dispatch(insertResourcePlan(runId, data)),
  saveResourcePlan: (data) => dispatch(saveResourcePlan(data)),
  setFilterSkillId: (id) => dispatch(setFilterSkillId(id)),
  deleteResourcePlan: (index, id, runId) =>
    dispatch(deleteResourcePlan(index, id, runId)),
  saveResourceItem: (data) => dispatch(saveResourceItem(data)),
  toggleResourceInfo: () => dispatch(toggleResourceInfo()),
  toggleResourcePlan: () => dispatch(toggleResourcePlan()),
  toggleResourceBudget: () => dispatch(toggleResourceBudget()),
  showReferences: (selectedKey) =>
    dispatch(openModal(REFERENCE_MODAL, { selectedKey }, "modal-wide")),
  showHelp: (selectedKey) =>
    dispatch(
      openModal(HELP_DOCUMENTATION_MODAL, { selectedKey }, "modal-wide")
    ),

  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
