import { handleActions } from "redux-actions";

import {
  PROCESS_EOD,
  PROCESS_EOW,
  PROCESS_EOP,
  PROCESS_EOS,
  RESET_WORKWEEK,
  PROCESS_CONDITION,
  PROCESS_CONDITION_COMPLETE,
  PROCESS_REASSIGN,
  PROCESS_REASSIGN_COMPLETE,
  PLAY_SCRIPT,
  REQUESTING_DATA,
  HIDE_CONDITION_PANEL,
  RECEIVE_CONDITIONS,
  SET_CONDITION_RESPONSE,
  RECEIVE_PRESENTATION_RUNDAY,
  PROCESS_STAFFING_PROBLEMS,
  RUN_WEEK_STARTED,
  CANSTUDENTRUN_SUCCESS,
  ENABLE_CONDITIONS_SUCCESS,
  RESET_CONDITIONS_SUCCESS,
  TOGGLE_AUTORUN,
  SET_AUTORUN_RESPONSETYPE,
  WORKWEEK_ERROR
} from "./types";

const initialState = {
  autorun: false,
  autorunResponseType: 0, // 0 = random, 1, 2, 3
  condition: {},
  conditionId: 0,
  runWeekResponseState: "",
  selectedResponse: 1,
  returnMessage: "",
  isFetching: false,
  runStarted: false,
  haveCondition: false,
  conditionOpen: false,
  haveReassign: false,
  reassignOpen: false,
  havePlayScript: false,
  playScriptOpen: false,
  playScriptList: [],
  staffingProblemList: [],
  haveStaffingProblem: false,
  historySummary: [],
  workWeekPctComplete: 0,
  runDay: 0,
  currentRunWeek: 0,
  canStudentRunWeek: true,
  studentRunWeekReturnObject: {},
  testConditionList: [],
  testConditionOptions: [],
  hasError: false,
  errorMessage: "",
  errorStacktrace: ""
};
// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [WORKWEEK_ERROR]: (state, {payload})  => ({
      ...state,
      hasError: payload.hasError,
      errorMessage: payload.errorMessage,
      errorStacktrace: payload.errorStacktrace
    }),
    [RUN_WEEK_STARTED]: state => ({
      ...state,
      runStarted: true,
      haveCondition: false,
      conditionOpen: false,
      haveReassign: false,
      reassignOpen: false,
      havePlayScript: false,
      playScriptOpen: false,
      playScriptList: [],
      staffingProblemList: [],
      haveStaffingProblem: false
    }),
    [REQUESTING_DATA]: (state, { payload }) => ({
      ...state,
      runStarted: false,
      haveCondition: false,
      conditionOpen: false,
      haveReassign: false,
      reassignOpen: false,
      havePlayScript: false,
      playScriptOpen: false,
      playScriptList: [],
      staffingProblemList: [],
      haveStaffingProblem: false
    }),
    [SET_CONDITION_RESPONSE]: (state, { payload }) => ({
      ...state,
      selectedResponse: payload
    }),
    [RECEIVE_PRESENTATION_RUNDAY]: (state, { payload }) => {
      // var currentWk = payload.presentationRunDay % 5;

      const currentWk = (payload.presentationRunDay - 1) / 5 + 1;

      return {
        ...state,
        runDay: payload.presentationRunDay,
        currentRunWeek: currentWk,
        historySummary: [],
        workWeekPctComplete: (payload.presentationRunDay % 5) / 5 * 100
      };
    },
    [HIDE_CONDITION_PANEL]: (state, { payload }) => ({
      ...state,
      haveCondition: false,
      conditionOpen: false,
      modalType: null,
      modalProps: {}
    }),
    [PROCESS_REASSIGN]: (state, { payload }) => ({
      ...state,
      haveReassign: true,
      condition: payload.conditionStatus,
      conditionId: payload.conditionStatus.CondId,
      selectedResponse: 1,
      reassignOpen: true,
      runWeekResponseState: "reassign",
      historySummary: payload.interimHistory
    }),
    [PROCESS_REASSIGN_COMPLETE]: (state, { payload }) => ({
      ...state,
      haveCondition: false,
      conditionOpen: false
    }),
    [PROCESS_CONDITION]: (state, { payload }) => ({
      ...state,
      haveCondition: true,
      condition: payload.conditionStatus,
      conditionId: payload.conditionStatus.ConditionId,
      historySummary: payload.interimHistory,
      selectedResponse: 1,
      conditionOpen: true,
      runWeekResponseState: "condition"
    }),
    [PROCESS_CONDITION_COMPLETE]: (state, { payload }) => ({
      ...state,
      haveCondition: false,
      conditionOpen: false
    }),
    [PLAY_SCRIPT]: (state, { payload }) => ({
      ...state,
      havePlayScript: true,
      playScriptList: payload.playScriptList,
      playScriptOpen: true,
      runWeekResponseState: "playscript",
      haveCondition: false,
      conditionOpen: false
    }),

    [PROCESS_STAFFING_PROBLEMS]: (state, { payload }) => ({
      ...state,

      staffingProblemList: payload,
      haveStaffingProblem: payload.length > 0
    }),
    [PROCESS_EOW]: (state, { payload }) => ({
      ...state,
      runStarted: false,
      haveCondition: false,
      conditionOpen: false,
      conditionId: 0,
      historySummary: payload.interimHistory,
      runWeekResponseState: "eow",
      workWeekPctComplete: 100
    }),
    [PROCESS_EOD]: (state, { payload }) => {
      let pctComplete = state.workWeekPctComplete;
      pctComplete += 20;

      return {
        ...state,
        runStarted: true,
        haveCondition: false,
        conditionId: 1,
        conditionOpen: false,
        runWeekResponseState: "eod",
        runDay: payload.currentProjectDay,
        historySummary: payload.interimHistory,
        condition: payload.conditionStatus,
        workWeekPctComplete: pctComplete
      };
    },
    [PROCESS_EOP]: (state, { payload }) => ({
      ...state,
      runStarted: false,
      haveCondition: false,
      conditionOpen: false,
      historySummary: payload.interimHistory,
      runWeekResponseState: "eop"
    }),
    [PROCESS_EOS]: (state, { payload }) => ({
      ...state,
      runStarted: true,
      haveCondition: false,
      conditionOpen: false,
      historySummary: payload.interimHistory,
      runWeekResponseState: "eos"
    }),
    [RESET_WORKWEEK]: state => ({
      ...state,
      runStarted: false,
      haveCondition: false,
      conditionOpen: false,
      runWeekResponseState: "",
      hasError: false,
      errorMessage: "",
      errorStacktrace: ""
    }),
    [CANSTUDENTRUN_SUCCESS]: (state, { payload }) => ({
      ...state,
      studentRunWeekReturnObject: payload
    }),
    [RECEIVE_CONDITIONS]: (state, { payload }) => {
      if (payload && payload.length > 0) {
        let conditions = [...payload];
        let opts = [];
        for (let condition of conditions) {
          opts.push({
            label: `${condition.ConditionId} : ${condition.Message}`,
            value: condition.ConditionId
          });
        }
        return {
          ...state,
          testConditionList: payload,
          testConditionOptions: [...opts]
        }
      } else {
        return {
          ...state
        }
      }
    },
    [ENABLE_CONDITIONS_SUCCESS]: (state, { payload }) => ({
      ...state
      // ,
      // testConditionList: payload
    }),
    [RESET_CONDITIONS_SUCCESS]: (state, { payload }) => ({
      ...state
      // ,
      // testConditionList: payload
    }),
    [TOGGLE_AUTORUN]: (state) => ({
      ...state,
      autorun: !state.autorun
    }),
      [SET_AUTORUN_RESPONSETYPE]: (state, {payload }) => ({
      ...state,
      autorunResponseType: payload
    }),
    default: state => ({
      ...state
    }),

  },
  initialState
);
