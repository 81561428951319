 import React from "react";
import {  CardFooter} from "reactstrap";

import ReferenceButton from "components/Info/ReferenceButton";
import InfoButton from "components/Info/InfoButton";
  
const InfoFooter = (props) => {
  return (

    <CardFooter style={{ minHeight: "50px", verticalAlign: "top", padding: "5px" }}>
        
      {props.showProjectLink !== false &&
        <ReferenceButton size="lg" selectedKey={props.selectedKey} />}
      <InfoButton selectedKey={props.selectedKey} size="lg" />
       
    </CardFooter>
  
  )
}
export default InfoFooter
