import React from "react";
import { useDispatch, useSelector } from "react-redux";
import accounting from "accounting";

import { BUDGET_MODAL } from "routes/Modal/ModalTypes";
import { openModal } from "redux/Modal/ModalModule";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  Collapse,
  Label,
  Input
} from "reactstrap";

import {
  getRecognitionBudgetSummary,
  getResourceBudgetSummary,
  getRiskBudgetSummary,
  getStakeholderBudgetSummary,
  getTrainingBudgetSummary,
  getBudgetSummaryRow
} from "redux/Project/selectors";

// for the accounting package
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

const BudgetSummary = (props) => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  const { summaryType } = props
  
  
  const getApprovedBudget = () => {
  
    switch (summaryType) {
      case "resource":
        return getResourceBudgetSummary(content).approvedBudget;
      case "risk":
        return getRiskBudgetSummary(content).approvedBudget;
      case "stakeholder":
        return getStakeholderBudgetSummary(content).approvedBudget;
      case "recognition":
        return getRecognitionBudgetSummary(content).approvedBudget;
      case "training":
        return getTrainingBudgetSummary(content).approvedBudget;
      default:
        return 0;
    }
  }

  const getPlannedBudget= () => {
    switch (summaryType) {
      case "resource":
        return getResourceBudgetSummary(content).plannedBudget;
      case "risk":
        return getRiskBudgetSummary(content).plannedBudget;
      case "stakeholder":
        return getStakeholderBudgetSummary(content).plannedBudget;
      case "recognition":
        return getRecognitionBudgetSummary(content).plannedBudget;
      case "training":
        return getTrainingBudgetSummary(content).plannedBudget;
      default:
        return 0;
    }
  }

  const getActualBudget = () => {
    switch (summaryType) {
      case "resource":
        return getResourceBudgetSummary(content).actualBudget;
      case "risk":
        return getRiskBudgetSummary(content).actualBudget;
      case "stakeholder":
        return getStakeholderBudgetSummary(content).actualBudget;
      case "recognition":
        return getRecognitionBudgetSummary(content).actualBudget;
      case "training":
        return getTrainingBudgetSummary(content).actualBudget;
      default:
        return 0;
    }
  }


    return (
      <Card>
        <CardHeader>
          <span className="float-left">
            {" "}
            <strong>{props.title}</strong>
          </span>
          <Label className="switch switch-default switch-primary">
            <Input
              type="checkbox"
              className="switch-input"
              onClick={() => props.togglePanel()}
              defaultChecked={props.panelOpen}
            />
            <span className="switch-label" />
            <span className="switch-handle" />
          </Label>
        </CardHeader>
        <Collapse isOpen={props.panelOpen}>
          <CardBody>
            <Row>
              <Col md="6">
                <h6 className="mt-2 mb-0">{props.titleApproved}</h6>
                <p>
                  <span>
                    {accounting.formatMoney(getApprovedBudget(props), options)}
                  </span>
                </p>
                <h6 className="mt-2 mb-0">{props.titlePlanned}</h6>
                <p className="warning">
                  <span>
                    {accounting.formatMoney(getPlannedBudget(props), options)}
                  </span>
                </p>
              </Col>
              <Col md="6">
                <h6 className="mt-2 mb-0">Total Approved Budget</h6>
                <p>
                  <span>
                    {accounting.formatMoney(
                      getBudgetSummaryRow(content).ApprovedBudget,
                      options
                    )}
                  </span>
                </p>
                <h6 className="mt-2 mb-0">Total Planned Budget</h6>
                <p>
                  <span>
                    {accounting.formatMoney(
                      getBudgetSummaryRow(content).PlannedCost,
                      options
                    )}
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button color="secondary" onClick={() => dispatch(openModal(BUDGET_MODAL, {}, "modal-wide"))}>
                  View Full Budget
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    );
  

}
 
export default BudgetSummary
