// ------------------------------------
// Constants
// ------------------------------------
export const AUTH_REQUEST = "AUTH_REQUEST";
export const DATA_REQUEST = "DATA_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GETUSER_SUCCESS = "GETUSER_SUCCESS";
export const GETUSER_FAILURE = "GETUSER_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const LOGOUT = "LOGOUT";
export const STORE_USER = "STORE_USER";
export const SET_USERNAME = "SET_USERNAME";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_MASTERCONTROL_USER = "SET_MASTERCONTROL_USER";
export const EXPIRED_ACCOUNT = "EXPIRED_ACCOUNT";
export const RECEIVE_SUCCESS = "RECEIVE_SUCCESS";
export const RECEIVE_ERROR = "RECEIVE_ERROR";
export const SET_USER = "SET_USER";
export const GET_AVAILABLE_CLASSES_SUCCESS = "GET_AVAILABLE_CLASSES_SUCCESS";
export const GET_AVAILABLE_CLASSES_FAILURE = "GET_AVAILABLE_CLASSES_FAILURE";
export const PLACE_IN_CLASSROOM_SUCCESS = "PLACE_IN_CLASSROOM_SUCCESS";
export const PLACE_IN_CLASSROOM_FAILURE = "PLACE_IN_CLASSROOM_FAILURE";
export const LICENSE_ACCEPTED_SUCCESS = "LICENSE_ACCEPTED_SUCCESS";
export const STORE_PASSWORD = "STORE_PASSWORD";
export const UPDATE_USERCONTROL_SUCCESS = "UPDATE_USERCONTROL_SUCCESS";
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE";
export const GETMESSAGE_SUCCESS = "GETMESSAGE_SUCCESS";
export const GETMESSAGE_FAILURE = "GETMESSAGE_FAILURE";
