import React, { useRef, useEffect } from "react";
import { Button } from "reactstrap";

import { connect } from "react-redux";
import { getLastTask, getEnhancedTaskList } from "redux/Project/selectors";
import { openModal } from "redux/Modal/ModalModule";
import { TASK_MODAL } from "routes/Modal/ModalTypes";

import "./GanttChart.css";

const GanttChart = (props) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current && props.activeTab) {
      tableRef.current.scrollLeft = (props.displayProjectWeek - 1) * 100;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab]);

  const renderDayValues = (ganttObj) => {
    const tags = [];
    for (let i = 0; i < ganttObj.numDays; i += 1) {
      tags.push(ganttObj[i]);
    }
    return tags;
  };

  const renderWeekHeaderTags = () => {
    const headerTags = [];
    const finishWeeks = Math.ceil(props.lastTask.LateFinishDay / 5) + 1;
    for (let week = 1; week <= finishWeeks + 1; week += 1) {
      const wkHdr = `W${week}`;
      headerTags.push(
        <th key={week} colSpan="5" className="th-fixed">
          {wkHdr}
        </th>
      );
    }
    return headerTags;
  };

  /** Define a function to call the dispatch defined in the
   mapdispatchtoprops for the opoenModal function below * */
  const showTaskInformation = (id) => {
    const filteredTasks = props.enhancedTaskList.filter(
      (task) => task.TaskId === id
    );
    const thisTask = Object.assign({}, filteredTasks[0]);
    props.showTaskInformation(thisTask);
  };

  let i = 1;
  return (
    <div className="component-Table" tourstop="gantt" id="gantt">
      <div className="table-fixed" id="div-table-fixed" ref={tableRef}>
        <table className="table table-Gantt">
          <thead>
            <tr>
              <th className="col-fix--first">Task Name</th>
              {/* TODO - make these TH tags dynamic depending on #proj Weeks */}
              {renderWeekHeaderTags()}
            </tr>
          </thead>
          <tbody>
            {props.enhancedTaskList.map((row, index) => (
              <tr key={(i += 1)}>
                <td className="col-fix--first" key={(i += 1)}>
                  <Button
                    key={(i += 1)}
                    onClick={() => showTaskInformation(row.TaskId)}
                    color="link"
                  >
                    {row.ganttObj.displayName}
                  </Button>
                </td>
                {renderDayValues(row.ganttObj)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  lastTask: getLastTask(state),
  displayProjectWeek: state.project.displayProjectWeek,
  taskStatusList: state.project.taskStatusList,
  taskSkillList: state.project.taskSkillList,
  workerAssignmentList: state.project.workerAssignmentList,
  enhancedTaskList: getEnhancedTaskList(state)
});

const mapDispatchToProps = (dispatch) => ({
  showTaskInformation: (task) =>
    dispatch(openModal(TASK_MODAL, { task }, "modal-medium task-modal-medium"))
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttChart);
