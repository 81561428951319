import React from "react";
import {
  CardBody,
  Button,
  InputGroupAddon,
  Input,
  InputGroup,
  Label,
  Form
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import "../Registration.css";

const LoadingContainer = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
  min-height: 10px;
  color: black;
`;

class PartOne extends React.Component {
  constructor(props) {
    super(props);
    let uname = "";
    let tpass = "";
    if (
      Object.prototype.hasOwnProperty.call(this.props, "auth") &&
      Object.prototype.hasOwnProperty.call(this.props.auth, "user") &&
      Object.prototype.hasOwnProperty.call(this.props.auth.user, "UserName")
    ) {
      uname = this.props.auth.user.UserName;
      tpass = this.props.auth.user.variable;
    }
    this.state = {
      username: uname ? uname : "",
      tempPassword: tpass ? tpass : "",
      acceptedLicense: false,
      registrationError: "",
      loading: false,
      type: "password"
      //  securityAnswer: '',
    };
    this.verifySubmission = this.verifySubmission.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleAcceptLicenseChange = this.handleAcceptLicenseChange.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  componentWillUnmount = () => {
    this.props.dismissAlert(4);
  };

  showHide = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  };

  handleAcceptLicenseChange = (event) => {
    this.setState({ acceptedLicense: !this.state.acceptedLicense });
  };

  handleUsernameChange = (event) => {
    if (event && event.target && event.target.value) {
      this.setState({ username: event.target.value });
    }
  };
  handlePasswordChange = (event) => {
    if (event && event.target && event.target.value) {
      this.setState({ tempPassword: event.target.value });
    }
  };

  verifySubmission = async () => {
    if (!this.state.username) {
      this.setState({ registrationError: "Please enter your username." });

      return;
    }
    if (!this.state.tempPassword) {
      this.setState({
        registrationError: "Please enter your temporary password."
      });

      return;
    }

    if (this.state.acceptedLicense !== true) {
      this.setState({
        registrationError:
          "Please read and accept the License Agreement before continuing."
      });

      return;
    }
    this.setState({ registrationError: "", loading: true });

    await this.props
      .authenticate(this.state.username, this.state.tempPassword)
      .then((response) => {
        if (response) {
          this.props.setUsername(this.state.username);
          const alert = {
            id: 4,
            type: "info",
            headline: "Continuing registration",
            event: "registration",
            message: "Please complete part 2"
          };
          this.props.addAlert(alert, {}, 300, false);
          this.setState({
            registrationError: "",
            loading: false
          });
          this.loadPartTwo();
        } else {
          if (
            this.props.auth.message === "authenticated" &&
            this.props.auth.status === "success"
          ) {
            this.setState({
              registrationError:
                "Pre-authentication check alert:  You are already registered;  forwarding...",
              loading: false
            });
            return;
          }
          this.setState({
            registrationError: `Pre-authentication check error:   ${this.props.auth.message}`,
            loading: false
          });

          return;
        }
      });
  };

  loadPartTwo = () => {
    // continue to PartTwo
    this.props.submit(
      this.state.username,
      this.state.tempPassword,
      this.state.acceptedLicense
    );
  };

  render() {
    return (
      <CardBody
        className="card-body"
        style={{ overflow: "hidden", width: "500px" }}
      >
        {this.state.loading && (
          <LoadingContainer>
            <FontAwesomeIcon spin size="2x" icon={faSpinner} />{" "}
          </LoadingContainer>
        )}
        <p style={{ color: "red" }}>{this.state.registrationError}</p>
        <Form>
          <InputGroup className="mb-3 input-group">
            <InputGroupAddon addonType="prepend" style={{ marginRight: "5px" }}>
              <i className="fa fa-user" />
            </InputGroupAddon>

            <Input
              type="text"
              name="username"
              placeholder="Username"
              className="form-control"
              value={this.state.username}
              ref={(input) => {
                this.usernameInput = input;
              }}
              onChange={this.handleUsernameChange}
            />
          </InputGroup>
          <InputGroup className="mb-4 input-group">
            <InputGroupAddon addonType="prepend" style={{ marginRight: "5px" }}>
              <i className="fa fa-lock" />
            </InputGroupAddon>
            <Input
              type={this.state.type}
              name="password"
              placeholder="Password"
              className="password__input"
              value={this.state.tempPassword}
              // ref={(input) => {
              // 	this.passwordInput = input;
              // }}
              onChange={this.handlePasswordChange}
            />
            <span className="password__show" onClick={this.showHide}>
              {this.state.type === "password" ? (
                <i className="fa fa-eye" />
              ) : (
                <i className="fa fa-eye-slash" />
              )}
            </span>
          </InputGroup>

          <InputGroup>
            <Label check>
              <Input
                type="checkbox"
                name="terms-checkbox"
                id="terms-checkbox"
                onChange={this.handleAcceptLicenseChange}
                value={this.state.acceptedLicense}
              />
            </Label>
            <small>
              I accept the{" "}
              <Button
                color="link"
                className="p-0"
                onClick={this.props.showLicense}
                onMouseDown={this.props.showLicense}
                style={{
                  fontSize: "inherit",
                  position: "relative",
                  top: "-0.075rem"
                }}
              >
                Licence Agreement
              </Button>
            </small>
          </InputGroup>
        </Form>

        <Button
          style={{ marginTop: "15px" }}
          color="primary"
          onClick={(event) => this.verifySubmission(event)}
        >
          Continue
        </Button>
        {this.state.values && (
          <div>
            <h5>Submission values</h5>
            Invalid: {this.state.errors.join(", ")}
          </div>
        )}
      </CardBody>
    );
  }
}

export default PartOne;
