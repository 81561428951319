import React from "react";
import styled from "styled-components";

const StyledTextDiv = styled.div`
  fontWeight: normal;
color: #ff0000;
fontSize: 14;
fontFamily: "Arial"
`;


export const NoWork = props => {
  return (
    <div>

        You have received the following text message from the Controller's
        Office: <StyledTextDiv >{props.properties.reason}</StyledTextDiv>


    </div>
  );
};
