/* src/routes/Risk/index.js */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonToolbar,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fetchRisks, deleteRisk, saveRisk } from "redux/Risk/thunks";
import BudgetSummary from "routes/Budget/components/BudgetSummary";
import { openModal } from "redux/Modal/ModalModule";
import { ADD_RISK_MODAL } from "routes/Modal/ModalTypes";
import { toggleRiskInfo, toggleRiskBudget } from "app/redux/actions";

import ResourceGrid from "../Resource/components/ResourceGrid";
import RiskTable from "./components/RiskTable";
import RiskInfo from "./components/RiskInfo";
import CardHeaderBold from "components/styled/CardHeaderBold";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";

const Risk = (props) => {
  const [activeTab, setActiveTab] = useState("resource-plan");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    props.fetchRisks(props.runId);
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [props.runId]);

  const showAddRiskModal = (riskItem) => {
    props.showAddRiskModal(riskItem);
  };

  const showUpdateRiskModal = (riskItem) => {
    props.showAddRiskModal(riskItem);
  };

  return (
    <div>
      <Row>
        <Col lg="12">
          <RiskInfo
            panelVisible={props.riskInfoVisible}
            toggle={props.toggleRiskInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <BudgetSummary
            title="Risk/Opportunities Budget"
            panelOpen={props.riskBudgetVisible}
            togglePanel={props.toggleRiskBudget}
            summaryType="risk"
            titleSummary="Risk/Opportunities"
            titleApproved="Approved Risk/Opportunities"
            titlePlanned="Planned Risk/Opportunities"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeaderBold>Risk/Opportunities Plan
             <ButtonToolbar className="text-right">
              
            <Button id="addRisk" color="link" onClick={() => showAddRiskModal()}>
               <FontAwesomeIcon icon={faPlusCircle} size="2x"/>
            </Button>
              <UncontrolledTooltip id='addRisktt' placement='top' target='addRisk'>
                Add new risk/opportunity
              
                </UncontrolledTooltip>
            </ButtonToolbar>
            </CardHeaderBold>
            <CardBody>
              <RiskTable
                risks={props.risks}
                runId={props.runId}
                saveRisk={props.saveRisk}
                updateRisk={showUpdateRiskModal}
                deleteRisk={props.deleteRisk}
                modalOpen={props.modalOpen}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="resource-plan">
              <ResourceGrid noHideToggle />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  risks: state.planning.risk.risks,
  runId: state.project.runId,
  budgetList: state.project.budgetList,
  riskInfoVisible: state.app.riskInfoVisible,
  riskBudgetVisible: state.app.riskBudgetVisible,
  modalOpen: state.modal.open
});

const mapDispatchToProps = (dispatch) => ({
  fetchRisks: (runId) => dispatch(fetchRisks(runId)),
  saveRisk: (data) => dispatch(saveRisk(data)),
  deleteRisk: (index, id, runId) => dispatch(deleteRisk(index, id, runId)),
  showAddRiskModal: (riskItem) =>
    dispatch(
      openModal(ADD_RISK_MODAL, { riskItem }, "modal-lg")
    ),
  toggleRiskInfo: () => dispatch(toggleRiskInfo()),
  toggleRiskBudget: () => dispatch(toggleRiskBudget())
});

export default connect(mapStateToProps, mapDispatchToProps)(Risk);
