import React, { Component, Fragment } from "react";
import Select from "react-select";
import { updateClassStatus } from "redux/Instructor/thunks";
import { connect } from "react-redux";

const statusOptions = [{
		value: 0,
		label: "Running"
	},
	{
		value: 1,
		label: "Registration"
	},
	{
		value: 2,
		label: "Inactive"
	}];

class EditClassroomStatus extends Component {

	constructor(props) {
		super(props);
		this.updateStatus = this.updateStatus.bind(this);
	}

	updateStatus = (selected) => {

		this.props.updateClassStatus(
			this.props.currentClassroom.classroomId,
			selected.label)
			.then(() => {
				this.props.refresh()
			});
	};

	render() {
		return (
			<Fragment>
				<span>{this.props.currentClassroom.ClassroomStatus}</span>
				<Select
					id="statusSelect"
					defaultValue={statusOptions.filter(option => option.label === this.props.currentClassroom.classroomStatus)}
					options={statusOptions}
					placeholder="Select status ..."
					onChange={selected => {
						this.updateStatus(selected);
					}}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
	updateClassStatus: (cId, cStatus) => dispatch(updateClassStatus(cId, cStatus))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassroomStatus);
