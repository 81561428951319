import React from "react";
import imageURLs from "./images/imageURLs.js";


const ReserveBreakdownReportHelp = () => (
  <div>
    <h3>Reserve <span >Breakdown</span> Report Info</h3>

    <p>
      The Reserve Breakdown Report table provides additional detail into the reserve hours and costs on the project. The report provides weekly and total data on the following sources of reserves hours and costs:
    </p>
    <ul>
      <li>Resources assigned to tasks that are inactive because the task can’t start due to a predecessor task that has not completed</li>
      <li>Resources not assigned to a task</li>
      <li>Resources assigned to a task that has been delayed</li>
      <li>Project costs for non-human resources (cost only, no associated hours)</li>
    </ul>
    <h5 style={{ fontSize: "14px" }}>Reserve Breakdown Reports</h5>
    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginBottom: "5px" }}
      src={imageURLs.ReserveCost}
      alt="report"
    />
  </div>

);

export default ReserveBreakdownReportHelp;
