import { CardBody  } from "reactstrap";
import styled from "styled-components";


const StyledCardBody = styled(CardBody)`
  &.card-body {
    max-height: 200px;
  }
`;

export default StyledCardBody
