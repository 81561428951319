import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const headerRow = [
  "Resource Id",
  "Name",
  "Title",
  "Status",
  "Priority 1",
  "W1",
  "W2",
  "W3",
  "W4",
  "W5",
  "W6",
  "W7",
  "W8",
  "W9",
  "W10",
  "W11",
  "W12",
  "W13",
  "W14",
  "W15",
  "W16",
  "W17",
  "W18",
  "W19",
  "W20",
  "W21",
  "W22",
  "W23",
  "W24",
  "W25",
  "W26",
  "W27",
  "W28",
  "W29",
  "W30"
];

const CSVExporter = (props) => {
  const content = useSelector((state) => state); //this hook gives us redux store state
  const currentWeek = content.project.displayProjectWeek;

  
  const data = useMemo(() => {
    let rows = [];
    rows.push(headerRow);
    const findArrivalWeek = (row, currentStatus) => {
    for (const [key, value] of Object.entries(row)) {
      if (key.startsWith("W")) {
        const parsedWeek = parseInt(key.substring(1, key.length));
        if (isNaN(parsedWeek) || parsedWeek < currentWeek) {
          continue;
        }

        const parsed = parseInt(value, 10);
        if (isNaN(parsed)) {
          continue;
        }
        return `Arrives week ${key.substring(1, key.length)}`;
      }
    }
    return currentStatus;
  };

    if (props.data && props.data.length > 0) {
      let keys = Object.keys(props.data);
      for (let i = 0; i < keys.length; i++) {
        let row = props.data[i];
        let status = row.WorkerStatusOnProject;
        if (row.WorkerStatusOnProject.toLowerCase() === "available") {
          status = findArrivalWeek(row);
        }
        rows.push([
          row.ResourceId,
          row.ResourceName,
          row.SkillName,
          status,
          row.Priority1Task,
          row.W1 === "None" ? "" : row.W1,
          row.W2 === "None" ? "" : row.W2,
          row.W3 === "None" ? "" : row.W3,
          row.W4 === "None" ? "" : row.W4,
          row.W5 === "None" ? "" : row.W5,
          row.W6 === "None" ? "" : row.W6,
          row.W7 === "None" ? "" : row.W7,
          row.W8 === "None" ? "" : row.W8,
          row.W9 === "None" ? "" : row.W9,
          row.W10 === "None" ? "" : row.W10,
          row.W11 === "None" ? "" : row.W11,
          row.W12 === "None" ? "" : row.W12,
          row.W13 === "None" ? "" : row.W13,
          row.W14 === "None" ? "" : row.W14,
          row.W15 === "None" ? "" : row.W15,
          row.W16 === "None" ? "" : row.W16,
          row.W17 === "None" ? "" : row.W17,
          row.W18 === "None" ? "" : row.W18,
          row.W19 === "None" ? "" : row.W19,
          row.W20 === "None" ? "" : row.W20,
          row.W21 === "None" ? "" : row.W21,
          row.W22 === "None" ? "" : row.W22,
          row.W23 === "None" ? "" : row.W23,
          row.W24 === "None" ? "" : row.W24,
          row.W25 === "None" ? "" : row.W25,
          row.W26 === "None" ? "" : row.W26,
          row.W27 === "None" ? "" : row.W27,
          row.W28 === "None" ? "" : row.W28,
          row.W29 === "None" ? "" : row.W29,
          row.W30 === "None" ? "" : row.W30
        ]);
      }
    }
    return rows;
  }, [props.data,currentWeek]);

  if (data === [] || data === null || data === undefined) {
    return <span>No data to download</span>;
  }
  return (
    <CSVLink data={data} separator="," filename={`Resource-Plan-${content.project.runId ? content.project.runId : 0}-${content.auth.user.UserName ? content.auth.user.UserName : "loading"}`}>
      <Button color="primary">   <FontAwesomeIcon icon={faDownload} /> Save as CSV</Button>
    </CSVLink>
  );
};
export default CSVExporter;
