import React from "react";

export const MeetingKO = () => {
  return (
    <>
      <p>
        I had a great meeting with Kristine Olson, our government customer
        today.
        <br /> I can’t believe how long it took me to get the meeting setup. In
        our meeting I had a chance to bring Kristine up to speed on what we are
        doing to get the project off to a good start. We also discussed the
        critical issues; I was especially happy that I was able to explain the
        relationship between schedule, cost, and scope. In particular, the fact
        that if scope is increased, she can expect us to request more time
        and/or money. She seemed to understand and said that she would keep it
        in mind as the project progresses.
      </p>
    </>
  );
};
