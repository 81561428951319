/* eslint-disable max-len */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faUserCog,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";

export const Charter = (props) => (
  <div className="charter-div style-background-components">
    <header>
      <h2>Project Charter</h2>
    </header>
    <p>
      <FontAwesomeIcon icon={faBookmark} className="icons-presentation" />
      <strong>Project:</strong> Alliance Prototype
      <br />
      <FontAwesomeIcon icon={faFemale} className="icons-presentation" />
      <strong>Customer:</strong> Kristine Olson, VP Market Development
      <br />
      <FontAwesomeIcon icon={faUserCog} className="icons-presentation" />
      <strong>Sponsor:</strong> B. D. Smith, President & COO
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Description
    </h4>
    <p>
      The overall Alliance project represents a strategic effort to augment the
      sales and marketing capabilities of Uniworld with an E-commerce equipped
      web site. This web site is needed to help Uniworld to regain its global
      market dominance. Your customer is the sales and marketing organization of
      Uniworld. The end user is a volunteer group of current Uniworld product
      customers.
    </p>
    <p>
      To validate this objective and to gain a better understanding of web site
      deployment the company has budgeted $50,000 for the development of a
      prototype web site. Marketing has stated that it needs this prototype site
      to be fully demonstratable within 11 weeks in order to make a final
      determination as to the viability of a fully functional site.
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Deliverables
    </h4>
    <p>
      The Alliance Prototype product is to be composed of hardware and software
      required in establishing an E-commerce web site capable of demonstrating
      the feasibility of developing a fully functional site capable of taking
      product orders and generating product fulfillment. The deliverables for
      this project are the demonstration web site, site documentation, and
      recommended product architecture.
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Performance
    </h4>
    <p>
      <mark>
        The prototype web site will be accepted upon successful completion of
        the customer acceptance test with no{" "}
        <strong>more than 12 known defects</strong>
      </mark>{" "}
      which will represent an estimated Mean Time To Failure (MTTF) of 5 days.
      Furthermore no known defects can be present which could potentially cause
      system failure or major loss of functionality. The web site must be
      capable of demonstrating the feasibility of handling a peak load of 2000
      hits per hour without appreciable degradation in system response.
    </p>
    <footer>
      <p>ID #1015</p>
    </footer>
  </div>
);
