/* eslint-disable */
import React from "react";
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Collapse,
	ListGroup,
	ListGroupItem,
	TabContent,
	TabPane
} from "reactstrap";
import classnames from "classnames";

import OverviewHelp from "components/Help/OverviewHelp";
import GettingStartedHelp from "components/Help/GettingStartedHelp";
import ChecklistHelp from "components/Help/ChecklistHelp";
import SupportHelp from "components/Help/SupportHelp";
import ProjectPlanningHelp from "components/Help/ProjectPlanningHelp";
import ResourcePlanningHelp from "components/Help/ResourcePlanningHelp";
import RiskPlanningHelp from "components/Help/RiskPlanningHelp";
import TrainingPlanningHelp from "components/Help/TrainingPlanningHelp";
import StakeholderPlanningHelp from "components/Help/StakeholderPlanningHelp";
import RecognitionPlanningHelp from "components/Help/RecognitionPlanningHelp";
import BudgetApprovalHelp from "components/Help/BudgetApprovalHelp";
import StaffingHelp from "components/Help/StaffingHelp";
import InteractionHelp from "components/Help/InteractionHelp";
import WorkWeekHelp from "components/Help/WorkWeekHelp";
import HistoryHelp from "components/Help/HistoryHelp";
import QualityHelp from "components/Help/QualityHelp";
import EarnedValueHelp from "components/Help/EarnedValueHelp";
import EstimatedFinishHelp from "components/Help/EstimatedFinishHelp";
import EstimatedCostHelp from "components/Help/EstimatedCostHelp";
import ProjectPerformanceHelp from "components/Help/ProjectPerformanceHelp";
import GanttChartHelp from "components/Help/GanttChartHelp";
import NetworkDiagramHelp from "components/Help/NetworkDiagramHelp";
import LaborBreakdownReportHelp from "components/Help/LaborBreakdownReportHelp";
import ReserveBreakdownReportHelp from "components/Help/ReserveBreakdownReportHelp";
import CreateReportPDFsHelp from "components/Help/CreateReportPDFsHelp";

import "../../Reference/Reference.css";

export class HelpDocumentationModal extends React.Component {
	constructor(props) {
		super(props);
		let selectedKey = "overview";

		if (
			Object.hasOwnProperty.call(this.props, "selectedKey") &&
			this.props.selectedKey !== undefined &&
			this.props.selectedKey !== null
		) {
			selectedKey = this.props.selectedKey;
		}

		this.state = {
			collapse: 1,
			activeTab: selectedKey
		};
	}

	toggleCollapse = e => {
		const { event } = e.target.dataset;
		this.setState({
			collapse: this.state.collapse === Number(event) ? 0 : Number(event)
		});
	};

	toggleTab = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});

			const contentEl = document.querySelector(".row-Documentation_Content");
			// Scroll to the top when this route mounts
			contentEl.scrollIntoView();
		}
	};

	componentDidMount() {
		this.setState({ activeTab: this.props.selectedKey });
	}
	render() {
		return (
			<div
				ref={ref => {
					this.topRow = ref;
				}}
			>
				<Row>
					<Col xs="12">
						<h2>Help Documentation</h2>
					</Col>
					<Col xs="12">
						<p>
							If at any point you need help or are struggling to use the simulation, this Help documentation or the Project Reference Guide should answer most of your questions.
						</p>
				
					</Col>
				</Row>

				<Row>
					<Col md="4">
						<Card className="ListGroup-Card" key={1}>
							<CardHeader onClick={this.toggleCollapse} data-event={1}>
								Getting Started
							</CardHeader>
							<Collapse isOpen={this.state.collapse === 1}>
								<CardBody className="p-0">
									<ListGroup>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "overview"
											})}
											onClick={() => {
												this.toggleTab("overview");
											}}
										>
											Overview
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "manage-projects"
											})}
											onClick={() => {
												this.toggleTab("manage-projects");
											}}
										>
											Manage Projects
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "contacting-support"
											})}
											onClick={() => {
												this.toggleTab("contacting-support");
											}}
										>
											Contacting Support
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "checklist"
											})}
											onClick={() => {
												this.toggleTab("checklist");
											}}
										>
											Planning and Execution Checklist
										</ListGroupItem>
									</ListGroup>
								</CardBody>
							</Collapse>
						</Card>
						<Card className="ListGroup-Card" key={2}>
							<CardHeader onClick={this.toggleCollapse} data-event={2}>
								Planning your Project
							</CardHeader>
							<Collapse isOpen={this.state.collapse === 2}>
								<CardBody className="p-0">
									<ListGroup>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "planning-overview"
											})}
											onClick={() => {
												this.toggleTab("planning-overview");
											}}
										>
											Overview
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "resource"
											})}
											onClick={() => {
												this.toggleTab("resource");
											}}
										>
											Resource Plan
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "reserves"
											})}
											onClick={() => {
												this.toggleTab("reserves");
											}}
										>
											Risk/Opportunities Plan
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "training"
											})}
											onClick={() => {
												this.toggleTab("training");
											}}
										>
											Training Plan
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "stakeholder"
											})}
											onClick={() => {
												this.toggleTab("stakeholder");
											}}
										>
											Stakeholder Plan
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "recognition"
											})}
											onClick={() => {
												this.toggleTab("recognition");
											}}
										>
											Recognition Budget
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "budget-approval"
											})}
											onClick={() => {
												this.toggleTab("budget-approval");
											}}
										>
											Budget Approval
										</ListGroupItem>
									</ListGroup>
								</CardBody>
							</Collapse>
						</Card>
						<Card className="ListGroup-Card" key={3}>
							<CardHeader onClick={this.toggleCollapse} data-event={3}>
								Running your Project
							</CardHeader>
							<Collapse isOpen={this.state.collapse === 3}>
								<CardBody className="p-0">
									<ListGroup>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "staffing"
											})}
											onClick={() => {
												this.toggleTab("staffing");
											}}
										>
											Staffing Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "interaction"
											})}
											onClick={() => {
												this.toggleTab("interaction");
											}}
										>
											Interaction Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "work-week"
											})}
											onClick={() => {
												this.toggleTab("work-week");
											}}
										>
											Work Week Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "history"
											})}
											onClick={() => {
												this.toggleTab("history");
											}}
										>
											History Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "quality"
											})}
											onClick={() => {
												this.toggleTab("quality");
											}}
										>
											Quality Chart Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "ev"
											})}
											onClick={() => {
												this.toggleTab("ev");
											}}
										>
											Earned Value Chart Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active:
													this.state.activeTab === "ef"
											})}
											onClick={() => {
												this.toggleTab("ef");
											}}
										>
											Estimated Project Finished Chart Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "eac"
											})}
											onClick={() => {
												this.toggleTab("eac");
											}}
										>
											Estimated Cost at Completion Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active:
													this.state.activeTab === "ppi"
											})}
											onClick={() => {
												this.toggleTab("ppi");
											}}
										>
											Project Performance Index Chart Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "gantt"
											})}
											onClick={() => {
												this.toggleTab("gantt");
											}}
										>
											Gantt Chart Info
										</ListGroupItem>
										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "nd"
											})}
											onClick={() => {
												this.toggleTab("nd");
											}}
										>
											Network Diagram Info
										</ListGroupItem>

										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "labor"
											})}
											onClick={() => {
												this.toggleTab("labor");
											}}
										>
										Labor Breakdown Report
										</ListGroupItem>

										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "reserve"
											})}
											onClick={() => {
												this.toggleTab("reserve");
											}}
										>
										Reserve Breakdown Report
										</ListGroupItem>

										<ListGroupItem
											className={classnames({
												active: this.state.activeTab === "pdfs"
											})}
											onClick={() => {
												this.toggleTab("pdfs");
											}}
										>
											Create Report PDFs Info
										</ListGroupItem>
									</ListGroup>
								</CardBody>
							</Collapse>
						</Card>
					</Col>
					<Col md="8" className="row-Documentation_Content">
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId="overview">
								<OverviewHelp />
							</TabPane>
							<TabPane tabId="manage-projects">
								<GettingStartedHelp />
							</TabPane>
							<TabPane tabId="contacting-support">
								<SupportHelp />
							</TabPane>
							<TabPane tabId="checklist">
								<ChecklistHelp />
							</TabPane>
							<TabPane tabId="planning-overview">
								<ProjectPlanningHelp />
							</TabPane>
							<TabPane tabId="resource">
								<ResourcePlanningHelp />
							</TabPane>
							<TabPane tabId="reserves">
								<RiskPlanningHelp />
							</TabPane>
							<TabPane tabId="training">
								<TrainingPlanningHelp />
							</TabPane>
							<TabPane tabId="stakeholder">
								<StakeholderPlanningHelp />
							</TabPane>
							<TabPane tabId="recognition">
								<RecognitionPlanningHelp />
							</TabPane>
							<TabPane tabId="budget-approval">
								<BudgetApprovalHelp />
							</TabPane>
							<TabPane tabId="staffing">
								<StaffingHelp />
							</TabPane>
							<TabPane tabId="interaction">
								<InteractionHelp />
							</TabPane>
							<TabPane tabId="work-week">
								<WorkWeekHelp />
							</TabPane>
							<TabPane tabId="history">
								<HistoryHelp />
							</TabPane>
							<TabPane tabId="quality">
								<QualityHelp />
							</TabPane>
							<TabPane tabId="ev">
								<EarnedValueHelp />
							</TabPane>
							<TabPane tabId="ef">
								<EstimatedFinishHelp />
							</TabPane>
							<TabPane tabId="eac">
								<EstimatedCostHelp />
							</TabPane>
							<TabPane tabId="ppi">
								<ProjectPerformanceHelp />
							</TabPane>
							<TabPane tabId="gantt">
								<GanttChartHelp />
							</TabPane>
							<TabPane tabId="nd">
								<NetworkDiagramHelp />
							</TabPane>
							<TabPane tabId="labor">
								<LaborBreakdownReportHelp />
							</TabPane>
							<TabPane tabId="reserve">
								<ReserveBreakdownReportHelp />
							</TabPane>
							<TabPane tabId="pdfs">
								<CreateReportPDFsHelp />
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
		);
	}
}
