import { createAction } from "redux-actions";
import {
  REQUEST_RISKS,
  RECEIVE_RISKS,
  // SAVE_RISK_REQUEST,
  SAVE_RISK_SUCCESS,
  RISK_ERROR,
  DELETE_RISK_SUCCESS,
  DELETE_RISK_ERROR
} from "./types";

export const receiveRisks = createAction(RECEIVE_RISKS, payload => payload);

export const requestRisks = createAction(REQUEST_RISKS);

export const deleteSuccess = createAction(
  (DELETE_RISK_SUCCESS: index => index)
);

export const deleteError = createAction((DELETE_RISK_ERROR: error => error));

export const saveSuccess = createAction(
  (SAVE_RISK_SUCCESS: payload => payload)
);

export const actionError = createAction((RISK_ERROR: error => error));
