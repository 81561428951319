import React from "react";
import Resource from "components/Resource";

import { ListGroup, ListGroupItem } from "reactstrap";

const OnBoardingTable = ({ data }) => {
  return (
    <div>
      <ListGroup className="mb-2 b-b-0">
        {data.map((item) => (
          <ListGroupItem key={item.ResourceName}>
            <Resource row={item} status={item.WorkerStatusOnProject} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default OnBoardingTable;
