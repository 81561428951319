import React from "react";
import { connect } from "react-redux";
// component for checking internet connectivity
import { Offline, Online } from "react-detect-offline";
import { Switch, Route, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
// import ErrorBoundary from '../components/ErrorBoundary'
import * as Sentry from "@sentry/react";
import NotificationContainer from "components/Notification";
import Tour from "reactour";

import {
  getPlanningChecklistItems,
  getExecutionChecklistItems
} from "redux/Project/selectors";
import { updateProjectCheckListItem } from "redux/Project/thunks";
import { ModalContainer } from "routes/Modal/ModalContainer";

import { WelcomeContainer } from "routes/Welcome";
import { NotificationHistoryContainer } from "routes/Notification";
import { ManageProjectsContainer } from "routes/Projects";

import Settings from "routes/Settings/Settings";

import Overview from "routes/Overview";
import ResourceContainer from "routes/Resource";
import RiskContainer from "routes/Risk";
import RecognitionContainer from "routes/Recognition";
import StakeholderContainer from "routes/Stakeholder";
import TrainingContainer from "routes/Training";
import ApprovalContainer from "routes/Approval";

import AdminPanelContainer from "routes/Admin";
import AssignmentContainer from "routes/Assignment";
import { InteractionContainer } from "routes/Interaction";
import WorkWeekContainer from "routes/WorkWeek";
import ReviewProject from "routes/ReviewProject";

//Modification of the rendering of the references doc

import {
  AbsenteeismBis,
  CharterBis,
  OrgChartBis,
  OrgChartBiss,
  RecognitionBis,
  ResignationBis,
  StakeholdersBis,
  TaskContainer,
  TrainingBis
} from "../routes/Reference/Nog-components";

import {
  Absenteeism,
  BySkillContainer,
  Charter,
  Conferences,
  Meetings,
  OrgChart,
  Overtime,
  Quality,
  Recognition,
  Reserves,
  Resignation,
  Staffing,
  Stakeholders,
  SummaryContainer,
  Training,
  Vacations,
  WorkAssignments,
  WBSContainer
} from "../routes/Reference/components";

// Reports

import HistoryContainer from "routes/Report/HistoryContainer";
import ChartContainer from "routes/Report/ChartContainer";
import GanttChartContainer from "routes/Report/GanttChartContainer";
import NetworkDiagramContainer from "routes/Report/NetworkDiagramContainer";
import LaborCostSummaryContainer from "routes/Report/LaborCostSummaryContainer";
import ReserveSummaryContainer from "routes/Report/ReserveSummaryContainer";

import PdfReportsContainer from "routes/Report/PdfReportsContainer";

// classroom
import InstructorDashboard from "routes/Instructor/InstructorDashboard";
import ClassroomManagementContainer from "routes/Instructor/ClassroomManagement";
import StudentManagementContainer from "routes/Instructor/StudentManagement";
import StudentOverviewContainer from "routes/Instructor/StudentOverview";
import Budget from "routes/Budget";
import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import PageHeader from "components/PageHeader/PageHeader";
import Aside from "components/Aside/Aside";
import {
  addSingleAlert,
  dismissAlert,
  clearAlerts
} from "redux/Notification/NotificationModule";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";

import {
  toggleTourComponentOpen,
  toggleTourComponentClosed
} from "./redux/thunks";
import history from "../history";

export class App extends React.Component {
  componentDidMount() {
    if (this.props && this.props.auth && this.props.auth.username) {
      Sentry.setUser({ username:  this.props.auth.username, id: this.props.auth.userId});
    }
    if (this.props.location.pathname === "/planning") {
      history.push("/planning/overview");
    } else if (this.props.location.pathname === "/execution") {
      history.push("/execution/assignment");
    }
  }

  getFirstName = () => {
    if (
      Object.prototype.hasOwnProperty.call(this.props.auth, "studentInfo") &&
      Object.prototype.hasOwnProperty.call(
        this.props.auth.studentInfo,
        "FirstName"
      )
    ) {
      return this.props.auth.studentInfo.FirstName;
    }
    return "";
  };
  getLastName = () => {
    if (
      Object.prototype.hasOwnProperty.call(this.props.auth, "studentInfo") &&
      Object.prototype.hasOwnProperty.call(
        this.props.auth.studentInfo,
        "LastName"
      )
    ) {
      return this.props.auth.studentInfo.LastName;
    }
    return "";
  };
  // to figure out if the sidebar menu should show only limited links,
  // count how many runs this user has available or in process.
  getAvailableRunCount = () => {
    if (
      Object.prototype.hasOwnProperty.call(this.props, "auth") &&
      Object.prototype.hasOwnProperty.call(this.props.auth, "runDetails") &&
      this.props.auth.runDetails.length > 0
    ) {
      const availRuns = this.props.auth.runDetails.filter(
        (run) => run.RunId === 0 || run.RunStatus !== "Complete!"
      );
      return availRuns;
    }
    return 0;
  };

  // onTimeoutChange({ target: { value } }) {
  //   this.setState({ timeout: +value * 1000 });
  // }
  //
  //
  //
  stopTour = () => {
    this.props.stopTour();
  };
  handleDismissAlert = (idx) => {
    this.props.dismissAlert(idx);
  };

  render() {
    let productId = this.props.productId;
   
    return (
      <div className="app">
        {/* <ErrorBoundary> */}
        {/* <Online>Network connected</Online> turned off per samir
        <Offline>Network Disconnected!</Offline> */}

        <NotificationContainer />
        <Header name={`${this.getFirstName()} ${this.getLastName()}`} />
        <div className="app-body">
         <Sidebar
            productId={productId}
            budgetIsLocked={this.props.project.budgetIsLocked}
            projectReadOnly={this.props.project.projectReadOnly}
            username={this.props.auth.user && this.props.auth.user.UserName ?
              this.props.auth.user.UserName
              : "none"}
            user={this.props.auth.studentInfo}
            isInstructor={this.props.auth.userType === "Instructor"}
            noRunIdSelected={
              !!(
                this.props.runId === 0 ||
                this.props.runId === undefined ||
                this.getAvailableRunCount() < 1
              )
            }
            workWeekInProgress={
              !!(
                this.props.runStarted === false &&
                this.props.weekHasCompleted === false
              )
            }
          />
          <main className="main">
            <PageHeader
              title=""
              runId={this.props.project.runId}
              userType={this.props.auth.userType}
              displayProjectWeek={this.props.project.displayProjectWeek}
              projectedFinishWeek={this.props.project.projectedFinishWeek}
              tourstop="pageHeader"
            />
            <Container fluid>
              <Switch>
                <Route
                  exact
                  path="/welcome"
                  name="Welcome"
                  component={WelcomeContainer}
                />
                <Route
                  exact
                  path="/planning"
                  name="Overview"
                  component={Overview}
                />
                <Route
                  exact
                  path="/planning/overview"
                  name="Overview"
                  component={Overview}
                />
                <Route
                  exact
                  path="/planning/budget"
                  name="Budget"
                  component={Budget}
                />
                <Route
                  exact
                  path="/planning/approval"
                  name="Approval"
                  component={ApprovalContainer}
                />
                <Route
                  exact
                  path="/planning/recognition"
                  name="Recognition"
                  component={RecognitionContainer}
                />
                <Route
                  exact
                  path="/planning/resource"
                  name="Resource"
                  component={ResourceContainer}
                />
                <Route
                  exact
                  path="/planning/risk"
                  name="Risk"
                  component={RiskContainer}
                />
                <Route
                  exact
                  path="/planning/stakeholder"
                  name="Resource"
                  component={StakeholderContainer}
                />
                <Route
                  exact
                  path="/planning/training"
                  name="Training"
                  component={TrainingContainer}
                />

                <Route
                  exact
                  path="/execution"
                  name="Assignment"
                  component={AssignmentContainer}
                />
                <Route
                  exact
                  path="/execution/assignment"
                  name="Assignment"
                  component={AssignmentContainer}
                />
                   <Route
                  exact
                  path="/execution/budget"
                  name="Budget"
                  component={Budget}
                />
                <Route
                  exact
                  path="/execution/interaction"
                  name="Interaction"
                  component={InteractionContainer}
                />
                <Route
                  exact
                  path="/execution/workweek"
                  name="WorkWeek"
                  component={WorkWeekContainer}
                />
                <Route
                  exact
                  path="/documentation"
                  name="Documentation"
                  component={CharterBis}
                />
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/project"
                    name="Project "
                    component={CharterBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/project"
                    name="Project"
                    component={Charter}
                  />
                )}

                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/project/charter"
                    name="Project Charter"
                    component={CharterBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/project/charter"
                    name="Project Charter"
                    component={Charter}
                  />
                )}
                <Route
                  exact
                  path="/documentation/project/chart"
                  name="Organizational Chart"
                  render={(props) => {
                    if (productId === 50) {
                      return <OrgChartBiss productId={productId} {...props} />;
                    } else if (productId === 51) {
                      return <OrgChartBis productId={productId} {...props} />;
                    } else {
                      return <OrgChart productId={productId} {...props} />;
                    }
                  }}
                />

                <Route
                  exact
                  path="/documentation/project/wbs"
                  name="Work Breakdown Structure"
                  component={WBSContainer}
                />
                <Route
                  exact
                  path="/documentation/project/tasks"
                  name="Task Descriptions"
                  component={TaskContainer}
                />
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/policies"
                    name="Absenteeism"
                    component={AbsenteeismBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/policies"
                    name="Absenteeism"
                    component={Absenteeism}
                  />
                )}
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/policies/absenteeism"
                    name="Absenteeism"
                    component={AbsenteeismBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/policies/absenteeism"
                    name="Absenteeism"
                    component={Absenteeism}
                  />
                )}
                <Route
                  exact
                  path="/documentation/policies/conferences"
                  name="Conferences"
                  component={Conferences}
                />
                <Route
                  exact
                  path="/documentation/policies/meetings"
                  name="Meetings"
                  component={Meetings}
                />
                <Route
                  exact
                  path="/documentation/policies/overtime"
                  name="Overtime"
                  component={Overtime}
                />
                <Route
                  exact
                  path="/documentation/policies/quality"
                  name="Quality"
                  component={Quality}
                />
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/policies/recognition"
                    name="Recognition"
                    component={RecognitionBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/policies/recognition"
                    name="Recognition"
                    component={Recognition}
                  />
                )}
                <Route
                  exact
                  path="/documentation/policies/reserves"
                  name="Reserves"
                  component={Reserves}
                />
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/policies/resignation"
                    name="Resignation"
                    component={ResignationBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/policies/resignation"
                    name="Resignation"
                    component={Resignation}
                  />
                )}
                <Route
                  exact
                  path="/documentation/policies/staffing"
                  name="Staffing"
                  component={Staffing}
                />
                {productId === 50 || productId === 51 ? (
                  <Route
                    exact
                    path="/documentation/policies/stakeholders"
                    name="Stakeholders"
                    component={StakeholdersBis}
                  />
                ) : (
                  <Route
                    exact
                    path="/documentation/policies/stakeholders"
                    name="Stakeholders"
                    component={Stakeholders}
                  />
                )}
               {productId === 38 || productId === 43 ? (
                <Route
                  exact
                  path="/documentation/policies/training"
                  name="Training"
                  // component={Training}
                  render={(props) => (
                    <Training productId={productId} {...props} />
                  )}
                />
              ) : (
                <Route
                  exact
                  path="/documentation/policies/training"
                  name="Training"
                  // component={TrainingBis}
                  render={(props) => (
                    <TrainingBis productId={productId} {...props} />
                  )}
                />
              )}


                <Route
                  exact
                  path="/documentation/policies/vacations"
                  name="Vacations"
                  component={Vacations}
                />
                <Route
                  exact
                  path="/documentation/policies/assignments"
                  name="Work Assignments"
                  component={WorkAssignments}
                />
                <Route
                  exact
                  path="/documentation/resources"
                  name="Resources By Job Description"
                  component={BySkillContainer}
                />
                <Route
                  exact
                  path="/documentation/resources/byskill"
                  name="Resources By Job Description"
                  component={BySkillContainer}
                />
                <Route
                  exact
                  path="/documentation/resources/summary"
                  name="Resources Summary"
                  component={SummaryContainer}
                />

                <Route
                  exact
                  path="/reports/history"
                  name="History"
                  component={HistoryContainer}
                />
                <Route
                  exact
                  path="/reports/charts"
                  name="Charts"
                  component={ChartContainer}
                />
                <Route
                  exact
                  path="/reports/gantt"
                  name="GANTT"
                  component={GanttChartContainer}
                />
                 <Route
                  exact
                  path="/reports/budget"
                  name="Budget"
                  component={Budget}
                />
                <Route
                  exact
                  path="/reports/network"
                  name="ND"
                  component={NetworkDiagramContainer}
                />
                <Route
                  exact
                  path="/reports/labor"
                  name="LABOR"
                  component={LaborCostSummaryContainer}
                />
                 <Route
                  exact
                  path="/reports/reserve"
                  name="RESERVES"
                  component={ReserveSummaryContainer}
                />
                <Route
                  exact
                  path="/reports/pdf"
                  name="PDF"
                  component={PdfReportsContainer}
                />

                <Route
                  exact
                  path="/notifications"
                  name="Notification"
                  component={NotificationHistoryContainer}
                />
                <Route
                  exact
                  path="/projects"
                  name="Manage Projects"
                  component={ManageProjectsContainer}
                />
                <Route
                  exact
                  path="/review"
                  name="Review Project"
                  component={ReviewProject}
                />
                <Route
                  exact
                  path="/settings"
                  name="Settings"
                  component={Settings}
                />
                 <Route
                  exact
                  path="/instructor-dashboard"
                  name="Instructor Dashboard"
                  component={InstructorDashboard}
                />
                <Route
                  exact
                  path="/admin"
                  name="Admin"
                  component={AdminPanelContainer}
                />
              </Switch>
            </Container>
          </main>

          <Aside
            planningChecklist={this.props.planningChecklist}
            executionChecklist={this.props.executionChecklist}
            projectChecklist={this.props.projectChecklist}
            updateCheckListItem={this.props.updateProjectCheckListItem}
          />
        </div>
        <Footer />
        <ModalContainer />
        <Tour
          id="tour"
          onRequestClose={this.stopTour}
          steps={this.props.app.tourConfig}
          isOpen={this.props.app.isTourOpen}
          scrollDuration={200}
          maskClassName="mask"
          className="helper"
          updateDelay={20}
          startAt={0} // TODO bind this to props to open at beginning if user left off on last step otherwise, last step
        />
        {/* </ErrorBoundary> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal,
  auth: state.auth,
  project: state.project,
  app: state.app,
  notification: state.notification,
  runId: state.project.runId,
  weekHasCompleted: state.project.projectStatus.WeekHasCompleted,
  location: state.location,
  projectChecklist: state.project.projectCheckList,
  planningChecklist: getPlanningChecklistItems(state),
  executionChecklist: getExecutionChecklistItems(state),
  productId: state.auth.productId
});

const mapDispatchToProps = (dispatch) => ({
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  dismissAlert: (idx) => dispatch(dismissAlert(idx)),
  clearAlerts: () => dispatch(clearAlerts()),
  toggleTourComponentOpen: (toggleState, tourConfig) =>
    dispatch(toggleTourComponentOpen(toggleState, tourConfig)),
  stopTour: () => dispatch(toggleTourComponentClosed()),
  updateProjectCheckListItem: (clId, clNo, clVal) =>
    dispatch(updateProjectCheckListItem(clId, clNo, clVal))
});
const AppContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
export default AppContainer
