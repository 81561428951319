import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
const imgUrl = require("static/images/new-org.png").default;

export const OrgChart = () => (
  <div className="organisation-chart style-background-components">
    <h2>
      <FontAwesomeIcon icon={faSitemap} id="icon-chart" />
      Uniworld Enterprises Organization
    </h2>

    <img alt="org" src={imgUrl} />
  </div>
);
