import React from 'react'

const UpdatingFooter = (props) => {
  return (
    <div className="table-updates" >
      {props.updating === true
      ? <p>Updating...</p>
      : <span></span>}
    </div>
  )
}
export default UpdatingFooter
