import React, { useState } from "react";
import { useSelector } from "react-redux";
import OnBoardingTable from "./OnBoardingTable";
import { getCombinedWorkerResourceData } from "redux/Project/selectors";

import StaffingGrid from "./StaffingGrid";
import {
  Card,
  Row,
  CardHeader,
  CardBody,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import PlansCheckerResults from "./PlansCheckerResults";
import classnames from "classnames";
import CardHeaderBold from "components/styled/CardHeaderBold";

const StaffingContainer = (props) => {
  const content = useSelector((state) => state); //this hook gives us redux store state
  const [showStaffingCheck, setShowStaffingCheck] = useState(false);
  const [activeTab, setActiveTab] = useState("onboarding");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row>
      <Col md="9">
        <Card>
          <CardHeaderBold>
            <div>Staffing Plan</div>
            {/* <div className="text-right">
              <Button
                outline
                color="primary"
                onClick={() => toggle("plan-checks")}
              >
                Check Staffing Plan Against Resource Plan
              </Button>
            </div> */}
          </CardHeaderBold>

          <CardBody>
            <StaffingGrid
               id="container-staffing-grid"
              productId={content.auth.productId}
                data={props.data.filter(
                (d) => d.WorkerStatusCode === "wsOnProject" || d.WorkerStatusCode === "wsLeaves2Weeks" || d.WorkerStatusCode === "wsLeaves1Week"
              )}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="3">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "onboarding"
              })}
              onClick={() => {
                toggle("onboarding");
              }}
            >
              Currently Onboarding
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "plan-checks"
              })}
              onClick={() => {
                toggle("plan-checks");
              }}
            >
              View Staffing Issues
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="onboarding">
            <OnBoardingTable
              data={props.data
                .filter(
                  (d) =>
                    d.WorkerStatusCode !== "wsOnProject" &&
                    d.WorkerStatusCode !== "wsLeaves2Weeks" &&
                    d.WorkerStatusCode !== "wsNone" &&
                    d.WorkerStatusCode !== "wsLeaves1Week" &&
                    d.WorkerStatusCode !== "wsResigned" &&
                    d.WorkerStatusCode !== "wsAvailable"
                )
                .sort((a, b) =>
                  a.WorkerStatusCode > b.WorkerStatusCode
                    ? 1
                    : b.WorkerStatusCode > a.WorkerStatusCode
                    ? -1
                    : 0
                )}
            />
          </TabPane>
          <TabPane tabId="plan-checks">
            <PlansCheckerResults
              displayProjectWeek={content.project.displayProjectWeek}
              combinedData={getCombinedWorkerResourceData(content)}
              taskStatusList={content.project.taskStatusList}
            />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default StaffingContainer;
