import React from 'react'

export const ReportHeader = (props) => {
  return (
    <header className="report-Heading--main">
        <h2>Status Report For Week #{props.properties.weeknumber}</h2>
      <div>
        <p>For: <strong>{props.properties.reportname}</strong></p>
        <p>Run Time: <strong>{props.properties.runtime}</strong></p>
        <p>Run Id: #<strong>{props.properties.runId}</strong></p>
      </div>
    </header>
 )
}
