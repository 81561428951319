import React, { Component } from "react";
import moment from "moment";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Progress
} from "reactstrap";
import classnames from "classnames";
import styled from "styled-components";

import { DetailsList } from "components/styled/DetailsList";
import  BudgetContainer from "routes/Budget/components/BudgetContainer";
import GanttChartContainer from "routes/Report/GanttChartContainer";
import NetworkDiagramContainer from "routes/Report/NetworkDiagramContainer";
import LaborCostSummaryContainer from "routes/Report/LaborCostSummaryContainer";
import ReserveSummaryContainer from "routes/Report/ReserveSummaryContainer";
import HistoryContainer from  "routes/Report/HistoryContainer";
import ResourceGrid from "../../Resource/components/ResourceGrid";
import { StakeholderPlan4DisplayContainer } from "components/StakeholderPlan4Display";
import { RiskTable4DisplayContainer } from "components/RiskPlan4Display";
import  TrainingPlan4DisplayContainer  from "components/TrainingPlan4Display";
import {
  instructorLoadProject,
  instructorClearProject
} from "redux/Project/thunks";
import {
  fetchHistoryData,
  fetchChartData,
  fetchLaborSummaryData
} from "redux/Report/thunks";
import CardHeaderBold from "components/styled/CardHeaderBold";
import ChartContainer from "routes/Report/ChartContainer";

const StudentHeading = styled.h3`
  .btn {
    margin-left: 1rem;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: black;

  svg {
    margin-right: 1rem;
  }
`;

class StudentOverview extends Component {
  constructor(props) {
    super(props);
    let activeTab = 1;

    let currRun = {};
    if (
      Object.prototype.hasOwnProperty.call(props, "runDetails") &&
      props.runDetails !== null &&
      props.runDetails !== undefined &&
      props.runDetails.length > 0
    ) {
      const validRuns = props.runDetails.filter(
        (r) => r.RunStatus !== "Archived"
      );
      let loadRunNo = {};
      if (validRuns.length > 0) {
        const vRun = validRuns[0];
        loadRunNo = vRun.runId;
      }

      if (loadRunNo > 0) {
        if (props.runDetails && props.runDetails.length > 0) {
          currRun = props.runDetails.filter((r) => r.RunId === loadRunNo)[0];
          activeTab = currRun.RunSequenceNumber;
        }
      }
    }
    if (this.props.execSeqFilterVal !== 0) {
      activeTab = parseInt(this.props.execSeqFilterVal, 10);
    }
    this.state = {
      activeExecutionTab: activeTab,
      activeDetailsTab: "budget",
      loading: false,
      activeRun: currRun
    };
    this.toggleExecution = this.toggleExecution.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.addPercentage = this.addPercentage.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
  }


  toggleExecution = (tab, event) => {
    // event.preventDefault();

    if (this.state.activeExecutionTab !== tab) {
      this.setState({ activeExecutionTab: tab });
      let currRun = {};
      if (this.props.runDetails && this.props.runDetails.length > 0) {
        currRun = this.props.runDetails.filter(
          (r) => r.RunSequenceNumber === tab
        )[0];
      } else {
        this.setState({
          activeExecutionTab: tab,
          loading: false
        });
        return;
      }

      if (
        currRun !== null &&
        currRun !== undefined &&
        Object.prototype.hasOwnProperty.call(currRun, "RunId")
      ) {
        if (currRun.RunId === 0 && this !== undefined) {
          this.props.instructorClearProject();
          return;
        } else {
          this.setState({
            activeExecutionTab: tab,
            loading: false
          });
          Promise.all([
          this.props.instructorLoadProject(currRun.RunId, currRun.ProductId),
          this.props.fetchHistoryData(currRun.RunId),
          this.props.fetchChartData(currRun.RunId),
          this.props.fetchLaborSummaryData(currRun.RunId)]).then(() => {
            this.setState({loading: false})
          })
        }
      } else {
        this.setState({
          activeExecutionTab: tab,
          loading: false
        });
        this.props.instructorClearProject();
        return;
      }

      this.setState({
       
        activeRun: currRun
      });

      const conversionDate = Date.parse("01-13-2018");
      const runStartDate = Date.parse(currRun.StartDateString);

      // if (
      //   currRun.RunStatus !== "Archived" &&
      //   currRun.RunStatus !== "Waiting" &&
      //   currRun.RunStatus !== "Available" &&
      //   currRun.RunStatus !== "Not Available" &&
      //   runStartDate > conversionDate
      // ) {
      //   Promise.all([
      //     this.props.instructorLoadProject(currRun.RunId, currRun.ProductId)
      //   ]).then(() => {
      //     this.setState({
      //       activeExecutionTab: tab,
      //       loading: false
      //     });
      //   });
      // } else {
      //   this.setState({
      //     activeExecutionTab: tab,
      //     loading: false
      //   });
      //   this.props.instructorClearProject();
      // }
    }
  };

  toggleDetails = (tab) => {
    if (this.state.activeDetailsTab !== tab) {
      this.setState({
        activeDetailsTab: tab
      });
    }
  };

  renderLastLoginDate = () => {
    if (
      this.props.selectedStudentInfo !== null &&
      this.props.selectedStudentInfo !== undefined &&
      Object.prototype.hasOwnProperty.call(
        this.props.selectedStudentInfo,
        "LastLoginDate"
      )
    ) {
      const dt = new Date(this.props.selectedStudentInfo.LastLoginDate);
      return <span>{moment(dt).format("DD MMM YY")}</span>;
    }
    return <span>Unknown</span>;
  };

  addPercentage = (cell) => {
    let progressColor;
    switch (true) {
      case cell >= 30 && cell <= 60:
        progressColor = "warning";
        break;
      case cell > 60 && cell <= 90:
        progressColor = "info";
        break;
      case cell > 90:
        progressColor = "success";
        break;
      default:
        progressColor = "secondary";
    }

    return (
      <div>
        <div className="clearfix">
          {/* <div className='float-right'>
						<small className='text-muted'>Jun 11, 2015 - Jul 10, 2015</small>
					</div> */}
        </div>
        <Progress className="progress-xs" color={progressColor} value={cell} />
      </div>
    );
  };

  renderNotes = (row) => {
    const conversionDate = Date.parse("2018-01-13");
    const runStartDate = Date.parse(row.StartDateString);
    if (runStartDate < conversionDate) {
      return (
        <span>
          This run was not migrated from the silverlight version of SimProject;
          please contact support@simulationpl.com
        </span>
      );
    }
    return <span />;
  };

  
  render() {
    if (!this.props.studentInfo || this.props.studentInfo === undefined || this.props.studentInfo === null) {
      return ( <span>No student selected</span>)
    }
    let iterKey = 1;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeaderBold>Student: {this.props.studentInfo.FirstName} {this.props.studentInfo.LastName}</CardHeaderBold>
              <CardBody>
                {/* <StudentHeading>
                  {this.props.studentInfo.FirstName !== undefined
                    ? `${this.props.studentInfo.FirstName} ${this.props.studentInfo.LastName}`
                    : ""}
                  <Link
                    className="btn btn-sm btn-outline-secondary"
                    to="/instructort"
                    href="/instructor/student-management"
                  >
                    <FontAwesomeIcon icon={faCaretLeft} /> Go back
                  </Link>
                </StudentHeading> */}
                <DetailsList>
                  <li>
                    <h4>User ID:</h4>
                    <p>{this.props.studentInfo.ClassStudentDBId}</p>
                  </li>
                  <li>
                    <h4>Username:</h4>
                    <p>{this.props.selectedStudentInfo.UserName}</p>
                  </li>
                  <li>
                    <h4>Status:</h4>
                    <p>
                      <Badge color="light">
                        {this.props.studentInfo.StudentStatus}
                      </Badge>
                    </p>
                  </li>
                  <li>
                    <h4>Last login:</h4>
                    {this.renderLastLoginDate()}
                  </li>
                  <li>
                    <h4>Email:</h4>
                    <a href={"mailto:" + this.props.selectedStudentInfo.Email}>
                      {this.props.selectedStudentInfo.Email}
                    </a>
                  </li>
                </DetailsList>
                <p>
                  Toggle between executions on the right to review info and
                  details about each execution belonging to this student.
                </p>
                {/* <Button color='info' disabled>
                  <i className='fa fa-envelope' /> Email Student
                </Button>
                <Button color='info' disabled>
                  <i className='fa fa-file-pdf-o' /> Export Summary to PDF
                </Button> */}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Row>
              <div className="col-sm-4">
                <h4>
                  Executions{" "}
                  {this.state.loading === true ? (
                    <FontAwesomeIcon spin icon={faSpinner} />
                  ) : (
                    ""
                  )}
                </h4>
              </div>
            </Row>
            <Nav tabs>
              {this.props.runDetails.map((row) => (
                <NavItem key={(iterKey += 1)}>
                  <NavLink
                    className={classnames({
                      active:
                        this.state.activeExecutionTab === row.RunSequenceNumber
                    })}
                    onClick={(event) => {
                      this.toggleExecution(row.RunSequenceNumber, event);
                    }}
                  >
                    Execution {row.RunId}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={this.state.activeExecutionTab}>
              {this.props.runDetails.map((row) => (
                <TabPane tabId={row.RunSequenceNumber} key={(iterKey += 1)}>
                  <DetailsList key={(iterKey += 1)}>
                    <li key={(iterKey += 1)}>
                      <h4>Execution Sequence:</h4>
                      <p>{row.runSequenceNumber}</p>
                    </li>
                    <li key={(iterKey += 1)}>
                      <h4>Execution ID:</h4>
                      <p>{row.RunId}</p>
                    </li>
                    <li key={(iterKey += 1)}>
                      <h4>Execution Status:</h4>
                      <p>
                        <Badge
                          color={
                            row.RunStatus === "Complete!"
                              ? "success"
                              : "warning"
                          }
                        >
                          {row.RunStatus}
                        </Badge>
                      </p>
                    </li>
                    {/* <li key={ (iterKey += 1) }>
                      <h4>% Complete:</h4>
                      <div>{ this.addPercentage(70) }</div>
                    </li> */}
                    {/* <li key={ (iterKey += 1) }>
                      <h4>Estimated Cost:</h4>
                      <p>50000</p>
                    </li> */}
                    <li key={(iterKey += 1)}>
                      <h4>Estimated Schedule:</h4>
                      <p>{this.props.projectedFinishWeek}</p>
                    </li>
                    {/* <li key={ (iterKey += 1) }>
                      <h4>Defect Count:</h4>
                      <p>0</p>
                    </li> */}
                  </DetailsList>
                  {this.renderNotes(row)}
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
              
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "budget"
                  })}
                  onClick={() => {
                    this.toggleDetails("budget");
                  }}
                >
                  Budget
                </NavLink>
              </NavItem>
                <NavLink className={classnames({
                    active: this.state.activeDetailsTab === "history"
                  })}
                  onClick={() => {
                    this.toggleDetails("history");
                  }}
                >
                  History Reports
                   
                </NavLink>
                  <NavLink className={classnames({
                    active: this.state.activeDetailsTab === "charts"
                  })}
                  onClick={() => {
                    this.toggleDetails("charts");
                  }}
                >
                 Chart Reports
                  
                </NavLink>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "gantt-chart"
                  })}
                  onClick={() => {
                    this.toggleDetails("gantt-chart");
                  }}
                >
                  Gantt Chart
                </NavLink>
                
              </NavItem>
            <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "network-diagram"
                  })}
                  onClick={() => {
                    this.toggleDetails("network-diagram");
                  }}
                >
                  Network Diagram
                </NavLink>
                
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "labor-cost"
                  })}
                  onClick={() => {
                    this.toggleDetails("labor-cost");
                  }}
                >
                 Labor Cost Summary
                </NavLink>
                
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "reserve-cost"
                  })}
                  onClick={() => {
                    this.toggleDetails("reserve-cost");
                  }}
                >
                  Reserves Summary
                </NavLink>
                
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "resource"
                  })}
                  onClick={() => {
                    this.toggleDetails("resource");
                  }}
                >
                  Resource Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "stakeholder-plan"
                  })}
                  onClick={() => {
                    this.toggleDetails("stakeholder-plan");
                  }}
                >
                  Stakeholder Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "risk-plan"
                  })}
                  onClick={() => {
                    this.toggleDetails("risk-plan");
                  }}
                >
                  Risk Plan
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === "training-plan"
                  })}
                  onClick={() => {
                    this.toggleDetails("training-plan");
                  }}
                >
                  Training Plan
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeDetailsTab === 'activity-history'
                  })}
                  onClick={() => {
                    this.toggleDetails('activity-history');
                  }}
                >
                  Activity History
                </NavLink>
              </NavItem> */}
            </Nav>
            <TabContent activeTab={this.state.activeDetailsTab}>
              <TabPane tabId="budget">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <BudgetContainer />
                )}
              </TabPane>
                <TabPane tabId="history" >
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <HistoryContainer id={`historytab-${this.state.activeDetailsTab}`}/>
                )}
              </TabPane>
                <TabPane tabId="charts">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <ChartContainer />
                )}
              </TabPane>
              <TabPane tabId="gantt-chart">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <GanttChartContainer />
                )}
              </TabPane>
              <TabPane tabId='network-diagram'>
                { this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size='2x' icon={ faSpinner } />{ " " }
                    Loading
                  </LoadingContainer>
                ) : (
                    <NetworkDiagramContainer />
                  ) }
              </TabPane>
               <TabPane tabId="labor-cost">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <LaborCostSummaryContainer />
                )}
              </TabPane>
               <TabPane tabId="reserve-cost">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <ReserveSummaryContainer />
                )}
              </TabPane>
              <TabPane tabId="resource">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <ResourceGrid displayOnly />
                )}
              </TabPane>
              <TabPane tabId="stakeholder-plan">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <StakeholderPlan4DisplayContainer />
                )}
              </TabPane>
              <TabPane tabId="risk-plan">
                {this.state.loading ? (
                  <LoadingContainer>
                    <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
                  </LoadingContainer>
                ) : (
                  <RiskTable4DisplayContainer />
                )}
              </TabPane>
              <TabPane tabId='training-plan'>
                {this.state.loading ? (
									<LoadingContainer>
										<FontAwesomeIcon spin size='2x' icon={faSpinner} /> Loading
									</LoadingContainer>
								) : (
									<TrainingPlan4DisplayContainer />
								)}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  execSeqFilterVal: state.instructor.execSeqFilterValue,
  studentList: state.instructor.studentList,
  instructorClasses: state.instructor.instructorClasses,
  selectedClassId: state.instructor.selectedClassId,
  selectedStudentInfo: state.instructor.selectedStudentInfo,
  studentInfo: state.auth.studentInfo,
  runDetails: state.auth.runDetails,
  runId: state.project.runId,

  projectedFinishWeek: state.project.projectedFinishWeek
});

const mapDispatchToProps = (dispatch) => ({
  instructorLoadProject: (runId, productId) =>
    dispatch(instructorLoadProject(runId, productId)),
  fetchHistoryData: (runId) =>  dispatch(fetchHistoryData(runId)),
   fetchChartData: (runId) => dispatch(fetchChartData(runId)),
   fetchLaborSummaryData: (runId) => dispatch(fetchLaborSummaryData(runId)),
  instructorClearProject: () => dispatch(instructorClearProject())
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentOverview);
