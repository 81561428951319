import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import routes from "routes/routes";
import { toggleInfoPanel, toggleBudgetPanel } from "app/redux/thunks";
import InstructorGuideDownloadLink from "../InstructorGuideDownloadLink";

import "./PageHeader.css";

const PageHeader = (props) => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();


  return (
    <header tourstop="pageHeader">
      <div>
        <h2 className="page-Header">{routes[props.location.pathname]}</h2>
        <Breadcrumb />
        <div key={`reg-message-${content.auth.user.ClassroomStatus}`}>
          {content.auth.productId === 50 &&
            content.auth.user.ClassroomStatus === "Registration" && (
              <span style={{ color: "red", fontWeight: "bold" }}>
                <small>
                  The classroom is in Registration status. This is intended for
                  Instructor use only.
                </small>
              </span>
            )}
          {/* {content.auth.productId !== 50 && content.auth.userType !== "Instructor" &&
            content.auth.user.ClassroomStatus === "Registration" && (
              <span style={{ color: "red", fontWeight: "bold" }}>
                <small>
                  Congratulations on registering for Simproject. Your classroom
                  is still in Registration status which limits available menu
                  options. <br />
                  Please contact your instructor for more information.
                </small>
              </span>
            )} */}
        </div>
      </div>
      {props.runId > 0 && props.userType !== "Instructor" ? (
        <div className="ml-auto run-info">
          <small>
            Execution Id: {props.runId}
            <br />
            {content.project.projectControlStatusList.some(s => s.ControlNumber === 52 && s.ControlValue === 1)
              ? <span>Project Complete!</span> 
              : <span>Current Project Week: {props.displayProjectWeek}</span> 
          }
            <br />
               {content.project.projectControlStatusList.some(s => s.ControlNumber === 52 && s.ControlValue === 1)
              ? <span>Finished Week: {props.displayProjectWeek}</span> 
              : <span>Projected Finish Week: {props.projectedFinishWeek}</span> 
          }
            
          </small>
        </div>
      ) : (
        <p />
      )}
      {props.userType === "Instructor" ? (
        <div className="ml-auto run-info">
          {" "}
          <br />
          <br />
          <InstructorGuideDownloadLink />{" "}
        </div>
      ) : (
        <p />
      )}
    </header>
  );
};

export default withRouter(PageHeader);
