import { isOnProject, isOnBoarding } from "utilities/utils.js";
/*
****    result object format

result = {
  className: <check-failed, check-warning, check-passed",
  status: <fail, warning, pass>,
  message: "string"
};
 */

export const findTaskByWorkerP1Task = (p1, taskData) =>
  taskData.find(t => t.TaskId === p1);

export const findWorkersOnTask = (taskId, workers) =>
  workers.find(w => w.Task1 === taskId);

export const doStaffingChecks = (workerData, taskData, currentWeek) => {
  const results = [];
  /*  check workers on project but not on vacation who have no tasks assigned */

  let keys = [];
  let worker = {};
  keys = Object.keys(workerData);

  for (let i = 0; i < keys.length; i++) {
    worker = workerData[i];

    /* idle workers - onproject with no assigned task */
    if (
      isOnProject(worker.WorkerStatusCode) &&
      (worker.Task1 === null || worker.Task1 === "")
    ) {
      results.push({
        className: "check-failed",
        status: "fail",
        message: `Worker : ${worker.WorkerName
          } is on project but does not have an Assigned Task for the week.  This worker will be paid to be idle for the week without an assigned task.`
      });
      // check mismatches in currentweek and assigned task 1
    } else if (
      isOnProject(worker.WorkerStatusCode) &&
      worker.Task1 !== worker[currentWeek]
    ) {
      const thisTask = findTaskByWorkerP1Task(worker.Task1, taskData);

      if (thisTask !== undefined && thisTask !== null) {
        if (thisTask.State !== "tsWorking") {
          results.push({
            className: "check-warning",
            status: "warning",
            message: `Worker : ${worker.WorkerName
              } is assigned Task ${worker.Task1
              } which is not being worked on at the moment.  According to the resource plan, this worker should work on Task ${worker[currentWeek]
              }.  Check to verify this worker is correctly assigned and/or update your resource plan to match the status of your project.`
          });
        } else {
          const remainingHours =
            (1 - thisTask.PercentComplete / 100) * thisTask.Duration * 8; // convert days to hours

          if (remainingHours < 40 && worker.Task2 === worker[currentWeek]) {
            // worker is still on p1 task, rp shows p2.
            results.push({
              className: "check-passed",
              status: "pass",
              message: `Worker : ${worker.WorkerName
                } still has some work to do on the assigned task: ${worker.Task1
                } but should be able to begin the scheduled task ${worker.Task2
                } as shown in the resource plan during the upcoming work week.  There is no need to change the resource plan unless you want.`
            });
          } else if (remainingHours < 40 && worker.Task2 !== worker[currentWeek]) {
            results.push({
              className: "check-warning",
              status: "warning",
              message: `Worker : ${worker.WorkerName
                } still has some work to do on the assigned task: ${worker.Task1
                } and according to the resource plan, should work on ${worker[currentWeek]
                } this week.  Check to verify this worker is correctly assigned and/or update your resource plan to match the status of your project.`
            });
          } else if (remainingHours >= 40) {
            results.push({
              className: "check-warning",
              status: "warning",
              message: `Worker : ${worker.WorkerName
                } still has more than 40 hours to do on the assigned task: ${worker.Task1
                } but the resource plan shows this worker is planned to work on ${worker[currentWeek]
                } this week.   You may want to update your resource plan to match the status of your project.`
            });
          }
        }
      } else if (
        isOnBoarding(worker.WorkerStatusCode) &&
        worker[currentWeek] !== "Staff"
      ) {
        results.push({
          className: "check-warning",
          status: "warning",
          message: `Worker : ${worker.WorkerName
            } is currently in an onboarding status but there is no Staff action in the resource
          plan for the current week.  While this will not cause any work week errors, it is always a good idea to
          keep your resource plan in sync with your current Staffing assignments.`
        });
      } else if (
        !isOnProject(worker.WorkerStatusCode) &&
        !isOnBoarding(worker.WorkerStatusCode) &&
        worker[currentWeek] === "Staff"
      ) {
        results.push({
          className: "check-warning",
          status: "warning",
          message: `Worker : ${worker.WorkerName
            } is currently is not yet on project nor onboarding; however, the resource plan has a Staff action planned for this worker.  Did you mean to begin the on-boarding process with ${worker.WorkerName
            }?`
        });
      } else if (
        isOnProject(worker.WorkerStatusCode) &&
        worker[currentWeek] === "Release"
      ) {
        results.push({
          className: "check-warning",
          status: "warning",
          message: `Worker : ${worker.WorkerName
            } is scheduled to be released this week per the Resource Plan but is still on the project.  While this will not cause any work week errors, it is always a good idea to
      keep your resource plan in sync with your current Staffing assignments.`
        });
      } else if (
        isOnBoarding(worker.WorkerStatusCode) &&
        worker[currentWeek] === "Release"
      ) {
        results.push({
          className: "check-warning",
          status: "warning",
          message: `Worker : ${worker.WorkerName
            } is scheduled to be released this week per the Resource Plan but is still in the on-boarding prcess.  While this will not cause any work week errors, it is always a good idea to
      keep your resource plan in sync with your current Staffing assignments.`
        });
      }
    }
  }

  const activeTasks = taskData.filter(t => t.State === "tsWorking");
  if (activeTasks && activeTasks.length > 0) {
    keys = Object.keys(activeTasks);

    for (let i = 0; i < keys.length; i++) {
      const task = activeTasks[i];
      const workersOnTask = findWorkersOnTask(task.TaskId, workerData);
      if (!workersOnTask || workersOnTask.length < 1) {
        results.push({
          className: "check-fail",
          status: "fail",
          message: `Task : ${task.TaskId
            } is currently active and needs workers but there are no workers assigned to this task.  This will cause your project to be delayed and should be addressed.  Use the current week task column dropdown to assign at least one of  your On Project workers to this task.`
        });
      }
    }
  }
  return results;
};

/* task is active but no workers assigned */
