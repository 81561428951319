import React from "react";
import { Row, Col } from "reactstrap"

import imageURLs from "./images/imageURLs.js";

const ProjectPlanningHelp = () => (
  <div>
    <h3>Planning Your Project - Overview</h3>

    <Row style={{marginTop: "10px"}}>

      <Col md="6">
    <p>There are five different plans that you need to complete:</p>
    <ul>
      <li>Resource Plan</li>
      <li>Training Plan</li>
      <li>Stakeholder Plan</li>
      <li>Risk/Opportunities Plan</li>
      <li>Recognition Plan</li>
    </ul>
      </Col>
  <Col md="6">

      <img
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        src={imageURLs.p1}
        alt="planning menu"
      />

  </Col>
    </Row>

 <Row style={{marginTop: "10px"}}>
     <Col className="pull-left" md="4">
       <img
         style={{ maxWidth: "100%", maxHeight: "100%" }}
         src={imageURLs.p2}
         alt="planning menu"
       />
     </Col>

     <Col className="pull-left" md="8">
       Once your planning is complete, you will submit your project plan for approval. The Budget Approval process requires that your planned budget be less than or equal to the approved budget for your project (See Project Charter). You will automatically be notified if there are actual or potential problems with your budget. Once you gain Approval, you will be ready to start Executing your project!
     </Col>
 </Row>


  </div>
);

export default ProjectPlanningHelp;
