// ------------------------------------
// Constants
// ------------------------------------
// if we need to know the difference between component and data loading,
// here are the constants for that but for now, we'll use the
// loading and LOADED
export const COMPONENT_LOADING = "COMPONENT_LOADING";
export const COMPONENT_LOADED = "COMPONENT_LOADED";
export const DATA_LOADING = "DATA_LOADING";
export const DATA_LOADED = "DATA_LOADED";

export const TOGGLE_INFO_PANEL = "TOGGLE_INFO_PANEL";
export const TOGGLE_BUDGET_PANEL = "TOGGLE_BUDGET_PANEL";
export const TOGGLE_RECOGNITION_INFO = "TOGGLE_RECOGNITION_INFO";
export const TOGGLE_RECOGNITION_BUDGET = "TOGGLE_RECOGNITION_BUDGET";
export const TOGGLE_RISK_INFO = "TOGGLE_RISK_INFO";
export const TOGGLE_RISK_BUDGET = "TOGGLE_RISK_BUDGET";
export const TOGGLE_RESOURCE_INFO = "TOGGLE_RESOURCE_INFO";
export const TOGGLE_RESOURCE_PLAN = "TOGGLE_RESOURCE_PLAN";
export const TOGGLE_RESOURCE_BUDGET = "TOGGLE_RESOURCE_BUDGET";
export const TOGGLE_STAKEHOLDER_INFO = "TOGGLE_STAKEHOLDER_INFO";
export const TOGGLE_STAKEHOLDER_OPTIONS = "TOGGLE_STAKEHOLDER_OPTIONS";
export const TOGGLE_STAKEHOLDER_BUDGET = "TOGGLE_STAKEHOLDER_BUDGET";
export const TOGGLE_TRAINING_INFO = "TOGGLE_TRAINING_INFO";
export const TOGGLE_TRAINING_BUDGET = "TOGGLE_TRAINING_BUDGET";
export const TOGGLE_WORKWEEK_INFO = "TOGGLE_WORKWEEK_INFO";
export const TOGGLE_STAFFING_INFO = "TOGGLE_STAFFING_INFO";
export const TOGGLE_INTERACTION_INFO = "TOGGLE_INTERACTION_INFO";


export const TOGGLE_REPORT_INFO = "TOGGLE_REPORT_INFO";

// TOUR COMPONENT
export const TOGGLE_TOUR_OPEN = "TOGGLE_TOUR_OPEN";
