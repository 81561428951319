import React from "react";
import imageURLs from "./images/imageURLs.js";

const GanttChartHelp = () => (
  <div>
    <h3>Gantt Chart Info</h3>

    <p>
      The Gantt or bar chart provides the project time table. It is useful in the project planning phase to know when a task is planned to start and finish (use it in resource planning). It indicates the earliest each task can start, the earliest each task can finish, and if different from the early finish date, the latest each task can finish. The hashed bars are the baseline calculations of start and finish for each task. These will not change unless there are tasks added or deleted as you run the project. The solid white bars in the middle of the hashed bars represent the actual start and finish or projected finish of the tasks. These will update at the completion of each week. By looking at you last task you will be able to see your projected finish date.
    </p>
    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.GanttChart}
      alt="report"
    />
    <p>
      The difference between each task’s early and late finish (if it is non-zero) is represented by the dotted line extending from both the baseline and actual task early finish date. The end of the dotted line represents both the baseline (green color) and actual task (blue color) late finish date. The dotted line represents the float on each task. Be aware that even though a task may have float, the float belongs to every task on that path. As the float is used by one task on the path all subsequent tasks on the path will lose their float too. The critical path tasks are red and no float.

    </p>

    <p>
      During project execution use the Gantt chart each week to track your task status, particularly your critical path task status. Beware that as non-critical path tasks slip (use up float), they can become the critical path. Also use the projected finish date from the Gantt chart to determine your project schedule each week.

    </p>
  </div>
);

export default GanttChartHelp;
