import React, { Component, Fragment } from "react";
import { FormGroup, Label, Button, Row, Col } from "reactstrap";
import { updateClassDates } from "redux/Instructor/thunks";
import { connect } from "react-redux";
import moment from "moment";
import {formatDate,parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

class EditClassDates extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: props.currentClassroom.startDate,
			endDate: props.currentClassroom.endDate,
			formStatus: ""
		}
		this.updateDates = this.updateDates.bind(this);
		this.changeStartDate = this.changeStartDate.bind(this);
		this.changeEndDate = this.changeEndDate.bind(this);
	}

	changeStartDate = date => {
		if (date > this.state.endDate) {
			this.setState({
				endDate: date
			})

		}
		this.setState({ startDate:date, formStatus: "" })

	};

	changeEndDate = (date) => {
		this.setState({ endDate: date, formStatus: "" })
	};

	updateDates = (e) => {
		e.preventDefault();
		if (
			this.state.startDate === undefined ||
			this.state.startDate === null || this.state.startDate === ""
		) {
			this.setState({ formStatus: "Select a start date" });
			return;
		}

		if (
			this.state.endDate === undefined ||
			this.state.endDate === null || this.state.endDate === ""
		) {
			this.setState({ formStatus: "Select a end date" });
			return;
		}

		if (moment(this.state.endDate).valueOf() < moment(this.state.startDate).valueOf()) {
			this.setState({ formStatus: "End date cannot be earlier than start date" });
			return;
		}

		this.props.updateClassDates(
			this.props.currentClassroom.classroomId,
			moment(this.state.startDate).valueOf(),
			moment(this.state.endDate).valueOf()
		).then(() => {
			this.props.refresh()
		});
	};

	render() {
		const { startDate, endDate, formStatus } = this.state;
		return (
			<Fragment>
				<Row>
					{formStatus && formStatus !== "" && <p style={{marginLeft: "5px", color: "red"}}>Status: {formStatus} </p>

					}
				</Row>
				<Row>
					<Col style={{ alignContent: "right" }}>
						<FormGroup>
							<Label for="startDate">Start Date</Label>
							<div className="customDatePickerWidth50">

								<DayPickerInput
									formatDate={formatDate}
									parseDate={parseDate}
									onDayChange={this.changeStartDate}
									placeholder={`${formatDate(startDate)}`}

								/>


							</div>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="endDate">End Date</Label>
							<div className="customDatePickerWidth50">

								<DayPickerInput
									formatDate={formatDate}
									parseDate={parseDate}
									onDayChange={this.changeEndDate}
									placeholder={`${formatDate(endDate)}`}

								/>
							</div>
						</FormGroup>
					</Col>

					<Col style={{ alignContent: "center" }}>
						<Button
							color="primary"
							onClick={e => this.updateDates(e)}
						>
							Update
							</Button>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
	updateClassDates: (cId, startDate, endDate) => dispatch(updateClassDates(cId, startDate, endDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassDates);
