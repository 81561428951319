import { createAction } from "redux-actions";

import {
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_ERROR,
  FETCH_CLASSRUNS_ERROR,
  FETCH_CLASSRUNS_SUCCESS,
  FETCH_INSTRUCTORCLASSES_ERROR,
  FETCH_INSTRUCTORCLASSES_SUCCESS,
  FETCH_INSTRUCTORS_ERROR,
  FETCH_INSTRUCTORS_SUCCESS,
  ADD_SECONDARY_SUCCESS,
  ADD_SECONDARY_ERROR,
  REMOVE_SECONDARY_SUCCESS,
  REMOVE_SECONDARY_ERROR,
  CHANGE_PRIMARY_SUCCESS,
  CHANGE_PRIMARY_ERROR,
  ADD_CLASSROOM_SUCCESS,
  ADD_CLASSROOM_ERROR,
  INACTIVATE_CLASSROOM_ERROR,
  INACTIVATE_CLASSROOM_SUCCESS,
  SET_SELECTED_CLASS,
  UPDATE_CLASSROOM_ERROR,
  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSRUNS_ERROR,
  UPDATE_CLASSRUNS_SUCCESS,
  UPDATE_STOPWEEKS_ERROR,
  UPDATE_STOPWEEKS_SUCCESS,
  SET_STUDENT_INVALIDLICENSE,
  RECEIVE_STUDENT_INFO,
  SET_LOADING,
  TOGGLE_ALLOW_TO_RUN,
  TOGGLE_ALLOW_TO_PLAN,
  TOGGLE_STOP_WEEK,
  UPDATE_STOP_MESSAGE,
  SET_EXECSEQ_FILTER_VALUE
} from "./types";

export const setLoading = createAction(
  SET_LOADING,
  payload => payload
)


const setExecSeqFilterValue = createAction(
  SET_EXECSEQ_FILTER_VALUE,
  payload => payload
)

const fetchStudentsError = createAction(
  FETCH_STUDENTS_ERROR,
  payload => payload
);

const fetchStudentsSuccess = createAction(
  FETCH_STUDENTS_SUCCESS,
  payload => payload
);

const fetchClassRunsError = createAction(
  FETCH_CLASSRUNS_ERROR,
  payload => payload
);

const fetchClassRunsSuccess = createAction(
  FETCH_CLASSRUNS_SUCCESS,
  payload => payload
);

const fetchInstructorClassesError = createAction(
  FETCH_INSTRUCTORCLASSES_ERROR,
  payload => payload
);

const fetchInstructorClassesSuccess = createAction(
  FETCH_INSTRUCTORCLASSES_SUCCESS,
  payload => payload
);

const fetchInstructorsError = createAction(
  FETCH_INSTRUCTORS_ERROR,
  payload => payload
);

const fetchInstructorsSuccess = createAction(
  FETCH_INSTRUCTORS_SUCCESS,
  payload => payload
);

const addSecondarySuccess = createAction(
  ADD_SECONDARY_SUCCESS,
  payload => payload);

const addSecondaryError = createAction(
  ADD_SECONDARY_ERROR,
  payload => payload
);
const removeSecondarySuccess = createAction(
  REMOVE_SECONDARY_SUCCESS,
  payload => payload);
const removeSecondaryError = createAction(
  REMOVE_SECONDARY_ERROR,
  payload => payload
);
const changePrimarySuccess = createAction(
  CHANGE_PRIMARY_SUCCESS,
  payload => payload);
const changePrimaryError = createAction(
  CHANGE_PRIMARY_ERROR,
  payload => payload
);
const addClassroomSuccess = createAction(
  ADD_CLASSROOM_SUCCESS,
  payload => payload);
const addClassroomError = createAction(
  ADD_CLASSROOM_ERROR,
  payload => payload
);
const inactivateClassroomSuccess = createAction(
  INACTIVATE_CLASSROOM_ERROR,
  payload => payload);
const inactivateClassroomError = createAction(
  INACTIVATE_CLASSROOM_SUCCESS,
  payload => payload
);

const setSelectedClass = createAction(
  SET_SELECTED_CLASS,
  payload => payload
);

const updateClassroomSuccess = createAction(
  UPDATE_CLASSROOM_SUCCESS,
  payload => payload
);

const updateClassroomError = createAction(
  UPDATE_CLASSROOM_ERROR,
  payload => payload
);

const updateClassStatusSuccess = createAction(
  UPDATE_CLASSROOM_SUCCESS,
  payload => payload
);

const updateClassStatusError = createAction(
  UPDATE_CLASSROOM_ERROR,
  payload => payload
);
const updateClassNameSuccess = createAction(
  UPDATE_CLASSROOM_SUCCESS,
  payload => payload
);

const updateClassNameError = createAction(
  UPDATE_CLASSROOM_ERROR,
  payload => payload
);

const updateClassRunsSuccess = createAction(
  UPDATE_CLASSRUNS_SUCCESS,
  payload => payload
);

const updateClassRunsError = createAction(
  UPDATE_CLASSRUNS_ERROR,
  payload => payload
);

const updateStopWeeksSuccess = createAction(
  UPDATE_STOPWEEKS_SUCCESS,
  payload => payload
);

const updateStopWeeksError = createAction(
  UPDATE_STOPWEEKS_ERROR,
  payload => payload
);

const setStudentInvalidLicense = createAction(
  SET_STUDENT_INVALIDLICENSE,
  payload => payload
);

const receiveStudentInfo = createAction(
  RECEIVE_STUDENT_INFO,
  payload => payload
);

const toggleAllowToPlan = createAction(
  TOGGLE_ALLOW_TO_PLAN,
  payload => payload
)

const toggleAllowToRun = createAction(
  TOGGLE_ALLOW_TO_RUN,
  payload => payload
)

const toggleStopWeek = createAction(
  TOGGLE_STOP_WEEK,
  payload => payload
)

const updateStopMessage = createAction(
  UPDATE_STOP_MESSAGE,
  payload => payload
)
const actions = {
  fetchStudentsError,
  fetchStudentsSuccess,
  fetchClassRunsError,
  fetchClassRunsSuccess,
  fetchInstructorClassesError,
  fetchInstructorClassesSuccess,
  fetchInstructorsError,
  fetchInstructorsSuccess,
  setSelectedClass,
  addClassroomError,
  addClassroomSuccess,
  changePrimaryError,
  changePrimarySuccess,
  addSecondaryError,
  addSecondarySuccess,
  removeSecondaryError,
  removeSecondarySuccess,
  inactivateClassroomSuccess,
  inactivateClassroomError,
  updateClassroomSuccess,
  updateClassroomError,
  updateClassStatusSuccess,
  updateClassStatusError,
  updateClassNameSuccess,
  updateClassNameError,
  updateClassRunsSuccess,
  updateClassRunsError,
  updateStopWeeksSuccess,
  updateStopWeeksError,
  setStudentInvalidLicense,
  receiveStudentInfo,
  toggleAllowToPlan,
  toggleAllowToRun,
  toggleStopWeek,
  updateStopMessage,
  setLoading,
  setExecSeqFilterValue
};

export default actions;
