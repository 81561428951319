import React from 'react';

export const Reassign = (props) => {
	return (
		<div className="content">
			<h3>
				{props.properties.worker}
				<small>{props.properties.title}</small>
			</h3>
			<p>finished their work and was reassigned to work on task {props.properties.task}.</p>
		</div>
	);
};
