import React from "react";

const TrainingTooltip = () => (
    <div style={{textAlign: "left"}}>
       <p>Do you have a specific plan, or at least a
         budget (plan any classes, any resources, any weeks) to use for training on the project?
       </p>
    </div>
  )

export default TrainingTooltip
