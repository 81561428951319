import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Collapse, Label, Input } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import StyledCardBody from "components/styled/StyledCardBody";
import imageURLs from "components/Help/images/imageURLs.js";
import InfoFooter from "components/Info/InfoFooter";
import { toggleWorkWeekInfo } from "app/redux/actions"

const WorkWeekInfo = () => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();
return (
  <Card className="Card-info">
    <CardHeaderBold>
      <span style={{ marginRight: "10px" }}>Work Week Info</span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
            onClick={() => dispatch(toggleWorkWeekInfo())}
          defaultChecked={content.app.workWeekInfoPanelVisible}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </CardHeaderBold>
    <Collapse isOpen={content.app.workWeekInfoPanelVisible}>
      
 
      <StyledCardBody>
        <p>Prerequisites:</p>
        <ul>
          <li>Project Planning Complete</li>
          <li>Project Budget Approved</li>
          <li>
            You have made your decisions for the week (Staffing and Interaction)
      </li>
        </ul>

        <p>
          When ready, click on the&nbsp;
      <img
            style={{ maxWidth: "120px", maxHeight: "90px" }}
            src={imageURLs.StartWeekButtonSmall}
            alt="workweek"
          />
      &nbsp;button. This will execute the week.
    </p>

        <p>
          When responding to the pop-up communications in the Communications window,
          consider the anticipated results of each response before selecting your
          desired response.
    </p>

        <p>
          {" "}
      At the end of the week you will be automatically redirected to the Reports
      module; carefully look over all the reports. The reports are explained in
      detail in the Help Documentation.
    </p>

        <p>
          <i>
            If at anytime your work week execution gets interrupted, just come back
            to the page and click the Continue Week button. With few execeptions,
            your work will be saved and you can pick up where you left off before
            the interruption.
      </i>
        </p>
      </StyledCardBody>
      <InfoFooter selectedKey="work-week" showProjectLink={false} />

      
    </Collapse>
  </Card>
)
};

export default WorkWeekInfo;
