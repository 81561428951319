/* Auth Reducer */

import { handleActions } from "redux-actions";

import {
  AUTH_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GETUSER_SUCCESS,
  GETUSER_FAILURE,
  LOGOUT,
  DATA_REQUEST,
  SET_MASTERCONTROL_USER,
  EXPIRED_ACCOUNT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RECEIVE_SUCCESS,
  RECEIVE_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_USER,
  GET_AVAILABLE_CLASSES_FAILURE,
  GET_AVAILABLE_CLASSES_SUCCESS,
  PLACE_IN_CLASSROOM_SUCCESS,
  SET_LOGIN_MESSAGE,
  LICENSE_ACCEPTED_SUCCESS,
  STORE_PASSWORD,
  UPDATE_USERCONTROL_SUCCESS,
  GETMESSAGE_SUCCESS,
  GETMESSAGE_FAILURE
} from "./types";


const initialState = {
        isFetching: false,
        isAuthenticated:false,
        user: {},
        userId: 0,
        userName: "",
        token: "",
        message: "",
        status: "",
        statusCode: "",
        statusTarget: "",
        productId: 38,
        studentInfo: {}, // contains licensing info too
        runDetails: [],
        checkListItems: [],
        planList: [],
        passwordResetUser: {},
        availableClasses: [],
        userType: "student",
        userVariable: "",
        userMessage: {}
      }

// export const actions = {
//   login,
//   isAuthenticated,
//   // setUser,
//   logout
// }

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [LOGOUT]: (state, { payload }) => ({
      ...initialState
    }),
    [AUTH_REQUEST]: state => ({
      ...state,
      message: "",
      status: "",
      isFetching: true
    }),
    [DATA_REQUEST]: state => ({
      ...state,
      message: "",
      isFetching: true
    }),
    [SET_LOGIN_MESSAGE]: (state, { payload} ) => ({
      ...state,
      message: payload
    }),
    [GETUSER_SUCCESS]: (state, { payload }) => {
      const user = JSON.parse(payload.user);
      const msg = payload.message;
      const returnStatus = payload.status;
      return {
        ...state,
        // isFetching: false,
        status: returnStatus,
        message: msg,
        user,
        username: user.UserName,
        userId: user.UserId
      };
    },
    [LOGIN_SUCCESS]: (state, { payload }) => {
      const user = JSON.parse(payload.payload);

      const message = payload.statusMessage;
      const { status } = payload;
      return {
        ...state,
        status,
       isFetching: false,
        message:
          message === "success" || message === "sudosuccess"
            ? "authenticated"
            : message,
        isAuthenticated: true,
        token: "thiswillbethetoken",
        user,
        username: user.UserName,
        userId: user.UserId,
        userType: user.Role
      };
    },
    [REGISTER_SUCCESS]: (state, { payload }) => {
      const user = JSON.parse(payload.user);
      const message = payload.statusMessage;
      const { status } = payload;
      return {
        ...state,
        // isFetching: false,
        status,
        message: message === "success" ? "registered" : message,
        isAuthenticated: true,
        token: "thiswillbethetoken",
        user,
        username: user.UserName,
        userId: user.UserId
      };
    },
    [FORGOT_PASSWORD_SUCCESS]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      isAuthenticated: false,
      token: "thiswillbethetoken",
      message: "temporary password sent; please check your email"
    }),
    [RESET_PASSWORD_SUCCESS]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      isAuthenticated: false,
      token: "thiswillbethetoken",
      message: "password reset, please login"
    }),
    [STORE_PASSWORD]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      userVariable: payload
    }),
    [SET_MASTERCONTROL_USER]: (state, { payload }) => {
      const u1 = Object.assign({}, state.user);
      u1.ExpirationDate = payload.ExpirationDate;
      u1.ProductName = payload.ProductName;
      u1.ProductId = payload.ProductId;
      u1.ClassroomId = payload.ClassroomId;
      u1.ClassroomStatus = payload.ClassroomStatus;
      const chkList = payload.UserControlList;
      return {
        ...state,
        user: u1,
        productId: u1.ProductId,
        checkListItems: chkList
      };
    },
    [LICENSE_ACCEPTED_SUCCESS]: (state, { payload }) => ({
      ...state,
      checkListItems: payload
    }),
    [UPDATE_USERCONTROL_SUCCESS]: (state, { payload }) =>
      // payload contains updated list of usercontrol records
      ({
        ...state,
        checkListItems: JSON.parse(payload)
      }),
    [EXPIRED_ACCOUNT]: (state, { payload }) => ({
      ...state,
       isFetching: false,
      isAuthenticated: false,
      message: payload
    }),
    [GETUSER_FAILURE]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAuthenticated: false,
      message: payload.message,
      status: payload.status
    }),
    [LOGIN_FAILURE]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAuthenticated: false,
      message: payload.message,
      statusCode: payload.statusCode,
      statusTarget: payload.statusTarget,
      status: "error"
    }),
    [FORGOT_PASSWORD_FAILURE]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAuthenticated: false,
      message: payload
    }),
    [RESET_PASSWORD_FAILURE]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAuthenticated: false,
      message: payload
    }),
    [REGISTER_FAILURE]: (state, { payload }) =>
      // const usr = JSON.parse(payload.payload);
      ({
        ...state,
        isFetching: false,
        isAuthenticated: false,
        message: payload.message,
        statusCode: payload.statusCode,
        statusTarget: payload.statusTarget,
        status: "error",
        user: payload.user
      }),
    [SET_USER]: (state, { payload }) => {
      const user = JSON.parse(payload.payload);
      const userId = user.UserId;
      return {
        ...state,
        status: payload.status,
        message: payload.statusMessage,

        // isFetching: false,
        user,
        username: user.UserName,
        userId
      };
    },
    // export const GET_AVAILABLE_CLASSES_SUCCESS = 'GET_AVAILABLE_CLASSES_SUCCESS'
    // export const GET_AVAILABLE_CLASSES_FAILURE = 'GET_AVAILABLE_CLASSES_FAILURE'
    // export const PLACE_IN_CLASSROOM_SUCCESS = 'PLACE_IN_CLASSROOM_SUCCESS'
    // export const PLACE_IN_CLASSROOM_FAILURE = 'PLACE_IN_CLASSROOM_FAILURE'
    [GET_AVAILABLE_CLASSES_SUCCESS]: (state, { payload }) =>
      // have correct object array here in response
      ({
        ...state,
        availableClasses: payload,
        // isFetching: false
      }),
    [GET_AVAILABLE_CLASSES_FAILURE]: (state, { payload }) => ({
      ...state,
      // isFetching: false,
      message: payload
    }),
    [PLACE_IN_CLASSROOM_SUCCESS]: (state, { payload }) => {
      const pl = JSON.parse(payload);
      const student = pl.StudentRunList[0];
      const runs = pl.StudentRunList[0].RunDetails;
      return {
        ...state,
        studentInfo: student,
        runDetails: runs,
        // isFetching: false
      };
    },
    [GET_AVAILABLE_CLASSES_FAILURE]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      message: payload
    }),
    [RECEIVE_SUCCESS]: (state, { payload }) =>
      // have correct object array here in response
      ({
        ...state,
        studentInfo: payload.StudentRunList[0],
        runDetails: payload.StudentRunList[0].RunDetails,
        planList: payload.PlanList,
        availableClasses: payload.AvailableClasses,
        // isFetching: false
      }),
    [RECEIVE_ERROR]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      message: payload.statusMessage,
      status: payload.status
    }),
    [GETMESSAGE_SUCCESS]: (state, { payload }) => ({
      ...state,
      systemMessage: payload
    }),
    [GETMESSAGE_FAILURE]: state => ({
      ...state
    })
  },
  initialState
);
