
import React, { Component } from "react";
import Select from "react-select";
import { addSecondaryInstructor } from "redux/Instructor/thunks";
import _debounce from "lodash.debounce";
import { connect } from "react-redux";

class AddSecondaryInstructor extends Component {
    constructor(props) {
        super(props);
        this.updateSecondaryInstructor = this.updateSecondaryInstructor.bind(this);
    }

    updateSecondaryInstructor = _debounce((selected) => {
        this.props.addSecondaryInstructor(selected.value, this.props.currentClassroom.classroomId)
            .then(() => {
                this.props.refresh();
            })

    });
    render() {
        return (
            <div>
                <span>{this.props.currentClassroom.secondaryInstructorName}</span>
                <Select
                    id="instructorSelect"
                    options={this.props.instructorOptions}
                    placeholder="Choose an instructor ..."
                    onChange={(selected) => {
                        this.updateSecondaryInstructor(selected);
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
    addSecondaryInstructor: (instructorId, classroomId) => dispatch(addSecondaryInstructor(instructorId, classroomId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSecondaryInstructor);
