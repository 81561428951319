import React from "react";
import { Alert } from "react-bs-notifier";

export const ResourcePlanNotification = props => {
  return (
    <li>
      <time>
        {props.notification.CreateTime}
        <span className="date">{props.notification.CreateDate}</span>
      </time>
      <Alert
        type={props.notification.AlertType}
        headline={props.notification.AlertHeadline}
      >
        {props.notification.NotificationName} <br />
        <strong>{props.notification.NotificationWorker}</strong>
        <br /> <strong>Week {props.notification.NotificationWeek}</strong>
      </Alert>
    </li>
  );
};
