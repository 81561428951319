import React from "react";
import imageURLs from "./images/imageURLs.js";


const NetworkDiagramHelp = () => (
  <div>
    <h3>Network Diagram Info</h3>

    <p>
      The Network provides a diagram of all your executable tasks. The tasks are presented by the nodes and the lines between the nodes represent the task dependencies or relationships. All the dependencies in the network are finish to start relationships. That means all predecessor tasks must be complete before any successor task can start. You do not have the ability to change or circumvent these relationships. Use the network diagram to determine which tasks can be staffed for execution each week. Completed tasks will have an 'X' through them.
    </p>

    <p>
      The network diagram has multiple paths through the network. The longest path through the network is the critical path and it determines the end date (finish date) of the project. The critical path tasks have a red border and no float.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.NetworkDiagram}
      alt="report"
    />
  </div>
);

export default NetworkDiagramHelp;
