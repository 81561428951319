import React from "react"
import { CardBody } from "reactstrap"
import styled from "styled-components";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";

import { faTrash, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
const StyledCardBody = styled(CardBody)`
	&.card-body {
		max-height: 200px;
	}
`;

const Info = () => (
		<StyledCardBody>
			<p>Successful project managers make efficient use of their resources. They make sure their resources are assigned to tasks started and not complete (assuming they have the right skill for that task), and they update their resource plan based on actual project status to minimize resources arriving too early or too late to the project. 

   </p>
    <p><u>Staffing Plan:</u></p>
  
    <p>The Staffing Plan is built using your actual <b>project status (Assigned column) and current resource plan (Priority 2 and 3 columns)</b>. 
    </p>
  
    Staffing Plan Columns:
    <ul>
      <li>Assigned Column - <span style={{ fontWeight: "bold", color: "red" }}>The Assigned column task will always be the task that each resource was working on at the end of the previous week. </span>The only exception is a resource just added to the project. In this case the Assigned task is the first task in the Resource Plan for the resource. The Assigned column provides the ability to change the Assigned task you want a resource to work on but <span style={{ fontWeight: "bold", color: "red" }}>BE CAREFUL!</span>  Since the resource was working on that task the previous week, it means that task is not done, and you will typically want to keep the resource on that task until it is completed. Only change the Assigned task to a task that has started or is about to start. </li>
<li>Priority 2 and 3 Columns - Priority 2 and 3 column tasks are based on the future week task assignments from the Resource Plan. It provides the ability to see the task priorities (sequence) for each resource. <span style={{ fontWeight: "bold", color: "red" }}>Priority 2 and 3 task changes can only be made in the Resource Plan.</span></li>
<li>Overtime Column - Request a resource to work the selected number of overtime hours this week</li>
<li>Remove Column - Immediately release a resource from your project</li>
    </ul>
    

    <p><u>Currently Onboarding Panel Tab</u></p>
    <p>Lists resources 1 or 2 weeks from arriving for task assignment on your project.
    </p>
    
    <p><u>View Staffing Issues Panel Tab</u></p>

    <p>Lists assignment warnings you may want to resolve before running the week.</p>
    
    <p><u>Resource Plan – Access Provided via Staffing for Convenience</u></p>
    
    <p>The Resource Plan, Available Resources, Gantt Chart and Network Diagram are duplicated on the Staffing Screen. Editing the Resource plan here is remarkably like the Resource Plan on the Planning screen. </p>
    <p>
      Late or early task starts, the addition of a new task, resource removal, or a decision to add a resource not originally planned, will require one or more of the following changes to the Resource Plan:</p>
    <ol>
      <li>
        As necessary, update your current staffing and task assignments in your resource plan based on project task status. Shift individual resource plan assignments one week to the left (ahead of schedule) or one week to the right (behind schedule) to:
        <ul>
          <li>
Align current resources to active tasks, or
          </li>
          <li>
Staff or release resources earlier or later to your project
          </li>
      </ul>

       </li>
<li>If you want to change a planned task assignment for the week, click on the cell and select a task assignment from the dropdown.</li>
<li>If a new task is added to your project, and/or you want to add a resource to your plan, click on “Available Resources” tab in panel header to view available resources and their profiles (click on profile icon). Add resources by clicking on the + icon.</li>
<li>If you want to add a resource to your plan, click on “Available Resources” tab in panel header to view available resources and their profiles.</li>
<li>Remove a resource from the resource plan by clicking on its remove icon in the Action column.
</li>
</ol>

		</StyledCardBody>
  )
export default Info
