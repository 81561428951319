import React from "react";
import CustomizedDropDown from "components/CustomizedDropDown";
import { isOnProject } from "utilities/utils.js";


export class WorkerAssignmentModal extends React.Component {

 state = {
      overtime: 0,
   currentWorker: {}
    };
  

  componentDidMount() {
    this.setState({
      overtime: this.props.workerAssignmentItem.HoursOvertime,
      currentWorker: this.props.workerAssignmentItem
    });
  }

  renderOvertimeInput() {
    const overtimeOptions = [];
    let thisKey = 1;
    if (isOnProject(this.props.workerAssignmentItem.WorkerStatusCode)) {
      for (let i = 0; i < 21; i++) {
        overtimeOptions.push(
          <option key={thisKey++} style={{ width: "100%" }} value={i} label={i}>
            {i}
          </option>
        );
      }

      return (
        <CustomizedDropDown
          id={this.props.workerAssignmentItem.WorkerId.toString()}
          key={thisKey++}
          options={overtimeOptions}
          value={this.state.overtime}
          editableObject={this.props.workerAssignmentItem}
          itemKey={this.props.workerAssignmentItem.WorkerId.toString()}
          labelField="OvertimeHours"
          valueField="OvertimeHours"
          whichStyle="center"
          onChange={this.handleOvertimeChange.bind(this)}
        />
      );
    }
    return <span />;
  }

  handleOvertimeChange = change => {
    //  var itemId = change.whichItemId
    const currentWorker = change.whichObject;
    const rIndex = this.props.rIndex;
    this.props.processAssignments(
      rIndex,
      currentWorker,
      "Overtime",
      "",
      change.newValue
    );
  };

  // need to show overtime
  //  tasks in priority order comes from resource plan but in worker Assignments
  //
  //
  //
  render() {
    return <div>{this.renderOvertimeInput()}</div>;
  }
}
