import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip} from "reactstrap";
import Resource from "components/Resource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faAddressCard } from "@fortawesome/free-solid-svg-icons";

const AvailableResourceListItem = props => {
  return (
    <Card>
      {/* <Toast style={{ border: "1px darkGrey solid", padding: "3px" }}>
        <div
          style={{
            textAlign: "right",
            verticalAlign: "top",
            borderBottom: "0px lightGray solid",
            height: "30px"
          }}
        > */}
      <CardHeader style={{padding: "0.25rem 1rem"}}>
          <Button
            id={`profile-${props.rowIndex}`}
            color="link"
            onClick={((e) => props.showProfile(props.resource, e))}
            style={{padding: "0 0.25rem"}}
          >
            <FontAwesomeIcon icon={faAddressCard} />
          </Button>
          <UncontrolledTooltip
            placement="top"
            target={`profile-${props.rowIndex}`}
          >
            Click to show profile for {props.resource.ResourceName}
          </UncontrolledTooltip>
          <Button
            id={`add-${props.rowIndex}`}
            color="link"
            onClick={() => props.insertResourcePlan(props.resource)}
            style={{padding: "0.25rem"}}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
          <UncontrolledTooltip
            placement="top"
            target={`add-${props.rowIndex}`}
          >
            Click to add {props.resource.ResourceName} to the project
          </UncontrolledTooltip>
       </CardHeader>
        <CardBody style={{overflow: "hidden"}}>
          <Resource
            showStatus={false}
            row={props.resource}
            status={props.resource.Status}
            onClick={e => props.showProfile(props.resource, e)}
          />
          <div style={{height: "5px"}} />
        </CardBody>
      </Card>
  );
};

export default AvailableResourceListItem;
