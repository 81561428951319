import React from "react";

export const TaskComplete = props => {
  return (
    <div className="content">
      <p><strong>Task Complete</strong> &nbsp;
      Task {props.properties.task} is complete.</p>
    </div>
  );
};
