import React from "react";
import { connect } from "react-redux";

import { fetchProjectData } from "redux/Project/thunks";
import { getNonDeferredTaskSkillList } from "redux/Reference/selectors";
import ReactHtmlParser from 'react-html-parser';

import { Card, CardHeader, CardBody } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack, faListUl } from "@fortawesome/free-solid-svg-icons";

export const TasksBis = props => {
  let i = 1;

  let renderTitle = () => {
    let productId = props.productId;
    return productId === 50 ? (
      <h2>
        <FontAwesomeIcon icon={faListUl} id="icon-title" /> PACCISS Project Task
        Descriptions
      </h2>
    ) : (
      <h2> Alliance Development Project Task Descriptions</h2>
    );
  };

  

  return (
    <div id="task-div" className="style-background-components">
      {renderTitle()}

      <br />
      <br />

      {props.taskSkillList.filter(t => t.DeferLoad === false)
        .sort((a, b) => {
          return a.TaskId - b.TaskId;
        })
        .map((row, index) => (
          <Card key={i++}>
            <CardHeader className="task-headers">{`Task: ${row.TaskId} - ${row.TaskName}`}</CardHeader>
            <CardBody>
              <div>{ ReactHtmlParser(row.TaskDescription) }</div>
        
              <p>
                <strong>Skills Required:</strong>
              </p>
              {row.SkillsRequired.map((sRow, sIndex) => (
                <div key={i++}>
                  <FontAwesomeIcon icon={faThumbtack} className="icons-tasks" />
                  {sRow.SkillName} ({sRow.StaffingQty})<br />
                </div>
              ))}
            </CardBody>
          </Card>
        ))}
    </div>
  );
};

const mapStateToProps = state => ({
  runId: state.project.runId,
  taskSkillList: state.project.taskSkillList,
  workerFixedList: state.project.workerFixedList,
  nonDeferredTaskSkillList: getNonDeferredTaskSkillList(state),
  productId: state.auth.productId
});

const mapDispatchToProps = dispatch => ({
  fetchProjectData: runId => dispatch(fetchProjectData(runId))
});

export const TaskContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksBis);
