import React from 'react';
import ResourceImage from 'components/ResourceImage';

export const Reassign = props => {
	// if there was no new staffing activity, worker will be none, don't need
	// image or worker then, just the status
	var iterKey = 1;

	const renderBody = () => {
		if (props.properties.worker === 'Buki') {
			return <p> Assistant</p>
		} else if (props.properties.title !== undefined && props.properties.title !== null) {
			return <p>{props.properties.worker}<br />{props.properties.title}</p>
		} else {
			return <p>{props.properties.worker}</p>
		}
	}
	// eslint-disable-next-line no-return-assign
	return (
		<div className='report-Item'>
		<div className='report-Item_Icon'>
			<div className='resource-Profile'>
				<ResourceImage
					key={(iterKey += 1)}
					png={
						props.properties.worker === 'Buki'
							? 'Assistant.png'
							: `${props.properties.worker
									.replace(/\s+/g, '')
									.replace(/\./g, '')}.png`
					}
					alt={props.properties.worker}
				/>
			</div>
			<div className='resource-Body'>
			{renderBody()}
			</div>
		</div>
		<div className='report-Item_Content'>
			was reassigned to {props.properties.tasktype} {props.properties.task}


		</div>
	</div>


	);
};
