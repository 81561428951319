import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons";import imageURLs from "./images/imageURLs.js";

const ResourcePlanningHelp = () => (
  <div>
    <h3> Planning Your Project – Resource Plan </h3>
    <h4>Context and Resources for Resource Planning:</h4>
    <p>
      Create a resource plan to document what resources (human) you need at what
      time and in what amounts for the project. A complete resource plan will:
    </p>
    <ol>
      <li>
        Identify when to add/remove resources to/from the project.

      </li>
      <li>Identify the weekly task assignments for each resource.
      </li>
      <li>Determine the total labor costs for the project.
      </li>
    </ol>
    <p>
      It is good project management to keep your Resource plan up to date as you
      execute your project.
    </p>
    <h4>Building your Resource Plan</h4>


    <ol>
      <li style={{marginBottom: "10px"}}>
        The Gantt Chart provides a great visual of which tasks are planned to be active each week of the project.
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.gantt}
          alt="gantt"
        />

         <p>Click on a task name and you will see:</p>
      <ul>
        <li style={{ listStyleType: "square" }}>The duration of the task in days</li>
          <li style={{ listStyleType: "square" }}>The skills required to complete the task</li>





      <img
        style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

        src={imageURLs.task}
        alt="task"
      />
      </ul>
    <p>
      Knowing the skills required to complete the task and the planned project weeks for the task are critical in putting together a correct resource plan. As an example, Task Number 3 is planned to be executed in weeks 6 through 8 of the project (15-day task duration) and requires two resources, each with a specific skill. The Resource Plan must show two different resources, with the correct skills, assigned to Task Number 3 in project weeks 6, 7 and 8.
    </p>
      </li>
      <li style={{marginBottom: "10px"}}>
        Resource Plan – with the knowledge of the required skills and planned weeks for each task, select a specific resource with the required skill to work on that task from:
        <ul>

          <li style={{ listStyleType: "square" }}>
            <p>Resources currently in your Resource Plan. They must be available (not already assigned a task) for task assignment
               (Step 5) in the required weeks
            </p>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "0px", marginBottom: "15px"}}

              src={imageURLs.rp1}
              alt="resource plan"
            />
          </li>

          <li style={{ listStyleType: "square" }}>
            The Available Resources window (Step 3) if the desired resource is not currently in your Resource Plan.
          </li>
          <li style={{ listStyleType: "square" }}>
            <p><b>
              {" "}
              Any resource who is in the Resource Plan by default is already on the project in week 1 (does not need to be staffed) and can be assigned tasks starting in week 1 (Step 5)!

               </b>
            </p>
          </li>

        </ul>
      </li>
      <li style={{marginBottom: "10px"}}>
        <p>
        Available Resources window - read resource profiles, compare, and pick your best resource:
        </p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.ar}
          alt="available"
        />

      <Row>
        <Col lg="12">
            <p style={{marginTop: "8px"}}>Filter the available resources panel by the required skill (dropdown) </p>
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

            src={imageURLs.ar2}
            alt="available2"
          />
        </Col>

      </Row>


      <Row>
        <Col lg="4">
          <p style={{marginTop: "8px"}}>
            Compare resources through their individual resource profiles (click on their avatar).

          </p>


        </Col>
          <Col lg="8">
            <img
            style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

            src={imageURLs.ar3}
            alt="available3"
            />

          </Col>
      </Row>
      <Row>
        <p>Multiple profiles can be opened at the same time making comparison easy. The profiles provide performance review,
        resume and general information on each resource.
        </p>

        <p>Once you have made your resource selection, add the resource to your Resource Plan by clicking on the “Add” button. This action opens the Choose Initial Staffing Week window (Step 4).
        </p>
      </Row>

      </li>
      <li style={{marginBottom: "10px"}}>
        <Row>
          <Col lg="4">
        <p>Choose Initial Staffing Week window -– The Staff week is required by the application to begin the 2-week onboarding process.
        </p>
          </Col>
      <Col log="8">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.is1}
          alt="is"
        />
      </Col>
        </Row>

            <Row>
              <Col lg="4">
                <p>Using the dropdown, select the week you need the resource to be available for task assignment and press the blue Save button.
                </p>
              </Col>
              <Col log="8">
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-20px", marginTop: "0px", marginBottom: "5px"}}

                  src={imageURLs.is2}
                  alt="is2"
                />
              </Col>
            </Row>



            <Row>
            <p>Upon selection (Save) the resource is added to your Resource Plan and the word “Staff” has been added to the two weeks prior to your requested
            week.  The Staff week is required by the application to begin the 2 week onboarding process.  If you delete this action, the resource will
            not come on to your project automatically.
            </p>

            <img
              style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

              src={imageURLs.is3}
              alt="is3"
            />
            </Row>


      </li>
      <li style={{marginBottom: "10px"}}>
        <Row>
          <Col lg="8">

            <p>  Resource Plan - Assign Task Number. Assign resources the task number of the task you want them to work on each week they are on the project. Add the task number by clicking in the cell and using the dropdown or clicking in the cell and typing the task number.</p>
          </Col>

          <Col lg="4">
            <img
              style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

              src={imageURLs.TaskDropdown}
              alt="is3"
            />

          </Col>
        </Row>


      </li>
      <li style={{marginBottom: "10px"}}>
        <Row>
          <Col lg="8">
        Resource Plan - Release Resource. When you no longer need a resource (no task need for their skill) on the project select "Release" for the cell of the week you will be releasing them back to the Available resources window (resource pool). Releasing a resource when you no longer need them on your project saves your project money. Released resources can be brought back to the project in later weeks by Staffing them two weeks prior to the week you need to assign them to a task. To denote the re-staffing need and two-week period click on the cell and select Staff from the dropdown. Then add task assignments as required after the two-week requesting period (Step 5).
          </Col>

      <Col lg="4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.TaskDropdownRelease}
          alt="is3"
        />

      </Col>
        </Row>
      </li>

      <li style={{marginBottom: "10px"}}>
        <p>Compare your planned budget numbers against your approved budget numbers
        for individual tasks using the “View Full Budget” button.
        </p>

        <img
          style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.ViewFullBudgetButton}
          alt="is3"
        />
      </li>
    <li style={{marginBottom: "10px"}}>
        <Row>
          <Col lg="8">
        Resource Plan Vacation - Vacation time (represented with the letter V in the resource plan) cannot be planned or controlled by the Project Manager. Vacations are announced to the Project Manager by the Human Resources department during the execution of the project, not during planning. During execution it is appropriate to update the resource plan to reflect any announced vacations. Planning vacation before it is known (provided by HR) is meaningless because the Project Manager does not determine vacation and therefore cannot plan for vacations.
          </Col>
              <Col lg="4">
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

                  src={imageURLs.TaskDropdownVacation}
                  alt="is3"
                />

              </Col>
        </Row>
    </li>
    </ol>
    <h4> Editing your Resource Plan </h4>
    <Row>
      <Col lg="8">
    <p>
      Edit Individual cells by clicking in the cell and using the dropdown.
    </p>
      </Col>
      <Col lg="4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginTop: "5px", marginBottom: "5px"}}

          src={imageURLs.TaskDropdown}
          alt="is3"
        />

      </Col>
    </Row>

      Resources can be removed from the plan and returned to Available Resources by clicking on the trashcan icon <FontAwesomeIcon icon={faTrash} />  to the far right of each row.


    <h4>Execution and your Resource Plan:</h4>
    <p>
      Use your resource plan in making your weekly project decisions. Follow your plan if it makes sense but change your plan as project conditions change (i.e. ahead or behind schedule). When you start executing the project, the Planned Budget amounts will be locked; however, you will still be able to update your Resource Plan to match your actual progress. It is good Project Management practice to keep your Resource Plan consistent with the current project status, even though the Planned Budget is locked.

    </p>
  </div>
);

export default ResourcePlanningHelp;
