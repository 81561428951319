import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import { processTaskAssignmentShift } from "redux/Assignment/AssignmentsModule";
import { toggleLoading } from "app/redux/actions";

const ShiftBackwardAction = props => {
  const content = useSelector(state => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  // const currentResource = props.cellInfo.row.original;
  // const rowIdx = props.cellInfo.row.index;
  const currentResource = props.row;
  const { rowIdx } = props;

  const runId = content.project.runId;
  const detailInfoWeekMap = content.planning.resource.detailInfoWeekMap;

  const handleActionShiftBackward = async () => {
    dispatch(toggleLoading(true));

    // algorithm is :
    // take the task from the previous week = weekNumber - 1 and copy it to the current week
    // take the current week task and copy it to the next week and shift every task down one week
    // TODO somehow the projected finish week will have to move forward

    // go through the dets for this person, find them for weeknumber - 1 through end
    // put in a collectio
    // let currentResourceDetails = [];
    const dataObjCollection = [];
    let list = [];
    // grab previous week's value and stick it into current week

    // now loop through the future weeks and grab the value from each
    // loopweek's previous week to put in the current loopweek
    // now loop through the future weeks and grab the value from each
    // loopweek's previous week to put in the current loopweek

    let futureLoopWeek = parseInt(props.currentProjectWeek, 0, 10) + 1; // starting 2 weeks ahead
    const loopWeek = props.currentProjectWeek; // starting current week
    // add 1 to project finish because we're moving it out a week now
    /* eslint-disable no-loop-func */

    for (let i = 1; i <= props.maxWeeks; i++) {
      const wk = `W${i.toString()}`;
      list = detailInfoWeekMap.filter(
        item => item.Item3 === currentResource.RowId && item.Item4 === wk
      );
      if (i < loopWeek) {
        dataObjCollection.push({
          RunId: runId,
          DetailId: list[0].Item1,
          NewAction: currentResource[wk],
          Week: wk
        });
      } else {
        // hardcoded project end week for simulations - move all the items
        const futureWk = `W${futureLoopWeek.toString()}`;
        let newAction = "None";
        if (futureLoopWeek < content.project.maxWeeks) {
          newAction = currentResource[futureWk];
        }

        dataObjCollection.push({
          RunId: runId,
          DetailId: list[0].Item1,
          NewAction: newAction,
          Week: wk
        });
        futureLoopWeek += 1;
      }
    }
    const wa = content.project.workerAssignmentList.filter(
      item => item.WorkerId === currentResource.ResourceId
    );

    await dispatch(processTaskAssignmentShift(wa[0], dataObjCollection)).then(
      () => {
        dispatch(toggleLoading(false));
      }
    );
  }; // end of handleActionShiftBackward

  return (
    <>
      <Button
        color="link"
        id={`tooltip-shiftRight-${rowIdx}`}
        onClick={() => {
          handleActionShiftBackward();
        }}
      >
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </Button>
      <UncontrolledTooltip
        placement="top"
        target={`tooltip-shiftRight-${rowIdx}`}
      >
        Keep your resource plan in sync with the state of your project. Starting
        week 2, you can shift the plan forward or back for each resource
        depending on the state of your project.
      </UncontrolledTooltip>
    </>
  );
};

export default ShiftBackwardAction;
