/* eslint-disable no-useless-escape */

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import accounting from "accounting";
import {
  Label,
  FormText,
  Col,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Row,
  InputGroupAddon,
  InputGroupText,
  Form,
  InputGroup
} from "reactstrap";
import RichTextEditor from "react-rte";
import { saveRisk } from "redux/Risk/thunks";
import { ModalForm } from "components/styled/ModalForm";
import _debounce from "lodash.debounce";
import { components } from "react-select";

const StyledLabel = styled(Label)`
  font-weight: "bold";
`;
// for the accounting package display helper - see
// https://www.npmjs.com/package/accounting
// used to help display money values
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

class AddRisk extends React.Component {
  state = {
    costImpactRegex: /^-?[0-9][0-9]*\d*(\.\d+)?$/,
    emptyRegex: /(?:)/,
    riskItem: {
      ID: 0,
      RunId: this.props.runId,
      Action: "",
      CostImpact: "",
      Probability: 20,
      OnCriticalPath: false,
      EventName: ""
    },
    requiredMessage: "",
    requiredComponent: "",
    value: RichTextEditor.createEmptyValue(),
    loading: false
  };

  componentDidMount() {
    let item = { ...this.props.riskItem };
    if (item && item.ID > 0) {
      this.setState({
        riskItem: { ...item },
        value: RichTextEditor.createValueFromString(item.Action, "html")
      });
    }
  }

  handleEventNameChange = (event) => {
    const updated = this.state.riskItem;

    updated.EventName = event.target.value;
    this.setState({ riskItem: updated });
  };

  handleCostImpactChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const updated = this.state.riskItem;

    if (
      this.state.costImpactRegex.test(value) ||
      value === "" ||
      value === "-"
    ) {
      updated.CostImpact = event.target.value;
      const pctProb = updated.Probability * 0.01;

      this.setState({ riskItem: updated, costInputValid: true });
    }
    return;
  };

  handleProbChange = (event) => {
    const updated = this.state.riskItem;
    if (updated && event.target && event.target.value) {
      updated.Probability = event.target.value;
      const pctProb = updated.Probability * 0.01;

      this.setState({ riskItem: updated });
    }
  };

  handleSaveClick = _debounce((event) => {
    event.preventDefault();
    const updated = this.state.riskItem;
    if (!updated) {
      return; // need a message here.
    }

    if (
      !updated.EventName ||
      updated.EventName === "" ||
      updated.EventName.length < 5
    ) {
      this.setState({
        requiredMessage:
          "Please enter a valid Event Name, must be longer than 5 characters",
        requiredComponent: "eventname"
      });
      return;
    } else if (!updated.CostImpact || updated.CostImpact === 0) {
      this.setState({
        requiredMessage: "Please enter a value in Cost Impact",
        requiredComponent: "costimpact"
      });
      return;
    } else if (
      !updated.Action ||
      updated.Action === "" ||
      updated.Action === "<p><br></p>"
    ) {
      this.setState({
        requiredMessage: "Please fill out an action plan",
        requiredComponent: "actionplan"
      });
      return;
    } else {
      this.setState({
        requiredMessage: "",
        requiredComponent: ""
      });
    }
    this.setState({ loading: true });
    this.props.saveRisk(updated).then(() => {
      this.setState({ loading: false });
    });
  }, 75);

  handleActionChange = (value) => {
    const updated = this.state.riskItem;

    updated.Action = value.toString("html");

    this.setState({
      value,
      riskItem: updated
    });
  };

  toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS"
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" }
    ]
  };
  //className="m-t"
  render() {
    return (
      <Form className="form-split">
       
        <FormGroup>
          <StyledLabel for="eventname">Enter event name</StyledLabel>

          <Input
            id="eventname"
            required
            type="text"
            name="eventname"
            value={this.state.riskItem.EventName}
            minLength="5"
            maxLength="100"
            onChange={this.handleEventNameChange}
            placeholder="Risk/Opportunity Event Name"
            innerRef={(ef) => (this.eventNameInput = ef)}
          />
            {this.state.requiredComponent === "eventname" && <h6  style={{ color: "red", marginTop: "3px"  }}>{this.state.requiredMessage}</h6>}
        </FormGroup>

        <FormGroup>
          <StyledLabel for="probability">
            Choose probability percentage
          </StyledLabel>

          <Input
            type="select"
            name="probability"
            id="probability"
            label="Select Probability"
            required
            value={this.state.riskItem.Probability}
            onChange={this.handleProbChange}
            placeholder="Probability Selection"
            innerRef={(ps) => (this.probSelect = ps)}
          >
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <StyledLabel for="costimpact">Choose cost impact amount</StyledLabel>

          <Input
            type="text"
            name="cost"
            id="costimpact"
            required
            placeholder="Cost impact"
            value={this.state.riskItem.CostImpact}
            onChange={this.handleCostImpactChange}
            innerRef={(ci) => (this.costImpact = ci)}
          />
           {this.state.requiredComponent === "costimpact" && <h6 style={{ color: "red", marginTop: "3px"  }}>{this.state.requiredMessage}</h6>}
        </FormGroup>

        <FormGroup>
          <div className="text-left" style={{ fontWeight: "bold" }}>
            <span>
              <StyledLabel for="netCost">
                <strong>Net Cost:</strong>{" "}
              </StyledLabel>
            </span>
            <span id="netCost" style={{ marginLeft: "5px" }}>
              {accounting.formatMoney(
                this.state.riskItem.Probability *
                  0.01 *
                  this.state.riskItem.CostImpact,
                options
              )}
            </span>
          </div>
        </FormGroup>

        {/* <h3 className="mb-0 mt-4">

						</h3> */}
        <FormGroup>
          <StyledLabel for="risktextarea">Enter response action</StyledLabel>
          <RichTextEditor
            style={{ height: "100px" }}
            required
            value={this.state.value}
            onChange={this.handleActionChange}
            placeholder="Enter action plan for risk/opportunity; format as needed"
          />
          {this.state.requiredComponent === "actionplan" && <h6  style={{ color: "red", marginTop: "3px" }}>{this.state.requiredMessage}</h6>}
        </FormGroup>
        {/* <Input
              id="risktextarea"
								type="textarea"
								name="action"
								innerRef={(ac) => (this.actionInput = ac)}
								valid={this.state.actionInputValid}
								value={this.state.riskItem.Action}
								placeholder="Risk Action to be taken"
								minLength="5"
								onChange={this.handleActionChange}
								required="required"
							>
							{this.state.actionInputValid === false && (
								<FormFeedback>Action must be a least 5 characters</FormFeedback>
							)}
						</Input> */}

        <FormGroup>
          <Button
            className="float-left"
            color="primary"
            disabled={this.state.loading}
            style={{ marginTop: "20px" }}
            onClick={this.handleSaveClick}
          >
            Save
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  risks: state.planning.risk.risks,
  runId: state.project.runId
});

const mapDispatchToProps = (dispatch) => ({
  saveRisk: (data) => dispatch(saveRisk(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRisk);
