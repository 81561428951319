import React from "react";

export const IndvTraining = props => {
  return (
    <div className="content">
      <h3>Individual Training</h3>
      <p>
        {props.properties.worker} attended the {props.properties.training} all
        day today.{" "}
      </p>
    </div>
  );
};
