import React from "react";

import imageURLs from "./images/imageURLs.js";

const SupportHelp = () => (
  <div>
    <h3>Getting Started - Contacting Support</h3>

    <p>
      <strong>Support Times:&nbsp;&nbsp;</strong>Technical support is available M-F from
      9am to 5pm Eastern Time via support@simulationpl.com.
    </p>
    <p>
      <strong>Off Hours:&nbsp;&nbsp;</strong> You can email at off hours and weekends;
      however, we cannot guarantee a response during off hours.
    </p>
    <p>
      <strong>Do Not Ask:&nbsp;&nbsp;</strong> Please do not contact support with questions related to making project management decisions. Many instructors grade students based on project outcomes, so we cannot provide hints that give one student an edge over another.
    </p>
    <p>
      <strong>Information That We Must Have:&nbsp;&nbsp;</strong> When you need support
      because something does not appear to be working properly, please provide
      as much information concerning your problem as possible. Telling us <i>"I
      can't log in."
                                                                          </i> or <i>"My project will not run."</i> causes delays since we will
      have to email you back and get the information we need to resolve the
      issue. We need the following information (In order of importance) in your
      email to support@ simulationpl.com:
    </p>
    <ul>
      <li>
        <strong>A screen print/capture of the problem or message if possible!!!</strong>
      </li>
      <li>A detailed description of your problem</li>
      <li>Your First and Last Name</li>
      <li>
        Your Execution ID# (also known as Run ID#) which you can see on your
        Dashboard
      </li>
      <li>Your Telephone Number, if you would like us to call you</li>
    </ul>

    <p>For example:</p>
    <p>
      <strong>
        <i>
          Below is a screen capture of the error message. When I attempt to log
          in I get a message that my license has expired. My username is
          janedoe.
        </i>
      </strong>
    </p>
    <p>
      <img
        style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "20px", marginBottom: "10px"}}

        src={imageURLs.expiredAccountErrorMessage}
        alt="error message example"
      />


    </p>
  </div>
);

export default SupportHelp;
