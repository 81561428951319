import React from 'react';
import ResourceImage from 'components/ResourceImage';

export const PlayScript = props => {
	return (
		<div className="report-Item">
			<div className="report-Item_Icon">
				<div className="resource-Profile">
					<ResourceImage alt="Assistant" png="Assistant.png" />
				</div>
				<div className="resource-Body">
					<h2>Assistant</h2>
				</div>
			</div>
			<div className="report-Item_Content">
				<p>{props.properties.message}</p>
			</div>
		</div>
	);
};
