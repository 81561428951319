
import styled from "styled-components";


const LoadingContainer = styled.div`
width: 100%;

justify-content: center;
align-items: center;
min-height: 10px;
color: black;
`;

export default LoadingContainer