import React from "react";

export const StandardsReview = () => {
  return (
    <>
      <p>
        It has been pointed out to me that we are having difficulty using our
        current process standards given our required schedule, budget, and
        delivery quality objectives. I would like all of you to get together and
        come to agreement on the standards you feel will work for meeting our
        project objectives. Send me a copy of what you have developed. I will
        approve it.
      </p>
      <footer>
        <p>ID #1073</p>
      </footer>
    </>
  );
};
