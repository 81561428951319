import React from "react";
import styled from "styled-components";

const h4 =styled.h6`
   color: "#5378AD" !important
`;

const TrainingOptionsCosts = props => {
  //function to render the cost of the different training options according to the productId/ project

  /*First function => for 
	1.Communication and Interpersonal Skills 
	2.Project Management
	3.Leadership
	4.Quality and Quality Assurance
*/

  const lowerCostsAccordingToProductId = () => {
    return props.productId === 50 ? "$1,500" : props.productId === 43  ? "$1,000" : "$750";
  };

  /*Second function => for 
	1.Requirements Analysis and Design 
	2.Implementation Methods and Technologies
	3. Systems Testing and Deployment
*/

  const higherCostsAccordingToProductId = () => {
    return props.productId === 50 ? "$2,100" : props.productId === 43  ? "$1,500" : "$1,050";
  };

  /*Third function => for 
	1.Team building
  */

  const teamBuildingCostByProductId = () => {
    return (props.productId === 50 || props.productId === 51)? "$32,000 for the team.": (props.productId === 43) ? "$13,000 per attendee.": (props.productId === 38) ? "$1,500 per attendee." : "$750 per attendee.";
  };

  return (
    <div className="box-content">
      <div className="text div-training-and-costs">
        <h4> Communication and Interpersonal Skills </h4>
        <p>
          This is a 2 day workshop that meets on Monday and Tuesday. The cost of
          this workshop is {lowerCostsAccordingToProductId()} per attendee.
        </p>
        <h4>Project Management</h4>
        <p>
          This is a 2 day workshop that meets on Monday and Tuesday. The cost of
          this workshop is {lowerCostsAccordingToProductId()} per attendee.
        </p>
        <h4>Leadership</h4>
        <p>
          This is a 2 day workshop that meets on Monday and Tuesday. The cost of
          this workshop is {lowerCostsAccordingToProductId()} per attendee.
        </p>
        <h4>Quality and Quality Assurance</h4>
        <p>
          This is a 2 day workshop that meets on Monday and Tuesday. The cost of
          this workshop is {lowerCostsAccordingToProductId()} per attendee.
        </p>
        <h4> Requirements Analysis and Design </h4>
        <p>
          This is a 3 day workshop that meets on Wednesday, Thursday, and
          Friday. The cost of this workshop is{" "}
          {higherCostsAccordingToProductId()} per attendee.
        </p>
        <h4> Implementation Methods and Technologies </h4>
        <p>
          This is a 3 day workshop that meets on Wednesday, Thursday, and
          Friday. The cost of this workshop is{" "}
          {higherCostsAccordingToProductId()} per attendee.
        </p>
        <h4>Systems Testing and Deployment</h4>
        <p>
          This is a 3 day workshop that meets on Wednesday, Thursday, and
          Friday. The cost of this workshop is{" "}
          {higherCostsAccordingToProductId()} per attendee.
        </p>
        <h4>Teambuilding</h4>
        <p>
          This is a 2 day workshop that meets on Wednesday and Thursday. The
          cost of this workshop is {teamBuildingCostByProductId()}
        </p>
      </div>
    </div>
  );
};

export default TrainingOptionsCosts;
