import React from "react";

const ResourceTooltip = () => (
    <div style={{textAlign: "left"}}>
      Do you have:
      <ul>
        <li>Resources, with the right skills, assigned to EVERY task for the duration of the task?</li>
        <li>The word “Staff” in the two weeks prior to needing a resource on the project <strong>(VERY IMPORTANT)</strong>?</li>
        <li>The word “Release” after the last week you need a resource on the project?</li>

      </ul>
    </div>
  )

export default ResourceTooltip
