/* eslint-disable max-len */
import React from "react";

export const Staffing = () => {
  return (
    <div id="Staffing" className="style-background-components">
      <header>
        <h2>Staffing Projects</h2>
      </header>
      <div>
        <h4>Special Projects</h4>
        <p>
          Special projects will not be given the option of staff selection. On
          these special projects personnel are also pre-assigned to tasks. No
          staffing decisions will be required. Special projects include projects
          where personnel are available less than 50% of the time to the
          project.
        </p>
        <h4>Requesting Staff</h4>
        <p>
          For staffing projects, you are provided with a list of company
          personnel that have been previously identified by their respective
          resource managers as being available and having the requisite skills
          for assignment to your project. When a specific resource is requested
          by you there is a two week lead time between the actual request and
          the arrival of the resource on the project. This lead time is used by
          the resource manager to process the request and by the requested
          individual to transition from their current work activities.
        </p>
        <p>
          You will need to keep this lead time in mind when you request
          personnel in order to ensure that needed resources arrive on a timely
          basis. Please also note that the availability of these identified
          individuals may change at any time due to unforeseen future company
          needs. However, unless you are notified otherwise you can safely
          assume that the individual will arrive as promised. In the event that
          a requested individual is not immediately available, generally the
          responsible resource manager will offer alternative possibilities.
        </p>
        <h4>Assigning Staff</h4>
        <p>
          Project personnel available for work must be assigned to a project
          task.
        </p>
        <p>
          If personnel are not assigned to a task starting the week, or assigned
          to tasks that are not active, (waiting for a predecessor task to
          complete), they will accomplish no work and their costs will be
          charged to the project reserve budget.{" "}
        </p>
        <p>
          If an assigned task is active, (not waiting for a predecessor task to
          complete), the individual will work on that task until one of three
          things happen: 1) the task is complete 2) a higher priority task
          becomes active or 3) task assignments are changed. It is helpful to
          assign personnel to a sequence of tasks so that as they complete their
          first priority task, they will automatically move to their second
          priority task.
        </p>
        <p>
          If personnel have no second priority task when they complete their
          first priority task, they will immediately announce they are done with
          their assigned tasks during project execution. The project manager at
          that time can assign a new active task or allow the worker to leave
          the project. Then, only actual hours worked on task are charged to the
          project. The worker is returned to the resource pool where they can be
          requested later if needed. Please note there is no negative stigma
          associated with being released from a project.
        </p>
        <h4>Releasing Staff</h4>
        <p>
          The removal of project personnel is effective immediately and those
          individuals are returned to the available resource pool where they can
          be requested again later if needed. Please note there is no negative
          stigma associated with being released from a project.
        </p>
      </div>
    </div>
  );
};
