import React from "react";
import { Card, CardBody, Label, Input, Collapse } from "reactstrap";
import InfoFooter from "components/Info/InfoFooter";
import CardHeaderBold from "components/styled/CardHeaderBold";

const StakeholderInfo = (props) => (
  <Card className="Card-info">
    <CardHeaderBold>
      <span className="float-left">Stakeholder Info</span>
      <Label className="switch switch-default switch-primary">
        <Input
          type="checkbox"
          className="switch-input"
          onClick={() => props.toggle()}
          defaultChecked={props.panelVisible}
        />
        <span className="switch-label" />
        <span className="switch-handle" />
      </Label>
    </CardHeaderBold>
    <Collapse isOpen={props.panelVisible}>
      <CardBody>
        <h5>Context and resources for stakeholder planning:</h5>
        <p>
          Create a Stakeholder Plan to build and maintain your relationships
          with your stakeholders over the life of your project. Plan specific
          interactions with specific stakeholders in specific weeks in building
          the Stakeholder Plan.
        </p>

        <h5>Building your plan:</h5>

        <p>To add a stakeholder interaction:</p>
        <ol>
          <li>
            Determine the stakeholder and week and
            <strong> click on the intersecting cell.</strong>
          </li>
          <li>
            From the dropdown menu select a specific interaction from the
            available options for that stakeholder.
          </li>
          <li>
            Plan a status report for all stakeholders for the week by clicking
            on the “Report” <i className="fa fa-file-text" /> icon at the top of
            the week.
          </li>
        </ol>

        <h5>Editing your plan</h5>
        <p>
          Once entered, stakeholder interactions can be edited or deleted (None)
          by clicking on the cell and using the dropdown menu. All interactions
          for the week can also be cleared using the “Clear”{" "}
          <i className="fa fa-minus-square-o" /> icon at the top of the week.
        </p>
        <p>
          It is good project management to keep your Stakeholder plan up to date
          as you execute your project.
        </p>
      </CardBody>
      <InfoFooter selectedKey="stakeholder" showProjectLink={true} />
    </Collapse>
  </Card>
);

export default StakeholderInfo;
