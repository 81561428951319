import { createSelector } from "reselect";
// import React from "react";

const getWeeklyInteractions = state =>
  state.execution.interactions.weeklyInteractions;

export const getInteractionsByWeek = createSelector(
  [getWeeklyInteractions],
  interactions => {
    var byWeekLists = {
      mondayList: [],
      tuesdayList: [],
      wednesdayList: [],
      thursdayList: [],
      fridayList: []
    };

    for (var row in interactions) {
      var day = interactions[row].DayOfWeek;

      switch (day) {
        case "Monday":
          byWeekLists.mondayList.push(interactions[row]);
          break;
        case "Tuesday":
          byWeekLists.tuesdayList.push(interactions[row]);
          break;
        case "Wednesday":
          byWeekLists.wednesdayList.push(interactions[row]);
          break;
        case "Thursday":
          byWeekLists.thursdayList.push(interactions[row]);
          break;
        case "Friday":
          byWeekLists.fridayList.push(interactions[row]);
          break;
        default:
          // push to friday, we'll deal  with it later
          // its now 2 years later, we still haven't dealt with it
          byWeekLists.fridayList.push(interactions[row]);
          break;
      }
    }

    return byWeekLists;
  }
);
