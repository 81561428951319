import React, { useState } from "react";
import { Row, Card, CardHeader, CardBody, Label, Input, Collapse, Col } from "reactstrap";
import ConditionFilter from "./ConditionFilter";
import AutoRun from "./AutoRun";
import { connect } from "react-redux";
import { authenticate } from "redux/Authentication/thunks";

const TestPanel = props => {
  const [open, toggle] = useState(true);

  return (
      props.role === 'Administrator' ||
      //props.userName.toLowerCase().startsWith('paccissuser') ||
      //props.userName.toLowerCase().startsWith('paccisstest') ||
      props.userName.toLowerCase() === 'blondie' ||
      props.userName.toLowerCase() === 'samirspl' ||
      props.userName.toLowerCase() === 'SimA1' ||
      props.userName.toLowerCase() === 'TestAzure14' ||
      props.userName.toLowerCase() === 'A2Pacciss14' ||
      props.userName.toLowerCase() === 'samirspl'
      ?
      <Card >
        <CardHeader>
          <span style={{ marginRight: "10px" }}>Testing / Diagnostics Panel</span>
          <Label className="switch switch-default switch-primary">
            <Input
              type="checkbox"
              className="switch-input"
              onClick={() => toggle(!open)}
              defaultChecked={open}
            />
            <span className="switch-label" />
            <span className="switch-handle" />
          </Label>
        </CardHeader>
        <Collapse isOpen={open}>
          <CardBody>
            <Row>
              <Col md="7"> <ConditionFilter /></Col>
              <Col md="5">  <AutoRun /></Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
      :
      ''
  );
};

const mapStateToProps = (state, ownProps) => ({
  role: state.auth.user.Role,
  userName: state.auth.user.UserName
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (username, password) =>
    dispatch(authenticate(username, password))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestPanel);
