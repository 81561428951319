import React from "react";

export const LicenseModal = () => (
  <div>
    <h2>Simulation Powered Learning</h2>
    <p>
      SPL Corporation reserves all rights to both the content contained within 
      the simulation and the content on this website. The SPL simulation is for 
      personal use or for use within a SPL-licenced university or college-class 
      only.
    </p>
    <p>
      No part of this simulation may be reproduced, redistributed or sold for
      profit of any kind. Licences may not be transferred or sold.
    </p>
  </div>
);
