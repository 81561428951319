import React, { useState } from "react";
import { Button, Row, Col, Label } from "reactstrap";
import Select from "react-select";
import "../ProjectStyle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: 'center'
  })
}
const ResetWeekChooser = props => {
  const [selectedWeek, setSelectedWeek] = useState({ value: 1, label: 1 });
  const [loading, setLoading] = useState(false);

  let options = [];
  for (let i = 1; i <= 30; i += 1) {
    options.push({ value: i, label: i });
  }

  return (
    <Row>
      <Col xs={3}>
        <Select
          styles={customStyles}
          options={options}
          value={selectedWeek}
          onChange={selected => {
            setSelectedWeek(selected);
          }}
        />
        <Label>Choose week</Label>
      </Col>
      <Col xs={2}>
        <Button
          color="primary"
          disabled={selectedWeek === 0 ? true : false}
          onClick={() => {
            setLoading(true);
            props.resetProject(props.runId, selectedWeek.value).then(() => {
              setLoading(false);
              props.closeModal();
            });
          }}
        >
          Reset Project
        </Button>
      </Col>
      <Col xs={3} id="column-reset">
        {loading && (
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-pulse"
            id="reset-icon"
          />
        )}
      </Col>
    </Row>
  );
};

export default ResetWeekChooser;
