/* this may just end up being a button component allowing the user to review a previous run */

import React from "react";
import { connect } from "react-redux";
import StartRunContainer from "./StartRun.jsx";
import ResumeRun from "./ResumeRun";
import BumpRun from "./BumpRun";
import ResetRun from "./ResetRun";
import ReviewRun from "./ReviewRun";
import { resumeProject, reviewProject } from "redux/Project/thunks";

// prop is the run detail row, StudentClassification,
class RunActionButton extends React.Component {
  isAdminUser = () => {
    if (
      Number.parseInt(this.props.classification, 10) !== Number.parseInt(0, 10)
    ) {
      return true;
    }
    return false;
  };

  renderChildren = row => {
    const runStatus = row.RunStatus;
    const buttonList = [];
    // order -> Resume/Start/Review | Bump | Reset
    if (
      runStatus.substring(0, 3) === "Run" ||
      runStatus.substring(0, 3) === "App" ||
      runStatus.substring(0, 3) === "Pla"
    ) {
      buttonList.push(
        <ResumeRun
          key="resume-run"
          runId={row.RunId}
          productId={row.ProductId}
          resumeProject={this.props.resumeProject}
        />
      );
    } else if (
      runStatus.substring(0, 3) === "Com" ||
      runStatus.substring(0, 3) === "Aba"
    ) {
      // completed/ abandoned
      buttonList.push(
        <ReviewRun
          key="review-run"
          runId={row.RunId}
          productId={row.ProductId}
          reviewProject={this.props.reviewProject}
        />
      );
    } else if (runStatus.substring(0, 3) !== "Not" ||
    (this.isAdminUser(row) && row.RunId === 0)) {
      buttonList.push(<StartRunContainer row={row} key="start-run" />);
    }


    if (this.isAdminUser() && row.RunId > 0) {
      buttonList.push(
        <BumpRun key="bump-run" classStudentRunId={row.ClassStudentRunDBId} runId={row.RunId} />
      );
      buttonList.push(
        <ResetRun key="reset-run" runId={row.RunId} finalWeek={row.FinalWeek} />
      );
    }
    return buttonList;
  };

  render() {
    return <div id={"a1"}>{this.renderChildren(this.props.thisRun)}</div>;
  }
}

const mapStateToProps = state => ({
  licenseId: state.auth.studentInfo.LicenseId,
  productId: state.auth.studentInfo.ProductId,
  classification: state.auth.studentInfo.StudentClassification
});

// need start project, resume propject, review project

const mapDispatchToProps = dispatch => ({
  resumeProject: (runId, productId) =>
    dispatch(resumeProject(runId, productId)),
  reviewProject: (runId, productId) => dispatch(reviewProject(runId, productId))
});

const RunActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RunActionButton);
export default RunActionsContainer;

/*

 {/* here we want to check if if this is the active team,
                                and if not, is any team active
                                and if not, find the next active team id and initialize a team.
this.props.currentUser.isAdmin ||
                          process.env.NODE_ENV === "stage" ||
                          process.env.NODE_ENV === "dev" ||
                          process.env.NODE_ENV === "development" ?
                    {run.team &&
                    (run.id ===
                      this.activeRecord(data.classruns) ||
                      run.status === "COMPLETED") ? (
                      <Fragment>
                        <Row style={{ float: "right" }}>
                          {run.status === "COMPLETED" ? (
                            <Button
                              color="primary"
                              onClick={event =>
                                this.handleReviewCompletedTeamReports(
                                  event,
                                  run.team.id
                                )
                              }
                            >
                              Review Run
                            </Button>
                          ) : (
                            <Link
                              to={{
                                pathname: "/primary/backlog",
                                state: { teamId: run.team.id }
                              }}
                            >
                              <ContinueTeam teamId={run.team.id} />
                            </Link>
                          )}
                          {this.props.currentUser.role === "INSTRUCTOR" ||
                          this.props.currentUser.isAdmin ||
                          process.env.NODE_ENV === "stage" ||
                          process.env.NODE_ENV === "dev" ||
                          process.env.NODE_ENV === "development" ? (
                            <DeleteTeam
                              teamId={run.team.id}
                              userId={id}
                              classrunId={run.id}
                            />
                          ) : (
                            <span />
                          )}
                        </Row>
                      </Fragment>
                    ) : this.nextActiveRecord(data.classruns) ===
                      run.id ? (
                      <InitializeTeam
                        userId={id}
                        classrunId={run.id}
                      />
                    ) : (
                      <span>Waiting to complete above run </span>
                    )}
*/
