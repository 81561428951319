import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  InputGroupAddon,
  Alert,
  NavLink,
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Label,
  Form
} from "reactstrap";
import { AlertList } from "react-bs-notifier";
import _debounce from "lodash.debounce";
import BrowserDetection from "react-browser-detection";

import { openModal } from "redux/Modal/ModalModule";
import {
  addSingleAlert,
  dismissAlert,
  dismissAlertById,
  clearAlerts
} from "redux/Notification/NotificationModule";
import { login, getUser, authenticate } from "redux/Authentication/thunks";
import { ModalContainer } from "routes/Modal/ModalContainer";
import {
  FORGOT_PASSWORD_MODAL,
  RESET_PASSWORD_MODAL,
  NOTIFICATION_MODAL
} from "routes/Modal/ModalTypes";
import BuildVersion from "components/BuildVersion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logo from "static-new/images/logo-spl--full.png";

import "./Login.css";

const LoadingContainer = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
  min-height: 10px;
  color: black;
`;
//display: flex;
//svg {
// 	margin-right: 1rem;
// }
class Login extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    loginError: "",
    type: "password",
    alerts: this.props.alerts,
    usernameErrorMessage: "Please enter a username",
    passwordErrorMessage: "Please enter a password",
    downForMaintenance: false,
    whatsNewModal: false
  };

  /*

		this.showHide = this.showHide.bind(this);
		this.setLoadingAndLogin = this.setLoadingAndLogin.bind(this);
		this.forgotPassword = this.forgotPassword.bind(this)
  */

  componentDidMount = () => {
    document.body.classList = "";
    document.body.classList.add("gray-bg");
  };

  validateUsername = _debounce((value, ctx, input, cb) => {
    if (!value || value === "") {
      this.setState({
        usernameErrorMessage: "Username is required",
        loading: false
      });
      cb(false);
      return;
    }
    this.setState({ usernameErrorMessage: "" });

    Promise.all([this.props.getUser(value)]).then((response) => {
      if (response[0] === false) {
        this.setState({
          usernameErrorMessage: "Invalid username",
          loading: false
        });
        cb(false);
      } else {
        this.setState({ username: value, usernameErrorMessage: "" });
        cb(true);
      }
    });
  }, 50);

  validatePassword = _debounce((value, ctx, input, cb) => {
    if (!value || value === "") {
      this.setState({
        passwordErrorMessage: "Password is required",
        loading: false
      });
      cb(false);
      return;
    }
    this.setState({ passwordErrorMessage: "" });

    Promise.all([this.props.authenticate(this.state.username, value)]).then(
      (response) => {
        if (response[0] === false) {
          this.setState({
            passwordErrorMessage: "Password is incorrect",
            loading: false
          });
          cb(false);
        } else {
          this.setState({ passwordErrorMessage: "", loading: false });
          cb(true);
        }
      }
    );
  }, 500);

  onAlertDismissed = (alert) => {
    // new es6 destructuring syntax
    const { alerts } = this.state;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        loading: false,
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  };

  clearAlerts = () => {
    this.setState({ alerts: [] });
  };

  clearLoading = () => {
    this.setState({ loading: false });
  };

  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  };

  days = () => {
    var a = moment(Date());
    var b = moment([2, 0, 28]);
    var given = moment("2021-04-03", "YYYY-MM-DD");
    var current = moment().startOf("day");
    return moment.duration(given.diff(current)).asDays();
  };
  // this method handles the actual login submit - it calls the api located
  // in the authentication/redux/thunks
  // fires if all required fields are populated
  loginUser = _debounce(async () => {
    this.setState({ loading: true, loginError: "" });
    if (
      !this.passwordInput ||
      !this.usernameInput ||
      !this.passwordInput.value ||
      (this.passwordInput && this.passwordInput.value === "") ||
      !this.usernameInput.value ||
      (this.usernameInput && this.usernameInput.value === "")
    ) {
      this.setState({
        loading: false,
        loginError: "Please enter both a username and password"
      });

      return;
    } else {
      this.setState({ loading: true });
      const response = await this.props.authenticate(
        this.usernameInput.value,
        this.passwordInput.value
      );

      if (this.props.auth.message === "authenticated") {
        await this.props.login();
      } else {
        this.setState({
          loading: false,
          loginError: this.props.auth.message
        });
      }
    }
  }, 500);

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.loginUser();
    }
  };

  render() {
    return (
      <div className="app route-Login flex-row align-items-center">
        {/* <CheckBrowserSupport /> */}
        <AlertList
          position={this.props.notification.position}
          alerts={this.props.notification.alerts}
          timeout={this.props.notification.timeout}
          dismissTitle="Bye!"
          onDismiss={this.props.dismissAlert}
        />
        <Container>
          <Row className="justify-content-center align-items-center">
            {!this.state.downForMaintenance && (
              <Col lg="4">
                <Card>
                  <CardBody style={{ overflow: "hidden" }}>
                    <div align="center">
                      <img src={logo} alt="SPL Logo" className="logo" />
                      <h4>
                        Simulation Project
                      </h4>
                  
                      <div
                        style={{
                          fontWeight: "bold",
                          marginTop: "5px",
                          marginBottom: "10px"
                        }}
                      >
                        <Button
                          style={{ fontWeight: "bold", color: "maroon" }}
                          color="link"
                          onClick={() => this.setState({ whatsNewModal: true })}
                        >
                          <span role="img" aria-label="tada">
                            🎉
                          </span>{" "}
                          What's new in SimProject?
                        </Button>
                      </div>
                    </div>
                    <BrowserDetection>
                      {{
                        ie: () => (
                          <Alert color="danger">
                            <strong>Please note:</strong> This app has been
                            tested successfully on Edge, Chrome, Firefox and
                            Safari browsers. Please do not use Microsoft's
                            Internet Explorer; it does not work with this app.
                          </Alert>
                        ),
                        default: () => ""
                      }}
                    </BrowserDetection>
                   
                    <p>
                      <strong>
                        Please report issues to{" "}
                        <a tabIndex="-1" href="mailto:support@simulationpl.com">
                          support@simulationpl.com
                        </a>
                        .
                      </strong>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col lg="6">
              <Card>
                <CardBody style={{ overflow: "hidden" }}>
                  {this.state.downForMaintenance && (
                    <h4>
                      <p>
                        Simproject is undergoing an upgrade and will not be
                        available from April 3, 2021 at 12:00 pm EDT until April
                        5, 2021 at 8:00 pm EDT.{" "}
                      </p>{" "}
                      <p>Thank you for your understanding.</p>
                    </h4>
                  )}
                  {!this.state.downForMaintenance && (
                    <span>
                      <h1>Login</h1>
                      <p className="text-muted">Sign in to your account</p>

                      <CardBody
                        className="card-body"
                        style={{ overflow: "hidden" }}
                      >
                        <p style={{ color: "red" }}>{this.state.loginError}</p>
                        <Form>
                          <InputGroup className="mb-3 input-group">
                            <InputGroupAddon
                              addonType="prepend"
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fa fa-user" />
                            </InputGroupAddon>
                            <Input
                              type="text"
                              name="username"
                              placeholder="Username"
                              innerRef={(ef) => (this.usernameInput = ef)}
                              required
                              onKeyPress={this.onKeyPress}
                            />
                          </InputGroup>
                          <InputGroup className="mb-4 input-group">
                            <InputGroupAddon
                              addonType="prepend"
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fa fa-lock" />
                            </InputGroupAddon>

                            <Input
                              type={this.state.type}
                              placeholder="Password"
                              name="password"
                              className="password__input"
                              onKeyPress={this.onKeyPress}
                              innerRef={(pf) => (this.passwordInput = pf)}
                            />
                            <span
                              className="password__show"
                              onClick={this.showHide}
                            >
                              {this.state.type === "password" ? (
                                <i className="fa fa-eye" />
                              ) : (
                                <i className="fa fa-eye-slash" />
                              )}
                            </span>
                          </InputGroup>
                          <Row>
                            <Col>
                              <Button
                                color="primary"
                                className="btn btn-primary px-3 btn-submit"
                                onClick={() => this.loginUser()}
                                disabled={
                                  this.state.loading ||
                                  this.props.auth.isFetching
                                }
                              >
                                <span>Login</span>
                                {this.state.loading ||
                                this.props.auth.isFetching ? (
                                  <LoadingContainer>
                                    <FontAwesomeIcon
                                      style={{ color: "white" }}
                                      className="ml-1"
                                      spin
                                      icon={faSpinner}
                                    />{" "}
                                  </LoadingContainer>
                                ) : (
                                  <span />
                                )}
                              </Button>
                            </Col>
                            <Col className="text-right">
                              <Button
                                color="link"
                                className="px-0"
                                onClick={this.forgotPassword}
                              >
                                Forgot password?
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </span>
                  )}
                </CardBody>
                {/* {!this.state.downForMaintenance && (
                  <CardFooter>
                    <small className="d-flex justify-content-end align-items-center">
                      <strong>New User?</strong>
                      <NavLink
                        className="btn ml-2 btn-outline-primary"
                        style={{
                          padding: "0.25rem 0.5rem"
                        }}
                        to="/registration"
                        href="https://simulationpl.com/product/simproject-2"
                      >
                        Register
                      </NavLink>
                    </small>
                  </CardFooter>
                )} */}
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalContainer />
        <Modal
          isOpen={this.state.whatsNewModal}
          toggle={() =>
            this.setState({ whatsNewModal: !this.state.whatsNewModal })
          }
          size="lg"
        >
          <ModalHeader
            cssModule={{ "modal-title": "w-100 text-left" }}
            toggle={() =>
              this.setState({ whatsNewModal: !this.state.whatsNewModal })
            }
          >
            What's new in SimProject 
          </ModalHeader>
          <ModalBody>
            <div>
              <p>
                This is a minor upgrade which includes bug fixes.
              </p>
             
              <h4>Bug fixes</h4>
              <ul>
                <li>
                Resource Plan budget issue : due to keyboard navigation issues, the resource plan budget would sometimes get out of sync.  To make sure the budget stays in sync, keyboard navigation in the resource plan has been disabled for now.  Click to update still works.
                </li>

                <li>
                  Training Plan updating message has been fixed to disappear after updates.
                </li>

                <li>The checklist for planning and execution has been fixed to allow checking off items on the list.</li>

          
               
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() =>
                this.setState({ whatsNewModal: !this.state.whatsNewModal })
              }
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  /** DEFINE A FUNCTION TO CALL THE DISPATCH  DEFINED IN THE
   MAPDISPATCHTOPROPS for the opoenModal function below * */
  forgotPassword = () => this.props.forgotPassword();
  showNotificationModal = (msg) => {
    this.props.showNotification(msg);
  };
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
  notification: state.notification
});

/** DEFINE THE DISPATCH OPEN MODAL TO OPEN YOUR NEW MODAL * */
const mapDispatchToProps = (dispatch) => ({
  showNotification: (msg) =>
    dispatch(openModal(NOTIFICATION_MODAL, msg, "modal-medium")),
  forgotPassword: () =>
    dispatch(openModal(FORGOT_PASSWORD_MODAL, {}, "modal-medium")),
  resetPassword: () =>
    dispatch(openModal(RESET_PASSWORD_MODAL, {}, "modal-medium")),
  authenticate: (username, password) =>
    dispatch(authenticate(username, password)),
  login: () => dispatch(login()),
  getUser: (username) => dispatch(getUser(username)),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  dismissAlert: (alert) => dispatch(dismissAlert(alert)),
  dismissAlertById: (id) => dispatch(dismissAlertById(id)),
  clearAlerts: () => dispatch(clearAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
