import React from "react";

const ApprovalTooltip = () => (
    <div style={{textAlign: "left"}}>
      Have you:
      <ul>
        <li>Executed the “Plan Run Check”?</li>
        <li>Reviewed the results in the “Check Log” and addressed any feedback?</li>
        <li>Requested and received Budget Approval?</li>

      </ul>
    </div>
  )

export default ApprovalTooltip
