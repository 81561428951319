import { createAction } from "redux-actions";

import {
  TOGGLE_INFO_PANEL,
  TOGGLE_BUDGET_PANEL,
  TOGGLE_RECOGNITION_INFO,
  TOGGLE_RECOGNITION_BUDGET,
  TOGGLE_RISK_INFO,
  TOGGLE_RISK_BUDGET,
  TOGGLE_RESOURCE_INFO,
  TOGGLE_RESOURCE_PLAN,
  TOGGLE_RESOURCE_BUDGET,
  TOGGLE_STAKEHOLDER_OPTIONS,
  TOGGLE_STAKEHOLDER_INFO,
  TOGGLE_STAKEHOLDER_BUDGET,
  TOGGLE_TRAINING_INFO,
  TOGGLE_TRAINING_BUDGET,
  TOGGLE_WORKWEEK_INFO,
  TOGGLE_STAFFING_INFO,
  TOGGLE_INTERACTION_INFO,
  TOGGLE_REPORT_INFO,
  TOGGLE_TOUR_OPEN,
  DATA_LOADING
} from "./types";

// ------------------------------------
// Actions
// ------------------------------------
export const toggleLoading = createAction(DATA_LOADING, (payload) => payload);
export const toggleRecognitionInfo = createAction(
  TOGGLE_RECOGNITION_INFO,
  (payload) => payload
);
export const toggleRecognitionBudget = createAction(
  TOGGLE_RECOGNITION_BUDGET,
  (payload) => payload
);
export const toggleRiskBudget = createAction(
  TOGGLE_RISK_BUDGET,
  (payload) => payload
);
export const toggleResourceInfo = createAction(
  TOGGLE_RESOURCE_INFO,
  (payload) => payload
);
export const toggleResourcePlan = createAction(
  TOGGLE_RESOURCE_PLAN,
  (payload) => payload
);
export const toggleResourceBudget = createAction(
  TOGGLE_RESOURCE_BUDGET,
  (payload) => payload
);
export const toggleRiskInfo = createAction(
  TOGGLE_RISK_INFO,
  (payload) => payload
);
export const riskBudgetPanel = createAction(
  TOGGLE_RISK_BUDGET,
  (payload) => payload
);
export const toggleStakeholderInfo = createAction(
  TOGGLE_STAKEHOLDER_INFO,
  (payload) => payload
);
export const toggleStakeholderOptions = createAction(
  TOGGLE_STAKEHOLDER_OPTIONS,
  (payload) => payload
);
export const toggleStakeholderBudget = createAction(
  TOGGLE_STAKEHOLDER_BUDGET,
  (payload) => payload
);
export const toggleTrainingInfo = createAction(
  TOGGLE_TRAINING_INFO,
  (payload) => payload
);
export const toggleTrainingBudget = createAction(
  TOGGLE_TRAINING_BUDGET,
  (payload) => payload
);
export const toggleWorkWeekInfo = createAction(
  TOGGLE_WORKWEEK_INFO,
  (payload) => payload
);
export const toggleStaffingInfo = createAction(
  TOGGLE_STAFFING_INFO,
  (payload) => payload
);
export const toggleInteractionInfo = createAction(
  TOGGLE_INTERACTION_INFO,
  (payload) => payload
);
export const toggleReportInfo = createAction(
  TOGGLE_REPORT_INFO,
  (payload) => payload
);

export const infoPanel = createAction(TOGGLE_INFO_PANEL, (payload) => payload);

export const budgetPanel = createAction(
  TOGGLE_BUDGET_PANEL,
  (payload) => payload
);

export const toggleTourOpen = createAction(
  TOGGLE_TOUR_OPEN,
  (payload) => payload
);
