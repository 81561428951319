import React from "react";
import accounting from "accounting";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import { Card } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%s%v"
};

const TaskSummaryTable = (props) => {
  const footer = () => {
    const footers = [];
    const totalCosts = props.data.reduce(function (a, b) {
      return a + b["total"];
    }, 0);
    const totalHours = props.data.reduce(function (a, b) {
      return a + (b["reg"]);
    }, 0);
    const regHours = props.data.reduce(function (a, b) {
      return a + b["reg"];
    }, 0);

    const otHours = props.data.reduce(function (a, b) {
      return a + b["ot"];
    }, 0);

    footers.push(
      <td className="text-right" key="wsfooter1">
        <strong>Totals:</strong>
      </td>
    );
    footers.push(
      <td key="wsfooter2">
        <span />
      </td>
    ); // hourly rate - no summary

    footers.push(
      <td className="text-right" key="wshoursfooter">
        <strong>{totalHours }</strong>
      </td>
    );
    footers.push(
      <td className="text-right" key="wscostsfooter">
        <strong>{accounting.formatMoney(totalCosts)}</strong>
      </td>
    );
    return footers;
  };
  let iterKey = 1;
  return (
    <Card>
      <CardHeaderBold>By Task Labor Hours/Costs</CardHeaderBold>
      <NoMaxCardBody>
        <StyledTable bordered hover>
          <thead>
            <tr>
              <th className="text-center">Task Id</th>
              <th className="text-center">Task Name</th>
              <th className="text-center">Total Hours</th>
              <th className="text-center">Total Labor Cost</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((row) => (
              <tr key={(iterKey += 1)}>
                <td className="text-center">{row.task}</td>
                <td>{row.taskName}</td>
                <td className="text-right">{row.reg}</td>
                <td className="text-right" style={{ marginRight: "10px" }}>
                  {accounting.formatMoney(row.total, options)}
                </td>
              </tr>
            ))}
          </tbody>
            <tfoot>
            <tr>{footer()}</tr>
          </tfoot>
        </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default TaskSummaryTable;
