import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import imageURLs from "./images/imageURLs.js";

const BudgetApprovalHelp = () => (
  <div>
    <h3>Planning Your Project –Budget Approval</h3>
    <h4>Context and Resources for Budget Approval:</h4>
    <p>It is time to ask management to approve your plan, and specifically your budget. It is a good idea to check your planned budget numbers on your own first via the Full Budget panel. Look for Planned Budget items that are either still $0 or items where there is a big variance between your planned amount and the approved amount. Make any necessary changes to the respective plans and come back to Approval.</p>
<h4>Submitting your Budget for Approval:
</h4>



    <p>Click on the Run Plan Check button </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

      src={imageURLs.RunPlanCheckButton}
      alt="plan"
    />

  <p>  Your planned budget amounts will be checked against the approved budget amounts and a report will be generated:</p>

  <img
    style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

    src={imageURLs.RunPlanCheckResults}
    alt="plan"
  />

<p>Review the report and address any problems or warnings by editing the respective plan.  Once you review and/or update your plans you can “Run Plan Check” again. </p>


<Row>
  <Col lg="8">
    When your plan has either passed the checks or passed with warnings you are willing to accept, click on the “Request Budget Approval” button
  </Col>
  <Col lg="4">
    <img
      style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "5px", marginBottom: "5px"}}

      src={imageURLs.RequestBudgetApprovalButton}
      alt="plan"
    />
  </Col>
</Row>


    <h4>Execution and your plan:</h4>
    <p>
      When you start executing the project all budget amounts will be locked but you will still be able to update your plans to track actual progress. It is good Project Management practice to keep your plans up to date as the project progresses, even if the planned budget is locked.

    </p>
  </div>
);

export default BudgetApprovalHelp;
