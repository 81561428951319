import React from "react";
import { connect } from "react-redux";
import {
  insertStakeholder,
  updateStakeholder,
  insertAllStatusReports,
  fetchWeeklyInteractions
} from "redux/Interaction/thunks";
import {
  getOnProjectWorkers,
  getStakeholderListPlusAll
} from "redux/Project/selectors";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { closeModal } from "redux/Modal/ModalModule";
import { shouldAcceptStakeholderEvent } from "utilities/InteractionChecks";
import { addSingleAlert } from "redux/Notification/NotificationModule";

class StakeholderInteraction extends React.Component {
  state = {
    workerId: this.props.onProjectWorkers[0].id,
    isTraining: this.props.isTraining,
    selectedStakeholderIndex: 0,
    currentStakeholderItem: this.props.stakeholderItem,
    workerList: [...this.props.onProjectWorkers],
    stakeholderList: [],
    firstActionId: 0,
    actionList:[],
    availableActions: [
      { id: 0, description: "None" },
      { id: 1, description: "Meeting" },
      { id: 2, description: "Status Report" },
      { id: 3, description: "Visit" },
      { id: 4, description: "Education" },
      { id: 5, description: "Survey" }
    ],
    showWorkerControl: false,
    saveButtonDisabled: false // if user clicks checkmark, don't need to save
  };

  componentDidMount() {
  //  var showControl;
  //     if (
  //     Object.prototype.hasOwnProperty.call(
  //       this.props.stakeholderItem,
  //       "WorkerName"
  //     )
  //   ) {
  //     if (
  //       this.props.stakeholderItem.WorkerName !== null &&
  //       this.props.stakeholderItem.WorkerName !== ""
  //     ) {
  //       showControl = true;
  //     } 
  //   }
    this.setState({
      selectedStakeholderIndex: [...this.getStakeholderList()].id,
      stakeholderList: [...this.getStakeholderList()],
      firstActionId: [...this.getActionList()][0].interactionId,
        actionList: [...this.getActionList()]
    })
  }
  handleTrainingChange = event => {
    event.preventDefault();

    const newClassId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentStakeholderItem);
    item.CourseId = newClassId;
    item.InteractionId = 4; // education
    this.setState({
      currentStakeholderItem: item
    });
  };

  handleActionChange = event => {
    event.preventDefault();
    this.setState({ showWorkerControl: false });
    // when the action changes, need to also filter the stakeholders
    const actionId = parseInt(event.target.value, 10);
    const action = this.state.availableActions.find(a => a.id === actionId);
    let stakeholderIds = [];

    // for (let i = 0; i < this.props.stakeholderActionList.length; i += 1) {
    //   if (this.props.stakeholderActionList[i].description === action.description) {
    //     stakeholderIds.push(this.props.stakeholderActionList[i].stakeholderId)
    //   }
    // }
    stakeholderIds = this.props.stakeholderActionList
      .filter(s => s.description === action.description)
      .map(y => y.stakeholderId);

    const newStakeholderList = this.props.stakeholderListPlusAll.filter(
      s => stakeholderIds.indexOf(s.id) > -1
    );

    this.setState({
      stakeholderList: [...newStakeholderList]
    });

    // get interaction id
    const newInteractionId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentStakeholderItem);
    item.InteractionId = newInteractionId;

    // get interaction description

    const sts = this.props.stakeholderActionList.filter(
      st => st.interactionId === newInteractionId
    );
    if (sts !== undefined && sts.length > 0) {
      item.Interaction = sts[0].description;
      item.InteractionDesc = sts[0].description;
      item.InteractionId = newInteractionId;
    }

    // filter to show workers if interaction requires
    const data = event.target.options[event.target.selectedIndex].dataset;
    const showWorker = data.requiresworker === "true";
    if (data.requiresworker === "false") {
      item.WorkerId = 0;
      item.WorkerName = "";
    } else {
      item.WorkerId = this.props.onProjectWorkers[0].id;
      item.WorkerName = this.props.onProjectWorkers[0].name;
    }
    this.setState({
      currentStakeholderItem: item,
      showWorkerControl: showWorker
    });
  };

  getStakeholderList = () => {
    let stakeholderList = [];

    if (this.props.isTraining === true) {
      stakeholderList = this.props.stakeholderListPlusAll.filter(
        s => s.id === 1 || s.id === 4
      );
    } else {
      stakeholderList = this.props.stakeholderListPlusAll;
    }
    return stakeholderList;
  };

  getActionList = () => {
    let actionList = [];
    let stakeholderId = 0;
    if (this.props.isAdd === false) {
      stakeholderId = this.props.stakeholderItem.StakeholderId;
    } else {
      stakeholderId = this.props.stakeholderListPlusAll[0].id;
    }
    if (stakeholderId === 0) {
      const tmpList = this.props.stakeholderActionList.filter(
        action => action.description === "Status Report"
      );
      actionList.push(tmpList[0]);
    } else {
      actionList = this.props.stakeholderActionList.filter(action =>
        action.description !== "None" && action.description !== "Education"
          ? action.stakeholderId === stakeholderId
          : ""
      );
    }

    return actionList;
  };

  handleWorkerChange = event => {
    event.preventDefault();
    const newWorkerId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentStakeholderItem);
    item.WorkerId = newWorkerId;
    const wrkr = this.props.onProjectWorkers.find(w => w.id === newWorkerId);
    item.WorkerName = wrkr.name;
    this.setState({
      currentStakeholderItem: item,
      workerId: newWorkerId
    });
  };

  handleGiveAllStakeholdersAStatusReport = event => {
    //    event.preventDefault();
    //
    this.setState({ saveButtonDisabled: true });
    if (event.target.checked) {
      this.props.insertAllStatusReports(
        this.props.runId,
        this.props.selectedDay
      );
    }
    //
  };
  handleStakeholderChange = event => {
    event.preventDefault();
    let actionList = [];
    const stakeholderId = parseInt(event.target.value, 10);
    if (stakeholderId === 0) {
      const tmpList = this.props.stakeholderActionList.filter(
        action => action.description === "Status Report"
      );
      actionList.push(tmpList[0]);
    } else {
      actionList = this.props.stakeholderActionList.filter(action =>
        action.description !== "None" && action.description !== "Education"
          ? action.stakeholderId === stakeholderId
          : ""
      );
    }
    const ind = this.props.stakeholderList.findIndex(
      sh => sh.id === stakeholderId
    );
    const item = Object.assign({}, this.state.currentStakeholderItem);

    item.StakeholderId = stakeholderId;
    this.setState({
      currentStakeholderItem: item,
      selectedStakeholderIndex: ind,
      actionList
    });
  };

  saveStakeholder = event => {
    event.preventDefault();
    let result = {};
    const si = Object.assign({}, this.state.currentStakeholderItem);
    if (si.Interaction === undefined) {
      // temp fix because if changing between
      // certain interactions and/or stakeholders,
      // this field is may not be used
      si.Interaction = si.InteractionDesc;
    } else if (si.InteractionDesc === undefined) {
      si.InteractionDesc = si.Interaction;
    }

    si.StakeholderDesc = this.state.stakeholderList.find(
      s => s.id === si.StakeholderId
    ).stakeholder;

    result = shouldAcceptStakeholderEvent(
      si,
      this.props.onProjectWorkers.length,
      this.props.interactionList
    );

    if (result.status === "ok") {
      if (si.StakeholderId === 0) {
        // give all stakeholders a status report
        this.props.insertAllStatusReports(
          this.props.runId,
          this.props.selectedDay
        );
        const alert = {
          id: 12,
          type: "success",
          headline: "Stakeholder Interaction Scheduled",
          event: "interaction",
          message:
            "All Stakeholders have been scheduled to receive a status report."
        };
        const notificationData = {
          info:
            "All Stakeholders have been scheduled to receive a status report.",
          worker: si.StakeholderDesc,
          week: this.props.displayProjectWeek
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else if (this.props.isAdd) {
        Promise.all([this.props.insertStakeholder(si)]).then(() => {
          let msg = "";
          if (this.props.isTraining) {
            const course = this.props.stakeholderCourseList.find(
              c => c.id === si.CourseId
            ).description;
            msg = `${si.StakeholderDesc} scheduled for ${course} class.`;
          } else {
            msg = `${si.Interaction} scheduled for ${si.StakeholderDesc}`;
          }
          const alert = {
            id: 12,
            type: "success",
            headline: "Stakeholder Interaction Scheduled",
            event: "interaction",
            message: msg
          };
          const notificationData = {
            info: msg,
            worker: si.StakeholderDesc,
            week: this.props.displayProjectWeek
          };
          this.props.addAlert(alert, notificationData, 3000, true);
        });
        // if (this.props.isTraining) {
        //   let m
        // }
      } else {
        if (si.Interaction === undefined) {
          // temp fix because if changing between
          // certain interactions and/or stakeholders,
          // this field is may not be used
          si.Interaction = si.InteractionDesc;
        }
        Promise.all([this.props.updateStakeholder(si)]).then(() => {
          let msg = "";
          if (this.props.isTraining) {
            const course = this.props.stakeholderCourseList.find(
              c => c.id === si.CourseId
            ).description;
            msg = `${si.StakeholderDesc} scheduled for ${course} class.`;
          } else {
            msg = `${si.InteractionDesc} scheduled for ${si.StakeholderDesc}`;
          }
          const alert = {
            id: 12,
            type: "success",
            headline: "Stakeholder Interaction Updated",
            event: "interaction",
            message: msg
          };
          const notificationData = {
            info: msg,
            worker: si.StakeholderDesc,
            week: this.props.displayProjectWeek
          };
          this.props.addAlert(alert, notificationData, 3000, true);
        });
      }
      this.props.fetchWeeklyInteractions(
        this.props.runId,
        this.props.productId
      );
    } else if (result.status !== "empty") {
      const alert = {
        id: 15,
        type: "danger",
        headline: "Stakeholder Conflict",
        event: "interaction scheduling",
        message: result.message
      };
      this.props.addAlert(alert, {}, 3000, false);
    }
  };

  render() {
    let iterKey = 1;

    return (
      <div>
        <Form className="form-split">
          <FormGroup>
            <Label for="stakeholder">Choose Stakeholder</Label>
            <Input
              placeholder="stakeholder"
              type="select"
              value={this.state.currentStakeholderItem.StakeholderId}
              onChange={(event) => this.handleStakeholderChange(event)}
            >
              {this.state.stakeholderList.map((row, index) => (
                <option key={(iterKey += 1)} value={row.id}>
                  {row.stakeholder}
                </option>
              ))}
            </Input>
          </FormGroup>
          {this.props.isTraining === true ? (
            <FormGroup>
              <Label for="stakeholder">Choose Training</Label>
              <Input
                id="stakeholder"
                type="select"
                placeholder="recognition"
                value={this.state.currentStakeholderItem.ClassId}
                onChange={(event) => this.handleTrainingChange(event)}
              >
                {this.props.stakeholderCourseList.map((row, index) => (
                  <option key={(iterKey += 1)} value={row.id}>
                    {row.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label for="stakeholder">Choose Action</Label>
              <Input
                  id="action"
                  type="select"
                placeholder="action"
                value={this.state.currentStakeholderItem.InteractionId}
                onChange={(event) => this.handleActionChange(event)}
              >
                {this.state.actionList.map((row, index) => (
                  <option
                    key={(iterKey += 1)}
                    value={row.interactionId}
                    data-requiresworker={row.requiresWorker}
                  >
                    {row.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          {this.state.showWorkerControl === true ? (
            <FormGroup>
              <Label for="stakeholder">Choose Participating Worker</Label>
              <Input
                id="worker"
                type="select"
                placeholder="worker"
                value={this.state.currentStakeholderItem.WorkerId}
                onChange={(event) => this.handleWorkerChange(event)}
              >
                {this.props.onProjectWorkers.map((row, index) => (
                  <option key={(iterKey += 1)} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ) : (
            " "
          )}
          <footer>
            <button
              className="btn btn-primary"
              type="button"
              disabled={this.state.saveButtonDisabled}
              onClick={(event) => this.saveStakeholder(event)}
            >
              Save
            </button>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stakeholderActionList: state.project.stakeholderActionList,
  stakeholderCourseList: state.project.stakeholderCourseList,
  stakeholderList: state.project.stakeholderList,
  stakeholderListPlusAll: getStakeholderListPlusAll(state),
  onProjectWorkers: getOnProjectWorkers(state),
  days: state.project.daysOfWeek,
  runId: state.project.runId,
  productId: state.auth.studentInfo.ProductId,
  displayProjectWeek: state.project.displayProjectWeek,
  interactionList: state.execution.interactions
});

const mapDispatchToProps = dispatch => ({
  insertStakeholder: stakeholderItem =>
    dispatch(insertStakeholder(stakeholderItem)),
  fetchWeeklyInteractions: (runId, productId) =>
    dispatch(fetchWeeklyInteractions(runId, productId)),
  insertAllStatusReports: (runId, weekDay) =>
    dispatch(insertAllStatusReports(runId, weekDay)),
  updateStakeholder: stakeholderItem =>
    dispatch(updateStakeholder(stakeholderItem)),
  dismiss: () => dispatch(closeModal()),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export const StakeholderInteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StakeholderInteraction);
