/* eslint-disable no-return-assign */
import React from "react";
import { connect } from "react-redux";
import { insertTraining, updateTraining2 } from "redux/Interaction/thunks";
import { getOnProjectWorkers } from "redux/Project/selectors";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { closeModal } from "redux/Modal/ModalModule";
import {
  shouldAcceptIndvTraining,
  shouldAcceptTeamTraining
} from "utilities/InteractionChecks";
import { addSingleAlert } from "redux/Notification/NotificationModule";

class TrainingInteraction extends React.Component {
  state = {
      currentTrainingItem: this.props.trainingItem,
      workerList: [...this.props.onProjectWorkers]
    };


  handleTrainingChange = event => {
    event.preventDefault();

    const newClassId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentTrainingItem);
    item.ClassId = newClassId;
    item.ClassName = this.props.trainingList.find(
      t => t.ClassId === newClassId
    ).ClassName;
    item.ClassStartDay = this.props.trainingList.find(
      t => t.ClassId === newClassId
    ).ClassStartDay;
    const data = event.target.options[event.target.selectedIndex].dataset;
    let workerList = [];
    if (data.trainingtype === "1") {
      workerList.push({
        id: 0,
        name: "TEAM"
      });
      item.WorkerName = "The Team";
    } else {
      workerList = [...this.props.onProjectWorkers];
    }
    this.setState({
      currentTrainingItem: item,
      workerList
    });
  };

  handleWorkerChange = event => {
    event.preventDefault();

    const newWorkerId = parseInt(event.target.value, 10);
    const item = Object.assign({}, this.state.currentTrainingItem);
    item.WorkerId = newWorkerId;

    item.WorkerName = this.props.onProjectWorkers.find(
      w => w.id === newWorkerId
    ).name;
    this.setState({
      currentTrainingItem: item
    });
  };

  saveTraining = event => {
    event.preventDefault();

    let result = {};
    if (this.props.isAdd) {
      const ti = Object.assign({}, this.state.currentTrainingItem);
      ti.RunId = this.props.runId;

      this.setState({
        currentTrainingItem: ti
      });
    }

    if (this.state.currentTrainingItem.ClassId !== 8) {
      // / individual

      result = shouldAcceptIndvTraining(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        this.state.currentTrainingItem
      );
    } else {
      // team
      result = shouldAcceptTeamTraining(
        this.props.interactionList,
        this.props.onProjectWorkers.length,
        this.state.currentTrainingItem,
      );
    }
    if (result.status === "ok") {
      const notificationData = {
        info: `${this.state.currentTrainingItem.WorkerName} is scheduled for ${this.state.currentTrainingItem.ClassName}`,
        worker: this.state.currentTrainingItem.WorkerName,
        week: this.props.displayProjectWeek
      };

      if (this.props.isAdd) {
        this.props.insertTraining(this.state.currentTrainingItem);
        const alert = {
          id: 13,
          type: "success",
          headline: "Training Interaction Scheduled",
          event: "interaction",
          message: `${
            this.state.currentTrainingItem.requiresTeam
              ? "The team"
              : this.state.currentTrainingItem.WorkerName
          } is scheduled for ${this.state.currentTrainingItem.ClassName}`
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      } else {
        this.props.updateTraining(this.state.currentTrainingItem);
        const alert = {
          id: 13,
          type: "success",
          headline: "Training Interaction Scheduled",
          event: "interaction",
          message: `${
            this.state.currentTrainingItem.requiresTeam
              ? "The team"
              : this.state.currentTrainingItem.WorkerName
          } is scheduled for ${this.state.currentTrainingItem.ClassName}`
        };
        this.props.addAlert(alert, notificationData, 3000, true);
      }
    } else if (result.status !== "empty") {
      const alert = {
        id: 15,
        type: "danger",
        headline: "Training Conflict",
        event: "interaction scheduling",
        message: result.message
      };
      this.props.addAlert(alert, {}, 3000, false);
    }

    // Close the modal on save
    //  this.props.dismiss();
  };

  render() {
    let iterKey = 1;
    return (
      <div>
        <Form className="form-split">
          <FormGroup>
            <Label for="trainingSelect">Choose Training</Label>
            <Input
              id="trainingSelect"
              type="select"
              placeholder="training"
              value={this.state.currentTrainingItem.ClassId}
              onChange={(event) => this.handleTrainingChange(event)}
            >
              {this.props.trainingList.map((row, index) => (
                <option
                  key={(iterKey += 1)}
                  data-trainingtype={row.TrainingType}
                  value={row.ClassId}
                >
                  {row.ClassName}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="workerSelect">Choose Worker</Label>
            <Input
              id="workerSelect"
              placeholder="worker"
              type="select"
              value={this.state.currentTrainingItem.WorkerId}
              onChange={(event) => this.handleWorkerChange(event)}
            >
              {this.state.workerList.map((row, index) => (
                <option key={(iterKey += 1)} value={row.id}>
                  {row.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <footer>
            <Button
              color="primary"
              className="btn btn-primary"
              type="button"
              disabled={
                this.props.onProjectWorkers[0].name ===
                "There are no active workers on project"
              }
              onClick={(event) => this.saveTraining(event)}
            >
              Save
            </Button>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  trainingList: state.project.trainingList,
  onProjectWorkers: getOnProjectWorkers(state),
  runId: state.project.runId,
  interactionList: state.execution.interactions,
  displayProjectWeek: state.project.displayProjectWeek
});

const mapDispatchToProps = dispatch => ({
  insertTraining: trainingItem => dispatch(insertTraining(trainingItem)),
  updateTraining: trainingItem => dispatch(updateTraining2(trainingItem)),
  dismiss: () => dispatch(closeModal()),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export const TrainingInteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingInteraction);
