import React from "react";

type Props = {
  properties: Object
};

export const TaskWillBeDelayed = (props: Props) => {
  return (
    <div className="content">
      <h3>Task will be delayed</h3>
      <p>{props.properties.reason}</p>
    </div>
  );
};
