import React from "react";

const IdleWorker = (props) => {
  return (
    <div className="content">
        {props.properties.worker}'s assigned task is dependent on a predecessor task finishing (Network Diagram), resulting in no work accomplished and charging labor costs to Reserves.
    </div>
  );
};

export default IdleWorker
