import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getQualityReport,
  getEACReport,
  getEVReport,
  getEPFReport,
  getPPIReport
} from "redux/Report/selectors";

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import QualityChart from "routes/Report/components/Charts/QualityChart";
import EACChart from "routes/Report/components/Charts/EACChart";
import EVChart from "routes/Report/components/Charts/EVChart";
import EPFChart from "routes/Report/components/Charts/EPFChart";
import PPIChart from "routes/Report/components/Charts/PPIChart";

const ChartContainer = () => {
  const content = useSelector((state) => state); //this hook gives us redux store state

  const [activeTab, setActiveTab] = useState("quality");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "quality"
                })}
                onClick={() => {
                  toggle("quality");
                }}
              >
                Quality
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "eac"
                })}
                onClick={() => {
                  toggle("eac");
                }}
              >
                Estimated Cost at Completion
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "ev"
                })}
                onClick={() => {
                  toggle("ev");
                }}
              >
                Earned Value
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "epf"
                })}
                onClick={() => {
                  toggle("epf");
                }}
              >
                Estimated Project Finish
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "ppi"
                })}
                onClick={() => {
                  toggle("ppi");
                }}
              >
                PPI
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}
            id={`tab-${content && content.project && content.project.projectStatus && content.project.projectStatus.WeekHasCompleted ? content.project.projectStatus.WeekHasCompleted.toString() : "false"}`}>
            <TabPane tabId="quality">
              <QualityChart data={getQualityReport(content)} />
            </TabPane>
            <TabPane tabId="eac">
              <EACChart noHideToggle data={getEACReport(content)} />
            </TabPane>
            <TabPane tabId="ev">
              <EVChart data={getEVReport(content)} />
            </TabPane>
            <TabPane tabId="epf">
              <EPFChart data={getEPFReport(content)} />
            </TabPane>
            <TabPane tabId="ppi">
              <PPIChart data={getPPIReport(content)} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default ChartContainer;
