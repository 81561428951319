import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  fetchStudents,
  fetchClassesByInstructor,
  fetchInstructors,
  fetchStudentInfo,
  fetchClassRuns,
  updateClassName,
  updateClassStatus,
  updateClassRuns,
  setSelectedClass,
  updateAllowToPlan,
  updateAllowToRun,
  updateStopWeek,
  updateStopMessage
} from "redux/Instructor/thunks";
import { createClassroom } from "redux/Classroom/thunks"
import { getUniqueInstructors } from "redux/Authentication/selectors";
import ExecutionsTable from "./components/ExecutionsTable";

class ControlRunContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardInfoOpen: true
    }
  }

  componentDidMount() {
    this.props.fetchInstructors();
  }

  toggleInfo() {
    this.setState({ cardInfoOpen: !this.state.cardInfoOpen });
  }

  fetchStudentsInClass = classId => {
    this.props.fetchStudents(classId);
  };

  setSelectedClass = (classroom) => {
    this.props.setSelectedClass(classroom);
  }

  render() {
    return (
      <Fragment>
        <ExecutionsTable
          classRuns={this.props.classRuns}
          instructorClasses={this.props.instructorClasses}
          updateClassRuns={this.props.updateClassRuns}
          updateAllowToPlan={this.props.updateAllowToPlan}
          updateAllowToRun={this.props.updateAllowToRun}
          updateStopWeek={this.props.updateStopWeek}
          updateStopMessage={this.props.updateStopMessage}
          classSelectorOpen={this.props.classSelectorOpen}
          activeTab={this.props.activeTab}
        />

      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  userId: state.auth.userId,
  studentList: state.instructor.studentList,
  instructorClasses: state.instructor.instructorClasses,
  uniqueInstructors: getUniqueInstructors(state),
  classRuns: state.instructor.classRuns,
  selectedClass: state.instructor.selectedClass,
  instructors: state.instructor.instructors
});

const mapDispatchToProps = dispatch => ({
  fetchStudents: classroomId => dispatch(fetchStudents(classroomId)),
  fetchClassesByInstructor: instructorId =>
    dispatch(fetchClassesByInstructor(instructorId)),
  fetchInstructors: () => dispatch(fetchInstructors()),
  fetchStudentInfo: studentData => dispatch(fetchStudentInfo(studentData)),
  fetchClassRuns: cId => dispatch(fetchClassRuns(cId)),
  updateClassName: (cId, cName) => dispatch(updateClassName(cId, cName)),
  updateClassStatus: (cId, cStatus) =>
    dispatch(updateClassStatus(cId, cStatus)),
  setSelectedClass: classroom => dispatch(setSelectedClass(classroom)),
  updateClassRuns: cRuns => dispatch(updateClassRuns(cRuns)),
  updateAllowToPlan: (id, canPlan) => dispatch(updateAllowToPlan(id, canPlan)),
  updateAllowToRun: (id, canRun) => dispatch(updateAllowToRun(id, canRun)),
  updateStopWeek: (id, stopWeek) => dispatch(updateStopWeek(id, stopWeek)),
  updateStopMessage: (id, stopMessage) => dispatch(updateStopMessage(id, stopMessage)),
  createClassroom: (payload) => dispatch(createClassroom(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ControlRunContainer
);