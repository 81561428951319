// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_TRAININGS = "REQUEST_TRAININGS";
export const RECEIVE_TRAININGS = "RECEIVE_TRAININGS";
export const DELETE_TRAININGPLAN_REQUEST = "DELETE_TRAININGPLAN_REQUEST";
export const DELETE_TRAININGPLAN_SUCCESS = "DELETE_TRAININGPLAN_SUCCESS";
export const DELETE_TRAININGPLAN_ERROR = "DELETE_TRAININGPLAN_ERROR";
export const SAVE_TRAININGPLAN_REQUEST = "SAVE_TRAININGPLAN_REQUEST";
export const SAVE_TRAININGPLAN_SUCCESS = "SAVE_TRAININGPLAN_SUCCESS";
export const SAVE_TRAININGPLAN_ERROR = "SAVE_TRAININGPLAN_ERROR";
export const REQUEST_ALL_WORKERS = "REQUEST_ALL_WORKERS";
export const RECEIVE_ALL_WORKERS = "RECEIVE_ALL_WORKERS";
export const REQUEST_ALL_TRAININGS = "REQUEST_ALL_TRAININGS";
export const RECEIVE_ALL_TRAININGS = "RECEIVE_ALL_TRAININGS";
export const REQUEST_MAX_WEEKS = "REQUEST_MAX_WEEKS";
export const RECEIVE_MAX_WEEKS = "RECEIVE_MAX_WEEKS";
export const TRAININGPLAN_ERROR = "TRAININGPLAN_ERROR";

