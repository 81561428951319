import React from "react";

type Props = {
  properties: Object
};

export const VoluntaryOvertime = (props: Props) => {
  return (
    <div className="content">
      <h5>
        {props.properties.worker} : {props.properties.title} voluntarily worked
        overtime.
      </h5>
    </div>
  );
};
