import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
// comment this next out to remove
// the redux action messages from the console.log
import createLogger from "redux-logger";
import reducer from "../rootReducer";

const CreateStore = (initialState = {}, history) => {
	// ======================================================
	// Middleware Configuration
	// ======================================================
	const middleware = [thunk, routerMiddleware(history)];
	// comment these next three lines out to remove
	// the redux action messages from the console.log
	if (process.env.NODE_ENV !== "production") {
	  middleware.push(createLogger());
	}
	// ======================================================
	// Store Enhancers
	// ======================================================
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
	const enhancers = [sentryReduxEnhancer];

	// ======================================================
	// Store Instantiation and HMR Setup
	// ======================================================
	const store = createStore(
		reducer(),
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);

	store.asyncReducers = {};

	// if (process.env.NODE_ENV !== 'production') {
	//  middleware.push(addLoggingToDispatch)
	// store.dispatch = addLoggingToDispatch(store)
	// }
	// middleware.push(addPromiseSupportToDispatch)
	// store.dispatch = addPromiseSupportToDispatch(store)

	// if (process.env.NODE_ENV !== "production") {
	//   if (module.hot) {
	//     module.hot.accept("../rootReducer", () => {
	//       store.replaceReducer(rootReducer);
	//     });
	//   }
	// }

	return store;
};

export default CreateStore
