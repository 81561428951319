/* eslint-disable max-len */
import React from "react";

export const Reserves = () => {
  return (
    <div id="Reserves" className="style-background-components">
      <header>
        <h2>Risks/Inefficiencies</h2>
      </header>
      <div>
        <h4>Establishing Project Risks/Inefficiencies</h4>
        <p>
          Project Risks/Inefficiencies are established through the accepted
          practice of identifying potential risks and opportunities on the
          project. Risks and opportunities are identified, probabilities
          estimated, and anticipated impact in dollars and weeks are also
          estimated. The factored cost (probability X cost impact) is added
          (risk) or subtracted (opportunity) to the project
          Risks/Inefficiencies.
        </p>
        <p>
          It is accepted practice to not only identify potential risks (known
          risks), but also to have one or more risk items to account for risks
          which will occur during the project but are not known (unknown risks)
          at project planning.
        </p>
        <p>
          Risk items will automatically be generated from the project resource
          plan for any resource planned to be on the project but not assigned to
          any task for a given week. The resource cost for that week becomes
          part of the project reserves.
        </p>
        <h4>Using Project Risks/Inefficiencies</h4>
        <p>
          If personnel are not assigned to a task starting the week, or assigned
          to a task that is not active (waiting for a predecessor task to
          complete) they will accomplish no work and their costs will be charged
          to the project reserve.
        </p>
        <p>
          During the project, costs associated with unanticipated events (risks)
          will be charged to the project reserve.
        </p>
      </div>
    </div>
  );
};
