import React from "react";
import styled from "styled-components"; 
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import { ResourceProfile } from "routes/Reference/components";
import scrollToComponent from "react-scroll-to-component";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
class ResourceProfileList extends React.Component
{
  componentDidMount ()
  {
    if (this.props.profileLists.length > 0) {
      const latest = this.props.profileLists.length - 1

      scrollToComponent(this[ `profile${ latest }` ], { align: "bottom", duration: 600 });
    }
  }
  render ()
  {
    let iterKey = 1;
    return (
      <Grid>
        { this.props.profileLists.map((row, index) => (
         
            <Card>
              <CardHeader>
                Profile
                <Button
                  outline
                  size='sm'
                  color='secondary'
                  onClick={ event => this.props.hideProfile(index, event) }
                >
                  <i className='fa fa-times ml-0' />
                </Button>
              </CardHeader>
              <CardBody>
                <ResourceProfile
                  // ref={ ref =>
                  // {
                  //   this[ `profile${ index }` ] = ref;
                  // } }
                  key={ (iterKey += 1) } worker={ row } />
              </CardBody>
            </Card>
      
        )) }
      </Grid>
    );
  }
}

export default ResourceProfileList;
