import React, { Component, Fragment } from "react";
// import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Badge,
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,

  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import styled from "styled-components";
import { SmallButton } from "components/styled/SmallButton";
import { CardHeader } from "components/styled/CardHeader";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StudentActionPopover from "./StudentActionPopover";

import ResetRun from "routes/Projects/components/ResetRun";
import BumpRun from "routes/Projects/components/BumpRun";
import CardHeaderBold from "components/styled/CardHeaderBold";


class StudentsTable extends Component {
  state = {
    dropdownOpen: false,
    studentData: [],
    instructorClassData: [], //this.props.instructorClasses,
    studentCount: 0,
    sortName: "lastName",
    sortOrder: "asc",
    rSelected: this.props.execSeqFilterVal,
    currentRow: {},
    modalOpen: false,
    modalMessage: "",
    modalAction: "",
    popoverOpen: false
  };
  componentDidMount() {
    this.execSeqRef = React.createRef();
    let selected = {};
    if (!this.props.selectedClass > 0) {
      selected = this.props.instructorClasses.filter(
        (c) => c.classroomId === this.props.selectedClassId
      )[0];
    } else if (this.props.instructorClasses.length > 0) {
      selected = this.props.instructorClasses[0];
    }
    let count = 0;
    if (this.props.students && this.props.students.length > 0) {
      count = this.props.students.reduce(
        function (values, v) {
          if (!values.set[v.studentId]) {
            values.set[v.studentId] = 1;
            values.count += 1;
          }
          return values;
        },
        { set: {}, count: 0 }
      ).count;
    }

    this.setState({
      count: count,
      // studentData: this.filterStudentList(this.props.execSeqFilterVal),
      studentData: this.props.students,
      instructorClassData: this.props.instructorClasses
    });
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.instructorModal, modalMessage: "" });
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  renderModalBody = () => {
    if (this.state.modalAction === "bumpRun") {
      return (
        <BumpRun key="bump-run" classStudentRunId={this.state.currentRow.id} runId={this.state.currentRow.RunId} />
      )
    } else if (this.state.modalAction === "resetRun") {
      return (
        <ResetRun key="reset-run" runId={this.state.currentRow.runId} finalWeek={20} />
      )
    } else if (this.state.modalAction === "unlockBudget") {
      // return <UnlockBudget
      // 	row={props.row.runId} />
      return <span>Unlock budget</span>

    }
  }

  renderModalHeader = () => {
    if (this.state.modalAction === "bumpRun") {
      return (
        <Row>
          <Col sm="8">Bump Run</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "resetRun") {
      return (
        <Row>
          <Col sm="8">Reset Run</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "unlockBudget") {
      return (
        <Row>
          <Col sm="8">Unlock budget</Col>
          <Col sm="4">
            <span>{this.statev.modalMessage}</span>
          </Col>
        </Row>
      );
    } else {
      return <div>unknown action</div>;
    }
  }




  menuFormatter = (cell, row) => {
    // if (Number.parseInt(row.classroomId, 10) === Number.parseInt(0, 10)) {
    // 	return <span />
    // }
    if (row.runId < 1) {
      return <span />
    }
    return (
      <StudentActionPopover
        row={row}
        id={row.id}
        productId={this.props.selectedClass.productId}
        toggleTab={this.props.toggleTab}
        classroomid={this.props.selectedClass.classroomid}
        placement={"left"}
      />
    );
  };

  refreshStudentList = () => {
    this.props.fetchStudents(this.props.selectedClass.classroomId)
  };

  filterStudentList = (execSeq) => {
    if (execSeq === 0) {
      return this.props.students.slice(0);
    }
    return this.props.students.filter((t) => t.runSequenceNumber === execSeq);
  };

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sortName,
      sortOrder
    });
  };

  onRadioBtnClick = (rSelected) => {
    this.props.setExecSeqFilterVal(rSelected).then(() => {
      this.setState({
        rSelected,
      });
    });
  };

  onRowClick = (row) => {
    this.setState({
      currentRow: row
    });
    this.props.fetchStudentInfo(row);
  };


  addBadge = (cell) => {
    let badgeColor;
    switch (cell) {
      case "Registered":
        badgeColor = "light";
        break;
      case "Planning":
        badgeColor = "info";
        break;
      case "Waiting":
        badgeColor = "warning";
        break;
      case "Complete":
        badgeColor = "success";
        break;
      default:
        badgeColor = "secondary";
    }

    return <Badge color={badgeColor}>{cell}</Badge>;
  };

  renderToggleHeader = () => {
    let h = "";
    if (
      this.state
      // this.state !== undefined &&
      // this.state !== null &&
      // {}.hasOwnProperty.call(this, 'state') &&
      // {}.hasOwnProperty.call(this.state, 'selectedClass') &&
      // {}.hasOwnProperty.call(this.state.selectedClass, 'ClassroomId')
    ) {
      h = `${this.props.selectedClass.ClassroomId.toString()} - ${this.props.selectedClass.className
        }`;
      return <h3>{h}</h3>;
    }
    return <span>Not Set</span>;
  };

  formatDate = (cell, row) => {
    if (row.runId === 0) {
      return "";
    }
    const dt = new Date(cell);
    if (moment(dt).format("DD-MMM-YY") === "01-Jan-01") {
      return "";
    }

    return <div class="tool-tip">{moment(dt).format("DD-MMM-YY HH:mm:ss")}
      <span class="tool-tiptext">{moment(dt).format("DD-MMM-YY HH:mm:ss")}</span>
    </div>;
  };

  formatStatus = (cell, row) => {
    if (row.runStatus === "Complete!") {
      return cell.replace("Run ", "");
    }
    if (row.runStatus !== "Waiting" && row.runPercentComplete < 100) {
      return cell.replace("Run ", "");
    }
    return "";
  };
  formatHideZero = (cell, row) => {
    if (row.runStatus === "Complete!") {
      return cell;
    }
    if (row.runStatus !== "Waiting" && row.runPercentComplete < 100) {
      return cell;
    }
    return "";
  };

  formatHide = (cell, row) => {
    if (row.runStatus === "Complete!") {
    let demo = "$" + cell
    return demo;
  }
  if (row.runStatus !== "Waiting" && row.runPercentComplete < 100) {
    let demo = "$" + cell
    return demo;
    
  }
  return "";
};

  formatName = (cell, row) => {
    return <div class="tool-tip">{row.firstName} {row.lastName}
      <span class="tool-tiptext">{row.firstName} {row.lastName}</span>
    </div>
  };

  addPercentage = (cell) => {
    let progressColor;
    if (cell === 0) {
      return <span></span>;
    }

    switch (true) {
      case cell >= 30 && cell <= 60:
        progressColor = "warning";
        break;
      case cell > 60 && cell <= 90:
        progressColor = "info";
        break;
      case cell > 90:
        progressColor = "success";
        break;
      default:
        progressColor = "secondary";
    }

    return (
      <div>
        <div className="clearfix">
          <div className="float-left">
            <small>
              <strong>{cell}%</strong>
            </small>
          </div>
          {/* <div className='float-right'>
            <small className='text-muted'>Jun 11, 2015 - Jul 10, 2015</small>
          </div> */}
        </div>
        <Progress className="progress-xs" color={progressColor} value={cell} />
      </div>
    );
  };

  createCustomToolBar = (props) => (
    <Col>

      <StyledTableHeaderRow>

        <Col sm="12" lg="6">

          <dl>
            Instructor:  <strong>{this.props.selectedClass.primaryInstructorName}</strong>
          </dl>
          <dl>Status: <strong>{this.props.selectedClass.classroomStatus}</strong>
          </dl>
          <dl>Current Student Count: <strong>{this.props.students.filter(s => s.runSequenceNumber === 1).length}</strong>
          </dl>
          <p>
            <span />
          </p>
          <dl > <span>Filter By Run Seq#: </span>

            <ButtonGroup>
              <SmallButton
                color="primary"
                onClick={() => this.onRadioBtnClick(0)}
                active={Number.parseInt(this.state.rSelected, 10) === 0}
              >
                All
                </SmallButton>
              <SmallButton
                color="primary"
                onClick={() => this.onRadioBtnClick(1)}
                active={Number.parseInt(this.state.rSelected, 10) === 1}
              >
                1
                </SmallButton>
              <SmallButton
                color="primary"
                onClick={() => this.onRadioBtnClick(2)}
                active={Number.parseInt(this.state.rSelected, 10) === 2}
              >
                2
                </SmallButton>
              <SmallButton
                color="primary"
                onClick={() => this.onRadioBtnClick(3)}
                active={Number.parseInt(this.state.rSelected, 10) === 3}
              >
                3
                </SmallButton>
            </ButtonGroup>
          </dl>

          <div style={{ marginTop: "10px" }}><small>Click on a row to choose a student to view in the selected student overview tab</small></div>
        </Col>
        <Col sm="12" lg="6">
          <div className="header-actions">
            <Button
              size="sm"
              color="secondary"
              onClick={this.refreshStudentList}
            >
              <i className="fa fa-refresh" />
            </Button>{" "}
            {props.components.btnGroup}
          </div>
          <div className="search">{props.components.searchPanel}</div>
          <div className="search" style={{ color: "maroon" }}>
            <b>Current Sort</b>: column={this.state.sortName}, order=
            {this.state.sortOrder}
          </div>
        </Col>

      </StyledTableHeaderRow>
    </Col>
  );



  render() {

    const tableOptions = {
      toolBar: this.createCustomToolBar,
      clearSearch: true,
      sizePerPage: 30,
      sizePerPageList: [
        {
          text: "15",
          value: 15
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "45",
          value: 45
        },
        {
          text: "60",
          value: 60
        }
      ],
      onRowClick: (row) => this.onRowClick(row),
      onSortChange: this.onSortChange.bind(this)
    };
    let iterkey = 1;
    return (

      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeaderBold>


                Student List for Class: #{this.props.selectedClass.classroomId} -{" "}
                {this.props.selectedClass.className}


              </CardHeaderBold>
              <NoMaxCardBody>
                <Fragment>

                  <StyledBootstrapTable
                    data={this.state.rSelected === 0
                      ? this.props.students
                      : this.props.students.filter(s => s.runSequenceNumber === this.state.rSelected)}
                    options={tableOptions}
                    hover
                    condensed
                    pagination
                    search
                    version="4"
                    exportCSV
                    striped

                  >
                    <TableHeaderColumn
                      isKey
                      name="id"
                      dataField="studentId"
                      dataSort
                      width="5%"
                    >
                      ID
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
												dataField='studentName'
												dataSort
												width='200px'
												dataFormat={this.addLink}
											>
												Student Name
											</TableHeaderColumn> */}
                    <TableHeaderColumn
                      dataField="firstName"
                      dataSort
                      width="10%"
                      dataFormat={this.formatName}
                    >
                      Name
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="lastName"
                      dataSort
                      width="8%"
                    // dataFormat={this.formatName}
                    >
                      Last
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
										dataField='studentStatus'
										dataSort
										width='100px'
                    dataAlign='left'

									>
										Status
								                  </TableHeaderColumn> */}

                    <TableHeaderColumn
                      dataField="runSequenceNumber"
                      dataSort
                      width="50px"
                      dataAlign="center"
                      ref={this.execSeqRef}
                    >
                      Run Seq#
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataAlign="center"
                      dataField="runId"
                      width="5%"
                      dataSort
                      dataFormat={this.formatHideZero}
                    >
                      Run Id
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="runStatus"
                      dataSort
                      width="8%"
                      dataAlign="left"
                      dataFormat={this.formatStatus}
                    >
                      Status
                      <br />
                      <small>(last completed week)</small>
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="runStartDt"
                      dataSort
                      width="10%"
                      dataFormat={this.formatDate}
                    >
                      Start Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="runEndDt"
                      dataSort
                      width="10%"
                      dataFormat={this.formatDate}
                    >
                      End Date
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="runPercentComplete"
                      dataSort
                      dataFormat={this.addPercentage}
                      width="60px"
                      dataAlign="center"
                    >
                      % Complete
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataAlign="center"
                      dataField="estbudgetcost"
                      dataSort
                      width="5%"
                      dataFormat={this.formatHide}
                    >
                      Planned budget
                    </TableHeaderColumn>
                    
                    <TableHeaderColumn
                      dataAlign="right"
                      dataField="runBudget"
                      dataSort
                      width="5%"
                      dataFormat={this.formatHide}
                    >
                      Est. Cost
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataAlign="center"
                      dataField="runScheduleStatus"
                      dataSort width="5%"
                      dataFormat={this.formatHideZero}
                    >
                      Est. Finish Week
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataAlign="center"
                      dataField="runDefectStatus"
                      dataSort
                      width="5%"
                      dataFormat={this.formatHideZero}
                    >
                      Defect Count
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataAlign="center"
                      dataField="runScore"
                      dataSort
                      width="5%"
                      dataFormat={this.formatHideZero}
                    >
                      Score
                    </TableHeaderColumn>

                    {/* <TableHeaderColumn
			                width="7%"
			                dataAlign="center"                                      
                     >
                           Score     
						        </TableHeaderColumn> */}
                    <TableHeaderColumn
                      width="8%"
                      dataAlign="center"
                      dataField="actions"
                      dataFormat={this.menuFormatter}
                    >
                      Actions
                    </TableHeaderColumn>
                  </StyledBootstrapTable>

                  {/* {this.state.modalOpen && (
                    <StudentActionModal
                      row={this.state.currentRow}
                      modalOpen={this.state.modalOpen}
                      modalAction={this.state.modalAction}
                      toggleModal={this.toggleModal}
                    />
                  )} */}

                  <Modal
                    isOpen={this.state.modalOpen}
                    toggle={() => this.setState({ modalOpen: !this.state.modalOpen })}
                    className={this.props.className}
                    size="sm"
                  >
                    <ModalHeader
                      cssModule={{ "modal-title": "w-100 text-left" }}
                      toggle={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                      {this.renderModalHeader()}
                    </ModalHeader>
                    <ModalBody>
                      {this.renderModalBody()}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        outline
                        onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Fragment>
              </NoMaxCardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(StudentsTable);


export const StyledBootstrapTable = styled(BootstrapTable)`
  .react-bs-table-bordered {
    border-radius: 0;
    border-bottom: 0;
    overflow-x: scroll;
  }

  .react-bs-container-header,
  .react-bs-container-body {
    min-width: 1000px;
  }

  .table-bordered thead th,
  .table-bordered thead td {
    font-size: 0.75rem;
    vertical-align: top !important;
  }

  .table-bordered thead th {
		white-space: normal;
	}

  td {
    vertical-align: middle;
  }


  th .filter {
    padding: 0.175rem 0.35rem;
    font-size: 0.75rem;
  }

  th.sort-column .fa {
    margin-top: 0 !important;
  }

  .btn {
    padding: 0.275rem 0.75rem;
    line-height: initial;
    font-weight: 600;
  }

  .page-item.active .page-link,
  .pagination-datatables li.active .page-link,
  .pagination li.active .page-link,
  .page-item.active .pagination-datatables li a,
  .pagination-datatables li .page-item.active a,
  .pagination-datatables li.active a,
  .page-item.active .pagination li a,
  .pagination li .page-item.active a,
  .pagination li.active a {
    background-color: #5378AD;
    border-color: #5378AD;
  }

  .page-link,
  .pagination-datatables li a,
  .pagination li a {
    color: black;

    &:hover {
      black;
    }
  }
`;

export const StyledTableHeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;

  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .search {
    margin-top: 0.5rem;
  }

  dl {
    font-size: 0.75rem;
    margin-bottom: 0;
  }

  dl .col {
    margin-bottom: 0;
  }
`;
