/* src/routes/Interaction/index.js */
import React from "react";
import Sortable from "react-sortablejs";
import { connect } from "react-redux";
import _debounce from "lodash.debounce";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button
} from "reactstrap";
import classnames from "classnames";
import styled from "styled-components";

import { fetchTraining } from "redux/Training/thunks";
import {
  fetchStakeholderPlan,
  saveStakeholderItem,
  addActionToAll
} from "redux/Stakeholder/thunks";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import { getStakeholderActions } from "redux/Project/selectors";

import { getInteractionsByWeek } from "redux/Interaction/selectors";
import {
  fetchWeeklyInteractions,
  deleteConference,
  updateConference,
  deleteMeeting,
  updateMeeting,
  deleteRecognition,
  updateRecognition,
  deleteStakeholder,
  updateStakeholder,
  deleteTraining,
  updateTraining2
} from "redux/Interaction/thunks";
import {
  MEETING_INTERACTION_MODAL,
  CONFERENCE_INTERACTION_MODAL,
  RECOGNITION_INTERACTION_MODAL,
  STAKEHOLDER_INTERACTION_MODAL,
  TRAINING_INTERACTION_MODAL,
  NOTIFICATION_MODAL,
  HELP_DOCUMENTATION_MODAL
} from "routes/Modal/ModalTypes";
import { openModal } from "redux/Modal/ModalModule";
import TrainingTable4DisplayContainer from "components/TrainingPlan4Display";
import StakeholderTable from "../Stakeholder/components/StakeholderTable";
import CardHeaderBold from "components/styled/CardHeaderBold";

import { InteractionMenuContainer } from "./components/InteractionMenu";
import { InteractionItem } from "./components/InteractionItem";
import InteractionInfo from "./components/InteractionInfo";
import AvailableResourceList from "../Resource/components/AvailableResourceList";
import ResourceGrid from "../Resource/components/ResourceGrid";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";

import "./Interaction.css";

const StyledRow = styled(Row)`
  margin-bottom: 1rem;
`;

class Interaction extends React.Component {
  state = {
    activeTab: "1",
    message: "",
    activeReportsTab: "resource-plan"
  };

  componentDidMount() {
    this.props.fetchWeeklyInteractions(this.props.runId, this.props.productId);
    this.props.fetchTraining(this.props.runId);
    this.props.fetchStakeholderPlan(this.props.runId);
  }

  deleteItem = _debounce((id, itemType, itemTitle, itemTarget, day) => {
    const typeStr = itemType.substring(0, 3).toLowerCase();

    const alert = {
      id: 2,
      type: "warning",
      headline: `${itemType} interaction deleted`,
      event: "interaction",
      message: `${itemTitle} for ${itemTarget} deleted on ${day}`
    };

    switch (typeStr) {
      case "mee": {
        // meeting
        this.props.deleteMeeting(id, this.props.runId);
        break;
      }
      case "con": {
        // conference
        this.props.deleteConference(id, this.props.runId);
        break;
      }
      case "rec":
      case "tea":
      case "ind": {
        this.props.deleteRecognition(id, this.props.runId);
        break;
      }
      case "sta": {
        this.props.deleteStakeholder(id, this.props.runId);
        break;
      }
      case "tra": {
        // trainings have multiple instances
        // need to find all ids pertaining to the requested delete item
        // by class id and worker id then make a list of those ids to
        // pass to the delete function
        const trs = this.props.trainingInteractions.filter(
          (tr) => tr.Id === id
        );

        const ids = [];
        if (trs !== undefined && trs.length > 0) {
          const classId = trs[0].ClassId;
          const workerId = trs[0].WorkerId;
          let keys = [];
          let ti = {};
          keys = Object.keys(this.props.trainingInteractions);
          for (let i = 0; i < keys.length; i++) {
            ti = this.props.trainingInteractions[i];
            if (ti.ClassId === classId && ti.WorkerId === workerId) {
              ids.push(ti.Id);
            }
          }
        }

        this.props.deleteTraining(ids, this.props.runId);

        break;
      }
      default:
        break;
    }
    const notificationData = {
      info: `${itemTitle} for ${itemTarget} deleted on ${day}`,
      worker: "",
      week: this.props.displayProjectWeek
    };
    this.props.addAlert(alert, notificationData, 3000, true);
  }, 200);

  editItem = (id, day, itemType) => {
    switch (itemType) {
      case "Meeting": // meeting
        this.showMeetingModal(id, day);
        break;
      case "Conference": // conference
        this.showConferenceModal(id, day);
        break;
      case "Team Recognition":
        this.showTeamRecognitionModal(id, day);
        break;
      case "Individual Recognition":
        this.showIndividualRecognitionModal(id, day);
        break;
      case "Stakeholder Action":
        this.showStakeholderActionModal(id, day);
        break;
      case "Stakeholder Training":
        this.showStakeholderTrainingModal(id, day);
        break;
      case "Training":
        this.showTrainingModal(id, day);
        break;
      default:
        break;
    }
  };

  showConferenceModal = (id, day) => {
    const list = this.props.conferenceInteractions.filter(
      (item) => item.Id === id
    );

    this.props.showConferenceInteractionModal(list[0], day, false);
  };

  showMeetingModal = (id, day) => {
    const list = this.props.meetingInteractions.filter(
      (item) => item.Id === id
    );
    this.props.showMeetingInteractionModal(list[0], day, false);
  };

  showIndividualRecognitionModal = (id, day) => {
    const list = this.props.indvInteractions.filter((item) => item.Id === id);
    this.props.showRecognitionInteractionModal(list[0], day, "INDV", false);
  };

  showTeamRecognitionModal = (id, day) => {
    const list = this.props.teamInteractions.filter((item) => item.Id === id);
    this.props.showRecognitionInteractionModal(list[0], day, "TEAM", false);
  };

  showTrainingModal = (id, day) => {
    const list = this.props.trainingInteractions.filter(
      (item) => item.Id === id
    );
    this.props.showTrainingInteractionModal(list[0], day, false);
  };

  showStakeholderActionModal = (id, day) => {
    const list = this.props.stakeholderInteractions.filter(
      (item) => item.Id === id
    );

    this.props.showStakeholderInteractionModal(
      list[0],
      day,
      list[0].Interaction === "Meeting",
      false
    );
  };

  showStakeholderTrainingModal = (id, day) => {
    const list = this.props.stakeholderInteractions.filter(
      (item) => item.Id === id
    );
    this.props.showStakeholderInteractionModal(
      list[0],
      // list[0].Interaction === "Education",
      this.props.selectedDay,
      true,
      false
    );
  };

  checkMove = () => {
    if (this.props.weekHasCompleted === false) {
      return false;
    }
    return true;
  };
  onDragStart = (evt) => {
    if (this.props.weekHasCompleted === false) {
      // check where we are moving from
      if (!this.okToEdit(evt.currentTarget.id)) {
        setTimeout(
          this.setState({
            message: "Changes cannot be made to past interactions"
          }),
          3000
        );
        evt.preventDefault();
        return;
      }
    }
  };

  handleSortableChange = (order, sortable, evt) => {
    if (evt.defaultPrevented === true) {
      // means on drag start set it to false
      evt.preventDefault();
      return;
    }
    if (this.props.weekHasCompleted === false) {
      // check where we are moving from
      if (!this.okToEdit(sortable.el.id)) {
        setTimeout(
          this.setState({
            message: "Changes cannot be made to past interactions"
          }),
          3000
        );
        evt.preventDefault();
        return;
      } else if (!this.okToEdit(evt.target.id)) {
        setTimeout(
          this.setState({
            message: "Changes cannot be made to past interactions"
          }),
          3000
        );
        evt.preventDefault();
        return;
      }
      // check where we are moving to
    }
    if (evt.item.type === "Training") {
      this.props.showTrainingMoveNotification();
    } else {
      const typeStr = evt.item.type.substring(0, 3).toLowerCase();
      const id = parseInt(evt.item.id, 10);
      switch (typeStr) {
        case "mee": {
          // meeting
          const mtgs = this.props.meetingInteractions.filter(
            (mtg) => mtg.Id === id
          );
          if (mtgs !== undefined && mtgs.length > 0) {
            mtgs[0].DayOfWeek = evt.to.id; // day of weekday

            this.props.updateMeeting(mtgs[0]);
          }
          break;
        }
        case "con": {
          // conference
          const confs = this.props.conferenceInteractions.filter(
            (conf) => conf.Id === id
          );

          if (confs !== undefined && confs.length > 0) {
            confs[0].DayOfWeek = evt.to.id; // day of weekday

            this.props.updateConference(confs[0]);
          }

          break;
        }
        case "tea": {
          const trs = this.props.teamInteractions.filter(
            (rec) => rec.Id === id
          );

          if (trs !== undefined && trs.length > 0) {
            trs[0].DayOfWeek = evt.to.id; // day of weekday

            this.props.updateRecognition(trs[0]);
          }

          break;
        }
        case "ind": {
          const recs = this.props.indvInteractions.filter(
            (rec) => rec.Id === id
          );

          if (recs !== undefined && recs.length > 0) {
            recs[0].DayOfWeek = evt.to.id; // day of weekday

            this.props.updateRecognition(recs[0]);
          }

          break;
        }
        case "sta": {
          const shs = this.props.stakeholderInteractions.filter(
            (sh) => sh.Id === id
          );

          if (shs !== undefined && shs.length > 0) {
            shs[0].DayOfWeek = evt.to.id; // day of weekday

            this.props.updateStakeholder(shs[0]);
          }
          break;
        }
        default:
          break;
      }
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleReportsTab = (tab) => {
    if (this.state.activeReportsTab !== tab) {
      this.setState({
        activeReportsTab: tab
      });
    }
  };

  openHelpModal = () => {
    const selectedKey = "interaction";
    this.props.showHelp(selectedKey);
  };

  okToEdit = (interactionDay) => {
    // props currentweekday is the day number of the project
    // need to translate this into a number from 0 -4 representing m - f
    if (this.props.weekHasCompleted) {
      return true;
    }
    var weekdayNumber = (this.props.currentDayOfWeek % 5) + 1;
    let interactionDayNumber = this.props.daysOfWeek.indexOf(interactionDay);
    if (interactionDayNumber > weekdayNumber) {
      return true;
    }
    return false;
  };

  render() {
    const mondayList = this.props.weeklyInteractions.mondayList.map(
      (row, index) => (
        <InteractionItem
          id={row.Id}
          type={row.ItemHeader}
          data-id={row.Id}
          key={row.Id}
          row={row}
          editItem={this.editItem}
          day="Monday"
          dayNumber={this.props.displayProjectWeek * 5 - 4}
          deleteItem={this.deleteItem}
          weekHasCompleted={this.props.weekHasCompleted}
          runDay={this.props.runDay}
        />
      )
    );

    const tuesdayList = this.props.weeklyInteractions.tuesdayList.map(
      (row, index) => (
        <InteractionItem
          id={row.Id}
          type={row.ItemHeader}
          data-id={row.Id}
          key={row.Id}
          row={row}
          editItem={this.editItem}
          day="Tuesday"
          dayNumber={this.props.displayProjectWeek * 5 - 3}
          deleteItem={this.deleteItem}
          weekHasCompleted={this.props.weekHasCompleted}
          runDay={this.props.runDay}
        />
      )
    );

    const wednesdayList = this.props.weeklyInteractions.wednesdayList.map(
      (row, index) => (
        <InteractionItem
          id={row.Id}
          type={row.ItemHeader}
          data-id={row.Id}
          key={row.Id}
          row={row}
          editItem={this.editItem}
          day="Wednesday"
          dayNumber={this.props.displayProjectWeek * 5 - 2}
          deleteItem={this.deleteItem}
          weekHasCompleted={this.props.weekHasCompleted}
          runDay={this.props.runDay}
        />
      )
    );

    const thursdayList = this.props.weeklyInteractions.thursdayList.map(
      (row, index) => (
        <InteractionItem
          data-id={row.Id}
          type={row.ItemHeader}
          key={row.Id}
          row={row}
          editItem={this.editItem}
          day="Thursday"
          dayNumber={this.props.displayProjectWeek * 5 - 1}
          deleteItem={this.deleteItem}
          weekHasCompleted={this.props.weekHasCompleted}
          runDay={this.props.runDay}
        />
      )
    );

    const fridayList = this.props.weeklyInteractions.fridayList.map(
      (row, index) => (
        <InteractionItem
          data-id={row.Id}
          type={row.ItemHeader}
          key={row.Id}
          row={row}
          editItem={this.editItem}
          day="Friday"
          dayNumber={this.props.displayProjectWeek * 5}
          deleteItem={this.deleteItem}
          weekHasCompleted={this.props.weekHasCompleted}
          runDay={this.props.runDay}
        />
      )
    );

    let i = 1;
    return (
      <div>
        <Row>
          <Col lg="12">
           
              <InteractionInfo />
             
          </Col>
        </Row>
        {<h6 style={{ color: "maroon" }}>{this.state.message}</h6>}
        <Row className="row-InteractionSchedule">
          <Col xl="2" lg="3" md="4" sm="6">
            <Card>
              <CardHeader>
                Monday
                {this.okToEdit("Monday") && (
                  <InteractionMenuContainer selectedDay="Monday" />
                )}
              </CardHeader>
              <CardBody>
                <Sortable
                  disabled={!this.okToEdit("Monday")}
                  id="Monday"
                  className="sortable-list agile-list"
                  tag="ul" // Defaults to "div"
                  options={{
                    group: "shared"
                  }}
                  onDragStart={this.onDragStart}
                  onChange={this.handleSortableChange}
                >
                  {mondayList}
                </Sortable>
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="4" sm="6">
            <Card>
              <CardHeader>
                Tuesday
                {this.okToEdit("Tuesday") && (
                  <InteractionMenuContainer selectedDay="Tuesday" />
                )}
              </CardHeader>
              <CardBody>
                <Sortable
                  disabled={!this.okToEdit("Tuesday")}
                  id="Tuesday"
                  className="sortable-list agile-list"
                  tag="ul" // Defaults to "div"
                  options={{
                    group: "shared"
                  }}
                  onDragStart={this.onDragStart}
                  onChange={this.handleSortableChange}
                >
                  {tuesdayList}
                </Sortable>
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="4" sm="6">
            <Card>
              <CardHeader>
                Wednesday
                {this.okToEdit("Wednesday") && (
                  <InteractionMenuContainer selectedDay="Wednesday" />
                )}
              </CardHeader>
              <CardBody>
                <Sortable
                  disabled={!this.okToEdit("Wednesday")}
                  id="Wednesday"
                  className="sortable-list agile-list"
                  tag="ul" // Defaults to "div"
                  options={{
                    group: "shared"
                  }}
                  onDragStart={this.onDragStart}
                  onChange={this.handleSortableChange}
                >
                  {wednesdayList}
                </Sortable>
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="4" sm="6">
            <Card>
              <CardHeader>
                Thursday
                {this.okToEdit("Thursday") && (
                  <InteractionMenuContainer selectedDay="Thursday" />
                )}
              </CardHeader>
              <CardBody>
                <Sortable
                  disabled={!this.okToEdit("Thursday")}
                  id="Thursday"
                  className="sortable-list agile-list"
                  tag="ul" // Defaults to "div"
                  options={{
                    group: "shared"
                  }}
                  onDragStart={this.onDragStart}
                  onChange={this.handleSortableChange}
                >
                  {thursdayList}
                </Sortable>
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="4" sm="6">
            <Card>
              <CardHeader>
                Friday
                {this.okToEdit("Friday") && (
                  <InteractionMenuContainer selectedDay="Friday" />
                )}
              </CardHeader>
              <CardBody>
                <Sortable
                  disabled={!this.okToEdit("Friday")}
                  id="Friday"
                  className="sortable-list agile-list"
                  tag="ul" // Defaults to "div"
                  options={{
                    group: "shared"
                  }}
                  onDragStart={this.onDragStart}
                  onChange={this.handleSortableChange}
                >
                  {fridayList}
                </Sortable>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <StyledRow>
          
          <Col lg="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Stakeholder Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Training Plan
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane key={(i += 1)} tabId="1">
                <Card className="component-StakeholderPlan">
                  <CardHeaderBold>Stakeholder Plan</CardHeaderBold>
                  <CardBody>
                    <StakeholderTable
                      stakeholderPlan={this.props.stakeholderPlan}
                      runId={this.props.runId}
                      displayProjectWeek={this.props.displayProjectWeek}
                      detailInfoWeekMap={this.props.detailInfoWeekMap}
                      saveStakeholderItem={this.props.saveStakeholderItem}
                      addActionToAll={this.props.addActionToAll}
                      projectedFinishWeek={this.props.projectedFinishWeek}
                      stakeholderActions={this.props.stakeholderActions}
                      displayOnly
                      narrowView
                    />
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane key={(i += 1)} tabId="2">
                <TrainingTable4DisplayContainer />
              </TabPane>
            </TabContent>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReportsTab === "resource-plan"
                  })}
                  onClick={() => {
                    this.toggleReportsTab("resource-plan");
                  }}
                >
                  Resource Plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      this.state.activeReportsTab === "available-resources"
                  })}
                  onClick={() => {
                    this.toggleReportsTab("available-resources");
                  }}
                >
                  Available Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReportsTab === "gantt-chart"
                  })}
                  onClick={() => {
                    this.toggleReportsTab("gantt-chart");
                  }}
                >
                  Gantt Chart
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReportsTab === "network-diagram"
                  })}
                  onClick={() => {
                    this.toggleReportsTab("network-diagram");
                  }}
                >
                  Network Diagram
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeReportsTab}>
              <TabPane tabId="resource-plan">
                <ResourceGrid noHideToggle />
              </TabPane>
              <TabPane tabId="available-resources">
                <AvailableResourceList noHideToggle />
              </TabPane>
              <TabPane tabId="gantt-chart">
                <GanttChartContainer />
              </TabPane>
              <TabPane tabId="network-diagram">
                <NetworkDiagramContainer />
              </TabPane>
            </TabContent>
          </Col>
        </StyledRow>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayProjectWeek: state.project.displayProjectWeek,
  runId: state.project.runId,
  runDay: state.project.runDay,
  currentDayOfWeek: state.project.projectStatus.DayNumber,
  productId: state.auth.productId,
  daysOfWeek: state.project.daysOfWeek,
  stakeholderPlan: state.planning.stakeholder.stakeholderPlan,
  detailInfoWeekMap: state.planning.stakeholder.detailInfoWeekMap,
  projectedFinishWeek: state.project.projectedFinishWeek,
  stakeholderActions: getStakeholderActions(state),
  weeklyInteractions: getInteractionsByWeek(state),
  conferenceInteractions: state.execution.interactions.conferenceInteractions,
  indvInteractions: state.execution.interactions.indvRecognitionInteractions,
  meetingInteractions: state.execution.interactions.meetingInteractions,
  stakeholderInteractions: state.execution.interactions.stakeholderInteractions,
  teamInteractions: state.execution.interactions.teamRecognitionInteractions,
  trainingInteractions: state.execution.interactions.trainingInteractions,
  origWeeklyInteractions: state.execution.interactions.weeklyInteractions,
  weekHasCompleted: state.project.projectStatus.WeekHasCompleted
});

const mapDispatchToProps = (dispatch) => ({
  saveStakeholderItem: (data) => dispatch(saveStakeholderItem(data)),
  addActionToAll: (idList, weekNo, runId, newAction) =>
    dispatch(addActionToAll(idList, weekNo, runId, newAction)),
  fetchWeeklyInteractions: (runId, productId) =>
    dispatch(fetchWeeklyInteractions(runId, productId)),
  fetchTraining: (runId) => dispatch(fetchTraining(runId)),
  fetchStakeholderPlan: (runId) => dispatch(fetchStakeholderPlan(runId)),
  deleteMeeting: (id, runId) => dispatch(deleteMeeting(id, runId)),
  updateMeeting: (meetingItem) => dispatch(updateMeeting(meetingItem)),
  deleteConference: (id, runId) => dispatch(deleteConference(id, runId)),
  updateConference: (conferenceItem) =>
    dispatch(updateConference(conferenceItem)),
  deleteRecognition: (id, runId) => dispatch(deleteRecognition(id, runId)),
  updateRecognition: (recognitionItem) =>
    dispatch(updateRecognition(recognitionItem)),

  deleteStakeholder: (id, runId) => dispatch(deleteStakeholder(id, runId)),
  updateStakeholder: (stakeholderItem) =>
    dispatch(updateStakeholder(stakeholderItem)),

  deleteTraining: (ids, runId) => dispatch(deleteTraining(ids, runId)),
  updateTraining: (trainingItem) => dispatch(updateTraining2(trainingItem)),

  showMeetingInteractionModal: (meetingItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        MEETING_INTERACTION_MODAL,
        {
          meetingItem,
          selectedDay,
          isAdd
        },
        "modal-medium"
      )
    ),
  showConferenceInteractionModal: (conferenceItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        CONFERENCE_INTERACTION_MODAL,
        {
          conferenceItem,
          selectedDay,
          isAdd
        },
        "modal-medium"
      )
    ),
  showRecognitionInteractionModal: (
    recognitionItem,
    selectedDay,
    recognitionType,
    isAdd
  ) =>
    dispatch(
      openModal(
        RECOGNITION_INTERACTION_MODAL,
        {
          recognitionItem,
          selectedDay,
          recognitionType,
          isAdd
        },
        "modal-medium"
      )
    ),
  showStakeholderInteractionModal: (
    stakeholderItem,
    selectedDay,
    isTraining,
    isAdd
  ) =>
    dispatch(
      openModal(
        STAKEHOLDER_INTERACTION_MODAL,
        {
          stakeholderItem,
          selectedDay,
          isTraining,
          isAdd
        },
        "modal-medium"
      )
    ),
  showTrainingInteractionModal: (trainingItem, selectedDay, isAdd) =>
    dispatch(
      openModal(
        TRAINING_INTERACTION_MODAL,
        {
          trainingItem,
          selectedDay,
          isAdd
        },
        "modal-medium"
      )
    ),
  showTrainingMoveNotification: () =>
    dispatch(
      openModal(
        NOTIFICATION_MODAL,
        "Training items cannot be moved",
        "modal-medium"
      )
    ),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  showHelp: (selectedKey) =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, { selectedKey }, "modal-wide"))
});

export const InteractionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Interaction);
