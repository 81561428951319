import { requestUpdatedBudget } from "redux/Project/thunks";
import { fetchChartData} from "redux/Report/thunks";

import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";

import * as actions from "./actions";

export const fetchRisks = runId => async (dispatch, getState) => {
  try {
    const payload = { runId };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/riskPlan`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "error",
        event: "Risk Error",
        headline: "Error accessing the riskPlan api",
        message: `runId: ${runId}  fetch riskPlan : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  fetchRisks : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating fetchRisks api"
        })
      );
      return false;
    } else if (json.status === "error") {
      dispatch(actions.actionError(json));
      return false;
    }
    dispatch(actions.receiveRisks(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    processFailToFetch(e,getState(), dispatch, addSingleAlert, "fetchRisk");
    return false;
  }
};

export const saveRisk = data => async (dispatch, getState) => {
  try {
    const newItem = data.ID === 0; // sets new item to true if the id is 0
    const payload = { riskStatusDTO: data };


    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/riskPlan/save`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "error",
        event: "Risk Error",
        headline: "Error accessing the riskPlan  save api",
        message: `runId: ${
          data.RunId
        }  fetch saveRisk : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${
          data.RunId
        }  saveRisk : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId: data.RunId,
          message: "Entity error while creating saveRisk api"
        })
      );
      return false;
    }
    const cls = json.status;
    // need change this error passing to an object in the response
    if (cls === "error") {
      dispatch(actions.actionError(json.Action));
      return false;
    }
    const pl = {};
    pl.newItem = newItem;
    pl.result = json;

    const alert = {
      id: 20,
      type: "success",
      event: "Add Risk",
      headline: "Risk Saved",
      message: "Risk item has been saved"
    };
    const notificationData = {
      worker: "thunk",
      week: 0,
      info: "Risk item has been saved"
    };
    dispatch(addSingleAlert(alert, notificationData, 3000, true));

    await dispatch(actions.saveSuccess(pl));
    await dispatch(fetchRisks(data.RunId));
    await dispatch(requestUpdatedBudget(data.RunId));
    dispatch(fetchChartData(data.RunId));

    return true;
  } catch (e) {

    processFailToFetch(e,getState(), dispatch, addSingleAlert, "saveRisk");
    return false;
  }
};

export const deleteRisk = (index, id, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      id
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/riskPlan/delete`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "error",
        event: "Risk Error",
        headline: "Error accessing the riskPlan delete api",
        message: `runId: ${runId}  fetch deleteRisk : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteRisk : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating deleteRisk api"
        })
      );
      return false;
    } else if (json.status === "success") {
      const alert = {
        id: 75,
        type: "warning",
        event: "deleteRisk",
        headline: "Deleted",
        message: "Risk item removed"
      };
      const notificationData = {
        worker: "thunk",
        week: runId,
        info: "risk deleted"
      };
      await dispatch(addSingleAlert(alert, notificationData, 300, false));
      dispatch(actions.deleteSuccess(index));
      await dispatch(fetchRisks(runId))

      await dispatch(requestUpdatedBudget(runId));
      dispatch(fetchChartData(runId));

      return false;
    }
    dispatch(actions.deleteError(json.statusMessage));
    return true;
  } catch (e) {
    processFailToFetch(e,getState(), dispatch, addSingleAlert, "deleteRisk");
    return false;
  }
};
