import React, {Fragment} from "react";

export const InteractionItem = props => {
  const okToDisplay = () => {
    if (props.weekHasCompleted) {
      return true;
    }
    if (props.runDay >= props.dayNumber) {
      return true
    }
    return false;
  }
  var i = 222;
  return (
    <li
      key={i++}
      type={props.type}
      id={props.row.Id}
      className={props.row.ItemStyleClass}
      data-id={props.row.Id}
    >
      {okToDisplay() &&
        <Fragment>
        {props.type !== "Training" && <button
          key={i++}
          className="btn-link btn-close fa fa-pencil"
          onClick={props.editItem.bind(
            this,
            props.row.Id,
            props.day,
            props.row.ItemHeader
          )}
        />}
          <button
            key={i++}
            className="btn-link btn-close fa fa-times"
            onClick={props.deleteItem.bind(
              this,
              props.row.Id,
              props.row.ItemHeader,
              props.row.ItemTitle,
              props.row.ItemTarget,
              props.day
            )}
          />
        </Fragment>
      } 

      <h3 key={i++}>{props.row.ItemHeader}</h3>
      <p key={i++}>{props.row.ItemTitle}</p>
      <p key={i++} className="target">
        <em>{props.row.ItemTarget}</em>
      </p>

      <div className="agile-detail">
        {props.row.ItemTime === "NONE" ? (
          <span />
        ) : (
          <span className="time">
            <i className="fa fa-clock-o" />
            {props.row.ItemTime}
          </span>
        )}

        {props.row.IsRecurring === true ? (
          <i className="fa fa-repeat" />
        ) : (
          <span />
        )}
      </div>
    </li>
  );
};
