// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_STAKEHOLDERPLAN = "REQUEST_STAKEHOLDERPLAN";
export const RECEIVE_STAKEHOLDERPLAN = "RECEIVE_STAKEHOLDERPLAN";
export const SAVE_STAKEHOLDERPLAN_REQUEST = "SAVE_STAKEHOLDERPLAN_REQUEST";
export const SAVE_STAKEHOLDERPLAN_SUCCESS = "SAVE_STAKEHOLDERPLAN_SUCCESS";
export const SAVE_STAKEHOLDERPLAN_ERROR = "SAVE_STAKEHOLDERPLAN_ERROR";
export const ADDACTIONALL_SUCCESS = "ADDACTIONALL_SUCCESS";
export const STAKHOLDER_ERROR = "STAKHOLDER_ERROR";
export const STAKEHOLDER_ERROR = "STAKEHOLDER_ERROR";
