/* @flow */
import React, { Component } from "react";
import { openModal, closeModal } from "redux/Modal/ModalModule";
import { connect } from "react-redux";
import {
  Button as ModalButton,
  Modal as ReactStrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
/* IMPORT THE MODAL TYPES FROM MODALTYPES.JS HERE */
import ResetWeekChooser from "routes/Projects/components/ResetWeekChooser";
import {
  NOTIFICATION_MODAL,
  CONFIRMATION_MODAL,
  FORGOT_PASSWORD_MODAL,
  RESET_PASSWORD_MODAL,
  RESET_PROJECT_MODAL,
  HELP_DOCUMENTATION_MODAL,
  EMAIL_MODAL,
  ORIENTATION_MODAL,
  REFERENCE_MODAL,
  LICENSE_MODAL,
  TASK_MODAL,
  BUDGET_MODAL,
  ADD_RISK_MODAL,
  ADD_RESOURCE_MODAL,
  ADD_TRAINING_MODAL,
  START_RUN_MODAL,
  CONFERENCE_INTERACTION_MODAL,
  MEETING_INTERACTION_MODAL,
  RECOGNITION_INTERACTION_MODAL,
  STAKEHOLDER_INTERACTION_MODAL,
  TRAINING_INTERACTION_MODAL,
  WORKER_ASSIGNMENT_MODAL,
  AVAILABLE_RESOURCES_MODAL,
  RESOURCE_PROFILE_MODAL,
  NEW_CLASSROOM_MODAL
} from "./ModalTypes";

/** IMPORT THE MODALS FROM INDEX.JS HERE * */
import {
  NotificationModal,
  ConfirmModal,
  ForgotPasswordContainer,
  ResetPasswordContainer,
  HelpDocumentationModal,
  EmailModalContainer,
  OrientationModal,
  ReferenceModal,
  LicenseModal,
  TaskModal,
  BudgetModal,
  AddResourceContainer,
  AddTrainingContainer,
  StartRunModal,
  ConferenceInteractionContainer,
  MeetingInteractionContainer,
  RecognitionInteractionContainer,
  StakeholderInteractionContainer,
  TrainingInteractionContainer,
  WorkerAssignmentModal,
  AvailableResourcesModalContainer,
  ResourceProfileModal,
  CreateClassroomContainer
} from "./index";

import AddRiskContainer from "./components/AddRiskContainer";

import "./ModalContainer.css";

export class Modal extends Component {
 
   state = {
      title: "",
      closeButtonLabel: "Close"
    };
  

  onEscape = evt => {
    if (evt.which === 27) {
      evt.preventDefault();
      this.dismiss();
    }
  };


  /** CASE STATEMENT FOR THE MODAL TITLE SETTER BASED ON MODAL TYPE * */
  componentDidMount() {
    if (
      Object.prototype.hasOwnProperty.call(
        this.props.payload,
        "closeButtonLabel"
      )
    ) {
      this.setState({
        closeButtonLabel: this.props.payload.closeButtonLabel
      });
    }
    if (this.props.modal) {
      document.addEventListener("keydown", this.onEscape);
    } else {
      document.removeEventListener("keydown", this.onEscape);
    }
  }

  getTitle = () => {
    let title = "";

    switch (this.props.modal) {
      case NOTIFICATION_MODAL:
        title = "Alert";
        break;
      case CONFIRMATION_MODAL:
        title = "Please Confirm";
        break;
      case LICENSE_MODAL:
        title = "LICENSE AGREEMENT";
        break;
      case FORGOT_PASSWORD_MODAL:
        title = "Reset your password";
        break;
      case RESET_PASSWORD_MODAL:
        title = "Please change your temporary password";
        break;
      case RESET_PROJECT_MODAL:
        title = "Reset run to beginning of chosen week";
        break;
      case HELP_DOCUMENTATION_MODAL:
        title = "Help Documentation";
        break;
      case EMAIL_MODAL:
        title = "Email";
        break;
      case ORIENTATION_MODAL:
        title = "Orientation Guide";
        break;
      case REFERENCE_MODAL:
        title = "Reference Guide";
        break;
      case TASK_MODAL:
        title = "Task Information";
        break;
      case BUDGET_MODAL:
        title = "Budget";
        break;
      case ADD_RISK_MODAL:
        title = "Add/Edit Risk / Opportunities";
        break;
      case ADD_RESOURCE_MODAL:
        title = "Choose initial staffing week";
        break;
      case ADD_TRAINING_MODAL:
        title = "Add/Edit Training";
        break;
      case CONFERENCE_INTERACTION_MODAL:
        title = "Add/Edit Individual Conferences";
        break;
      case MEETING_INTERACTION_MODAL:
        title = "Add/Edit Team Meetings";
        break;
      case RECOGNITION_INTERACTION_MODAL:
        title = "Add/Edit Individual and Team Recognition";
        break;
      case STAKEHOLDER_INTERACTION_MODAL:
        title = "Add/Edit Stakeholder Actions and Training";
        break;
      case TRAINING_INTERACTION_MODAL:
        title = "Add/Edit Individual and Team Training";
        break;
      case WORKER_ASSIGNMENT_MODAL:
        title = "Edit Worker Weekly Assignments";
        break;
      case START_RUN_MODAL:
        title = "Start A New Execution";
        break;
      case RESOURCE_PROFILE_MODAL:
        title = "Profile";
        break;
      case AVAILABLE_RESOURCES_MODAL:
        title = "Available Resources";
        break;
      case NEW_CLASSROOM_MODAL:
        title = "Create a new classroom";
        break;
      default:
        break;
    }
    return title
  };

  render() {
    if (!this.props.modal) {
      return null;
    }
    // className for size should be settable by the calling form
    // also should conditionally show close button(s) or other
    // kinds of buttons as appropriate - passed in via props
    //

    return (
      <ReactStrapModal
        fade
        isOpen={this.props.open}
        // toggle={this.toggle}
        className={this.props.size}
        style={{ zIndex: 10000 }}
        // override all the 9999s
        size="lg"
      >
        <ModalHeader>
          {this.getTitle()}
          <ModalButton color="link" onClick={this.dismiss}>
            <i className="fa fa-times" />
          </ModalButton>
        </ModalHeader>
        <ModalBody>{this.renderContent()}</ModalBody>
        <ModalFooter>
          <ModalButton color="primary" onClick={this.dismiss}>
            {" "}
            {this.state.closeButtonLabel}
          </ModalButton>
        </ModalFooter>
      </ReactStrapModal>
    );
  }

  /** CASE STATEMENT FOR ACTUAL COMPONENT RENDERED * */
  renderContent = () => {
    // this.props.payload contains any passed parameters to the openmodal call
    switch (this.props.modal) {
      case NOTIFICATION_MODAL:
        return <NotificationModal content={this.props.payload} />;
      case CONFIRMATION_MODAL:
        return (
          <ConfirmModal
            title={this.props.payload.title}
            message={this.props.payload.message}
            confirmLabel={this.props.payload.confirmLabel}
            cancelLabel={this.props.payload.cancelLabel}
            handleConfirm={this.props.payload.handleConfirm}
            handleCancel={this.props.payload.handleCancel}
          />
        );
      case FORGOT_PASSWORD_MODAL:
        return <ForgotPasswordContainer dismiss={this.dismiss} />;
      case LICENSE_MODAL:
        return <LicenseModal />;
      case RESET_PASSWORD_MODAL:
        return (
          <ResetPasswordContainer
            username={this.props.payload.username}
            password={this.props.payload.password}
            cancel={this.dismiss}
          />
        );
      case RESET_PROJECT_MODAL:
        return (
          <ResetWeekChooser
            runId={this.props.payload.runId}
            selectedWeek={this.props.payload.selectedWeek}
            currentWeek={this.props.payload.currentWeek}
            setSelectedWeek={this.props.payload.setSelectedWeek}
            resetProject={this.props.payload.resetProject}
            closeModal={this.dismiss}
          />
        );
      case HELP_DOCUMENTATION_MODAL:
        return (
          <HelpDocumentationModal
            selectedKey={this.props.payload.selectedKey}
          />
        );
      case EMAIL_MODAL:
        return <EmailModalContainer />;
      case ORIENTATION_MODAL:
        return <OrientationModal productId={this.props.productId} />;
      case REFERENCE_MODAL:
        return <ReferenceModal selectedKey={this.props.payload.selectedKey} />;
      case TASK_MODAL:
        return <TaskModal task={this.props.payload.task} />;
      case BUDGET_MODAL:
        return <BudgetModal />;
      case ADD_RISK_MODAL:
        return <AddRiskContainer riskItem={this.props.payload.riskItem} />;
      case ADD_RESOURCE_MODAL:
        return (
          <AddResourceContainer
            newResourceItem={this.props.payload.newResourceItem}
            closeModal={this.dismiss}
          />
        );
      case ADD_TRAINING_MODAL:
        return (
          <AddTrainingContainer
            trainingItem={this.props.payload.trainingItem}
          />
        );
      case CONFERENCE_INTERACTION_MODAL:
        return (
          <ConferenceInteractionContainer
            conferenceItem={this.props.payload.conferenceItem}
            selectedDay={this.props.payload.selectedDay}
            isAdd={this.props.payload.isAdd}
          />
        );

      case MEETING_INTERACTION_MODAL:
        return (
          <MeetingInteractionContainer
            meetingItem={this.props.payload.meetingItem}
            selectedDay={this.props.payload.selectedDay}
            isAdd={this.props.payload.isAdd}
          />
        );

      case RECOGNITION_INTERACTION_MODAL:
        return (
          <RecognitionInteractionContainer
            recognitionItem={this.props.payload.recognitionItem}
            selectedDay={this.props.payload.selectedDay}
            recognitionType={this.props.payload.recognitionType}
            isAdd={this.props.payload.isAdd}
          />
        );

      case STAKEHOLDER_INTERACTION_MODAL:
        return (
          <StakeholderInteractionContainer
            stakeholderItem={this.props.payload.stakeholderItem}
            selectedDay={this.props.payload.selectedDay}
            isTraining={this.props.payload.isTraining}
            isAdd={this.props.payload.isAdd}
          />
        );

      case TRAINING_INTERACTION_MODAL:
        return (
          <TrainingInteractionContainer
            trainingItem={this.props.payload.trainingItem}
            selectedDay={this.props.payload.selectedDay}
            isAdd={this.props.payload.isAdd}
          />
        );

      case WORKER_ASSIGNMENT_MODAL:
        return (
          <WorkerAssignmentModal
            workerAssignmentItem={this.props.payload.workerAssignmentItem}
            rIndex={this.props.payload.rindex}
            processAssignments={this.props.payload.processAssignments}
            taskList={this.props.payload.taskList}
            runId={this.props.payload.runId}
            currentWeek={this.props.payload.currentWeek}
            resourcePlanItem={this.props.payload.resourcePlanItem}
          />
        );
      case START_RUN_MODAL:
        return (
          <StartRunModal
            availRuns={this.props.payload.availRuns}
            closeModal={this.dismiss}
          />
        );
      case RESOURCE_PROFILE_MODAL:
        return <ResourceProfileModal resource={this.props.payload.resource} />;
      case AVAILABLE_RESOURCES_MODAL:
        return <AvailableResourcesModalContainer />;
      case NEW_CLASSROOM_MODAL:
        return (
          <CreateClassroomContainer refresh={this.props.payload.refresh} />
        );

      default:
        break;
    }
    return null;
  };

  dismiss = () => {
    this.props.dismiss();
  };
}

const mapStateToProps = state => ({
  modal: state.modal.activeModal,
  payload: state.modal.payload,
  size: state.modal.size,
  open: state.modal.open,
  productId: state.auth.productId
});

const mapDispatchToProps = dispatch => ({
  dismiss: () => dispatch(closeModal()),
  openModal: (modal, params, size) => dispatch(openModal(modal, params, size))
});

export const ModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
