import { handleActions } from "redux-actions";

import {
  DATA_REQUESTED,
  CHART_DATA_RECEIVED,
  HISTORY_RECEIVED,
  INTERIM_HISTORY_RECEIVED,
  LABOR_SUMMARY_RECEIVED,
  REPORT_ERROR
} from "./types";

const initialState = {
  history: [],
  defects: [],
  projectDefects: [],
  softwareDefects: [],
  hardwareDefects: [],
  ACCData: [],
  EACData: [],
  EBCData: [],
  EPFData: [],
  EVData: [],
  PPIData: [],
  workerTaskHoursData: [],
  reserveSummaryData: [],
  reserveHours: [],
  taskHoursSummary: [],
 taskHoursSummaryNew: [],
  workerTaskHoursSummary: [],
  lostHoursSummary: [],
  lostHours: [],
  isFetching: false,
  error: ""
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [DATA_REQUESTED]: state => ({
      ...state,
      isFetching: true
    }),
    [CHART_DATA_RECEIVED]: (state, { payload }) => Object.assign({}, state, {
        ...state,
        defects: payload.Defects,
        projectDefects: payload.ProjectDefects,
        softwareDefects: payload.SoftwareDefects,
        hardwareDefects: payload.HardwareDefects,
        ACCData: payload.ACCData,
        EACData: payload.EACData,
        EBCData: payload.EBCData,
        EPFData: payload.EPFData,
        EVData: payload.EVData,
        PPIData: payload.PPIData,
        isFetching: false
    }),
    [LABOR_SUMMARY_RECEIVED]: (state, { payload }) => Object.assign({}, state, {
        ...state,
        reserveHours: payload.ReserveHours,
        reserveSummaryData: payload.ReserveSummaryData ,
        workerTaskHoursData: payload.WorkerTaskHoursData,
      taskHoursSummary: payload.TaskHoursSummary,
                taskHoursSummaryNew: payload.TaskHoursSummaryNew,

        workerTaskHoursSummary: payload.WorkerTaskHoursSummary,
        lostHours: payload.LostHours,
        lostHoursSummary: payload.LostHoursSummary,
        isFetching: false
      }),
    [HISTORY_RECEIVED]: (state, { payload }) => ({
      ...state,
      history: payload
    }),
    [INTERIM_HISTORY_RECEIVED]: (state, { payload }) => {
      const currentHistory = { ...state.history };
      currentHistory.push(payload)
      return {
        ...state,
        history: currentHistory
      };
    },
    [REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    })
  },
  initialState
);
