import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Label,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  Input,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import  BudgetContainer from "routes/Budget/components/BudgetContainer";
import {
  toggleTourComponentOpen,
  toggleTourComponentClosed
} from "app/redux/thunks";
import { openModal } from "redux/Modal/ModalModule";
import {
  REFERENCE_MODAL,
  HELP_DOCUMENTATION_MODAL
} from "routes/Modal/ModalTypes";
import { fetchAssignments } from "redux/Project/thunks";
import CardHeaderBold from "components/styled/CardHeaderBold";

import StaffingContainer from "./components/StaffingContainer";
import ResourceGrid from "routes/Resource/components/ResourceGrid";
import StaffingInfo from "./components/StaffingInfo";
import AvailableResourceList from "routes/Resource/components/AvailableResourceList";
import { acceptWeeklyStaffingPlan } from "redux/Project/thunks";
import GanttChartContainer from "routes/Report/GanttChartContainer";
import NetworkDiagramContainer from "routes/Report/NetworkDiagramContainer";

const Assignment = (props) => {
  const [cardBudgetOpen, setCardBudgetOpen] = useState(false);
  const [minStaffingHeight, setMinStaffingHeight] = useState("300px");

  const [activeTab, setActiveTab] = useState("resource-plan");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const {
    runId,
    runStarted,
    acceptWeeklyStaffingPlan,
    displayProjectWeek
  } = props;
  useEffect(() => {
    if (runId !== 0) {
      if (runStarted === false) {
        acceptWeeklyStaffingPlan(runId, displayProjectWeek);
      }
    }
    // Remove the Info and Budget Item toggle links from the PageHeader
    const PageHeaderEl = document.querySelector(".component-PageHeader");
    if (
      PageHeaderEl &&
      PageHeaderEl.classList.contains("route-PlanningChild")
    ) {
      PageHeaderEl.classList.remove("route-PlanningChild");
    }
    document.body.classList.add("page-execution");
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [runId, runStarted, acceptWeeklyStaffingPlan, displayProjectWeek]);

  const toggleBudget = () => {
    setCardBudgetOpen(!cardBudgetOpen);
  };

  const startTheTour = () => {
		const config = [
			{
				selector: "[tourstop='1']",
				content:
					"The Staffing Info panel provides detailed information on what we need to understand and do in Staffing. It provides specific information on the staffing decisions we need to make each week.",
				position: "bottom",
				style: {
					padding: "30px"
				}
			},
			{
				selector: "[tourstop='2']",
				content:
					"Several tools are available here to help you run your project.",
				style: {
					padding: "30px"
				}
      },
      {
				selector: "[tourstop='4']",
				content:
					"Also included is a list of available resources in case you need to add new workers to your project.",
				style: {
					padding: "30px"
				}
      },
      	{
				selector: "[tourstop='3']",
				content:
					"A copy of your Resource Plan is provided on the Staffing screen to facilitate required resource changes due to late or early task starts, the addition of new tasks, or a need to add a resource.",
				style: {
					padding: "30px"
				}
      },
			{
				selector:  "[tourstop='actionHeader']",// "#tooltip-shiftRight-0",
				content:
					"Using available buttons in the Actions column in the Resource Plan, you may be able to shift the plan for each resource either forward or backward one week, as well as remove the resource from the plan/project completely.",
				style: {
					padding: "30px"
				}
			},
			{
				selector: "#addResBtn",
				content:
					"If you need to add a resource to the plan, add them by clicking here.",
				style: {
					padding: "30px"
				}
			},
			{
				selector: "#card-HelpfulReports",
				content:
					"Confirm the currently active tasks. Tasks with all predecessor tasks completed are the only active tasks. The Network Diagram delineates completed tasks with a big X inside the task box. The Gantt Chart indicates a completed task with a filled solid middle status bar – no white left in the middle bar. Task dependencies are not always clear on the Gantt Chart, so use the Network Diagram to confirm that a task is active (all predecessor tasks are completed).",
				style: {
					padding: "30px",
					marginBottom: "60px"
				}
			},
			{
				selector: "[tourstop='6']",
				content:
					"Based on task status and available resources, you can override the starting task assignment (Priority 1) for a resource in the current week (highlighted in green) here. You can also release resources and request overtime hours here. ",
				style: {
					padding: "30px"
				}
			},
			{
				selector: "[tourstop='checkstatus']",
				content:
					"Compare your staffing decisions and resource plan for differences here.",
				style: {
					padding: "30px"
				}
			}
		];
		props.startTour(true, config);
	};

	const stopTheTour = () => {
		props.closeTour();
		 toggle("resource-plan");
	};







  return (
    <div className="animated fadeIn">
      <Row>
        <Col tourstop="1">
          <StaffingInfo />
        </Col>
      </Row>
      <Row style={{ minHeight: minStaffingHeight }}>
        <Col lg={12}>
          <Card >
            <CardHeaderBold>
              <>
                <span>Weekly Staffing Decisions -{" "}
                <strong>{`Week: ${props.displayProjectWeek}`}</strong></span>
                {/* <div className="float-left">
                <Button
                  color="link"
                  onClick={startTheTour}
                >
                    Take the tour
                </Button>
                  </div> */}
              </>
            </CardHeaderBold>
            <CardBody className="mh-0">
  
     <p>
    The table below contains your project staffing actions and task assignments/priorities for the current week. It is based on current active tasks, current resource task assignments and current resource plan. Review, and if required, update task assignments, request overtime, or remove resources from the project before executing the next week. <span style={{ fontWeight: "bold", color: "red" }}>See Staffing Info panel for more information</span>. Use the View Staffing Issues panel tab to see assignment warnings you may want to resolve before running the week.
             </p>
            </CardBody>
          </Card>

          <StaffingContainer
            style={{ minHeight: "800px" }}
            data={props.workerAssignmentList}
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "available-resources"
                })}
                onClick={() => {
                  toggle("available-resources");
                }}
              >
                Available Resources
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent tourstop="tabs" activeTab={activeTab}>
            <TabPane tabId="resource-plan" >
              <ResourceGrid noHideToggle  />
            </TabPane>
            <TabPane tabId="available-resources">
              <AvailableResourceList noHideToggle  />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeaderBold>
              Budget
              <Label className="switch switch-default switch-primary">
                <Input
                  type="checkbox"
                  className="switch-input"
                  onClick={() => toggleBudget()}
                  defaultChecked={cardBudgetOpen}
                />
                <span className="switch-label" />
                <span className="switch-handle" />
              </Label>
            </CardHeaderBold>
            <Collapse isOpen={cardBudgetOpen}>
              <CardBody>
                <BudgetContainer />
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  runStarted: state.execution.workweek.runStarted,
  workerAssignmentList: state.project.workerAssignmentList,
  taskStatusList: state.project.taskStatusList,
  runId: state.project.runId,
  maxWeeks: state.project.maxWeeks,
  currentProjWeek: state.project.currentProjectWeek,
  displayProjectWeek: state.project.displayProjectWeek,
  projectedFinishWeek: state.project.projectedFinishWeek,
  tasks: state.project.tasks,
  resourceActionTasks: state.project.resourceActionTasks
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssignments: (runId) => dispatch(fetchAssignments(runId)),
  acceptWeeklyStaffingPlan: (runId, weekNumber) =>
    dispatch(acceptWeeklyStaffingPlan(runId, weekNumber)),
  startTour: (toggleState, tc) =>
    dispatch(toggleTourComponentOpen(toggleState, tc)),
  stopTour: () => dispatch(toggleTourComponentClosed()),
  showReferences: (selectedKey) =>
    dispatch(openModal(REFERENCE_MODAL, { selectedKey }, "modal-wide")),
  showHelp: (selectedKey) =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, { selectedKey }, "modal-wide"))
});

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
