import React from "react";
import { connect } from "react-redux";
import { saveTraining } from "redux/Training/thunks";
import {
  getProjectedLastWeek,
  getWorkerNameAndImgPng
} from "redux/Project/selectors";
import _debounce from "lodash.debounce";

import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  FormText,
  Button
} from "reactstrap";
import { update } from "lodash";

class AddTraining extends React.Component {
  state = {
      courseAlertText: "Please choose training",
      courseValidationState: "success",
      trainingItem: this.props.trainingItem,
      workerList: [],
      projectWeekValidationState: "success",
      projectWeekAlertText: "Please choose week for training ",
      resourceValidationState: "success",
      resourceAlertText: "Please choose worker to attend training"
    };

  componentDidMount() {
    let workerList = [];
    if (this.props.trainingItem.Id === 0) {
      workerList = this.props.resources.slice(0);
    } else if (this.props.trainingItem.TeamTraining === true) {
      workerList.push({
        workerId: 0,
        workerName: "TEAM",
        workerPng: "Team.png"
      });
    } else {
      workerList = this.props.resources.slice(0);
    }
    this.setState({
      workerList: workerList
    })
  }

  handleCourseChange = event => {
    event.preventDefault();

    // find the class name, have to force event.target.value to a Number
    // it seems to return a string resulting in no matches
    const updated = Object.assign({}, this.state.trainingItem);
    const thisTraining = this.props.trainingList.find(
      c => c.ClassId === Number(event.target.value)
    );
    updated.TrainingDays = thisTraining.TrainingDays;
    updated.ClassName = thisTraining.ClassName;
    updated.ClassId = thisTraining.ClassId;
    update.ClassStartDay = thisTraining.ClassStartDay;
    updated.TeamTraining = thisTraining.TrainingType === 1;

    let workerList = [];
    if (thisTraining.TrainingType === 1) {
      updated.WorkerName = "TEAM";
      updated.WorkerId = 0;
      workerList.push({
        workerId: 0,
        workerName: "TEAM",
        workerPng: "Team.png"
      });
    } else {
      workerList = [...this.props.resources];
    }

    this.setState({
      trainingItem: updated,
      workerList: [...workerList]
    });
  };

  handleProjectWeekChange = event => {
    const updated = this.state.trainingItem;
    updated.WeekNumber = Number(event.target.value);
    this.setState({
      trainingItem: updated
    });
  };
  handleResourceChange = event => {
    const updated = Object.assign({}, this.state.trainingItem);

    updated.WorkerName = this.state.workerList.find(
      c => c.workerId === Number(event.target.value)
    ).workerName;
    updated.WorkerId = Number(event.target.value);
    this.setState({
      trainingItem: updated
    });
  };

  getcourseValidationState= () =>{
    return this.state.courseValidationState;
  }

  getresourceValidationState= () => {
    return this.state.resourceValidationState;
  }

  getCostValidationState = () => {
    return this.state.costValidationState;
  }

  getprojectWeekValidationState = () =>{
    return this.state.projectWeekValidationState;
  }

  renderWeekOptions = () => {
    const weekOptions = [];
    for (let i = 1; i <= this.props.projectedLastWeek; i++) {
      weekOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return weekOptions;
  }

  doSaveChecksThenSave = () => {
    const thisTrainingItem = Object.assign({}, this.state.trainingItem);
    if (thisTrainingItem.WorkerId === 0) {
      this.TrainingItem.WorkerId = this.props.resources[0].workerId;
      this.TrainingItem.WorkerName = this.props.resources[0].workerName;
    }
    if (thisTrainingItem.ClassId === 0) {
      this.TrainingItem.ClassId = this.props.trainingList[0].ClassId;
      this.TrainingItem.ClassName = this.props.trainingList[0].ClassName;
      this.TrainingItem.ClassStartDay = this.props.trainingList[0].ClassStartDay;
    }

    if (thisTrainingItem.WeekNumber === 0) {
      this.TrainingItem.WeekNumber = 1;
    }
  }

  handleSubmit = _debounce(() => {
    this.props.saveTraining(this.state.trainingItem);
  }, 75);

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <FormGroup>
              <Label form="trainingItem">Course</Label>
              <Input
                id="trainingItem"
                type="select"
                value={this.state.trainingItem.ClassId}
                placeholder="course"
                onChange={(event) => this.handleCourseChange(event)}
              >
                {this.props.trainingList.map(w => (
                  <option value={w.ClassId} key={w.ClassName}>
                    {w.ClassName}
                  </option>
                ))}
              </Input>
              <FormFeedback>{this.getcourseValidationState()}</FormFeedback>
              <FormText>{this.state.courseAlertText}</FormText>
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormGroup>
              <Label for="projWeek">Project Week:</Label>

              <Input
                type="select"
                value={this.state.trainingItem.WeekNumber}
                placeholder="Project Week#"
                onChange={(event) => this.handleProjectWeekChange(event)}
                required
              >
                {this.renderWeekOptions()}
              </Input>

              <FormFeedback>{this.getprojectWeekValidationState()}</FormFeedback>
              <FormText>{this.state.projectWeekAlertText}</FormText>
            </FormGroup>
          </div>
        </div>
        <div className="row row-Last">
          <div className="col-lg-12">
            <FormGroup>
              <Label for="worker">Resource:</Label>

              < Input
                type="select"
                disabled={this.state.trainingItem.workerId === 0}
                value={this.state.trainingItem.WorkerId}
                placeholder="Resource"
                onChange={(event) => this.handleResourceChange(event)}
                required
              >
                {this.state.workerList.map(w => (
                  <option value={w.workerId} key={w.workerId}>
                    {w.workerName}
                  </option>
                ))}
              </Input>

              <FormFeedback />
              <FormText>{this.state.resourceAlertText}</FormText>
            </FormGroup>
            <Button
              color="primary"
              className="btn btn-primary"
              type="button"
              onClick={(event) => this.handleSubmit(event)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  trainings: state.planning.training.trainings,
  trainingList: state.project.trainingList,
  runId: state.project.runId,
  projectedLastWeek: getProjectedLastWeek(state),
  resources: getWorkerNameAndImgPng(state)
});

const mapDispatchToProps = dispatch => ({
  saveTraining: data => dispatch(saveTraining(data))
});

export const AddTrainingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTraining);
