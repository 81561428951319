import { createAction, handleActions } from "redux-actions";
import { addSingleAlert } from "redux/Notification/NotificationModule";

// ------------------------------------
// Constants
// ------------------------------------
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// ------------------------------------
// Actions
// ------------------------------------
export const open = createAction(OPEN_MODAL, payload => payload);
export const closeModal = createAction(CLOSE_MODAL);

// ------------------------------------
// Thunk
// ------------------------------------
//
export const openModal = (modal, params, size) => (dispatch, getState) => {
  try {
    const payload = {
      modal,
      payload: params,
      size
    };
    dispatch(open(payload));
  } catch (e) {
    const alert = {
      id: 20,
      type: "danger",
      event: "Modal Open Error",
      headline: "Error opening modal",
      message: `  open Modal : Error() : ${e.toString()}`
    };
    const notificationData = {
      worker: "thunk",
      week: 0,
      info: `  open Modal : Error() : ${e.toString()}`
    };
    dispatch(addSingleAlert(alert, notificationData, 5000, true));
  }
};

const initialState = {
  activeModal: "",
  payload: {},
  size: "",
  open: false
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [OPEN_MODAL]: (state, { payload }) => ({
      ...state,
      activeModal: payload.modal,
      open: true,
      payload: payload.payload,
      size: payload.size
    }),
    [CLOSE_MODAL]: (state, { payload }) => ({
      ...initialState,
      open: false
    }),
    default: state => state
  },
  initialState
);
