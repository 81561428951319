import React from "react";

import imageURLs from "./images/imageURLs.js";

const HistoryHelp = () => (
  <div>
    <h3>History Info:</h3>
    <h4>Context and Resources for History:</h4>

    <p>
      The history window provides a documented history of the decisions and results for each executed week of the project. Select a specific week via the menu. Weekly reports are organized by the days of the week and include a weekly summary of current project and resource performance at the end of the report.

    </p>

    <h4>The Weekly Summary Reports include:</h4>
    <ul>
      <li>
      <p>Weekly Resource Hours – total hours worked, and total hours worked on each task for each resource on the project this week, and worker effectiveness. Use worker effectiveness as a measure of individual productivity and watch and manage positive and negative trends from week to week. Total hours do not include hours spent in meetings or other non-task related work time. Additional hours worked (beyond the typical 40-hour work week) are noted as overtime hours in this report.

      </p>
<img
  style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

  src={imageURLs.ReportsWeeklyResourceHours}
  alt="report"
/>
      </li>
      <li>
      <p>Worker Availability Report – provides information on resources that have been requested to join the project</p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.ReportsWorkerAvailabilityReport}
          alt="report"
        />
      </li>
      <li>
        <p>Initial Task Assignment – provides a snapshot of the current and near future task assignments for active and staffed resources and requested
        overtime before the start of the current execution week.
        </p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.ReportsInitialTaskAssignment}
          alt="report"
        />

      </li>
      <li>
        <p>Task Hours Report – total hours worked for each task for all resources</p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.ReportTaskHoursReport}
          alt="report"
        />

      </li>
      <li>
        <p>Task Status Report – percentage of task completed as of the end of this week</p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.ReportTaskStatusReport}
          alt="report"
        />
      </li>
      <li>
        <p>Project Status Report – project % complete, actual cumulative cost, planned value, and earned value as of the end of the week</p>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

          src={imageURLs.ReportProjectStatusReport}
          alt="report"
        />

      </li>
    </ul>
  </div>
);

export default HistoryHelp;
