import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
const imgUrl = require("static/images/Org-Chart_new.jpg").default;

export const OrgChartBis = (productId) => {
  return (
    <>
    <div className="organisation-chart style-background-components">
    <img src={imgUrl} alt="orgchart"  />
       </div>
    </>
  );
};
