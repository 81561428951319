// ------------------------------------
// Constants
// ------------------------------------
export const PROCESS_EOD = "PROCESS_EOD";
export const PROCESS_EOW = "PROCESS_EOW";
export const PROCESS_EOP = "PROCESS_EOP";
export const PROCESS_EOS = "PROCESS_EOS";
export const RESET_WORKWEEK = "RESET_WORKWEEK";
export const PROCESS_CONDITION = "PROCESS_CONDITION";
export const PROCESS_CONDITION_COMPLETE = "PROCESS_CONDITION_COMPLETE";
export const PROCESS_REASSIGN = "PROCESS_REASSIGN";
export const PROCESS_REASSIGN_COMPLETE = "PROCESS_REASSIGN_COMPLETE";
export const PLAY_SCRIPT = "PLAY_SCRIPT";
export const REQUESTING_DATA = "REQUESTING_DATA";
export const HIDE_CONDITION_PANEL = "HIDE_CONDITION_PANEL";
export const WORKWEEK_ERROR = "WORKWEEK_ERROR";
export const SET_CONDITION_RESPONSE = "SET_CONDITION_RESPONSE";
export const RECEIVE_PRESENTATION_RUNDAY = "RECEIVE_PRESENTATION_RUNDAY";
export const PROCESS_STAFFING_PROBLEMS = "PROCESS_STAFFING_PROBLEMS";
export const RUN_WEEK_STARTED = "RUN_WEEK_STARTED";
export const CANSTUDENTRUN_SUCCESS = "CANSTUDENTRUN_SUCCESS";
export const CANSTUDENTRUN_ERROR = "CANSTUDENTRUN_ERROR";
export const RECEIVE_CONDITIONS = "RECEIVE_CONDITIONS";
export const ENABLE_CONDITIONS_SUCCESS = "ENABLE_CONDITIONS_SUCCESS";
export const RESET_CONDITIONS_SUCCESS = "RESET_CONDITIONS_SUCCESS";
export const TOGGLE_AUTORUN = "TOGGLE_AUTORUN";
export const SET_AUTORUN_RESPONSETYPE = "SET_AUTORUN_RESPONSETYPE";
