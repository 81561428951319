import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Button, ButtonGroup, ButtonToolbar } from "reactstrap";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import CardHeaderBold from "components/styled/CardHeaderBold";
import HistoryContent from "./components/History/HistoryContent";
import InfoButton from "components/Info/InfoButton";
import { fetchHistoryData } from "redux/Report/thunks";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

class HistoryContainer extends React.Component {
  state = {
    weeks: [],
    currentWeek: 1
  };

  async componentDidMount() {
    if (this.props.history.length < 1 && this.props.currentProjectWeek > 1) {
      await this.props.fetchHistoryData(this.props.runId);
    }
    if (this.props.history.length > 0) {
      this.setState({
        currentWeek: this.props.history.length
      });
    }
    let weeks = [];

    if (this.props.history.length > 0) {
      for (let i = 1; i <= this.props.history.length; i += 1) {
        weeks.push(i);
      }
    }
    this.setState({
      weeks: weeks
    });
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.length !== prevState.weeks.length) {
      let weeks = [];

      if (nextProps.history.length > 0) {
        for (let i = 1; i <= nextProps.history.length; i += 1) {
          weeks.push(i);
        }
      }

      return { weeks: weeks, currentWeek: nextProps.history.length };
    } else return null; // Triggers no change in the state
  }

  changeHistoryWeek = (item) => {
    let val = parseInt(item, 10);
    this.setState({
      currentWeek: val
    });
  };

  render() {
    return (
      <Card className="component-History" id={`card-history-${
            this.props.currentProjectWeek ? this.props.currentProjectWeek : 0
          }`}>
        <CardHeaderBold>
          <span>Project History Timeline</span>
          <ButtonToolbar>
            <InfoButton selectedKey="history" />

            <ReactToPrint
              trigger={() => (
                <Button className="float-right" color="link">
                  <FontAwesomeIcon size="2x" icon={faFilePdf} />
                </Button>
              )}
              content={() => this.HistoryRef}
            />
          </ButtonToolbar>
        </CardHeaderBold>
        <CardBody
          className="mh-0"
          id={`history-${
            this.props.currentProjectWeek ? this.props.currentProjectWeek : 0
          }`}
        >
          <Card>
            <CardHeaderBold>
              <ButtonToolbar>
                <div style={{ marginTop: "5px", marginRight: "5px" }}>
                  Week:
                </div>
                {this.state.weeks.map((item) => (
                  <ButtonGroup
                    key={item ? `grp_${item.toString()}` : "key"}
                    size="sm"
                    name="weeks"
                    vertical={false}
                  >
                    <Button
                      style={{
                        margin: "5px",
                        borderRadius: "70%",
                        width: "30px",
                        height: "30px"
                      }}
                      color="primary"
                      name="weekGroup"
                      outline={item === this.state.currentWeek ? false : true}
                      key={`btn_${item.toString()}`}
                      value={item}
                      onClick={() => {
                        this.setState({ currentWeek: item });
                      }}
                    >
                      {item}
                    </Button>
                  </ButtonGroup>
                ))}
              </ButtonToolbar>
            </CardHeaderBold>

            <CardBody className="mh-0">
              <PrintDiv
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                id={`h${this.state.currentWeek.toString()}`}
                ref={(el) => {
                  this.HistoryRef = el;
                }}
              >
                {/* {this.props.currentProjectWeek < 1  ? (
              <p>No History ---- available yet</p>
            ) : ( */}
                <HistoryContent
                productId={this.props.productId}
                  id={this.state.currentWeek}
                  history={this.props.history}
                  selectedReport={this.props.history.find(
                    (h) => h.Week === this.state.currentWeek
                  )}
                  week={this.state.currentWeek}
                />
                {/* )} */}
              </PrintDiv>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    runId: state.project.runId,
    currentProjectWeek: state.project.currentProjectWeek,
    history: state.report.history,
    currentDayOfWeek: state.project.projectStatus.DayNumber,
    productId: state.auth.productId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHistoryData: (runId) => dispatch(fetchHistoryData(runId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer);
