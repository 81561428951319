// ------------------------------------
// Constants
// ------------------------------------
export const ERROR_ON_FETCH = "ERROR_ON_FETCH";
export const RECEIVE_INTERACTIONS = "RECEIVE_INTERACTIONS";

export const DELETE_CONFERENCE_SUCCESS = "DELETE_CONFERENCE_SUCCESS";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_RECOGNITION_SUCCESS = "DELETE_RECOGNITION_SUCCESS";
export const DELETE_STAKEHOLDER_SUCCESS = "DELETE_STAKEHOLDER_SUCCESS";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const DELETE_INTERACTION_ERROR = "DELETE_INTERACTION_ERROR";

export const UPDATE_CONFERENCE_SUCCESS = "UPDATE_CONFERENCE_SUCCESS";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_RECOGNITION_SUCCESS = "UPDATE_RECOGNITION_SUCCESS";
export const UPDATE_STAKEHOLDER_SUCCESS = "UPDATE_STAKEHOLDER_SUCCESS";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_INTERACTION_ERROR = "UPDATE_INTERACTION_ERROR";

export const INSERT_CONFERENCE_SUCCESS = "INSERT_CONFERENCE_SUCCESS";
export const INSERT_MEETING_SUCCESS = "INSERT_MEETING_SUCCESS";
export const INSERT_RECOGNITION_SUCCESS = "INSERT_RECOGNITION_SUCCESS";
export const INSERT_STAKEHOLDER_SUCCESS = "INSERT_STAKEHOLDER_SUCCESS";
export const INSERT_TRAINING_SUCCESS = "INSERT_TRAINING_SUCCESS";
export const INSERT_INTERACTION_ERROR = "INSERT_INTERACTION_ERROR";

export const INSERT_STATUSREPORTS_SUCCESS = "INSERT_STATUSREPORTS_SUCCESS";
