import styled from "styled-components";

const DetailsList = styled.ul`
  list-style: none;
  padding-left: 0;
  column-count: 2;
  height: 150px;

  li {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1em;
    margin-bottom: 0;
    line-height: 1rem;
    margin-right: 0.5rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }
`;

export { DetailsList };
