import React from "react";
import accounting from "accounting";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import { Card } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%s%v"
};

const RawDataTable = (props) => {  
  let iterKey = 1;
  return (
    <Card>
      <CardHeaderBold>Raw Data</CardHeaderBold>
      <NoMaxCardBody>
         <StyledTable bordered hover>
                  <thead>
                     <tr>
              <th>Resource</th>
              <th>Project Week</th>
              <th>Project Day</th>
                       <th>Task</th>
                       <th>Task Name</th>
                       <th>Hourly Rate</th>
                       <th>Regular hours</th>
                       <th>Overtime hours</th>
                       <th>Total Cost</th>
                     </tr>
                   </thead>
                   <tbody>
                    {props.data.filter(d => d.runDay > 0).map((row) => (
                      <tr key={(iterKey += 1)}>
                        <td>{row.workerName}</td>
                        <td className="text-center">{row.weekNumber}</td>
                        <td className="text-center">{row.runDay}</td>
                        <td className="text-center">{row.taskId}</td>
                        <td>{row.taskName}</td>
                        <td
                          className="text-right"
                          style={{ marginRight: "10px" }}
                        >
                          {accounting.formatMoney(row.hourlyRate, options)}
                        </td>
                        <td className="text-center">
                          {row.accumulatedRegularHours}
                        </td>
                        <td className="text-center">
                          {row.accumulatedOvertimeHours}
                        </td>
                        <td
                          className="text-right"
                          style={{ marginRight: "10px" }}
                        >
                          {accounting.formatMoney(
                            row.accumulatedRegularHours * row.hourlyRate,
                            options
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default RawDataTable;
