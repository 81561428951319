import React from "react";
import { HistoryWeek } from "./HistoryWeek";
import styles from "routes/Report/styles/Reports.scss";

const HistoryContent = props => {
  if (props.selectedReport === undefined) {
    return (
      <div>
        <p>No History available yet</p>
      </div>
    );
  }
  return (
    <div className={styles.HolyGrail}>
      <div className={styles.HolyGrailBody}>
        <main className={styles.HolyGrailContent}>
          <HistoryWeek
          productId={props.productId}
            key={props.week}
            weekNumber={props.selectedReport["Week"]}
            componentList={props.selectedReport["ComponentList"]}
          />
        </main>
      </div>
    </div>
  );
};

export default HistoryContent;
