import React from "react";
import { Progress } from "reactstrap";



export class EffectivenessImage extends React.Component {
  render() {
    let effectiveness = parseInt(this.props.effectiveness, 10); // Out of 100%

    let progressbarStyle;
    if (effectiveness >= 75) {
      progressbarStyle = "success";
    } else if (effectiveness < 75 && effectiveness >= 45) {
      progressbarStyle = "info";
    } else {
      progressbarStyle = "danger";
    }

    return (
      <div>
        <ProgressBar
          bgcolor={"black"}
          completed={effectiveness}
        />
      </div>
    );
  }
}
const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 22,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 20,
    margin: 0
  }
  
  let width = `${completed}%`

  //don't let the bar go past the right border
  if (completed > 100) {
    width = "100%";
  }

  //Minimum width to allow text visibility
  if (completed < 15) {
    width = "15%";
  }

  const fillerStyles = {
    height: '100%',
    width: width,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    paddingRight: "3px",
    textAlign: 'right'
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};
