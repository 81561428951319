import isEmpty from "lodash/isEmpty";
// conferenceValidation
//
// Worker cannot have  conference overlap with a meeting, all day individual training, all day team training if worker is only one on project, another conference, all day stakeholder action

export const shouldAcceptConference = (state, numberOfWorkers, conference) => {
  let keys = [];
  if (conference !== null && conference !== undefined && !isEmpty(conference)) {
    const st = new Date(conference.StartDate);

    const ed = new Date(conference.EndDate);

    // const st = moment(new Date(conference.StartDate));
    // const ed = moment(new Date(conference.EndDate));
    keys = Object.keys(state.conferenceInteractions);

    for (let i = 0; i < keys.length; i++) {
      if (
        state.conferenceInteractions[i].DayOfWeek === conference.DayOfWeek &&
        state.conferenceInteractions[i].Id.toString() !==
          conference.Id.toString()
      ) {
        const st2 = new Date(state.conferenceInteractions[i].StartDate);
        const ed2 = new Date(state.conferenceInteractions[i].EndDate);
        if (
          (st.getTime() < ed2.getTime() && st.getTime() > st2.getTime()) ||
          (ed.getTime() < ed2.getTime() && ed.getTime() > st2.getTime()) ||
          st.getTime() === st2.getTime() ||
          ed.getTime() === ed2.getTime()
        ) {
          const alertMessage = `You already have a conference scheduled with : ${state.conferenceInteractions[i].WorkerName} during that time.`;
          const result = {
            status: "conflict",
            message: alertMessage
          };
          return result;
        }
      }
    }
 const matchingIndvTraining = state.trainingInteractions.filter(
      c =>
        c.DayOfWeek === conference.DayOfWeek &&
        c.WorkerName === conference.WorkerName
    );
    if (matchingIndvTraining.length > 0) {
      return {
        status: "CONFLICT",
        message: `Worker : ${conference.WorkerName} already has an individual training on that day.`
      };
    }

    const matchingTeamTraining = state.trainingInteractions.filter(
      c => c.DayOfWeek === conference.DayOfWeek && c.WorkerName === "Everyone"
    );
    if (matchingTeamTraining.length > 0) {
      return {
        status: "CONFLICT",
        message: `Worker : ${conference.WorkerName} already has a team training on that day.`
      };
    }

    const matchingStakeholderAction = state.stakeholderInteractions.filter(
      c =>
        c.DayOfWeek === conference.DayOfWeek &&
        c.WorkerName === conference.WorkerName
    );
    if (matchingStakeholderAction.length > 0) {
      return {
        status: "CONFLICT",
        message: `Worker : ${conference.WorkerName} already has a stakeholder appointment on that day.`
      };
    }
    keys = Object.keys(state.meetingInteractions);

    for (let i = 0; i < keys.length; i++) {
      if (state.meetingInteractions[i].DayOfWeek === conference.DayOfWeek) {
        const st2 = new Date(state.meetingInteractions[i].StartDate);
        const ed2 = new Date(state.meetingInteractions[i].EndDate);
        if (
          (st.getTime() < ed2.getTime() && st.getTime() > st2.getTime()) ||
          (ed.getTime() < ed2.getTime() && ed.getTime() > st2.getTime()) ||
          st.getTime() === st2.getTime() ||
          ed.getTime() === ed2.getTime()
        ) {
          const alertMessage = "There is already a meeting at that time.";

          const result = {
            status: "conflict",
            message: alertMessage
          };
          return result;
        }
      }
    }

   
    const result = {
      status: "ok",
      message: ""
    };
    return result;
  }
  const result = {
    status: "empty",
    message: ""
  };
  return result;
};

// Meeting validation
//
// Worker cannot have  meeting overlap with meeting, conference, individual training, all day team training if worker is only one on project, stakeholder action <- needs more clarification

export const shouldAcceptMeeting = (state, numberOfWorkers, meeting) => {
  let keys = [];

  if (meeting !== null && meeting !== undefined && !isEmpty(meeting)) {
    const st = new Date(meeting.StartDate); // .setDate(meeting.StartDate.getDate() + 1))

    const ed = new Date(meeting.EndDate); // .setDate(meeting.EndDate.getDate() + 1))

    // check conference conflict
    //
    keys = Object.keys(state.conferenceInteractions);

    for (let i = 0; i < keys.length; i++) {
      if (state.conferenceInteractions[i].DayOfWeek === meeting.DayOfWeek) {
        const st2 = new Date(state.conferenceInteractions[i].StartDate);

        const ed2 = new Date(state.conferenceInteractions[i].EndDate);

        if (
          (st.getTime() < ed2.getTime() && st.getTime() > st2.getTime()) ||
          (ed.getTime() < ed2.getTime() && ed.getTime() > st2.getTime()) ||
          st.getTime() === st2.getTime() ||
          ed.getTime() === ed2.getTime()
        ) {
          const alertMessage = `Worker : ${state.conferenceInteractions[i].WorkerName} has a conflicting conference.`;
          const result = {
            status: "conflict",
            message: alertMessage
          };
          return result;
        }
      }
    }

    keys = Object.keys(state.meetingInteractions);

    for (let i = 0; i < keys.length; i++) {
      if (
        state.meetingInteractions[i].DayOfWeek === meeting.DayOfWeek &&
        state.meetingInteractions[i].Id !== meeting.Id
      ) {
        const st2 = new Date(state.meetingInteractions[i].StartDate);

        const ed2 = new Date(state.meetingInteractions[i].EndDate);
        if (
          (st.getTime() < ed2.getTime() && st.getTime() > st2.getTime()) ||
          (ed.getTime() < ed2.getTime() && ed.getTime() > st2.getTime()) ||
          st.getTime() === st2.getTime() ||
          ed.getTime() === ed2.getTime()
        ) {
          const alertMessage = "There is already a meeting at that time.";

          const result = {
            status: "conflict",
            message: alertMessage
          };
          return result;
        }
      }
    }

    const matchingTeamTraining = state.trainingInteractions.filter(
      c => c.DayOfWeek === meeting.DayOfWeek && c.WorkerName === "Everyone"
    );
    if (matchingTeamTraining.length > 0) {
      return {
        status: "CONFLICT",
        message: "There is already a team training on that day."
      };
    }

    // const matchingStakeholderAction = state.stakeholderInteractions.filter(
    //   c => c.DayOfWeek === meeting.DayOfWeek && c.WorkerId !== 0
    // );
    // if (matchingStakeholderAction.length > 0) {
    //   return {
    //     status: "CONFLICT",
    //     message: `Worker : ${matchingStakeholderAction[0].WorkerName} already has a stakeholder appointment on that day.`
    //   };
    // }
    const result = {
      status: "ok",
      message: ""
    };
    return result;
  }
  const result = {
    status: "empty",
    message: ""
  };
  return result;
};

// Individual Training validation
//
// Worker cannot have training overlap meeting, conference, another training, stakeholder action
// individual training cannot overlap individual training for same worker or with team training

export const shouldAcceptIndvTraining = (state, numberOfWorkers, training) => {
  if (training !== null && training !== undefined && !isEmpty(training)) {
    if (
      state.conferenceInteractions &&
      state.conferenceInteractions.length > 0
    ) {
      const matchingConference = state.conferenceInteractions.filter(
        c =>
          c.DayOfWeek === training.DayOfWeek &&
          c.WorkerName === training.WorkerName
      );

      if (matchingConference.length > 0) {
        const alertMessage = `Worker : ${training.WorkerName} has a conflicting conference.`;
        const result = {
          status: "conflict",
          message: alertMessage
        };
        return result;
        //  addSingleAlert(alert, notificationData, 40, false);
        //  return false;
      }
    }
    // ok to schedulem meetign if there is a training
    // if (state.meetingInteractions && state.meetingInteractions.length > 0) {
    //   const matchingMeeting = state.meetingInteractions.filter(
    //     c => c.DayOfWeek === training.DayOfWeek
    //   );
    //   if (matchingMeeting.length > 0) {
    //     const alertMessage = "There is already a meeting on this day.";

    //     const result = {
    //       status: "conflict",
    //       message: alertMessage
    //     };
    //     return result;
    //   }
    // }

    if (state.trainingInteractions && state.trainingInteractions.length > 0) {

      const matchingIndvTraining = state.trainingInteractions.filter(
        c =>
          c.ClassStartDay === training.ClassStartDay &&
          c.WorkerName === training.WorkerName &&
          c.WorkerId === training.WorkerId &&
          c.Id !== training.Id
      );
      if (matchingIndvTraining.length > 0) {
        return {
          status: "CONFLICT",
          message: ` Worker : ${training.WorkerName} already has another training planned.`
        };
      }

      const matchingTeamTraining = state.trainingInteractions.filter(
        c =>
          c.DayOfWeek === training.DayOfWeek &&
          ["The Team", "Everyone"].includes(c.WorkerName) &&
          c.Id !== training.Id
      );
      if (matchingTeamTraining.length > 0) {
        return {
          status: "CONFLICT",
          message: "There is already a team training on that day."
        };
      }
    }

    if (
      state.stakeholderInteractions &&
      state.stakeholderInteractions.length > 0
    ) {
      const matchingStakeholderAction = state.stakeholderInteractions.filter(
        c =>
          c.DayOfWeek === training.DayOfWeek &&
          c.WorkerName === training.WorkerName
      );
      if (matchingStakeholderAction.length > 0) {
        return {
          status: "CONFLICT",
          message: `Worker : ${matchingStakeholderAction[0].WorkerName} already has a stakeholder appointment on that day.`
        };
      }
    }
    const result = {
      status: "ok",
      message: ""
    };
    return result;
  }
  const result = {
    status: "empty",
    message: ""
  };
  return result;
};

// team training cannot overlap other training or with stakeholder customer visit
//
export const shouldAcceptTeamTraining = (state, numberOfWorkers, training) => {
 
  if (state !== null && state !== undefined && !isEmpty(state)) {
    let matchingTeamTraining = state.trainingInteractions.filter(
      c =>
        ["The Team", "Everyone"].includes(c.WorkerName) &&
        c.ClassId === state.ClassId
    );

    if (matchingTeamTraining.length > 0) {
      return {
        status: "CONFLICT",
        message: "There is already a team training on that day."
      };
    }
    let matchingIndvTraining = state.trainingInteractions.filter(
      c =>
      c.ClassStartDay === training.ClassStartDay &&
        c.ClassName !== training.ClassName
    );
    if (matchingIndvTraining.length > 0) {
      return {
        status: "CONFLICT",
        message: ` Worker : ${matchingIndvTraining[0].WorkerName} already has an individual training on that day.`
      };
    }

    let matchingStakeholderAction = state.stakeholderInteractions.filter(
      c =>
        ["Wednesday", "Thursday"].includes(c.DayOfWeek) &&
        c.WorkerName !== state.WorkerName
    );

    if (matchingStakeholderAction.length > 0) {
      return {
        status: "CONFLICT",
        message: `There is already a stakeholder appointment on that day.`
      };
    }

    if (
      state.conferenceInteractions &&
      state.conferenceInteractions.length > 0
    ) {
      let matchingConference = state.conferenceInteractions.filter(c =>
        ["Wednesday", "Thursday"].includes(c.DayOfWeek)
      );

      if (matchingConference.length > 0) {
        const alertMessage = `Worker : ${state.conferenceInteractions[0].WorkerName} has a conflicting conference.`;
        const result = {
          status: "CONFLICT",
          message: alertMessage
        };
        return result;
      }
    }

    if (
      state.meetingInteractions &&
      state.meetingInteractions.length > 0
    ) {
      const matchingMeeting = state.meetingInteractions.filter(c =>
        ["Wednesday", "Thursday"].includes(c.DayOfWeek)
      );
      if (matchingMeeting.length > 0) {
        const alertMessage = "There is already a meeting on this day.";

        const result = {
          status: "CONFLICT",
          message: alertMessage
        };
        return result;
      }
    }

    const result = {
      status: "ok",
      message: ""
    };
    return result;
  }
  const result = {
    status: "empty",
    message: ""
  };
  return result;
};

// stakeholder validation
// only one status report per stakeholder per day
// cannot have overlapping worker-involved stakeholder actions with meeting, conference, individual training, all day team training if worker is only one on project, stakeholder action
// cannot have multiple stakeholder actions per day unless 1 is a status report
// cannot have the same worker involved in multiple different stakeholder actions
export const shouldAcceptStakeholderEvent = (
  state,
  numberOfWorkers,
  stakeholderEvent
) => {
  if (state !== null && state !== undefined && !isEmpty(state)) {
    if (state.InteractionDesc === "Meeting") {
      const result = {
        status: "ok",
        message: ""
      };
      return result;
    }
    if (
      state.InteractionDesc === "Status Report" &&
      stakeholderEvent.stakeholderInteractions &&
      stakeholderEvent.stakeholderInteractions.length > 0
    ) {
      const matchingStakeholderAction = stakeholderEvent.stakeholderInteractions.filter(
        c =>
          c.DayOfWeek === state.DayOfWeek &&
          c.InteractionDesc === "Status Report" &&
          c.StakeholderId === state.StakeholderId &&
          c.Id !== state.Id
      );
      if (matchingStakeholderAction.length > 0) {
        return {
          status: "CONFLICT",
          message: `Stakeholder : ${matchingStakeholderAction[0].StakeholderDesc} already has a status report scheduled on this day.`
        };
      }
    }

    if (state.InteractionDesc !== "Status Report") {
      if (
        stakeholderEvent.stakeholderInteractions &&
        stakeholderEvent.stakeholderInteractions.length > 0
      ) {
        let matchingStakeholderAction = stakeholderEvent.stakeholderInteractions.filter(
          c =>
            c.DayOfWeek === state.DayOfWeek &&
            c.InteractionDesc !== "Status Report" &&
            c.StakeholderId === state.StakeholderId &&
            c.Id !== state.Id
        );

        if (
          stakeholderEvent.stakeholderInteractions &&
          stakeholderEvent.stakeholderInteractions.length > 0
        ) {
          matchingStakeholderAction = stakeholderEvent.stakeholderInteractions.filter(
            c =>
              c.DayOfWeek === state.DayOfWeek &&
              c.StakeholderId === state.StakeholderId
          );
          if (matchingStakeholderAction.length > 0) {
            return {
              status: "CONFLICT",
              message: `Stakeholder : ${matchingStakeholderAction[0].StakeholderDesc} already has a stakeholder appointment on that day.`
            };
          }
        }
        if (
          stakeholderEvent.stakeholderInteractions &&
          stakeholderEvent.stakeholderInteractions.length > 0
        ) {
          matchingStakeholderAction = stakeholderEvent.stakeholderInteractions.filter(
            c =>
              c.DayOfWeek === state.DayOfWeek &&
              c.WorkerName !== null &&
              state.WorkerName !== null &&
              c.WorkerName === state.WorkerName
          );
          if (matchingStakeholderAction.length > 0) {
            return {
              status: "CONFLICT",
              message: `Worker : ${matchingStakeholderAction[0].WorkerName} already has a stakeholder appointment on that day.`
            };
          }
        }
      }

      if (
        stakeholderEvent.conferenceInteractions &&
        stakeholderEvent.conferenceInteractions.length > 0
      ) {
        const matchingConference = stakeholderEvent.conferenceInteractions.filter(
          c => c.DayOfWeek === state.DayOfWeek
        );

        if (matchingConference.length > 0) {
          const alertMessage = `Worker : ${state.WorkerName} has a conflicting conference.`;
          const result = {
            status: "CONFLICT",
            message: alertMessage
          };
          return result;
          //  addSingleAlert(alert, notificationData, 40, false);
          //  return false;
        }
      }

      // if (
      //   stakeholderEvent.meetingInteractions &&
      //   stakeholderEvent.meetingInteractions.length > 0
      // ) {
      //   const matchingMeeting = stakeholderEvent.meetingInteractions.filter(
      //     c => c.DayOfWeek === state.DayOfWeek
      //   );
      //   if (matchingMeeting.length > 0) {
      //     const alertMessage = "There is already a meeting on this day.";

      //     const result = {
      //       status: "CONFLICT",
      //       message: alertMessage
      //     };
      //     return result;
      //   }
      // }

      if (
        stakeholderEvent.trainingInteractions &&
        stakeholderEvent.trainingInteractions.length > 0
      ) {
        const matchingIndvTraining = stakeholderEvent.trainingInteractions.filter(
          c =>
            c.DayOfWeek === state.DayOfWeek &&
            c.WorkerName === state.WorkerName &&
            c.WorkerId === state.WorkerId
        );
        if (matchingIndvTraining.length > 0) {
          return {
            status: "CONFLICT",
            message: ` Worker : ${state.WorkerName} already has an individual training on that day.`
          };
        }

        const matchingTeamTraining = stakeholderEvent.trainingInteractions.filter(
          c =>
            c.DayOfWeek === state.DayOfWeek &&
            ["The Team", "Everyone"].includes(c.WorkerName)
        );
        if (matchingTeamTraining.length > 0) {
          return {
            status: "CONFLICT",
            message: "There is already a team training on that day."
          };
        }
      }
    }
    const result = {
      status: "ok",
      message: ""
    };
    return result;
  }
  const result = {
    status: "empty",
    message: ""
  };
  return result;
};
