
import React, { useState } from "react";
import {
  Button,
  Popover,
  PopoverBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const ClassroomActionPopover = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const actionClicked = (e, action) => {
    e.preventDefault();
    props.actionClicked(action, props.row, e);
  };

  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <>
      <Button
        key={`Popover-button-${props.id}`}
        className="fa fa-ellipsis-h"
        color="primary"
        outline
        id={"Popover-" + props.id}
        onClick={() => toggle()}
      ></Button>
      <Popover
        key={`Popover-${props.id}`}
        placement={props.placement}
        isOpen={popoverOpen}
        target={"Popover-" + props.id}
        toggle={toggle}
      >
        <PopoverBody key={`Popover-body-${props.id}`}>
          <Dropdown
            isOpen={true}
            toggle={toggle}
            key={`Popover-dropdown-${props.id}`}
          >
            <DropdownToggle key={`Popover-dd-toggle-${props.id}`} caret>
              Choose classroom action
            </DropdownToggle>
            <DropdownMenu key={`Popover-dd-menu-${props.id}`}>
              <DropdownItem
                key={`Popover-dd-edot-${props.id}`}
                onClick={(e) => {
                  actionClicked(e, "editClassName");
                  toggle();
                }}
              >
                Edit Class Name
              </DropdownItem>
              <DropdownItem
                key={`Popover-cs-menu-${props.id}`}
                onClick={(e) => {
                  actionClicked(e, "changeStatus");
                  toggle();
                }
                }
              >
                Change Status
              </DropdownItem>
              <DropdownItem
                key={`Popover-cd-menu-${props.id}`}
                onClick={(e) => {
                  actionClicked(e, "changeDates");
                  toggle();
                }
                }
              >
                Change Dates
              </DropdownItem>
              <DropdownItem
                key={`Popover-ep-menu-${props.id}`}
                onClick={(e) => {
                  actionClicked(e, "editPrimary");
                  toggle();
                }
                }
              >
                Edit Primary Instructor
              </DropdownItem>
              {/* <DropdownItem onClick={(e) => actionClicked(e, "removeSecondary")}> Edit Secondary Instructor</DropdownItem> */}
              <DropdownItem
                key={`Popover-as-menu-${props.id}`}
                onClick={(e) => { actionClicked(e, "addSecondary");
                  toggle();
                }}
              >
                Add Secondary Instructor
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default ClassroomActionPopover;
