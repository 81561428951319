import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faClock,
  faUsers,
  faLightbulb,
  faUserCheck,
  faHistory,
  faThumbtack
} from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser from 'react-html-parser';

export class TaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: props.task.skillList
    };
  }
  componentDidMount() {
    this.setState({ skills: this.props.task.skillList });
  }

  render() {
    return (
      <div className="task-Details">
        <h2>
          <small>
            <FontAwesomeIcon icon={faThumbtack} className="icon" />
            Task: {this.props.task.TaskId}
          </small>
          {this.props.task.Name}
        </h2>
        <div className="task-Description">
          { ReactHtmlParser(this.props.task.Description) }

        </div>
        <dl className="row" >
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faClock} className="icon" />
            Duration
          </dt>
          <dd className="col-md-7">{this.props.task.Duration} days</dd>
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faTasks} className="icon" />
            Percent Complete
          </dt>
          <dd className="col-md-7">{this.props.task.PercentComplete}%</dd>
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faHistory} className="icon" />
            Predecessor List
          </dt>
          <dd className="col-md-7">{this.props.task.Predecessors}</dd>
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faUserCheck} className="icon" />
            Recommended Staffing
          </dt>
          <dd className="col-md-7">{this.props.task.totalStaffing}</dd>
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faLightbulb} className="icon" />
            Skills Required
          </dt>
          <dd className="col-md-7">
            <ul>
              {this.state.skills &&
                this.state.skills.length > 0 &&
                this.state.skills.map((item, i) => <li key={i}>{item}</li>)}
            </ul>
          </dd>
          <dt className="col-md-5">
            <FontAwesomeIcon icon={faUsers} className="icon" />
             Resources: % allocated
          </dt>
          {this.props.task.State === "tsComplete" ? (
            <dd className="col-md-7">Task is complete, no workers assigned</dd>
          ) : this.props.task.State === "tsWorking" ? (
            <dd className="col-md-7">
              <ul>
                {this.props.task &&
                    this.props.task.assignedWorkers.length > 0 &&
                    this.props.task.assignedWorkers.map((item, i) => (
                  <li key={i}>
                    {item.Name}
                    ,&nbsp;
                    {item.Title}
                    :&nbsp;&nbsp;
                    {item.Availability}%
                  </li>
                ))}
              </ul>
            </dd>
          ) : (
            <dd className="col-md-7">
              Task has not started, no workers assigned
            </dd>
          )}
        </dl>
      </div>
    );
  }
}
