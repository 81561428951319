import React from "react";

export const Conference = props => {
  const timeFrame = props.properties.duration < 2 ? "hour" : "hours";

  return (
    <div className="content">
      <h5>Conference</h5>
      <p>
        {`A conference was held with ${props.properties.worker} to discuss ${props.properties.topic}. The conference duration was ${props.properties.duration} ${timeFrame} and the feedback provided was ${props.properties.feedback}.`}
      </p>
    </div>
  );
};
