import React from "react";

export const StakeholdersBis = () => {
  return (
    <div id="StakeholderBis" className="style-background-components">
      <header>
        <h2>Stakeholder Relations</h2>
      </header>

      <p>
       Northrop Grumman Security Systems offers several options for internal project personnel to interact with the project stakeholders. On the PACCISS Project there are five stakeholders: government customer (customer), customer users (users), subcontractors, project sponsor (sponsor), and Northrop Grumman Security Systems executive management (executive management). The specific sponsor, customer and user are identified for each project in the respective Project Charter or Definition. Subcontractors are non-Northrop Grumman vendors that projects contract with to provide materials or products required to complete the project. Subcontractors are not people contracted for use on internal project tasks. Not all projects subcontract work to outside vendors. Executive Management is every manager in the Northrop Grumman Homeland Defense Division Organization Chart. Stakeholder actions directed at Executive Management will not substitute for Sponsor and Customer actions even though they may be part of Executive Management. Project management can choose any of the following actions on a weekly basis:
      </p>
      <ol>
        <li>
          <a href="#s1">Meetings</a>
        </li>
        <li>
          <a href="#s2">Status Reports</a>
        </li>
        <li>
          <a href="#s3">Visits</a>
        </li>
        <li>
          <a href="#s4">Training</a>
        </li>
        <li>
          <a href="#s5">Surveys</a>
        </li>
      </ol>

      <h4 id="s1">Meetings</h4>
      <p>
        Meetings can be scheduled with any of the five stakeholders identified
        on the project. Meetings last two hours and involve the project manager
        and the respective stakeholder(s). The cost to the project for one
        meeting is $300.
      </p>
      <h4 id="s2">Status Reports</h4>
      <p>
        Status reports can be sent to any of the five stakeholders identified on
        the project. Status reports do not require any additional effort but
        cost $200 to produce. There is no additional cost for sending status
        reports to multiple stakeholders.
      </p>
      <h4 id="s3">Visits</h4>
      <p>
        Visits can be scheduled with the customers, the users, and/or the
        subcontractor(s) on the project. Visits last one full day and involve a
        selected member of the project team and the respective stakeholder.
        Visits with the customers or users can be used for focus group meetings.
        The cost to the project for one visit is $150, plus one day of effort
        for the selected team member.
      </p>
      <h4 id="s4">Training</h4>
      <p>
        Training can be provided for either the customers or subcontractor(s).
        Training opportunities for the customers and subcontractor(s) include:
        Defining the Product, Scope Change Control, The Northrop Grumman
        Security Systems Product Development Process, and Product Verification
        and Validation. The cost to the project for providing any one of these
        training sessions is $2,000 per session. There is no project effort
        associated with stakeholder training. The following are short
        descriptions of these stakeholder training opportunities:{" "}
      </p>
      <ol>
        <li>
        Defining the Product
          <p>
            A one-day training session on the process and tools used to define
            the various levels of a product description. This includes
            high-level descriptions like a Project Charter and lower level
            descriptions like requirements, functional, and design
            specifications.
          </p>
        </li>

        <li>
          Scope Change Control
          <p>
            {" "}
            A one-day training session on the process and tools used to manage
            requirements, design, and implementation changes on Northrop Grumman
            Security Systems projects. This includes what and how to create
            baseline documents, how to manage baselines, and how to create and
            use the change control process.
          </p>
        </li>
        <li>
         Product Development Process
          <p>
            A one-day training session on the specific product development
            process and tools used by Northrop Grumman Security Systems to
            develop products. Each phase of the product development life cycle
            is covered.
          </p>
        </li>
        <li>
          Product Verification and Validation
          <p>
            A one-day training session on the process and tools used to perform
            the verification and validation of Northrop Grumman Security Systems
            products. This includes the informal and formal processes and tools
            used in the various phases of the product development life cycle.
          </p>
        </li>
      </ol>
      <h4 id="s5">Surveys</h4>
      <p>
        Surveys can be sent to the potential users of the product to collect
        information of product requirements, design, implementation, and use.
        Surveys do not require any additional effort but cost $200 to produce
        and process.
      </p>
    </div>
  );
};
