import React from 'react';



export const MissedEvent = (props) => {
	return (
		<div className="content">
			<p>
				{props.properties.worker}
				{/* <small>{props.properties.title}</small> */}
			</p>
			<small>{props.properties.reason}</small>
		</div>
	);
};
