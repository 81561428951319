/* eslint-disable max-len */
import React from "react";

export const Absenteeism = () => (
  <div className="Absenteeism style-background-components">
    <header>
      <h2>Absenteeism</h2>
    </header>
    <div>
      <p>
        Uniworld typically experiences a 2% to 3% absenteeism rate due to
        illnesses or other causes.
      </p>
      <p>
        You will need to pay attention to team member absenteeism and should it
        become chronic take appropriate action.
      </p>
      <p>
        Absenteeism also covers the missing of regularly scheduled meetings,
        whether they are team meetings, individual conferences, or stakeholder
        meetings.
      </p>
    </div>
  </div>
);
