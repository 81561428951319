import React from "react";
import styled from "styled-components";
const StyledSpan = styled.span`
font-size: 10px;
color: red;
margin-right: 10px;
`;
const ResponseDebug = props => {

  return (
    <div className="report-Item">
    <StyledSpan>
      <strong>Response: </strong> {props.properties.response}
      </StyledSpan>
      <StyledSpan>
       <strong> Action : </strong>  {props.properties.action === null || props.properties.action === "" ? "none" : props.properties.action}</StyledSpan>
       </div>
  );
};

export default ResponseDebug
