import {
  fetchProjectData,
  acceptWeeklyStaffingPlan,
  resumeProject
} from "redux/Project/thunks";
import {
  refreshAfterRunDay,
  setProjectAsReadOnly,
  updateProjectStatus,
  receiveProjectData
} from "redux/Project/actions";

import { interimHistoryReceived, historyReceived } from "redux/Report/actions";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import { fetchHistoryData, fetchChartData, fetchLaborSummaryData } from "redux/Report/thunks";

import history from "../../history";
import * as actions from "./actions";

export const checkStudentCanRun = (rid, cid, uid) => async (
  dispatch,
  getState
) => {
  if (rid === null || rid === undefined || rid === 0) {
    return false;
  }
  if (cid === null || cid === undefined || cid === 0) {
    return false;
  }
  if (uid === null || uid === undefined || uid === 0) {
    return false;
  }
  const payload = {
    runId: rid,
    classroomId: cid,
    userId: uid
  };
  const response = await fetch(
    `${getState().app.baseURL}JsonClassroomSvc.svc/checkStudentCanRunWeek`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
      },
      method: "post",
      body: JSON.stringify(payload)
    }
  );
  // api call http status
  const { status } = response;
  // wait for json respone to come back
  const json = await response.json();
  if (!response.ok) {
    throw new Error(
      `CanStudentRunWeekResult : http request response error: ${status}`
    );
  } else if (json.status === "error") {
    throw new Error(
      `CanStudentRunWeekResult : http request response error: ${json.statusMessage}`
    );
  }
  const pl = JSON.parse(json.payload);
  dispatch(actions.canStudentRunSuccess(pl));
  return pl.CanRun;
};

export function getPresentationRunDay(runId) {
  return async (dispatch, getState) => {
    const payload = {
      runId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonRunWeekSvc.svc/getPresentationRunDay`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      throw new Error(
        `setPresentationRunDay : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `setPresentationRunDay : http request response error: ${json.statusMessage}`
      );
    }
    dispatch(
      actions.setPresentationRunDay(
        JSON.parse(json.payload)
      )
    );
    return true;
  };
}

export function checkForStaffingProblems(runId, weekNumber) {
  return async (dispatch, getState) => {
    const r = runId;
    const w = weekNumber;
    if (r === undefined || r === null || r === 0) {
      return false;
    }
    const payload = {
      runId: r,
      weekNumber: w
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonRunWeekSvc.svc/checkForStaffingProblems`,

      {
        headers: {
          Accept: "text/json",
          "Content-Type": "text/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      throw new Error(
        `checkForStaffingProblemsResult : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `checkForStaffingProblemsResult : http request response error: ${json.statusMessage}`
      );
    }
    const pl = JSON.parse(json.payload);
    dispatch(actions.processStaffingProblems(pl));
    return true;
  };
}

export function processConditionResponse(
  runId,
  conditionId,
  responseId,
  runWeek,
  runDay
) {
  return async (dispatch, getState) => {
    const payload = {
      runId,
      condId: conditionId,
      condResponse:
        responseId === undefined || responseId === null || responseId === 0
          ? 1
          : responseId,
      runDay: getState().project.runDay,
      debugRecords: false
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonRunWeekSvc.svc/processConditionResponse`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `processConditionResponseResult : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `processConditionResponseResult : http request response error: ${json.statusMessage}`
      );
    }
    const returnPayload = JSON.parse(
      json.payload
    );
    await dispatch(updateProjectStatus(returnPayload.projectStatus))
    if (
      returnPayload.playScriptList !== null &&
      returnPayload.playScriptList.length > 0
    ) {
      await dispatch(actions.conditionComplete());
      dispatch(actions.playScript(returnPayload));
      return true;
    }
    await dispatch(actions.conditionComplete(returnPayload));
    // eslint-disable-next-line no-use-before-define
    await dispatch(runTheWeek(runId, conditionId, runDay, runWeek));
    return true;
  };
}

// , runDay, conditionId,
//  espVal, weekNumber, maxHistoryReportId, historyReportingLevel
export function runTheWeek(runId, conditionId, runDay, runWeek) {
  let returnPayload;
  return async (dispatch, getState) => {
    // try {
      let tmpRunDay = runDay;
      dispatch(actions.startRunWeek());
      const payload = {
        runId,
        runDay: getState().project.projectStatus.DayNumber,
        condId: conditionId,
        teamId: 0,
        espVal: 0,
        weekNumber: runWeek,
        maxHistoryReportId: 0,
        historyReportingLevel: 50
      };

      const response = await fetch(
        `${getState().app.baseURL}JsonRunWeekSvc.svc/runWeek`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        const errorInfo = {
          hasError: true,
          errorMessage: `${response.status} ${response.statusText}`
        }
        dispatch(actions.workWeekError(errorInfo))
        const alert = {
          id: 999,
          type: "danger",
          headline: "Request Error",
          event: "workweek",
          message:
            "The request returned a  bad status.  Please stop your work and contact support@simulationpl.com with your username, execution id and this message."
        };
        const notificationData = {
          info:
          `${response.status} ${response.statusText}`,
          worker: getState().auth.user.UserName,
          week: runId
        };
        await dispatch(addSingleAlert(alert, notificationData, 5000, true));
        return false;
      } else if (json.status === "error") {
       const errorInfo = {
         hasError: true,
         errorMessage:   `runweek result error:  ${json.statusMessage}`,
         errorStacktrace: json.payload
       }
       dispatch(actions.workWeekError(errorInfo))
       const alert = {
        id: 999,
        type: "danger",
        headline: "Work Week Error returned",
        event: "workweek",
        message:
          `There was an error in the workweek api : runweek result error:  ${json.statusMessage}.  Please stop your work and contact support@simulationpl.com with your username, execution id and this message.`
      };
      const notificationData = {
        info:
        `runweek result error:  ${json.statusMessage}`,
        worker: getState().auth.user.UserName,
        week: runId
      };
      await dispatch(addSingleAlert(alert, notificationData, 5000, true));
       return false;
      }
      try {
        returnPayload = JSON.parse(json.payload);
      } catch (e) {
        throw e;
      }

      const returnMsg = returnPayload.returnMessage;
      var toHistory = false;
      switch (returnMsg) {
        case "EOP": {
          // end of project
          await dispatch(actions.processEOP(returnPayload));

          Promise.all([
            // dispatch(fetchHistoryData(runId)),
            dispatch(fetchChartData(runId)),
            dispatch(fetchLaborSummaryData(runId)),
            dispatch(actions.resetWorkWeek()),
            dispatch(receiveProjectData(returnPayload)),
            dispatch(historyReceived(returnPayload.projectDataLists.projectHistoryList)),

            dispatch(setProjectAsReadOnly(true))

          ]);
          toHistory = true;

          if (getState().auth.productId === 38) {
            const alert = {
              id: 99,
              type: "success",
              headline: "Congratulations",
              event: "workweek",
              message:
                "You have completed your project!  We will be opening a survey in another tab; please help us make SimProject better by providing feedback and completing the survey.  For now, we're sending you to reports so you can review your project  Congratulations."
            };
            const notificationData = {
              info:
                "You have completed your project! Redirecting to reports so you can review your project.",
              worker: getState().auth.user.UserName,
              week: 0
            };
            await dispatch(addSingleAlert(alert, notificationData, 5000, true));

            const RunSequenceNumber = getState().auth.studentInfo.RunDetails.filter((r) => r.RunId === runId);

            if (RunSequenceNumber.length > 0) {
              const seqNo = RunSequenceNumber[0].RunSequenceNumber;
              if (seqNo === 3) {
                window.open(
                  "http://survey.constantcontact.com/survey/a07ef13x5x1jce7l6so/start",
                  "_newtab"
                );
              } else if (seqNo === 2) {
                window.open(
                  "http://survey.constantcontact.com/survey/a07ef13t67qjce4o5p8/start",
                  "_newtab"
                );
              } else {
                // default to first
                window.open(
                  "http://survey.constantcontact.com/survey/a07eezg0eh6jbsh1a8r/start",
                  "_newtab"
                );
              }
            }
          }
          break;
        }
        case "EOW": {
          const alert = {
            id: 100,
            type: "success",
            headline: "Work Week Completed",
            event: "workweek",
            message: `The team has finished working on week #${getState().project.currentProjectWeek
              }.  Redirecting to reports so you can review their work.`
          };
          const notificationData = {
            info: `The team has finished working on week #${getState().project.currentProjectWeek
              }.  Redirecting to reports so you can review their work.`,
            worker: getState().auth.user.UserName,
            week: getState().project.currentProjectWeek
          };

          await dispatch(actions.processEOW(returnPayload));
          await dispatch(
            acceptWeeklyStaffingPlan(
              runId,
              getState().project.displayProjectWeek + 1
            )
          );
          Promise.all([
            dispatch(addSingleAlert(alert, notificationData, 3000, true)),
            dispatch(fetchChartData(runId)),
            dispatch(fetchLaborSummaryData(runId)),
            dispatch(receiveProjectData(returnPayload)),
            dispatch(historyReceived(returnPayload.projectDataLists.projectHistoryList)),
            dispatch(actions.resetWorkWeek())

          ]);
          toHistory = true;
          break;
        }
        case "EOD": {
          await (dispatch(
            actions.processEOD(JSON.parse(json.payload))
          ),
            await dispatch(
              refreshAfterRunDay(JSON.parse(json.payload))
            ), // updates day of proj
            (tmpRunDay += 1));
          dispatch(runTheWeek(runId, 1, tmpRunDay, runWeek));
          break;
        }
        case "EOS": {
          dispatch(actions.processEOS(JSON.parse(json.payload)));
          break;
        }
        case "ReassignWorker": {
          await dispatch(
            actions.processReassign(JSON.parse(json.payload))
          );

          break;
        }
        default: {
          const pl = JSON.parse(json.payload);
          if (getState().execution.workweek.autorun === true) {
            dispatch(actions.processEOS(pl));
            let condResponse = 1;
            const responseType = getState().execution.workweek
              .autorunResponseType;
            const rcount = pl.conditionStatus.ResponseList.length;
            if (responseType === 0) {
              condResponse = Math.floor(Math.random() * rcount + 1); // 0 start
              if (
                condResponse > pl.conditionStatus.ResponseList.length + 1 ||
                rcount < 1
              ) {
                condResponse = pl.conditionStatus.ResponseList.length;
              }
            } else if (responseType === 2 && rcount > 1) {
              condResponse = 2;
            } else if (responseType === 3 && rcount > 2) {
              condResponse = 3;
            }
            dispatch(
              processConditionResponse(
                runId,
                pl.conditionStatus.ConditionId,
                condResponse,
                runWeek,
                tmpRunDay
              )
            );
          } else if (pl.conditionStatus.Silent === 1) {
            dispatch(
              processConditionResponse(
                runId,
                pl.conditionStatus.ConditionId,
                1,
                runWeek,
                tmpRunDay
              )
            );
          } else {
            await dispatch(actions.processCondition(pl));
          }
          break;
        }
      }
      if (toHistory) {
        history.push("/reports/history");
      }
      return true;
    // } catch (e) {
    //   c
    //   if (e.name === "SyntaxError") {
    //    throw e;
    //    return false;
    //   } else {
    //     throw e;
    //     // processFailToFetch(e,getState(), dispatch, addSingleAlert, 'runTheWeek');
    //   }
    //   return false;
    // }
  };
}

export function processReassignResponse(
  runId,
  conditionId,
  responseId,
  workerId,
  runWeek,
  runDay
) {
  return async (dispatch, getState) => {
    try {
      const payload = {
        runId,
        condId: conditionId,
        taskId: responseId,
        workerId
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonRunWeekSvc.svc/processReassignResponse`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        throw new Error(
          `processReassignResponse : http request response error: ${status}`
        );
      } else if (json.status === "error") {
        throw new Error(
          `processReassignResponse : http request response error: ${json.statusMessage}`
        );
      } else if (
        json.payload.returnMessage ===
        "playScript"
      ) {
        dispatch(
          actions.playScript(json.payload)
        );
        return true;
      } else if (
        json.payload.returnMessage === "runWeek"
      ) {
        dispatch(runTheWeek(runId, conditionId, runDay, runWeek));
        return true;
      }
      await dispatch(actions.reassignComplete());
      dispatch(runTheWeek(runId, conditionId, runDay, runWeek));
      return true;
    } catch (e) {
      throw e;
    }
  };
}

export const getHeadConditions = () => {
  return async (dispatch, getState) => {
    try {
      const payload = {
        runId: getState().project.runId
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonRunWeekSvc.svc/getHeadConditions`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        throw new Error(
          `getHeadConditions : http request response error: ${status}`
        );
      } else if (json.status === "error") {
        throw new Error(
          `getHeadConditions : http request response error: ${json.statusMessage}`
        );
      }
      dispatch(
        actions.getHeadConditionsSuccess(
          JSON.parse(json.payload)
        )
      );
      return JSON.parse(json.payload);
    } catch (e) {
      throw e;
    }
  };
};

export const setEnabledConditions = (conditionList) => {
  return async (dispatch, getState) => {
    try {
      // condition list comes over as an option list
      // create a new array from the contents

      const idList = conditionList.map((c) => c.value);
      const payload = {
        runId: getState().project.runId,
        conditionIdList: idList
      };

      const response = await fetch(
        `${getState().app.baseURL}JsonRunWeekSvc.svc/setEnabledConditions`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        throw new Error(
          `getEnabledConditions : http request response error: ${status}`
        );
      } else if (json.status === "error") {
        throw new Error(
          `getEnabledConditions : http request response error: ${json.statusMessage}`
        );
      }
      dispatch(
        actions.setEnabledConditionsSuccess(
          JSON.parse(json.payload)
        )
      );
      return true;
    } catch (e) {
      throw e;
    }
  };
};

export const resetConditions = () => {
  return async (dispatch, getState) => {
    try {
      const payload = {
        runId: getState().project.runId,
        productId: getState().auth.productId
      };

      const response = await fetch(
        `${getState().app.baseURL}JsonRunWeekSvc.svc/resetConditions`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        throw new Error(
          `resetConditions : http request response error: ${status}`
        );
      } else if (json.status === "error") {
        throw new Error(
          `resetConditions : http request response error: ${json.statusMessage}`
        );
      }
      dispatch(
        actions.resetConditionsSuccess(
          JSON.parse(json.payload)
        )
      );
      return true;
    } catch (e) {
      throw e;
    }
  };
};
