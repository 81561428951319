import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  TabContent,
  TabPane,
  Row,
  Col,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  Card
} from "reactstrap";
import classnames from "classnames";
import {
  getTotalCostByWorker,
  getTotalCostByTask
} from "redux/Report/selectors";
import { fetchLaborSummaryData } from "redux/Report/thunks";
import PerfectScrollbar from "react-perfect-scrollbar";
import accounting from "accounting";
import CardHeaderBold from "components/styled/CardHeaderBold";

import {
  getLostHoursSummaryData,
  getTaskHoursSummaryData,
  getTaskHoursSummaryNewData
} from "./components/LaborSummary/LaborSelectors";
import LostHoursTable from "./components/LaborSummary/LostHoursTable";
import TaskHoursTable from "./components/LaborSummary/TaskHoursTable";
import WorkerSummaryTable from "./components/LaborSummary/WorkerSummaryTable";
import TaskSummaryTable from "./components/LaborSummary/TaskSummaryTable";
import RawDataTable from "./components/LaborSummary/RawDataTable";

import InfoButton from "components/Info/InfoButton";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";

const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 2,
  format: "%s%v"
};

const LaborCostSummaryContainer = (props) => {
  const content = useSelector((state) => state); //this hook gives us redux store state
  const [activeTab, setActiveTab] = useState("task-hours");

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  var maxWeek = Math.max.apply(
    Math,
    content.report.taskHoursSummary.map(function (o) {
      return o.weekNumber;
    })
  );

  return (
    <>
      <Card>
        <CardHeaderBold>
          <span>Labor Breakdown Reports</span>

          <ButtonToolbar>
            <InfoButton selectedKey="labor" />
            {/* <PdfButton selectedKey="labor" /> */}
          </ButtonToolbar>
        </CardHeaderBold>
      </Card>
      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "task-hours"
                })}
                onClick={() => {
                  setActiveTab("task-hours");
                }}
              >
                Task Related Labor Hours/Costs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "lost-hours"
                })}
                onClick={() => {
                  setActiveTab("lost-hours");
                }}
              >
                Non-Task Related Labor Hours/Costs
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "worker-summary"
                })}
                onClick={() => {
                  setActiveTab("worker-summary");
                }}
              >
                By Resource Labor Hours/Costs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "task-summary"
                })}
                onClick={() => {
                  setActiveTab("task-summary");
                }}
              >
                By Task Labor Hours/Costs
              </NavLink>
            </NavItem>
            {content.auth &&
              content.auth.hasOwnProperty("user") &&
              content.auth.user.hasOwnProperty("UserName") &&
              (content.auth.user.UserName.toLowerCase().includes(
                "paccissuser"
              ) ||
                content.auth.user.UserName.toLowerCase().includes(
                  "paccesstest"
                )) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "raw-data"
                    })}
                    onClick={() => {
                      setActiveTab("raw-data");
                    }}
                  >
                    {" "}
                    Raw Data{" "}
                  </NavLink>
                </NavItem>
              )}
          </Nav>
          <TabContent
            id={`lstab-${
              content &&
              content.project.projectStatus &&
              content.project.projectStatus &&
              content.project.projectStatus.WeekHasCompleted
                ? content.project.projectStatus.WeekHasCompleted.toString()
                : "false"
            }`}
            activeTab={activeTab}
            style={{ height: "900" }}
          >
            <TabPane tabId="task-hours">
              <PerfectScrollbar>
                <TaskHoursTable
                  maxWeek={maxWeek}
                  options={options}
                  taskHoursContent={getTaskHoursSummaryNewData(content)}
                />
              </PerfectScrollbar>
            </TabPane>
            <TabPane tabId="lost-hours">
              <PerfectScrollbar>
                <LostHoursTable
                  maxWeek={maxWeek}
                  options={options}
                  lostHoursContent={getLostHoursSummaryData(content)}
                />
              </PerfectScrollbar>
            </TabPane>

            <TabPane tabId="worker-summary">
              <PerfectScrollbar>
                <WorkerSummaryTable data={getTotalCostByWorker(content)} />
              </PerfectScrollbar>
            </TabPane>

            <TabPane tabId="task-summary">
              <PerfectScrollbar>
                <TaskSummaryTable data={getTotalCostByTask(content)} />
              </PerfectScrollbar>
            </TabPane>
            <TabPane tabId="raw-data">
              <RawDataTable data={content.report.workerTaskHoursData} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default LaborCostSummaryContainer;
