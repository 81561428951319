import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import BudgetSummary from "routes/Budget/components/BudgetSummary";
import ResourceGrid from "../Resource/components/ResourceGrid";
import { updateBudget } from "redux/Project/thunks";
import {
  toggleRecognitionInfo,
  toggleRecognitionBudget
} from "app/redux/actions";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import ReferenceButton from "components/Info/ReferenceButton";
import RecognitionInfo from "./components/RecognitionInfo";
import RecognitionOptionsCosts from "./components/RecognitionOptionsCosts";
import CardHeaderBold from "components/styled/CardHeaderBold";
import GanttChartContainer from "../Report/GanttChartContainer";
import NetworkDiagramContainer from "../Report/NetworkDiagramContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
const Recognition = (props) => {
  const [budgetAmt, setBudgetAmt] = useState("");
  const [activeTab, setActiveTab] = useState("resource-plan");
  const [loading, setLoading] = useState(false)

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
  }, []);

  const updateRecognitionBudget = () => {
    const budgetObj = {
      BudgetAmt: budgetAmt,
      BudgetKind: "5",
      DayNumber: 0,
      RunId: props.runId,
      Task: ""
    };

    if (budgetAmt > 0 && budgetAmt !== undefined && budgetAmt !== null) {
      setLoading(true)
      Promise.all([props.updateBudget(budgetObj)]).then(() => {
        setLoading(false)
        const alert = {
          id: 7,
          type: "success",
          headline: "Recognition Budget Updated",
          event: "interaction",
          message: `The budget line item:  1.4 Team / Individual Recognition has been set to:  $${budgetAmt}`
        };

        const notificationData = {
          info: `The budget line item:  1.4 Team / Individual Recognition has been set to:  $${budgetAmt}`,
          worker: "",
          week: props.displayProjectWeek
        };
        props.addAlert(alert, notificationData, 3000, true);
      });
    }
  };

  return (
    <div>
      <Row>
        <Col lg="12">
          <RecognitionInfo
            panelVisible={props.recognitionInfoVisible}
            toggle={props.toggleRecognitionInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <BudgetSummary
            title="Recognition Budget Item"
            panelOpen={props.recognitionBudgetVisible}
            togglePanel={props.toggleRecognitionBudget}
            summaryType="recognition"
            titleSummary="Individual/Team Recognition"
            titleApproved="Approved for Recognition"
            titlePlanned="Planned for Recognition"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="7">
          <Card>
            <CardHeaderBold>Recognition Allocation
            </CardHeaderBold>
            <CardBody>
              {props.project.budgetIsLocked === false ? (
                <div>
                  <div className="alert alert-info">
                    Submitting this voucher will set the planned budget cost of
                    your planned Team/Individual Recognition cost.

                   <b> Any previous value will be replaced by the amount you
                    specify.</b>
                  </div>
                  <p className="recognition-line">
                    Estimated Cost of Recognition for Project: <span>$</span>{" "}
                    <input
                      className="form-control"
                      type="number"
                      value={budgetAmt}
                      onChange={(event) => {
                        event.preventDefault();
                        const { value } = event.target;

                        if (
                          /^[0-9][0-9]*\d*(\.\d+)?$/.test(value) ||
                          value === ""
                        ) {
                          setBudgetAmt(event.target.value)

                        }

                      }}
                    />
                  </p>
                  <footer className="text-right">
                    <button
                      disabled={budgetAmt === 0 || budgetAmt === undefined || budgetAmt === ""}
                      className="btn btn-primary"
                      onClick={updateRecognitionBudget}
                    >
                        {loading && (
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" />
        )} Submit Voucher
                    </button>
                  </footer>
                </div>
              ) : (
                <div>
                  <div className="alert alert-info">
                    <h3>Your budget has been approved.</h3>
                    No more changes can be made to the planned Recognition
                    budget.
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col lg="5">
          <Card className="box-AdditionalInfo  Card-info-large">
            <CardHeaderBold>Recognition Options + Costs

              <ReferenceButton className="float-right" selectedKey="recognition" size="lg" />

            </CardHeaderBold>
            <CardBody>
              <RecognitionOptionsCosts />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "resource-plan"
                })}
                onClick={() => {
                  toggle("resource-plan");
                }}
              >
                Resource Plan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "gantt-chart"
                })}
                onClick={() => {
                  toggle("gantt-chart");
                }}
              >
                Gantt Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "network-diagram"
                })}
                onClick={() => {
                  toggle("network-diagram");
                }}
              >
                Network Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="resource-plan">
              <ResourceGrid noHideToggle />
            </TabPane>
            <TabPane tabId="gantt-chart">
              <GanttChartContainer activeTab={activeTab === "gantt-chart"} />
            </TabPane>
            <TabPane tabId="network-diagram">
              <NetworkDiagramContainer />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  runId: state.project.runId,
  recognitionBudgetVisible: state.app.recognitionBudgetVisible,
  recognitionInfoVisible: state.app.recognitionInfoVisible
});

const mapDispatchToProps = (dispatch) => ({
  toggleRecognitionInfo: () => dispatch(toggleRecognitionInfo()),
  toggleRecognitionBudget: () => dispatch(toggleRecognitionBudget()),
  updateBudget: (budgetObj) => dispatch(updateBudget(budgetObj)),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))

});

export default connect(mapStateToProps, mapDispatchToProps)(Recognition);
