import React from "react";
import accounting from "accounting";
import { Card } from "reactstrap";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import StyledTable from "./StyledTable";
import RenderWeekColumns from "./RenderWeekColumns";
import TableFooter from "./TableFooter";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { NoMaxCardBody } from "components/styled/NoMaxCardBody";

const TaskHoursTable = ({ maxWeek, taskHoursContent, options }) => {

  const taskHeaders = () => {
    let headers = [];
    headers.push(
      <th className="text-center" key="task">
        Task
      </th>
    );

    for (let i = 1; i <= maxWeek; i += 1) {
      headers.push(
        <th className="text-center" key={`W${i}`}>
          {i}
        </th>
      );
    }

    headers.push(
      <th className="text-center" key={"tothr"}>
        Resource Hours Working on Task
      </th>
    );
    headers.push(
      <th className="text-center" key={"totcost"}>
        Resource Costs Working on Task
      </th>
    );

    return headers;
  };

  return (
    <Card>
      <CardHeaderBold>
      Task Related Labor Hours/Costs
      </CardHeaderBold>
      <NoMaxCardBody>
        <StyledTable bordered hover>
          <thead>
            <tr>{taskHeaders()}</tr>
          </thead>
          <tbody>
            {taskHoursContent.map((data, index) => {
              return (
                <tr key={data.event}>
                  <td key={`${data.event}-task`} className="text-center">
                    {data.description}
                  </td>
                  <RenderWeekColumns
                    data={data}
                    keyTag="task-week"
                    maxWeek={maxWeek}
                  />

                  <td className="text-right" key={`${data.event}-th`}>
                    {data.totalHours}
                  </td>
                  <td className="text-right" key={`${data.event}-tc`}>
                    {accounting.formatMoney(data.totalCosts, options)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <TableFooter maxWeek={maxWeek} data={taskHoursContent} />
        </StyledTable>
      </NoMaxCardBody>
    </Card>
  );
};

export default TaskHoursTable;
