import React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  InputGroupAddon,
  InputGroup,
  Card,
  Toast,
  ToastBody,
} from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { ComponentArea } from "components/styled/ComponentArea";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { updateStopMessageList } from "redux/Instructor/thunks";

const StyledTable = styled(Table)`
  transition: transform 0.3 ease;
  &.active {
    transform: scale(1.03);
  }
  &.table-hover tr {
    cursor: pointer;
  }
  tr.active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .cell-checkbox {
    text-align: center;
    .form-check-input {
      margin: 0;
    }
  }
  tr.row-execution.active 
  {
    background-color:#dbe1dd;
  }
  input[type=text]:disabled {
    background-color:rgba(0, 0, 0, 0.075);
  }
`;

class ExecutionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRun:
        this.props.classRuns && this.props.classRuns.length > 0
          ? this.props.classRuns[0]
          : null,
      classRuns: this.props.classRuns,
      isOpen: false,
      isOpenConfirm: false,
      open: false,
      show: false,
      rowID: '',
      StopMessage: '',
      selectedIndex: 0,
      stopCheckBox: false
    };
    this.handleMessageChange = this.handleMessageChange.bind(this);
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, isOpenConfirm: false });
  onRowSelect = (event, row, index) => {
    if (this.state.selectedRun !== row) {
      this.setState({
        selectedRun: row,
        selectedIndex: index
      });

      const currentActiveRowEl = document.querySelector(
        ".row-execution.active"
      );

      if (currentActiveRowEl !== null && currentActiveRowEl !== undefined) {
        currentActiveRowEl.classList.remove("active");
        event.target.parentNode.classList.add("active");
      }

      // add a small animation to indicate execution change
      const stopWeeksTableEl = document.querySelector(".table-StopWeeks");
      stopWeeksTableEl.classList.add("active");
      setTimeout(() => {
        stopWeeksTableEl.classList.remove("active");
      }, 150);
    }
    else {
      this.setState({
        selectedRun: row,
        selectedIndex: index
      });
      const currentActiveRowEl = document.querySelector(
        ".row-execution.active"
      );
      if (currentActiveRowEl !== null && currentActiveRowEl !== undefined) {
        currentActiveRowEl.classList.remove("active");
        event.target.parentNode.classList.add("active");
      }
    }
  };

  closeButton = () => this.setState({ open: false, show: false });

  onClickAllowToPlan = (event, row, index) => {
    row.AllowStudentToPlan = !row.AllowStudentToPlan;
    if (this.state.selectedRun !== row) {
      this.setState({
        selectedRun: row
      });

      const currentActiveRowEl = document.querySelector(
        ".row-execution.active"
      );
      if (
        currentActiveRowEl !== null &&
        currentActiveRowEl !== undefined &&
        Object.prototype.hasOwnProperty.call(currentActiveRowEl, "classList")
      ) {
        currentActiveRowEl.classList.remove("active");
        event.target.parentNode.classList.add("active");
      }

      // add a small animation to indicate execution change
      const stopWeeksTableEl = document.querySelector(".table-StopWeeks");
      stopWeeksTableEl.classList.add("active");
      setTimeout(() => {
        stopWeeksTableEl.classList.remove("active");
      }, 150);
    }

    this.props.updateAllowToPlan(row.ID, row.AllowStudentToPlan);

    // runs[index].AllowStudentToPlan = !runs[index].AllowStudentToPlan;
    // this.setState({
    //   classRuns: runs
    // });
    // this.props.updateClassRuns(runs);
  };

  onClickAllowToRun = (event, row, index) => {
    row.AllowStudentToRun = !row.AllowStudentToRun;

    if (this.state.selectedRun !== row) {
      this.setState({
        selectedRun: row
      });

      const currentActiveRowEl = document.querySelector(
        ".row-execution.active"
      );
      if (currentActiveRowEl !== null && currentActiveRowEl !== undefined) {
        currentActiveRowEl.classList.remove("active");
        event.target.parentNode.classList.add("active");
      }

      // add a small animation to indicate execution change
      const stopWeeksTableEl = document.querySelector(".table-StopWeeks");
      stopWeeksTableEl.classList.add("active");
      setTimeout(() => {
        stopWeeksTableEl.classList.remove("active");
      }, 150);
    }
    this.props.updateAllowToRun(row.ID, row.AllowStudentToRun);
    // const runs = this.state.classRuns.slice();

    // runs[index].AllowStudentToRun = !runs[index].AllowStudentToRun;
    // this.setState({
    //   classRuns: runs
    // });
    // this.props.updateClassRuns(runs);
  };

  onStopWeekClick = (event, row, index) => {
    const currRun = Object.assign({}, this.state.selectedRun);
    const stopFlag = currRun.StopWeeks[index].StopOnWeek;

    currRun.StopWeeks[index].StopOnWeek = !stopFlag;
    if (stopFlag === false) {
      currRun.StopWeeks[index].StopMessage = '';
    }

    this.setState({
      selectedRun: currRun
    });
    this.setState({ stopCheckBox: true });
    const id = currRun.StopWeeks[index].ID;
    this.props.updateStopWeek(id, !stopFlag);
  };

  handleMessageChange = (row, index, event) => {
    event.preventDefault();

    const currRun = Object.assign({}, this.state.selectedRun);
    currRun.StopWeeks[index].StopMessage = event.target.value;

    this.setState({
      selectedRun: currRun
    });
  };

  updateMessage = (row, index, event) => {
    if (row.StopOnWeek === true) {
      if (row.StopMessage !== "") {
        this.setState({ isOpen: true, rowID: row.ID, StopMessage: row.StopMessage });
        this.setState({ show: false });
      }
      else {
        this.setState({ show: true });
        setTimeout(() => {
          this.setState({ show: false });
        }, 5000);
      }
    }
    if (row.StopOnWeek === false) {
      this.setState({ isOpen: true, rowID: row.ID, StopMessage: row.StopMessage });
      this.setState({ show: false });
    }
  };

  // handleMessageBlur = (row, index, event) => {
  //   event.preventDefault();
  //
  //   const currRun = Object.assign({}, this.state.selectedRun);
  //   currRun.StopWeeks[index].StopMessage = this.state.stopMessage;
  //   this.setState({
  //     selectedRun: currRun
  //   });
  // };

  saveModal = () => {
    this.props.updateStopMessage(this.state.rowID, this.state.StopMessage);
    this.setState({ open: true });
    setTimeout(() => {
      this.setState({ open: false });
    }, 5000);
  }

  saveAllAlert = () => {
    for (const element of this.state.selectedRun.StopWeeks) {
      if (element.StopOnWeek === true && element.StopMessage === "") {
        this.setState({ isOpenConfirm: false });
        this.setState({ show: true });
        setTimeout(() => {
          this.setState({ show: false });
        }, 5000);
        return false;
      }
      else {
        this.setState({ isOpenConfirm: true });
      }
    }
  }

  saveAll = () => {
    var stopMessageList = [];
    for (const element of this.state.selectedRun.StopWeeks) {
      if (element.StopOnWeek === true) {
        stopMessageList.push({
          ID: element.ID,
          StopMessage: element.StopMessage
        })
      }
      else {
        stopMessageList.push({
          ID: element.ID,
          StopMessage: ""
        })
      }
    }
    this.props.updateStopMessageList(stopMessageList);
    this.setState({ isOpenConfirm: false });
    this.setState({ open: true });
    setTimeout(() => {
      this.setState({ open: false });
    }, 5000);
  }

  onClickSaveAll = event => {
    event.preventDefault();
    this.props.updateClassRuns(this.state.classRuns);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab === true && this.state.stopCheckBox !== true) {
      this.setState({ selectedRun: nextProps.classRuns[0] });
    }
    if (nextProps.classSelectorOpen === false && this.state.stopCheckBox !== true) {
      this.setState({ selectedIndex: 0 });

      var myTable = document.getElementById('classrunsTable');
      var rows = myTable.rows;
      for (const element of rows) {
        element.classList.remove("active");
      }
      var secondRow = rows[1];
      if (secondRow !== null && secondRow !== undefined) {
        secondRow.classList.add("active");
      }

    }
    if (this.state.stopCheckBox === true) {
      this.setState({ stopCheckBox: false });
    }
  }

  render() {
    let iterKey = 0;
    return (
      <Card>
        <Toast isOpen={this.state.show}>
          <ToastBody>
            <div className="alert alert-warning" style={{ width: "600px", position: "fixed", top: "60px", right: "50px", zIndex: '1' }}>
              <span className={{ content: "", clear: "both", display: "block", cursor: "pointer" }} onClick={this.closeButton}><i className="fa fa-close" style={{ float: "right" }}></i></span>
              <p>
                Please enter the stop message.
              </p>
            </div>
          </ToastBody>
        </Toast>
        <Toast isOpen={this.state.open}>
          <ToastBody>
            <div className="alert alert-success" style={{ width: "600px", position: "fixed", top: "60px", right: "50px", zIndex: '1' }}>
              <span className={{ content: "", clear: "both", display: "block", cursor: "pointer" }} onClick={this.closeButton}><i className="fa fa-close" style={{ float: "right" }}></i></span>
              <p>
                Saved successfully
              </p>
            </div>
          </ToastBody>
        </Toast>
        <CardHeaderBold>

          Manage Execution Flags & Stop Weeks
          {/* <span style={{ color: "#9e3237" }}>{this.props.selectedClass.className}</span> */}

        </CardHeaderBold>
        <ComponentArea>
          <Row>
            <div style={{ margin: "20px" }}>
              Organize your classroom plan and execution flags, add stop weeks to
              each execution as needed.
              <ul>
                <li> Select an execution row below - use the check boxes in the columns
                  to allow students to approve their budget/and or execute their projects as
                  desired.
                </li>
                <li>For each selected row below, use the table on the right
                  containing the corresponding row's stop weeks to add stops and
                  custom messages as needed.</li>
              </ul>
            </div>
          </Row>
          <Row>
            <Col lg="6">
              <h6 style={{ marginLeft: "10px" }}>Execution Flags</h6>
              <StyledTable bordered hover id="classrunsTable">
                <thead>
                  <tr>
                    <th className="text-center">Run Seq#</th>
                    <th className="text-center">Approve the budget?</th>
                    <th className="text-center">Run the work week?</th>
                  </tr>
                </thead>
                {this.props.classRuns ?
                  <tbody>
                    {this.props.classRuns.map((row, index) => (
                      <tr
                        key={(iterKey += 1)}
                        onClick={event => this.onRowSelect(event, row, index)}
                        className={
                          iterKey === 0 ? "active row-execution" : "row-execution"
                        }
                      >
                        <td align="center" width="40%">
                          {row.RunSequenceNumber}
                        </td>
                        <td className="cell-checkbox">
                          <Input
                            type="checkbox"
                            checked={row.AllowStudentToPlan}
                            onChange={event =>
                              this.onClickAllowToPlan(event, row, index)
                            }
                          />
                        </td>
                        <td className="cell-checkbox">
                          <Input
                            checked={row.AllowStudentToRun}
                            type="checkbox"
                            onChange={event =>
                              this.onClickAllowToRun(event, row, index)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  :
                  ''
                }
              </StyledTable>
            </Col>
            <Col lg="6">

              <h6>
                Stop Week for Run Sequence #:{" "}
                <strong>
                  {/* {this.state.selectedRun &&
                    this.state.selectedRun !== null &&
                    this.state.selectedRun.RunSequenceNumber} */}
                  {this.state.selectedIndex + 1}
                </strong>
              </h6>
              <button className="btn btn-primary" style={{ position: "absolute", top: "-15px", right: "25px" }}
                onClick={() => { this.saveAllAlert() }}>Save</button>
              <Modal show={this.state.isOpenConfirm} onHide={this.closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, want to save?</Modal.Body>
                <Modal.Footer>
                  <Button variant="primary"
                    onClick={() => { this.saveAll() }}
                    style={{ background: "#0275d8", color: "white" }}
                  >
                    Save
                  </Button>
                  <Button style={{ background: "#0275d8", color: "white" }} onClick={this.closeModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={this.state.isOpen} onHide={this.closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, want to save?</Modal.Body>
                <Modal.Footer>
                  <Button variant="primary"
                    onClick={() => { this.saveModal() }}
                    style={{ background: "#0275d8", color: "white" }}
                  >
                    Save
                  </Button>
                  <Button style={{ background: "#0275d8", color: "white" }} onClick={this.closeModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <StyledTable bordered className="table-StopWeeks">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>Week#</th>
                    <th style={{ width: "80px" }} className="text-center">
                      Stop <u>after</u> Week
                    </th>
                    <th>
                      Stop Message{" "}
                      <small>
                        <i>
                          enter message then click <i className="fa fa-check" />{" "}
                        </i>
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedRun &&
                    this.state.selectedRun !== null &&
                    this.state.selectedRun.StopWeeks.map((row, index) => (
                      <tr key={(iterKey += 1)}>
                        <td style={{ width: "50px" }} className="text-center">
                          {row.RunWeek}
                        </td>
                        <td style={{ width: "80px" }} className="cell-checkbox">
                          <Input
                            type="checkbox"
                            checked={
                              this.state.selectedRun.StopWeeks[index].StopOnWeek
                            }
                            // defaultChecked={row.StopOnWeek === true}
                            onChange={event =>
                              this.onStopWeekClick(event, row, index)
                            }
                          />
                        </td>
                        <td className="cell-checkbox">
                          {/* <Input
                              value={row.StopMessage}
                              type='text'
                              placeholder='message to students - ie. your professor has disable executions starting this week'
                              onChange={event =>
                                this.handleMessageChange(row, index, event)
                              }
                            /> */}
                          <InputGroup className="mb-3" size="sm">
                            {
                              row.StopOnWeek === true ?
                                <Input
                                  type="text"
                                  value={
                                    row.StopMessage
                                  }
                                  onChange={event =>
                                    this.handleMessageChange(row, index, event)
                                  }
                                />
                                :
                                <Input
                                  type="text"
                                  value={
                                    ""
                                  }
                                  disabled
                                />

                            }
                            {/* <InputGroupAddon addonType="append">
                                <Button
                                  style={{ background: "white" }}
                                  onClick={event =>
                                    this.updateMessage(row, index, event)
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-check"
                                    style={{ background: "white" }}
                                  />
                                </Button>
                              </InputGroupAddon> */}
                          </InputGroup>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </Col>
          </Row>
        </ComponentArea>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  classRuns: state.instructor.classRuns,
  instructorClasses: state.instructor.instructorClasses,
  instructors: state.instructor.instructors,
  selectedClass: state.instructor.selectedClass,

});

const mapDispatchToProps = dispatch => ({
  updateStopMessageList: (stopMessageList) => dispatch(updateStopMessageList(stopMessageList)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExecutionsTable));