import React, { useState, useRef, useMemo } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { saveResourceItem } from "redux/Resource/thunks";
import { toggleLoading } from "app/redux/actions";
const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {`${nbValues} items selected`}
    </components.ValueContainer>
  );
};

function SelectEditor({
  row,
  col,
  value,
  options,
  rowHeight,
  menuPortalTarget
}) {
  const content = useSelector((state) => state); //this hook gives us redux store state
  const dispatch = useDispatch(); //this hook gives us dispatch method
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [currentValue, setCurrentValue] = useState(value);
  const selectEl = useRef(null);
  const components = { ValueContainer };

  const isDisabled = useMemo(() => {
    const currentMapping = content.planning.resource.detailInfoWeekMap.filter(
      (item) => item.Item4 === col.key && item.Item3 === row.RowId
    );
    const obj = currentMapping[0];
    const index = content.planning.resource.detailInfoWeekMap.indexOf(obj);

    if (
      value === "Staff" &&
     index > 0 // doesn't work for first column
    ) {
      const obj3 = content.planning.resource.detailInfoWeekMap[index - 1];
      if (row[obj3.Item4] === "Staff") {
        return true;
      }
    }
    return false;
  }, [content.planning.resource.detailInfoWeekMap, col, row, value]);

  const handleActionChange = async (selected, event) => {
    selectEl.current.blur();
    await dispatch(toggleLoading(true));
    if (
      selected === null ||
      selected === undefined ||
      !selected.hasOwnProperty("value") ||
      !selected.hasOwnProperty("label")
    ) {
      return;
    } else if (selected.value === null || selected.label === "") {
      return;
    }
    setCurrentValue(selected.value);
    const currentMapping = content.planning.resource.detailInfoWeekMap.filter(
      (item) => item.Item4 === col.key && item.Item3 === row.RowId
    );

    if (currentMapping !== null && currentMapping.length > 0) {
      const obj = currentMapping[0];
      const dataObj = {
        displayObj: row,
        detailId: obj.Item1,
        detailId2: 0,
        newAction: selected.label
      };
      // Add the second Staff or None if replacing the Staff
      row[obj.Item4] = selected.label;
      const index = content.planning.resource.detailInfoWeekMap.indexOf(obj);
      if (
        selected.label === "Staff" &&
        content.planning.resource.detailInfoWeekMap.length > index + 1
      ) {
        const obj2 = content.planning.resource.detailInfoWeekMap[index + 1];
        dataObj.detailId2 = obj2.Item1;
        row[obj2.Item4] = selected.label;
      } else if (
        selected.label === "None" &&
        content.planning.resource.detailInfoWeekMap.length > index + 1
      ) {
        const obj3 = content.planning.resource.detailInfoWeekMap[index + 1];
         if (row[obj3.Item4] === "Staff"){
            dataObj.detailId2 = obj3.Item1;
            row[obj3.Item4] = selected.label;
           }

      }

      await dispatch(saveResourceItem(dataObj));
       await dispatch(toggleLoading(false));
    }
  };

  if (isDisabled) {
    return (
      <div
        id="tooltip-cell"
        style={{
          textAlign: "center",
          background: "lightgray"
        }}
      >
        <textarea
          disabled
          value={currentValue}
          onClick={(e) => e.preventDefault()}
        />
        <UncontrolledTooltip placement="top" target="tooltip-cell">
          The second Staff item cannot be deleted. Please delete the first staff
          cell and both will be removed.
        </UncontrolledTooltip>
      </div>
    );
  }
  const filterOption = (option, inputValue) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
   };
  return (
    <Select
      ref={selectEl}
      components={components}
      autoFocus
      autoComplete
      defaultMenuIsOpen
      filterOption={filterOption}
      closeMenuOnSelect
      options={options}
      value={options.find((o) => o.value === currentValue)}
      onChange={(o, event) => {
        selectEl.current.blur();
        handleActionChange(o, event);
      }}
      menuPortalTarget={menuPortalTarget}
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      styles={{
        control: (provided) => ({
          ...provided,
          height: rowHeight - 1,
          minHeight: 30,
          lineHeight: "normal"
        }),
        placeholder: (defaultStyles) => ({
          ...defaultStyles,
          fontSize: "9px"
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          width: "16px",
          height: rowHeight - 1
        })
      }}
      onKeyDown={(event, option) => {
        // event.preventDefault();
        // event.stopPropagation();
      }}
      // do not allow keystroke navigation for now
      // onKeyDown={(event, option) => {

      // event.preventDefault();
      // var optionSelected;
      // if (["Backspace"].includes(event.key) && isMenuOpen) {
      //   event.preventDefault();
      //   event.stopPropagation();
      // } else if (
      //   ["ArrowUp", "Enter", "Tab", "ArrowLeft", "ArrowRight"].includes(
      //     event.key
      //   )
      // ) {
      //   console.log(event.key); // placeholder til we figure it out
      // } else if (["ArrowDown"].includes(event.key) && isMenuOpen) {
      //   //  event.preventDefault();
      //   //   event.stopPropagation();
      //   if (event.target && event.target.value) {
      //     optionSelected = options.find(
      //       (o) => o.label === event.target.value.toString()
      //     );
      //     if (optionSelected !== undefined && optionSelected !== null) {
      //       handleActionChange(optionSelected);
      //     }
      //   }
      // } else {
      //   if (["r", "R"].includes(event.key) && isMenuOpen) {
      //     optionSelected = options.find((o) => o.label === "Release");
      //     setCurrentValue("Release");
      //     handleActionChange(optionSelected);
      //   } else if (["s", "S"].includes(event.key) && isMenuOpen) {
      //     optionSelected = options.find((o) => o.label === "Staff");
      //     setCurrentValue("Staff");

      //     handleActionChange(optionSelected);
      //   } else if (["n", "N"].includes(event.key) && isMenuOpen) {
      //     optionSelected = options.find((o) => o.label === "None");
      //     setCurrentValue("None");
      //     handleActionChange(optionSelected);
      //     setMenuOpen(false);
      //   } else if (["v", "V"].includes(event.key) && isMenuOpen) {
      //     event.stopPropagation();
      //   } else if (isFinite(event.key)) {
      //     event.stopPropagation();

      //     optionSelected = options.find(
      //       (o) => o.label === event.key.toString()
      //     );
      //     setCurrentValue(event.key.toString());
      //     event.stopPropagation();

      //     handleActionChange(optionSelected);
      //   } else {
      //     event.stopPropagation();
      //   }
      // }

      // if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      //   // event.stopPropagation();
      // }
      // }}
    />
  );
}
export default SelectEditor;
