import React from "react";
import { Card, CardBody, Collapse, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InfoFooter from "components/Info/InfoFooter"
import CardHeaderBold from "components/styled/CardHeaderBold";
import { recomputeResourcePlan } from "redux/Project/thunks";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "reactstrap"

const ResourcePlanInfo = (props) => {
  const content = useSelector(state => state);

  return (
    <Card>
      <CardHeaderBold>
        <span className="float-left">Resource Plan Info</span>
        <Label className="switch switch-default switch-primary">
          <Input
            type="checkbox"
            className="switch-input"
            onClick={() => props.toggle()}
            defaultChecked={props.panelVisible}
          />
          <span className="switch-label" />
          <span className="switch-handle" />
        </Label>
      </CardHeaderBold>
      <Collapse isOpen={props.panelVisible}>
        <CardBody>
          <h5>Context and resources for resource planning</h5>
          <p>
            Create a resource plan to document what resources (human) you need at what time and in what amounts for the project. A complete resource plan will:

			</p>
          <ol>
            <li>
              Identify when you need to add/remove resources to/from the project

				</li>
            <li>Determine what tasks each resource needs to work on</li>
            <li>Determine the total labor costs for the project</li>
          </ol>
          <p>
            It is good project management to keep your Resource plan up to date as
            you execute your project.
			</p>
          <h5><u>Building your plan</u></h5>
          <ol>
            <li>
              The Gantt chart provides a great visual of which tasks are active each week of the project. Click on a task name to see detailed information.

				</li>
            <li>
              Resource Plan – with the knowledge of the required skills and planned weeks for each task, select a specific resource with the required skill to work on that task

				</li>
            <li>
              Use Available Resources panel to pick the best available resource by clicking on the “Add” button. This action opens the Choose Initial Staffing Week window (Step 4).
				</li>
            <li>
              Use Choose Initial Staffing Week panel to select the week you need the resource to be available for task assignment.

				</li>

            <li>In the Resource Plan release resources when you no longer need them (no task need for their skill) on the project.
    </li>
            <li>Compare your planned budget numbers against your approved budget numbers for individual tasks using the “View Full Budget” button.
     </li>
            <li>Vacations cannot be added during planning. Update the resource plan to reflect any announced vacations during the execution of the project.
      </li>
          </ol>
          <h5>Editing your plan</h5>
          <p>
            Edit Individual cells by clicking in the cell. Resources can be removed from the plan and returned to available resources by clicking on the
			trashcan icon, <FontAwesomeIcon icon={faTrash} /> ,  to the far right of each row.
			</p>
          <p>
            <strong>
              Note: The Staff action in the 2 weeks prior to a resource arriving for task assignment should not be removed unless you are changing the arrival week. The Staff action triggers the automatic onboarding process as you execute your project. Without it, your resources will not arrive for assignment!!

				</strong>
          </p>
          
          <p>
            Sync up the resources and budget by clicking the Recompute button.
          </p>

          <Button style={{ marginLeft: '20px !important' }}
            outline
            color="primary"
            onClick={() => props.recomputeResourcePlan(content.project.runId)}
          >
            Recompute Budget
          </Button>

        </CardBody>
        <InfoFooter selectedKey="resource" showProjectLink={true} />
      </Collapse>
    </Card>
  )
}

const mapStateToProps = state => ({
  runId: state.project.runId
});

const mapDispatchToProps = dispatch => ({
  recomputeResourcePlan: (runId) => dispatch(recomputeResourcePlan(runId))
});

const RecomputeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcePlanInfo);

export default RecomputeContainer;