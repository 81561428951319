import React from "react";
import { connect } from "react-redux";
import { insertResourcePlan } from "redux/Resource/thunks";
import { addSingleAlert } from "redux/Notification/NotificationModule";
import _debounce from "lodash.debounce";

import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button
} from "reactstrap";

class AddResource extends React.Component {
 state = {
      startingWeek: this.props.currentWeek + 2, // need 2 weeks for staffing
      availableWeeks:[],
      addButtonDisabled: false
    };

  componentDidMount() {
    this.setState({
      availableWeeks:  this.getAvailableWeeks()
    })
  }

  getAvailableWeeks = () => {
    const wks = [];
    for (
      let i = this.props.currentWeek + 2; // need 2 weeks for staffing;
      i <= this.props.projectedFinishWeek + 3;
      i++
    ) {
      wks.push(i);
    }
    return wks;
  };

  handleStaffWeekChange = evt => {
    evt.preventDefault();
    this.setState({
      startingWeek: evt.target.value
    });
  };

  handleSave = _debounce(() => {
    // this.props.inser= tResourcePlan(
    //   this.props.runId,
    //   this.props.newResourceItem,
    //   this.state.startingWeek
    // );
    //
    this.setState({
      addButtonDisabled: true
    });
    Promise.all([
      this.props.insertResourcePlan(
        this.props.runId,
        this.props.newResourceItem,
        this.state.startingWeek
      )
    ]).then(() => {
      const alert = {
        id: 8,
        type: "success",
        headline: "Resource Added",
        event: "resourcePlan",
        message: `Resource : ${this.props.newResourceItem.WorkerName} scheduled to start during week : ${this.state.startingWeek}`
      };
      const notificationData = {
        info: `Resource : ${this.props.newResourceItem.WorkerName} scheduled to start during week : ${this.state.startingWeek}`,
        worker: this.props.newResourceItem.WorkerName,
        week: this.props.displayProjectWeek
      };
      this.props.addAlert(alert, notificationData, 3000, true);
    });
    this.props.closeModal();
  }, 125);

  render() {
    let iterKey = 1;
    return (
      <div>
        What week do you want&nbsp;
        {this.props.newResourceItem.WorkerName}&nbsp; to start on your project?
        <FormGroup id="weeks" >
          <Label>Enter Starting Week:</Label>
          <Input
            type="select"
            value={this.state.startingWeek}
            onChange={(event) => this.handleStaffWeekChange(event)}
            placeholder="Project Start Week"
          >
            {this.state.availableWeeks.map(wk => (
              <option key={(iterKey += 1)} value={wk}>
                {wk}
              </option>
            ))}
          </Input>

          {/* <Input.Feedback /> */}
          <FormFeedback>"choose a week"</FormFeedback>
        </FormGroup>
        <Button
          color="primary"
          onClick={(event) => this.handleSave(event)}
          disabled={this.state.addButtonDisabled}
        >
          Save
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentWeek: state.project.displayProjectWeek,
  projectedFinishWeek: state.project.projectedFinishWeek,
  runId: state.project.runId
});

const mapDispatchToProps = dispatch => ({
  insertResourcePlan: (runId, data, startingWeek) =>
    dispatch(insertResourcePlan(runId, data, startingWeek)),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist))
});

export const AddResourceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResource);
