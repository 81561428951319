import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ResourceImage from "./ResourceImage";

const TrainingTable4Display = props => {
  let iterKey = 1;
  return (
    <div className="component-Box component-ReferenceTable">
      <div className="box-content">
        <div className="component-Table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Course</th>
                <th>Project Week</th>
                <th>Resource</th>
              </tr>
            </thead>
            <tbody>
              {props.trainings
                .sort((a, b) => a.WeekNumber - b.WeekNumber)
                .map(row => (
                  <tr key={(iterKey += 1)}>
                    <td className="min300">{row.ClassName}</td>
                    <td className="text-center">{row.WeekNumber}</td>
                    <td>
                      <div className="resource-Item">
                        <div className="resource-Profile">
                          <ResourceImage
                            png={`${row.WorkerName.replace(/\s+/g, "")}.png`}
                            alt={row.WorkerName}
                          />
                        </div>
                        <div className="resource-Body">
                          <p>{row.WorkerName}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {window.location.pathname !== "/instructor/student-overview" &&
          window.location.pathname !== "/review" ? (
            <footer>
              <Link
                href="/planning/training"
                to="/planning/training"
                className="btn btn-outline-secondary"
              >
                Edit Training Plan
              </Link>
            </footer>
          ) : (
            <span />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trainings: state.planning.training.trainings
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingTable4Display);
