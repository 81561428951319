import React from "react";

export const Quality = (props) => {
  return (
    <div id="Quality" className="style-background-components">
      <header>
        <h2>Quality</h2>
      </header>
      <div>
        <h4>Project Quality</h4>
        <p>
          For those projects where a predetermined level of quality is required
          prior to project completion, quality is measured in the current number
          of "open" defects. Defects include any known discrepancy in any
          project documentation, development items, or deliverables. Defects are
          tracked from the very beginning of the project.
        </p>
        <p>
          Projects with a predetermined level of quality (maximum number of open
          defects) for project completion will not be allowed to finish until
          the required defect level is met. Any task tied to this project
          release criteria will have this requirement specified in their task
          description.
        </p>
        <h4>Defect Behavior</h4>
        <p>
          Most projects will start out finding more defects (open) than they are
          fixing (closed). As a result, the defect curve on the Quality report
          will rise during the beginning of the project. This typically
          continues until about halfway through the project, or when the project
          hits the integration/testing tasks.
        </p>
        <p>
          At that point the number of defects fixed (closed) is higher than
          those found (open) and as a result the defect curve of the Quality
          report will "turnover" and start to head back down.
        </p>
        <h4>Theoretical Defect Curve</h4>
        <p>
          Organizations that maintain and analyze historical defect data on past
          projects, can predict with some measure of certainty what the
          projected weekly number of "open" defects should be for a particular
          type of project. This Theoretical Defect Curve projection appears in
          the weekly Quality report. The projection is meant strictly as a
          benchmark.
        </p>

        <p>
          A project which displays an actual open defect rate that is higher
          than the Theoretical Defect Curve should take actions to address the
          quality focus on the project. Quality is an important objective of
          most {props.productId === 50 ? "Northrop Grumman" : "Uniworld"} projects and poor quality is a result not easily changed
          mid to late project.
        </p>
      </div>
    </div>
  );
};
