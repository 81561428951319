

import React from "react";

const BackToStep1Tooltip = () => (
    <div style={{textAlign: "left"}}>
       <p>Repeat Steps 1 through 5 until your project is complete.</p>
    </div>
  )

export default BackToStep1Tooltip
