import React from "react";
import imageURLs from "./images/imageURLs.js";

const QualityHelp = () => (
  <div>
    <h3>Quality Chart Info</h3>
    <h4>Context and resources for quality:</h4>
    <p>
      Delivering quality is just as important as meeting cost and schedule. You must meet your quality goals as stated in your Project Charter and task descriptions, failure to do so will delay your project. These goals are measured by the maximum number of open defects. Some projects measure software and hardware defects (added together equals project defects) and some just measure project defects. At Uniworld everything is measured for quality, including documents.

    </p>

    <h4>Defect curve:</h4>

    <p>
      The Quality chart tracks the number of “open” defects on your project. The Theoretical curve represents how many open total project defects you should expect to see on your project at any given week. Projects will find more defects than they are fixing at the beginning, thus the curves will naturally rise. At some point the team will start fixing more defects than they are finding, and the curve will turn over and start to drop. The turn over point usually happens around the integrating and testing tasks.

    </p>

    <img
      style={{ maxWidth: "100%", maxHeight: "100%",  marginBottom: "5px"}}

      src={imageURLs.QualityChart}
      alt="quality"
    />


    <h4>Impacting quality:</h4>
    <p>
    Changing your project quality is like trying to turn a big ship; you turn the wheel and wait quite a while for the ship to turn. Thus, by the time you realize you have a quality problem it is too late, so stay proactive with quality. It is also good to keep in mind that “If you want it bad, you get it bad”. Meaning a management focus on schedule will negatively impact quality.
    </p>
  </div>
);

export default QualityHelp;
