import React from "react";

export const AbsenteeismBis = () => {
  return (
    <div className="Absenteeism style-background-components">
      <h2>Absenteeism</h2>
      <p>
        Northrop Grumman Security Systems typically experiences a 2 % to 3 %
        absenteeism rate due to illnesses or other causes. You will need to pay
        attention to team member absenteeism and should it become chronic take
        appropriate action.{" "}
      </p>
      <p>
        Absenteeism also covers the missing of regularly schedule meetings,
        whether they are team meetings, individual conferences, or stakeholder
        meetings.
      </p>
    </div>
  );
};
