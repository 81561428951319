import React from "react";
import { connect } from "react-redux";
import { fetchProjectData } from "redux/Project/thunks";
import { getWBSData } from "redux/Reference/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faThumbtack
} from "@fortawesome/free-solid-svg-icons";

export const WBS = props => {
  const sortedBudgetList = props.budgetList.sort((a, b) => {
    const numA = a.description.match(/(\d+\.\d+)/) ? parseFloat(a.description.match(/(\d+\.\d+)/)[0]) : 0;
    const numB = b.description.match(/(\d+\.\d+)/) ? parseFloat(b.description.match(/(\d+\.\d+)/)[0]) : 0;
    return numA - numB;
  })
  let i = 1;

  let renderTitle = () => {
    let productId = props.productId;
    return productId === 50 ? (
      <h2>PACCISS Work Breakdown Structure</h2>
    ) : (
      <h2>Alliance Work Breakdown Structure</h2>
    );
  };

  return (
    <div id="WBS" className="style-background-components">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <header>{renderTitle()}</header>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>
                  <FontAwesomeIcon icon={faAngleDoubleDown} id="wbs-icon" />
                 {props.productId === 50 ? "PACCISS Project" : "Alliance Development Project"}
                </th>
                <th>
                  <FontAwesomeIcon icon={faThumbtack} id="wbs-icon" />
                  Task #
                </th>
              </tr>
            </thead>
            <tbody>
                  {sortedBudgetList.map((row, index) => (
                <tr key={index}>
                  <td>{row.description}</td>
                  <td>{row.task}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  runId: state.project.runId,
  budgetList: getWBSData(state),
  productId: state.auth.productId
});

const mapDispatchToProps = dispatch => ({
  fetchProjectData: runId => dispatch(fetchProjectData(runId))
});

export const WBSContainer = connect(mapStateToProps, mapDispatchToProps)(WBS);
