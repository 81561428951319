import React from 'react'


export const Conference = (props) => {
  return (
    <div className="content">
      <h6>The {props.properties.topic} conference was held.</h6>
    </div>
 )
}
