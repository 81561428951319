import React from "react";
import { useDispatch } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon }from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { openModal } from "redux/Modal/ModalModule";
import {
  HELP_DOCUMENTATION_MODAL
} from "routes/Modal/ModalTypes";

const InfoButton = (props) => {
  const dispatch = useDispatch();
  let size="2x"
  if (props.size) {
    size = props.size
  }
  return (
    <>
    <Button
       id={`infoButton-${props.selectedKey}`}
      className="float-right"
      color="link"
      onClick={() =>
        dispatch(
          openModal(
            HELP_DOCUMENTATION_MODAL,
            { selectedKey: props.selectedKey },
            "modal-wide"
          )
        )
      }
    >
    <FontAwesomeIcon size={size} icon={faInfoCircle} />


    </Button>
    <UncontrolledTooltip
        placement="top"
        target={`infoButton-${props.selectedKey}`}
      >
        Click to view the help document for {props.selectedKey}
      </UncontrolledTooltip>
      </>
  );
};
export default InfoButton;
