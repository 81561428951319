import React from "react";
import {
    Badge,
    CardBody
} from "reactstrap";

const ClassroomInfo = () =>  {
        return (
            <CardBody>
                <h5>Classrooms</h5>
                <p>
                    The initial classroom status is{" "}
                    <Badge color="warning">Registration</Badge>. You'll want to
                  change the status to <Badge color="success">Running</Badge>{" "}
                    when you are ready for students to start working. Often, this
                    is something you want to do right away.
                </p>
                <p>
                    You should delay changing the classroom status if you want
                    students to be able to place themselves into your classroom,
                    but don't want them taking any project actions, like planning
                    or executing their projects.
                </p>
                <h5>Executions</h5>
                <p>
                    If you want students to run through their first execution
                    right away, but wait until a lecture or other milestone before
                    they start their second execution. The third button allows you
                    to stop executions on a Run Week basis. So, you can stop all
                    students from moving past a certain point for each execution.
                    Finally, there is a help button that contains an Instructor
                    Manual and several web pages with information about using
                  SimProject. Always{" "}
                    <a href="mailto:support@simulationpl.com">contact Support</a>{" "}
                    if you need help. There are multiple people monitoring to that
                    email account.
                </p>
            </CardBody>
        )
    
}

export default ClassroomInfo
