import { createAction } from "redux-actions";
import {
DATA_REQUESTED,
CHART_DATA_RECEIVED,
HISTORY_RECEIVED,
  INTERIM_HISTORY_RECEIVED,
LABOR_SUMMARY_RECEIVED,
  REPORT_ERROR
} from "./types"

export const dataRequested = createAction(DATA_REQUESTED);
export const chartDataReceived = createAction(CHART_DATA_RECEIVED, payload => payload);
export const historyReceived = createAction(
  HISTORY_RECEIVED,
  payload => payload
);
export const interimHistoryReceived = createAction(
  INTERIM_HISTORY_RECEIVED,
  payload => payload
);
export const laborSummaryReceived = createAction(LABOR_SUMMARY_RECEIVED, payload => payload);


export const reportError = createAction(REPORT_ERROR, error => error);
