import { createSelector } from "reselect";

const getReserveHoursSummary = (state) => state.report.reserveSummaryData;
const getTaskHoursSummary = (state) => state.report.taskHoursSummary;
const getLostHoursSummary = (state) => state.report.lostHoursSummary;
const getTaskHoursSummaryNew = (state) => state.report.taskHoursSummaryNew;

const getMaxWeek = (data) => {
  return Math.max.apply(
    Math,
    data.map(function (o) {
      return o.weekNumber;
    })
  );
};

export const getTaskHoursSummaryData = createSelector(
  [getTaskHoursSummary],
  (taskHoursSummary) => {
    let returnArray = [];

    const taskHoursSorted = [
      ...taskHoursSummary.sort((a, b) =>
        Number(a.taskId) > Number(b.taskId)
          ? 1
          : Number(b.taskId) > Number(a.taskId)
          ? -1
          : 0
      )
    ];
    const maxWeek = getMaxWeek(taskHoursSummary);

    let keys = Object.keys(taskHoursSorted);
    for (let i = 0; i < keys.length; i++) {
      const row = taskHoursSorted[i];
      // does return array already contain a row for this task
      const rowObj = {
        taskId: row.taskId,
        totalHours: 0.0,
        totalCosts: 0.0
      };

      let key = "";

      for (let i = 1; i <= maxWeek; i += 1) {
        key = i.toString();
        rowObj[key] = 0; // task hours by week
      }
      rowObj.totalHours = 0;
      rowObj.totalCosts = 0;

      returnArray.push(rowObj);
    }
    const uniqueArray = returnArray.filter(
      (row, index, self) =>
        index === self.findIndex((t) => t.taskId === row.taskId)
    );

    for (let i = 0; i < keys.length; i++) {
      const row = taskHoursSorted[i];

      let index = uniqueArray.findIndex((s) => s.taskId === row.taskId);

      let weekCol = row.weekNumber;

      uniqueArray[index].totalCosts += row.cost;
      uniqueArray[index].totalHours += row.regHours + row.otHours;
      uniqueArray[index][weekCol] += row.regHours + row.otHours;
    }
    return uniqueArray;
  }
);

export const getTaskHoursSummaryNewData = createSelector(
  [getTaskHoursSummaryNew, getTaskHoursSummary, getReserveHoursSummary],
  (taskHoursSummaryNew, taskHoursSummary, reserveHours) => {
    let returnArray = [];

    const taskHoursSorted = [
      ...taskHoursSummaryNew.sort((a, b) =>
        Number(a.RowKey) > Number(b.RowKey)
          ? 1
          : Number(b.RowKey) > Number(a.RowKey)
          ? -1
          : 0
      )
    ];

    const maxWeek = getMaxWeek(taskHoursSummary);

    let keys = Object.keys(taskHoursSorted);
    for (let i = 0; i < keys.length; i++) {
      const row = taskHoursSorted[i];
      // does return array already contain a row for this task
      var rowExistsIndex = returnArray.findIndex((r) => r.event === row.RowKey);

      if (
        rowExistsIndex !== null &&
        returnArray[rowExistsIndex] !== undefined
      ) {
        returnArray[rowExistsIndex].totalCosts += row.SummaryCosts;
        returnArray[rowExistsIndex].totalHours += row.SummaryHours;
        returnArray[rowExistsIndex][row.WeekNumber] += row.SummaryHours;
      } else {
        let key = "";

        const rowObj = {
          event: row.RowKey,
          description:
            row.RowKey === "0"
              ? "Task related hours - charged to reserves"
              : row.RowKey, //0.0
          totalCosts: 0,
          totalHours: 0
        };
        for (let i = 1; i <= maxWeek; i += 1) {
          key = i.toString();
          if (key.toString() === row.WeekNumber.toString()) {
            rowObj[key] = row.SummaryHours;
          } else {
            rowObj[key] = 0; // task hours by week
          }
        }

        rowObj.totalCosts = row.SummaryCosts;
        rowObj.totalHours = row.SummaryHours;

        returnArray.push(rowObj);
      }
    }
    return returnArray;
  }
);

export const getReserveSummaryData = createSelector(
  [getReserveHoursSummary, getTaskHoursSummary],
  (reserveHoursList, taskHoursSummary) => {
    const reserveHours = [
      ...reserveHoursList.sort((a, b) =>
        Number(a.weekNumber) > Number(b.weekNumber)
          ? 1
          : Number(b.weekNumber) > Number(a.weekNumber)
          ? -1
          : 0
      )
    ];
    const maxWeek = getMaxWeek(taskHoursSummary);

    const reserveHrsContent = [];

    const taskWaitingRow = {
      event: "task-waiting",
      description: "Resources on tasks waiting for predecessor task to finish"
    };
    // data : rowKey = task-waiting, weekNumber, hours, costs
    //
    let key = "";
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      taskWaitingRow[key] = 0; // task hours by week  taskWaitingRow[data.weekNumber] = hours;
      // taskWaitingRow.totalHours += data.hours;
      // taskWaitingRow.totalCosts += data.costs;
    }
    taskWaitingRow.totalHours = 0;
    taskWaitingRow.totalCosts = 0;

    const noTaskRow = {
      event: "no-task",
      description: "Resources on the project but not assigned to any task"
    };

    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      noTaskRow[key] = 0; // task hours by week
    }
    noTaskRow.totalHours = 0;
    noTaskRow.totalCosts = 0;

    const delayedTaskRow = {
      event: "task-delayed",
      description: "Resources on delayed tasks "
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      delayedTaskRow[key] = 0; // task hours by week
    }
    delayedTaskRow.totalHours = 0;
    delayedTaskRow.totalCosts = 0;

    const otherCostsRow = {
      event: "other-costs",
      description: "Other costs"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      otherCostsRow[key] = 0; // task hours by week
    }
    otherCostsRow.totalHours = 0;
    otherCostsRow.totalCosts = 0;

    const researchCostsRow = {
      event: "research-this",
      description: "Non-zero data needs researching to capture the right bucket"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      researchCostsRow[key] = 0; // task hours by week
    }
    researchCostsRow.totalHours = 0;
    researchCostsRow.totalCosts = 0;

    let weeklyRows = [];
    for (let i = 1; i <= maxWeek; i += 1) {
      weeklyRows = [...reserveHours.filter((s) => Number(s.WeekNumber) === i)];
      if (weeklyRows && weeklyRows.length > 0) {
        const keys = Object.keys(weeklyRows);

        for (let j = 0; j < keys.length; j += 1) {
          switch (weeklyRows[j].RowKey.toLowerCase()) {
            case "task-delayed":
              delayedTaskRow[i.toString()] += weeklyRows[j].SummaryHours;
              delayedTaskRow.totalHours += weeklyRows[j].SummaryHours;
              delayedTaskRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "no-task":
              noTaskRow[i.toString()] += weeklyRows[j].SummaryHours;
              noTaskRow.totalHours += weeklyRows[j].SummaryHours;
              noTaskRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "task-waiting":
              taskWaitingRow[i.toString()] += weeklyRows[j].SummaryHours;
              taskWaitingRow.totalHours += weeklyRows[j].SummaryHours;
              taskWaitingRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "other-costs":
              otherCostsRow[i.toString()] += weeklyRows[j].SummaryHours;
              otherCostsRow.totalHours += weeklyRows[j].SummaryHours;
              otherCostsRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            default:
              // case "research-this":

              researchCostsRow[i.toString()] += weeklyRows[j].SummaryHours;
              researchCostsRow.totalHours += weeklyRows[j].SummaryHours;
              researchCostsRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
          }
        }
      }
    }

    reserveHrsContent.push(taskWaitingRow);
    reserveHrsContent.push(noTaskRow);
    reserveHrsContent.push(delayedTaskRow);
    reserveHrsContent.push(otherCostsRow);
    reserveHrsContent.push(researchCostsRow);

    return reserveHrsContent;
  }
);

export const getLostHoursSummaryData = createSelector(
  [getLostHoursSummary, getTaskHoursSummary],
  (lostHoursSummary, taskHoursSummary) => {
    const maxWeek = getMaxWeek(taskHoursSummary);
    const lostHoursList = [
      ...lostHoursSummary.sort((a, b) =>
        Number(a.weekNumber) > Number(b.weekNumber)
          ? 1
          : Number(b.weekNumber) > Number(a.weekNumber)
          ? -1
          : 0
      )
    ];

    const lhRows = [];
    const meetingRow = {
      event: "meeting",
      description: "Team Meetings"
    };
    let key = "";
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      meetingRow[key] = 0; // task hours by week
    }
    meetingRow.resHours = 0;
    meetingRow.totalHours = 0;
    meetingRow.totalCosts = 0;

    const conferenceRow = {
      event: "conference",
      description: "Individual Conference"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      conferenceRow[key] = 0; // task hours by week
    }
    conferenceRow.resHours = 0;
    conferenceRow.totalHours = 0;
    conferenceRow.totalCosts = 0;

    const stakeholderRow = {
      event: "visit",
      description: "Visits"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      stakeholderRow[key] = 0; // task hours by week
    }
    stakeholderRow.resHours = 0;
    stakeholderRow.totalHours = 0;
    stakeholderRow.totalCosts = 0;

    const trainingRow = {
      event: "training",
      description: "Trainings"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      trainingRow[key] = 0; // task hours by week
    }
    trainingRow.resHours = 0;
    trainingRow.totalHours = 0;
    trainingRow.totalCosts = 0;

    const absenceRow = {
      event: "absence",
      description: "Absence"
    };
    for (let i = 1; i <= maxWeek; i += 1) {
      key = i.toString();
      absenceRow[key] = 0; // task hours by week
    }
    absenceRow.resHours = 0;
    absenceRow.totalHours = 0;
    absenceRow.totalCosts = 0;

    let weeklyRows = [];

    for (let i = 1; i <= maxWeek; i += 1) {
      weeklyRows = [...lostHoursList.filter((s) => Number(s.WeekNumber) === i)];
      if (weeklyRows && weeklyRows.length > 0) {
        const keys = Object.keys(weeklyRows);
        // per jesse, reserve hours get added to each weekly display but do not
        // get added into the 2 final summary columns for hours/costs
        // only to the reserve column hours total
        // LT - 5/24
        for (let j = 0; j < keys.length; j += 1) {
          switch (weeklyRows[j].RowKey.toLowerCase()) {
            case "absence":
              absenceRow[i.toString()] += weeklyRows[j].SummaryHours;
              absenceRow.totalHours += weeklyRows[j].SummaryHours;
              absenceRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "res-absence":
              absenceRow[i.toString()] += weeklyRows[j].SummaryHours;
              absenceRow.resHours += weeklyRows[j].SummaryHours;
              absenceRow.totalCosts += weeklyRows[j].SummaryCosts;

              break;
            case "meeting":
              meetingRow[i.toString()] += weeklyRows[j].SummaryHours;
              meetingRow.totalHours += weeklyRows[j].SummaryHours;
              meetingRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "res-meeting":
              meetingRow[i.toString()] += weeklyRows[j].SummaryHours;
              meetingRow.resHours += weeklyRows[j].SummaryHours;
              meetingRow.totalCosts += weeklyRows[j].SummaryCosts;

              break;
            case "conference":
              conferenceRow[i.toString()] += weeklyRows[j].SummaryHours;
              conferenceRow.totalHours += weeklyRows[j].SummaryHours;
              conferenceRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "res-conference":
              conferenceRow[i.toString()] += weeklyRows[j].SummaryHours;
              conferenceRow.resHours += weeklyRows[j].SummaryHours;
              conferenceRow.totalCosts += weeklyRows[j].SummaryCosts;

              break;
            case "training":
              trainingRow[i.toString()] += weeklyRows[j].SummaryHours;
              trainingRow.totalHours += weeklyRows[j].SummaryHours;
              trainingRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "res-training":
              trainingRow[i.toString()] += weeklyRows[j].SummaryHours;
              trainingRow.resHours += weeklyRows[j].SummaryHours;
              trainingRow.totalCosts += weeklyRows[j].SummaryCosts;

              break;
            case "stakeholder":
              stakeholderRow[i.toString()] += weeklyRows[j].SummaryHours;
              stakeholderRow.totalHours += weeklyRows[j].SummaryHours;
              stakeholderRow.totalCosts += weeklyRows[j].SummaryCosts;
              break;
            case "res-stakeholder":
              stakeholderRow[i.toString()] += weeklyRows[j].SummaryHours;
              stakeholderRow.resHours += weeklyRows[j].SummaryHours;
              stakeholderRow.totalCosts += weeklyRows[j].SummaryCosts;

              break;

            // this is bucket where non categorized data goes in the api,
            //  we think we got them all but just in case you miss some costs,
            // uncomment the below case to research
            // // case "research-this":
            // costs = weeklyRows.reduce((acc, curr) => acc + curr.costs, 0);
            // hours = weeklyRows.reduce((acc, curr) => acc + curr.hours, 0);
            // researchCostsRow[i.toString()] += hours;
            // researchCostsRow.totalHours += hours;
            // researchCostsRow.totalCosts += costs;
            default:
              break;
          }
        }
      }
    }

    lhRows.push(meetingRow);
    lhRows.push(conferenceRow);
    lhRows.push(trainingRow);
    lhRows.push(stakeholderRow);
    lhRows.push(absenceRow);
    return lhRows;
  }
);
