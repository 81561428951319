import { createAction } from "redux-actions";
import {
  REQUEST_TRAININGS,
  RECEIVE_TRAININGS,
  DELETE_TRAININGPLAN_SUCCESS,
  DELETE_TRAININGPLAN_ERROR,
  SAVE_TRAININGPLAN_SUCCESS,
  SAVE_TRAININGPLAN_ERROR,
  REQUEST_ALL_WORKERS,
  RECEIVE_ALL_WORKERS,
  REQUEST_ALL_TRAININGS,
  RECEIVE_ALL_TRAININGS,
  REQUEST_MAX_WEEKS,
  RECEIVE_MAX_WEEKS,
  TRAININGPLAN_ERROR
} from "./types";

// ------------------------------------
// Actions
// ------------------------------------

export const receiveTrainings = createAction(
  RECEIVE_TRAININGS,
  payload => payload
);
export const requestTrainingPlan = createAction(REQUEST_TRAININGS);
export const deleteTrainingPlanSuccess = createAction(
  DELETE_TRAININGPLAN_SUCCESS,
  payload => payload
);
export const deleteError = createAction(
  DELETE_TRAININGPLAN_ERROR,
  error => error
);
export const saveTrainingPlanSuccess = createAction(
  SAVE_TRAININGPLAN_SUCCESS,
  payload => payload
);

export const saveError = createAction(SAVE_TRAININGPLAN_ERROR, error => error);

export const receiveAllWorkers = createAction(
  RECEIVE_ALL_WORKERS,
  payload => payload
);

export const requestAllWorkers = createAction(REQUEST_ALL_WORKERS);
export const receiveAllTrainings = createAction(
  RECEIVE_ALL_TRAININGS,
  payload => payload
);

export const requestAllTrainings = createAction(
  REQUEST_ALL_TRAININGS,
  payload => payload
);

export const receiveMaxWeeks = createAction(
  RECEIVE_MAX_WEEKS,
  payload => payload
);

export const requestMaxWeeks = createAction(REQUEST_MAX_WEEKS);
export const actionError = createAction(TRAININGPLAN_ERROR, error => error);
