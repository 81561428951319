import { findMessage } from "utilities/feedbackMessages";
import {
  toggleRecognitionInfo,
  toggleResourceInfo,
  toggleRiskInfo,
  toggleStakeholderInfo,
  toggleTrainingInfo,
  toggleWorkWeekInfo,
  toggleRecognitionBudget,
  toggleResourceBudget,
  riskBudgetPanel,
  toggleStakeholderBudget,
  toggleTrainingBudget,
  toggleTourOpen
} from "./actions";

export const toggleTourComponentOpen = (toggleState, tc) => {
  const payload = {
    isTourOpen: true,
    tourConfig: tc
  };

  return dispatch => {
    dispatch(toggleTourOpen(payload));
  };
};
export const toggleTourComponentClosed = () => {
  // STORM - TODO - how to close the checklist from here if it's open. :)
  const payload = {
    isTourOpen: false,
    tourConfig: []
  };

  return dispatch => {
    dispatch(toggleTourOpen(payload));
  };
};

export const findFeedbackMessage = (msgId, msgProps) => {
  const msg = findMessage(msgId, msgProps);

  return msg;
};

export const toggleInfoPanel = route => {
  const routeLower = route.toLowerCase();
  return dispatch => {
    switch (routeLower) {
      case "recognition":
        dispatch(toggleRecognitionInfo());
        break;
      case "resource":
        dispatch(toggleResourceInfo());
        break;
      case "risk":
        dispatch(toggleRiskInfo());
        break;
      case "stakeholder":
        dispatch(toggleStakeholderInfo());
        break;
      case "training":
        dispatch(toggleTrainingInfo());
        break;
      case "workWeek":
        dispatch(toggleWorkWeekInfo());
        break;
      default:
        break;
    }
  };
};

export const toggleBudgetPanel = route => {
  const routeLower = route.toLowerCase();
  return dispatch => {
    switch (routeLower) {
      case "recognition":
        dispatch(toggleRecognitionBudget());
        break;
      case "resource":
        dispatch(toggleResourceBudget());
        break;
      case "risk":
        dispatch(riskBudgetPanel());
        break;
      case "stakeholder":
        dispatch(toggleStakeholderBudget());
        break;
      case "training":
        dispatch(toggleTrainingBudget());
        break;
      default:
        break;
    }
  };
};
