import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Collapse,
  Label,
  Input
} from "reactstrap";
import { toggleStaffingInfo } from "app/redux/actions"
import CardHeaderBold from "components/styled/CardHeaderBold";
import InfoFooter from "components/Info/InfoFooter"
import HeaderButton from "components/styled/HeaderButton";
import Info from "./Info"
const StaffingInfo = () => {
  const content = useSelector(state => state);
  const dispatch = useDispatch();

  return (
       <Card className="Card-info">
              <CardHeaderBold>
                <span className="pull-left">Staffing Info</span>
                 {/* <HeaderButton
                  outline
                  color="primary"
                  onClick={() => console.log("start the tour")}
                >
                  Take the Tour (not working)
                </HeaderButton> */}
                <Label className="switch switch-default switch-primary">
                  <Input
                    type="checkbox"
                    className="switch-input"
                    onClick={() => dispatch(toggleStaffingInfo())}
                    defaultChecked={content.app.staffingInfoVisible}
                  />
                  <span className="switch-label" />
                  <span className="switch-handle" />
                </Label>
              </CardHeaderBold>
      <Collapse isOpen={content.app.staffingInfoVisible}>
        <Info />
              <InfoFooter selectedKey="staffing" showProjectLink={true} />
              </Collapse>
            </Card>
  );
};

export default StaffingInfo



/*

*/
