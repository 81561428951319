import React from "react";

export const TeamTraining = (props) => {
  return (
    <div className="content">
      <h6>
        The team attended <strong>{props.properties.training}</strong>
      </h6>
    </div>
  );
};
