import { CardHeader } from "reactstrap";
import styled from "styled-components";

const CardHeaderBold = styled(CardHeader)`
  display: flex;
  font-weight: bold;
  align-items: center;

  .pull-right {
    margin-left: auto;
  }

  .btn {
    margin-top: 0;
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
      margin-left: 10px !important;
    }
  }
`;

export default CardHeaderBold;
