import {
  REQUEST_RESOURCEPLAN,
  RECEIVE_RESOURCEPLAN,
  UPDATE_RESOURCEPLAN_SUCCESS,
  INSERT_RESOURCEPLAN_SUCCESS,
  DELETE_RESOURCEPLAN_SUCCESS,
  ADD_MORE_ITEM_MAPPINGS,
  RESOURCE_ERROR,
  SAVE_RESOURCEPLANITEM_SUCCESS,
  SET_FILTER_SKILLID,
  UPDATE_RESOURCEPLANITEM_STATE
} from "./types";

const initialState = {
  resourcePlan: [],
  resourcePlanDisplay: [],
  detailInfoWeekMap: [],
  isFetching: false,
  runId: 0,
  maxWeeks: 0,
  tasks: [],
  resourceActionTasks: [],
  resPlanColumnMetadata: [],
  actionTasks: [],
  availableWorkers: [],
  workers: [],
  skills: [],
  selectedFilterSkillId: 0
};
export default function resourceReducer(state = initialState, action) {
  let index = 0; // constiable used in reducer - prevents redefine
  switch (action.type) {
    case REQUEST_RESOURCEPLAN: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case RECEIVE_RESOURCEPLAN: {

      return Object.assign({}, state, {
        resourcePlan: action.response.ResourcePlanViewList,
        resourcePlanDisplay: action.response.ResourcePlanDisplayList,
        detailInfoWeekMap: action.response.DetailInfoWeekMap,
        isFetching: false
      });
    }
    case RESOURCE_ERROR: {
      console.log(action.error);
      return state;
    }
    case UPDATE_RESOURCEPLAN_SUCCESS: {
      // here if we have a new resource, we need to add them with the new id to the
      // collection, if we've only updated, then return current state
      // which already contains the updates
      // todo find item and update with new id number

      const returnRes = JSON.parse(action.data);

      index = state.resourcePlanDisplay.findIndex(m => m.Id === returnRes.Id);
      return Object.assign({}, state, {
        resourcePlanDisplay: state.resourcePlanDisplay
          .slice(0, index)
          .concat(returnRes)
          .concat(state.resourcePlanDisplay.slice(index + 1))
      });
    }
    case INSERT_RESOURCEPLAN_SUCCESS: {
      // here if we have a new resource, we need to add them with the new id to the
      // collection, if we've only updated, then return current state
      // which already contains the updates
      // todo find item and update with new id number
      const newResource = action.data.resourcePlanDisplayObj;
      const newDisplayMapping = action.data.displayMapping;

      return Object.assign({}, state, {
        resourcePlanDisplay: [...state.resourcePlanDisplay, newResource],
        detailInfoWeekMap: [...state.detailInfoWeekMap, ...newDisplayMapping]
      });
    }
    case DELETE_RESOURCEPLAN_SUCCESS: {
      return state;
      // return Object.assign({}, state, {
      //   resourcePlanDisplay: [
      //     ...state.resourcePlanDisplay.slice(0, action.index),
      //     ...state.resourcePlanDisplay.slice(
      //       action.index + 1,
      //       state.resourcePlanDisplay.length
      //     )
      //   ]
      // });
    }
    case ADD_MORE_ITEM_MAPPINGS: {
      // here if we have a new resource, we need to add them with the new id to the
      // collection, if we've only updated, then return current state
      // which already contains the updates
      // todo find item and update with new id number
      const mappings = action.data.displayMapping;
      return Object.assign({}, state, {
        detailInfoWeekMap: [...state.detailInfoWeekMap, mappings]
      });
    }
    case UPDATE_RESOURCEPLANITEM_STATE: {
      // update display before api finishes 
      const row = action.payload;
      index = state.resourcePlanDisplay.findIndex(m => m.Id === row.Id);

      return Object.assign({}, state, {
        resourcePlanDisplay: state.resourcePlanDisplay
          .slice(0, index)
          .concat(row)
          .concat(state.resourcePlanDisplay.slice(index + 1))
      });
    }
    case SAVE_RESOURCEPLANITEM_SUCCESS: {
      // item is already in the collection, we've just updated the db with the new action
      const returnObj = JSON.parse(action.data);
      index = state.resourcePlanDisplay.findIndex(m => m.Id === returnObj.Id);

      return Object.assign({}, state, {
        resourcePlanDisplay: state.resourcePlanDisplay
          .slice(0, index)
          .concat(returnObj)
          .concat(state.resourcePlanDisplay.slice(index + 1))
      });
    }
    case SET_FILTER_SKILLID: {
      return Object.assign({}, state, {
        selectedFilterSkillId: action.id
      });
    }
    default: {
      return state;
    }
  }
}
