import { createAction } from "redux-actions";

import {
  AUTH_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GETUSER_SUCCESS,
  GETUSER_FAILURE,
  LOGOUT,
  DATA_REQUEST,
  SET_MASTERCONTROL_USER,
  EXPIRED_ACCOUNT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RECEIVE_SUCCESS,
  RECEIVE_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_USER,
  GET_AVAILABLE_CLASSES_FAILURE,
  GET_AVAILABLE_CLASSES_SUCCESS,
  PLACE_IN_CLASSROOM_SUCCESS,
  PLACE_IN_CLASSROOM_FAILURE,
  LICENSE_ACCEPTED_SUCCESS,
  STORE_PASSWORD,
  UPDATE_USERCONTROL_SUCCESS,
  SET_LOGIN_MESSAGE,
  GETMESSAGE_SUCCESS,
  GETMESSAGE_FAILURE
} from "./types";
// ------------------------------------
// Actions
// ------------------------------------
export const requestLogin = createAction(AUTH_REQUEST);
export const receiveLogin = createAction(LOGIN_SUCCESS, payload => payload);
export const invalidLogin = createAction(LOGIN_FAILURE, payload => payload);
export const receiveUser = createAction(GETUSER_SUCCESS, payload => payload);
export const invalidUser = createAction(GETUSER_FAILURE, payload => payload);
export const doLogout = createAction(LOGOUT, payload => payload);
export const receiveMasterControlUser = createAction(
  SET_MASTERCONTROL_USER,
  payload => payload
);
export const requestPassword = createAction(AUTH_REQUEST);
export const expiredAccount = createAction(EXPIRED_ACCOUNT, payload => payload);
export const forgotPasswordSuccess = createAction(
  FORGOT_PASSWORD_SUCCESS,
  payload => payload
);
export const forgotPasswordFailure = createAction(
  FORGOT_PASSWORD_FAILURE,
  payload => payload
);
export const resetPasswordSuccess = createAction(
  RESET_PASSWORD_SUCCESS,
  payload => payload
);
export const setLoginMessage = createAction(
  SET_LOGIN_MESSAGE,
  payload => payload
);
export const resetPasswordFailure = createAction(
  RESET_PASSWORD_FAILURE,
  payload => payload
);
export const receiveSuccess = createAction(RECEIVE_SUCCESS, payload => payload);
export const receiveError = createAction(RECEIVE_ERROR, payload => payload);
export const registerSuccess = createAction(
  REGISTER_SUCCESS,
  payload => payload
);
export const registerFailure = createAction(
  REGISTER_FAILURE,
  payload => payload
);
export const requestData = createAction(DATA_REQUEST);

export const setUserForPasswordReset = createAction(
  SET_USER,
  payload => payload
);

export const setUserForRegistration = createAction(
  SET_USER,
  payload => payload
);
export const getAvailableClassesError = createAction(
  GET_AVAILABLE_CLASSES_FAILURE,
  payload => payload
);
export const getAvailableClassesSuccess = createAction(
  GET_AVAILABLE_CLASSES_SUCCESS,
  payload => payload
);
export const placeInClassroomError = createAction(
  PLACE_IN_CLASSROOM_FAILURE,
  payload => payload
);
export const placeInClassroomSuccess = createAction(
  PLACE_IN_CLASSROOM_SUCCESS,
  payload => payload
);
export const licenseAcceptedSuccess = createAction(
  LICENSE_ACCEPTED_SUCCESS,
  payload => payload
);
export const savePasswordToState = createAction(
  STORE_PASSWORD,
  payload => payload
);
export const updateUserControlSuccess = createAction(
  UPDATE_USERCONTROL_SUCCESS,
  payload => payload
);

export const getMessageSuccess = createAction(
  GETMESSAGE_SUCCESS,
  payload => payload
);

export const getMessageFailure = createAction(GETMESSAGE_FAILURE);
