import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export default class StakeholderPlan4Display extends React.Component {
  renderWeekHeaderTags() {
    const headerTags = [];
    for (let week = 1; week <= this.props.projectedFinishWeek; week++) {
      const wkHdr = `W${week}`;
      headerTags.push(<th key={week}>{wkHdr}</th>);
    }
    return headerTags;
  }

  renderActionColumns(row) {
    const actionCols = [];
    for (let i = 1; i <= this.props.projectedFinishWeek; i++) {
      const week = `W${i.toString()}`;
      // are we in the current week?  add extra style tag
      const currentVal = row[week];
      if (i === this.props.displayProjectWeek) {
        actionCols.push(
          <td className="table-Current text-center" key={i}>
            <span
              style={{
                opacity: currentVal === "None" ? "0" : "1"
              }}
            >
              {currentVal}
            </span>
          </td>
        );
      } else {
        actionCols.push(
          <td key={i} className="text-center">
            <span
              style={{
                opacity: currentVal === "None" ? "0" : "1"
              }}
            >
              {currentVal}
            </span>
          </td>
        );
      }
    }
    return actionCols;
  }
  render() {
    let iterKey = 1;
    return (
      <div className="compontent-Box component-ReferenceTable">
        <div className="box-content">
          <div className="component-Table">
            <div className="table-fixed">
              <table className="table table-StakeholderPlan">
                <thead>
                  <tr>
                    <th className="col-fix--first stakeholder-plan-4-display">
                      Name
                    </th>
                    {this.renderWeekHeaderTags()}
                  </tr>
                </thead>
                <tbody>
                  {this.props.stakeholderPlan.map((row, index) => (
                    <tr key={(iterKey += 1)}>
                      <td
                        key={(iterKey += 1)}
                        className="col-fix--first stakeholder-plan-4-display"
                      >
                        {row.StakeholderDescription}
                      </td>
                      {this.renderActionColumns(row)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {window.location.pathname !== "/instructor/student-overview" &&
            window.location.pathname !== "/review" ? (
              <footer>
                <Link
                  href="/planning/stakeholder"
                  to="/planning/stakeholder"
                  className="btn btn-outline-secondary"
                >
                  Edit Stakeholder Plan
                </Link>
              </footer>
            ) : (
              <span />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stakeholderPlan: state.planning.stakeholder.stakeholderPlan,
  projectedFinishWeek: state.project.projectedFinishWeek,
  displayProjectWeek: state.project.displayProjectWeek
});

const mapDispatchToProps = dispatch => ({});

export const StakeholderPlan4DisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StakeholderPlan4Display);
