// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_RISKS = "REQUEST_RISKS";
export const RECEIVE_RISKS = "RECEIVE_RISKS";
export const SAVE_RISK_REQUEST = "SAVE_RISK_REQUEST";
export const SAVE_RISK_SUCCESS = "SAVE_RISK_SUCCESS";
export const RISK_ERROR = "RISK_ERROR";
export const DELETE_RISK = "DELETE_RISK";
export const DELETE_RISK_SUCCESS = "DELETE_RISK_SUCCESS";
export const DELETE_RISK_ERROR = "DELETE_RISK_ERROR";
