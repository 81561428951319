import React from "react";
import ReactDOM from "react-dom";
import CreateStore from "app/store";
import { loadState, saveState } from "app/store/localStorage";
import throttle from "lodash/throttle"; // only import the throttle, not all of lodash
import Root from "app/Root";
import history from "./history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let env = "production"
if (window.location.href.includes("test")) {
  env = "test"
} else if (window.location.href.includes("local")) {
  env = "local"
}
if (env !== "local") {
  Sentry.init({
    dsn: "https://c9ef9da176064f05b4bf0b9b50ae2700@o613130.ingest.sentry.io/5758804",
    integrations: [new Integrations.BrowserTracing()],
    release: "Simproject",
    environment: env,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded"
    ],
    tracesSampleRate: 1.0
  });
}


require("react-hot-loader/patch");

let initialState = {};
// const RELEASE = '0.1.0';  //for sentry

if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)) {
  const persistedState = loadState();
  /* eslint-disable no-underscore-dangle */
  initialState =
    persistedState === undefined ? window.___INITIAL_STATE__ : persistedState;
} else {
  initialState = window.___INITIAL_STATE__;

}

/* eslint-enable */

const store =
  module.hot && module.hot.data && module.hot.data.store
    ? module.hot.data.store
    : CreateStore(initialState, history);

// createStore(initialState, history);
// persist the store but throttle occur only every 1000 ms

// ========================================================
// Developer Tools Setup
// ========================================================
if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)) {
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

}



ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById("root")
);

/* eslint-disable no-param-reassign */
if (module.hot) {
  module.hot.accept();

  module.hot.dispose(data => {
    data.store = store;
  });
}
