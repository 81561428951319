import { createAction } from "redux-actions";

import {
  ERROR_ON_FETCH,
  RECEIVE_INTERACTIONS,
  DELETE_CONFERENCE_SUCCESS,
  DELETE_MEETING_SUCCESS,
  DELETE_RECOGNITION_SUCCESS,
  DELETE_STAKEHOLDER_SUCCESS,
  DELETE_TRAINING_SUCCESS,
  DELETE_INTERACTION_ERROR,
  UPDATE_CONFERENCE_SUCCESS,
  UPDATE_MEETING_SUCCESS,
  UPDATE_RECOGNITION_SUCCESS,
  UPDATE_STAKEHOLDER_SUCCESS,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_INTERACTION_ERROR,
  INSERT_CONFERENCE_SUCCESS,
  INSERT_MEETING_SUCCESS,
  INSERT_RECOGNITION_SUCCESS,
  INSERT_STAKEHOLDER_SUCCESS,
  INSERT_TRAINING_SUCCESS,
  INSERT_INTERACTION_ERROR,
  INSERT_STATUSREPORTS_SUCCESS
} from "./types";

const actionError = createAction(ERROR_ON_FETCH, payload => payload);
const insertError = createAction(INSERT_INTERACTION_ERROR, payload => payload);

const interactionsReceived = createAction(
  RECEIVE_INTERACTIONS,
  payload => payload
);

const conferenceInserted = createAction(
  INSERT_CONFERENCE_SUCCESS,
  payload => payload
);

const meetingInserted = createAction(
  INSERT_MEETING_SUCCESS,
  payload => payload
);

const recognitionInserted = createAction(
  INSERT_RECOGNITION_SUCCESS,
  payload => payload
);

const stakeholderInserted = createAction(
  INSERT_STAKEHOLDER_SUCCESS,
  payload => payload
);

const statusReportsInserted = createAction(
  INSERT_STATUSREPORTS_SUCCESS,
  payload => payload
);

const trainingInserted = createAction(
  INSERT_TRAINING_SUCCESS,
  payload => payload
);

const updateError = createAction(UPDATE_INTERACTION_ERROR, payload => payload);

const conferenceUpdated = createAction(
  UPDATE_CONFERENCE_SUCCESS,
  payload => payload
);

const meetingUpdated = createAction(UPDATE_MEETING_SUCCESS, payload => payload);

const recognitionUpdated = createAction(
  UPDATE_RECOGNITION_SUCCESS,
  payload => payload
);

const stakeholderUpdated = createAction(
  UPDATE_STAKEHOLDER_SUCCESS,
  payload => payload
);

const trainingUpdated = createAction(
  UPDATE_TRAINING_SUCCESS,
  payload => payload
);

const deleteError = createAction(DELETE_INTERACTION_ERROR, payload => payload);

const conferenceDeleted = createAction(
  DELETE_CONFERENCE_SUCCESS,
  payload => payload
);

const meetingDeleted = createAction(DELETE_MEETING_SUCCESS, payload => payload);

const recognitionDeleted = createAction(
  DELETE_RECOGNITION_SUCCESS,
  payload => payload
);

const stakeholderDeleted = createAction(
  DELETE_STAKEHOLDER_SUCCESS,
  payload => payload
);

const trainingDeleted = createAction(
  DELETE_TRAINING_SUCCESS,
  payload => payload
);

const actions = {
  actionError,
  insertError,
  updateError,
  interactionsReceived,
  conferenceInserted,
  conferenceDeleted,
  conferenceUpdated,
  meetingDeleted,
  meetingInserted,
  meetingUpdated,
  stakeholderDeleted,
  stakeholderInserted,
  stakeholderUpdated,
  trainingDeleted,
  trainingInserted,
  trainingUpdated,
  recognitionDeleted,
  recognitionInserted,
  recognitionUpdated,
  statusReportsInserted,
  deleteError
};

export default actions;
