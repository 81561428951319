import React from "react";
import { EffectivenessImage } from "./EffectivenessImage";

export const WeeklyResourceHours = props => {
  const showTd = props.header.col2 === "Skill"

  return (
    <div id="weekly-resource-hours-div">
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>{props.header.col1}</th>
            <th>{props.header.col2}</th>
            <th>{props.header.col3}</th>
            <th>{props.header.col4}</th>
            {props.header.col5 && <th>{props.header.col5}</th>}          
            </tr>
        </thead>
        <tbody>
          {props.properties.map((row, index) => (
            <tr key={index}>
              <td>{row.worker}</td>
              {showTd && <td >{row.title}</td>}
              <td className="text-center">{row.totalhours}</td>
              <td className="text-center fixed-width">{row.taskhours.replace(/\n/g, "")}</td>
              <td className="table-ProgressCell text-center worker-productivity-width">
                {row.effectiveness === "" ? (
                  <span></span>
                ) : (
                  <EffectivenessImage effectiveness={row.effectiveness} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
          <td>{props.footer.col1}</td>
          {!showTd ? (
                <>
                  <td className="text-center" style={{ background: "#F6F6F6" }}>
                    {props.footer.col2}
                  </td>
                  <td className="text-center" style={{ background: "#F6F6F6" }} />
                </>
              ) : (
                <>
                  <td className="text-center" style={{ background: "#F6F6F6" }} />
                  <td className="text-center" style={{ background: "#F6F6F6" }}>
                    {props.footer.col2}
                  </td>
                </>
              )}
            <td className="text-center" style={{ background: "#F6F6F6" }} />
            {showTd && <td className="text-center" style={{ background: "#F6F6F6" }} >{props.footer.col5}</td>}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default WeeklyResourceHours;
