import React from "react";

export const ThoughtsOnQuality = () => {
  return (
    <>
      <p>
        The following are some of my thoughts on quality. How to measure
        quality, how to track quality and how to improve quality.
      </p>
      <p>
        There are two views of quality. One is the developer view. The other is
        the customer's view. The product developer is concerned with identifying
        defects, which are placed in the product during development, and
        removing them before the product is released. Here at Northrop Grumman
        we have a standard around quality. Our standard requires that, of the
        known defects within the product, no more than a pre-specified number be
        released with the product when it is given over to the customer for
        their use. The number of allowable defects is set by management, in
        concert with the customer, at the start of the project. Any defects,
        which are released with the product, must not cause the product to fail
        and none may cause loss of product functionality.
      </p>
      <p>
        The customer views quality from a different perspective. Clearly the
        customer does not want the product to fail while they are using it. And
        clearly the customer does not want any loss of product functionality.
        But their quality needs go deeper. The customer will need the product to
        have a high probability of running for a minimum period of time before
        the customer encounters any new defect which would hinder its use. This
        period of time generally relates to the customer's intended operational
        use of the product. For example, the customer may be planning to use the
        product eight hours per day, five days per week and cannot allow new
        defects to disturb their operation during that time.
      </p>
      <p>
        The standard Northrop Grumman quality report is a plot of the number of
        known defects in the product at any point in time. You the
        developer/project manager must keep your eye on the report and take
        actions to cause the number of defects at any point in time to decrease.
        You do this through meeting with your team on quality and giving them
        training on quality, as they need it. Northrop Grumman has an excellent
        training workshop on quality. If your project team members meet on
        quality but none of them have had any training, you will frustrate them
        because they won't know what to do to improve their situation. If you
        decide to meet your quality goal by testing at the end of the project
        and your people have not been trained, your testing will likely take
        longer than planned and you stand a good chance of missing your schedule
        goal. Remember, at Northrop Grumman, the product does not go to the
        customer until the quality goal has been reached.
      </p>
      <footer>
        <p>ID #1075</p>
      </footer>
    </>
  );
};
