import React, { useState } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import ClassroomInfo from "./ClassroomManagement/components/ClassroomInfo";

import {
  Card,
  Input,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  ButtonToolbar
} from "reactstrap";
import {
  setExecSeqFilterValue,
  fetchStudents,
  fetchClassesByInstructor,
  setSelectedClass,
  fetchStudentInfo
} from "redux/Instructor/thunks";
import ClassroomManagementContainer from "./ClassroomManagement/index";
import ControlRunContainer from "./ClassroomManagement/controlrun";
import StudentManagement from "./StudentManagement";
import StudentOverview from "./StudentOverview/";
import { routerMiddleware } from "react-router-redux";
import CardHeaderBold from "components/styled/CardHeaderBold";
export const StyledDropdownMenu = styled(DropdownMenu)`
  top: 216px !important;
  min-height: 100px;
  overflow: scroll;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  color: black;
  svg {
    margin-right: 1rem;
  }
`;

const InstructorDashboard = (props) => {
  const content = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("classroom-list");
  const [cardInfoOpen, toggleInfo] = useState(false);
  const [classSelectorOpen, setClassSelectorOpen] = useState(false);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Card>
        <CardHeaderBold>
          Classroom & Student Management Info/Selector
          {content.instructor.instructorClasses &&
            content.instructor.instructorClasses.length > 0 && (
              <ButtonGroup key="id-buttongroup">
                <Dropdown
                  key="classroom-dropdown"
                  isOpen={classSelectorOpen}
                  toggle={() => setClassSelectorOpen(!classSelectorOpen)}
                >
                  <DropdownToggle
                    color="primary"
                    caret
                    key="classroom-dropdown-toggle"
                  >
                    <span>
                      {content.instructor.selectedClass
                        ? content.instructor.selectedClass.className
                        : ""}
                    </span>
                  </DropdownToggle>

                  <StyledDropdownMenu key="dd-menu" style={{ minHeight: '100px', maxHeight: '200px', overflowY: 'auto', marginTop: '-13rem'  }}>
                  <div style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>
                    <DropdownItem key="dropdown-item-header" header>
                      Select a classroom
                    </DropdownItem>
                    </div>
                    {content.instructor.instructorClasses &&
                      content.instructor.instructorClasses.length > 0 ? (
                      content.instructor.instructorClasses
                        .sort((a, b) => b.classroomId - a.classroomId)
                        .map((row) => (
                          <DropdownItem
                            value={row.classroomId}
                            key={`${row.classroomId.toString()}-${row.className
                              }`}
                            onClick={() => dispatch(setSelectedClass(row))}
                          >
                            {row.className}
                          </DropdownItem>
                        ))
                    ) : (
                      <span />
                    )}
                  </StyledDropdownMenu>
                </Dropdown>
              </ButtonGroup>
            )}
          {content.instructor.loading && (
            <LoadingContainer>
              <FontAwesomeIcon spin size="2x" icon={faSpinner} /> Loading
            </LoadingContainer>
          )}
          <ButtonToolbar>
            <ButtonGroup>
              <Label className="switch switch-default switch-primary">
                <Input
                  type="checkbox"
                  className="switch-input"
                  onClick={() => toggleInfo(!cardInfoOpen)}
                  defaultChecked={cardInfoOpen}
                />
                <span className="switch-label" />
                <span className="switch-handle" />
              </Label>
            </ButtonGroup>
          </ButtonToolbar>
        </CardHeaderBold>
        <Collapse isOpen={cardInfoOpen}>
          <ClassroomInfo />
        </Collapse>
      </Card>

      <Row style={{ marginBottom: "1.5rem" }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "classroom-list"
                })}
                onClick={() => {
                  toggle("classroom-list");
                }}
              >
                My Classrooms
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "student-list"
                })}
                onClick={() => {
                  toggle("student-list");
                }}
              >
                Student Run List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "selected-student"
                })}
                onClick={() => {
                  toggle("selected-student");
                }}
              >
                Selected Student Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "control-runs-list"
                })}
                onClick={() => {
                  toggle("control-runs-list");
                }}
              >
                Control Runs
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} style={{ height: "900" }}>
            <TabPane tabId="classroom-list">
              <ClassroomManagementContainer noHideToggle />
            </TabPane>
            <TabPane tabId="student-list">
              <StudentManagement noHideToggle toggleTab={toggle} />
            </TabPane>
            <TabPane tabId="selected-student">
              <LoadingOverlay
                active={content.instructor.loading}
                spinner
                text="Loading your content..."
              >
                <StudentOverview activeTab={activeTab === "selected-student"} />
              </LoadingOverlay>
            </TabPane>
            <TabPane tabId="control-runs-list">
              <ControlRunContainer
                activeTab={activeTab === "control-runs-list"}
                classSelectorOpen={classSelectorOpen}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default InstructorDashboard;