/* eslint-disable max-len */
import React from "react";

export const Meetings = () => {
  return (
    <div id="Meetings" className="style-background-components">
      <header>
        <h2>Team Meetings</h2>
      </header>
      <div>
        <p>
          Successful projects will need to schedule and conduct team meetings.
          Team meetings do not include subcontractors. Meetings are used to
          communicate information and provide a forum for team decision-making.
          The kind of topics that are typically scheduled for team meetings are:
        </p>
        <ul>
          <li>Requirements</li>
          <li>Process / Quality</li>
          <li>Status / Productivity</li>
          <li>Risk / Issues</li>
          <li>Team Needs</li>
          <li>Schedule</li>
          <li>Cost</li>
          <li>Teamwork</li>
        </ul>
        <p>
          In establishing a meeting agenda, you may select which day of the week
          to meet and whether the meeting is recurring, or not. You may also
          select the topics to discuss, the amount of time to spend discussing
          each topic, and optionally you may indicate performance feedback you
          intend to provide on specific topics. The performance feedback choices
          represent a continuum ranging from "Praise" on one end, and "Warning"
          on the other end of the continuum. "Counseling" and "Warning" are
          generally associated with problematic behavior.
        </p>
        <p>
          You may discuss any number of topics, however, meetings cannot exceed
          8 hours in total duration. The 8 hour maximum meeting time includes
          individual conferences, stakeholder meetings, and team meetings.
          Meetings can be scheduled any day of the week, and up to 5 meetings
          can be scheduled per week. When team meetings are scheduled, every
          member of the team is invited to attend. Activities, such as training,
          stakeholder visits, and other possible work related activities can
          take priority over attendance at team meetings.
        </p>
        <p>
          Hours spent in team meetings are not reflected in "Hours Worked",
          which are reported on a weekly basis.
        </p>
        <h4>Setting Up Recurring Meetings</h4>
        <p>
          Team Meetings may be selected to be a weekly "recurring" meeting. When
          you set up a recurring meeting, the same Team Meeting will be held
          each week at the same time, covering the same topic(s), with the same
          duration and same feedback. The timing, agenda and feedback can be
          changed at any time without impacting the recurring designation. The
          recurring designation can be cleared at anytime.
        </p>
      </div>
    </div>
  );
};
